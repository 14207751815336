var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get as _get } from 'lodash';
export var getScaleFilter = function (state) {
    var config = state.config;
    return _get(config, 'filtersData.timeRange') ? "&scale=".concat(_get(config, 'filtersData.timeRange')) : '';
};
export var onChangeReadingType = function (state, dispatch, activeReadingType) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            activeReadingType: activeReadingType,
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { activeReadingType: activeReadingType }) }),
        },
    });
};
export var onQuickFilterChange = function (state, dispatch, filter, isSensorVersion) {
    var _a;
    if (isSensorVersion === void 0) { isSensorVersion = false; }
    var config = state.config, activeQuickFilters = state.activeQuickFilters;
    var changedActiveQuickFilters = isSensorVersion
        ? __assign(__assign({}, activeQuickFilters), { sensorVersionTypes: activeQuickFilters.sensorVersionTypes && activeQuickFilters.sensorVersionTypes.includes(filter)
                ? activeQuickFilters.sensorVersionTypes.filter(function (sensorVersionType) { return +sensorVersionType !== +filter; })
                : __spreadArray(__spreadArray([], (activeQuickFilters.sensorVersionTypes || []), true), [filter], false) }) : __assign(__assign({}, activeQuickFilters), (_a = {}, _a[filter] = +!activeQuickFilters[filter], _a));
    if (filter === 'hide_on_bed_ai' && changedActiveQuickFilters[filter] === 1) {
        changedActiveQuickFilters['hide_ai'] = 1;
        changedActiveQuickFilters['hide_other_side_ai'] = 1;
    }
    if (filter === 'alarm_triggered_in_last_48_hours' && changedActiveQuickFilters[filter] === 1) {
        changedActiveQuickFilters['in_alarm'] = 1;
    }
    dispatch({
        type: 'setState',
        state: {
            activeQuickFilters: changedActiveQuickFilters,
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { activeQuickFilters: changedActiveQuickFilters }) }),
        },
    });
};
export var onTimeRangeChange = function (state, dispatch, timeRange) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            timeRange: timeRange,
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { timeRange: timeRange }) }),
        },
    });
};
export var aiOnBedClick = function (dispatch, equipmentId, installationPointId, callback) {
    dispatch({
        type: 'setState',
        state: {
            equipmentId: equipmentId,
            installationPointId: installationPointId,
        },
    });
    callback();
};
