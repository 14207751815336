import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { find as _find } from 'lodash';
import React, { memo } from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { DateTimeLabel } from '../../../../widgets/common';
import DefaultTooltip from '../../DefaultTooltip';
import './index.scss';
var ActivityIndicator = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, analystLog = _a.analystLog;
    if (!analystLog.length) {
        return _jsx(_Fragment, {});
    }
    var activeLog = _find(analystLog, { is_active: 1 }), inactiveLog = _find(analystLog, { is_active: 0 });
    return (_jsxs("div", { className: "activity-indicators ".concat(activeLog ? 'active' : '', " ").concat(className), children: [_jsx(BsFillExclamationCircleFill, { "data-tooltip-id": "activity-indicator-".concat((activeLog === null || activeLog === void 0 ? void 0 : activeLog.id) || 'activeLog', "-").concat((inactiveLog === null || inactiveLog === void 0 ? void 0 : inactiveLog.id) || 'inactiveLog') }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "activity-indicator-".concat((activeLog === null || activeLog === void 0 ? void 0 : activeLog.id) || 'activeLog', "-").concat((inactiveLog === null || inactiveLog === void 0 ? void 0 : inactiveLog.id) || 'inactiveLog'), children: activeLog ? (_jsxs(_Fragment, { children: [_jsx("b", { children: activeLog.username }), " analyst is also working on this point!"] })) : inactiveLog ? (_jsxs(_Fragment, { children: ["User ", _jsx("b", { children: inactiveLog.username }), " viewed", ' ', _jsx(DateTimeLabel, { fromNow: true, id: "view_inactive_log_".concat(inactiveLog.id), dateTime: inactiveLog.updated_at })] })) : (_jsx(_Fragment, {})) })] }));
};
export default memo(ActivityIndicator);
