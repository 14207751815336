import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { nameplatesParametersKey } from './useNameplatesParametersQuery';
var nameplateParameterAddFn = function (data, customerId) {
    return Api.post('all-analytics/nameplate-parameter', data, { query: { selected_customer_ids: [customerId] } });
};
export var useNameplateParameterAddMutation = function (customerId) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return nameplateParameterAddFn(data, customerId); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: nameplatesParametersKey(customerId) });
        },
    });
};
