import Api from '../../api';
export default {
    getSensorInfo: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/sensor/get-info/".concat(installationPointId), options);
    },
    updateImpactVue: function (body, options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/sensor/update-impact-vue', body, options);
    },
};
