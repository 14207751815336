var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button, ButtonGroup } from 'reactstrap';
import { DateTimeLabel } from '../../../widgets/common';
export var getDynamicParams = function (isStaticTable) {
    return {
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: isStaticTable,
            page: 1,
            pageSize: 20,
            total: 0,
        },
        disabledSearch: isStaticTable,
        search: '',
        selectedInstallationPointId: null,
    };
};
export var config = function (isStaticTable) {
    if (isStaticTable === void 0) { isStaticTable = false; }
    return __assign(__assign({ columns: [
            {
                name: 'title',
                title: 'Title',
                sortable: false,
                style: {
                    width: '50%',
                },
                component: function (row) {
                    return (_jsx(_Fragment, { children: _jsx("span", { id: "analyst_note_".concat(row.id), children: _jsx("span", { className: "d-inline-block", dangerouslySetInnerHTML: { __html: row.title } }) }) }));
                },
            },
            {
                name: 'user_name',
                title: 'Added By',
                sortable: !isStaticTable,
            },
            {
                name: 'created_at',
                title: 'Date Create',
                sortable: !isStaticTable,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "analyst_notes_created_at".concat(row.id), dateTime: row.created_at });
                },
            },
            {
                name: 'updated_at',
                title: 'Date Update',
                sortable: !isStaticTable,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "analyst_notes_updated_at".concat(row.id), dateTime: row.updated_at });
                },
            },
            {
                name: 'action',
                title: '',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "response-ignore me-1", children: _jsxs(Button, { color: "primary", size: "sm", onClick: function () { return callbacks.onEdit(row); }, children: [_jsx(FaEdit, {}), " Edit Note"] }) }), _jsx(ButtonGroup, { children: _jsxs(Button, { color: "danger", size: "sm", onClick: function () { return callbacks.onDelete(row.id); }, children: [_jsx(FaTrash, {}), " Remove"] }) })] }));
                },
            },
        ] }, getDynamicParams(isStaticTable)), { data: [], loader: false });
};
