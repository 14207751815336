import Api from '../../api';
export default {
    getPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/pre-action-list', options);
    },
    getPreActionItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/pre-action-list/".concat(id), options);
    },
    editPreActionItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.put("all-analytics/pre-action-list/".concat(id), options);
    },
    createPreActionItem: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/pre-action-list/create', options);
    },
    removePreActionItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("all-analytics/pre-action-list/".concat(id), options);
    },
    declinePreActionItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/pre-action-list/decline/".concat(id), options);
    },
    approvePreActionItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/pre-action-list/approve/".concat(id), options);
    },
    replacePreAiSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/pre-action-list/replace-sensor', options);
    },
    replacePreAiMoteSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/pre-action-list/replace-mote-sensor', options);
    },
    replacePreAiNodeBatteries: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/pre-action-list/replace-node-batteries', options);
    },
    replacePreAiMoteBattery: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/pre-action-list/replace-mote-battery', options);
    },
    getRejectedPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/rejected-pre-ai', options);
    },
    getRejectedPreActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/rejected-pre-ai-csv', options);
    },
    leavePreAiCommentForMlTeam: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/ml-statistic/leave-pre-ai-comment', data);
    },
    getRelativePreAiList: function (preActionListId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/pre-action-list/relative-equipment-pre-ai/".concat(preActionListId), options);
    },
    savePreAiComment: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/pre-action-list-comment/save', data);
    },
    deletePreAiComment: function (id) { return Api.post('all-analytics/pre-action-list-comment/delete', { id: id }); },
    getPreActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/pre-action-list/csv', options);
    },
};
