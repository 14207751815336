import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionItemKey as actionItemKeyAmazon } from '../../../amazon/services/actionItems/useActionItemQuery';
import Api from '../../../api/api';
import { SYSTEM_TYPES_DATA } from '../../../constants';
import { actionItemKey as actionItemKeyRemastered } from '../../../remastered/services/actionItems/useActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
import { makeValidLink } from '../../helper/routeHelper';
var createFn = function (data, coreLink, storage) {
    return Api.post("".concat(coreLink, "/action-item/create"), data, { host: getHost(storage) });
};
export var useCreateMutation = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var trackEvent = useMatomo().trackEvent;
    var queryClient = useQueryClient();
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var navigate = useNavigate();
    return useMutation({
        mutationFn: function (data) { return createFn(data, getCoreUrl(selectedSystemType), storage); },
        onSuccess: function (data) {
            navigate(makeValidLink("/".concat(prefix, "/action-item/").concat(data.created_action_item_id)));
            queryClient.invalidateQueries({
                queryKey: selectedSystemType
                    ? actionItemKeyRemastered(actionItemId)
                    : actionItemKeyAmazon(actionItemId),
            });
            trackEvent({
                category: 'Action item',
                action: 'Create',
                name: "ID: ".concat(data.created_action_item_id),
                href: window.location.href,
            });
        },
    });
};
