import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import CommentToML from '../../AIPreAi/topButtons/CommentToMLBtn';
import RateMlDiagnosisButton from '../../AIPreAi/topButtons/RateMlDiagnosisButton';
import RefreshBtn from '../../AIPreAi/topButtons/RefreshBtn';
var Actions = function (_a) {
    var isShowMlComment = _a.isShowMlComment, onInvalidateData = _a.onInvalidateData, activeMlReport = _a.activeMlReport;
    return (_jsxs("div", { className: "top-actions", children: [_jsx(RefreshBtn, { onInvalidateData: onInvalidateData }), isShowMlComment ? _jsx(CommentToML, {}) : _jsx(_Fragment, {}), _jsx(RateMlDiagnosisButton, { activeMlReport: activeMlReport })] }));
};
export default memo(Actions);
