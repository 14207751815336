import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { isTemperatureChart } from '../../../../../../helper/chart';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import '../../../../../../styles/charts/chartsWrapper/LabelsChartActions.scss';
import WakeOnShakeIndicator from '../../shared/labelsChartActionsIndicators/WakeOnShakeIndicator';
import HidePeaksIndicator from './components/HidePeaksIndicator';
import ZoomActionActivity from './components/ZoomActionActivity';
var LabelsChartActions = function (_a) {
    var chartIdentifier = _a.chartIdentifier, hidePeaksOptions = _a.hidePeaksOptions;
    var _b = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
        hidePeaks: state.hidePeaks,
    }); }, shallow), isSelectChartVisible = _b.isSelectChartVisible, hidePeaks = _b.hidePeaks;
    var wosTrigger = InstallationPointStore(function (state) { return state.wosTrigger; });
    return (_jsx("div", { className: "labels-chart-actions", children: !isSelectChartVisible ? (_jsxs(_Fragment, { children: [hidePeaks ? (_jsx(HidePeaksIndicator, { hidePeaksOptions: hidePeaksOptions, chartIdentifier: chartIdentifier })) : (_jsx(_Fragment, {})), wosTrigger && !isTemperatureChart(chartIdentifier) ? (_jsx(WakeOnShakeIndicator, { chartIdentifier: chartIdentifier })) : (_jsx(_Fragment, {})), _jsx(ZoomActionActivity, { chartIdentifier: chartIdentifier })] })) : (_jsx(_Fragment, {})) }));
};
export default memo(LabelsChartActions);
