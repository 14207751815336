import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { DateTimeLabel } from '../../../../../../widgets/common';
import LinkWithPrefix from '../../../../../components/LinkWithPrefix';
export var initialState = {
    config: {
        additionContent: function (row) {
            return (_jsxs("div", { className: "table-additional-content", children: [_jsx("span", { className: "d-block text-center w-100 fz-12 fw-bold mb-2", children: "Description" }), _jsx("div", { className: "machine-info-history-content", dangerouslySetInnerHTML: { __html: row.description } })] }));
        },
        columns: [
            {
                name: 'time_create',
                title: 'Date',
                sortable: false,
                style: {
                    width: '11%',
                },
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "machine_info_history_time_created_".concat(row.id), dateTime: row.time_create_stored, timeBreak: true }));
                },
            },
            {
                name: 'comment',
                title: 'Action',
                sortable: false,
                component: function (row) {
                    if ((_get(row, 'comment') === 'Action Item Opened' ||
                        _get(row, 'comment') === 'Action Item Closed') &&
                        _get(row, 'entity_id')) {
                        return (_jsx(LinkWithPrefix, { className: "text-dark", to: "/action-item/".concat(row.entity_id), target: "_blank", rel: "noreferrer", children: row.comment }));
                    }
                    if ((_get(row, 'comment') === 'Pre-Action Item Opened' ||
                        _get(row, 'comment') === 'Pre-Action Item Declined' ||
                        _get(row, 'comment') === 'Pre-Action Item Approved') &&
                        _get(row, 'entity_id')) {
                        return (_jsx(LinkWithPrefix, { className: "text-dark", to: "/pre-action-list/".concat(row.entity_id), target: "_blank", rel: "noreferrer", children: row.comment }));
                    }
                    return _jsx(React.Fragment, { children: row.comment });
                },
            },
            {
                name: 'user_name',
                title: 'User',
                sortable: false,
                style: {
                    width: '11%',
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        search: '',
        disabledSearch: true,
        data: [],
        loader: true,
    },
};
