import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var bearingDetachFn = function (coreLink, installationPointId, bearingId) {
    return Api.post("".concat(coreLink, "/bearing/").concat(installationPointId, "/detach/").concat(bearingId), {});
};
export var useBearingDetachMutation = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var bearingId = _a.bearingId;
            return bearingDetachFn(getCoreUrl(selectedSystemType), installationPointId, bearingId);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['bearings', installationPointId] });
        },
    });
};
