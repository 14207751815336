import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment/moment';
import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import '../../../../../../styles/charts/topBar/StartupMode.scss';
var StartupMode = function (_a) {
    var startupMode = _a.startupMode;
    if (!startupMode || !startupMode.active) {
        if (startupMode &&
            startupMode.settings &&
            moment().diff(moment(startupMode.settings.end_date), 'day') > 0 &&
            moment().diff(moment(startupMode.settings.end_date), 'day') <= 60) {
            var daysAfterStartupModeEnd = moment().diff(moment(startupMode.settings.end_date), 'day');
            return (_jsxs("div", { className: "startupMode-wrap", children: [_jsxs("p", { className: "fw-bold", children: [_jsx(AiFillWarning, {}), " NEW FACILITY ", daysAfterStartupModeEnd, ' ', daysAfterStartupModeEnd === 1 ? 'day' : 'days', " since site launch"] }), _jsxs("p", { children: ["This is a new facility that has recently launched/ started up (as of", ' ', moment(startupMode.settings.end_date).format('MM/DD/YYYY'), "), so alarms may still be default alarm levels that need to be adjusted. Before creating an AI, please check alarm levels."] }), _jsxs("p", { children: ["This message will stop displaying 60 days after the approximate facility launch date (i.e. 60 days after ", 'Startup Mode', " ends)."] })] }));
        }
        if (startupMode &&
            !startupMode.settings &&
            moment().diff(moment(startupMode.facility.timestamp), 'month') <= 6) {
            var dateFrom = startupMode.facility.first_reading_timestamp ||
                startupMode.minPoweredUp ||
                startupMode.facility.timestamp, minPoweredUpDays = moment().diff(moment(dateFrom), 'day');
            if (minPoweredUpDays > 60) {
                return _jsx(React.Fragment, {});
            }
            return (_jsxs("div", { className: "startupMode-wrap", children: [_jsxs("p", { className: "fw-bold", children: [_jsx(AiFillWarning, {}), " NEW FACILITY ", minPoweredUpDays, " ", minPoweredUpDays === 1 ? 'day' : 'days', " since 1st node reported"] }), _jsx("p", { children: "This is a new facility, so alarms may still be default alarm levels that need to be adjusted. Before creating an AI, please check alarm levels." }), _jsx("p", { children: "This message will stop displaying 60 days after the first node reported data at this facility." })] }));
        }
        return _jsx(React.Fragment, {});
    }
    return (_jsxs("div", { className: "startupMode-wrap", children: [_jsxs("div", { className: "fw-bold", children: [_jsxs("p", { children: [_jsx(AiFillWarning, {}), "This facility is new; it is in Startup Mode from", ' ', moment(startupMode.settings.start_date).format('MM/DD/YYYY'), " to", ' ', moment(startupMode.settings.end_date).format('MM/DD/YYYY'), "."] }), +startupMode.settings.create_ai === 1 ? (_jsx("p", { children: "Please review alarm levels before creating Action Items that are based on automated alarm levels." })) : (''), _jsx("p", { children: "Here's what's different for this facility during Startup Mode:" })] }), _jsxs("div", { className: "mt-2 ms-3", children: [_jsxs("p", { children: ["- ", startupMode.settings.condition === '>' ? 'Increase' : 'Decrease', " default alarm levels by", ' ', startupMode.settings.percent, "%"] }), +startupMode.settings.shell === 1 ? (_jsxs("p", { children: ["- THIS IS A ", 'SHELL', " SITE. THE SITE IS NOT RUNNING. RMS/ raw data readings may be turned off, and temp intervals may be drastically extended, to save node battery life."] })) : (''), +startupMode.settings.create_ai !== 1 ? (_jsxs("p", { children: ["- ", startupMode.settings.blocking_create_ai_message] })) : ('')] })] }));
};
export default React.memo(StartupMode);
