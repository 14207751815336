import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { first as _first, size as _size } from 'lodash';
import React, { memo, useState } from 'react';
import { TbFileReport } from 'react-icons/tb';
import { Col, Row } from 'reactstrap';
import { ACTION_LIST } from '../../../../../../constants';
import DefaultTooltip from '../../../../DefaultTooltip';
import LinkWithPrefix from '../../../../LinkWithPrefix';
import ActiveAITitle from '../../../../actionItem/Title/components/ActiveAITitle';
import WWAITitle from '../../../../actionItem/Title/components/WWAITitle';
import Timestamp from '../../../Timestamp';
import TranslatesToggleBtn from '../../../TranslatesToggleBtn';
import { getTranslates } from '../../../TranslatesToggleBtn/features';
import './index.scss';
var ActionItemRow = function (_a) {
    var _b, _c;
    var installationPointCurrent = _a.installationPointCurrent, existingActionItem = _a.existingActionItem, _d = _a.isShowOld, isShowOld = _d === void 0 ? false : _d, _e = _a.isByInstallationPoint, isByInstallationPoint = _e === void 0 ? false : _e, _f = _a.isActive, isActive = _f === void 0 ? true : _f, onViewCommentsClickAction = _a.onViewCommentsClickAction, statusRow = _a.statusRow;
    var _g = useState(false), isShowTranslates = _g[0], setIsShowTranslates = _g[1];
    if (!isShowOld &&
        !!existingActionItem.is_older_then_3_months &&
        +existingActionItem.is_older_then_3_months === 1 &&
        !isActive) {
        return _jsx(_Fragment, {});
    }
    var installationPoint = existingActionItem.installationPoint, serial = existingActionItem.serial, title = existingActionItem.title, time_created = existingActionItem.time_created, updated_at = existingActionItem.updated_at, created_by = existingActionItem.created_by, translates = existingActionItem.translates, id = existingActionItem.id;
    if (isByInstallationPoint && (installationPointCurrent === null || installationPointCurrent === void 0 ? void 0 : installationPointCurrent.id) !== (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.id)) {
        return _jsx(_Fragment, {});
    }
    var installationPointName = ((_b = existingActionItem.installationPoint) === null || _b === void 0 ? void 0 : _b.name) || null;
    var sensorId = +existingActionItem.sensor_id || +((_c = existingActionItem.installationPoint) === null || _c === void 0 ? void 0 : _c.sensor_id) || null;
    var equipmentType = existingActionItem.equipment_type || null;
    var isSensor = equipmentType === ACTION_LIST.EQUIPMENT_TYPE_SENSOR || equipmentType === ACTION_LIST.EQUIPMENT_TYPE_MOTE;
    var isShowReports = !(existingActionItem.is_no_actions !== 1 && !_size(_first(existingActionItem.reports)));
    return (_jsxs(Row, { className: "py-1 active-item-row", children: [_jsx(Col, { md: 3, children: _jsxs("div", { className: "d-flex flex-column position-relative", children: [_jsx("div", { children: isSensor && installationPoint ? (_jsx(_Fragment, { children: _jsx(LinkWithPrefix, { target: "_blank", rel: "noreferrer", to: "/action-item/".concat(id), children: _jsx(ActiveAITitle, { installationPointName: installationPointName, sensorId: sensorId }) }) })) : (_jsx(LinkWithPrefix, { target: "_blank", rel: "noreferrer", to: "/action-item/".concat(id), children: _jsx(WWAITitle, { installationPointName: installationPointName, equipmentType: equipmentType, serial: serial, sensorId: sensorId }) })) }), _jsx(TranslatesToggleBtn, { fieldName: 'title', fieldNameId: 'title' + id, translates: translates || {}, buttonStyle: { right: 0, top: 0 }, isShowTranslates: isShowTranslates, setIsShowTranslates: setIsShowTranslates }), _jsx("span", { children: isShowTranslates ? getTranslates(translates || {}, 'title') : title })] }) }), _jsx(Col, { md: 2, children: statusRow(existingActionItem) }), _jsx(Col, { md: 7, children: _jsxs("div", { className: "d-flex", children: [_jsx(Timestamp, { variant: 'column', updatedAt: updated_at, timeCreated: time_created, createdBy: created_by }), !isActive && isShowReports ? (_jsxs("div", { className: "d-flex align-items-center ms-auto", "data-tooltip-id": "report_".concat(id), children: [_jsx("div", { className: "active-item-row-show-report-btn", onClick: function () {
                                        return onViewCommentsClickAction ? onViewCommentsClickAction(existingActionItem.id) : function () { };
                                    }, children: _jsx(TbFileReport, {}) }), _jsx(DefaultTooltip, { place: "left", id: "report_".concat(id), children: "Show Report" })] })) : (_jsx("div", { style: { width: '30px' } }))] }) })] }));
};
export default memo(ActionItemRow);
