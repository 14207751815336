export var onChangeCreatedBy = function (dispatch, created_by) {
    dispatch({
        type: 'setState',
        state: {
            created_by: created_by,
        },
    });
};
export var onChangeDeclinedBy = function (dispatch, declined_by) {
    dispatch({
        type: 'setState',
        state: {
            declined_by: declined_by,
        },
    });
};
export var onClearDeclined = function (dispatch) {
    dispatch({
        type: 'setState',
        state: {
            declined_by: '',
        },
    });
};
export var onClearCreated = function (dispatch) {
    dispatch({
        type: 'setState',
        state: {
            created_by: '',
        },
    });
};
