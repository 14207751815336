import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { find as _find, get as _get, size as _size } from 'lodash';
import React, { useContext, useReducer, useRef } from 'react';
import { shallow } from 'zustand/shallow';
import { IMPACT_VUE_CHART_LIST, SYSTEM_TYPES_DATA, SYSTEM_TYPE_AMAZON } from '../../../../constants';
import FullscreenChartWrapper from '../../../../modals/fullscreenChartWrapper/FullscreenChartWrapper';
import AlertConditionsSettingsLabel from '../../../../widgets/alertConditionsSettingsLabel/AlertConditionsSettingsLabel';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { getTimezone } from '../../../features/shared';
import { reducer } from '../../../helper/reducer';
import * as User from '../../../helper/user';
import { useRmsHooks } from '../../../hooks/charts/initHooks/useRmsHooks';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
import '../../../styles/charts/chartsWrapper/ChartToolsBtns.scss';
import '../../../styles/charts/index.scss';
import Loader from '../../Loader';
import ChartToolsButtonsWrapper from './components/rms/ChartToolsButtonsWrapper';
import RmsContextMenu from './components/rms/ContextMenu';
import LabelsChartActions from './components/rms/LabelsChartActions';
import { ChartSelect } from './components/shared/ChartSelect';
import { config } from './config/rmsDefaultConfig';
import { getChartAlarmBorder } from './features/rms/alertConditions';
var RmsChart = function (_a) {
    var _b;
    var chartIdentifier = _a.chartIdentifier;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        customerAlertLevels: state.customerAlertLevels,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _c.installationPoint, customerAlertLevels = _c.customerAlertLevels, readingTypes = _c.readingTypes;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _d = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
        chartTooltipsToggle: state.chartTooltipsToggle,
        chartView: state.chartView,
        setStatesChartStore: state.setStates,
        countDays: state.countDays,
    }); }, shallow), isSelectChartVisible = _d.isSelectChartVisible, chartTooltipsToggle = _d.chartTooltipsToggle, setStatesChartStore = _d.setStatesChartStore, chartView = _d.chartView;
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var _e = useReducer(reducer, config(chartIdentifier, readingTypes, setStatesChartStore, chartView, prefix, timezone, measure, personalSettingMeasure)), state = _e[0], dispatch = _e[1];
    var options = state.options, loader = state.loader, overlayInstallationPointsLoader = state.overlayInstallationPointsLoader, isVisibleFullscreenChart = state.isVisibleFullscreenChart, isDataAvailable = state.isDataAvailable, alertConditions = state.alertConditions, hidePeaksOptions = state.hidePeaksOptions, isChartSelectionChecked = state.isChartSelectionChecked, isChartPainting = state.isChartPainting;
    var tachDataIsVisible = ChartFeaturesStore(function (state) { return ({
        tachDataIsVisible: state.tachDataIsVisible,
    }); }, shallow).tachDataIsVisible;
    var chartRef = useRef(null);
    var chartElementRef = useRef(null);
    var isImpactVueChart = Boolean(IMPACT_VUE_CHART_LIST.includes(chartIdentifier));
    useRmsHooks(state, dispatch, chartIdentifier, chartRef, chartElementRef, selectedSystemType === SYSTEM_TYPE_AMAZON
        ? _get(installationPoint, 'facilityTable.timezone', 0)
        : _get(User.get(), 'timezone', 0));
    return (_jsx(_Fragment, { children: _jsx(FullscreenChartWrapper, { chartView: chartView, visible: isVisibleFullscreenChart, typeChart: 'rms', chartIdentifier: chartIdentifier, children: _jsxs("div", { style: getChartAlarmBorder({}, customerAlertLevels, chartIdentifier), id: "chart-".concat(chartIdentifier), className: "chart-item-".concat(chartIdentifier, " chart-item chart-rms ").concat(chartTooltipsToggle ? 'hidden-tooltips' : ''), "data-chart-identifier": chartIdentifier, ref: chartElementRef, children: [loader ? (_jsx("div", { style: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            zIndex: 10000,
                            backdropFilter: 'blur(2px)',
                            width: '100%',
                            height: '100%',
                        }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), overlayInstallationPointsLoader ? (_jsx("div", { style: {
                            position: 'absolute',
                            zIndex: 10,
                            top: '45px',
                            left: '10px',
                            backdropFilter: 'blur(2px)',
                        }, children: _jsx(Loader, { variant: "loader-sm" }) })) : (_jsx(_Fragment, {})), !loader && isDataAvailable ? (_jsx(ChartToolsButtonsWrapper, { state: state, dispatch: dispatch, chartRef: chartRef, chartIdentifier: chartIdentifier })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "position-relative", children: [isSelectChartVisible ? (_jsx(ChartSelect, { isChartSelectionChecked: isChartSelectionChecked, chartIdentifier: chartIdentifier, dispatch: dispatch, isChartPainting: isChartPainting, chartRef: chartRef })) : (_jsx(_Fragment, {})), _size(_get(_find(options.series, 'chartType'), 'data', [])) > 0 ? (_jsx(AlertConditionsSettingsLabel, { settings: (_b = {},
                                    _b[chartIdentifier] = {
                                        cautionSettings: {
                                            is_pre_ai: !!+_get(alertConditions, 'settings.caution.is_pre_ai'),
                                            is_ai: !!+_get(alertConditions, 'settings.caution.is_ai'),
                                            value: _get(alertConditions, 'levels.caution'),
                                            username: _get(alertConditions, 'settings.caution.username'),
                                            trigger_time: _get(alertConditions, 'settings.caution.trigger_time'),
                                        },
                                        warningSettings: {
                                            is_pre_ai: !!+_get(alertConditions, 'settings.warning.is_pre_ai'),
                                            is_ai: !!+_get(alertConditions, 'settings.warning.is_ai'),
                                            value: _get(alertConditions, 'levels.warning'),
                                            username: _get(alertConditions, 'settings.warning.username'),
                                            trigger_time: _get(alertConditions, 'settings.warning.trigger_time'),
                                        },
                                    },
                                    _b) })) : (_jsx(_Fragment, {})), _jsx(LabelsChartActions, { hidePeaksOptions: hidePeaksOptions, chartIdentifier: chartIdentifier }), _jsx(RmsContextMenu, { chartIdentifier: chartIdentifier, chartRef: chartRef, tachDataIsVisible: tachDataIsVisible, isImpactVueChart: isImpactVueChart, children: _jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: options }) })] })] }) }) }));
};
export default React.memo(RmsChart);
