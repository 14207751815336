var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck, FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import RichTextEditor from '../../../../../../widgets/TextEditor/TextEditor';
import User from '../../../../../helper/user';
import useToastErrorAlert from '../../../../../hooks/useToastErrorAlert';
import { useActionItemModalsActions } from '../../../../../store/AIPreAi/useActionItemModalsStore';
import { useConfirmModalActions } from '../../../../../store/global/useConfirmModalStore';
import { actionItemReportFormsSchemaAccept, actionItemReportFormsSchemaClosed, actionItemReportFormsSchemaDecline, } from './schemas';
var processingFormText = {
    accept: 'WW Analyst Closing Comments:',
    decline: 'Declination reason:',
    close: 'The reason for closing Action Item:',
};
var ReportForms = function (_a) {
    var isNoActionReport = _a.isNoActionReport, isShowDeclineButton = _a.isShowDeclineButton, customerId = _a.customerId, acceptAIReportMutate = _a.acceptAIReportMutate, closeAIReportMutate = _a.closeAIReportMutate, declineAIReportMutate = _a.declineAIReportMutate, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var actionItemId = useParams().id;
    if (!acceptAIReportMutate || !closeAIReportMutate || !declineAIReportMutate) {
        return _jsx(_Fragment, {});
    }
    var _c = useState(''), reportProcessingType = _c[0], setReportProcessingType = _c[1];
    var _d = useState(false), isVisibleReportProcessingForm = _d[0], setIsVisibleReportProcessingForm = _d[1];
    var getSchema = function () {
        if (reportProcessingType === 'close') {
            return actionItemReportFormsSchemaClosed;
        }
        if (reportProcessingType === 'accept') {
            return actionItemReportFormsSchemaAccept;
        }
        return actionItemReportFormsSchemaDecline;
    };
    var _e = useForm({
        defaultValues: {
            reportProcessingReason: '',
        },
        resolver: getSchema(),
    }), setValue = _e.setValue, errors = _e.formState.errors, handleSubmit = _e.handleSubmit, resetField = _e.resetField;
    var reportProcessingReasonRef = useRef('');
    useToastErrorAlert(errors);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var setCloseReportModalState = useActionItemModalsActions().setCloseReportModalState;
    var onProcessReport = handleSubmit(function () {
        var afterSave = function () {
            setIsVisibleReportProcessingForm(false);
            resetField('reportProcessingReason');
        };
        if (reportProcessingType === 'decline') {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: "Are you sure you want to ".concat(reportProcessingType, " report?"),
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    return declineAIReportMutate({
                        id: actionItemId,
                        comment: reportProcessingReasonRef.current,
                    }, {
                        onSuccess: function () {
                            toast.success('Successfully declined', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            afterSave();
                        },
                    });
                },
            });
        }
        setCloseReportModalState({
            visible: true,
            reportProcessingType: reportProcessingType,
            onSave: function (data) {
                if (reportProcessingType === 'accept') {
                    return acceptAIReportMutate(__assign({ action_item_id: +actionItemId, report_comment: reportProcessingReasonRef.current }, data), {
                        onSuccess: function () {
                            toast.success('Successfully accept', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            afterSave();
                        },
                    });
                }
                if (reportProcessingType === 'close') {
                    return closeAIReportMutate(__assign({ action_list_id: +actionItemId, description: reportProcessingReasonRef.current }, data), {
                        onSuccess: function () {
                            toast.success('Successfully closed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            afterSave();
                        },
                    });
                }
            },
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: !isVisibleReportProcessingForm ? (_jsxs("div", { className: "mt-2 float-end", children: [User.can('reviewingCR') ? (_jsx(_Fragment, { children: isNoActionReport ? (_jsxs(Button, { onClick: function () {
                                        setIsVisibleReportProcessingForm(true);
                                        setReportProcessingType('close');
                                    }, color: "success", size: "sm", className: "me-1", children: [_jsx(FaCheck, {}), " Close"] })) : (_jsxs(Button, { onClick: function () {
                                        setIsVisibleReportProcessingForm(true);
                                        setReportProcessingType('accept');
                                    }, color: "success", size: "sm", className: "me-1", children: [_jsx(FaCheck, {}), " Accept"] })) })) : (_jsx(_Fragment, {})), isShowDeclineButton ? (_jsxs(Button, { onClick: function () {
                                    setIsVisibleReportProcessingForm(true);
                                    setReportProcessingType('decline');
                                }, color: "danger", size: "sm", children: [_jsx(FaTimes, {}), " Decline"] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {})) }) }), _jsx(Row, { className: "mt-2", children: _jsx(Col, { children: isVisibleReportProcessingForm ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "fw-bold mb-2", children: processingFormText[reportProcessingType] }), _jsx("div", { className: "mt-2", children: _jsx(RichTextEditor, { storage: storage, customerId: customerId, content: reportProcessingReasonRef.current, onChange: function (content) { return (reportProcessingReasonRef.current = content); } }) }), _jsxs("div", { className: "mt-2 float-end", children: [_jsx(Button, { className: "me-1", color: "secondary", size: "sm", onClick: function () {
                                            setIsVisibleReportProcessingForm(false);
                                        }, children: "Cancel" }), _jsx(Button, { color: "success", size: "sm", onClick: function () {
                                            setValue('reportProcessingReason', reportProcessingReasonRef.current);
                                            onProcessReport();
                                        }, children: "Submit" })] })] })) : (_jsx(_Fragment, {})) }) })] }));
};
export default memo(ReportForms);
