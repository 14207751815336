import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaChartPie } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { useStatisticCountAiQuery, } from '../../../services/watchersStatistic/useStatisticCountAiQuery';
import Loader from '../../Loader';
import ChartsData from './components/Charts';
import Tables from './components/Tables';
var CountAiTab = function () {
    var _a = useState(false), isShowCharts = _a[0], setIsShowCharts = _a[1];
    var _b = useStatisticCountAiQuery(), statisticCountAiData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    if (!statisticCountAiData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    var chartsData = statisticCountAiData.chartsData, tablesData = statisticCountAiData.tablesData;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, { md: 12, className: "mb-1", children: _jsx(Button, { color: "primary", onClick: function () { return setIsShowCharts(function (prev) { return !prev; }); }, className: "pull-right", size: "sm", children: _jsx(FaChartPie, {}) }) }) }), isShowCharts ? _jsx(ChartsData, { chartsData: chartsData }) : _jsx(Tables, { tablesData: tablesData })] }));
};
export default memo(CountAiTab);
