import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { Card, Row } from 'reactstrap';
import { READING_TYPES, SENSOR } from '../constants';
import '../shared/components/shared/HighlighterWord/index.scss';
export var SensorId = function (_a) {
    var _b = _a.sensor_id, sensor_id = _b === void 0 ? null : _b, _c = _a.showDecId, showDecId = _c === void 0 ? true : _c, _d = _a.hexBreak, hexBreak = _d === void 0 ? false : _d, _e = _a.notShowEmptyHex, notShowEmptyHex = _e === void 0 ? false : _e, _f = _a.highlightSearch, highlightSearch = _f === void 0 ? '' : _f, _g = _a.hideBrackets, hideBrackets = _g === void 0 ? false : _g, _h = _a.allowBigHex, allowBigHex = _h === void 0 ? false : _h;
    if (!sensor_id) {
        return _jsx(_Fragment, {});
    }
    var hex = getHexId(sensor_id, allowBigHex);
    if (!showDecId && hex === '') {
        return (_jsxs("span", { "data-qa": "sensor-id", className: highlightSearch && sensor_id.toString().toLowerCase() === highlightSearch.toLowerCase()
                ? 'highlight-background no-wrap'
                : 'no-wrap', children: [' ', sensor_id] }));
    }
    return (_jsxs("span", { className: "no-wrap sensor-id-wrapper", children: [_jsxs("span", { "data-qa": "sensor-id", className: highlightSearch && showDecId && sensor_id.toString().toLowerCase() === highlightSearch.toLowerCase()
                    ? 'highlight-background'
                    : '', children: [' ', showDecId && sensor_id, ' '] }), hexBreak ? _jsx("br", {}) : '', _jsx("span", { "data-qa": "sensor-hex", className: highlightSearch && hex.toString().toLowerCase() === highlightSearch.toLowerCase()
                    ? 'highlight-background'
                    : '', children: hex === '' && notShowEmptyHex ? '' : hideBrackets ? hex : "(".concat(hex, ")") })] }));
};
export var getHexId = function (sensorId, allowBigHex) {
    if (allowBigHex === void 0) { allowBigHex = false; }
    if (!sensorId) {
        return '';
    }
    var hex = sensorId.toString(16);
    if (allowBigHex) {
        return hex.toUpperCase();
    }
    return hex.length === 6 ? hex.toUpperCase() : '';
};
export var SensorVersion = function (_a) {
    var versionType = _a.versionType;
    return _get(SENSOR.VERSION_TYPE_NAMES, versionType || SENSOR.VERSION_TYPE_V1);
};
export var getSensorVersionType = function (versionType) {
    return _get(SENSOR.VERSION_TYPE_NAMES, versionType || SENSOR.VERSION_TYPE_V1);
};
export var isSupportZAxis = function (versionType) {
    if (!versionType) {
        return null;
    }
    return ![SENSOR.VERSION_TYPE_V1, SENSOR.VERSION_TYPE_V1b].includes(versionType);
};
export var isMote = function (versionType, lotData) {
    if (lotData === void 0) { lotData = ''; }
    return (SENSOR.MOTE_VERSION_TYPES.includes(getSensorVersionType(versionType)) ||
        SENSOR.MOTES_LOT_DATA.includes(lotData));
};
export var isTachometer = function (versionType) {
    return +versionType === +SENSOR.VERSION_TYPE_TACHOMETER;
};
export var getAxisName = function (readingTypeId) {
    return _get(READING_TYPES, [readingTypeId, 'axisShort']);
};
export var SensorInfoContainer = function (_a) {
    var children = _a.children;
    return (_jsx(Card, { body: true, children: _jsx(Row, { children: children }) }));
};
