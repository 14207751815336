var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useReducer } from "react";
import Endpoints from "../../../../../api/endpoints/endpoints";
import { DateTimeLabel } from "../../../../../widgets/common";
import { SensorId } from "../../../../../widgets/sensor";
import DataTable from "../../../../components/shared/Table/Table";
import { SystemTypeContext } from "../../../../context/SystemTypeContext";
import { reducer } from "../../../../helper/reducer";
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from "../../../../helper/table";
var initialState = {
    config: {
        columns: [
            {
                name: 'equipment_name',
                title: 'Equipment',
                sortable: true,
            },
            {
                name: 'point_name',
                title: 'Point Name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { hexBreak: true, notShowEmptyHex: true, sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'username',
                title: 'User',
                sortable: true,
            },
            {
                name: 'old_data',
                title: 'Old Data',
                sortable: false,
                component: function (row) {
                    if (row.old_data === '[]') {
                        return 'Not set';
                    }
                    return row.old_data
                        .replaceAll(/\{|}|"/gi, '')
                        .replaceAll(',', ', ')
                        .replaceAll(':', ' = ')
                        .replaceAll('period', 'Period ')
                        .replaceAll('running_hours', 'Running hours ')
                        .replaceAll('min_', 'MIN ')
                        .replaceAll('max_', 'MAX ');
                },
            },
            {
                name: 'new_data',
                title: 'New Data',
                sortable: false,
                component: function (row) {
                    if (row.new_data === '[]') {
                        return 'Not set';
                    }
                    return row.new_data
                        .replaceAll(/\{|}|"/gi, '')
                        .replaceAll(',', ', ')
                        .replaceAll(':', ' = ')
                        .replaceAll('period', 'Period ')
                        .replaceAll('running_hours', 'Running hours ')
                        .replaceAll('min_', 'MIN ')
                        .replaceAll('max_', 'MAX ');
                },
            },
            {
                name: 'created_at',
                title: 'Time',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "running_speed_log_".concat(row.id), dateTime: row.created_at });
                },
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
    },
};
var fetch = function (selectedSystemType, state, dispatch, savingQuery, searchBase, selectedInstallationPointIds, selectedReadingTypes) {
    var config = state.config;
    setLoader(state, dispatch, true);
    // @ts-ignore
    Endpoints[selectedSystemType]
        .getSystemAlertConditionSettingLog({
        query: __assign(__assign({}, savingQuery), { searchBase: searchBase, selectedInstallationPointIds: selectedInstallationPointIds, reading_type_ids: selectedReadingTypes, pagination: config.pagination, sort: config.sort, search: config.search }),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var ActionLogTable = function (_a) {
    var savingQuery = _a.savingQuery, search = _a.search, selectedInstallationPointIds = _a.selectedInstallationPointIds, selectedReadingTypes = _a.selectedReadingTypes;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var config = state.config;
    var callback = function () {
        return fetch(selectedSystemType, state, dispatch, savingQuery, search, selectedInstallationPointIds, selectedReadingTypes);
    };
    useEffect(function () {
        callback();
    }, [selectedInstallationPointIds, selectedReadingTypes]);
    return (_jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, callback); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, state, dispatch, callback); }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, callback, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, callback); } }));
};
export default memo(ActionLogTable);
