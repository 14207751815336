// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-actionItemsComments .actionItemsComments-text {
  padding: 0 15px;
}
.page-actionItemsComments .actionItemsComments-text img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/actionItemsComments/actionItemsComments.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AACI;EACE,eAAA;AACN","sourcesContent":[".page-actionItemsComments {\n  & .actionItemsComments-text {\n    padding: 0 15px;\n    & img {\n      max-width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
