var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useReducer } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'reactstrap';
import { initialState } from '../../../config/watchersStatistic/costAvoidedReport';
import { applyFilter, onSort } from '../../../features/watchersStatistic/costAvoidedReport';
import { reducer } from '../../../helper/reducer';
import { useStatisticCostAvoidedReportQuery } from '../../../services/watchersStatistic/useStatisticCostAvoidedReportQuery';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
import Filters from './components/Filter';
var CostAvoidedReportTab = function () {
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var config = state.config, filters = state.filters, defaultData = state.defaultData;
    var _b = useStatisticCostAvoidedReportQuery(), statisticCostAvoidedReportData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                defaultData: statisticCostAvoidedReportData,
                config: __assign(__assign({}, config), { loader: false, data: statisticCostAvoidedReportData }),
                filters: __assign({}, filters),
            },
        });
    }, [statisticCostAvoidedReportData]);
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { data: applyFilter(state) }),
            },
        });
    }, [filters, defaultData]);
    if (!statisticCostAvoidedReportData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    return (_jsx(Row, { children: _jsxs(Col, { className: "mb-3", md: 12, children: [_jsx("h4", { children: "Cost Avoided" }), _jsx(Row, { children: _jsx(Col, { className: "d-flex mb-3", children: _jsx(Filters, { state: state, dispatch: dispatch }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx(DataTable, { config: config, onSort: function (column_name) { return onSort(state, dispatch, column_name); } }) }) })] }) }));
};
export default memo(CostAvoidedReportTab);
