import { useEffect } from 'react';
import { connectNulls } from '../../../helper/chart';
export var useConnectNulls = function (state, chartRef, chartIdentifier) {
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        connectNulls(chartRef, chartIdentifier);
    }, [options]);
};
