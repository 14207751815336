import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useCallback, useContext } from 'react';
import { BsGearFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../../../../store/global/useConfirmModalStore';
var ConfirmAutoLevels = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = InstallationPointStore(function (state) { return ({
        setStatesInstallationPointStore: state.setStates,
        installationPoint: state.installationPoint,
        isAutoAlertConditionsExists: state.isAutoAlertConditionsExists,
    }); }, shallow), setStatesInstallationPointStore = _a.setStatesInstallationPointStore, installationPoint = _a.installationPoint, isAutoAlertConditionsExists = _a.isAutoAlertConditionsExists;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onConfirm = useCallback(function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to confirm auto alert levels for this installation point?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                // @ts-ignore
                Endpoints[selectedSystemType]
                    .confirmAutoAlertCondition({
                    entity_id: installationPoint.id,
                })
                    .then(function (resp) {
                    if (resp.success) {
                        setStatesInstallationPointStore({ isAutoAlertConditionsExists: false });
                        return toast.success(resp.message || 'Successfully confirmed', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                    }
                });
            },
        });
    }, [selectedSystemType, installationPoint]);
    if (!isAutoAlertConditionsExists) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Button, { onClick: onConfirm, className: "btn-chart-tools", color: "customChartTopBarSelect", outline: true, size: "sm", children: _jsx(BsGearFill, {}) }));
};
export default memo(ConfirmAutoLevels);
