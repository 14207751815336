import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTitle } from '../../../customHooks';
import AnalystNotesDataTable from '../../../shared/components/analystNotes/AnalystNotesDataTable';
import FormAnalystNoteModal from '../../../shared/modals/analystNotes/FormAnalystNoteModal';
import { useAnalystNotesCreateMutation } from '../../../shared/services/analystNotes/useAnalystNotesCreateMutation';
import { useAnalystNotesDeleteAttachmentMutation } from '../../../shared/services/analystNotes/useAnalystNotesDeleteAttachmentMutation';
import { useAnalystNotesDeleteMutation } from '../../../shared/services/analystNotes/useAnalystNotesDeleteMutation';
import { useAnalystNotesUpdateMutation } from '../../../shared/services/analystNotes/useAnalystNotesUpdateMutation';
import { selectNote, useAnalystNotesModalsActions, } from '../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import SelectsGroup from '../../components/analystNotes/SelectsGroup';
import { config as systemConfig } from '../../config/analystNotes/dataTableConfig';
import { ANALYST_NOTES_TAGS } from '../../constants';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
var AnalystNotesPage = function (_a) {
    var _b = _a.installationPointId, installationPointId = _b === void 0 ? null : _b;
    useTitle('Analyst Notes');
    var selectedCustomers = selectSelectedCustomers();
    var options = {
        installationPointId: installationPointId,
        config: systemConfig(),
        additionOptions: {
            selected_customer_ids: selectedCustomers,
        },
    };
    var _c = useAnalystNotesModalsActions(), setIsVisibleModal = _c.setIsVisibleModal, resetNote = _c.resetNote;
    var note = selectNote();
    var _d = useAnalystNotesDeleteAttachmentMutation(), deleteAttachmentMutate = _d.mutate, deleteAttachmentIsLoading = _d.isPending;
    var _e = useAnalystNotesDeleteMutation(), deleteMutate = _e.mutate, deleteIsLoading = _e.isPending;
    var _f = useAnalystNotesUpdateMutation(), updateMutate = _f.mutate, updateIsLoading = _f.isPending;
    var _g = useAnalystNotesCreateMutation(), createMutate = _g.mutate, createIsLoading = _g.isPending;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsxs(Col, { className: "move-pagination-top", children: [_jsx("h3", { className: "page-title", children: "Analyst Notes" }), _jsxs("div", { className: "table_wrap mt-3", children: [_jsx(Button, { size: "sm", color: "success", onClick: function () {
                                        setIsVisibleModal(true);
                                        resetNote();
                                    }, children: "Add new" }), _jsx(AnalystNotesDataTable, { options: options, deleteMutate: deleteMutate, deleteIsLoading: deleteIsLoading, additionalNoteParam: function (note) {
                                        return {
                                            customer: {
                                                id: note.customer_id,
                                                name: note.customer_name,
                                            },
                                            locations: note.locations.map(function (el) {
                                                return {
                                                    id: el.location_id,
                                                    name: el.name,
                                                };
                                            }),
                                        };
                                    } })] })] }) }), _jsx(FormAnalystNoteModal, { tagsList: ANALYST_NOTES_TAGS, deleteAttachmentMutate: deleteAttachmentMutate, deleteAttachmentIsLoading: deleteAttachmentIsLoading, saveMutate: note.id ? updateMutate : createMutate, saveIsLoading: note.id ? updateIsLoading : createIsLoading, children: _jsx(SelectsGroup, {}) })] }));
};
export default memo(AnalystNotesPage);
