var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DateTimeLabel } from '../../../../widgets/common';
import LinkWithPrefix from '../../LinkWithPrefix';
import DataTable from '../../shared/Table/Table';
var RecentEvents = function (_a) {
    var sensorHistory = _a.sensorHistory;
    var _b = useState({
        columns: [
            {
                name: 'time_create',
                title: 'Date',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "recent_events_time_of_create_".concat(row.id), dateTime: row.time_create });
                },
            },
            {
                name: 'event',
                title: 'Status',
                sortable: false,
                component: function (row) {
                    if (!row.essence_id) {
                        return row.event;
                    }
                    var updatedText = _jsx("span", {});
                    var isAiEvent = row.event === 'Action Item Opened' || row.event === 'Action Item Closed', isPreAiEvent = row.event === 'Pre-Action Item Opened' ||
                        row.event === 'Pre-Action Item Approved' ||
                        row.event === 'Pre-Action Item Declined', isBadSensorEvent = row.event === 'Removed from Bad Items List';
                    if (row.event === 'Action Item Opened') {
                        updatedText = (_jsxs("span", { children: ["AI ", _jsx("br", {}), " Opened"] }));
                    }
                    if (row.event === 'Action Item Closed') {
                        updatedText = (_jsxs("span", { children: ["AI ", _jsx("br", {}), " Closed"] }));
                    }
                    if (row.event === 'Pre-Action Item Opened') {
                        updatedText = (_jsxs("span", { children: ["Pre-AI ", _jsx("br", {}), " Opened"] }));
                    }
                    if (row.event === 'Pre-Action Item Approved') {
                        updatedText = (_jsxs("span", { children: ["Pre-AI ", _jsx("br", {}), " Approved"] }));
                    }
                    if (row.event === 'Pre-Action Item Declined') {
                        updatedText = (_jsxs("span", { children: ["Pre-AI ", _jsx("br", {}), " Declined"] }));
                    }
                    if (isBadSensorEvent) {
                        updatedText = (_jsxs("span", { children: ["Removed from ", _jsx("br", {}), " Bad Sensors"] }));
                    }
                    var isUnhandledEvent = !isAiEvent && !isPreAiEvent;
                    if (isUnhandledEvent) {
                        return row.event;
                    }
                    return (_jsx(LinkWithPrefix, { to: isAiEvent ? "/action-item/".concat(row.essence_id) : "/pre-action-list/".concat(row.essence_id), className: "tdu text-dark", rel: "noreferrer", target: "_blank", children: updatedText }));
                },
            },
            {
                name: 'user_name',
                title: 'User',
                style: { width: '80px' },
                sortable: false,
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        search: '',
        disabledSearch: true,
        data: [],
        textAlignment: 'text-center',
        loader: false,
    }), config = _b[0], setConfig = _b[1];
    useEffect(function () {
        setConfig(__assign(__assign({}, config), { data: sensorHistory }));
    }, [sensorHistory]);
    return (_jsxs("div", { className: "recent-events-block", children: [_jsx("div", { className: "history-title float-start", children: "Recent events" }), _jsx(Scrollbars, { className: "recent-events-table-scrollbar", renderThumbVertical: function (_a) {
                    var style = _a.style, props = __rest(_a, ["style"]);
                    return (_jsx("div", __assign({ className: "thumb thumb-vertical", style: __assign({}, style) }, props)));
                }, children: _jsx("div", { className: "response-table", children: _jsx(DataTable, { config: config }) }) })] }));
};
export default React.memo(RecentEvents);
