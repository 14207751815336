import Api from '../../api';
export default {
    getDashboardOldPointsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point/old-points', options);
    },
    getAvgList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point-avg', options);
    },
    getAvgListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point-avg/csv', options);
    },
    getAvgSensorType: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point-avg/sensor-types', options);
    },
    getExistAiOnBed: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point/get-exist-ai-on-bed', options);
    },
    getInstallationPointInfo: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/installation-point/".concat(id), options);
    },
    changeSpeed: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/installation-point/change-speed', data);
    },
    saveIntervals: function (installationPointId, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/installation-point-settings/".concat(installationPointId, "/update"), body, options);
    },
    saveIntervalsEquipment: function (equipmentId, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/installation-point-settings/".concat(equipmentId, "/equipment-update"), body, options);
    },
};
