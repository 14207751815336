import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var equipmentPhotoDeleteFn = function (coreLink, id, equipmentId, installationPointId) {
    return Api.post("".concat(coreLink, "/equipment-photos/detach-image/").concat(id), {}, { query: { equipmentId: equipmentId, installationPointId: installationPointId } });
};
export var useEquipmentPhotoDeleteMutation = function (equipmentId, installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (id) {
            return equipmentPhotoDeleteFn(getCoreUrl(selectedSystemType), id, equipmentId, installationPointId);
        },
    });
};
