import { mapValues as _mapValues } from 'lodash';
export var handleEnableSelectCharts = function (chartSelectionType, setStatesChartsStore, setModalsStoreStates) {
    setStatesChartsStore({ isSelectChartVisible: true });
    setModalsStoreStates({ isSelectionEnabled: true, chartSelectionType: chartSelectionType });
    document.body.classList.add('check-graphs-to-watchlist');
};
export var handleDisableSelectCharts = function (selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setModalsStoreStates) {
    setStatesChartsStore({
        isSelectChartVisible: false,
        selectedChartsAvailable: _mapValues(selectedChartsAvailable, function () { return false; }),
        chartsSelectedPainting: _mapValues(chartsSelectedPainting, function () { return false; }),
    });
    setModalsStoreStates({ isSelectionEnabled: false, chartSelectionType: '' });
    document.body.classList.remove('check-graphs-to-watchlist');
};
