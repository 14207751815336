import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { selectNote, useAnalystNotesModalsActions, } from '../../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import { useCustomersQuery } from '../../../services/useCustomersQuery';
var CustomerSelectAnalystNotes = function () {
    var _a;
    var note = selectNote();
    var customer = note.customer;
    var _b = useCustomersQuery(), customersData = _b.data, customersIsLoading = _b.isLoading, customersIsFetching = _b.isFetching;
    var customers = customersData.customers;
    var _c = useAnalystNotesModalsActions(), setCustomer = _c.setCustomer, setFacility = _c.setFacility, setLocations = _c.setLocations, setEquipments = _c.setEquipments, setComponents = _c.setComponents;
    return (_jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Customer:" }), _jsx(Select, { placeholder: "Select Customer", classNamePrefix: "customSelectStyleDefault", name: "analystNotesCustomerSelector", id: "analystNotesCustomerSelector", isLoading: customersIsLoading || customersIsFetching, value: customers.find(function (_a) {
                    var customer_id = _a.customer_id;
                    return customer_id === (customer === null || customer === void 0 ? void 0 : customer.id);
                })
                    ? {
                        label: (_a = customers.find(function (_a) {
                            var customer_id = _a.customer_id;
                            return customer_id === (customer === null || customer === void 0 ? void 0 : customer.id);
                        })) === null || _a === void 0 ? void 0 : _a.name,
                        value: customer === null || customer === void 0 ? void 0 : customer.id,
                    }
                    : null, onChange: function (_a) {
                    var value = _a.value, label = _a.label;
                    setCustomer({
                        id: value,
                        name: label,
                    });
                    setFacility(null);
                    setLocations(null);
                    setEquipments(null);
                    setComponents(null);
                }, options: customers.map(function (_a) {
                    var name = _a.name, customer_id = _a.customer_id;
                    return {
                        label: name,
                        value: customer_id,
                    };
                }) })] }));
};
export default memo(CustomerSelectAnalystNotes);
