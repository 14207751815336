import Api from "../../api";
export default {
    getEquipmentsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/equipment', options);
    },
    getInstallationPointsByEquipment: function (equipmentId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/equipment/".concat(equipmentId, "/points"), options);
    },
    changeSpeedEquipment: function (equipmentId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("amazon-analytic/equipment/".concat(equipmentId, "/change-speed"), data);
    },
};
