var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../constants';
import { ChartFeaturesStore } from '../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
import './ChangeAlarmLevels.scss';
import AlertLogTab from './components/tabs/AlertLogTab';
import MlSuggestedLevelsTab from './components/tabs/MlSuggestedLevelsTab';
import SuggestedLevelsTab from './components/tabs/SuggestedLevelsTab';
import AlertLevelsTab from './components/tabs/alertLevelsTab/AlertLevelsTab';
import AnalystNotesTab from './components/tabs/analystNotesTab/AnalystNotesTab';
var ChangeAlarmLevelsModal = function (_a) {
    var onCancel = _a.onCancel;
    var _b = useState(false), smallLoader = _b[0], setSmallLoader = _b[1];
    var _c = useState(0), saveClicked = _c[0], setSaveClicked = _c[1];
    var _d = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setStates: state.setStates,
    }); }, shallow), changeAlertConditions = _d.changeAlertConditions, setStates = _d.setStates, readingTypeId = changeAlertConditions.readingTypeId;
    var mlSuggestedLevels = InstallationPointStore(function (state) { return state.mlSuggestedLevels; });
    var resetSpots = function () {
        setStates({
            resetAlertConditions: __assign(__assign({}, changeAlertConditions), { levels: changeAlertConditions.storedLevels }),
        });
        onCancel();
    };
    var tabContentMapping = [
        {
            component: _jsx(AlertLevelsTab, { saveClicked: saveClicked, setSmallLoader: setSmallLoader }),
            title: 'Alert Levels',
        },
        {
            component: _jsx(SuggestedLevelsTab, { setSmallLoader: setSmallLoader, smallLoader: smallLoader }),
            title: 'Suggested Levels',
        },
        {
            component: _jsx(AnalystNotesTab, { setSmallLoader: setSmallLoader }),
            title: 'Analyst Notes',
        },
        {
            component: _jsx(AlertLogTab, { smallLoader: smallLoader, setSmallLoader: setSmallLoader }),
            title: 'Alert Log',
        },
    ];
    if (_get(mlSuggestedLevels, [readingTypeId, 'id'])) {
        tabContentMapping.push({
            component: _jsx(MlSuggestedLevelsTab, { setSmallLoader: setSmallLoader, smallLoader: smallLoader }),
            title: 'ML-adjustment',
        });
    }
    var _e = useState(tabContentMapping[0].title), activeTab = _e[0], setActiveTab = _e[1];
    var onTabClick = function (tab) {
        setSaveClicked(0);
        setActiveTab(tab);
    };
    return (_jsx(React.Fragment, { children: _jsxs(Modal, { autoFocus: false, className: "modal-alarm-levels modal-response", size: "xxl", toggle: resetSpots, isOpen: true, children: [_jsxs(ModalHeader, { toggle: resetSpots, children: ["Settings of alarm levels for ", _get(READING_TYPES, [readingTypeId, 'title'], ''), ' ', _get(READING_TYPES, [readingTypeId, 'axis'], ''), smallLoader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsx(ModalBody, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx(Nav, { tabs: true, children: tabContentMapping.map(function (el) { return (_jsx(_Fragment, { children: el.hidden ? (_jsx(_Fragment, {})) : (_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === el.title, onClick: function () { return onTabClick(el.title); }, children: el.title }) }, el.title)) })); }) }), _jsx(TabContent, { className: "mt-3", activeTab: activeTab, children: tabContentMapping.map(function (el) { return (_jsx(_Fragment, { children: activeTab === el.title && !el.hidden ? (_jsx(TabPane, { tabId: el.title, children: el.component }, el.title)) : (_jsx(_Fragment, {})) })); }) })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { disabled: smallLoader, outline: true, size: "sm", color: "secondary", onClick: resetSpots, children: "Cancel" }), activeTab === tabContentMapping[0].title && (_jsx(Button, { disabled: smallLoader, size: "sm", color: "success", onClick: function () { return setSaveClicked(function (saveClicked) { return saveClicked + 1; }); }, children: "Save" }))] }) })] }) }));
};
export default memo(ChangeAlarmLevelsModal);
