import { get as _get, isEqual as _isEqual } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { FFT_ALERT_TYPES } from '../../../../constants';
import { usePrevious } from '../../../../customHooks';
import { getBandsAnnotations, getMaxBand, } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/BandAlarms';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useBands = function (state, dispatch, chartRef, chartIdentifier) {
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        currentSpeed: state.currentSpeed,
    }); }, shallow), installationPoint = _a.installationPoint, currentSpeed = _a.currentSpeed;
    var _b = FftChartsStore(function (state) { return ({
        loader: state.loader,
        fftAlerts: state.fftAlerts,
        fftTimestampDates: state.fftTimestampDates,
        isChangedCalendar: state.isChangedCalendar,
        fftSelectedDate: state.fftSelectedDate,
    }); }, shallow), loader = _b.loader, fftAlerts = _b.fftAlerts, fftTimestampDates = _b.fftTimestampDates, isChangedCalendar = _b.isChangedCalendar, fftSelectedDate = _b.fftSelectedDate;
    var options = state.options, isVisibleBands = state.isVisibleBands;
    useEffect(function () {
        if (loader || !isVisibleBands) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                isVisibleBands: false,
            },
        });
    }, [installationPoint.settings, fftSelectedDate]);
    useEffect(function () {
        if (loader) {
            return;
        }
        if (isChangedCalendar) {
            dispatch({
                type: 'setState',
                state: {
                    isVisibleBands: false,
                },
            });
        }
    }, [fftTimestampDates[chartIdentifier]]);
    var prevIsVisibleBands = usePrevious(isVisibleBands);
    var prevBands = usePrevious(_get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.BAND; }));
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        var currentBands = _get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.BAND; });
        var visibilityIsChanged = !!prevIsVisibleBands !== !!isVisibleBands;
        var bandsIsChanged = !_isEqual(prevBands, currentBands);
        if (!visibilityIsChanged && !bandsIsChanged) {
            return;
        }
        chartRef.current.chart.annotations.forEach(function (annotation) {
            if (_get(annotation, 'options.type') === 'band') {
                annotation.destroy();
            }
        });
        chartRef.current.chart.annotations = chartRef.current.chart.annotations.filter(function (annotation) { return Object.values(annotation).length > 0; });
        if (isVisibleBands) {
            var maxBand = getMaxBand(chartIdentifier, _get(fftAlerts, chartIdentifier, []));
            if (maxBand && maxBand > chartRef.current.chart.yAxis[0].max) {
                chartRef.current.chart.yAxis[0].update({ max: maxBand + maxBand * 0.1 });
            }
            getBandsAnnotations(chartIdentifier, _get(fftAlerts, chartIdentifier, []), currentSpeed).forEach(function (annotation) {
                annotation.visible = isVisibleBands;
                chartRef.current.chart.addAnnotation(annotation);
            });
            return;
        }
        chartRef.current.chart.yAxis[0].update({ max: null });
    }, [isVisibleBands, fftAlerts]);
};
