import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { ACTION_LIST, PRE_ACTION_LIST } from '../../../constants';
import CountActionItemsOnFacility from '../../../shared/components/CountActionItemsOnFacility';
import LinkWithPrefix from '../../../shared/components/LinkWithPrefix';
import HighlighterWord from '../../../shared/components/shared/HighlighterWord';
import { DateTimeLabelWithUTC } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
export var sensorInfoConfig = function (highlightSearch, data, hiddenRows, isContentVisible) {
    if (hiddenRows === void 0) { hiddenRows = []; }
    var customer = {
        name: 'Customer:',
        value: hiddenRows.includes('customer') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'customer.name') ||
                _get(data, 'customerName') ||
                _get(data, 'installationPoint.equipment.location.customer.name') ||
                _get(data, 'installationPoint.equipment.location.facility.customer.name') ||
                '---' })),
        visible: !hiddenRows.includes('customer'),
    };
    var facility = {
        name: 'Facility:',
        value: hiddenRows.includes('facility') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: _get(data, 'facility.name') ||
                        _get(data, 'facilityName') ||
                        _get(data, 'installationPoint.equipment.location.facility.name') ||
                        '---' }), _jsx(CountActionItemsOnFacility, { countHardwareAis: _get(data, 'installationPoint.equipment.location.facility.countHardwareAis'), countRegularAis: _get(data, 'installationPoint.equipment.location.facility.countRegularAis') })] })),
        visible: !hiddenRows.includes('facility'),
    };
    var equipment = {
        name: 'Equipment:',
        value: hiddenRows.includes('equipment') ? (_jsx(_Fragment, {})) : (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(_get(data, 'installationPoint.id')).concat(_get(data, 'installationPointIdsOnEquipment', [])
                .map(function (el) { return '&overlay_points=' + el; })
                .toString()
                .replaceAll(',', '')), rel: "noreferrer", target: "_blank", children: _get(data, 'installationPoint.equipment.name') ||
                _get(data, 'equipmentName') ||
                _get(data, 'equipment_info') ||
                '---' })),
        visible: !hiddenRows.includes('equipment'),
    };
    var approximate_date = {
        name: 'Approximate date of failure:',
        value: hiddenRows.includes('approximate_date') ? (_jsx(_Fragment, {})) : (_jsx(DateTimeLabelWithUTC, { className: "fz-12 fst-italic", dateTime: _get(data, 'approximateDate') })),
        visible: !!_get(data, 'approximateDate') && !hiddenRows.includes('approximate_date'),
    };
    var sla = {
        name: 'SLA:',
        value: hiddenRows.includes('sla') ? _jsx(_Fragment, {}) : _get(data, 'sla', '---'),
        visible: _get(data, 'sla') !== null && !hiddenRows.includes('sla'),
    };
    if (!isContentVisible) {
        return [
            [customer, facility],
            [equipment, sla, approximate_date],
        ];
    }
    return [
        [
            customer,
            facility,
            {
                name: 'Facility timezone:',
                value: hiddenRows.includes('facility_timezone') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'facility.timezone.title') || '---' })),
                visible: !hiddenRows.includes('facility_timezone'),
            },
            {
                name: 'Lead analysts:',
                value: hiddenRows.includes('analyticLeads') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'customer.analyticLeads', [])
                        .map(function (el) { return el.name_first + ' ' + el.name_last; })
                        .join(', ') || '---' })),
                visible: !hiddenRows.includes('analyticLeads'),
            },
            {
                name: 'Sensor:',
                value: hiddenRows.includes('sensor') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(SensorId, { notShowEmptyHex: true, sensor_id: +_get(data, 'sensorId') }), " /", ' ', _get(data, 'sensorPartNumber')] })),
                visible: !hiddenRows.includes('sensor'),
            },
            {
                name: 'Point name:',
                value: hiddenRows.includes('point_name') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'installationPoint.name') ||
                        _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, _get(data, 'equipment_type')) ||
                        '---' })),
                visible: !hiddenRows.includes('point_name'),
            },
            {
                name: 'Replace WW equipment:',
                value: hiddenRows.includes('replace_ww_equipment') ? (_jsx(_Fragment, {})) : (+_get(data, 'is_replace_equipment') === 1 && _jsx("div", { className: "response-left", children: "Yes" })),
                visible: +_get(data, 'is_replace_equipment') === 1 && !hiddenRows.includes('replace_ww_equipment'),
            },
            {
                name: data.state !== PRE_ACTION_LIST.STATUS_DECLINED ? 'Approved By:' : 'Declined By:',
                value: hiddenRows.includes('declined_by') ? _jsx(_Fragment, {}) : _jsx(_Fragment, { children: _get(data, 'actionUserName') }),
                visible: !hiddenRows.includes('declined_by') && data.state !== PRE_ACTION_LIST.STATUS_NEW,
            },
        ],
        [
            {
                name: 'Location:',
                value: hiddenRows.includes('location') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'installationPoint.equipment.location.name') ||
                        _get(data, 'locationName') ||
                        _get(data, 'location_info') ||
                        '---' })),
                visible: !hiddenRows.includes('location'),
            },
            equipment,
            sla,
            approximate_date,
        ],
        [
            {
                name: 'Country:',
                value: hiddenRows.includes('country') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'installationPoint.equipment.location.facility.country') })),
                visible: !hiddenRows.includes('country') &&
                    _get(data, 'installationPoint.equipment.location.facility.country'),
            },
        ],
    ];
};
