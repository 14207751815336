import { createStore } from '../../shared/store/createStore';
var useSelectedCustomersStore = createStore(function (set) { return ({
    selectedCustomers: [],
    actions: {
        setSelectedCustomers: function (selectedCustomers) { return set({ selectedCustomers: selectedCustomers }); },
    },
}); }, 'SelectedCustomers', true);
export var useSelectedCustomersActions = function () {
    return useSelectedCustomersStore(function (state) { return state.actions; });
};
export var selectSelectedCustomers = function () {
    return useSelectedCustomersStore(function (state) { return state.selectedCustomers; });
};
