// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal .highcharts-button-box {
  fill: transparent !important;
}
.modal .highcharts-menu {
  box-shadow: none !important;
  border-radius: 0.25rem;
}
.light-layout .modal .highcharts-menu {
  border: 1px solid #dcdcdc;
  background-color: #f1f1f1 !important;
}
.dark-layout .modal .highcharts-menu {
  border: 1px solid #dcdcdc;
  background-color: #292a38 !important;
}
.modal .highcharts-menu-item {
  font-size: 11px;
}
.light-layout .modal .highcharts-menu-item {
  color: #212325 !important;
}
.dark-layout .modal .highcharts-menu-item {
  color: #dcdcdc !important;
}
.modal .highcharts-menu-item:hover {
  border-radius: 5px;
}
.light-layout .modal .highcharts-menu-item:hover {
  background-color: #d9d9d9 !important;
}
.dark-layout .modal .highcharts-menu-item:hover {
  background-color: #3e3f4f !important;
}`, "",{"version":3,"sources":["webpack://./../src/modals/closestFft/ClosestFft.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;AAAJ;AAEE;EACE,2BAAA;EACA,sBAAA;AAAJ;AACI;EACE,yBAAA;EACA,oCAAA;AACN;AACI;EACE,yBAAA;EACA,oCAAA;AACN;AACI;EAOE,eAAA;AALN;AADM;EACE,yBAAA;AAGR;AADM;EACE,yBAAA;AAGR;AAAM;EAOE,kBAAA;AAJR;AAFQ;EACE,oCAAA;AAIV;AAFQ;EACE,oCAAA;AAIV","sourcesContent":[".modal {\n  & .highcharts-button-box {\n    fill: transparent !important;\n  }\n  & .highcharts-menu {\n    box-shadow: none !important;\n    border-radius: 0.25rem;\n    .light-layout & {\n      border: 1px solid #dcdcdc;\n      background-color: #f1f1f1 !important;\n    }\n    .dark-layout & {\n      border: 1px solid #dcdcdc;\n      background-color: #292a38 !important;\n    }\n    &-item {\n      .light-layout & {\n        color: #212325 !important;\n      }\n      .dark-layout & {\n        color: #dcdcdc !important;\n      }\n      font-size: 11px;\n      &:hover {\n        .light-layout & {\n          background-color: #d9d9d9 !important;\n        }\n        .dark-layout & {\n          background-color: #3e3f4f !important;\n        }\n        border-radius: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
