import Api from '../../api';
export default {
    getWithoutAlarmLevelsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/without-alarm-levels', options);
    },
    getWithoutAlarmLevelsListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/without-alarm-levels/csv', options);
    },
};
