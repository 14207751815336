import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { ACTION_LIST } from '../../../../../constants';
import { SensorId } from '../../../../../widgets/sensor';
var WWAITitle = function (_a) {
    var installationPointName = _a.installationPointName, equipmentType = _a.equipmentType, serial = _a.serial, sensorId = _a.sensorId;
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "page-title-name", children: installationPointName || _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, equipmentType, '---') }), "\u00A0", sensorId ? (_jsx(SensorId, { highlightSearch: highlightSearch || '', showDecId: false, sensor_id: sensorId })) : (_jsxs(_Fragment, { children: ["(", serial || sensorId || '---', ")"] }))] }));
};
export default memo(WWAITitle);
