var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onChangeFilter = function (state, dispatch, name, value) {
    var _a;
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), (_a = {}, _a[name] = value, _a)) }),
        },
    });
};
export var onDelete = function (endpoint, id, callback, setConfirmData) {
    setConfirmData({
        isVisible: true,
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            endpoint(id).then(function (resp) {
                if (resp.success) {
                    toast.success(resp.message || 'Successfully removed', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    return callback();
                }
                toast.error(resp.message || 'Removing failed', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            });
        },
    });
};
