var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { head as _head } from 'lodash';
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { READING_TYPE_TEMPERATURE } from '../../../constants';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/avgReadingValues/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { aiOnBedClick, getScaleFilter, onChangeReadingType, onQuickFilterChange, onTimeRangeChange, } from '../../../shared/features/avgReadingValues';
import { exportToCsv, onViewInstallationPoint } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import ExistingAiPreAiOnBedModal from '../../../shared/modals/ExistingAiPreAiOnBed';
import { useExistingAiPreAiActions } from '../../../shared/modals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore';
import { AVG_FILTER_FIELDS } from '../../constants';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function (avgFilterFields) {
    var config = baseConfig(avgFilterFields);
    return {
        timeRange: null,
        activeReadingType: String(READING_TYPE_TEMPERATURE),
        activeQuickFilters: {},
        equipmentId: null,
        installationPointId: null,
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                },
                {
                    name: 'name',
                    title: 'Point Name',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var config = state.config, activeReadingType = state.activeReadingType, activeQuickFilters = state.activeQuickFilters, timeRange = state.timeRange;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getAvgList({
        query: __assign({ selected_customer_ids: selectedCustomers, selected_location_id: _head(selectedLocations), reading_type_id: activeReadingType, pagination: config.pagination, sort: config.sort, search: config.search }, activeQuickFilters),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: {
                            readingTypes: resp.reading_types,
                            activeReadingType: activeReadingType,
                            activeQuickFilters: activeQuickFilters,
                            timeRange: timeRange,
                        } }),
                },
            });
        }
    });
};
var AvgReadingValues = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('AVG Reading Values');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _c = useExistingAiPreAiActions(), setIsVisible = _c.setIsVisible, setInstallationPointId = _c.setInstallationPointId, setEquipmentId = _c.setEquipmentId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(AVG_FILTER_FIELDS)), state = _d[0], dispatch = _d[1];
    var config = state.config, activeQuickFilters = state.activeQuickFilters, activeReadingType = state.activeReadingType;
    useResetDataTableEffect([selectedCustomers, selectedLocations], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers, selectedLocations);
    }, [refreshFlag, selectedCustomers, selectedLocations, activeQuickFilters, activeReadingType]);
    return (_jsx(React.Fragment, { children: _jsxs(Row, { children: [_jsxs(Col, { className: "align-pagination-section move-pagination-top", children: [_jsxs("h3", { className: "page-title", children: ["AVG reading values", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                        return exportToCsv(Endpoints.remastered.getAvgListCsv, 'avg', {
                                            query: __assign({ selected_customer_ids: selectedCustomers, selected_location_id: _head(selectedLocations), reading_type_id: activeReadingType, sort: config.sort, search: config.search }, activeQuickFilters),
                                            isBlob: true,
                                        });
                                    }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { config: config, onPageChange: function (page) {
                                return onPageChange(page, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            }, onSort: function (column_name, ev) {
                                return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); }, ev);
                            }, callbacks: {
                                onChangeReadingType: function (activeReadingType) {
                                    return onChangeReadingType(state, dispatch, activeReadingType);
                                },
                                onQuickFilterChange: function (filter, isSensorVersion) {
                                    return onQuickFilterChange(state, dispatch, filter, isSensorVersion);
                                },
                                onTimeRangeChange: function (timeRange) { return onTimeRangeChange(state, dispatch, timeRange); },
                                aiOnBedClick: function (equipmentId, installationPointId) {
                                    return aiOnBedClick(dispatch, equipmentId, installationPointId, function () {
                                        setIsVisible(true);
                                        setInstallationPointId(installationPointId);
                                        setEquipmentId(equipmentId);
                                    });
                                },
                                onViewClick: function (id) {
                                    return onViewInstallationPoint(id, selectedSystemType, getScaleFilter(state));
                                },
                            }, onSearch: function (search) {
                                return onSearch(search, state, dispatch, function () {
                                    return fetch(state, dispatch, selectedCustomers, selectedLocations);
                                });
                            } })] }), _jsx(ExistingAiPreAiOnBedModal, {})] }) }));
};
export default memo(AvgReadingValues);
