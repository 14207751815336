var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { find as _find, get as _get, set as _set } from 'lodash';
import moment from 'moment/moment';
export var getStatisticByAnalysts = function (data) {
    var outData = [];
    (data || []).map(function (analytic) {
        outData.push({ name: analytic.username, y: analytic.ai });
    });
    return outData;
};
export var getStatisticByState = function (data) {
    var outData = [];
    var statesArray = [
        'NEW',
        'Waiting',
        'Snoozed',
        'Closed',
        'Verifying',
        'Awaiting Update',
        'Archived',
        'Needs Response',
    ];
    (data || []).map(function (analytic) {
        statesArray.map(function (state) {
            var stateItem = _find(outData, { name: state });
            var nexValue = _get(analytic, ['statusCount', state], 0);
            if (!stateItem) {
                stateItem = {
                    name: state,
                    y: nexValue,
                    drilldown: state,
                };
                outData.push(stateItem);
            }
            _set(stateItem, 'y', stateItem.y + nexValue);
        });
    });
    return outData;
};
export var getGroupedData = function (data) {
    return Object.values(data.analysts).reduce(function (data, items) {
        return __spreadArray(__spreadArray([], data, true), items, true);
    }, []);
};
export var getStatisticByDays = function (data) {
    var groupedData = getGroupedData(__assign({}, data));
    var uaTeamData = _get(data, 'analysts.UA_team', []);
    var usaTeamData = _get(data, 'analysts.USA_team', []);
    // const phlTeamData = _get(data, "analysts.PHL_team", []);
    var mlBotData = _get(data, 'analysts.ML_bot_system', []);
    return [
        {
            colorByPoint: true,
            name: 'All AI Counts',
            data: groupListByDays(groupedData),
        },
        {
            colorByPoint: true,
            name: 'UA AI Counts',
            data: groupListByDays(uaTeamData),
        },
        {
            colorByPoint: true,
            name: 'USA AI Counts',
            data: groupListByDays(usaTeamData),
        },
        // {
        //     colorByPoint: true,
        //     name: "PHL AI Counts",
        //     data: groupListByDays(phlTeamData)
        // },
        {
            colorByPoint: true,
            name: 'ML-Bot AI Counts',
            data: groupListByDays(mlBotData),
        },
    ].filter(function (el) { return el.data.length; });
};
export var groupListByDays = function (data) {
    var out = [];
    var tmpData = {};
    data.map(function (item) {
        item.units.map(function (unit) {
            var date = moment(unit.date_created).format('L');
            var timestamp = new Date(date).getTime();
            var item = _get(tmpData, timestamp, 0);
            tmpData[timestamp] = item + 1;
        });
    });
    (Object.keys(tmpData) || []).map(function (time) { return out.push([parseInt(time), _get(tmpData, time, 0)]); });
    return out.sort(function (a, b) {
        return a[0] > b[0] ? 1 : -1;
    });
};
