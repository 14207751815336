import { createStore } from '../createStore';
var useWatchersStatisticModalsStore = createStore(function (set) { return ({
    isVisibleAlarmLevelCountModal: false,
    isVisiblePreAiModal: false,
    actions: {
        setIsVisibleAlarmLevelCountModal: function (isVisibleAlarmLevelCountModal) {
            return set({ isVisibleAlarmLevelCountModal: isVisibleAlarmLevelCountModal });
        },
        setIsVisiblePreAiModal: function (isVisiblePreAiModal) { return set({ isVisiblePreAiModal: isVisiblePreAiModal }); },
    },
}); });
export var useWatchersStatisticModalsActions = function () {
    return useWatchersStatisticModalsStore(function (state) { return state.actions; });
};
export var selectIsVisibleAlarmLevelCountModal = function () {
    return useWatchersStatisticModalsStore(function (state) { return state.isVisibleAlarmLevelCountModal; });
};
export var selectIsVisiblePreAiModal = function () {
    return useWatchersStatisticModalsStore(function (state) { return state.isVisiblePreAiModal; });
};
