// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selects-group {
  display: flex;
}
.selects-group .customCustomerSelect .customSelectStyleDefault__control {
  position: relative;
  max-width: 500px;
  height: 34px;
  overflow: hidden;
}
.selects-group .customCustomerSelect .customSelectStyleDefault__control:hover {
  overflow: visible;
}
.selects-group .customCustomerSelect:has(.customSelectStyleDefault__control--menu-is-open) .customSelectStyleDefault__control {
  overflow: hidden;
}
.selects-group .customCustomerSelect .customSelectStyleDefault__indicators {
  position: sticky;
  right: 0;
  top: 0;
  height: 34px;
}
.selects-group .customCustomerSelect .customSelectStyleDefault__value-container {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.dark-layout .selects-group .customCustomerSelect .customSelectStyleDefault__value-container {
  background-color: #252a38;
  border: 1px solid #3e3f4f;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/SelectsGroup/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACI;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AACN;AAAM;EACE,iBAAA;AAER;AAEM;EACE,gBAAA;AAAR;AAGI;EACE,gBAAA;EACA,QAAA;EACA,MAAA;EACA,YAAA;AADN;AAGI;EACE,kBAAA;EACA,sBAAA;EACA,yBAAA;AADN;AAEM;EACE,yBAAA;EACA,yBAAA;AAAR","sourcesContent":[".selects-group {\n  display: flex;\n  & .customCustomerSelect {\n    & .customSelectStyleDefault__control {\n      position: relative;\n      max-width: 500px;\n      height: 34px;\n      overflow: hidden;\n      &:hover {\n        overflow: visible;\n      }\n    }\n    &:has(.customSelectStyleDefault__control--menu-is-open) {\n      & .customSelectStyleDefault__control {\n        overflow: hidden;\n      }\n    }\n    & .customSelectStyleDefault__indicators {\n      position: sticky;\n      right: 0;\n      top: 0;\n      height: 34px;\n    }\n    & .customSelectStyleDefault__value-container {\n      border-radius: 4px;\n      background-color: #fff;\n      border: 1px solid #d9d9d9;\n      .dark-layout & {\n        background-color: #252a38;\n        border: 1px solid #3e3f4f;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
