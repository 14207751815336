var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useState } from 'react';
import { FaChartPie } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { config, costAvoidedTableOptions } from '../../../config/watchersStatistic/costAvoided';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault, } from '../../../features/watchersStatistic';
import { buildUsersChartSeries, numberWithCommas } from '../../../features/watchersStatistic/costAvoided';
import { useStatisticCostAvoidedQuery, } from '../../../services/watchersStatistic/useStatisticCostAvoidedQuery';
import { selectDateRange } from '../../../store/watchersStatistic/useDateRangeStore';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
import { PieChart } from '../AllStatisticTab/components/PieChart';
var CostAvoidedTab = function () {
    var dateRange = selectDateRange();
    var _a = useState({
        team_ua: false,
        team_usa: false,
        // team_phl: false,
        ml_bot_system: false,
        hardware: false,
    }), isVisibleAnalyticsChart = _a[0], setIsVisibleAnalyticsChart = _a[1];
    var _b = useStatisticCostAvoidedQuery(), statisticCostAvoidedData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    if (!statisticCostAvoidedData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    var analystsChartOptions = statisticCostAvoidedData.analystsChartOptions, teamsChartOptions = statisticCostAvoidedData.teamsChartOptions, total = statisticCostAvoidedData.total, analysts = statisticCostAvoidedData.analysts;
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: costAvoidedTableOptions.map(function (el) {
                    var _a;
                    return (_jsxs(Col, { className: "mb-3", md: 6, children: [_jsxs("h4", { children: [el.title +
                                        ' from ' +
                                        getFormattedDateFromOrDefault(dateRange[0], 'YYYY-MM-DD') +
                                        ' to ' +
                                        getFormattedDateToOrDefault(dateRange[1], 'YYYY-MM-DD'), _jsx(Button, { color: "primary", onClick: function () {
                                            var _a;
                                            setIsVisibleAnalyticsChart(__assign(__assign({}, isVisibleAnalyticsChart), (_a = {}, _a[el.key] = !isVisibleAnalyticsChart[el.key], _a)));
                                        }, size: 'sm', className: "ms-2", children: _jsx(FaChartPie, {}) })] }), isVisibleAnalyticsChart[el.key] ? (_jsx(PieChart, { options: {
                                    title: {
                                        text: "Cost Avoided By ".concat(el.shortDesc),
                                    },
                                    series: [
                                        {
                                            type: 'pie',
                                            colorByPoint: true,
                                            data: __spreadArray([], buildUsersChartSeries(_get(analysts, el.key)), true),
                                        },
                                    ],
                                } })) : (_jsxs(_Fragment, { children: [_jsx(DataTable, { config: __assign(__assign({}, config), { data: (_a = analysts[el.key]) !== null && _a !== void 0 ? _a : [] }) }), _jsxs("span", { children: ["Total: ", _jsxs("b", { children: ["$ ", numberWithCommas(total[el.key])] })] })] }))] }, el.key));
                }) }), _jsxs(Row, { className: "mt-1", children: [_jsx(Col, { sm: 6, children: _jsx(PieChart, { options: analystsChartOptions }) }), _jsx(Col, { sm: 6, children: _jsx(PieChart, { options: teamsChartOptions }) })] })] }));
};
export default memo(CostAvoidedTab);
