import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useState } from 'react';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaAmazon, FaChartLine, FaHome } from 'react-icons/fa';
import { LiaExchangeAltSolid } from 'react-icons/lia';
import { useLocation } from 'react-router-dom';
import { Navbar, UncontrolledTooltip } from 'reactstrap';
import SelectsGroupAmazon from '../../../amazon/components/SelectsGroup';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../constants';
import SelectsGroupRemastered from '../../../remastered/components/SelectsGroup';
import LinkWithPrefix from '../../../shared/components/LinkWithPrefix';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { getIsBlockedActionOnAIPreAIPage } from '../../../shared/helper/commonHelper';
import { InstallationPointStore } from '../../../shared/store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../shared/store/charts/leftEquipmentList/LeftEquipmentListStore';
import Logo from './components/Logo';
import Profile from './components/Profile';
import ProfileMenu from './components/ProfileMenu';
import './index.scss';
var Header = function (_a) {
    var auth = _a.auth, layoutDispatch = _a.layoutDispatch, _b = _a.selectedFacility, selectedFacility = _b === void 0 ? null : _b, _c = _a.selectedZone, selectedZone = _c === void 0 ? null : _c, _d = _a.selectedArea, selectedArea = _d === void 0 ? null : _d;
    var resetFilters = LeftEquipmentListStore(function (state) { return state.resetFilters; });
    var setStatesInstallationPointStore = InstallationPointStore(function (state) { return state.setStates; });
    var pathname = useLocation().pathname;
    var isChartsDashboardPage = pathname === '/dashboard' || pathname === '/amazon/dashboard';
    var _e = useContext(SystemTypeContext), selectedSystemType = _e[0], setSelectedSystemType = _e[1];
    var isBlockedChangeSystem = getIsBlockedActionOnAIPreAIPage(pathname);
    var _f = useState(false), isShowProfileMenu = _f[0], setIsShowProfileMenu = _f[1];
    return (_jsxs(Navbar, { className: "header-navbar ".concat(selectedSystemType === SYSTEM_TYPE_REMASTERED ? '' : 'amazon-header-navbar'), expand: true, children: [_jsx("div", { className: "wrapper-logo", children: _jsx(Logo, {}) }), _jsxs("div", { className: "wrapper-header-menu gap-3", children: [selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(SelectsGroupRemastered, {})) : (_jsx(SelectsGroupAmazon, { selectedFacility: selectedFacility, selectedZone: selectedZone, selectedArea: selectedArea, layoutDispatch: layoutDispatch })), _jsxs("div", { className: "btn-system-type", children: [selectedSystemType === SYSTEM_TYPE_REMASTERED ? _jsx(AiOutlineGlobal, {}) : _jsx(FaAmazon, {}), SYSTEM_TYPES_DATA[selectedSystemType].label, _jsx("div", { id: "isBlockedChangeSystem", className: "type-switch ".concat(isBlockedChangeSystem ? 'disabled' : ''), onClick: function () {
                                    if (isBlockedChangeSystem) {
                                        return;
                                    }
                                    setSelectedSystemType(selectedSystemType === SYSTEM_TYPE_REMASTERED ? SYSTEM_TYPE_AMAZON : SYSTEM_TYPE_REMASTERED);
                                }, children: _jsx(LiaExchangeAltSolid, {}) }), isBlockedChangeSystem ? (_jsx(UncontrolledTooltip, { placement: "bottom", target: 'isBlockedChangeSystem', children: "It is forbidden to switch the system on this page" })) : (_jsx(_Fragment, {}))] }), _jsx(LinkWithPrefix, { className: "headerButtonDefault", to: isChartsDashboardPage ? '/' : '/dashboard', onClick: function () {
                            setStatesInstallationPointStore({ overlayInstallationPoints: [] });
                            resetFilters();
                        }, children: isChartsDashboardPage ? (_jsxs(_Fragment, { children: [_jsx(FaHome, {}), " Manage"] })) : (_jsxs(_Fragment, { children: [_jsx(FaChartLine, {}), " Analytics"] })) }), _jsx(Profile, { isShowProfileMenu: isShowProfileMenu, setIsShowProfileMenu: setIsShowProfileMenu, children: _jsx(ProfileMenu, { layoutDispatch: layoutDispatch, isShowProfileMenu: isShowProfileMenu, auth: auth }) })] })] }));
};
export default memo(Header);
