// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node-sensor-block {
  margin-top: 20px;
}
.node-sensor-block .default-ai-block-content {
  display: flex;
  flex-wrap: wrap;
}
.node-sensor-block-item {
  position: relative;
  width: 20%;
  border-radius: 5px;
  padding: 5px 8px;
  background: #198754;
  min-width: 220px;
  margin: 10px 20px 10px 10px;
  font-size: 14px;
  color: #fff;
}
.node-sensor-block-item b {
  font-weight: 500;
}
.node-sensor-block-item-position {
  right: -12px;
  top: -12px;
  position: absolute;
  border: 2px solid #198754;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: #fff;
  color: #212529;
}
.dark-layout .node-sensor-block-item-position {
  color: #fff;
  background: #2b2f3d;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/NodeSensors/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,aAAA;EACA,eAAA;AAEJ;AAAE;EACE,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,eAAA;EACA,WAAA;AAEJ;AADI;EACE,gBAAA;AAGN;AADI;EACE,YAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAGN;AAFM;EACE,WAAA;EACA,mBAAA;AAIR","sourcesContent":[".node-sensor-block {\n  margin-top: 20px;\n  & .default-ai-block-content {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  &-item {\n    position: relative;\n    width: 20%;\n    border-radius: 5px;\n    padding: 5px 8px;\n    background: #198754;\n    min-width: 220px;\n    margin: 10px 20px 10px 10px;\n    font-size: 14px;\n    color: #fff;\n    & b {\n      font-weight: 500;\n    }\n    &-position {\n      right: -12px;\n      top: -12px;\n      position: absolute;\n      border: 2px solid #198754;\n      width: 24px;\n      height: 24px;\n      border-radius: 50%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      font-size: 13px;\n      background: #fff;\n      color: #212529;\n      .dark-layout & {\n        color: #fff;\n        background: #2b2f3d;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
