import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { first as _first } from 'lodash';
import React, { memo, useState } from 'react';
import { ACTION_LIST } from '../../../../../../constants';
import { useZoomingImg } from '../../../../../hooks/zoomingImg/useZoomingImg';
import TranslatesToggleBtn from '../../../../AIPreAi/TranslatesToggleBtn';
import { getTranslates } from '../../../../AIPreAi/TranslatesToggleBtn/features';
import './index.scss';
var NoActionReportBody = function (_a) {
    var isVisibleReportBody = _a.isVisibleReportBody, isSensorWasDetached = _a.isSensorWasDetached, isShowDetachBlockInReport = _a.isShowDetachBlockInReport, isSensorMountedInTheSameOrientation = _a.isSensorMountedInTheSameOrientation, noActionsDescription = _a.noActionsDescription, translates = _a.translates, noActionReports = _a.noActionReports, wwNoActionReports = _a.wwNoActionReports, type = _a.type, isOpenFromComments = _a.isOpenFromComments;
    var noActionReportData = _first(type === ACTION_LIST.AI_TYPE_REGULAR ? noActionReports : wwNoActionReports);
    var _b = useState(false), isShowTranslates = _b[0], setIsShowTranslates = _b[1];
    useZoomingImg('.zoomImg-CompletionReport-NoActionReportBody img', [isVisibleReportBody, isShowTranslates]);
    return (_jsxs("div", { className: "no-action-report-body zoomImg-CompletionReport-NoActionReportBody", children: [(noActionReportData && noActionReportData.no_actions_description) || noActionsDescription ? (_jsxs("div", { className: 'position-relative', children: [_jsx("div", { className: "no-action-report-body-title", children: "Description / Reason Provided:" }), _jsxs(_Fragment, { children: [_jsx("div", { dangerouslySetInnerHTML: {
                                    __html: isShowTranslates
                                        ? getTranslates((noActionReportData === null || noActionReportData === void 0 ? void 0 : noActionReportData.translates) || translates || {}, 'no_actions_description')
                                        : noActionsDescription || noActionReportData.no_actions_description || '',
                                } }), _jsx(TranslatesToggleBtn, { fieldNameId: "".concat(isOpenFromComments ? 'comment_' : '', "no_actions_description"), fieldName: 'no_actions_description', translates: (noActionReportData === null || noActionReportData === void 0 ? void 0 : noActionReportData.translates) || translates || {}, buttonStyle: { top: '0', right: '0' }, isShowTranslates: isShowTranslates, setIsShowTranslates: setIsShowTranslates })] })] })) : (_jsx(_Fragment, {})), isShowDetachBlockInReport && isSensorWasDetached ? (_jsxs("div", { className: "d-flex mt-2", children: [_jsx("div", { className: "no-action-report-body-title me-2", children: "Sensor was detached:" }), isSensorWasDetached ? 'Yes' : 'No'] })) : (_jsx(_Fragment, {})), isShowDetachBlockInReport && !isSensorWasDetached && isSensorMountedInTheSameOrientation ? (_jsxs("div", { className: "d-flex mt-2", children: [_jsx("div", { className: "no-action-report-body-title me-2", children: "Sensor mounted in the same orientation as the original installation photo:" }), isSensorMountedInTheSameOrientation ? 'Yes' : 'No'] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(NoActionReportBody);
