var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useCustomersQuery } from '../../../services/useCustomersQuery';
import { useLeadAnalystQuery } from '../../../services/useLeadAnalystQuery';
import { selectSelectedCustomers, useSelectedCustomersActions } from '../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst, useSelectedLeadAnalystActions, } from '../../../store/SelectedLeadAnalystStore';
import { selectSelectedLocations, useSelectedLocationsActions } from '../../../store/SelectedLocationsStore';
var LeadAnalystSelect = function () {
    var _a;
    var trackEvent = useMatomo().trackEvent;
    var customersData = useCustomersQuery().data;
    var customers = customersData.customers;
    var _b = useLeadAnalystQuery({ customers: customers }), leadAnalystData = _b.data, leadAnalystIsLoading = _b.isLoading, leadAnalystIsFetching = _b.isFetching;
    var leadAnalyst = leadAnalystData.data;
    var setSelectedLeadAnalyst = useSelectedLeadAnalystActions().setSelectedLeadAnalyst;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var setSelectedCustomers = useSelectedCustomersActions().setSelectedCustomers;
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var onChangeHandler = useCallback(function (item) {
        setSelectedLeadAnalyst([item.value]);
        trackEvent({ category: 'Base', action: 'Lead analyst', name: item.label, href: window.location.href });
        if (selectedCustomers.length) {
            setSelectedCustomers([]);
        }
        if (selectedLocations.length) {
            setSelectedLocations([]);
        }
    }, [leadAnalyst, selectedCustomers, selectedLocations]);
    var options = useMemo(function () { return __spreadArray([
        { label: 'All Lead analyst', value: 0 }
    ], leadAnalyst.map(function (analyst) { return ({
        label: analyst.name_first + ' ' + analyst.name_last,
        id: analyst.id,
        value: analyst.id,
    }); }), true); }, [leadAnalyst]);
    return (_jsx(Select, { name: "leadAnalystSelector", isLoading: leadAnalystIsLoading || leadAnalystIsFetching, classNamePrefix: "customSelectStyleDefault", placeholder: "All lead analysts", value: (_a = options.find(function (_a) {
            var value = _a.value;
            return value === selectedLeadAnalyst[0];
        })) !== null && _a !== void 0 ? _a : null, onChange: onChangeHandler, options: options }));
};
export default memo(LeadAnalystSelect);
