// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-timestamp {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 16px;
  vertical-align: bottom;
  font-weight: 500;
}
.ai-timestamp small {
  font-size: 13px;
  margin: 0 7px;
  font-weight: 400;
  vertical-align: bottom;
  text-transform: uppercase;
  color: #777;
}
.dark-layout .ai-timestamp small {
  color: #ccc;
}
.ai-timestamp.column {
  width: 100%;
  margin: 0;
  align-items: flex-start;
}
.ai-timestamp.column small {
  margin: 0;
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/Timestamp/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;AAGN;AAAE;EACE,WAAA;EACA,SAAA;EACA,uBAAA;AAEJ;AADI;EACE,SAAA;EACA,mBAAA;AAGN","sourcesContent":[".ai-timestamp {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n  font-size: 16px;\n  vertical-align: bottom;\n  font-weight: 500;\n  & small {\n    font-size: 13px;\n    margin: 0 7px;\n    font-weight: 400;\n    vertical-align: bottom;\n    text-transform: uppercase;\n    color: #777;\n    .dark-layout & {\n      color: #ccc;\n    }\n  }\n  &.column {\n    width: 100%;\n    margin: 0;\n    align-items: flex-start;\n    & small {\n      margin: 0;\n      vertical-align: top;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
