// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nameplate-photo {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 10px;
  cursor: pointer;
}
.nameplate-photo img {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/Nameplates/Nameplates.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".nameplate-photo {\n  display: inline-block;\n  width: 100px;\n  height: 100px;\n  margin: 10px;\n  cursor: pointer;\n\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
