import { useQuery } from '@tanstack/react-query';
import { get as _get } from 'lodash';
import Api from '../../../api/api';
import { FrequencyConverter } from '../../../shared/components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
export var nameplatesKey = function (installationPointId) { return ['nameplates', installationPointId]; };
var nameplatesFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("all-analytics/machine-info/get-nameplates/".concat(queryKey[1]));
};
export var useNameplatesQuery = function (_a) {
    var installationPointId = _a.installationPointId;
    return useQuery({
        queryKey: nameplatesKey(installationPointId),
        queryFn: nameplatesFn,
        select: function (_a) {
            var data = _a.data;
            var installation_point = _get(data, 'installation_point', {});
            var equipment = _get(data, 'equipment', {});
            var speed = _get(installation_point, 'speed');
            // @ts-ignore
            var speedRatio = _get(installation_point, 'speed_ratio') || FrequencyConverter.equipmentSpeedRatio;
            return {
                data: [
                    {
                        installation_point_speed: speed === null ? '---' : speed,
                        installation_point_speed_ratio: speedRatio === null ? '---' : speedRatio,
                        equipment_speed_type: _get(equipment, 'speedType.0.name') || '---',
                        equipment_speed: _get(equipment, 'current_speed') || '---',
                        equipment_min_speed: _get(equipment, 'speed_min') || '---',
                        equipment_max_speed: _get(equipment, 'speed_max') || '---',
                    },
                ],
                installationPointNameplate: _get(installation_point, 'nameplate', []),
                installationPointNameplateLog: _get(installation_point, 'nameplateLogs', []),
                equipmentNameplate: _get(equipment, 'nameplate', []),
                equipmentNameplateLogs: _get(equipment, 'nameplateLogs', []),
                equipmentNoPhotoReason: _get(equipment, 'noPhotoReason'),
            };
        },
        enabled: !!installationPointId,
    });
};
export var nameplatesListKey = function (customerId) { return ['nameplatesList', customerId]; };
var nameplatesListFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get('all-analytics/nameplate/select', { query: { selected_customer_ids: [queryKey[1]] } });
};
export var useNameplatesListQuery = function (_a) {
    var customerId = _a.customerId;
    return useQuery({
        queryKey: nameplatesListKey(customerId),
        queryFn: nameplatesListFn,
        select: function (_a) {
            var list = _a.list;
            return { list: list };
        },
        enabled: !!customerId,
    });
};
