import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var updateWwMissStatusFn = function (data, coreLink, storage) {
    return Api.post("".concat(coreLink, "/action-list-ww-misses/update-status"), data, { host: getHost(storage) });
};
export var useUpdateWwMissStatusMutation = function (_a) {
    var _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) { return updateWwMissStatusFn(data, getCoreUrl(selectedSystemType), storage); },
    });
};
