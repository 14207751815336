import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useReducer } from 'react';
import { useTitle } from '../../../customHooks';
import { reducer } from '../../helper/reducer';
import './WhatsNew.scss';
import WhatsNewArticleList from './components/ArticleList/ArticleList';
import WhatsNewContent from './components/Content/Content';
import WhatsNewReleaseList from './components/ReleaseList/ReleaseList';
import SearchBtn from './components/SearchBtn/SearchBtn';
import SearchFrame from './components/SearchFrame/SearchFrame';
var initialState = {
    searchInput: '',
    searchFrameData: [],
    loaderSearchFrame: false,
    loaderRelease: false,
    loaderArticle: false,
    activeRelease: null,
    activeArticleId: null,
    releases: [],
    activeArticles: [],
    isOpenSearchFrame: false,
};
var WhatsNewPage = function () {
    useTitle('What`s New');
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(SearchFrame, { state: state, dispatch: dispatch }), _jsxs("div", { className: "page-whatsNew", children: [_jsx("h3", { className: "page-title", children: "What`s new" }), _jsx(SearchBtn, { state: state, dispatch: dispatch }), _jsxs("div", { className: "d-flex", children: [_jsx(WhatsNewReleaseList, { state: state, dispatch: dispatch }), _jsx(WhatsNewArticleList, { state: state, dispatch: dispatch }), _jsx(WhatsNewContent, { state: state, dispatch: dispatch })] })] })] }));
};
export default memo(WhatsNewPage);
