// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_tooltip {
  padding: 5px 5px 5px 5px;
  min-width: 180px;
  font-size: 13px;
  white-space: nowrap;
  pointer-events: all;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  z-index: 2;
  position: relative;
}
.dark-layout .custom_tooltip {
  border: 1px solid #292a38;
  background-color: #1e222d;
  color: #fff;
}
.custom_tooltip a {
  text-decoration: none;
}

.highcharts-tooltip .custom_tooltip_locker {
  background: #ddd;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 4px;
  color: #000;
  cursor: move !important;
}
.highcharts-tooltip > span {
  z-index: 1;
}

.chart-3d-trend-tooltip {
  background-color: #fff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/tooltip.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;AACF;AAAE;EACE,yBAAA;EACA,yBAAA;EACA,WAAA;AAEJ;AAAE;EACE,qBAAA;AAEJ;;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;AAAJ;AAEE;EACE,UAAA;AAAJ;;AAIA;EACE,sBAAA;EACA,aAAA;EACA,oCAAA;AADF","sourcesContent":[".custom_tooltip {\n  padding: 5px 5px 5px 5px;\n  min-width: 180px;\n  font-size: 13px;\n  white-space: nowrap;\n  pointer-events: all;\n  border: 1px solid #e2e2e2;\n  background-color: #fff;\n  z-index: 2;\n  position: relative;\n  .dark-layout & {\n    border: 1px solid #292a38;\n    background-color: #1e222d;\n    color: #fff;\n  }\n  & a {\n    text-decoration: none;\n  }\n}\n\n.highcharts-tooltip {\n  & .custom_tooltip_locker {\n    background: #ddd;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    padding: 2px 4px;\n    color: #000;\n    cursor: move !important;\n  }\n  & > span {\n    z-index: 1;\n  }\n}\n\n.chart-3d-trend-tooltip {\n  background-color: #fff;\n  padding: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
