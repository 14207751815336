import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { METRIC_KEY, METRIC_NAMES } from '../../../../constants';
import User from '../../../../shared/helper/user';
import { selectMeasure } from '../../../../shared/store/global/useGlobalStore';
var Profile = function (_a) {
    var isShowProfileMenu = _a.isShowProfileMenu, setIsShowProfileMenu = _a.setIsShowProfileMenu, children = _a.children;
    var user = User.get();
    var measure = selectMeasure();
    return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    _jsxs("div", { onMouseOver: function () { return setIsShowProfileMenu(true); }, onMouseLeave: function () { return setIsShowProfileMenu(false); }, className: "header-profile ".concat(isShowProfileMenu ? 'active' : ''), children: [_jsx("span", { className: "profile-avatar", style: {
                    backgroundImage: "url(".concat(user.userpic || '', "), url(").concat(APP_SSO_URL, "/images/placeholder-user.png)"),
                } }), _jsxs("div", { className: "profile-username-measurement", children: [user.full_name || '', _jsx("span", { children: METRIC_NAMES[METRIC_KEY[measure]] })] }), children || _jsx(_Fragment, {})] }));
};
export default memo(Profile);
