import { createStore } from '../createStore';
var useCountOfAlarmLevelsDataTableStore = createStore(function (set) { return ({
    sort: [
        {
            field: 'total',
            direction: 'desc',
        },
    ],
    actions: {
        setSort: function (sort) { return set({ sort: sort }); },
    },
}); });
export var useCountOfAlarmLevelsDataTableActions = function () {
    return useCountOfAlarmLevelsDataTableStore(function (state) { return state.actions; });
};
export var selectSortDataTable = function () {
    return useCountOfAlarmLevelsDataTableStore(function (state) { return state.sort; });
};
