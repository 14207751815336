import { createStore } from '../createStore';
var defaultSort = [
    {
        field: 'created_at',
        direction: 'desc',
    },
];
var useFirmwareStore = createStore(function (set) { return ({
    search: '',
    page: 1,
    pageSize: 20,
    sort: defaultSort,
    type: null,
    actions: {
        setSearch: function (search) { return set({ search: search }); },
        setPage: function (page) { return set({ page: page }); },
        setPageSize: function (pageSize) { return set({ pageSize: pageSize }); },
        setSort: function (sort) { return set({ sort: sort.length ? sort : defaultSort }); },
        setType: function (type) { return set({ type: type }); },
    },
}); });
export var useFirmwareActions = function () {
    return useFirmwareStore(function (state) { return state.actions; });
};
export var selectSearchDataTable = function () {
    return useFirmwareStore(function (state) { return state.search; });
};
export var selectPageDataTable = function () { return useFirmwareStore(function (state) { return state.page; }); };
export var selectPageSizeDataTable = function () {
    return useFirmwareStore(function (state) { return state.pageSize; });
};
export var selectSortDataTable = function () { return useFirmwareStore(function (state) { return state.sort; }); };
export var selectTypeDataTable = function () {
    return useFirmwareStore(function (state) { return state.type; });
};
