import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Col, Row, Table } from 'reactstrap';
import Loader from '../../Loader';
import EquipmentSuggestedLevels from './components/EquipmentSuggestedLevels/EquipmentSuggestedLevels';
export var SuggestedLevelsContent = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, fetchCallback = _a.fetchCallback, dispatchSuggestedLevelModal = _a.dispatchSuggestedLevelModal, stateSuggestedLevelFormulaModal = _a.stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal = _a.setStateSuggestedLevelFormulaModal;
    var data = state.data, units = state.units, loader = state.loader;
    return (_jsx(Row, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(Col, { children: _jsx(Scrollbars, { className: "mt-3", style: { height: 'calc(100vh - 270px)' }, children: data.length !== 0 ? (_jsx(Table, { bordered: true, responsive: "sm", className: "main-table", children: _jsx("tbody", { children: data.map(function (equipment) { return (_jsx(EquipmentSuggestedLevels, { state: state, dispatch: dispatch, equipment: equipment, units: units, dispatchSuggestedLevelModal: dispatchSuggestedLevelModal, stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal: setStateSuggestedLevelFormulaModal, fetchCallback: fetchCallback }, equipment.id)); }) }) })) : (_jsx(React.Fragment, { children: !loader ? _jsx("p", { className: "text-center", children: "There are no tracking components" }) : _jsx(_Fragment, {}) })) }) })) }));
};
