import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { first as _first, get as _get } from 'lodash';
import React from 'react';
import { Badge } from 'reactstrap';
import { ACTION_LIST, DETACHED_SENSOR, URGENCY_EARLY_PREDICTION_TOOLTIP } from '../../constants';
export var UrgencyLabel = function (_a) {
    var urgency = _a.urgency, _b = _a.replacementHistory, replacementHistory = _b === void 0 ? null : _b, type = _a.type;
    if (!urgency) {
        return _jsx(_Fragment, {});
    }
    if (+urgency === ACTION_LIST.URGENCY_EARLY_PREDICTION &&
        (type === DETACHED_SENSOR || _get(_first(replacementHistory), 'type') === DETACHED_SENSOR)) {
        return _jsx(Badge, { color: "secondary", children: "Detached sensor" });
    }
    return (_jsx(Badge, { title: +urgency === ACTION_LIST.URGENCY_EARLY_PREDICTION ? URGENCY_EARLY_PREDICTION_TOOLTIP : '', color: ACTION_LIST.URGENCY_COLORS[urgency], children: ACTION_LIST.URGENCY_NAMES[urgency] }));
};
