import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { FaInfo } from 'react-icons/fa';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { getUoms, isAccelerationChart, isAccelerationImpactVue } from '../../../../../../../helper/chart';
import { InstallationPointStore } from '../../../../../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../../../../../store/global/useGlobalStore';
var IdleThresholdsButton = function (_a) {
    var width = _a.width, chartIdentifier = _a.chartIdentifier, onShowThreshold = _a.onShowThreshold;
    var _b = useState(false), showThresholds = _b[0], setShowThresholds = _b[1];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    if (!isAccelerationChart(chartIdentifier) && !isAccelerationImpactVue(chartIdentifier)) {
        return _jsx(_Fragment, {});
    }
    useEffect(function () {
        onShowThreshold(showThresholds);
    }, [showThresholds]);
    useEffect(function () {
        setShowThresholds(false);
    }, [installationPoint.idle_threshold, installationPoint.impact_idle_threshold]);
    return (_jsxs("div", { className: "chart-btn", children: [_jsx(Button, { color: "secondary", onClick: function () { return setShowThresholds(!showThresholds); }, id: "idle-threshold-info-".concat(chartIdentifier), className: "chart-action-btn", style: { width: width }, children: _jsx(FaInfo, {}) }), _jsx(UncontrolledTooltip, { target: "idle-threshold-info-".concat(chartIdentifier), children: _jsxs(_Fragment, { children: ["Idle Threshold:", ' ', isAccelerationImpactVue(chartIdentifier)
                            ? installationPoint.impact_idle_threshold
                            : installationPoint.idle_threshold, ' ', getUoms(chartIdentifier, measure, personalSettingMeasure)] }) })] }));
};
export default memo(IdleThresholdsButton);
