var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep as _cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { calculateYExtremes } from '../../../components/charts/chartsWrapper/features/shared/extremes';
export var useYAxisExtremes = function (state, dispatch) {
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        var _a = calculateYExtremes(_cloneDeep(options.series), _cloneDeep(options.yAxis[0].plotLines)), maxY = _a[0], minY = _a[1];
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { softMax: maxY, softMin: minY }),
                    ] }),
            },
        });
    }, [options.series, options.yAxis[0].plotLines]);
};
