import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
var SHORT_TEXT = 'In the general list of Pre-AI, there are active entries with a critical level of urgency. Please process (or edit) them first!';
var FULL_TEXT = "\n  This is a Pre-AI with a critical level of urgency and needs priority processing, so please take one of the suggested actions:\n  <ul>\n    <li>Approve Pre-AI</li>\n    <li>Decline Pre-AI</li>\n    <li>Change the level of urgency (if the current one is not relevant)</li>\n    <li>Write a comment if a larger data set is required for processing (note that the comment must be expanded so that another analyst can analyze the cause)</li>\n  </ul>\n  <b>If you have any doubts, please contact the team for further advice!</b>\n";
export var useNotificationForCriticalPreAi = function (isCritical, isDetails) {
    useEffect(function () {
        if (!isCritical) {
            return;
        }
        toast.error(isDetails ? _jsx("div", { dangerouslySetInnerHTML: { __html: FULL_TEXT } }) : SHORT_TEXT, {
            icon: _jsx(FaTimes, { style: { fill: '#fff' } }),
            autoClose: false,
            theme: 'colored',
            style: { width: '600px' },
            position: 'bottom-left',
        });
    }, [isCritical]);
};
