// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-to-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.back-to-list-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px solid #ccc;
}
.dark-layout .back-to-list-btn {
  border: 2px solid #2a2e39;
}
.back-to-list-btn:hover {
  transition: 0.3s;
  border: 2px solid #2962ff;
  background: #2962ff;
}
.back-to-list-btn:hover svg {
  transition: 0.3s;
  fill: #fff;
}
.back-to-list-btn svg {
  width: 14px;
  height: 14px;
  fill: #212529;
}
.dark-layout .back-to-list-btn svg {
  fill: #fff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/BackToList/BackToList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AAEJ;AADI;EACE,yBAAA;AAGN;AADI;EACE,gBAAA;EACA,yBAAA;EACA,mBAAA;AAGN;AAFM;EACE,gBAAA;EACA,UAAA;AAIR;AADI;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAGN;AAFM;EACE,UAAA;AAIR","sourcesContent":[".back-to-list {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n  margin-right: 10px;\n  &-btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    border-radius: 5px;\n    border: 2px solid #ccc;\n    .dark-layout & {\n      border: 2px solid #2a2e39;\n    }\n    &:hover {\n      transition: 0.3s;\n      border: 2px solid #2962ff;\n      background: #2962ff;\n      & svg {\n        transition: 0.3s;\n        fill: #fff;\n      }\n    }\n    & svg {\n      width: 14px;\n      height: 14px;\n      fill: #212529;\n      .dark-layout & {\n        fill: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
