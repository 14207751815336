import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useState } from 'react';
import { FaRetweet } from 'react-icons/fa';
import Endpoints from '../../../../api/endpoints/endpoints';
import OnDemandStatus from '../../../../widgets/OnDemandStatus';
import { DateTimeLabel } from '../../../../widgets/common';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
var fetch = function (setData, endpoint) {
    endpoint().then(function (resp) {
        if (resp) {
            setData(resp.data);
        }
    });
};
var OnDemandLogTable = function (_a) {
    var refreshFlag = _a.refreshFlag, installationPointIds = _a.installationPointIds;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        fetch(setData, function () {
            return Endpoints[selectedSystemType].getOnDemandUsersFftRequests({
                query: {
                    installation_point_id: installationPointIds,
                    types: ['rms', 'fft', 'fft_hfdvue', 'rms_hfdvue'],
                    sort: [
                        {
                            field: 'created_at',
                            direction: 'desc',
                        },
                    ],
                },
            });
        });
    }, [refreshFlag, installationPointIds]);
    return (_jsx(_Fragment, { children: (data || []).map(function (item) { return (_jsxs("div", { className: "d-flex", children: [_jsxs("div", { children: [_jsx(FaRetweet, { size: 32, color: "#b1b1b1" }), _jsx("div", { className: "vertical-line mt-4", style: { backgroundColor: '#b1b1b1' } })] }), _jsxs("div", { className: "ms-4 fw-bold", children: [_jsx("div", { className: "mb-2 mt-2", style: { color: '#b1b1b1', fontSize: 12 }, children: _jsx(DateTimeLabel, { id: "on_demand_users_requests".concat(item.id), dateTime: item.created_at, fromNow: true }) }), item.point_name, _jsxs("div", { className: "fz-12", children: [item.type === 'fft' || item.type === 'fft_hfdvue' ? (_jsx(_Fragment, { children: _jsxs("div", { children: ["FFT ", item.type === 'fft_hfdvue' ? 'Impact Vue' : '', " requested", ' ', item.username ? _jsxs("b", { children: [" by ", item.username] }) : _jsx(_Fragment, {}), " with params:", ' ', item.fmax ? (_jsxs(_Fragment, { children: [_jsx("b", { children: "FMax: " }), " ", item.fmax] })) : (_jsx(_Fragment, {})), ' ', item.lor ? (_jsxs(_Fragment, { children: [_jsx("b", { children: "LOR: " }), " ", item.lor] })) : (_jsx(_Fragment, {}))] }) })) : (_jsx(_Fragment, { children: _jsxs("div", { children: ["RMS ", item.type === 'rms_hfdvue' ? 'Impact Vue' : '', " requested", ' ', item.username ? _jsxs("b", { children: [" by ", item.username] }) : _jsx(_Fragment, {})] }) })), _jsx("div", { className: "d-flex justify-content-start", children: _jsx(OnDemandStatus, { estimatedAt: item.estimated_at, readingFftOnDemand: item.readingFftOnDemand || item.readingFftHfdvueOnDemand, requestId: item.request_id }) })] })] })] }, "on-demand-log-".concat(item.id))); }) }));
};
export default memo(OnDemandLogTable);
