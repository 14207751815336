import { jsx as _jsx } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { DateTimeLabel } from '../../../../widgets/common';
import { PreStatusLabel, PreUrgencyLabel } from '../../../../widgets/preActionItems';
import { SensorId } from '../../../../widgets/sensor';
import { translateByKey } from '../../../features/shared';
import { highlightRow } from '../../../helper/table';
export var config = function (visibleState, onViewPreActionItem) {
    return {
        columns: [
            {
                name: 'facility_name',
                visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
                title: 'Facility',
                sortable: false,
            },
            {
                name: 'title',
                visible: _has(visibleState, 'title') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.title : true,
                title: 'Title',
                sortable: false,
                component: function (row) {
                    return translateByKey(row, 'translates.en.title.text', row.title);
                },
            },
            {
                name: 'sensor_id',
                visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true });
                },
            },
            {
                name: 'updated_at',
                visible: _has(visibleState, 'updated_at') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.updated_at : true,
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "pre_ai_widget_".concat(row.id), dateTime: row.updated_at, timeBreak: true });
                },
            },
            {
                title: 'Urgency',
                name: 'urgency',
                visible: _has(visibleState, 'urgency') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.urgency : true,
                sortable: false,
                component: function (row) {
                    return _jsx(PreUrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                title: 'Status',
                name: 'status',
                visible: _has(visibleState, 'status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.status : true,
                sortable: false,
                component: function (row) {
                    return _jsx(PreStatusLabel, { status: +row.status, isTech: row.source === 'installapp' });
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(Button, { onClick: function (ev) {
                            onViewPreActionItem(row.id);
                            highlightRow(ev);
                        }, color: "primary", size: "sm", children: "View" }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: true,
        hideTotalLabel: true,
    };
};
