// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.startupMode-wrap {
  padding: 10px;
}
.startupMode-wrap svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: #d93131;
}
.startupMode-wrap p {
  margin-bottom: 10px;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/topBar/StartupMode.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAEJ;AAAE;EACE,mBAAA;EACA,eAAA;AAEJ","sourcesContent":[".startupMode-wrap {\n  padding: 10px;\n  & svg {\n    width: 20px;\n    height: 20px;\n    margin-right: 6px;\n    fill: #d93131;\n  }\n  & p {\n    margin-bottom: 10px;\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
