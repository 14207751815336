import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import AnalystNotesGroupSelect from '../../../shared/components/analystNotes/AnalystNotesGroupSelect';
import { INSTALLATION_POINT_GROUP, MULTI_GROUP, NODE_GROUP, selectNote, selectSelectedGroup, useAnalystNotesModalsActions, } from '../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import { useComponentsQuery } from '../../services/useComponentsQuery';
import { useEquipmentsQuery } from '../../services/useEquipmentsQuery';
import { useFacilitiesQuery } from '../../services/useFacilitiesQuery';
import { useLocationsQuery } from '../../services/useLocationsQuery';
import CustomerSelectAnalystNotes from './components/CustomerSelectAnalystNotes';
var SelectsGroup = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var note = selectNote();
    var customer = note.customer, facility = note.facility, locationsOrigin = note.locations, equipmentsOrigin = note.equipments, componentsOrigin = note.components;
    var location_ids = useMemo(function () { var _a; return (_a = locationsOrigin === null || locationsOrigin === void 0 ? void 0 : locationsOrigin.map(function (location) { return location.id; })) !== null && _a !== void 0 ? _a : null; }, [locationsOrigin]);
    var equipment_ids = useMemo(function () { var _a; return (_a = equipmentsOrigin === null || equipmentsOrigin === void 0 ? void 0 : equipmentsOrigin.map(function (equipment) { return equipment.id; })) !== null && _a !== void 0 ? _a : null; }, [equipmentsOrigin]);
    var components = useMemo(function () { var _a; return (_a = componentsOrigin === null || componentsOrigin === void 0 ? void 0 : componentsOrigin.map(function (component) { return component.name; })) !== null && _a !== void 0 ? _a : null; }, [componentsOrigin]);
    var selectedGroup = selectSelectedGroup();
    var _j = useAnalystNotesModalsActions(), setFacility = _j.setFacility, setLocations = _j.setLocations, setEquipments = _j.setEquipments, setComponents = _j.setComponents, setInstallationPointId = _j.setInstallationPointId, setNode = _j.setNode;
    var _k = useFacilitiesQuery({ customerId: (_a = customer === null || customer === void 0 ? void 0 : customer.id) !== null && _a !== void 0 ? _a : null }), facilitiesData = _k.data, facilitiesIsLoading = _k.isLoading, facilitiesIsFetching = _k.isFetching;
    var _l = useLocationsQuery({ facilityId: (_b = facility === null || facility === void 0 ? void 0 : facility.id) !== null && _b !== void 0 ? _b : null }), locationsData = _l.data, locationsIsLoading = _l.isLoading, locationsIsFetching = _l.isFetching;
    var _m = useEquipmentsQuery({ locationIds: location_ids }), equipmentsData = _m.data, equipmentsIsLoading = _m.isLoading, equipmentsIsFetching = _m.isFetching;
    var _o = useComponentsQuery({
        customerId: (_c = customer === null || customer === void 0 ? void 0 : customer.id) !== null && _c !== void 0 ? _c : null,
        facilityId: (_d = facility === null || facility === void 0 ? void 0 : facility.id) !== null && _d !== void 0 ? _d : null,
        locationIds: location_ids !== null && location_ids !== void 0 ? location_ids : null,
        equipmentIds: equipment_ids !== null && equipment_ids !== void 0 ? equipment_ids : null,
    }), componentsData = _o.data, componentsIsLoading = _o.isLoading, componentsIsFetching = _o.isFetching;
    var facilities = facilitiesData.facilities;
    var locations = locationsData.locations;
    var equipments = equipmentsData.equipments;
    var componentsList = componentsData.components;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(AnalystNotesGroupSelect, {}) }), selectedGroup === MULTI_GROUP ? (_jsxs(Row, { children: [_jsx(CustomerSelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Facility:" }), _jsx(Select, { isClearable: true, placeholder: "Select Facility", classNamePrefix: "customSelectStyleDefault", name: "analystNotesFacilitySelector", id: "analystNotesFacilitySelector", isLoading: facilitiesIsLoading || facilitiesIsFetching, value: ((_e = facilities.find(function (_a) {
                                    var facility_id = _a.facility_id;
                                    return facility_id === (facility === null || facility === void 0 ? void 0 : facility.id);
                                })) === null || _e === void 0 ? void 0 : _e.name)
                                    ? {
                                        label: (_f = facilities.find(function (_a) {
                                            var facility_id = _a.facility_id;
                                            return facility_id === (facility === null || facility === void 0 ? void 0 : facility.id);
                                        })) === null || _f === void 0 ? void 0 : _f.name,
                                        value: facility === null || facility === void 0 ? void 0 : facility.id,
                                    }
                                    : null, onChange: function (event) {
                                    var facility = event
                                        ? {
                                            id: event.value,
                                            name: event.label,
                                        }
                                        : null;
                                    setFacility(facility);
                                    setLocations(null);
                                    setEquipments(null);
                                    setComponents(null);
                                }, options: facilities.map(function (_a) {
                                    var name = _a.name, facility_id = _a.facility_id;
                                    return {
                                        label: name,
                                        value: facility_id,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Location:" }), _jsx(Select, { isMulti: true, placeholder: "Select Locations", classNamePrefix: "customSelectStyleDefault", name: "analystNotesLocationSelector", id: "analystNotesLocationSelector", isLoading: locationsIsLoading || locationsIsFetching, value: (location_ids !== null && location_ids !== void 0 ? location_ids : []).map(function (locationId) {
                                    var _a;
                                    return {
                                        label: (_a = locations.find(function (location) { return location.location_id === locationId; })) === null || _a === void 0 ? void 0 : _a.name,
                                        value: locationId,
                                    };
                                }), onChange: function (data) {
                                    setLocations(data.map(function (el) {
                                        return {
                                            id: el.value,
                                            name: el.label,
                                        };
                                    }));
                                    setEquipments(null);
                                    setComponents(null);
                                }, options: locations.map(function (_a) {
                                    var name = _a.name, location_id = _a.location_id;
                                    return {
                                        label: name,
                                        value: location_id,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Equipment:" }), _jsx(Select, { isMulti: true, placeholder: "Select Equipments", classNamePrefix: "customSelectStyleDefault", name: "analystNotesEquipmentSelector", id: "analystNotesEquipmentSelector", isLoading: equipmentsIsLoading || equipmentsIsFetching, value: (equipment_ids !== null && equipment_ids !== void 0 ? equipment_ids : []).map(function (equipmentId) {
                                    var _a;
                                    return {
                                        label: (_a = equipments.find(function (equipment) { return equipment.equipment_id === equipmentId; })) === null || _a === void 0 ? void 0 : _a.name,
                                        value: equipmentId,
                                    };
                                }), onChange: function (data) {
                                    setEquipments(data.map(function (el) {
                                        return {
                                            id: el.value,
                                            name: el.label,
                                        };
                                    }));
                                    setComponents(null);
                                }, options: equipments.map(function (_a) {
                                    var name = _a.name, equipment_id = _a.equipment_id;
                                    return {
                                        label: name,
                                        value: equipment_id,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Component Unit:" }), _jsx(Select, { isMulti: true, placeholder: "Select Components", classNamePrefix: "customSelectStyleDefault", name: "analystNotesComponentSelector", id: "analystNotesComponentSelector", isLoading: componentsIsLoading || componentsIsFetching, value: (components !== null && components !== void 0 ? components : []).map(function (component) {
                                    var _a;
                                    return {
                                        label: (_a = componentsList.find(function (el) { return el.component === component; })) === null || _a === void 0 ? void 0 : _a.name,
                                        value: component,
                                    };
                                }), onChange: function (data) {
                                    setComponents(data.map(function (el) {
                                        return {
                                            name: el.value,
                                        };
                                    }));
                                }, options: componentsList.map(function (_a) {
                                    var name = _a.name, component = _a.component;
                                    return {
                                        label: name,
                                        value: component,
                                    };
                                }) })] })] })) : (_jsx(_Fragment, {})), selectedGroup === INSTALLATION_POINT_GROUP ? (_jsxs(Row, { children: [_jsx(CustomerSelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Installation Point:" }), _jsx(Input, { type: "number", min: 0, value: (_g = note.installationPointId) !== null && _g !== void 0 ? _g : '', onChange: function (ev) {
                                    setInstallationPointId(ev.target.value ? +ev.target.value : null);
                                } })] })] })) : (_jsx(_Fragment, {})), selectedGroup === NODE_GROUP ? (_jsxs(Row, { children: [_jsx(CustomerSelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Node:" }), _jsx(Input, { type: "number", min: 0, value: (_h = note.node_id) !== null && _h !== void 0 ? _h : '', onChange: function (ev) {
                                    setNode(ev.target.value ? +ev.target.value : null);
                                } })] })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(SelectsGroup);
