// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggestedLevels-analyst-notes-content {
  margin-bottom: 5px;
}
.suggestedLevels-analyst-notes-content > div {
  padding: 2px 6px;
  background: #f1f1f1;
  border: 1px solid #d9d9d9;
  margin-top: 5px;
}
.dark-layout .suggestedLevels-analyst-notes-content > div {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/suggestedLevel/components/AnalystNotesContent/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;AAEJ;AADI;EACE,mBAAA;EACA,yBAAA;AAGN","sourcesContent":[".suggestedLevels-analyst-notes-content {\n  margin-bottom: 5px;\n  & > div {\n    padding: 2px 6px;\n    background: #f1f1f1;\n    border: 1px solid #d9d9d9;\n    margin-top: 5px;\n    .dark-layout & {\n      background: #1e222d;\n      border: 1px solid #3e3f4f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
