import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var equipmentsKey = function (zone, area) { return ['equipments', zone, area]; };
var equipmentsFn = function (_a) {
    var _b;
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/zone/".concat(queryKey[1], "/equipments"), {
        query: {
            selected_area: (_b = queryKey[2]) !== null && _b !== void 0 ? _b : [],
        },
    });
};
export var useEquipmentsQuery = function (_a) {
    var zone = _a.zone, area = _a.area;
    return useQuery({
        queryKey: equipmentsKey(zone, area),
        queryFn: equipmentsFn,
        select: function (_a) {
            var data = _a.data;
            return {
                equipments: data !== null && data !== void 0 ? data : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!zone && !!area,
    });
};
