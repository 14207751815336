import { useEffect } from 'react';
var useSetDocumentTitle = function (installationPointName, setDocumentTitle) {
    useEffect(function () {
        if (!installationPointName) {
            return;
        }
        setDocumentTitle("Pre-Action Item for ".concat(installationPointName !== null && installationPointName !== void 0 ? installationPointName : ''));
    }, [installationPointName]);
};
export default useSetDocumentTitle;
