import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { onChangeCost, onChangeSortField } from "../../../../features/watchersStatistic/costAvoidedReport";
var Filters = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var filters = state.filters;
    var isMoreThen25 = filters.isMoreThen25, sortField = filters.sortField;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex flex-column me-5", children: [_jsx(FormGroup, { className: "d-flex align-items-center", check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "sort_field", value: "date_created", checked: sortField === 'date_created', onChange: function (ev) {
                                        return onChangeSortField(state, dispatch, ev.target.value);
                                    } }), "Date Created"] }) }), _jsx(FormGroup, { className: "d-flex align-items-center", check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "sort_field", value: "date_updated", checked: sortField === 'date_updated', onChange: function (ev) {
                                        return onChangeSortField(state, dispatch, ev.target.value);
                                    } }), "Date Updated"] }) })] }), _jsx(FormGroup, { className: "d-flex align-items-center", check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", value: "date_updated", checked: isMoreThen25, onChange: function () { return onChangeCost(state, dispatch); } }), "Cost Avoided more then 25000 $"] }) })] }));
};
export default memo(Filters);
