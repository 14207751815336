var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onFilterChange = function (state, dispatch, filter) {
    var _a;
    var config = state.config, activeFilters = state.activeFilters;
    var changedActiveFilters = __assign(__assign({}, activeFilters), (_a = {}, _a[filter] = +!activeFilters[filter], _a));
    if (filter === 'hide_on_bed_ai' && changedActiveFilters[filter] === 1) {
        changedActiveFilters['hide_ai'] = 1;
    }
    dispatch({
        type: 'setState',
        state: {
            activeFilters: changedActiveFilters,
            config: __assign(__assign({}, config), { loader: true, filtersData: __assign(__assign({}, config.filtersData), { activeFilters: changedActiveFilters }) }),
        },
    });
};
export var onFilterLevelChange = function (state, dispatch, level) {
    var config = state.config, activeFilters = state.activeFilters;
    var newLevel;
    if (activeFilters.level === '') {
        newLevel = level === 'caution' ? 'warning' : 'caution';
    }
    else {
        if (activeFilters.level === level) {
            newLevel = level === 'caution' ? 'warning' : 'caution';
        }
        else {
            newLevel = '';
        }
    }
    var changedActiveFilters = __assign(__assign({}, activeFilters), { level: newLevel });
    dispatch({
        type: 'setState',
        state: {
            activeFilters: changedActiveFilters,
            config: __assign(__assign({}, config), { loader: true, filtersData: __assign(__assign({}, config.filtersData), { activeFilters: changedActiveFilters }) }),
        },
    });
};
export var onRefreshClick = function (state, dispatch, endpoint) {
    var config = state.config, activeFilters = state.activeFilters;
    endpoint().then(function (resp) {
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { filtersData: {
                        activeFilters: activeFilters,
                        isRefreshInProgress: resp.success,
                    } }),
            },
        });
        if (resp.success) {
            return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
        }
        toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
