import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
var DEFAULT_DIRECTION = 0;
var CompletionReportAIWrapper = function (_a) {
    var children = _a.children, localStorageKey = _a.localStorageKey;
    var _b = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _b.isContentVisible, storageStateMemory = _b.storageStateMemory, setIsContentVisible = _b.setIsContentVisible, onSaveAsDefaultSize = _b.onSaveAsDefaultSize;
    return (_jsxs("div", { className: "ai-on-equipment default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsx("div", { className: "default-ai-block-header-title", children: "Completion Reports" }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), isContentVisible ? _jsx("div", { className: "default-ai-block-content", children: children }) : _jsx(_Fragment, {})] }));
};
export default memo(CompletionReportAIWrapper);
