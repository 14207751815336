// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyst-note-commentBlock {
  margin-top: 10px;
}
.analyst-note-commentBlock tbody:first-child {
  border-top: 1px solid #3e3f4f !important;
}
.analyst-note-commentBlock tbody tr {
  border-top: none !important;
  border-bottom: 1px solid #3e3f4f;
}
.analyst-note-commentBlock .analyst-note-username {
  white-space: nowrap;
  margin-right: 10px;
}
.analyst-note-commentBlock .analyst-note-comment {
  width: 100%;
}
.analyst-note-commentBlock .analyst-note-comment img {
  max-width: 15%;
  cursor: pointer;
}
.analyst-note-commentBlock .analyst-note-readingType {
  white-space: nowrap;
}
.analyst-note-commentBlock .analyst-note-btns {
  display: flex;
  justify-content: space-between;
}
.analyst-note-commentBlock .analyst-note-btns .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.analyst-note-commentBlock .analyst-note-btns .btn:last-child {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./../src/modals/changeAlarmLevels/components/tabs/analystNotesTab/components/CommentsBlock.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACI;EACE,wCAAA;AACN;AACI;EACE,2BAAA;EACA,gCAAA;AACN;AAEE;EACE,mBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,WAAA;AAAJ;AACI;EACE,cAAA;EACA,eAAA;AACN;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,aAAA;EACA,8BAAA;AAAJ;AACI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACN;AAAM;EACE,gBAAA;AAER","sourcesContent":[".analyst-note-commentBlock {\n  margin-top: 10px;\n  & tbody {\n    &:first-child {\n      border-top: 1px solid #3e3f4f !important;\n    }\n    & tr {\n      border-top: none !important;\n      border-bottom: 1px solid #3e3f4f;\n    }\n  }\n  & .analyst-note-username {\n    white-space: nowrap;\n    margin-right: 10px;\n  }\n  & .analyst-note-comment {\n    width: 100%;\n    img {\n      max-width: 15%;\n      cursor: pointer;\n    }\n  }\n  & .analyst-note-readingType {\n    white-space: nowrap;\n  }\n  & .analyst-note-btns {\n    display: flex;\n    justify-content: space-between;\n    & .btn {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      width: 30px;\n      height: 30px;\n      &:last-child {\n        margin-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
