import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { stripHtml } from '../../../../helper/dom';
import { getSearchWordsFromTextAndCloses, highlightSearchString, onChangeIsOpenSearchFrame, onChangeSearch, setLoader, } from '../../features';
import './SearchFrame.scss';
var SearchFrame = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isOpenSearchFrame = state.isOpenSearchFrame, loaderSearchFrame = state.loaderSearchFrame, searchFrameData = state.searchFrameData, searchInput = state.searchInput;
    var navigate = useNavigate();
    useEffect(function () {
        if (!searchInput || searchInput.length < 3) {
            return;
        }
        var fetchSearchFrame = function () {
            setLoader(state, dispatch, 'loaderSearchFrame', true);
            Endpoints.remastered
                .getWhatsNewSearch({
                query: {
                    systemType: selectedSystemType,
                    search: searchInput.trim(),
                },
            })
                .then(function (response) {
                if (response) {
                    dispatch({
                        type: 'setState',
                        state: {
                            searchFrameData: response.data,
                            loaderSearchFrame: false,
                        },
                    });
                }
            });
        };
        var debouncedFetchSearchFrame = debounce(fetchSearchFrame, 300);
        debouncedFetchSearchFrame();
        return function () {
            debouncedFetchSearchFrame.cancel();
        };
    }, [searchInput]);
    return (_jsx(_Fragment, { children: isOpenSearchFrame ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "search-bg-frame", onClick: function () { return onChangeIsOpenSearchFrame(false, dispatch); } }), _jsxs("div", { className: "search-frame", children: [_jsx(Input, { type: "search", placeholder: "Search by keywords and phrases...", value: searchInput, onChange: function (e) { return onChangeSearch(e.target.value, dispatch); } }), loaderSearchFrame && searchInput.length > 2 ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100 py-5", children: _jsx(Loader, { variant: "loader-md" }) })) : searchInput.length > 2 ? (_jsx(_Fragment, { children: searchFrameData.length ? (_jsx("div", { className: "search-result", children: searchFrameData.map(function (item, index) { return (_jsxs("div", { className: "search-release", children: [_jsx("b", { children: moment(item.release_date).format('YYYY/MM/DD') }), item.articles.map(function (el, i) { return (_jsxs("div", { className: "search-article", onClick: function () {
                                                onChangeIsOpenSearchFrame(false, dispatch);
                                                dispatch({
                                                    type: 'setState',
                                                    state: {
                                                        activeRelease: {
                                                            id: +item.id,
                                                            date: moment(item.release_date).format('MM/DD/YYYY') || null,
                                                            countNotViewed: +item.count_not_viewed,
                                                        },
                                                    },
                                                });
                                                navigate('/whats-new/' +
                                                    moment(item.release_date).format('MM/DD/YYYY').replaceAll('/', '-') +
                                                    '#' +
                                                    stripHtml(el.title).trim().replaceAll(' ', '_'));
                                            }, children: [_jsx("p", { dangerouslySetInnerHTML: {
                                                        __html: getSearchWordsFromTextAndCloses(stripHtml(el.description), searchInput, 5, 5),
                                                    } }), _jsx("h3", { dangerouslySetInnerHTML: {
                                                        __html: highlightSearchString(stripHtml(el.title), searchInput),
                                                    } })] }, 'article_' + i)); })] }, 'release_' + index)); }) })) : (_jsxs("div", { className: "search-no-results", children: [_jsx("span", { children: "No results for" }), " ", _jsxs("b", { children: ["\u201C", searchInput, "\u201C"] })] })) })) : (_jsx(_Fragment, {}))] })] })) : (_jsx(_Fragment, {})) }));
};
export default SearchFrame;
