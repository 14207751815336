var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get, set as _set } from "lodash";
import React, { memo, useContext, useEffect, useMemo, useState, } from "react";
import { FaCheckCircle, FaExclamation, FaInfo, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { Badge, Col, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import { shallow } from "zustand/shallow";
import Endpoints from "../../../../../api/endpoints/endpoints";
import { METRIC_KEY, READING_TYPE_TACHOMETER, READING_TYPES, SYSTEM_TYPE_REMASTERED } from "../../../../../constants";
import SensorsByEquipment from "../../../../../shared/components/SensorsByEquipment";
import { SystemTypeContext } from "../../../../../shared/context/SystemTypeContext";
import { isDistancePinSensor, isPinSensor } from "../../../../../shared/features/charts/shared";
import { ChartFeaturesStore } from "../../../../../shared/store/charts/chartsContent/ChartFeaturesStore";
import { InstallationPointStore } from "../../../../../shared/store/charts/chartsContent/InstallationPointStore";
import { useConfirmModalActions } from "../../../../../shared/store/global/useConfirmModalStore";
import { selectMeasure } from "../../../../../shared/store/global/useGlobalStore";
import RichTextEditor from "../../../../../widgets/TextEditor/TextEditor";
import { LossPacketsAlert } from "../../../../../widgets/common";
import { isSupportZAxis } from "../../../../../widgets/sensor";
import AxisType from "../../AxisType";
import Chart from "../../Chart";
import { getAvailableChartIdentifiersByReadingType } from "../../helpers";
import "./AlertLevelsTab.scss";
import AlertLevelBlock from "./components/AlertLevelBlock";
var fetchComments = function (installationPointId, readingTypeId, setComments, setSmallLoader, selectedSystemType) {
    setSmallLoader(true);
    Endpoints[selectedSystemType]
        .getAlertConditionComments({
        query: {
            installationPointIds: Array.isArray(installationPointId)
                ? installationPointId
                : [installationPointId],
            readingTypeIds: Array.isArray(readingTypeId) ? readingTypeId : [readingTypeId],
        },
    })
        .then(function (resp) {
        if (resp) {
            setComments(resp.data);
        }
        setSmallLoader(false);
    });
};
var fetchCustomerLevels = function (installationPointId, readingTypeId, setCustomerLevels) {
    Endpoints.remastered
        .getCustomerAlertConditions(installationPointId, readingTypeId)
        .then(function (resp) {
        if (resp) {
            setCustomerLevels(_get(resp.data, [installationPointId, readingTypeId]));
        }
    });
};
// @ts-ignore
var AlertLevelsTab = function (_a) {
    var _b, _c;
    var saveClicked = _a.saveClicked, setSmallLoader = _a.setSmallLoader;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setStates: state.setStates,
    }); }, shallow), changeAlertConditions = _d.changeAlertConditions, setStates = _d.setStates, readingTypeId = changeAlertConditions.readingTypeId;
    var _e = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
        packetLossPercent: state.packetLossPercent,
    }); }, shallow), readingTypes = _e.readingTypes, installationPoint = _e.installationPoint, overlayInstallationPoints = _e.overlayInstallationPoints, packetLossPercent = _e.packetLossPercent, equipment = _get(installationPoint, 'equipment') || {};
    var _f = useState(installationPoint.id), selectedInstallationPointId = _f[0], setSelectedInstallationPointId = _f[1];
    var _g = useState([readingTypeId]), selectedReadingTypeIds = _g[0], setSelectedReadingTypeIds = _g[1];
    var _h = useState('caution'), selectedLevel = _h[0], setSelectedLevel = _h[1];
    var _j = useState(false), forAllSensors = _j[0], setForAllSensors = _j[1];
    var _k = useState(false), forOverlaid = _k[0], setForOverlaid = _k[1];
    var _l = useState({
        readingTypeId: changeAlertConditions.readingTypeId,
        conditions: changeAlertConditions.conditions,
        levels: changeAlertConditions.currentLevel,
        settings: changeAlertConditions.settings || {},
        fftSettings: changeAlertConditions.fftSettings || {},
        isRmsOd: changeAlertConditions.isRmsOd || { caution: 0, warning: 0 },
        comment_id: null,
        comment: '',
    }), alertConditionsState = _l[0], setAlertConditionsState = _l[1];
    var _m = useState([]), comments = _m[0], setComments = _m[1];
    var _o = useState({
        caution: null,
        warning: null,
    }), customerLevels = _o[0], setCustomerLevels = _o[1];
    var _p = useState(false), isVisibleCustomerLevels = _p[0], setIsVisibleCustomerLevels = _p[1];
    var _q = useState(0), savedCount = _q[0], setSavedCount = _q[1];
    var onChangeAlertConditionState = function (path, value) {
        var changedAlertConditionsState = JSON.parse(JSON.stringify(alertConditionsState));
        _set(changedAlertConditionsState, path, value);
        setAlertConditionsState(__assign({ readingTypeId: readingTypeId }, changedAlertConditionsState));
        return;
    };
    var chartIdentifiers = ((_b = readingTypes[readingTypeId]) === null || _b === void 0 ? void 0 : _b.isImpactVue)
        ? [readingTypeId]
        : getAvailableChartIdentifiersByReadingType(readingTypeId);
    var defaultAiMessage = useMemo(function () {
        if (isDistancePinSensor(installationPoint)) {
            if (isPinSensor(installationPoint)) {
                return 'Walking pin detected on the Intralox conveyor. Please inspect immediately';
            }
            return 'A belt sag event was detected on the Intralox conveyor. Please inspect immediately.';
        }
        return "A {condition} ".concat(_get(READING_TYPES, [readingTypeId, 'title'], '').toLowerCase(), " level ({readingValue}) has been detected in a long term. Please inspect for the root cause");
    }, [installationPoint]);
    var triggerEachReading = useMemo(function () {
        return (+_get(alertConditionsState, 'settings.caution.trigger_time', 0) > 0 ||
            +_get(alertConditionsState, 'settings.warning.trigger_time', 0) > 0);
    }, [alertConditionsState.settings]);
    var measure = selectMeasure();
    var setConfirmData = useConfirmModalActions().setConfirmData;
    useEffect(function () {
        if (saveClicked > 0) {
            var callback_1 = function (resp) {
                setSmallLoader(false);
                if (resp.success) {
                    toast.success(resp.message || 'Successfully saved', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    if (selectedInstallationPointId === installationPoint.id) {
                        setStates({
                            changeAlertConditions: __assign(__assign({}, alertConditionsState), { selectedReadingTypeIds: selectedReadingTypeIds, isVisibleModal: true, shouldUpdate: true, storedLevels: alertConditionsState.levels, currentLevel: alertConditionsState.levels }),
                        });
                    }
                    return setSavedCount(1);
                }
                toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            };
            var processSave_1 = function () {
                setSmallLoader(true);
                var data = _cloneDeep(alertConditionsState);
                if (forOverlaid) {
                    data.overlayInstallationPoints = overlayInstallationPoints.map(function (overlayInstallationPoint) { return overlayInstallationPoint.id; });
                }
                return Endpoints[selectedSystemType]
                    .saveAlertConditions(selectedInstallationPointId, readingTypeId, {
                    data: data,
                    selectedReadingTypeIds: selectedReadingTypeIds,
                    setForAllEquipment: +forAllSensors,
                    setForAllComponents: +forOverlaid,
                    selected_facility_metric: METRIC_KEY[measure],
                })
                    .then(function (resp) {
                    callback_1(resp);
                });
            };
            var isAutoAi = +_get(alertConditionsState, 'settings.caution.is_ai') === 1 ||
                +_get(alertConditionsState, 'settings.warning.is_ai') === 1;
            var isAutoPreAi = +_get(alertConditionsState, 'settings.caution.is_pre_ai') === 1 ||
                +_get(alertConditionsState, 'settings.warning.is_pre_ai') === 1;
            var showConfirmationModal = comments.length > 0 || selectedReadingTypeIds.length > 1 || isAutoAi || isAutoPreAi;
            if (showConfirmationModal) {
                var textConfirmationModal = '';
                if (comments.length > 0) {
                    textConfirmationModal +=
                        'These alarm levels were set upon request, are you sure you want to confirm the action? </br>';
                }
                if (selectedReadingTypeIds.length > 1) {
                    var axesLabel = selectedReadingTypeIds.map(function (selectedReadingTypeId) { var _a; return (_a = READING_TYPES[selectedReadingTypeId]) === null || _a === void 0 ? void 0 : _a.axisShort; });
                    textConfirmationModal += "Are you sure you want to set the same levels for the ".concat(axesLabel.join(', '), " axes? </br>");
                }
                if (isAutoAi || isAutoPreAi) {
                    textConfirmationModal += "This reading type has settings for creation ".concat(isAutoAi ? 'Auto AI' : '', " ").concat(isAutoAi && isAutoPreAi ? ' and ' : '', " ").concat(isAutoPreAi ? 'Auto Pre-AI' : '', ", are you sure you want to change the alarm levels?");
                }
                setConfirmData({
                    isVisible: true,
                    textConfirmationModal: textConfirmationModal,
                    onConfirm: function () {
                        setConfirmData({
                            isVisible: false,
                        });
                        processSave_1();
                    },
                });
                return;
            }
            processSave_1();
        }
    }, [saveClicked]);
    useEffect(function () {
        fetchComments(installationPoint.id, selectedReadingTypeIds[0], setComments, setSmallLoader, selectedSystemType);
    }, [installationPoint.id, selectedReadingTypeIds]);
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
            fetchCustomerLevels(installationPoint.id, selectedReadingTypeIds[0], setCustomerLevels);
        }
    }, [installationPoint.id]);
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
            fetchCustomerLevels(installationPoint.id, selectedReadingTypeIds[0], setCustomerLevels);
        }
        setAlertConditionsState(__assign(__assign({}, alertConditionsState), { readingTypeId: selectedReadingTypeIds[0] || alertConditionsState.readingTypeId }));
    }, [selectedReadingTypeIds]);
    useEffect(function () {
        setAlertConditionsState(__assign(__assign({}, alertConditionsState), { levels: __assign(__assign({}, alertConditionsState.levels), { cautionCustomer: isVisibleCustomerLevels ? customerLevels.caution : null, warningCustomer: isVisibleCustomerLevels ? customerLevels.warning : null }) }));
    }, [isVisibleCustomerLevels]);
    return (_jsxs(_Fragment, { children: [(triggerEachReading || comments.length > 0) && (_jsxs(Row, { className: "g-0 mb-2", children: [triggerEachReading && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "danger", children: _jsx(FaInfo, {}) }), _jsxs("span", { className: "fw-bold align-bottom ms-2", children: [' ', "This option will be trigger each reading for check alarm"] })] })), comments.length > 0 && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "warning", children: _jsx(FaExclamation, {}) }), _jsxs("span", { className: "fw-bold align-bottom ms-2", children: [' ', "This equipment has an active analyst notes for alert levels"] })] }))] })), (_get(alertConditionsState, 'isRmsOd.caution') === 1 ||
                _get(alertConditionsState, 'isRmsOd.warning') === 1) && (_jsx(LossPacketsAlert, { lossPacketsPercent: +packetLossPercent })), _jsxs(Row, { children: [_jsxs(Col, { md: 6, children: [_jsx(SensorsByEquipment, { selectedInstallationPointId: selectedInstallationPointId, setSelectedInstallationPointId: setSelectedInstallationPointId, equipment: equipment }), chartIdentifiers.length > 1 && (_jsx(AxisType, { selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: setSelectedReadingTypeIds, chartIdentifiers: chartIdentifiers, isSupportZAxis: isSupportZAxis((_c = installationPoint.sensor) === null || _c === void 0 ? void 0 : _c.version_type) })), _jsx(AlertLevelBlock, { alertConditionsState: alertConditionsState, level: "caution", customerLevel: customerLevels ? customerLevels.caution : null, selectedLevel: selectedLevel, setSelectedLevel: setSelectedLevel, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: onChangeAlertConditionState }), _jsx(AlertLevelBlock, { alertConditionsState: alertConditionsState, level: "warning", customerLevel: customerLevels ? customerLevels.warning : null, selectedLevel: selectedLevel, setSelectedLevel: setSelectedLevel, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: onChangeAlertConditionState }), _jsxs(_Fragment, { children: [readingTypeId !== READING_TYPE_TACHOMETER && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forAllSensors, onChange: function () { return setForAllSensors(!forAllSensors); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all components on equipment" })] }) }) })), readingTypeId !== READING_TYPE_TACHOMETER &&
                                        Object.keys(overlayInstallationPoints).length !== 0 && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forOverlaid, onChange: function () { return setForOverlaid(!forOverlaid); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all overlaid components" })] }) }) }))] })] }), _jsxs(Col, { md: 6, children: [Object.values(alertConditionsState.settings).map(function (setting, index) { return (_jsx(React.Fragment, { children: +_get(setting, 'is_ai', 0) === 1 || +_get(setting, 'is_pre_ai', 0) === 1 ? (_jsx(Row, { className: "g-0 mt-1 mb-2", children: _jsxs(Col, { md: 12, children: [_jsxs("span", { children: [_jsxs("b", { children: ["Action ", "item's", " description for ", Object.keys(alertConditionsState.settings)[index], ' ', "level."] }), ' ', "Supported variables for action ", "item's", " description", ' ', _jsx(Badge, { className: "align-top ms-2", style: { minWidth: '10px' }, color: "info", id: "ai_description_variables_".concat(index), children: _jsx(FaInfo, {}) })] }), _jsx(UncontrolledTooltip, { placement: "right", target: "ai_description_variables_".concat(index), children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Variable" }), _jsx("th", { children: "Explanation" })] }) }), _jsxs("tbody", { children: [_jsxs("tr", { className: "border-bottom", children: [_jsx("td", { children: '{condition}' }), _jsxs("td", { children: ['high', " or ", 'low'] })] }), _jsxs("tr", { children: [_jsx("td", { children: '{readingValue}' }), _jsx("td", { children: "Value of triggered reading" })] })] })] }) }), _jsx("div", { className: "mt-2", children: _jsx(RichTextEditor, { customerId: installationPoint.customer_id, content: _get(setting, 'ai_description') || defaultAiMessage, onChange: function (content) {
                                                        return onChangeAlertConditionState(['settings', Object.keys(alertConditionsState.settings)[index]], __assign(__assign({}, setting), { ai_description: content }));
                                                    } }) })] }) })) : (_jsx(_Fragment, {})) }, "ai_setting_description_".concat(index))); }), _jsx(Chart, { chartIdentifiers: chartIdentifiers, selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: setSelectedReadingTypeIds, alertConditionsState: alertConditionsState, shouldRefresh: savedCount > 0, selectedInstallationPointId: selectedInstallationPointId })] })] })] }));
};
export default memo(AlertLevelsTab);
