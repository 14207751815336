var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer } from 'react';
import { FaEye } from 'react-icons/fa';
import { Alert, Button, ButtonGroup, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import Loader from '../../../shared/components/Loader';
import DataTable from '../../../shared/components/shared/Table/Table';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { onViewInstallationPoint } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { highlightRow, setLoader } from '../../../shared/helper/table';
import '../../../shared/styles/structure/structure.scss';
import { buildTree, onZoneChange } from '../../features/structure';
import { useResetStructure } from '../../hooks/structure/useResetStructure';
var initialState = function (onViewInstallationPoint) {
    return {
        structure: [],
        zone: '',
        selectedArea: null,
        selectedAreas: [],
        config: {
            columns: [
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: false,
                },
                {
                    name: 'count_points',
                    title: 'Count sensors',
                    sortable: false,
                },
                {
                    name: 'action',
                    title: '',
                    sortable: false,
                    component: function (row, callback) {
                        return (_jsxs("div", { className: "response-ignore", children: [_jsx(ButtonGroup, { className: "ms-2", children: _jsxs(Button, { color: "success", size: "sm", onClick: function () {
                                            callback.onViewZone();
                                        }, children: [_jsx(FaEye, {}), " View Zone"] }) }), _jsx(ButtonGroup, { className: "ms-2", children: _jsxs(Button, { color: "success", size: "sm", onClick: function () {
                                            callback.onViewArea();
                                        }, children: [_jsx(FaEye, {}), " View Area"] }) }), _jsx(ButtonGroup, { className: "ms-2", children: _jsxs(Button, { color: "primary", size: "sm", onClick: function (ev) {
                                            onViewInstallationPoint(0, "&equipment=".concat(row.equipment_name));
                                            highlightRow(ev);
                                        }, children: [_jsx(FaEye, {}), " View Equipment"] }) })] }));
                    },
                },
            ],
            pagination: {
                disabled: true,
            },
            hideTotalLabel: true,
            search: '',
            disabledSearch: true,
            data: [],
            loader: true,
        },
    };
};
var fetch = function (state, dispatch, selectedFacility) {
    setLoader(state, dispatch, true);
    Endpoints.amazon
        .getFacilityStructure(selectedFacility)
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    structure: buildTree(resp.list[selectedFacility]),
                },
            });
        }
        setLoader(state, dispatch, false);
    })
        .catch(function () {
        setLoader(state, dispatch, false);
    });
};
var fetchEquipments = function (state, dispatch, selectedFacility) {
    var config = state.config, zone = state.zone, selectedArea = state.selectedArea;
    setLoader(state, dispatch, true);
    Endpoints.amazon
        .getFacilityStructureEquipments({
        query: {
            facility: selectedFacility,
            zone: zone,
            area: selectedArea ? selectedArea.area : null,
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.list, loader: false }),
                },
            });
        }
    })
        .catch(function () {
        setLoader(state, dispatch, false);
    });
};
var StructurePage = function (_a) {
    var _b = _a.selectedFacility, selectedFacility = _b === void 0 ? null : _b;
    if (!selectedFacility) {
        return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { className: "page-title", children: "Structure" }), _jsx(Row, { children: _jsx(Col, { md: 3, children: _jsx(Alert, { color: "danger", children: "Choose facility first" }) }) })] }) }) }) }));
    }
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId, params) {
        return onViewInstallationPoint(installationPointId, selectedSystemType, params);
    })), state = _c[0], dispatch = _c[1];
    var config = state.config, structure = state.structure, selectedArea = state.selectedArea, selectedAreas = state.selectedAreas, zone = state.zone;
    var loader = config.loader;
    useTitle('Structure');
    useResetStructure([selectedFacility], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedFacility);
    }, [selectedFacility]);
    useEffect(function () {
        if (selectedArea) {
            fetchEquipments(state, dispatch, selectedFacility);
        }
    }, [selectedArea]);
    return (_jsx("div", { className: "page-Structure", children: _jsx(Row, { children: _jsxs(Col, { style: { minHeight: '90vh' }, children: [_jsx("h3", { className: "page-title", children: "Structure" }), loader ? (_jsx("div", { style: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'calc(100% - 70px)',
                        }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "structure-firstLayer-container", children: structure.map(function (zone) { return (_jsxs(Button, { className: "me-3 position-relative", onClick: function () { return onZoneChange(dispatch, zone); }, color: "info", size: "sm", children: [zone.zone, zone.areas.length > 0 && (_jsx("span", { className: "structure-firstLayer-badge", children: zone.areas.length }))] }, "structure-item-firstLayer-".concat(zone.zone))); }) }), selectedAreas.length > 0 && (_jsx("div", { className: "structure-secondLayer-container mt-2", children: selectedAreas.map(function (area) { return (_jsx(Button, { className: "me-2 mb-2 ".concat(selectedAreas && JSON.stringify(area) === JSON.stringify(selectedArea) ? 'active' : ''), color: "primary", onClick: function () {
                                        dispatch({
                                            type: 'setState',
                                            state: {
                                                selectedArea: area,
                                            },
                                        });
                                    }, size: "sm", children: area.area }, "structure-item-secondLayer-".concat(area.area))); }) })), selectedArea && !loader && (_jsx("div", { className: "table_wrap", children: _jsx(DataTable, { config: config, callbacks: {
                                        onViewZone: function () { return onViewInstallationPoint(0, selectedSystemType, "&zone=".concat(zone)); },
                                        onViewArea: function () {
                                            return onViewInstallationPoint(0, selectedSystemType, "&zone=".concat(zone, "&area=").concat(selectedArea.area));
                                        },
                                    } }) }))] }))] }) }) }));
};
export default StructurePage;
