import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { ACTION_LIST } from '../../../constants';
import CountActionItemsOnFacility from '../../../shared/components/CountActionItemsOnFacility';
import LinkWithPrefix from '../../../shared/components/LinkWithPrefix';
import HighlighterWord from '../../../shared/components/shared/HighlighterWord';
import { DateTimeLabelWithUTC } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
export var sensorInfoConfig = function (highlightSearch, data, hiddenRows, isContentVisible) {
    if (hiddenRows === void 0) { hiddenRows = []; }
    var customer = {
        name: 'Customer:',
        value: hiddenRows.includes('customer') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'customer.name') ||
                        _get(data, 'customer_name') ||
                        _get(data, 'installationPoint.equipment.location.customer.name') ||
                        _get(data, 'installationPoint.equipment.location.facility.customer.name') ||
                        '---' }), _get(data, 'customer.system_version') === 'hybrid-service' ? (_jsx("span", { className: "ms-1", children: "(Hybrid)" })) : (_jsx(_Fragment, {}))] })),
        visible: !hiddenRows.includes('customer'),
    };
    var facility = {
        name: 'Facility:',
        value: hiddenRows.includes('facility') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'facility.name') ||
                        _get(data, 'installationPoint.equipment.location.facility.name') ||
                        '---' }), _jsx(CountActionItemsOnFacility, { countHardwareAis: _get(data, 'facility.countHardwareAis'), countRegularAis: _get(data, 'facility.countRegularAis') })] })),
        visible: !hiddenRows.includes('facility'),
    };
    var equipment = {
        name: 'Equipment:',
        value: hiddenRows.includes('equipment') ? (_jsx(_Fragment, {})) : (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(_get(data, 'installationPoint.id')).concat(_get(data, 'installationPointIdsOnEquipment', [])
                .map(function (el) { return '&overlay_points=' + el; })
                .toString()
                .replaceAll(',', '')), rel: "noreferrer", target: "_blank", children: _jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'installationPoint.equipment.name') || _get(data, 'equipmentInfo') || '---' }) })),
        visible: !hiddenRows.includes('equipment'),
    };
    var approximate_date = {
        name: 'Approximate date of failure:',
        value: hiddenRows.includes('approximate_date') ? (_jsx(_Fragment, {})) : (_jsx(DateTimeLabelWithUTC, { className: "fz-12 fst-italic", dateTime: _get(data, 'approximateDate') })),
        visible: !!_get(data, 'approximateDate') && !hiddenRows.includes('approximate_date'),
    };
    var sla = {
        name: 'SLA:',
        value: hiddenRows.includes('sla') ? _jsx(_Fragment, {}) : _get(data, 'sla', '---'),
        visible: _get(data, 'sla') !== null && !hiddenRows.includes('sla'),
    };
    if (!isContentVisible) {
        return [
            [customer, facility],
            [equipment, sla, approximate_date],
        ];
    }
    return [
        [
            customer,
            facility,
            {
                name: 'Facility timezone:',
                value: hiddenRows.includes('facility_timezone') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'facility.timezone.title') || '---' })),
                visible: !hiddenRows.includes('facility_timezone'),
            },
            {
                name: 'Lead analysts:',
                value: hiddenRows.includes('analyticLeads') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'customer.analyticLeads', [])
                        .map(function (el) { return el.name_first + ' ' + el.name_last; })
                        .join(', ') || '---' })),
                visible: !hiddenRows.includes('analyticLeads'),
            },
            {
                name: 'Sensor:',
                value: hiddenRows.includes('sensor') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(SensorId, { highlightSearch: highlightSearch, notShowEmptyHex: true, sensor_id: +_get(data, 'sensorId') }), ' ', "/ ", _get(data, 'sensorPartNumber')] })),
                visible: !hiddenRows.includes('sensor'),
            },
            {
                name: 'Old Sensor:',
                value: hiddenRows.includes('oldSensorId') ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(SensorId, { highlightSearch: highlightSearch, notShowEmptyHex: true, sensor_id: +_get(data, 'oldSensorId') }), ' ', "/ ", _get(data, 'oldSensorPartNumber')] })),
                visible: !!_get(data, 'oldSensorId'),
            },
            {
                name: 'Point name:',
                value: hiddenRows.includes('point_name') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'installationPoint.name') ||
                        _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, _get(data, 'equipment_type')) ||
                        '---' })),
                visible: !hiddenRows.includes('point_name'),
            },
            {
                name: 'Serial:',
                value: hiddenRows.includes('serial') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: 'serial' in data && data.serial ? data.serial.toString() : '---' })),
                visible: data.equipmentType !== ACTION_LIST.EQUIPMENT_TYPE_SENSOR &&
                    data.equipmentType !== ACTION_LIST.EQUIPMENT_TYPE_MOTE &&
                    !hiddenRows.includes('serial'),
            },
            {
                name: 'Detected By:',
                value: hiddenRows.includes('detected_by') ? _jsx(_Fragment, {}) : _get(data, 'detectedBy'),
                visible: !!_get(data, 'detectedBy') && !hiddenRows.includes('detected_by'),
            },
        ],
        [
            {
                name: 'Location:',
                value: hiddenRows.includes('location') ? (_jsx(_Fragment, {})) : (_jsx(HighlighterWord, { searchWords: highlightSearch, block: false, textToHighlight: _get(data, 'installationPoint.equipment.location.name') || _get(data, 'locationInfo') || '---' })),
                visible: !hiddenRows.includes('location'),
            },
            equipment,
            {
                name: 'Is Waites AІ:',
                value: hiddenRows.includes('is_ww_ai') ? _jsx(_Fragment, {}) : 'Yes',
                visible: _get(data, 'type') === ACTION_LIST.AI_TYPE_WW && !hiddenRows.includes('is_ww_ai'),
            },
            {
                name: 'Waites AI Type:',
                value: hiddenRows.includes('equipment_type') ? (_jsx(_Fragment, {})) : (_get(data, 'equipmentType') || ACTION_LIST.EQUIPMENT_TYPE_SENSOR),
                visible: _get(data, 'type') === ACTION_LIST.AI_TYPE_WW && !hiddenRows.includes('equipment_type'),
            },
            sla,
            approximate_date,
        ],
        [
            {
                name: 'Country:',
                value: hiddenRows.includes('country') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'equipment.location.facility.country') || _get(data, 'facility.country') })),
                visible: !hiddenRows.includes('country') &&
                    (_get(data, 'equipment.location.facility.country') || _get(data, 'facility.country')),
            },
            {
                name: 'Node:',
                value: hiddenRows.includes('node') ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: _get(data, 'installationPoint.sensor.node.serial') || _get(data, 'sensor.node.serial') || '---' })),
                visible: (!hiddenRows.includes('node') && data.equipmentType === ACTION_LIST.EQUIPMENT_TYPE_SENSOR) ||
                    data.equipmentType === ACTION_LIST.EQUIPMENT_TYPE_MOTE,
            },
            {
                name: 'Replace WW equipment:',
                value: hiddenRows.includes('replace_ww_equipment') ? (_jsx(_Fragment, {})) : (+_get(data, 'isReplaceEquipment', '') === 1 && _jsx("div", { className: "response-left", children: "Yes" })),
                visible: +_get(data, 'isReplaceEquipment', '') === 1 && !hiddenRows.includes('replace_ww_equipment'),
            },
        ],
    ];
};
