// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-ai-modal .sensor-id-wrapper {
  white-space: normal !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/watchersStatistic/PreAiStatisticTab/components/PreAiModal/index.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;AAAJ","sourcesContent":[".pre-ai-modal {\n  & .sensor-id-wrapper {\n    white-space: normal !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
