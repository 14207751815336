var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useEffect, useReducer } from 'react';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { READING_TYPES } from '../../../../../constants';
import { DateTimeLabel } from '../../../../../widgets/common';
import DataTable from '../../../../components/shared/Table/Table';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { reducer } from '../../../../helper/reducer';
import { setLoader } from '../../../../helper/table';
import RecalculationStatus from './RecalculationStatus';
var initialState = {
    config: {
        columns: [
            {
                name: 'type',
                title: 'Type',
                sortable: false,
            },
            {
                name: 'location_info',
                title: 'Location info',
                sortable: false,
            },
            {
                name: 'username',
                title: 'User',
                sortable: false,
            },
            {
                name: 'is_overwrite',
                title: 'Overwrite',
                sortable: false,
                component: function (row) {
                    return +row.is_overwrite === 1 ? 'Yes' : 'No';
                },
            },
            {
                name: 'data',
                title: 'Data',
                sortable: false,
                component: function (row) {
                    if (!row.data) {
                        return 'Not set';
                    }
                    var data = JSON.parse(row.data);
                    return (_jsx(_Fragment, { children: Object.values(data).map(function (item, index) { return (_jsxs("div", { children: [_jsxs("b", { children: [_get(READING_TYPES, [Object.keys(data)[index], 'title'], ''), ' ', _get(READING_TYPES, [Object.keys(data)[index], 'axis'], ''), " -", ' '] }), JSON.stringify(item)
                                    .replaceAll(/\{|}|"/gi, '')
                                    .replaceAll(',', ', ')
                                    .replaceAll(':', ' = ')
                                    .replaceAll('caution', 'Caution ')
                                    .replaceAll('warning', 'Warning')
                                    .replaceAll('min_', 'MIN ')] }, "".concat(Object.keys(data)[index], "_").concat(index))); }) }));
                },
            },
            {
                name: 'selected_installation_point_ids',
                title: 'Installation Points',
                sortable: false,
                component: function (row) {
                    return row.selected_installation_point_ids || 'Not set';
                },
            },
            {
                name: 'created_at',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "running_speed_log_".concat(row.id), dateTime: row.created_at });
                },
            },
            {
                name: 'status',
                title: 'Status',
                sortable: false,
                component: function (row) {
                    return _jsx(RecalculationStatus, { status: row.status });
                },
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
            disabled: true,
        },
        search: '',
        hideTotalLabel: true,
        disabledSearch: true,
        data: [],
        loader: true,
    },
};
var fetch = function (selectedSystemType, state, dispatch, savingQuery) {
    var config = state.config;
    setLoader(state, dispatch, true);
    Endpoints[selectedSystemType]
        .getAlertConditionsRecalculationLog({
        query: __assign(__assign({}, savingQuery), { pagination: config.pagination }),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var RecalculationLogTable = function (_a) {
    var savingQuery = _a.savingQuery;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var config = state.config;
    useEffect(function () {
        fetch(selectedSystemType, state, dispatch, savingQuery);
    }, []);
    return (_jsx(DataTable, { config: config, onPageChange: function () { }, onPageSizeChange: function () { }, onSort: function () { }, onSearch: function () { } }));
};
export default memo(RecalculationLogTable);
