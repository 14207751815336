import { useEffect } from 'react';
import { usePrevious } from '../../../../customHooks';
import { fftTicksPositioner, formatXAxisFFT, } from '../../../components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useUnits = function (state, chartRef, loader) {
    var setStates = ChartsStore(function (state) { return state.setStates; });
    var currentSpeed = InstallationPointStore(function (state) { return state.currentSpeed; });
    var units = state.units;
    var prevUnits = usePrevious(units);
    var prevSpeed = usePrevious(currentSpeed);
    useEffect(function () {
        if (loader) {
            return;
        }
        if ((prevUnits && prevUnits !== units) ||
            (prevSpeed && prevSpeed !== currentSpeed && units === 'Orders')) {
            chartRef.current.chart.userOptions.units = units;
            chartRef.current.chart.xAxis[0].update({
                labels: {
                    formatter: function () {
                        return formatXAxisFFT(this.chart, this.value, units, currentSpeed, units === 'CPM' ? 0 : undefined);
                    },
                },
                tickPositioner: function () {
                    return fftTicksPositioner(this.chart, this.dataMax, units, currentSpeed);
                },
            });
        }
        setStates({ chartCursorType: 'normal' });
    }, [units, currentSpeed]);
};
