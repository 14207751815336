var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useState } from "react";
import { FaPlusCircle, FaSpinner } from "react-icons/fa";
import { Button } from "reactstrap";
import { shallow } from "zustand/shallow";
import Endpoints from "../../../api/endpoints/endpoints";
import { faultFrequenciesConfig } from "../../config/faultFrequency/dataTableConfig";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import { fetch, onEdit, onRemove, onSetFaultFrequency } from "../../features/faultFrequency";
import { ChartFeaturesStore } from "../../store/charts/chartsContent/ChartFeaturesStore";
import { FftChartsStore } from "../../store/charts/chartsContent/FftChartsStore";
import { useConfirmModalActions } from "../../store/global/useConfirmModalStore";
import DataTable from "../shared/Table/Table";
var FaultFrequenciesTable = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId, installationPoint = _a.installationPoint, _b = _a.readonly, readonly = _b === void 0 ? false : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = FftChartsStore(function (state) { return ({
        setStatesFftChartsStore: state.setStatesFftChartsStore,
        faultFrequencies: state.faultFrequencies,
    }); }, shallow), setStatesFftChartsStore = _c.setStatesFftChartsStore, faultFrequencies = _c.faultFrequencies;
    var _d = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        setFaultFrequency: state.setFaultFrequency,
    }); }, shallow), setStatesChartFeaturesStore = _d.setStatesChartFeaturesStore, setFaultFrequency = _d.setFaultFrequency;
    var _e = useState(false), loader = _e[0], setLoader = _e[1];
    var _f = useState(faultFrequenciesConfig([])), config = _f[0], setConfig = _f[1];
    var setConfirmData = useConfirmModalActions().setConfirmData;
    useEffect(function () {
        fetch(config, setConfig, function () {
            return Endpoints[selectedSystemType].getFaultFrequencies({
                query: {
                    installation_point_id: selectedInstallationPointId || installationPoint.id,
                },
            });
        }, setLoader);
    }, [selectedInstallationPointId, setFaultFrequency.visible, faultFrequencies]);
    return (_jsxs("div", { children: [loader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" }), !readonly && (_jsxs(Button, { outline: true, className: "float-end mb-2", color: "primary", size: "sm", onClick: function () {
                    return onSetFaultFrequency(setStatesChartFeaturesStore, selectedInstallationPointId || installationPoint.id);
                }, children: [_jsx(FaPlusCircle, {}), " Add"] })), _jsx(DataTable, { config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { readonly: readonly }) }), callbacks: {
                    onRemove: function (id) {
                        return onRemove(id, faultFrequencies, setLoader, setConfirmData, setStatesFftChartsStore, Endpoints[selectedSystemType].deleteFaultFrequency);
                    },
                    onEdit: function (faultFrequency) {
                        return onEdit(faultFrequency, setStatesChartFeaturesStore, selectedInstallationPointId || installationPoint.id);
                    },
                }, onSearch: function () { } })] }));
};
export default memo(FaultFrequenciesTable);
