var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { map as _map } from 'lodash';
import React, { useEffect, useReducer } from 'react';
import { USER_ACTIVITY_ACTION_TITLES } from '../../../../constants';
import { reducer } from '../../../helper/reducer';
import { setLoader } from '../../../helper/table';
import DataTable from '../../shared/Table/Table';
import Count from '../Count/Count';
var initialState = {
    config: {
        columns: [
            {
                name: 'action',
                title: 'Action',
                component: function (_a) {
                    var action = _a.action;
                    return USER_ACTIVITY_ACTION_TITLES[action];
                },
            },
            {
                name: 'day',
                title: 'Processed for day',
                component: function (_a) {
                    var day = _a.day;
                    return _jsx(Count, { counts: day || {} });
                },
            },
            {
                name: 'week',
                title: 'Processed for week',
                component: function (_a) {
                    var week = _a.week;
                    return _jsx(Count, { counts: week || {} });
                },
            },
            {
                name: 'month',
                title: 'Processed for month',
                component: function (_a) {
                    var month = _a.month;
                    return _jsx(Count, { counts: month || {} });
                },
            },
        ],
        data: [],
        loader: true,
        pagination: {
            disabled: true,
        },
        search: '',
        hideTotalLabel: true,
        disabledSearch: true,
    },
};
var UserActivityTable = function (_a) {
    var endpoint = _a.endpoint, _b = _a.deps, deps = _b === void 0 ? [] : _b;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var config = state.config;
    useEffect(function () {
        setLoader(state, dispatch, true);
        endpoint()
            .then(function (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: _map(resp, function (_a, action) {
                            var day = _a.day, week = _a.week, month = _a.month;
                            return {
                                action: action,
                                day: day,
                                week: week,
                                month: month,
                            };
                        }).filter(function (item) { return item; }), loader: false }),
                },
            });
        })
            .catch(function () {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: [], loader: false }),
                },
            });
        });
    }, deps);
    return _jsx(DataTable, { config: config });
};
export default UserActivityTable;
