// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sensor-info-block {
  margin-top: 20px;
}
.sensor-info-block-name {
  font-size: 13px;
  color: #777;
}
.dark-layout .sensor-info-block-name {
  color: #ccc;
}
.sensor-info-block-value {
  font-weight: 500;
  font-size: 14px;
}
.sensor-info-block .medium-zoom-image {
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/SensorInfoBlock/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,eAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;AAGN;AAAE;EACE,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,UAAA;AAEJ","sourcesContent":[".sensor-info-block {\n  margin-top: 20px;\n  &-name {\n    font-size: 13px;\n    color: #777;\n    .dark-layout & {\n      color: #ccc;\n    }\n  }\n  &-value {\n    font-weight: 500;\n    font-size: 14px;\n  }\n  & .medium-zoom-image {\n    z-index: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
