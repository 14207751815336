import { get as _get, size as _size } from 'lodash';
import { useEffect } from 'react';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useMessageOnChart = function (state, chart) {
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }), sensor = _get(installationPoint, 'sensor') || {}, isSensorInstalled = !!installationPoint.sensor_id, isSensorExists = _size(sensor) > 0;
    var isDataAvailable = state.isDataAvailable;
    useEffect(function () {
        var sensorInstalledMessage = 'No Recent Data Available';
        var sensorNotInstalledMessage = isSensorInstalled ? 'Need troubleshooting' : 'Sensor not installed';
        var noDataMessage = isSensorExists ? sensorInstalledMessage : sensorNotInstalledMessage;
        if (!isDataAvailable) {
            if (!chart.current.chart.hasData()) {
                chart.current.chart.hideNoData();
                chart.current.chart.showNoData(noDataMessage);
                chart.current.chart.redraw();
            }
        }
    }, [isDataAvailable]);
};
