// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-wrapper table {
  margin: 0;
}
.dashboard-wrapper .dashboard-widget {
  position: relative;
  height: 100%;
}
.dashboard-wrapper .dashboard-widget .table-head {
  position: relative;
  z-index: 20;
}
.dashboard-wrapper .dashboard-widget .loader-block {
  margin-top: 60px !important;
}
.dashboard-wrapper .dashboard-widget .badge {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/dashboard/dashboard.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAEE;EACE,kBAAA;EACA,YAAA;AAAJ;AACI;EACE,kBAAA;EACA,WAAA;AACN;AACI;EACE,2BAAA;AACN;AACI;EACE,mBAAA;AACN","sourcesContent":[".dashboard-wrapper {\n  & table {\n    margin: 0;\n  }\n  & .dashboard-widget {\n    position: relative;\n    height: 100%;\n    & .table-head {\n      position: relative;\n      z-index: 20;\n    }\n    & .loader-block {\n      margin-top: 60px !important;\n    }\n    & .badge {\n      white-space: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
