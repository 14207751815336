// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-indicators {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
.activity-indicators svg {
  width: 22px;
  height: 22px;
  fill: #2962ff;
}
.activity-indicators.active svg {
  fill: #dc3545;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/ActivityIndicator/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAEJ;AACI;EACE,aAAA;AACN","sourcesContent":[".activity-indicators {\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  font-weight: 500;\n  & svg {\n    width: 22px;\n    height: 22px;\n    fill: #2962ff;\n  }\n  &.active {\n    & svg {\n      fill: #dc3545;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
