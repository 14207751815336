import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var deactivationAiAnalystLogFn = function (data, coreLink, storage) {
    return Api.post("".concat(coreLink, "/action-list-analyst-log/delete/").concat(data.id), data, {
        host: getHost(storage),
        keepalive: true,
    });
};
export var useDeactivationAiAnalystLogMutation = function (_a) {
    var _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) { return deactivationAiAnalystLogFn(data, getCoreUrl(selectedSystemType), storage); },
    });
};
