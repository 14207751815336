import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer, useRef } from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useOnScreen } from '../../../customHooks';
import { selectSelectedCustomers } from '../../../remastered/store/SelectedCustomersStore';
import { fetchStatistic } from '../../features/home';
import { reducer } from '../../helper/reducer';
import Loader from '../Loader';
import HomePageStatistic from './Statistic/Statistic';
var initialState = {
    loader: true,
    statistic: null,
};
var HomePageCard = function (_a) {
    var client = _a.client, setOpenNodesModal = _a.setOpenNodesModal, setOpenSensorsModal = _a.setOpenSensorsModal, setSelectedClient = _a.setSelectedClient, setOpenSensorsWithoutAlarmLevelsModal = _a.setOpenSensorsWithoutAlarmLevelsModal;
    var selectedCustomers = selectSelectedCustomers();
    var cardRef = useRef(null);
    var isVisible = useOnScreen(cardRef);
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var loader = state.loader, statistic = state.statistic;
    var endpoint = function (selectedCustomers) {
        return Endpoints.remastered.getStatistic({
            query: {
                duplicated: true,
                selected_customer_ids: selectedCustomers,
            },
        });
    };
    useEffect(function () {
        if (!isVisible) {
            return;
        }
        fetchStatistic(dispatch, function () { return endpoint(client.customer_id ? [client.customer_id] : []); });
    }, [selectedCustomers, isVisible]);
    if (!client.customer_id) {
        return _jsx(_Fragment, {});
    }
    // @ts-ignore
    if (selectedCustomers.length && !selectedCustomers.includes(client.customer_id)) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Col, { md: 4, className: "my-2", children: _jsx("div", { ref: cardRef, children: _jsxs(Card, { children: [_jsx(CardHeader, { children: _jsx("b", { children: (client.name || '').toUpperCase() }) }), _jsx(CardBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(HomePageStatistic, { client: client, statistic: statistic, setSelectedClient: setSelectedClient, setOpenNodesModal: setOpenNodesModal, setOpenSensorsModal: setOpenSensorsModal, setOpenSensorsWithoutAlarmLevelsModal: setOpenSensorsWithoutAlarmLevelsModal })) })] }) }) }));
};
export default HomePageCard;
