import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { calcDaysForCharts } from '../../../features/actionItems';
import LinkWithPrefix from '../../LinkWithPrefix';
import ActiveAITitle from './components/ActiveAITitle';
import DefaultAITitle from './components/DefaultAITitle';
import WWAITitle from './components/WWAITitle';
var Title = function (_a) {
    var _b = _a.isShowPrefix, isShowPrefix = _b === void 0 ? false : _b, _c = _a.titleStyle, titleStyle = _c === void 0 ? {} : _c, _d = _a.isLinkToCharts, isLinkToCharts = _d === void 0 ? true : _d, children = _a.children, title = _a.title, installationPointName = _a.installationPointName, sensorId = _a.sensorId, isSensor = _a.isSensor, installationPoint = _a.installationPoint, equipmentType = _a.equipmentType, _e = _a.serial, serial = _e === void 0 ? null : _e, installationPointId = _a.installationPointId, timeCreated = _a.timeCreated, isInactive = _a.isInactive, translates = _a.translates;
    var actionItemId = useParams().id;
    return (_jsxs("div", { className: "page-title", style: titleStyle, children: [isShowPrefix ? _jsx("span", { className: "page-title-prefix", children: "Action Item for" }) : _jsx(_Fragment, {}), isSensor && installationPoint ? (isInactive ? (_jsx(ActiveAITitle, { installationPointName: installationPointName, sensorId: sensorId })) : (_jsx(_Fragment, { children: _jsx(LinkWithPrefix, { target: "_blank", rel: "noreferrer", to: isLinkToCharts
                        ? "/dashboard?installation_point=".concat(installationPointId, "&scale=").concat(calcDaysForCharts(timeCreated || ''))
                        : "/action-item/".concat(actionItemId), children: _jsx(ActiveAITitle, { installationPointName: installationPointName, sensorId: sensorId }) }) }))) : (_jsx(WWAITitle, { installationPointName: installationPointName, equipmentType: equipmentType, serial: serial, sensorId: sensorId })), title ? _jsx(DefaultAITitle, { translates: translates, title: title }) : _jsx(_Fragment, {}), children || _jsx(_Fragment, {})] }));
};
export default memo(Title);
