// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-contextmenu {
  background: #ffffff;
  z-index: 999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.react-contextmenu-item {
  margin: 8px 12px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #4b4b4b;
}

.react-contextmenu-item--divider {
  border-top: 1px solid #ccc;
}

.react-contextmenu-item--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.react-contextmenu-item:hover {
  color: #5c4de5;
}
`, "",{"version":3,"sources":["webpack://./../src/css/contextmenu.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".react-contextmenu {\n  background: #ffffff;\n  z-index: 999;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);\n}\n\n.react-contextmenu-item {\n  margin: 8px 12px;\n  cursor: pointer;\n  font-weight: 500;\n  font-size: 0.8rem;\n  line-height: 1.2rem;\n  color: #4b4b4b;\n}\n\n.react-contextmenu-item--divider {\n  border-top: 1px solid #ccc;\n}\n\n.react-contextmenu-item--disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n\n.react-contextmenu-item:hover {\n  color: #5c4de5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
