import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { setChartRange } from '../../../helper/chart';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { selectChartZoomDays } from '../../../store/charts/useChartSettingsStore';
export var useSetChartRange = function (state, chartRef, timezone) {
    var _a = ChartsStore(function (state) { return ({
        isZoom: state.isZoom,
        isChartResetRmsZoom: state.isChartResetRmsZoom,
    }); }, shallow), isZoom = _a.isZoom, isChartResetRmsZoom = _a.isChartResetRmsZoom;
    var chartZoomDays = selectChartZoomDays();
    var chartMarks = state.chartMarks, readings = state.readings, options = state.options, mainSeriesLoaded = state.mainSeriesLoaded, seriesIsRendered = state.seriesIsRendered, isVisibleFullscreenChart = state.isVisibleFullscreenChart;
    var series = options.series;
    useEffect(function () {
        if (mainSeriesLoaded && seriesIsRendered && chartZoomDays && !isZoom) {
            setChartRange(chartRef, series, chartZoomDays, readings, chartMarks, timezone);
        }
    }, [series, mainSeriesLoaded, seriesIsRendered]);
    useEffect(function () {
        if (!mainSeriesLoaded || !chartZoomDays) {
            return;
        }
        setChartRange(chartRef, series, chartZoomDays, readings, chartMarks, timezone);
    }, [isVisibleFullscreenChart, chartZoomDays, isChartResetRmsZoom]);
};
