import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Api from '../../../../api/api';
import { AMAZON_FILE_UPLOAD_URL, SYSTEM_TYPE_AMAZON } from '../../../../constants';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import useSetCommentActivityLog from '../../../hooks/AIPreAi/useSetCommentActivityLog';
import useToastErrorAlert from '../../../hooks/useToastErrorAlert';
import { activityLogSchema } from '../../../schemas/preAiSchemas';
import { useCommentDeleteMutation } from '../../../services/preActionItems/useCommentDeleteMutation';
import { useCommentSaveMutation } from '../../../services/preActionItems/useCommentSaveMutation';
import { ACTIVITY_LOG_SIZE_FULL, selectActivityLogSize } from '../../../store/AIPreAi/useActionItemViewStore';
import CommentEditor from '../../AIPreAi/CommentEditor';
import CommentStatusBar from '../../AIPreAi/CommentStatusBar';
import CommentsList from './CommentsList';
import Header from './Header';
var ActivityLog = function (_a) {
    var preActionItemData = _a.preActionItemData, storage = _a.storage;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var preActionItemId = useParams().id;
    var _b = useCommentSaveMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }), commentSaveMutate = _b.mutate, commentSaveIsLoading = _b.isPending;
    var _c = useCommentDeleteMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }), commentDeleteMutate = _c.mutate, commentDeleteIsLoading = _c.isPending;
    var id = preActionItemData.id, isReplaceEquipment = preActionItemData.isReplaceEquipment, comments = preActionItemData.comments, userImage = preActionItemData.userImage, isViewMode = preActionItemData.isViewMode, facilityMeasure = preActionItemData.facilityMeasure;
    var activityLogSize = selectActivityLogSize();
    var _d = useForm({
        resolver: activityLogSchema,
    }), setValue = _d.setValue, control = _d.control, errors = _d.formState.errors, handleSubmit = _d.handleSubmit;
    useToastErrorAlert(errors);
    useSetCommentActivityLog(setValue);
    return (_jsxs("div", { className: "activity-log", style: activityLogSize
            ? {
                width: activityLogSize === ACTIVITY_LOG_SIZE_FULL
                    ? 'calc(100% - 100px)'
                    : "calc(".concat(activityLogSize * 200, "px + 440px)"),
            }
            : { width: '62px' }, children: [_jsx(Header, { id: id, isViewMode: isViewMode, isReplaceEquipment: isReplaceEquipment, commentSaveMutate: commentSaveMutate, commentSaveIsLoading: commentSaveIsLoading, control: control, handleSubmit: handleSubmit, comments: comments }), activityLogSize ? (_jsx("div", { className: "activity-log-wrap", children: _jsxs("div", { className: "activity-log-content without-left-bar", children: [isViewMode ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx(CommentEditor, { storage: storage, control: control, uploadImageUrl: selectedSystemType === SYSTEM_TYPE_AMAZON
                                        ? Api.getUrl({ url: AMAZON_FILE_UPLOAD_URL })
                                        : void 0 }), _jsx(CommentStatusBar, { facilityMeasure: facilityMeasure })] })), _jsx(CommentsList, { userImage: userImage, isViewMode: isViewMode, comments: comments, commentDeleteMutate: commentDeleteMutate, commentDeleteIsLoading: commentDeleteIsLoading, commentSaveIsLoading: commentSaveIsLoading })] }) })) : (_jsx("div", { className: "activity-log-vertical-text", children: "Activity Log" }))] }));
};
export default memo(ActivityLog);
