var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticCostAvoidedReportKey = function (dateRange) {
    var _a, _b;
    return [
        'statisticCostAvoidedReport',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
    ];
};
var statisticCostAvoidedReportFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/cost-avoided-report"), {
        query: {
            'separate-by-team': 1,
            date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'),
            date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'),
        },
    });
};
export var useStatisticCostAvoidedReportQuery = function () {
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticCostAvoidedReportKey(dateRange),
        queryFn: function (data) { return statisticCostAvoidedReportFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h, _j;
            var data = _a.data;
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], ((_c = (_b = data === null || data === void 0 ? void 0 : data.analysts) === null || _b === void 0 ? void 0 : _b.Hardware_team) !== null && _c !== void 0 ? _c : {}), true), ((_e = (_d = data === null || data === void 0 ? void 0 : data.analysts) === null || _d === void 0 ? void 0 : _d.ML_bot_system) !== null && _e !== void 0 ? _e : {}), true), ((_g = (_f = data === null || data === void 0 ? void 0 : data.analysts) === null || _f === void 0 ? void 0 : _f.UA_team) !== null && _g !== void 0 ? _g : {}), true), ((_j = (_h = data === null || data === void 0 ? void 0 : data.analysts) === null || _h === void 0 ? void 0 : _h.USA_team) !== null && _j !== void 0 ? _j : {}), true);
        },
    });
};
