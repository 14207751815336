var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEqual as _isEqual } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { usePrevious } from '../../../../customHooks';
import { getFaultFrequencyConfig } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/FaultFrequency';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useFaultFrequencies = function (state, chartRef, chartIdentifier, loader) {
    var faultFrequencies = FftChartsStore(function (state) { return state.faultFrequencies; });
    var _a = InstallationPointStore(function (state) { return ({
        currentSpeed: state.currentSpeed,
        installationPoint: state.installationPoint,
    }); }, shallow), currentSpeed = _a.currentSpeed, installationPoint = _a.installationPoint;
    var options = state.options, isVisibleFaultFrequencyIds = state.isVisibleFaultFrequencyIds, selectedPoint = state.selectedPoint;
    var prevInstallationPoint = usePrevious(installationPoint);
    var prevIsVisibleFaultFrequencyIds = usePrevious(isVisibleFaultFrequencyIds);
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        if (prevInstallationPoint.id !== installationPoint.id) {
            return;
        }
        updateFaultFrequencies(chartRef, chartIdentifier, currentSpeed, faultFrequencies, isVisibleFaultFrequencyIds, selectedPoint);
    }, [faultFrequencies, currentSpeed, selectedPoint]);
    useEffect(function () {
        if (!prevIsVisibleFaultFrequencyIds ||
            _isEqual(prevIsVisibleFaultFrequencyIds, isVisibleFaultFrequencyIds)) {
            return;
        }
        updateFaultFrequencies(chartRef, chartIdentifier, currentSpeed, faultFrequencies, isVisibleFaultFrequencyIds, selectedPoint);
    }, [isVisibleFaultFrequencyIds]);
};
var updateFaultFrequencies = function (chartRef, chartIdentifier, currentSpeed, faultFrequencies, isVisibleFaultFrequencyIds, selectedPoint) {
    var otherPlotLines = (chartRef.current.chart.xAxis[0].plotLinesAndBands || [])
        .filter(function (plotLine) { return plotLine.options.type !== 'frequencies'; })
        .map(function (plotLine) { return plotLine.options; });
    chartRef.current.chart.xAxis[0].update({
        plotLines: __spreadArray(__spreadArray([], otherPlotLines, true), getFaultFrequencyConfig(chartIdentifier, currentSpeed, faultFrequencies, chartRef, selectedPoint, undefined, isVisibleFaultFrequencyIds), true),
    });
};
