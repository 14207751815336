// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.machine-info-alert {
  position: absolute;
  background: #d9534f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/topBar/MachineInformation.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AACF","sourcesContent":[".machine-info-alert {\n  position: absolute;\n  background: #d9534f;\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  top: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
