var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { first as _first, get as _get, size as _size } from 'lodash';
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Endpoints from '../../../api/endpoints/endpoints';
export var onSubmitModal = function (data, getInstallationPointBySensor, extraParams, callback, selectedSystemType, isHFDvue) {
    if (extraParams === void 0) { extraParams = {}; }
    if (isHFDvue === void 0) { isHFDvue = 0; }
    Endpoints[selectedSystemType]
        .requestOnDemand(__assign({ data: data }, extraParams))
        .then(function (resp) {
        var _a;
        if (!_size(resp.errors)) {
            toast.success('Your request was successfully sent', {
                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
            });
            (_a = resp === null || resp === void 0 ? void 0 : resp.list) === null || _a === void 0 ? void 0 : _a.forEach(function (item, index) {
                var _a;
                if (!item.data.requestId) {
                    return;
                }
                var isRmsRequest = 'rmsRequest' in item.data;
                getEstimatedTime((_a = getInstallationPointBySensor(_get(item.data, [isRmsRequest ? 'rmsRequest' : 'fftRequest', 'sensorIds', 0]))) === null || _a === void 0 ? void 0 : _a.id, item.data.requestId, resp, index === 0 ? callback : function () { }, selectedSystemType, isRmsRequest ? 'rms' : 'fft', isHFDvue);
            });
            return callback(_first(resp.list));
        }
        toast.error(_first(resp.errors), { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    })
        .catch(function () {
        toast.error('Your request was failed', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        callback({
            errors: {
                message: 'Something went wrong!',
            },
        });
    });
};
export var getEstimatedTime = function (installationPoint, requestId, resp, callback, selectedSystemType, type, isHFDvue) {
    if (type === void 0) { type = 'fft'; }
    if (isHFDvue === void 0) { isHFDvue = 0; }
    Endpoints[selectedSystemType]
        .getEstimatedRequest(installationPoint, requestId, {
        query: {
            type: type,
            isHFDvue: isHFDvue,
            duplicated: true,
        },
    })
        .then(function (response) {
        callback(__assign(__assign({}, resp), { estimateTime: response }));
    })
        .catch(function () {
        callback(resp);
    });
};
