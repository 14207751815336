import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
var ClearAdditionalFftTwfPoints = function () {
    var _a = ChartsStore(function (state) { return ({
        chartCursorType: state.chartCursorType,
        isClearFftTwfCursor: state.isClearFftTwfCursor,
        setStates: state.setStates,
    }); }, shallow), chartCursorType = _a.chartCursorType, isClearFftTwfCursor = _a.isClearFftTwfCursor, setStates = _a.setStates;
    return (_jsx(_Fragment, { children: chartCursorType !== 'normal' ? (_jsxs(_Fragment, { children: [_jsx(Button, { outline: true, color: "customChartTopBarSelect", className: "btn-chart-tools btn-clear-fft-twf-points", id: "clear-fft-twf-points", size: "sm", onClick: function () { return setStates({ isClearFftTwfCursor: !isClearFftTwfCursor }); }, children: _jsx(AiOutlineClear, {}) }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "clear-fft-twf-points", children: "Clear points on FFT/TWF" })] })) : (_jsx(_Fragment, {})) }));
};
export default memo(ClearAdditionalFftTwfPoints);
