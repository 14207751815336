import { READING_TYPE_ACCELERATION_IMPACT_VUE_X, READING_TYPE_ACCELERATION_IMPACT_VUE_Y, READING_TYPE_ACCELERATION_IMPACT_VUE_Z, READING_TYPE_ACCELERATION_X, READING_TYPE_ACCELERATION_Y, READING_TYPE_ACCELERATION_Z, READING_TYPE_PK_PK_IMPACT_VUE_X, READING_TYPE_PK_PK_X, READING_TYPE_PK_PK_Y, READING_TYPE_PK_PK_Z, READING_TYPE_RANGE, READING_TYPE_TEMPERATURE, READING_TYPE_VELOCITY_X, READING_TYPE_VELOCITY_Y, READING_TYPE_VELOCITY_Z, } from '../../../constants';
import { createStore } from '../createStore';
var READING_TYPES_WITH_SETTINGS = [
    READING_TYPE_TEMPERATURE,
    READING_TYPE_ACCELERATION_X,
    READING_TYPE_ACCELERATION_Y,
    READING_TYPE_ACCELERATION_Z,
    READING_TYPE_VELOCITY_X,
    READING_TYPE_VELOCITY_Y,
    READING_TYPE_VELOCITY_Z,
    READING_TYPE_PK_PK_X,
    READING_TYPE_PK_PK_Y,
    READING_TYPE_PK_PK_Z,
    READING_TYPE_RANGE,
    READING_TYPE_ACCELERATION_IMPACT_VUE_X,
    READING_TYPE_ACCELERATION_IMPACT_VUE_Y,
    READING_TYPE_ACCELERATION_IMPACT_VUE_Z,
    READING_TYPE_PK_PK_IMPACT_VUE_X,
];
var units = {};
READING_TYPES_WITH_SETTINGS.forEach(function (readingType) {
    units[readingType] = 'us';
});
var useGlobalStore = createStore(function (set) { return ({
    isFullSizeSidebar: false,
    theme: 'light',
    measure: 'us',
    personalSettingMeasure: {
        datetime_format: null,
        units: units,
    },
    actions: {
        setFullSizeSidebar: function (isFullSizeSidebar) { return set({ isFullSizeSidebar: isFullSizeSidebar }); },
        setTheme: function (theme) { return set({ theme: theme }); },
        setMeasure: function (measure) { return set({ measure: measure }); },
        setPersonalSettingMeasure: function (personalSettingMeasure) { return set({ personalSettingMeasure: personalSettingMeasure }); },
    },
}); }, 'GlobalStore', ['isFullSizeSidebar', 'theme']);
export var useGlobalActions = function () {
    return useGlobalStore(function (state) { return state.actions; });
};
export var selectIsFullSizeSidebar = function () {
    return useGlobalStore(function (state) { return state.isFullSizeSidebar; });
};
export var selectTheme = function () { return useGlobalStore(function (state) { return state.theme; }); };
export var selectMeasure = function () { return useGlobalStore(function (state) { return state.measure; }); };
export var selectPersonalSettingMeasure = function () {
    return useGlobalStore(function (state) { return state.personalSettingMeasure; });
};
