import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { selectTheme, useGlobalActions } from '../../../../shared/store/global/useGlobalStore';
import { useHotkeysModalActions } from '../../../../shared/store/hotkeys/useHotkeysModalStore';
var ProfileMenu = function (_a) {
    var layoutDispatch = _a.layoutDispatch, isShowProfileMenu = _a.isShowProfileMenu, auth = _a.auth;
    var theme = selectTheme();
    var setTheme = useGlobalActions().setTheme;
    var setIsVisible = useHotkeysModalActions().setIsVisible;
    return (_jsx("div", { className: "profile-menu-wrapper ".concat(isShowProfileMenu ? '' : 'hidden'), children: _jsxs("div", { className: "profile-menu", children: [_jsx("div", { className: "profile-item", onClick: function () {
                        return layoutDispatch({
                            type: 'setMetricsModal',
                            isVisibleMetricsModal: true,
                        });
                    }, children: "Measurement" }), _jsx("div", { className: "profile-item", onClick: function () { return setIsVisible(true); }, children: "Hotkeys" }), _jsx("div", { className: "profile-item", onClick: function () { return setTheme(theme === 'light' ? 'dark' : 'light'); }, children: theme === 'light' ? 'Dark' : 'Light' }), _jsx("hr", {}), _jsxs("div", { className: "profile-item", onClick: function () { return auth.logout(); }, children: [_jsx("i", { className: "fa fa-sign-out" }), " Logout"] })] }) }));
};
export default memo(ProfileMenu);
