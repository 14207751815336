import Api from "../../api";
export default {
    checkInstallationPointAnalystLog: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/installation-point-analyst-log/check/".concat(installationPointId), options);
    },
    deleteInstallationPointAnalystLog: function (installationPointId, body, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/installation-point-analyst-log/delete/".concat(installationPointId), body, options);
    },
};
