import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useOverlays = function (state, updateOverlay, prevInstallationPoint) {
    var overlayInstallationPoints = InstallationPointStore(function (state) { return state.overlayInstallationPoints; });
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var countDays = ChartsStore(function (state) { return ({
        countDays: state.countDays,
    }); }, shallow).countDays;
    var mainSeriesLoaded = state.mainSeriesLoaded, seriesIsRendered = state.seriesIsRendered;
    useEffect(function () {
        if (mainSeriesLoaded && seriesIsRendered && +installationPoint.id === +prevInstallationPoint.id) {
            updateOverlay(false);
        }
    }, [overlayInstallationPoints]);
    useEffect(function () {
        if (mainSeriesLoaded && seriesIsRendered) {
            updateOverlay(true);
        }
    }, [countDays, mainSeriesLoaded, seriesIsRendered]);
};
