import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { SensorId } from "../../../../../widgets/sensor";
import HighlighterWord from "../../../shared/HighlighterWord";
var ActiveAITitle = function (_a) {
    var installationPointName = _a.installationPointName, sensorId = _a.sensorId;
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "page-title-name", children: _jsx(HighlighterWord, { block: false, searchWords: highlightSearch || '', textToHighlight: installationPointName }) }), _jsx(SensorId, { highlightSearch: highlightSearch || '', showDecId: false, sensor_id: sensorId })] }));
};
export default memo(ActiveAITitle);
