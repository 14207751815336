import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FiFilter } from 'react-icons/fi';
import { selectIsOnlyInternalComments, useActionItemViewActions, } from '../../../../../store/AIPreAi/useActionItemViewStore';
import DefaultTooltip from '../../../../DefaultTooltip';
var OnlyInternalCommentsBtn = function () {
    var isOnlyInternalComments = selectIsOnlyInternalComments();
    var setIsOnlyInternalComments = useActionItemViewActions().setIsOnlyInternalComments;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "left-menu-btn ".concat(isOnlyInternalComments ? 'active' : ''), onClick: function () { return setIsOnlyInternalComments(!isOnlyInternalComments); }, "data-tooltip-id": "only-internal-comment", children: _jsx(FiFilter, {}) }), _jsx(DefaultTooltip, { place: "left", openEvents: { mouseenter: true }, id: "only-internal-comment", children: isOnlyInternalComments ? 'Show all comments' : 'Show only internal comments' })] }));
};
export default memo(OnlyInternalCommentsBtn);
