var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { get as _get, upperCase as _upperCase } from "lodash";
import React, { memo, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { shallow } from "zustand/shallow";
import { OFFSET_READING_TYPES, READING_OFFSET_X, SYSTEM_TYPES_DATA } from "../../../constants";
import { SensorId } from "../../../widgets/sensor";
import Loader from "../../components/Loader";
import { positioner, RmsTooltipFormatter } from "../../components/charts/chartsWrapper/components/shared/tooltip";
import { calculateSize } from "../../components/charts/chartsWrapper/features/shared/size";
import { DEFAULT_CHARTS_CONFIG } from "../../config/charts/defaultChartsConfig";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import { getTimezone } from "../../features/shared";
import { getUoms } from "../../helper/chart";
import { useHardwareDataQuery } from "../../services/charts/queries/useHardwareDataQuery";
import { ChartsStore } from "../../store/charts/chartsContent/ChartsStore";
import { InstallationPointStore } from "../../store/charts/chartsContent/InstallationPointStore";
import { selectChartZoomDays } from "../../store/charts/useChartSettingsStore";
import { selectMeasure, selectPersonalSettingMeasure } from "../../store/global/useGlobalStore";
var HardwareDataModal = function (_a) {
    var _b, _c;
    var visible = _a.visible, onCancel = _a.onCancel;
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var _d = ChartsStore(function (state) { return ({
        chartView: state.chartView,
        countDays: state.countDays,
    }); }, shallow), chartView = _d.chartView, countDays = _d.countDays;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }), sensor = _get(installationPoint, 'sensor') || {}, readingTypes = OFFSET_READING_TYPES;
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var chartZoomDays = selectChartZoomDays();
    var _e = useHardwareDataQuery(installationPoint.id, chartZoomDays || countDays), data = _e.data, isPending = _e.isPending;
    var options = __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
            zoomType: 'x',
            panning: true,
            animation: false,
            panKey: 'shift',
            selectionMarkerFill: 'rgba(51,92,173,0)',
            events: {},
            marginTop: 80,
            height: calculateSize(chartView).height,
        }, title: __assign({}, DEFAULT_CHARTS_CONFIG.title), xAxis: [
            __assign(__assign({}, DEFAULT_CHARTS_CONFIG.xAxis[0]), { crosshair: false, zoomEnabled: false }),
        ], series: [], tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { split: true, positioner: function (labelWidth, labelHeight, point) {
                // @ts-ignore
                return positioner(labelWidth, labelHeight, point, this.chart);
            }, formatter: function () {
                // @ts-ignore
                var points = this.points.filter(function (point) {
                    return point.series.options.id;
                });
                return ReactDOMServer.renderToStaticMarkup((_jsx(RmsTooltipFormatter, { points: points, 
                    // @ts-ignore
                    allPoints: this.points, prefix: prefix, timezone: timezone !== null && timezone !== void 0 ? timezone : 'America/New_York', 
                    // @ts-ignore
                    xValue: this.x })));
            } }) });
    return (_jsxs(Modal, { className: "modal-machine-info modal-response", size: "xxl", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Network Data - ", _jsx(SensorId, { sensor_id: +sensor.sensor_id })] }), _jsx(ModalBody, { children: isPending ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(HighchartsReact, { highcharts: Highcharts, options: __assign(__assign({}, options), { title: __assign(__assign({}, options.title), { text: 'OFFSET' }), yAxis: [
                                        __assign(__assign({}, options.yAxis[0]), { title: {
                                                text: 'Offset',
                                            } }),
                                    ], chartIdentifier: READING_OFFSET_X, series: Object.values(((_b = data === null || data === void 0 ? void 0 : data.readings) === null || _b === void 0 ? void 0 : _b.offset) || {}).map(function (readings, index) {
                                        var _a;
                                        var readingTypeId = Object.keys(((_a = data === null || data === void 0 ? void 0 : data.readings) === null || _a === void 0 ? void 0 : _a.offset) || {})[index];
                                        return {
                                            id: "offset_".concat(readingTypeId, "_").concat(installationPoint.id),
                                            installationPointId: installationPoint.id,
                                            data: readings,
                                            legendIndex: index,
                                            name: _get(readingTypes, [readingTypeId, 'axis']),
                                            units: getUoms(readingTypeId, measure, personalSettingMeasure, readingTypes),
                                            chartType: readingTypeId,
                                            precision: _get(readingTypes, [readingTypeId, 'precision']),
                                            visible: true,
                                            chartIdentifier: readingTypeId,
                                            showInLegend: true,
                                            dataGrouping: {
                                                approximation: 'high',
                                                forced: true,
                                            },
                                        };
                                    }) }) }) }), _jsx("div", { className: "mt-4", children: _jsx(HighchartsReact, { highcharts: Highcharts, options: __assign(__assign({}, options), { title: __assign(__assign({}, options.title), { text: 'BATTERY' }), yAxis: [
                                        __assign(__assign({}, options.yAxis[0]), { title: {
                                                text: 'Volts',
                                            } }),
                                    ], chartIdentifier: READING_OFFSET_X, series: Object.values(((_c = data === null || data === void 0 ? void 0 : data.readings) === null || _c === void 0 ? void 0 : _c.batt) || {}).map(function (readings, index) {
                                        var _a;
                                        var name = Object.keys(((_a = data === null || data === void 0 ? void 0 : data.readings) === null || _a === void 0 ? void 0 : _a.batt) || {})[index];
                                        return {
                                            id: "batt_".concat(name, "_").concat(installationPoint.id),
                                            installationPointId: installationPoint.id,
                                            data: readings,
                                            legendIndex: index,
                                            name: _upperCase(name),
                                            units: 'V',
                                            precision: 4,
                                            visible: true,
                                            chartIdentifier: READING_OFFSET_X,
                                            showInLegend: true,
                                            dataGrouping: {
                                                approximation: 'high',
                                                forced: true,
                                            },
                                        };
                                    }) }) }) })] })) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default memo(HardwareDataModal);
