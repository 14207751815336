var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import { cloneDeep as _cloneDeep, each as _each, findKey as _findKey, flatten as _flatten, get as _get, keyBy as _keyBy, sortedLastIndexBy as _sortedLastIndexBy, } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { CHART_GROUPS, IMPACT_VUE_FFT_TWF_CHART_LIST, INST_POINT_COLORS_DARK_THEME, INST_POINT_COLORS_LIGHT_THEME, READING_TYPES, READING_TYPE_ACCELERATION_IMPACT_VUE_X, READING_TYPE_CF_X, READING_TYPE_CF_Y, READING_TYPE_CF_Z, READING_TYPE_PIN, READING_TYPE_PK_PK_IMPACT_VUE_X, READING_TYPE_TACHOMETER, READING_TYPE_TEMPERATURE, } from '../../constants';
import { harmonicCursor, secondHarmonicCursor, sideBandCursor, } from '../components/charts/chartsWrapper/features/shared/cursor';
import { isJsonString } from './commonHelper';
import { removeAllByClassName } from './dom';
import { getMeasure, utcToUserTime } from './user';
export var isTemperatureChart = function (chartIdentifier) {
    return +chartIdentifier === READING_TYPE_TEMPERATURE;
};
export var isRmsChart = function (chartIdentifier) {
    return !READING_TYPES[chartIdentifier] ||
        (READING_TYPES[chartIdentifier] &&
            READING_TYPES[chartIdentifier].type === 'simple');
};
export var isFftChart = function (chartIdentifier) {
    return READING_TYPES[chartIdentifier] &&
        READING_TYPES[chartIdentifier].type === 'fft';
};
export var isTwfChart = function (chartIdentifier) {
    return READING_TYPES[chartIdentifier] &&
        READING_TYPES[chartIdentifier].type === 'twf';
};
export var isTwfAccelerationChart = function (chartIdentifier) {
    return READING_TYPES[chartIdentifier] &&
        _get(READING_TYPES[chartIdentifier], 'chartGroup') === CHART_GROUPS.TWF_ACC;
};
export var isAccelerationChart = function (chartIdentifier) {
    return READING_TYPES[chartIdentifier] &&
        _get(READING_TYPES[chartIdentifier], 'groupId') === CHART_GROUPS.ACC;
};
export var isCfChart = function (chartIdentifier) {
    return +chartIdentifier === READING_TYPE_CF_X ||
        +chartIdentifier === READING_TYPE_CF_Y ||
        +chartIdentifier === READING_TYPE_CF_Z;
};
export var isTachometerChart = function (chartIdentifier) {
    return +chartIdentifier === READING_TYPE_TACHOMETER;
};
export var isPinChart = function (chartIdentifier) { return +chartIdentifier === READING_TYPE_PIN; };
export var isFftTwfChart = function (chartIdentifier) {
    return isFftChart(chartIdentifier) || isTwfChart(chartIdentifier);
};
export var isFftTwfChartImpactVue = function (chartIdentifier) {
    return Boolean(IMPACT_VUE_FFT_TWF_CHART_LIST.includes(+chartIdentifier));
};
export var isAccelerationImpactVue = function (chartIdentifier) {
    return +chartIdentifier === READING_TYPE_ACCELERATION_IMPACT_VUE_X;
};
export var isPkPkImpactVue = function (chartIdentifier) {
    return +chartIdentifier === READING_TYPE_PK_PK_IMPACT_VUE_X;
};
export var getNearestPointIndex = function (series, x) {
    // hotfix: negative xx comes from fft server
    x = Math.max(x, 0);
    // _sortedLastIndexBy uses binary search. Got performance raise 10x+ compared to Highcharts.each
    var index = _sortedLastIndexBy(series.points, { x: x }, function (point) { return point.x; });
    index = Math.min(index, series.points.length - 1);
    if (!series.points[index]) {
        return;
    }
    var diff = series.points[index].x - x;
    if (index > 0) {
        var prevDiff = x - series.points[index - 1].x;
        if (diff >= prevDiff) {
            index = index - 1;
        }
    }
    return index;
};
export var getMaxAbsNumber = function (readings) {
    var _a;
    return (_a = Math.max.apply(Math, [Math.abs(Math.max.apply(Math, readings)), Math.abs(Math.min.apply(Math, readings))])) !== null && _a !== void 0 ? _a : 0;
};
export var getValueForNormalizeReadings = function (points, currentPoint) {
    if (_get(currentPoint, 'series.userOptions.id') === 'ambientNormalizeData') {
        var ambient = points.filter(function (point) {
            return point.series.userOptions.id === 'ambientData';
        });
        //@ts-ignore
        return Math.abs(+(points[0].y - ambient[0].y).toFixed(2));
    }
    return currentPoint.y || 0;
};
export var getUoms = function (readingType, measure, personalSettingMeasure, readingTypes) {
    if (!readingType) {
        return '';
    }
    var useMetric = getMeasure(+readingType, measure, personalSettingMeasure);
    return (_get(readingTypes, [readingType, 'uom', useMetric]) ||
        _get(READING_TYPES, [readingType, 'uom', useMetric]));
};
export var getSoftMax = function (chartIdentifier, measure, personalSettingMeasure) {
    var useMetric = getMeasure(chartIdentifier, measure, personalSettingMeasure);
    //@ts-ignore
    return READING_TYPES[chartIdentifier]
        ? //@ts-ignore
            READING_TYPES[chartIdentifier].softMax[useMetric]
        : 0;
};
export var getSoftMin = function (chartIdentifier, measure, personalSettingMeasure) {
    var useMetric = getMeasure(chartIdentifier, measure, personalSettingMeasure);
    //@ts-ignore
    return READING_TYPES[chartIdentifier]
        ? //@ts-ignore
            READING_TYPES[chartIdentifier].softMin[useMetric]
        : 0;
};
export var setChartRange = function (chartRef, series, chart_zoom_days, readings, chartMarks, timezone) {
    if (series === void 0) { series = []; }
    if (chartRef.current) {
        var prepareReadingsData_1 = readings;
        series.filter(function (el) {
            if (!((el === null || el === void 0 ? void 0 : el.data) || []).length || !el.visible || !el.data[0] || !prepareReadingsData_1[0]) {
                return;
            }
            // @ts-ignore
            prepareReadingsData_1 = el.data[0][0] < prepareReadingsData_1[0][0] ? el.data : prepareReadingsData_1;
        });
        var chartMarkMax_1 = 0;
        // @ts-ignore
        chartMarks.forEach(function (el) { return (chartMarkMax_1 < el.timestamp ? (chartMarkMax_1 = el.timestamp) : ''); });
        var from = moment.utc().subtract(chart_zoom_days, 'days').valueOf();
        var readingsData = prepareReadingsData_1.filter(function (el) { return el[1] !== null; });
        var calcEndDate = utcToUserTime(+new Date(), timezone);
        if (readingsData.length && readingsData[readingsData.length - 1][0]) {
            if (readingsData[readingsData.length - 1][0] > calcEndDate) {
                calcEndDate = readingsData[readingsData.length - 1][0];
            }
        }
        if (chartMarkMax_1 > calcEndDate) {
            calcEndDate = chartMarkMax_1;
        }
        chartRef.current.chart.xAxis[0].setExtremes(utcToUserTime(from, timezone), calcEndDate);
        if (readingsData.length && readingsData[0][0] > utcToUserTime(from, timezone)) {
            chartRef.current.chart.xAxis[0].setExtremes(readingsData[0][0], calcEndDate);
        }
        // @ts-ignore
        connectNulls(chartRef, chartRef.current.chart.userOptions.chartIdentifier);
    }
};
export var connectNulls = function (chartRef, chartIdentifier) {
    if (!chartRef.current || isFftTwfChart(chartIdentifier)) {
        return;
    }
    removeAllByClassName('.null-lines', ".chart-item-".concat(chartIdentifier));
    var plotLeft = chartRef.current.chart.plotLeft;
    var plotTop = chartRef.current.chart.plotTop;
    for (var i = 0; i < chartRef.current.chart.series.length; i++) {
        if (!chartRef.current.chart.series[i].points) {
            continue;
        }
        for (var j = 1; j < chartRef.current.chart.series[i].points.length; j++) {
            var path = [];
            if (chartRef.current.chart.series[i].points[j].y == null) {
                var p1 = chartRef.current.chart.series[i].points[j - 1];
                var p2 = chartRef.current.chart.series[i].points[j + 1];
                p2 = p2 === undefined ? p1 : p2;
                path.push('M');
                //@ts-ignore
                path.push(p1.plotX + plotLeft, p1.plotY + plotTop);
                path.push('L');
                //@ts-ignore
                path.push(p2.plotX + plotLeft, p2.plotY + plotTop);
                chartRef.current.chart.renderer
                    .path(path)
                    .attr({
                    class: 'null-lines',
                    'stroke-width': 1,
                    stroke: p1.color,
                    dashstyle: 'Dot',
                })
                    .add();
            }
        }
    }
};
export var getPointBetween = function (leftPoint, rightPoint, x) {
    var slope = (leftPoint[1] - rightPoint[1]) / (leftPoint[0] - rightPoint[0]);
    var xDiff = x - leftPoint[0];
    return [x, leftPoint[1] + xDiff * slope];
};
export var setPanning = function (min, max, setStatesChartStore) {
    var panningHandle = function () {
        setStatesChartStore({
            customZoomExtremes: {
                min: min,
                max: max,
            },
        });
    };
    var highestId = window.setTimeout(function () {
        for (var i = highestId; i >= 0; i--) {
            window.clearTimeout(i);
        }
    }, 0);
    setTimeout(panningHandle, 200);
};
export var setCustomZoom = function (min, max, setStatesChartStore) {
    setStatesChartStore({
        isZoom: true,
        customZoomExtremes: {
            min: min,
            max: max,
        },
    });
};
export var reRenderCursorFft = function (chart, customCursorPointsCount, currentSpeed) {
    if (chart.userOptions.chartCursorType === 'normal') {
        return true;
    }
    if (chart.userOptions.chartCursorType === 'harmonic') {
        harmonicCursor(chart.userOptions.harmonicPoints || [], customCursorPointsCount, true, chart, chart.userOptions.storedHarmonicPoints);
        secondHarmonicCursor(chart.userOptions.secondHarmonicPoints || [], customCursorPointsCount, true, chart);
    }
    if (chart.userOptions.chartCursorType === 'sideband' && chart.userOptions.lockedFftSidebandLinePoint) {
        sideBandCursor(chart.userOptions.lockedFftSidebandLinePoint, false, false, currentSpeed, customCursorPointsCount);
    }
};
export var setActiveChartOnClick = function (ev, chartIdentifier) {
    //@ts-ignore
    if (
    //@ts-ignore
    (typeof ev.target.className === 'string' ? ev.target.className : []).includes('react-datepicker__day')) {
        return;
    }
    var activeElement = void 0;
    var type = ev.type;
    switch (type) {
        case 'contextmenu':
            if (chartIdentifier) {
                //@ts-ignore
                activeElement = document.getElementById('chart-' + chartIdentifier);
            }
            ev.preventDefault();
            break;
        case 'click':
            //@ts-ignore
            activeElement = ev.target.closest('.chart-item');
            break;
    }
    var chartElements = document.getElementsByClassName('chart-item');
    if (chartElements.length) {
        // @ts-ignore
        __spreadArray([], chartElements, true).forEach(function (chartElement, index) {
            chartElements[index].classList.remove('chart-item-active');
        });
        if (activeElement) {
            //@ts-ignore
            activeElement.classList.add('chart-item-active');
        }
    }
};
export var getNearestFFTTimestampForAllAxesByDate = function (fftTimestamps, date) {
    var timestamp = moment.utc(date).toDate().getTime();
    var result = [];
    _each(fftTimestamps, function (pointsFftTimestamps, axisId) {
        var nearestFftTimestamp = null, nearestDiff = null, axisResult = {};
        _each(pointsFftTimestamps, function (pointsFftTimestampData) {
            var readingDate = pointsFftTimestampData[0].timestamp, readingId = pointsFftTimestampData[0].readingFFT_id;
            var readingTimestamp = moment.utc(readingDate).toDate().getTime();
            var diff = Math.abs(timestamp - readingTimestamp);
            if (nearestFftTimestamp === null || diff < nearestDiff) {
                nearestFftTimestamp = readingDate;
                nearestDiff = diff;
                axisResult = {
                    axisId: +axisId,
                    readingId: readingId,
                };
            }
        });
        result.push(__assign(__assign({}, axisResult), { readingDate: nearestFftTimestamp }));
    });
    return result;
};
export var isValidChartSorting = function (chartSorting) {
    return isJsonString(chartSorting) && JSON.parse(chartSorting).length === 29;
};
export var setHoverPointsAndCrosshair = function (isShow, chart, current, series, isDarkMode) {
    var _a;
    if (!current) {
        return;
    }
    //@ts-ignore
    if (isShow && !((_a = series.userOptions) === null || _a === void 0 ? void 0 : _a.isOverlay)) {
        if (chart.customCrosshair) {
            chart.customCrosshair.element.remove();
        }
        //@ts-ignore
        chart.customCrosshair = chart.renderer
            .rect(current.plotX + chart.plotLeft - 1, chart.plotTop, 1, chart.plotSizeY)
            .attr({
            fill: isDarkMode ? '#fff' : '#ccc',
            zIndex: 1,
            width: '1px',
        })
            .add();
    }
    current.setState('hover', true);
};
var onStopEventPropagation = function (event) {
    event.stopPropagation();
};
export var onBlockedFftTwfMouseMove = function (isLockedCursor, chart) {
    document
        .querySelectorAll(".chart-item-".concat(chart.userOptions.chartIdentifier, " .highcharts-root"))
        .forEach(function (el) {
        el.removeEventListener('mousemove', onStopEventPropagation);
    });
    document
        .querySelectorAll(".chart-item-".concat(chart.userOptions.chartIdentifier, " .highcharts-label"))
        .forEach(function (el) {
        el.removeEventListener('mousemove', onStopEventPropagation);
    });
    if (!isLockedCursor) {
        document
            .querySelectorAll(".chart-item-".concat(chart.userOptions.chartIdentifier, " .highcharts-root"))
            .forEach(function (el) {
            el.addEventListener('mousemove', onStopEventPropagation);
        });
        document
            .querySelectorAll(".chart-item-".concat(chart.userOptions.chartIdentifier, " .highcharts-label"))
            .forEach(function (el) {
            el.addEventListener('mousemove', onStopEventPropagation);
        });
        Highcharts.charts.forEach(function (chartItem) {
            var isDarkMode = document.getElementsByTagName('body')[0].className === 'dark-layout';
            // @ts-ignore
            if (!chartItem ||
                // @ts-ignore
                !isFftTwfChart(chartItem.userOptions.chartIdentifier) ||
                // @ts-ignore
                chartItem.userOptions.chartIdentifier !== chart.userOptions.chartIdentifier) {
                return;
            }
            chartItem.series.forEach(function (seriesItem) {
                var _a;
                if (!seriesItem.data.length) {
                    return;
                }
                var nearestPoint = getNearestPointIndex(seriesItem, ((_a = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _a === void 0 ? void 0 : _a.lastFftTwfHoverPointX) || 0);
                seriesItem.points[nearestPoint].setState('hover', true);
                // @ts-ignore
                if (chartItem.customCrosshair) {
                    // @ts-ignore
                    chartItem.customCrosshair.element.remove();
                }
                // @ts-ignore
                if (chartItem.userOptions.isCircleTwfType && isTwfChart(chartItem.userOptions.chartIdentifier)) {
                    return;
                }
                // @ts-ignore
                chartItem.customCrosshair = chartItem.renderer
                    .rect(
                // @ts-ignore
                seriesItem.points[nearestPoint].plotX + chartItem.plotLeft - 1, chartItem.plotTop, 1, 
                // @ts-ignore
                chartItem.plotSizeY)
                    .attr({
                    fill: isDarkMode ? '#fff' : '#ccc',
                    zIndex: 2,
                    width: '1px',
                })
                    .add();
            });
        });
    }
};
export var onBlockedRmsMouseMove = function (chart, isLockedRmsCursor) {
    document.querySelectorAll('.chart-rms .highcharts-root').forEach(function (el) {
        el.removeEventListener('mousemove', onStopEventPropagation);
    });
    document.querySelectorAll('.chart-rms .highcharts-label').forEach(function (el) {
        el.removeEventListener('mousemove', onStopEventPropagation);
    });
    if (!isLockedRmsCursor) {
        document.querySelectorAll('.chart-rms .highcharts-root').forEach(function (el) {
            el.addEventListener('mousemove', onStopEventPropagation);
        });
        document.querySelectorAll('.chart-rms .highcharts-label').forEach(function (el) {
            el.addEventListener('mousemove', onStopEventPropagation);
        });
        var isDarkMode_1 = document.getElementsByTagName('body')[0].className === 'dark-layout';
        if (chart) {
            Highcharts.each(chart.series, function (series) {
                if (!series.visible) {
                    return;
                }
                var current = series.points[getNearestPointIndex(series, chart.userOptions.lastRmsHoverPointsX[0].x)];
                // @ts-ignore
                setHoverPointsAndCrosshair(
                // @ts-ignore
                isRmsChart(series.userOptions.chartType), chart, current, series, isDarkMode_1);
            });
        }
    }
};
export var afterSaveFftAlerts = function (result, setStatesFftChartsStore, fftAlerts, chartIdentifier, isUpdate) {
    var updatedFftAlerts = _cloneDeep(fftAlerts);
    if (isUpdate) {
        var fftAlertIndex = _findKey(updatedFftAlerts[chartIdentifier], { id: result.id });
        if (+fftAlertIndex >= 0) {
            updatedFftAlerts[chartIdentifier][fftAlertIndex] = __assign(__assign({}, updatedFftAlerts[chartIdentifier][fftAlertIndex]), result);
        }
    }
    else {
        updatedFftAlerts[chartIdentifier].push(result);
    }
    setStatesFftChartsStore({
        fftAlerts: updatedFftAlerts,
    });
};
export var replaceHandlerSaveData = function (endPoint, data, setShowLoader, onCancel, callback, isPreAi) {
    endPoint(data).then(function (response) {
        setShowLoader(true);
        onCancel();
        if (response.status === 'ok' || response.success) {
            toast.success((isPreAi ? 'Pre-' : '') + 'Action item created successfully!', {
                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
            });
            return callback(isPreAi ? response.id : response.created_action_item_id);
        }
        toast.error(response.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
export var findClosestTimestamp = function (newFftSelectedDate, availableDate) {
    if (!availableDate || !availableDate.length) {
        return;
    }
    var newAvailableDate = availableDate.map(function (el) {
        var result = moment(el.timestamp).unix() - moment(newFftSelectedDate).unix();
        return Math.abs(result);
    });
    var activeIndex = null;
    newAvailableDate.filter(function (el, index) {
        if (el === Math.min.apply(Math, newAvailableDate)) {
            activeIndex = index;
        }
    });
    if (!activeIndex) {
        return availableDate[0];
    }
    return availableDate[activeIndex];
};
export var setColorForInstallationPoints = function (equipment) {
    var isDarkMode = document.getElementsByTagName('body')[0].className === 'dark-layout';
    var installationPointsColors = isDarkMode ? INST_POINT_COLORS_DARK_THEME : INST_POINT_COLORS_LIGHT_THEME;
    Object.values(equipment.installationPoints).forEach(function (installationPoint, index) {
        // @ts-ignore
        equipment.installationPoints[Object.keys(equipment.installationPoints)[index]].color =
            installationPointsColors[index];
    });
    return equipment;
};
export var getFftReadingsIdsByTimestamps = function (fftTimestamps, timestamps) {
    var indexedFftTimestamps = _keyBy(_flatten(fftTimestamps), 'timestamp');
    var readingsList = [];
    timestamps.forEach(function (timestamp) {
        var _a, _b;
        if ((_a = indexedFftTimestamps[timestamp]) === null || _a === void 0 ? void 0 : _a.readingFFT_id) {
            readingsList.push((_b = indexedFftTimestamps[timestamp]) === null || _b === void 0 ? void 0 : _b.readingFFT_id);
        }
    });
    return readingsList;
};
