import Api from '../../api';
export default {
    getSlaSettingsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/sla-settings', options);
    },
    getSlaSettingsLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/sla-settings/log', options);
    },
    saveSlaSettings: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/sla-settings/save', body, options);
    },
    deleteSlaSettings: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/sla-settings/delete', body, options);
    },
};
