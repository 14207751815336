// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-select {
  position: absolute;
  z-index: 1;
  left: 90px;
  top: 45px;
}
.color-svg {
  background: #ffff;
  font-size: 20px;
  color: green;
  cursor: pointer;
}
.color-svg-checked {
  background: #ffff;
  font-size: 33px;
  color: green;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/chartSelect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".chart-select {\n  position: absolute;\n  z-index: 1;\n  left: 90px;\n  top: 45px;\n}\n.color-svg {\n  background: #ffff;\n  font-size: 20px;\n  color: green;\n  cursor: pointer;\n}\n.color-svg-checked {\n  background: #ffff;\n  font-size: 33px;\n  color: green;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
