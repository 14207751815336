var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import './index.scss';
var Tags = function (_a) {
    var tags = _a.tags, onTagsChange = _a.onTagsChange, selectedTags = _a.selectedTags;
    return (_jsx("div", { className: "tags-wrapper", children: tags.map(function (tag) { return (_jsx("div", { onClick: function () {
                return onTagsChange(selectedTags.find(function (_a) {
                    var name = _a.name;
                    return name === tag.name;
                })
                    ? selectedTags.filter(function (_a) {
                        var name = _a.name;
                        return name !== tag.name;
                    })
                    : __spreadArray([tag], selectedTags, true));
            }, className: "tags-wrapper-tag ".concat(selectedTags.find(function (_a) {
                var name = _a.name;
                return name === tag.name;
            }) ? 'selected' : ''), children: tag.name }, tag.name)); }) }));
};
export default memo(Tags);
