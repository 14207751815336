import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DateTimeLabel } from '../../../../../widgets/common';
import { SensorId } from '../../../../../widgets/sensor';
import { Filters } from '../../../../components/withoutAlarmLevels/Filters';
export var config = function (onViewActionCallback, setOpenPhotosModal) {
    return {
        columns: [
            {
                name: 'facility_name',
                style: {
                    maxWidth: '100px',
                    wordBreak: 'break-all',
                },
                title: 'Facility',
                sortable: true,
            },
            {
                name: 'location_name',
                title: 'Location',
                sortable: true,
            },
            {
                name: 'equipment_name',
                title: 'Equipment',
                sortable: true,
            },
            {
                name: 'sensor_version_type',
                title: 'Sensor Version Type',
                sortable: true,
                component: function (item) {
                    if (!item.sensor_id) {
                        return 'Not installed';
                    }
                    return item.sensor_version_type;
                },
            },
            {
                name: 'sensor_id',
                title: 'Sensor Id',
                sortable: true,
                component: function (item) {
                    return _jsx(SensorId, { sensor_id: parseInt(item.sensor_id) });
                },
            },
            {
                name: 'missed_readings',
                title: 'Missed Alarms Levels',
                sortable: false,
                component: function (item) {
                    if (!item.missed_readings) {
                        return 'No Data';
                    }
                    var readings = (item.missed_readings || '').split('; ');
                    return (_jsx(_Fragment, { children: readings.map(function (reading) { return (_jsx("div", { children: reading }, reading)); }) }));
                },
            },
            {
                name: 'created_at',
                title: 'First reading time',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "without_list_created_at_".concat(row.id), dateTime: row.created_at, timeBreak: true }));
                },
            },
            {
                name: 'actions',
                title: '',
                sortable: false,
                component: function (item) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { size: "sm", onClick: function () { return onViewActionCallback(item); }, color: "success", children: "View" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", onClick: function () { return setOpenPhotosModal(item.sensor_id); }, color: "info", children: "Images" }) })] }));
                },
            },
        ],
        search: '',
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Filters, { onChangeFilter: callbacks.onChangeFilter, filtersData: filtersData, dateRange: [new Date(), new Date()], onChangeDateRange: function () { } }));
        },
        filtersData: {
            hide_bad_sensors: 1,
            new_data_readings: 1,
            has_data_readings: 1,
        },
    };
};
