import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { FaCheck, FaPencilAlt, FaUndo } from 'react-icons/fa';
import { MdArrowOutward } from 'react-icons/md';
import { PiRectangle, PiTextAa } from 'react-icons/pi';
import { Input } from 'reactstrap';
import { ChartsStore } from '../../../../store/charts/chartsContent/ChartsStore';
import { undoPaintingFigures } from '../../chartsWrapper/features/shared/painting';
import './index.scss';
var COLORS = ['indigo', 'red', 'blue', 'orange', 'black', 'green', 'yellow', 'purple'];
var TOOLS = [
    {
        key: 'pencil',
        icon: _jsx(FaPencilAlt, {}),
    },
    {
        key: 'arrow',
        icon: _jsx(MdArrowOutward, {}),
    },
    {
        key: 'text',
        icon: _jsx(PiTextAa, {}),
    },
    {
        key: 'rect',
        icon: _jsx(PiRectangle, {}),
    },
    {
        key: 'undo',
        icon: _jsx(FaUndo, {}),
    },
];
var PaintingLeftBar = function () {
    var chartsSelectedPainting = ChartsStore(function (state) { return state.chartsSelectedPainting; });
    var _a = useState('black'), activeColor = _a[0], setActiveColor = _a[1];
    var _b = useState(''), activeTool = _b[0], setActiveTool = _b[1];
    useEffect(function () {
        var _a;
        (_a = document.getElementById('undoBtn')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', undoPaintingFigures);
        return function () {
            var _a;
            (_a = document.getElementById('undoBtn')) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', undoPaintingFigures);
        };
    }, []);
    return (_jsxs("div", { className: "painting-left-bar ".concat(Object.values(chartsSelectedPainting).includes(true) ? '' : 'd-none'), children: [_jsx("div", { className: "mb-2", children: "Colors:" }), _jsx("div", { className: "painting-colors", children: COLORS.map(function (color) { return (_jsx("div", { onClick: function () { return setActiveColor(color); }, style: { backgroundColor: color }, id: "".concat(color, "Btn"), children: activeColor === color ? _jsx(FaCheck, {}) : '' }, color)); }) }), _jsx("div", { className: "mt-3 mb-2", children: "Tools:" }), _jsxs("div", { className: "painting-tools", children: [TOOLS.map(function (_a) {
                        var key = _a.key, icon = _a.icon;
                        return (_jsx("div", { className: activeTool === key ? 'active' : '', onClick: function () { return setActiveTool(function (prev) { return (key === 'undo' ? prev : key); }); }, style: { backgroundColor: key }, id: "".concat(key, "Btn"), children: icon }, key));
                    }), activeTool === 'text' ? _jsx(Input, { id: "textInput", type: "text", placeholder: "Enter text" }) : _jsx(_Fragment, {})] })] }));
};
export default memo(PaintingLeftBar);
