import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTitle } from "../../customHooks";
import "./Error.scss";
import ErrorPage401 from "./components/401";
import ErrorPage404 from "./components/404";
import ErrorPageFacilityNotPublished from "./components/facilityNotPublished";
var errorMap = {
    401: _jsx(ErrorPage401, {}),
    404: _jsx(ErrorPage404, {}),
    1: _jsx(ErrorPageFacilityNotPublished, {}),
};
var ErrorPage = function (_a) {
    var errorCode = _a.errorCode;
    useTitle('Error');
    if (!errorMap[errorCode]) {
        errorCode = 404;
    }
    return _jsx("div", { className: "page-error", children: errorMap[errorCode] });
};
export default ErrorPage;
