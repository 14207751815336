var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStore } from '../createStore';
var defaultConfig = function (set) {
    return {
        isVisible: false,
        size: 'md',
        isOnlyConfirm: false,
        textConfirmationModal: 'Are you sure?',
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        additionalBtns: [],
        onConfirm: function () { },
        onCancel: function () { return set({ isVisible: false }); },
    };
};
var useConfirmModalStore = createStore(function (set) { return (__assign(__assign({}, defaultConfig(set)), { onCancel: function () { return set({ isVisible: false }); }, actions: {
        setConfirmData: function (data) {
            return set({
                isVisible: data.isVisible || defaultConfig(set).isVisible,
                size: data.size || defaultConfig(set).size,
                isOnlyConfirm: data.isOnlyConfirm || defaultConfig(set).isOnlyConfirm,
                textConfirmationModal: data.textConfirmationModal || defaultConfig(set).textConfirmationModal,
                cancelText: data.cancelText || defaultConfig(set).cancelText,
                confirmText: data.confirmText || defaultConfig(set).confirmText,
                additionalBtns: data.additionalBtns || defaultConfig(set).additionalBtns,
                onConfirm: data.onConfirm || defaultConfig(set).onConfirm,
                onCancel: data.onCancel || defaultConfig(set).onCancel,
            });
        },
    } })); }, 'ConfirmModalStore');
export var useConfirmModalActions = function () {
    return useConfirmModalStore(function (state) { return state.actions; });
};
export var selectIsVisibleModal = function () {
    return useConfirmModalStore(function (state) { return state.isVisible; });
};
export var selectIsOnlyConfirm = function () {
    return useConfirmModalStore(function (state) { return state.isOnlyConfirm; });
};
export var selectTextConfirmationModal = function () {
    return useConfirmModalStore(function (state) { return state.textConfirmationModal; });
};
export var selectCancelText = function () {
    return useConfirmModalStore(function (state) { return state.cancelText; });
};
export var selectConfirmText = function () {
    return useConfirmModalStore(function (state) { return state.confirmText; });
};
export var selectAdditionalBtns = function () {
    return useConfirmModalStore(function (state) { return state.additionalBtns; });
};
export var selectConfirmCallback = function () {
    return useConfirmModalStore(function (state) { return state.onConfirm; });
};
export var selectCancelCallback = function () {
    return useConfirmModalStore(function (state) { return state.onCancel; });
};
export var selectSizeModal = function () {
    return useConfirmModalStore(function (state) { return state.size; });
};
