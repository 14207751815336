import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Col, Row } from "reactstrap";
import Endpoints from "../../../../../api/endpoints/endpoints";
import DataTable from "../../../../components/shared/Table/Table";
import { SystemTypeContext } from "../../../../context/SystemTypeContext";
import { reducer } from "../../../../helper/reducer";
import { InstallationPointStore } from "../../../../store/charts/chartsContent/InstallationPointStore";
import "../../MachineInfo.scss";
import SelectInstallationPointOnEquipment from "../../components/SelectInstallationPointOnEquipment";
import { initialState } from "./config/dataTableConfig";
import { fetch } from "./features";
var HistoryTab = function () {
    var _a, _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var _d = useState(installationPoint.id), selectedInstallationPointId = _d[0], setSelectedInstallationPointId = _d[1];
    var pointsOnEquipment = (_b = (_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.installationPoints) !== null && _b !== void 0 ? _b : [];
    useEffect(function () {
        fetch(state, dispatch, function () {
            return Endpoints[selectedSystemType].getMachineInfoHistory(selectedInstallationPointId);
        });
    }, [selectedInstallationPointId]);
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "mt-3", children: _jsx(Col, { md: 4, children: _jsx(SelectInstallationPointOnEquipment, { installationPoint: installationPoint, pointsOnEquipment: pointsOnEquipment, setSelectedInstallationPointId: setSelectedInstallationPointId }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", { className: "response-table mt-3", children: _jsx(DataTable, { config: config }) }) }) })] }));
};
export default HistoryTab;
