import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useLoader = function (state, setStatesFftChartsStore) {
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var mainSeriesLoaded = state.mainSeriesLoaded;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        setStatesFftChartsStore({
            loader: true,
        });
    }, [installationPoint.id]);
};
