import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import Loader from '../../components/Loader';
var RemovingWatchListModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, callback = _a.callback, id = _a.id, endpoint = _a.endpoint;
    var _b = useState(''), removingReason = _b[0], setRemovingReason = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    if (!visible || !id) {
        return _jsx(React.Fragment, {});
    }
    var onConfirm = function () {
        setLoader(true);
        endpoint(id, { deleted_comment: removingReason }).then(function (resp) {
            setLoader(false);
            if (resp.success) {
                callback();
                onCancel();
                setRemovingReason('');
                toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            }
        });
    };
    return (_jsxs(Modal, { className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Are you sure you want to delete this item from WatchList?" }), _jsx(ModalBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "datetime-local", children: "Reason why recommended for removal:" }), _jsx(Input, { id: "removing-reason", type: "textarea", value: removingReason, onChange: function (ev) { return setRemovingReason(ev.target.value); } })] }) })) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore ", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", disabled: loader, color: "primary", onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(RemovingWatchListModal);
