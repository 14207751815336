import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { Button } from 'reactstrap';
var LockTooltipButton = function (_a) {
    var onClickLockTooltip = _a.onClickLockTooltip, isVisibleLockTooltip = _a.isVisibleLockTooltip, width = _a.width, isDisabled = _a.isDisabled;
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { className: "lock-tooltip-btn chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", onClick: onClickLockTooltip, style: { width: width }, disabled: isDisabled, children: isVisibleLockTooltip ? _jsx(FaLock, {}) : _jsx(FaLockOpen, {}) }) }));
};
export default memo(LockTooltipButton);
export var onToggleLockTooltip = function (dispatch, isVisibleLockTooltip, chartRef) {
    dispatch({
        type: 'setState',
        state: {
            isVisibleLockTooltip: isVisibleLockTooltip,
        },
    });
    if (chartRef) {
        chartRef.current.chart.userOptions.isLockTooltip = isVisibleLockTooltip;
    }
};
