import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { TbBoxAlignBottomLeftFilled, TbBoxAlignBottomRightFilled, TbBoxAlignTopLeftFilled, TbBoxAlignTopRightFilled, } from 'react-icons/tb';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
var ICONS = {
    'top-left': _jsx(TbBoxAlignTopLeftFilled, {}),
    'top-right': _jsx(TbBoxAlignTopRightFilled, {}),
    'bottom-left': _jsx(TbBoxAlignBottomLeftFilled, {}),
    'bottom-right': _jsx(TbBoxAlignBottomRightFilled, {}),
};
var LockTooltipPosition = function (_a) {
    var isVisibleLockTooltip = _a.isVisibleLockTooltip, isDisabled = _a.isDisabled, width = _a.width, dispatch = _a.dispatch, lockTooltipPosition = _a.lockTooltipPosition, chartRef = _a.chartRef;
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var onToggle = function (lockTooltipPosition) {
        dispatch({
            type: 'setState',
            state: {
                lockTooltipPosition: lockTooltipPosition,
            },
        });
        chartRef.current.chart.userOptions.lockTooltipPosition = lockTooltipPosition;
    };
    if (!isVisibleLockTooltip) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "trend-dropdown chart-action-btn  ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsx(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: ICONS[lockTooltipPosition] }), _jsxs(DropdownMenu, { children: [_jsxs(DropdownItem, { className: "btn-sm", onClick: function () { return onToggle('top-left'); }, children: [_jsx(TbBoxAlignTopLeftFilled, {}), " Top Left"] }), _jsxs(DropdownItem, { className: "btn-sm", onClick: function () { return onToggle('top-right'); }, children: [_jsx(TbBoxAlignTopRightFilled, {}), " Top Right"] }), _jsxs(DropdownItem, { className: "btn-sm", onClick: function () { return onToggle('bottom-left'); }, children: [_jsx(TbBoxAlignBottomLeftFilled, {}), " Bottom Left"] }), _jsxs(DropdownItem, { className: "btn-sm", onClick: function () { return onToggle('bottom-right'); }, children: [_jsx(TbBoxAlignBottomRightFilled, {}), " Bottom Right"] })] })] }) }) }));
};
export default memo(LockTooltipPosition);
