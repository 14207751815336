var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Tooltip } from 'react-tooltip';
import { getReverseTheme } from '../helper/commonHelper';
import { selectTheme } from '../store/global/useGlobalStore';
var DefaultTooltip = function (props) {
    var theme = selectTheme();
    var children = props.children;
    return (_jsx(Tooltip, __assign({ variant: getReverseTheme(theme), closeEvents: { mouseup: true, mouseleave: true, blur: true, click: true }, globalCloseEvents: { clickOutsideAnchor: true }, delayShow: 200 }, props, { children: children })));
};
export default memo(DefaultTooltip);
