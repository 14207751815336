import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useId } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { DateTimeLabel } from '../../../../widgets/common';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { useAlertLevelsLogQuery } from '../../../services/alerts/useAlertLevelLogsQuery';
import UserSourceBadge from '../../UserSourceBadge';
import DataTable from '../../shared/Table/Table';
var DataContent = function (_a) {
    var data = _a.data;
    if (Object.values(data).every(function (item) { return !item; })) {
        return _jsx(_Fragment, { children: "Not set" });
    }
    var id = useId();
    var mapping = {
        notification_type: {
            name: 'Trigger type',
            values: {
                1: 'Max Val',
                2: 'RMS',
            },
        },
        following_type: {
            name: 'Following Type',
            values: {
                1: 'The currently visible reading',
                2: 'Recent readings',
                3: 'Recent days',
                4: 'Date range',
            },
        },
        fft_envelope_type: {
            name: 'FFT Envelope Type',
            values: {
                1: 'Percent',
                2: 'Std',
            },
        },
    };
    return (_jsx(_Fragment, { children: Object.values(data).map(function (item, index) { return (_jsx("div", { children: item ? (_jsxs(_Fragment, { children: [_get(mapping, Object.keys(data)[index] + '.name', Object.keys(data)[index])
                        .replaceAll('_', ' ')
                        .toUpperCase(), ": ", _get(mapping, [Object.keys(data)[index], 'values', item], item)] })) : (_jsx(_Fragment, {})) }, "".concat(id, "-").concat(index))); }) }));
};
var FftAlertLog = function (_a) {
    var readingTypes = _a.readingTypes, installationPointId = _a.installationPointId, type = _a.type;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useAlertLevelsLogQuery(installationPointId, readingTypes), logsData = _b.data, isPending = _b.isPending;
    return (_jsx(Row, { className: "g-1 mt-2", children: _jsx(Col, { children: _jsx(DataTable, { config: {
                    columns: [
                        {
                            name: 'label',
                            title: '',
                            sortable: false,
                            component: function (row) {
                                var permissions = _get(row, 'user.permissions') || [];
                                return (_jsx("span", { className: "mt-3", children: _jsx(UserSourceBadge, { permissions: permissions }) }));
                            },
                        },
                        {
                            name: 'val7',
                            title: 'Level',
                            sortable: false,
                            component: function (row) {
                                if (!row.val7) {
                                    return 'No data';
                                }
                                return row.val7.toLowerCase() === 'caution' ? (_jsx(Badge, { color: "warning", size: "sm", children: "Caution" })) : (_jsx(Badge, { color: "danger", size: "sm", children: "Warning" }));
                            },
                        },
                        {
                            name: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'customer_name' : 'facility',
                            title: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Customer' : 'Facility',
                            sortable: false,
                        },
                        {
                            name: 'sensor_hex',
                            title: 'Sensor',
                            sortable: false,
                        },
                        {
                            name: 'reading_type',
                            title: 'Reading Type',
                            sortable: false,
                        },
                        {
                            name: 'old_data',
                            title: 'Old data',
                            sortable: false,
                            component: function (row) {
                                return _jsx(DataContent, { data: row.old_data });
                            },
                        },
                        {
                            name: 'new_data',
                            title: 'New data',
                            sortable: false,
                            component: function (row) {
                                return _jsx(DataContent, { data: row.new_data });
                            },
                        },
                        {
                            name: 'date_create',
                            title: 'Time',
                            sortable: false,
                            component: function (row) {
                                return _jsx(DateTimeLabel, { id: "default-alert-log-".concat(row.id), dateTime: row.date_create });
                            },
                        },
                        {
                            name: 'user_name',
                            title: 'User',
                            sortable: false,
                        },
                    ],
                    sort: [
                        {
                            field: 'date_create',
                            direction: 'desc',
                        },
                    ],
                    pagination: {
                        disabled: true,
                    },
                    search: '',
                    disabledSearch: true,
                    hideTotalLabel: true,
                    data: _get(logsData, 'data')
                        ? _get(logsData, 'data').filter(function (item) { return item.val6 === type; })
                        : [],
                    loader: isPending,
                } }) }) }));
};
export default memo(FftAlertLog);
