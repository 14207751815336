import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var zonesKey = function (facility) { return ['zones', facility]; };
var zonesFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/facility/zones/".concat(queryKey[1]));
};
export var useZonesQuery = function (_a) {
    var facility = _a.facility;
    return useQuery({
        queryKey: zonesKey(facility),
        queryFn: zonesFn,
        select: function (_a) {
            var zones = _a.zones;
            return {
                zones: zones.map(function (zone) {
                    return {
                        name: zone,
                        zone: zone,
                    };
                }),
            };
        },
        placeholderData: {
            zones: [],
        },
        enabled: !!facility,
    });
};
