import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import LinkWithPrefix from '../../../LinkWithPrefix';
import './Tabs.scss';
var Tabs = function (_a) {
    var activeTab = _a.activeTab;
    return (_jsxs(Nav, { tabs: true, className: "mb-2 twfReports-tabs", children: [_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === 'reports', children: _jsx(LinkWithPrefix, { to: "/twf-detections-reports", children: "TWF Detections Reports" }) }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === 'statistic', children: _jsx(LinkWithPrefix, { to: "/twf-detections-statistic", children: "Statistic" }) }) })] }));
};
export default Tabs;
