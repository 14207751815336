import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { READING_TYPE_TEMPERATURE } from '../../../../constants';
import { hideAmbientReadings, showAmbientReadings, } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/Ambient/features';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useAmbient = function (state, dispatch, chartIdentifier, updateSeries) {
    var countDays = ChartsStore(function (state) { return ({
        countDays: state.countDays,
    }); }, shallow).countDays;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
    }); }, shallow).installationPoint;
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options, ambientDataIsVisible = state.ambientDataIsVisible;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        if (chartIdentifier !== READING_TYPE_TEMPERATURE) {
            return;
        }
        if (ambientDataIsVisible) {
            dispatch({ type: 'setState', state: { overlayInstallationPointsLoader: 1 } });
            return showAmbientReadings(installationPoint.id, countDays, options.series, updateSeries);
        }
        return hideAmbientReadings(options.series, updateSeries);
    }, [ambientDataIsVisible]);
};
