import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var bearingLogsKey = function (installationPointId) { return ['bearingLogs', installationPointId]; };
var bearingLogsFn = function (coreLink, installationPointId) {
    return Api.get("".concat(coreLink, "/bearing/logs"), { query: { installation_point_id: installationPointId } });
};
export var useBearingLogsQuery = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: bearingLogsKey(installationPointId),
        queryFn: function () { return bearingLogsFn(getCoreUrl(selectedSystemType), installationPointId); },
        select: function (_a) {
            var list = _a.list;
            return {
                list: list,
            };
        },
    });
};
