import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
var useContentSectionToggle = function (localStorageKey, defaultDirection) {
    var storageState = localStorage.getItem(localStorageKey)
        ? Number(localStorage.getItem(localStorageKey))
        : defaultDirection;
    var _a = useState(storageState), isContentVisible = _a[0], setIsContentVisible = _a[1];
    var _b = useState(storageState), storageStateMemory = _b[0], setStorageStateMemory = _b[1];
    var onSaveAsDefaultSize = function () {
        localStorage.setItem(localStorageKey, String(isContentVisible));
        setStorageStateMemory(isContentVisible);
        toast.success('Successfully saved', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
    };
    return {
        isContentVisible: isContentVisible,
        setIsContentVisible: setIsContentVisible,
        storageStateMemory: storageStateMemory,
        onSaveAsDefaultSize: onSaveAsDefaultSize,
    };
};
export default useContentSectionToggle;
