import { useEffect } from 'react';
import { clearAdditionalFftPoints } from '../../../components/charts/chartsWrapper/features/shared/cursor';
export var useResetCursorsOnChart = function (state, chartRef) {
    var options = state.options;
    useEffect(function () {
        clearAdditionalFftPoints(chartRef.current.chart);
        chartRef.current.chart.userOptions.isLockedWithAltCursor = false;
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
    }, [options.chart.height]);
};
