import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { actionItemKey } from './useActionItemQuery';
var submitCustomerCreatedAiFn = function (data) {
    return Api.post('amazon-analytic/action-item/submit-customer-created', data);
};
export var useSubmitCustomerCreatedAiMutation = function (_a) {
    var actionItemId = _a.actionItemId;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: submitCustomerCreatedAiFn,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: actionItemKey(actionItemId) });
        },
    });
};
