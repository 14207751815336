var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Api from "../../api";
export default {
    getActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('api/analytic/action-item/list', __assign(__assign({}, options), { host: APP_API_V2_URL }));
    },
    getActionListFilterInfo: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('api/analytic/action-item/list/filter', __assign(__assign({}, options), { host: APP_API_V2_URL }));
    },
    getActionListViewedLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/action-list/viewed-log', options);
    },
    getRejectedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/rejected-ai', options);
    },
    getRejectedActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/rejected-ai-csv', options);
    },
    getMissedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/missed-ai', options);
    },
    removeMissedActionList: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("all-analytics/ml-statistic/missed-ai/".concat(id), options);
    },
    getMissedActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/ml-statistic/missed-ai-csv', options);
    },
    saveChart: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/action-list/save-charts', body, options);
    },
    suggestedClose: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/suggested-close', options);
    },
    removeSeverityIncreased: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/action-item/remove-severity-increase', body, options);
    },
    getActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/action-list/csv', options);
    },
};
