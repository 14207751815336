import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import mediumZoom from 'medium-zoom';
import React, { memo, useEffect } from 'react';
import { FaParagraph } from 'react-icons/fa';
import { useLocation } from 'react-router';
import Loader from '../../../../components/Loader';
import { stripHtml } from '../../../../helper/dom';
import mediumZoom_Zooming from '../../../../helper/medium-zoom-zooming';
import { setActiveArticle } from '../../features';
var WhatsNewContent = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var hash = useLocation().hash;
    var activeRelease = state.activeRelease, activeArticles = state.activeArticles, loaderArticle = state.loaderArticle;
    var options = {
        rootMargin: '0px 0px -90% 0px',
        threshold: 0.001,
    };
    var callback = function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.001) {
                if (!activeArticles.length) {
                    return;
                }
                activeArticles.map(function (item) {
                    if (item.id == entry.target.attributes.itemID.nodeValue) {
                        setActiveArticle(dispatch, +entry.target.attributes.itemID.nodeValue);
                    }
                });
            }
        });
    };
    var observer = new IntersectionObserver(callback, options);
    useEffect(function () {
        if (!loaderArticle) {
            mediumZoom('.zoomImg img').on('open', function () {
                return setTimeout(function () {
                    return document
                        .getElementsByClassName('medium-zoom-image--opened')[0]
                        .addEventListener('wheel', mediumZoom_Zooming);
                });
            });
        }
        if (!activeArticles.length) {
            return;
        }
        if (activeRelease && activeArticles.length) {
            activeArticles.map(function (item) {
                var target = document.querySelector('#' + '_' + item.id);
                target ? observer.observe(target) : '';
            });
        }
        return function () {
            activeArticles.map(function (item) {
                var target = document.querySelector('#' + '_' + item.id);
                target ? observer.unobserve(target) : '';
            });
        };
    }, [activeArticles]);
    useEffect(function () {
        if (hash) {
            var element = document.getElementById(decodeURI(hash).substr(1));
            if (element) {
                element.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        }
    }, [hash, activeArticles]);
    return (_jsx("div", { className: "zoomImg whatsNew-content", children: loaderArticle ? (_jsx("div", { className: "whatsNew-content-article", children: _jsx("div", { style: {
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    width: '100%',
                    height: '100%',
                }, children: _jsx(Loader, { variant: "loader-lg" }) }) })) : (_jsx(_Fragment, { children: activeRelease && activeArticles.length ? (activeArticles.map(function (item) { return (_jsxs("div", { className: "whatsNew-content-article", children: [_jsxs("div", { className: "title", id: stripHtml(item.title).trim().replaceAll(' ', '_'), children: [_jsx("div", { className: "block" }), _jsxs("h5", { children: [_jsx("a", { href: '#' + stripHtml(item.title).trim().replaceAll(' ', '_'), children: _jsx(FaParagraph, {}) }), _jsx("span", { children: stripHtml(item.title) })] })] }), _jsx("div", { itemID: String(item.id), id: '_' + item.id, dangerouslySetInnerHTML: { __html: item.description } })] }, item.id)); })) : (_jsx(_Fragment, {})) })) }));
};
export default memo(WhatsNewContent);
