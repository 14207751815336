var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { SYSTEM_TYPES_DATA } from '../../../constants';
import { useTitle } from '../../../customHooks';
import Filters from '../../../shared/components/actionItemsComments/Filter';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/actionItemsComments/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { fetch, parseDate } from '../../../shared/features/actionItemsComments';
import { onViewActionItem, onViewInstallationPoint } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../shared/helper/table';
import { useResetSelectedUserName } from '../../../shared/hooks/actionItemsComments/useResetSelectedUserName';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import '../../../shared/styles/actionItemsComments/actionItemsComments.scss';
var params = new URLSearchParams(window.location.search);
var startDateParam = params.get('date_start');
var endDateParam = params.get('date_end');
var currentDate = new Date();
var defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
var startDate = parseDate(startDateParam) || defaultStartDate;
var endDate = parseDate(endDateParam) || currentDate;
var initialState = function () {
    return {
        userNames: [],
        selectedUserName: params.get('selected_user_name') || null,
        isBlockResetSelectedUserName: true,
        dateRange: [startDate, endDate],
        isCommentFieldActive: true,
        config: __assign({}, baseConfig()),
    };
};
var CommentsActionListPage = function () {
    useTitle('Comments Action List');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var _a = useState(false), isTriggerResetDataTable = _a[0], setIsTriggerResetDataTable = _a[1];
    var navigate = useNavigate();
    var _b = useReducer(reducer, initialState()), state = _b[0], dispatch = _b[1];
    var selectedUserName = state.selectedUserName, dateRange = state.dateRange, config = state.config, isCommentFieldActive = state.isCommentFieldActive;
    var callbackFetch = function () {
        return fetch(state, dispatch, function () {
            return Endpoints.remastered.getActionItemsCommentsStats({
                query: {
                    pagination: config.pagination,
                    sort: config.sort,
                    search: config.search,
                    show_descriptions: +!isCommentFieldActive,
                    date_from: moment(dateRange[0]).format('MM/DD/YYYY'),
                    date_to: moment(dateRange[1]).format('MM/DD/YYYY'),
                    user_name: selectedUserName,
                },
            });
        }, navigate, prefix);
    };
    useEffect(function () {
        if (!dateRange[1]) {
            return;
        }
        setIsTriggerResetDataTable(function (prev) { return !prev; });
    }, [dateRange]);
    useResetSelectedUserName([isCommentFieldActive], state, dispatch);
    useResetDataTableEffect([isTriggerResetDataTable, selectedUserName, isCommentFieldActive], state, dispatch, {
        isResetSorting: true,
        defaultSort: {
            field: 'time_created',
            direction: 'desc',
        },
    });
    useEffect(function () {
        if (!dateRange[1]) {
            return;
        }
        callbackFetch();
    }, [dateRange, selectedUserName, isCommentFieldActive]);
    return (_jsx("div", { className: "page-actionItemsComments", children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx("h3", { className: "page-title", children: "Comments Action List" }), _jsx(Filters, { state: state, dispatch: dispatch }), _jsx("div", { className: "table_wrap", children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                            }, callbacks: {
                                onViewAi: function (action_list_id) { return onViewActionItem(action_list_id, selectedSystemType); },
                                onViewSensor: function (installation_point_id) {
                                    return onViewInstallationPoint(installation_point_id, selectedSystemType);
                                },
                            }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); } }) })] }) }) }));
};
export default CommentsActionListPage;
