import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SuggestedLevelsSettingsForm from './components/settings/SuggestedLevelsSettingsForm';
import SuggestedLevelsSettingsInfoSection from './components/settings/SuggestedLevelsSettingsInfoSection';
import './index.scss';
var SuggestedLevelsSettingsModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, search = _a.search, filters = _a.filters, onlyIntralox = _a.onlyIntralox, selectedInstallationPoints = _a.selectedInstallationPoints, selectedEntity = _a.selectedEntity, savingQuery = _a.savingQuery, recalculatePayload = _a.recalculatePayload;
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    return (_jsxs(Modal, { size: "xxl", className: "modal-response set-suggested-levels-settins", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Set Suggested Levels Settings", loader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsx(ModalBody, { children: _jsxs(Row, { className: "g-0", children: [_jsx(Col, { md: 3, children: _jsx(SuggestedLevelsSettingsInfoSection, { selectedEntity: selectedEntity, search: search, filters: filters, onlyIntralox: onlyIntralox, selectedInstallationPoints: selectedInstallationPoints }) }), _jsx(Col, { md: 9, children: _jsx(SuggestedLevelsSettingsForm, { loader: loader, setLoader: setLoader, savingQuery: savingQuery, recalculatePayload: recalculatePayload, search: search, filters: filters, onlyIntralox: onlyIntralox, selectedInstallationPoints: selectedInstallationPoints, onCancel: onCancel }) })] }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { disabled: loader, size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Close" }) }) })] }));
};
export default memo(SuggestedLevelsSettingsModal);
