import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
export var getDateDetails = function (dateRange) {
    var _a;
    var date = (_a = dateRange[0]) !== null && _a !== void 0 ? _a : new Date();
    return {
        countDaysInMonth: new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
};
export var getColumns = function (countDaysInMonth, month, year, callback) {
    var columns = [
        {
            name: 'created_by',
            title: 'Created By',
            sortable: true,
        },
    ];
    var _loop_1 = function (dayNumber) {
        columns.push({
            name: dayNumber,
            title: dayNumber,
            style: {
                cursor: 'default',
            },
            sortable: false,
            component: function (row) {
                return (_jsx(_Fragment, { children: _get(row, dayNumber) ? (_jsx("span", { className: "count-alarm-level-day-item", onClick: function () {
                            return callback({
                                createdBy: row.created_by,
                                day: dayNumber,
                            });
                        }, children: _get(row, dayNumber) })) : (_jsx("span", { children: "---" })) }));
            },
        });
    };
    for (var dayNumber = 1; dayNumber <= countDaysInMonth; dayNumber++) {
        _loop_1(dayNumber);
    }
    columns.push({
        name: 'total',
        title: 'Total',
        sortable: true,
    });
    return columns;
};
