import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Badge } from 'reactstrap';
import { useDeleteSeverityIncrease } from '../../hooks/actionItem/useDeleteSeverityIncrease';
var IncreasedSeverityBadge = function (_a) {
    var id = _a.id, approveSeverityIncreaseMutate = _a.approveSeverityIncreaseMutate, declineSeverityIncreaseMutate = _a.declineSeverityIncreaseMutate, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b;
    var _c = useState(false), isHidden = _c[0], setIsHidden = _c[1];
    var confirmDeleteSeverityIncrease = useDeleteSeverityIncrease({
        actionListId: id,
        approveSeverityIncreaseMutate: approveSeverityIncreaseMutate,
        declineSeverityIncreaseMutate: declineSeverityIncreaseMutate,
        isPreAi: isPreAi,
        onSuccess: function () {
            setIsHidden(true);
        },
    }).confirmDeleteSeverityIncrease;
    return (_jsx(Badge, { className: "cursor-pointer ".concat(isHidden ? 'd-none' : ''), onClick: confirmDeleteSeverityIncrease, children: "Increased Severity" }));
};
export default memo(IncreasedSeverityBadge);
