var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaTimes, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { getIcon, isImage } from "../../shared/helper/file";
import "./dropzone.scss";
var Dropzone = function (_a) {
    var onFilesChange = _a.onFilesChange;
    var _b = useState([]), files = _b[0], setFiles = _b[1];
    var _c = useDropzone({
        onDrop: function (acceptedFiles, fileRejections) {
            Promise.all(Object.keys(acceptedFiles).map(function (index) {
                return new Promise(function (resolve, reject) {
                    if (acceptedFiles[+index]) {
                        var file_1 = acceptedFiles[+index];
                        var reader_1 = new FileReader();
                        reader_1.readAsDataURL(file_1);
                        reader_1.onload = function () {
                            return resolve({
                                content: reader_1.result,
                                name: file_1.name,
                                mimeType: file_1.type,
                                type: file_1.type,
                                size: file_1.size,
                            });
                        };
                        reader_1.onerror = function (error) { return reject(error); };
                    }
                    else {
                        reject('File is empty');
                    }
                });
            })).then(function (processedFiles) {
                setFiles(__spreadArray(__spreadArray([], files, true), processedFiles, true));
            });
            fileRejections.map(function (file) {
                return toast.error("File ".concat(file.file.name, " failed to upload"), {
                    icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                });
            });
        },
        maxSize: 1024 * 1024 * 20, // ~20mb
    }), getRootProps = _c.getRootProps, getInputProps = _c.getInputProps;
    var onRemove = function (removedFile) {
        setFiles(files.filter(function (file) { return file.content !== removedFile.content; }));
    };
    useEffect(function () {
        onFilesChange(files);
    }, [files]);
    return (_jsxs(React.Fragment, { children: [_jsx("h6", { className: "mt-2", children: "Add new attachments: " }), _jsx("p", { className: "fz-13", children: "Maximum file size: 20MB" }), _jsxs("section", { className: "container", children: [_jsxs("div", __assign({}, getRootProps({ className: 'dropzone' }), { children: [_jsx("input", __assign({}, getInputProps())), _jsx("p", { children: "Click here to upload files" })] })), _jsx("aside", { className: "thumbs-container", children: files.map(function (file, index) { return (_jsxs("div", { className: "thumb", title: file.name, children: [_jsx("div", { className: "thumb-inner", children: isImage(file) ? (_jsx("img", { src: file.content, className: "thumb-img", alt: file.name })) : (_jsx("div", { className: "file-icon", children: getIcon(file) })) }), _jsx(Button, { color: "danger", size: "sm", className: "thumb-btn", onClick: function () { return onRemove(file); }, children: _jsx(FaTrash, {}) })] }, "".concat(file.name, "_").concat(index))); }) })] })] }));
};
export default memo(Dropzone);
