import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'reactstrap';
import { InList } from '../../../widgets/InList';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import { Filters } from '../../components/withoutAlarmLevels/Filters';
import { highlightRow } from '../../helper/table';
export var config = {
    columns: [
        {
            name: 'sensor_version_type',
            title: 'Sensor version',
            sortable: true,
        },
        {
            name: 'sensor_id',
            title: 'Sensor ID',
            sortable: true,
            component: function (row) {
                return _jsx(SensorId, { hexBreak: true, sensor_id: +row.sensor_id });
            },
        },
        {
            name: 'first_reading_at',
            title: 'First reading',
            sortable: true,
            component: function (row) {
                return (_jsx(DateTimeLabel, { timeBreak: true, id: "without_alert_list_".concat(row.id), dateTime: row.first_reading_at }));
            },
        },
        {
            name: 'missed_readings',
            title: 'Missed alarm levels',
            sortable: false,
        },
        {
            name: 'status',
            title: 'Status',
            sortable: true,
            component: function (row) {
                return _jsx(InList, { entity: row });
            },
        },
        {
            name: 'actions',
            sortable: false,
            component: function (row, callbacks) {
                return (_jsx(Button, { onClick: function (ev) {
                        callbacks.onViewClick(row.id);
                        highlightRow(ev);
                    }, color: "primary", size: "sm", children: "View" }));
            },
        },
    ],
    pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
    },
    search: '',
    data: [],
    loader: true,
    filters: function (callbacks, filtersData) {
        return (_jsx(Filters, { onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, onChangeFilter: callbacks.onChangeFilter, filtersData: filtersData }));
    },
    filtersData: {
        dateRange: [],
        hide_bad_sensors: 1,
        new_data_readings: 1,
        has_data_readings: 1,
    },
};
