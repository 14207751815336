// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-svg .g-gtitle {
  transform: translateY(1px);
}

.waterfall-footer .fft-x-axis-labels {
  position: relative;
  transform: unset;
  left: unset;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/waterfallPlot/index.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AADF","sourcesContent":[".main-svg {\n  & .g-gtitle {\n    transform: translateY(1px);\n  }\n}\n\n.waterfall-footer .fft-x-axis-labels {\n  position: relative;\n  transform: unset;\n  left: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
