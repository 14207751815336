import { useEffect } from 'react';
import { makeValidLink } from '../../../helper/routeHelper';
import { InstallationPointStore, } from '../../../store/charts/chartsContent/InstallationPointStore';
var deleteInstallationPointAnalystLog = function (installationPoint, endpoint) {
    if (!installationPoint.id) {
        return;
    }
    endpoint(installationPoint.id);
};
export var useDeleteInstallationPointAnalystLog = function (prefix, endpoint) {
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    useEffect(function () {
        window.addEventListener('beforeunload', function () {
            return deleteInstallationPointAnalystLog(installationPoint, endpoint);
        });
        return function () {
            window.removeEventListener('beforeunload', function () {
                return deleteInstallationPointAnalystLog(installationPoint, endpoint);
            });
            //When open new component without closing previous tab
            if (window.location.pathname !== makeValidLink('/' + prefix + '/dashboard')) {
                deleteInstallationPointAnalystLog(installationPoint, endpoint);
            }
        };
    }, [installationPoint]);
};
