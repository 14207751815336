import Api from '../../api';
export default {
    getTwfDataActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/twf-data-action-list/list', options);
    },
    getTwfDataActionListReports: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/twf-data-action-list/reports-list', options);
    },
    getTwfDataActionListStatistic: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/twf-data-action-list/statistic', options);
    },
    twfDataActionListCreateReport: function (body, options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/twf-data-action-list/create-report', body, options);
    },
    twfDataActionListEditReport: function (body, options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/twf-data-action-list/edit-report', body, options);
    },
};
