var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { head as _head } from 'lodash';
import React, { memo, useEffect, useMemo, useReducer, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Alert, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import { SuggestedLevelsContent } from '../../../shared/components/suggestedLevels/Content/Content';
import Filters from '../../../shared/components/suggestedLevels/Filter/Filter';
import RecalculationQueue from '../../../shared/components/suggestedLevels/RecalculationQueue';
import SettingsButton from '../../../shared/components/suggestedLevels/SettingsButton';
import { initialModalStateConfig, initialStateConfig } from '../../../shared/config/suggestedLevels';
import { setLoader } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import SuggestedLevelsSettingsModal from '../../../shared/modals/suggestedLevel/SuggestedLevelsSettingsModal';
import SuggestedLevelModal from '../../../shared/modals/suggestedLevel/index';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../shared/store/global/useGlobalStore';
import '../../../shared/styles/suggestedLevels/suggestedLevels.scss';
import { getNewUpdatedData, getSuggestedAlertConditionHandler } from '../../features/suggestedLevels';
import SuggestedLevelFormulaModal from '../../modals/suggestedLevelFormula';
import { useCustomersQuery } from '../../services/useCustomersQuery';
import { useLocationsAssetsTreeQuery, } from '../../services/useLocationsAssetsTreeQuery';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var fetch = function (state, dispatch, selectedCustomers, selectedLocations, visibleEquipmentId) {
    var pagination = state.pagination, search = state.search, filters = state.filters, units = state.units, onlyIntralox = state.onlyIntralox;
    if (filters.less && filters.less < 0) {
        return toast.error('Value must be > 0', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    }
    setLoader(dispatch, true);
    Endpoints.remastered
        .suggestedLevelsList({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_id: _head(selectedLocations),
            filters: {
                not_set: filters.notSet,
                is_ai: filters.isActions,
                is_pre_ai: filters.isActions,
                less: filters.less,
                greater: filters.greater,
                selected_location_id: _head(selectedLocations),
            },
            search: search,
            onlyIntralox: onlyIntralox,
            pagination: __assign({}, pagination),
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    data: getNewUpdatedData(resp.data, visibleEquipmentId || null) || [],
                    units: resp.units || units,
                    loader: false,
                    pagination: __assign(__assign({}, pagination), { total: resp.total }),
                },
            });
        }
    })
        .catch(function () {
        setLoader(dispatch, false);
        toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
var SuggestedLevels = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Suggested Levels');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _c = useReducer(reducer, initialStateConfig), state = _c[0], dispatch = _c[1];
    var search = state.search, pagination = state.pagination, filters = state.filters, onlyIntralox = state.onlyIntralox, settingsMode = state.settingsMode, selectedInstallationPoints = state.selectedInstallationPoints;
    var page = pagination.page, pageSize = pagination.pageSize, total = pagination.total;
    var fetchCallback = function (visibleEquipmentId) {
        return fetch(state, dispatch, selectedCustomers, selectedLocations, visibleEquipmentId || null);
    };
    var _d = useReducer(reducer, initialModalStateConfig(function (visibleEquipmentId) { return fetchCallback(visibleEquipmentId); })), stateSuggestedLevelModal = _d[0], dispatchSuggestedLevelModal = _d[1];
    var _e = useState(false), isVisibleSetSettingsModal = _e[0], setIsVisibleSetSettingsModal = _e[1];
    var visible = stateSuggestedLevelModal.visible;
    var _f = useReducer(reducer, {
        visible: false,
        overwriteLevelsCallback: function () { },
        confirmFromChartCallback: function () { },
        onCancel: function () {
            return setStateSuggestedLevelFormulaModal({
                type: 'setState',
                state: __assign(__assign({}, stateSuggestedLevelFormulaModal), { visible: false, data: {} }),
            });
        },
        data: {},
    }), stateSuggestedLevelFormulaModal = _f[0], setStateSuggestedLevelFormulaModal = _f[1];
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    useEffect(function () {
        if (!visible && selectedCustomers.length) {
            fetchCallback();
        }
    }, [
        refreshFlag,
        selectedCustomers,
        selectedLocations,
        search,
        filters,
        page,
        pageSize,
        onlyIntralox,
        measure,
        personalSettingMeasure,
    ]);
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                selectedInstallationPoints: [],
            },
        });
    }, [selectedCustomers, selectedLocations, search, filters, onlyIntralox]);
    var customersData = useCustomersQuery().data;
    var customers = customersData.customers;
    var locationsAssetsTreeData = useLocationsAssetsTreeQuery().data;
    var list = locationsAssetsTreeData.list;
    var selectedEntity = useMemo(
    // @ts-ignore
    function () {
        var _a, _b;
        return selectedCustomers.length
            ? " ".concat(customers
                .filter(function (_a) {
                var customer_id = _a.customer_id;
                return selectedCustomers.includes(customer_id);
            })
                .map(function (_a) {
                var name = _a.name;
                return name;
            })
                .join(', '), " customer").concat(selectedCustomers.length > 1 ? 's' : '')
            : (_b = '' + ((_a = list.find(function (el) { return el.value === _head(selectedLocations); })) === null || _a === void 0 ? void 0 : _a.label) + ' location') !== null && _b !== void 0 ? _b : '';
    }, [customers, selectedCustomers]);
    if (!selectedCustomers.length) {
        return (_jsx(React.Fragment, { children: _jsx(Row, { className: 'my-3', children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { children: "Suggested Levels" }), _jsx(Row, { children: _jsx(Col, { md: 3, children: _jsx(Alert, { color: "danger", className: "mt-3", children: "Choose customer first" }) }) })] }) }) }) }));
    }
    return (_jsxs("div", { className: "page-suggestedLevels", children: [_jsxs("h3", { className: "page-title justify-content-between d-flex", children: ["Suggested Levels", _jsxs("div", { children: [total > 0 ? (_jsx(SettingsButton, { dispatch: dispatch, settingsMode: settingsMode, setIsVisibleSetSettingsModal: setIsVisibleSetSettingsModal, selectedInstallationPoints: Object.values(selectedInstallationPoints) })) : (_jsx(_Fragment, {})), _jsx(RecalculationQueue, { payload: {
                                    selected_customer_ids: selectedCustomers,
                                    location_id: _head(selectedLocations),
                                    search_value: search,
                                }, getForString: function (queueItem) {
                                    return "".concat(queueItem.customer.name, " ").concat(queueItem.location ? " - ".concat(queueItem.location.name) : '');
                                } })] })] }), _jsx(Filters, { state: state, dispatch: dispatch }), _jsx(SuggestedLevelsContent, { state: state, dispatch: dispatch, fetchCallback: fetchCallback, dispatchSuggestedLevelModal: dispatchSuggestedLevelModal, stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal: setStateSuggestedLevelFormulaModal }), _jsx(SuggestedLevelModal, { stateSuggestedLevelModal: stateSuggestedLevelModal, dispatchSuggestedLevelModal: dispatchSuggestedLevelModal, getSuggestedAlertConditionDateEndpoint: function (options) {
                    return Endpoints.remastered.getSuggestedAlertConditionData(options);
                }, getSuggestedAlertConditionHandler: function (resp, dispatchSuggestedLevelModal) {
                    return getSuggestedAlertConditionHandler(resp, dispatchSuggestedLevelModal);
                }, updateLevelBySuggestedValueEndpoint: function (options) {
                    return Endpoints.remastered.updateLevelBySuggestedValue(__assign(__assign({}, options), { customer_id: stateSuggestedLevelModal.equipment.customer_id }));
                }, deleteLevelBySuggestedValueEndpoint: function (options) {
                    return Endpoints.remastered.deleteLevelBySuggestedValue(__assign(__assign({}, options), { customer_id: stateSuggestedLevelModal.equipment.customer_id }));
                }, deleteAlertConditionComment: function (id) { return Endpoints.remastered.deleteAlertConditionComment(id); }, saveAlertConditionsComment: function (options) {
                    return Endpoints.remastered.saveAlertConditionsComment(options);
                } }), stateSuggestedLevelFormulaModal.visible ? (_jsx(SuggestedLevelFormulaModal, { callback: function () { return fetchCallback(); }, stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal })) : (_jsx(_Fragment, {})), isVisibleSetSettingsModal ? (_jsx(SuggestedLevelsSettingsModal, { visible: isVisibleSetSettingsModal, onCancel: function () { return setIsVisibleSetSettingsModal(false); }, selectedInstallationPoints: Object.values(selectedInstallationPoints), filters: filters, onlyIntralox: !!onlyIntralox, search: search, savingQuery: {
                    selected_customer_ids: selectedCustomers,
                    location_id: _head(selectedLocations),
                }, recalculatePayload: {
                    selected_customer_ids: selectedCustomers,
                    location_id: _head(selectedLocations),
                    search_value: search,
                }, selectedEntity: selectedEntity })) : (_jsx(_Fragment, {}))] }));
};
export default memo(SuggestedLevels);
