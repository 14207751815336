import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaChartPie } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault, } from '../../../features/watchersStatistic';
import { useAllAiStatisticQuery, } from '../../../services/watchersStatistic/useAllAiStatisticQuery';
import { selectDateRange } from '../../../store/watchersStatistic/useDateRangeStore';
import Loader from '../../Loader';
import AllAnalyticsTable from './components/AllAiAnalyticsTable';
import ColumnChart from './components/ColumnChart';
import PieChart from './components/PieChart';
import SplineChart from './components/SplineChart';
var AllStatisticTab = function () {
    var dateRange = selectDateRange();
    var _a = useState(false), isShowChartsBlock = _a[0], setIsShowChartsBlock = _a[1];
    var _b = useAllAiStatisticQuery(), allAiStatisticData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    if (!allAiStatisticData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    var data = allAiStatisticData.data, statisticByAnalysts = allAiStatisticData.statisticByAnalysts, statisticByState = allAiStatisticData.statisticByState, statisticByDays = allAiStatisticData.statisticByDays;
    return (_jsxs(_Fragment, { children: [_jsxs("h4", { children: ["All AI Statistic from ", getFormattedDateFromOrDefault(dateRange[0], 'YYYY-MM-DD'), " to", ' ', getFormattedDateToOrDefault(dateRange[1], 'YYYY-MM-DD'), _jsx(Button, { color: "primary", onClick: function () { return setIsShowChartsBlock(function (prev) { return !prev; }); }, size: "sm", className: "ms-2", children: _jsx(FaChartPie, {}) })] }), !isShowChartsBlock ? (_jsx(AllAnalyticsTable, { data: data })) : (_jsxs(Row, { children: [_jsx(Col, { className: "mb-3", md: 6, children: _jsx(ColumnChart, { options: statisticByState }) }), _jsx(Col, { className: "mb-3", md: 6, children: _jsx(PieChart, { options: statisticByAnalysts }) }), _jsx(Col, { md: 12, className: "mt-2", children: _jsx(SplineChart, { options: statisticByDays }) })] }))] }));
};
export default memo(AllStatisticTab);
