import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { InList } from '../../../widgets/InList';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import Filters from '../../components/badData/Filter/Filter';
import { highlightRow } from '../../helper/table';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'created_at',
                title: 'Date',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { timeBreak: true, dateTime: row.created_at, id: "bad_sensors_created_at".concat(row.id) }));
                },
            },
            {
                name: 'user_name',
                title: 'Added by',
                sortable: true,
            },
            {
                name: 'tags',
                title: 'Tags',
                sortable: false,
                component: function (row) {
                    if (row.tags.length === 0) {
                        return _jsx(_Fragment, { children: "---" });
                    }
                    return (_jsx(_Fragment, { children: row.tags.map(function (tag) { return (_jsxs("div", { children: [_jsx("b", { className: "text-dark", children: tag.tag }), ": ", tag.reading_types_labels] }, "tag_".concat(row.id, "_").concat(tag.tag))); }) }));
                },
            },
            {
                name: 'description',
                title: 'Description',
                sortable: true,
            },
            {
                name: 'status',
                title: 'Status',
                sortable: false,
                component: function (row) {
                    return _jsx(InList, { entity: row });
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-2", children: _jsx(Button, { onClick: function (ev) {
                                        onViewClick(row.installation_point_id);
                                        highlightRow(ev);
                                    }, color: "primary", className: "button-ml", size: "sm", children: "View" }) }), _jsx(ButtonGroup, { className: "me-2", children: _jsx(Button, { color: row.charts.length === 0 ? 'secondary' : 'primary', size: "sm", onClick: function () { return callbacks.onShowChartImages(row); }, disabled: row.charts.length === 0, children: "Image" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { color: "danger", onClick: function () { return callbacks.onDeleteBadDataItem(row.id); }, size: "sm", children: "Delete" }) })] }));
                },
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Filters, { onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, selectedTags: filtersData.selectedTags, onClickFilterTag: callbacks.onClickFilterTag, setSelectedReadingTypes: callbacks.setSelectedReadingTypes }));
        },
        filtersData: {
            dateRange: [],
            selectedTags: [],
            selectedReadingTypes: [],
        },
    };
};
