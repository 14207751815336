var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { DEFAULT_CHARTS_CONFIG } from '../../../../config/charts/defaultChartsConfig';
import { getRmsAccVelExtraType } from '../../../../features/charts/shared';
import { getUoms, isCfChart, isPinChart, setCustomZoom, setPanning } from '../../../../helper/chart';
import { RmsTooltipFormatter, positioner, syncRms } from '../components/shared/tooltip';
import { toggleRmsCursor } from '../features/shared/cursor';
import { calculateSize } from '../features/shared/size';
export var config = function (chartIdentifier, readingTypes, setStatesChartStore, chartView, prefix, timezone, measure, personalSettingMeasure) { return ({
    options: __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
            zoomType: 'x',
            panning: true,
            animation: false,
            panKey: 'shift',
            selectionMarkerFill: 'rgba(51,92,173,0)',
            events: {},
            marginTop: 80,
            height: calculateSize(chartView).height,
            width: null,
        }, title: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.title), { text: _get(readingTypes, [chartIdentifier, 'axis'], '') }), yAxis: [
            __assign(__assign({}, DEFAULT_CHARTS_CONFIG.yAxis[0]), { title: {
                    text: "".concat(_get(readingTypes, [chartIdentifier, 'title']), " ").concat(isCfChart(chartIdentifier) || isPinChart(chartIdentifier)
                        ? ''
                        : "(".concat(getUoms(chartIdentifier, measure, personalSettingMeasure, readingTypes), ") ").concat(getRmsAccVelExtraType(chartIdentifier))),
                } }),
        ], xAxis: [
            __assign(__assign({}, DEFAULT_CHARTS_CONFIG.xAxis[0]), { crosshair: false, zoomEnabled: false, events: {
                    setExtremes: function (e) {
                        if (e.trigger === 'pan') {
                            setPanning(e.min, e.max, setStatesChartStore);
                        }
                        if (e.trigger === 'zoom') {
                            setCustomZoom(e.min, e.max, setStatesChartStore);
                        }
                    },
                    afterSetExtremes: function (e) {
                        if (e.target.chart.resetZoomButton) {
                            e.target.chart.resetZoomButton.element.style.display = 'none';
                        }
                    },
                } }),
        ], plotOptions: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions), { series: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series), { point: {
                    events: {
                        click: function (ev) {
                            if (['button', 'i'].includes(_get(ev.target, 'localName')) ||
                                [
                                    'fft-labels-shape ',
                                    'selected-shape',
                                    'highcharts-label-box highcharts-annotation-label-box',
                                ].includes(_get(ev.target, 'className.animVal') || _get(ev.target, 'className'))) {
                                return;
                            }
                            toggleRmsCursor(setStatesChartStore, _get(ev.point.series.chart.userOptions, 'isLockedRmsCursor'), ev.point.series.chart);
                        },
                        mouseOver: function () {
                            syncRms(this);
                        },
                    },
                } }) }), series: [], tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { split: true, positioner: function (labelWidth, labelHeight, point) {
                // @ts-ignore
                return positioner(labelWidth, labelHeight, point, this.chart);
            }, formatter: function () {
                // @ts-ignore
                var points = this.points.filter(function (point) {
                    return point.series.options.id;
                });
                return ReactDOMServer.renderToStaticMarkup((_jsx(RmsTooltipFormatter, { points: points, 
                    // @ts-ignore
                    allPoints: this.points, prefix: prefix, timezone: timezone !== null && timezone !== void 0 ? timezone : 'America/New_York', 
                    // @ts-ignore
                    xValue: this.x })));
            } }), chartIdentifier: chartIdentifier, annotations: [] }),
    loader: true,
    seriesIsRendered: false,
    isDataAvailable: true,
    overlayInstallationPointsLoader: 0,
    mainSeriesLoaded: false,
    isVisibleFullscreenChart: false,
    isVisibleLockTooltip: false,
    ambientDataIsVisible: false,
    normalizedReadingsIsVisible: false,
    isAutoAlertConditions: false,
    isChartSelectionChecked: false,
    isChartPainting: false,
    hidePeaksOptions: {
        peak: null,
    },
    readings: [],
    ambient: [],
    chartMarks: [],
    alertConditions: {},
    lockTooltipPosition: 'top-right',
}); };
