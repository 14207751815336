var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { useCustomersQuery } from '../../../services/useCustomersQuery';
import { useLeadAnalystQuery } from '../../../services/useLeadAnalystQuery';
import { selectSelectedCustomers, useSelectedCustomersActions } from '../../../store/SelectedCustomersStore';
import { selectSelectedLeadAnalyst } from '../../../store/SelectedLeadAnalystStore';
import { useSelectedLocationsActions } from '../../../store/SelectedLocationsStore';
var CustomersSelect = function () {
    var trackEvent = useMatomo().trackEvent;
    var _a = useCustomersQuery(), customersData = _a.data, customersIsLoading = _a.isLoading, customersIsFetching = _a.isFetching;
    var customers = customersData.customers;
    var leadAnalystData = useLeadAnalystQuery({ customers: customers }).data;
    var leadAnalyst = leadAnalystData.data;
    var setSelectedCustomers = useSelectedCustomersActions().setSelectedCustomers;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLeadAnalyst = selectSelectedLeadAnalyst();
    var options = useMemo(function () {
        var _a;
        var customerAnalyticLeads = (leadAnalyst.find(function (_a) {
            var id = _a.id;
            return id === selectedLeadAnalyst[0];
        }) || {}).customerAnalyticLeads;
        return ((_a = customerAnalyticLeads !== null && customerAnalyticLeads !== void 0 ? customerAnalyticLeads : customers) !== null && _a !== void 0 ? _a : []).map(function (customer) { return ({
            label: customer.name,
            id: customer.customer_id,
            value: customer.customer_id,
        }); });
    }, [customers, leadAnalyst, selectedLeadAnalyst]);
    var areAllCustomersSelected = options.length === selectedCustomers.length;
    var selectAllOption = { label: 'All customers', id: 0, value: 0 };
    var onChangeHandler = function (items) {
        var selectedCustomerIds = [];
        var customerNames = [];
        if (items.some(function (el) { return el.value === selectAllOption.value; })) {
            options.forEach(function (_a) {
                var value = _a.value, label = _a.label;
                selectedCustomerIds.push(value);
                customerNames.push(label);
            });
        }
        else {
            items.forEach(function (_a) {
                var value = _a.value, label = _a.label;
                selectedCustomerIds.push(value);
                customerNames.push(label);
            });
        }
        setSelectedLocations([]);
        setSelectedCustomers(selectedCustomerIds);
        trackEvent({
            category: 'Base',
            action: 'Change customers',
            name: customerNames.join(', '),
            href: window.location.href,
        });
    };
    return (_jsx(Select, { name: "customerSelector", isMulti: true, isLoading: customersIsLoading || customersIsFetching, classNamePrefix: "customSelectStyleDefault", className: "customCustomerSelect", placeholder: "All customers", value: selectedCustomers.map(function (value) {
            var _a, _b;
            return ({
                label: (_b = (_a = options.find(function (el) { return el.value === value; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '',
                value: value,
            });
        }), onChange: onChangeHandler, options: areAllCustomersSelected ? options : __spreadArray([selectAllOption], options, true) }));
};
export default memo(CustomersSelect);
