import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import OnDemandStatus from '../../../widgets/OnDemandStatus';
import { DateTimeLabel, LossPacketsAlert } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import { Filters } from '../../components/onDemandUsersRequests/Filter';
import { highlightRow } from '../../helper/table';
export var config = function (onViewClick, extraFilter) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'created_at',
                title: 'Time',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "on_demand_users_requests".concat(row.id), dateTime: row.created_at });
                },
            },
            {
                name: 'username',
                title: 'Requester',
                sortable: true,
            },
            {
                name: '',
                title: '',
                sortable: false,
                component: function (row) {
                    return (_jsx("span", { children: _jsx(LossPacketsAlert, { isCollapsed: true, id: "loss_packets_alert_".concat(row.id), lossPacketsPercent: row.packet_loss_percent ? +row.packet_loss_percent : null }) }));
                },
            },
            {
                name: 'status',
                title: 'Status',
                sortable: false,
                component: function (row) {
                    return (_jsx(OnDemandStatus, { estimatedAt: row.estimated_at, readingFftOnDemand: row.readingFftOnDemand || row.readingFftHfdvueOnDemand, requestId: row.request_id }));
                },
            },
            {
                name: 'settings',
                title: 'Settings',
                sortable: false,
                component: function (row) {
                    if (!row.fmax && !row.lor) {
                        return _jsx(_Fragment, { children: "No data" });
                    }
                    return (_jsxs(_Fragment, { children: [row.fmax && (_jsxs("div", { children: [_jsx("b", { children: "FMax: " }), " ", row.fmax] })), row.lor && (_jsxs("div", { children: [_jsx("b", { children: "LOR: " }), " ", row.lor] }))] }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(Button, { onClick: function () {
                            onViewClick(row.installation_point_id);
                            highlightRow();
                        }, color: "primary", className: "mr-2", size: "sm", children: "View" }));
                },
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Row, { className: "response-flex", children: _jsx(Col, { className: "mb-2", children: _jsx(Filters, { filtersData: filtersData, onChangeDateRange: callbacks.onChangeDateRange, onUserChange: callbacks.onUserChange, children: extraFilter ? extraFilter(callbacks, filtersData) : '' }) }) }));
        },
        filtersData: {
            dateRange: [],
            users: [],
        },
    };
};
