import { createStore } from '../createStore';
var useActionItemModalsStore = createStore(function (set) { return ({
    reportEditorEntityEditState: {
        visible: false,
        id: 0,
        name: '',
        entityName: 'componentType',
    },
    recommendationsReassignModalState: {
        visible: false,
        onSave: function () { },
        recommendations: [],
    },
    closeReportModalState: {
        visible: false,
        onSave: function () { },
        reportProcessingType: 'accept',
    },
    isVisibleSnoozeModal: false,
    isVisibleAwaitingUpdateModal: false,
    isVisibleChangeToHardwareIssueModal: false,
    isVisibleHardwareAssistanceModal: false,
    isVisibleWaitingModal: false,
    isVisibleQuestionModal: false,
    isVisibleCancelHardwareAssistanceModal: false,
    isVisibleCancelAndCloseModal: false,
    isVisibleViewedAILogModal: false,
    isVisibleCommentForMlTeamModal: false,
    isVisibleAiMissModal: false,
    isVisibleMachineInformationModal: false,
    isVisibleCreateAiModal: false,
    idPreviousAiReportForModal: null,
    actions: {
        setReportEditorEntityEditState: function (reportEditorEntityEditState) { return set({ reportEditorEntityEditState: reportEditorEntityEditState }); },
        setRecommendationsReassignModalState: function (recommendationsReassignModalState) {
            return set({ recommendationsReassignModalState: recommendationsReassignModalState });
        },
        setCloseReportModalState: function (closeReportModalState) { return set({ closeReportModalState: closeReportModalState }); },
        setIsVisibleSnoozeModal: function (isVisibleSnoozeModal) { return set({ isVisibleSnoozeModal: isVisibleSnoozeModal }); },
        setIsVisibleAwaitingUpdateModal: function (isVisibleAwaitingUpdateModal) {
            return set({ isVisibleAwaitingUpdateModal: isVisibleAwaitingUpdateModal });
        },
        setIsVisibleChangeToHardwareIssueModal: function (isVisibleChangeToHardwareIssueModal) {
            return set({ isVisibleChangeToHardwareIssueModal: isVisibleChangeToHardwareIssueModal });
        },
        setIsVisibleHardwareAssistanceModal: function (isVisibleHardwareAssistanceModal) {
            return set({ isVisibleHardwareAssistanceModal: isVisibleHardwareAssistanceModal });
        },
        setIsVisibleWaitingModal: function (isVisibleWaitingModal) { return set({ isVisibleWaitingModal: isVisibleWaitingModal }); },
        setIsVisibleQuestionModal: function (isVisibleQuestionModal) { return set({ isVisibleQuestionModal: isVisibleQuestionModal }); },
        setIsVisibleCancelHardwareAssistanceModal: function (isVisibleCancelHardwareAssistanceModal) {
            return set({ isVisibleCancelHardwareAssistanceModal: isVisibleCancelHardwareAssistanceModal });
        },
        setIsVisibleCancelAndCloseModal: function (isVisibleCancelAndCloseModal) {
            return set({ isVisibleCancelAndCloseModal: isVisibleCancelAndCloseModal });
        },
        setIsVisibleViewedAILogModal: function (isVisibleViewedAILogModal) { return set({ isVisibleViewedAILogModal: isVisibleViewedAILogModal }); },
        setIsVisibleCommentForMlTeamModal: function (isVisibleCommentForMlTeamModal) {
            return set({ isVisibleCommentForMlTeamModal: isVisibleCommentForMlTeamModal });
        },
        setIsVisibleAiMissModal: function (isVisibleAiMissModal) { return set({ isVisibleAiMissModal: isVisibleAiMissModal }); },
        setIsVisibleMachineInformationModal: function (isVisibleMachineInformationModal) {
            return set({ isVisibleMachineInformationModal: isVisibleMachineInformationModal });
        },
        setIsVisibleCreateAiModal: function (isVisibleCreateAiModal) { return set({ isVisibleCreateAiModal: isVisibleCreateAiModal }); },
        setIdPreviousAiReportForModal: function (idPreviousAiReportForModal) { return set({ idPreviousAiReportForModal: idPreviousAiReportForModal }); },
    },
}); });
export var useActionItemModalsActions = function () {
    return useActionItemModalsStore(function (state) { return state.actions; });
};
export var selectReportEditorEntityEditState = function () {
    return useActionItemModalsStore(function (state) { return state.reportEditorEntityEditState; });
};
export var selectRecommendationsReassignModalState = function () {
    return useActionItemModalsStore(function (state) { return state.recommendationsReassignModalState; });
};
export var selectCloseReportModalState = function () {
    return useActionItemModalsStore(function (state) { return state.closeReportModalState; });
};
export var selectIsVisibleSnoozeModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleSnoozeModal; });
};
export var selectIsVisibleAwaitingUpdateModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleAwaitingUpdateModal; });
};
export var selectIsVisibleChangeToHardwareIssueModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleChangeToHardwareIssueModal; });
};
export var selectIsVisibleHardwareAssistanceModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleHardwareAssistanceModal; });
};
export var selectIsVisibleWaitingModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleWaitingModal; });
};
export var selectIsVisibleQuestionModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleQuestionModal; });
};
export var selectIsVisibleCancelHardwareAssistanceModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleCancelHardwareAssistanceModal; });
};
export var selectIsVisibleCancelAndCloseModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleCancelAndCloseModal; });
};
export var selectIsVisibleViewedAILogModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleViewedAILogModal; });
};
export var selectIsVisibleCommentForMlTeamModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleCommentForMlTeamModal; });
};
export var selectIsVisibleAiMissModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleAiMissModal; });
};
export var selectIsVisibleMachineInformationModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleMachineInformationModal; });
};
export var selectIsVisibleCreateAiModal = function () {
    return useActionItemModalsStore(function (state) { return state.isVisibleCreateAiModal; });
};
export var selectIdPreviousAiReportForModal = function () {
    return useActionItemModalsStore(function (state) { return state.idPreviousAiReportForModal; });
};
