import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo } from 'react';
import { Badge } from 'reactstrap';
import { ACTION_LIST } from '../../../../../../../constants';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
import { getLabelData } from '../features';
var WWAiLabel = function (_a) {
    var _b, _c, _d;
    var existInList = _a.existInList;
    var data = getLabelData(existInList, 'wwActionList');
    var isTroubleshootingHold = +((_b = data === null || data === void 0 ? void 0 : data.value) === null || _b === void 0 ? void 0 : _b.is_troubleshooting_hold) === 1;
    var isWaitesNew = (Number((_c = data === null || data === void 0 ? void 0 : data.value) === null || _c === void 0 ? void 0 : _c.analyst_comments_count) === 0 && ((_d = data === null || data === void 0 ? void 0 : data.value) === null || _d === void 0 ? void 0 : _d.state) == ACTION_LIST.STATE_WAITING) ||
        null;
    return (_jsx(_Fragment, { children: data ? (_jsx("div", { className: "tag-item", children: _jsxs(TooltipBlockData, { icon: _jsx(Badge, { color: "secondary", children: "WWAI" }), children: [_jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Urgency:" }), ACTION_LIST.URGENCY_NAMES[+data.value.urgency] ||
                                '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "State:" }), isTroubleshootingHold
                                ? ACTION_LIST.STATUS_NAMES[ACTION_LIST.STATE_TROUBLESHOOTING_HOLD]
                                : isWaitesNew
                                    ? ACTION_LIST.STATUS_NAMES[ACTION_LIST.STATE_WW_NEW]
                                    : ACTION_LIST.STATUS_NAMES[+data.value.state] ||
                                        '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Time created:" }), "UTC: ", moment(data.value.time_created).format('MM/DD/YYYY, h:mm A') || '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Updated at:" }), "UTC: ", moment(data.value.updated_at).format('MM/DD/YYYY, h:mm A') || '---'] })] }) })) : (_jsx(_Fragment, {})) }));
};
export default memo(WWAiLabel);
