import { jsx as _jsx } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../constants';
import { usePrevious } from '../../customHooks';
import { makeValidLink } from '../helper/routeHelper';
var SystemTypeContext = React.createContext({});
var SystemTypeProvider = function (props) {
    var pathname = useLocation().pathname;
    var trackEvent = useMatomo().trackEvent;
    var _a = useState(pathname.includes('/amazon') ? SYSTEM_TYPE_AMAZON : SYSTEM_TYPE_REMASTERED), selectedSystemType = _a[0], setSelectedSystemType = _a[1];
    var prevSelectedSystemType = usePrevious(selectedSystemType);
    var _b = useLocation(), hash = _b.hash, search = _b.search;
    useEffect(function () {
        if (!prevSelectedSystemType || prevSelectedSystemType === selectedSystemType) {
            return;
        }
        window.location.href = makeValidLink('/' +
            SYSTEM_TYPES_DATA[selectedSystemType].prefix +
            pathname.replaceAll('/amazon', '') +
            search +
            hash);
        trackEvent({
            category: 'Base',
            action: 'Change system type',
            name: selectedSystemType,
            href: window.location.href,
        });
    }, [selectedSystemType]);
    return (_jsx(SystemTypeContext.Provider, { value: [selectedSystemType, setSelectedSystemType], children: props.children }));
};
export { SystemTypeContext, SystemTypeProvider };
