import { useEffect, useMemo, useRef, useState } from 'react';
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};
export var useOnScreen = function (ref, resetDeps) {
    var _a = useState(false), isIntersecting = _a[0], setIntersecting = _a[1];
    var observer = useMemo(function () {
        return new IntersectionObserver(function (_a) {
            var entry = _a[0];
            setIntersecting(entry.isIntersecting);
        });
    }, [ref]);
    useEffect(function () {
        if (isIntersecting) {
            observer.disconnect();
            return;
        }
        if (!ref.current) {
            return;
        }
        observer.observe(ref.current);
        return function () { return observer.disconnect(); };
    }, [isIntersecting]);
    useEffect(function () {
        if (resetDeps && resetDeps.length > 0) {
            if (!ref.current) {
                setIntersecting(false);
                return;
            }
            observer.observe(ref.current);
        }
    }, resetDeps);
    return isIntersecting;
};
export var useTitle = function (title) {
    var _a = useState(title), documentTitle = _a[0], setDocumentTitle = _a[1];
    useMemo(function () {
        document.title = documentTitle;
    }, [documentTitle]);
    return [documentTitle, setDocumentTitle];
};
export var useInterval = function (callback, delay) {
    var savedCallback = useRef();
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
};
