var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloneDeep as _cloneDeep, filter as _filter, get as _get } from 'lodash';
export var onChangeVisibleCheckBox = function (state, type, dispatch, equipment_id) {
    var data = state.data;
    dispatch({
        type: 'setState',
        state: {
            data: data.map(function (equipment) {
                var _a;
                var _b;
                if (+equipment.id === +equipment_id) {
                    return __assign(__assign({}, equipment), { certain: __assign(__assign({}, equipment.certain), (_a = {}, _a[type] = __assign(__assign({}, equipment.certain[type]), { is_visible: !((_b = equipment.certain[type]) === null || _b === void 0 ? void 0 : _b.is_visible) }), _a)) });
                }
                return equipment;
            }),
        },
    });
};
export var onChangeToggleCheckedInstallPoint = function (state, dispatch, type, installationPointId, equipment_id) {
    var data = state.data;
    dispatch({
        type: 'setState',
        state: {
            data: data.map(function (equipment) {
                var _a;
                if (+equipment.id === +equipment_id) {
                    return __assign(__assign({}, equipment), { certain: __assign(__assign({}, equipment.certain), (_a = {}, _a[type] = __assign(__assign({}, equipment.certain[type]), { installPointIds: equipment.certain[type].installPointIds.includes(installationPointId)
                                ? equipment.certain[type].installPointIds.filter(function (el) { return el !== installationPointId; })
                                : __spreadArray(__spreadArray([], equipment.certain[type].installPointIds, true), [installationPointId], false) }), _a)) });
                }
                return equipment;
            }),
        },
    });
};
export var onUpdateButtonClick = function (dispatchSuggestedLevelModal, fetchCallback, installationPoint, equipment, units, readingTypes, serial, certain) {
    if (serial === void 0) { serial = null; }
    if (certain === void 0) { certain = null; }
    dispatchSuggestedLevelModal({
        type: 'setState',
        state: {
            visible: true,
            onCancel: function () {
                return dispatchSuggestedLevelModal({
                    type: 'setState',
                    state: {
                        visible: false,
                        onCancel: function () { },
                        callback: function (visibleEquipmentId) { return fetchCallback(visibleEquipmentId); },
                        installationPoint: {},
                        equipment: {},
                        units: null,
                        serial: null,
                        readingTypes: [],
                    },
                });
            },
            callback: function (visibleEquipmentId) { return fetchCallback(visibleEquipmentId); },
            installationPoint: installationPoint,
            equipment: equipment,
            units: units,
            serial: serial,
            readingTypes: readingTypes,
            certain: certain,
        },
    });
};
export var onEditFormulaButtonClick = function (stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal, data) {
    setStateSuggestedLevelFormulaModal({
        type: 'setState',
        state: __assign(__assign({}, stateSuggestedLevelFormulaModal), { visible: true, data: data }),
    });
};
export var toggleEquipment = function (state, dispatch, equipment_id) {
    var data = state.data;
    dispatch({
        type: 'setState',
        state: {
            data: data.map(function (equipment) {
                if (+equipment.id === +equipment_id) {
                    return __assign(__assign({}, equipment), { is_visible: !equipment.is_visible });
                }
                return equipment;
            }),
        },
    });
};
export var onChangeOnlyIntralox = function (dispatch, onlyIntralox) {
    dispatch({
        type: 'setState',
        state: {
            onlyIntralox: onlyIntralox === 1 ? 0 : 1,
        },
    });
};
export var onChangeFilter = function (state, dispatch, field, value) {
    var _a;
    var filters = state.filters;
    dispatch({
        type: 'setState',
        state: {
            filters: __assign(__assign({}, filters), (_a = {}, _a[field] = value, _a)),
        },
    });
};
export var onChangeSearch = function (state, dispatch, value) {
    var pagination = state.pagination;
    dispatch({
        type: 'setState',
        state: {
            pagination: __assign(__assign({}, pagination), { page: 1 }),
            search: value,
        },
    });
};
export var onChangePagination = function (state, dispatch, field, value) {
    var _a;
    var pagination = state.pagination;
    dispatch({
        type: 'setState',
        state: {
            pagination: __assign(__assign({}, pagination), (_a = {}, _a[field] = value, _a)),
        },
    });
};
export var onToggleCollapseExpand = function (state, dispatch, type) {
    var data = state.data;
    dispatch({
        type: 'setState',
        state: {
            data: data.map(function (el) {
                return __assign(__assign({}, el), { is_visible: type === 'expand' });
            }),
        },
    });
};
export var hasAlertConditionsAction = function (alertLevelsSettings, readingType, typeSettings) {
    if (typeSettings) {
        var type = typeSettings + 'Settings';
        return ((alertLevelsSettings[readingType] && alertLevelsSettings[readingType][type].is_ai) ||
            (alertLevelsSettings[readingType] && alertLevelsSettings[readingType][type].is_pre_ai) ||
            false);
    }
    return ((alertLevelsSettings[readingType] && alertLevelsSettings[readingType].cautionSettings.is_ai) ||
        (alertLevelsSettings[readingType] && alertLevelsSettings[readingType].cautionSettings.is_pre_ai) ||
        (alertLevelsSettings[readingType] && alertLevelsSettings[readingType].warningSettings.is_ai) ||
        (alertLevelsSettings[readingType] && alertLevelsSettings[readingType].warningSettings.is_pre_ai) ||
        false);
};
export var getCalculationAlertLevelsSettings = function (installationPoint, readingTypes) {
    var alertLevelsSettings = {};
    readingTypes.map(function (readingType) {
        var _a;
        var alertConditions = _filter(installationPoint.alertCondition, function (alertCondition) {
            return +readingType === +alertCondition.reading_type_id && alertCondition.equipment_type === 'sensor';
        }), alertConditionCaution = _filter(alertConditions, function (alertCondition) {
            return _get(alertCondition, 'alertLevel.name', '').toLowerCase() === 'caution';
        })[0], alertConditionWarning = _filter(alertConditions, function (alertCondition) {
            return _get(alertCondition, 'alertLevel.name', '').toLowerCase() === 'warning';
        })[0];
        alertLevelsSettings = __assign(__assign({}, alertLevelsSettings), (_a = {}, _a[readingType] = {
            cautionSettings: {
                is_pre_ai: !!+_get(alertConditionCaution, 'setting.is_pre_ai'),
                is_ai: !!+_get(alertConditionCaution, 'setting.is_ai'),
                value: _get(alertConditionCaution, 'value'),
                username: _get(alertConditionCaution, 'setting.user_name', ''),
                trigger_time: _get(alertConditionCaution, 'setting.trigger_time'),
            },
            warningSettings: {
                is_pre_ai: !!+_get(alertConditionWarning, 'setting.is_pre_ai'),
                is_ai: !!+_get(alertConditionWarning, 'setting.is_ai'),
                value: _get(alertConditionWarning, 'value'),
                username: _get(alertConditionWarning, 'setting.user_name', ''),
                trigger_time: _get(alertConditionWarning, 'setting.trigger_time'),
            },
        }, _a));
    });
    return alertLevelsSettings;
};
export var onChangeSettingsMode = function (dispatch, settingsMode) {
    dispatch({
        type: 'setState',
        state: {
            settingsMode: !settingsMode,
        },
    });
};
export var onToggleSelectedInstallationPoints = function (dispatch, installationPoints, selectedInstallationPoints, isInsert) {
    if (isInsert === void 0) { isInsert = true; }
    if (isInsert) {
        dispatch({
            type: 'setState',
            state: {
                selectedInstallationPoints: __assign(__assign({}, selectedInstallationPoints), installationPoints.reduce(function (map, installationPoint) { return ((map[installationPoint.id] = installationPoint), map); }, {})),
            },
        });
        return;
    }
    selectedInstallationPoints = _cloneDeep(selectedInstallationPoints);
    installationPoints.forEach(function (installationPoint) { return delete selectedInstallationPoints[installationPoint.id]; });
    dispatch({
        type: 'setState',
        state: {
            selectedInstallationPoints: selectedInstallationPoints,
        },
    });
};
