import { shallow } from 'zustand/shallow';
import { clearAllTooltips } from '../../helper/commonHelper';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { selectChartZoomDays, useChartSettingsActions } from '../../store/charts/useChartSettingsStore';
import { selectAlertLevelsHotkey, selectChartMarksHotkey, selectHarmonicFftTwfCursorHotkey, selectNormalFftTwfCursorHotkey, selectResetRmsZoomHotkey, selectSidebandFftTwfCursorHotkey, selectTooltipsHotkey, } from '../../store/hotkeys/useChartsHotkeysStore';
import { useHotkeyEvents } from './useHotkeyEvents';
export var useChartHotkey = function () {
    var _a = ChartsStore(function (state) { return ({
        setStates: state.setStates,
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
        chartTooltipsToggle: state.chartTooltipsToggle,
        hideChartMarks: state.hideChartMarks,
    }); }, shallow), setStates = _a.setStates, chartAlertLevelsVisibleToggle = _a.chartAlertLevelsVisibleToggle, chartTooltipsToggle = _a.chartTooltipsToggle, hideChartMarks = _a.hideChartMarks;
    var setChartZoomDays = useChartSettingsActions().setChartZoomDays;
    var chartZoomDays = selectChartZoomDays();
    var alertLevelsHotkey = selectAlertLevelsHotkey();
    var tooltipsHotkey = selectTooltipsHotkey();
    var chartMarksHotkey = selectChartMarksHotkey();
    var resetRmsZoomHotkey = selectResetRmsZoomHotkey();
    var normalFftTwfCursorHotkey = selectNormalFftTwfCursorHotkey();
    var harmonicFftTwfCursorHotkey = selectHarmonicFftTwfCursorHotkey();
    var sidebandFftTwfCursorHotkey = selectSidebandFftTwfCursorHotkey();
    useHotkeyEvents(function (hotkey) {
        if (alertLevelsHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                chartAlertLevelsVisibleToggle: !chartAlertLevelsVisibleToggle,
            });
        }
    }, [chartAlertLevelsVisibleToggle, alertLevelsHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (tooltipsHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                chartTooltipsToggle: !chartTooltipsToggle,
            });
        }
    }, [chartTooltipsToggle, tooltipsHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (chartMarksHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                hideChartMarks: !hideChartMarks,
            });
        }
    }, [hideChartMarks, chartMarksHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (resetRmsZoomHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setChartZoomDays(+chartZoomDays);
            setStates({
                isCustomZoom: false,
                isChartResetRmsZoom: true,
            });
        }
    }, [chartZoomDays, resetRmsZoomHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (normalFftTwfCursorHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                chartCursorType: 'normal',
            });
            clearAllTooltips();
        }
    }, [normalFftTwfCursorHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (harmonicFftTwfCursorHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                chartCursorType: 'harmonic',
            });
            clearAllTooltips();
        }
    }, [harmonicFftTwfCursorHotkey]);
    useHotkeyEvents(function (hotkey) {
        if (sidebandFftTwfCursorHotkey.codes.every(function (key) { return hotkey.codes.includes(key); })) {
            setStates({
                chartCursorType: 'sideband',
            });
            clearAllTooltips();
        }
    }, [sidebandFftTwfCursorHotkey]);
};
