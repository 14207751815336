import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { usePrevious } from '../../../../customHooks';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectChartDefaultZoomDays, useChartSettingsActions, } from '../../../store/charts/useChartSettingsStore';
export var useResetToolsToDefault = function (state, chartRef) {
    var setStatesChartStore = ChartsStore(function (state) { return ({
        setStatesChartStore: state.setStates,
    }); }, shallow).setStatesChartStore;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var prevInstallationPoint = usePrevious(installationPoint);
    var mainSeriesLoaded = state.mainSeriesLoaded;
    var setChartZoomDays = useChartSettingsActions().setChartZoomDays;
    var chartDefaultZoomDays = selectChartDefaultZoomDays();
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        if (!prevInstallationPoint || prevInstallationPoint.id === installationPoint.id) {
            return;
        }
        chartRef.current.chart.userOptions.chartCursorType = 'normal';
        chartRef.current.chart.userOptions.isLockedFftCursor = false;
        chartRef.current.chart.userOptions.isLockedWithAltFftCursor = false;
        setChartZoomDays(chartDefaultZoomDays);
        setStatesChartStore({
            countDays: chartDefaultZoomDays,
            chartCursorType: 'normal',
            selectedChartsAvailable: {},
            chartsSelectedPainting: {},
            chartsSelectedRanges: {},
            customZoomExtremes: {
                min: null,
                max: null,
            },
            isSelectChartVisible: false,
            chartTooltipsToggle: false,
            chartAlertLevelsVisibleToggle: false,
            showSystemLevels: false,
            showMlSuggestedLevels: false,
            isVisibleFftShapes: false,
            isZoomEnabled: false,
            hideChartMarks: false,
            hidePeaks: false,
            isLockedRmsCursor: false,
            isZoom: false,
            isChartResetRmsZoom: false,
        });
    }, [installationPoint.id]);
};
