import { createStore } from '../../../store/createStore';
var useReportMlStore = createStore(function (set) { return ({
    isVisible: false,
    actions: {
        setIsVisible: function (isVisible) { return set({ isVisible: isVisible }); },
    },
}); }, 'ReportMlStore');
export var useReportMlActions = function () {
    return useReportMlStore(function (state) { return state.actions; });
};
export var selectIsVisibleModal = function () {
    return useReportMlStore(function (state) { return state.isVisible; });
};
