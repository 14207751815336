// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotkey-group {
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
}
.dark-layout .hotkey-group {
  border-bottom: 1px solid #3e3f4f;
}
.hotkey-group > span {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 28px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/Hotkeys/components/HotkeyGroup/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gCAAA;AACF;AAAE;EACE,gCAAA;AAEJ;AAAE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".hotkey-group {\n  padding: 10px 0;\n  border-bottom: 1px solid #d9d9d9;\n  .dark-layout & {\n    border-bottom: 1px solid #3e3f4f;\n  }\n  & > span {\n    font-size: 16px;\n    font-weight: 500;\n    text-transform: uppercase;\n    line-height: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
