import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Endpoints from '../../api/endpoints/endpoints';
import Loader from '../../shared/components/Loader';
import { SystemTypeContext } from '../../shared/context/SystemTypeContext';
import { replaceHandlerSaveData } from '../../shared/helper/chart';
import { getHost } from '../../shared/helper/customer';
import User from '../../shared/helper/user';
import { isMote } from '../../widgets/sensor';
import ReplacementBody from './components/ReplacementBody';
var ReplaceSensor = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, sensor = _a.sensor, sensorPartNumber = _a.sensorPartNumber, installationPointId = _a.installationPointId, callback = _a.callback, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b, storage = _a.storage;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useState(false), showLoader = _c[0], setShowLoader = _c[1];
    var onConfirm = function () {
        var data = isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))
            ? {
                inst_point_id: installationPointId,
                equipment_type: 'mote',
            }
            : {
                inst_point_id: installationPointId,
                user_id: User.getId(),
                equipment_type: 'sensor',
            };
        var replaceMoteSensorEndpoint = isPreAi
            ? function (payload) { return Endpoints[selectedSystemType].replacePreAiMoteSensor(payload); }
            : function (payload) {
                return Endpoints[selectedSystemType].replaceMoteSensor(payload, { host: getHost(storage) });
            };
        var replaceSensorEndpoint = isPreAi
            ? function (payload) { return Endpoints[selectedSystemType].replacePreAiSensor(payload); }
            : function (payload) { return Endpoints[selectedSystemType].replaceSensor(payload, { host: getHost(storage) }); };
        var endPoint = isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))
            ? replaceMoteSensorEndpoint
            : replaceSensorEndpoint;
        setShowLoader(true);
        return replaceHandlerSaveData(endPoint, data, setShowLoader, onCancel, callback, isPreAi);
    };
    return (_jsxs(Modal, { size: "xs", className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))
                    ? 'Mote sensor'
                    : 'Sensor' + ' replacement' }), showLoader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(ModalBody, { children: _jsx(ReplacementBody, { isPreAi: isPreAi, sensor: sensor, sensorPartNumber: sensorPartNumber }) })), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, disabled: showLoader, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onConfirm, disabled: showLoader, children: "Confirm" })] }) })] }));
};
export default ReplaceSensor;
