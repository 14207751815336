var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaInfo, FaInfoCircle } from 'react-icons/fa';
import { Alert, Badge, Input, UncontrolledTooltip } from 'reactstrap';
import StatusLabelAmazon from '../amazon/components/AIPreAi/StatusLabel';
import { SYSTEM_TYPE_REMASTERED } from '../constants';
import StatusLabelRemastered from '../remastered/components/AIPreAi/StatusLabel';
export var DateTimeLabel = function (_a) {
    var _b = _a.id, id = _b === void 0 ? Math.random().toString().slice(2) : _b, dateTime = _a.dateTime, _c = _a.fromNow, fromNow = _c === void 0 ? false : _c, _d = _a.timeBreak, timeBreak = _d === void 0 ? false : _d, _e = _a.isShowRelativeTimeInTooltip, isShowRelativeTimeInTooltip = _e === void 0 ? false : _e;
    if (!dateTime) {
        return _jsx(_Fragment, { children: "---" });
    }
    var momentDate = useMemo(function () { return moment(moment.utc(dateTime).toDate()).local(); }, [dateTime]);
    return (_jsxs(React.Fragment, { children: [_jsxs("span", { className: "cursor-pointer data-time-wrapper", id: '_' + id, children: [' ', fromNow ? (momentDate.fromNow()) : timeBreak ? (_jsxs("span", { children: [momentDate.format('MM/DD/YYYY'), ",", _jsx("br", {}), momentDate.format('h:mm A')] })) : (momentDate.format('MM/DD/YYYY, h:mm A'))] }), _jsxs(UncontrolledTooltip, { target: '_' + id, children: [isShowRelativeTimeInTooltip ? (_jsxs(_Fragment, { children: [momentDate.fromNow(), _jsx("br", {})] })) : (_jsx(_Fragment, {})), moment(dateTime).format('MM/DD/YYYY, h:mm A'), " - UTC"] })] }));
};
export var DateTimeLabelWithUTC = function (_a) {
    var dateTime = _a.dateTime, _b = _a.fromNow, fromNow = _b === void 0 ? false : _b, _c = _a.withLineBreak, withLineBreak = _c === void 0 ? false : _c, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.hideUtc, hideUtc = _e === void 0 ? false : _e;
    if (!dateTime) {
        return _jsx("div", { children: "---" });
    }
    var momentDate = moment(moment.utc(dateTime).toDate()).local();
    return (_jsxs(React.Fragment, { children: [_jsx("span", { children: fromNow ? momentDate.fromNow() : momentDate.format('MM/DD/YYYY, h:mm A') }), !hideUtc ? (_jsxs(_Fragment, { children: [withLineBreak ? _jsx("br", {}) : ' ', _jsxs("span", { className: className, children: ["(UTC: ", moment(dateTime).format('MM/DD/YYYY, h:mm A'), ")"] })] })) : (_jsx(_Fragment, {}))] }));
};
export var WatchListBadge = function () {
    return (_jsx(Badge, { color: "yellow", children: _jsx("span", { className: "dark-theme-badge", children: "In watchlist" }) }));
};
export var AiOnBedBadge = function (_a) {
    var _b = _a.onClick, onClick = _b === void 0 ? function () { } : _b;
    return (_jsx(Badge, { color: "danger", className: "cursor-pointer", onClick: onClick, children: _jsx("span", { children: "AiBed" }) }));
};
export var BadDataBadge = function () {
    return _jsx(Badge, { color: "secondary", children: "Bad Sensor" });
};
export var RegularAiBadge = function (_a) {
    var status = _a.status, is_troubleshooting_hold = _a.is_troubleshooting_hold, is_troubleshooting_waiting = _a.is_troubleshooting_waiting, is_waites_new = _a.is_waites_new, selectedSystemType = _a.selectedSystemType;
    if (status !== null) {
        return selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { status: status, isTroubleshootingHold: is_troubleshooting_hold, commentsCount: 0, isTroubleshootingWaiting: is_troubleshooting_waiting, isWaitesNew: is_waites_new })) : (_jsx(StatusLabelAmazon, { status: status, commentsCount: 0 }));
    }
    return _jsx(Badge, { color: "danger", children: "Action Item" });
};
export var HardwareAiBadge = function () {
    return _jsx(Badge, { color: "danger", children: "Network Item" });
};
export var PreActionItemBadge = function () {
    return _jsx(Badge, { color: "warning", children: "Pre-Action item" });
};
export var Money = function (_a) {
    var amount = _a.amount;
    if (!amount && amount !== 0) {
        return _jsx(_Fragment, { children: "$ -" });
    }
    return _jsx(_Fragment, { children: '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
};
export var LossPacketsAlert = function (_a) {
    var lossPacketsPercent = _a.lossPacketsPercent, _b = _a.isCollapsed, isCollapsed = _b === void 0 ? false : _b, _c = _a.id, id = _c === void 0 ? '' : _c;
    if (lossPacketsPercent === null || +lossPacketsPercent < 30) {
        return _jsx(_Fragment, {});
    }
    if (isCollapsed) {
        return (_jsxs(_Fragment, { children: [_jsx("span", { id: id, children: _jsx(Badge, { className: "badge-circle cursor-pointer", color: "danger", children: _jsx(FaInfo, {}) }) }), _jsx(UncontrolledTooltip, { target: id, children: "FFT may not be returned due poor connectivity to the device!" })] }));
    }
    return (_jsxs(Alert, { color: "danger", children: [_jsx(FaInfoCircle, {}), " FFT may not be returned due poor connectivity to the device!"] }));
};
export var DateInput = React.forwardRef(function DateInput(props, ref) {
    var _a;
    return (_jsx(Input, { type: "text", name: "title", style: __assign({ width: 225 }, ((_a = props.style) !== null && _a !== void 0 ? _a : {})), className: "form-control", id: "title", value: _get(props, 'value'), onChange: function () { }, placeholder: "Select date range", onClick: _get(props, 'onClick'), ref: ref }));
});
