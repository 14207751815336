import Highcharts from 'highcharts';
import { toast } from 'react-toastify';
export var initPainting = function () {
    // @ts-ignore
    window.chartDataHistory = [];
    Highcharts.wrap(Highcharts.Chart.prototype, 'init', function (proceed, options, callback) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        proceed.call(this, options, callback);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var chart = this;
        var isDrawing = false;
        var currentTool = '';
        var currentColor = 'black';
        var startX, startY;
        var lastX, lastY;
        var distanceThreshold = 5;
        this.userOptions.paintingData = {
            elements: [],
            textElements: [],
            isPaintingEnabled: false,
        };
        var isTextMode = false;
        var isDrawingRect = false;
        var currentRect = null;
        var isDrawingArrow = false;
        var currentArrow = null;
        function drawPencil() {
            var points = [];
            var path;
            Highcharts.addEvent(chart.container, 'mousemove', function (e) {
                if (!chart.userOptions.paintingData.isPaintingEnabled) {
                    return;
                }
                if (isDrawing && !isDrawingRect && !isDrawingArrow) {
                    var distX = e.chartX - lastX;
                    var distY = e.chartY - lastY;
                    if (Math.sqrt(distX * distX + distY * distY) > distanceThreshold) {
                        points.push([e.chartX, e.chartY]);
                        if (!path) {
                            path = chart.renderer
                                .path(['M'].concat(points))
                                .attr({
                                'stroke-width': 2,
                                stroke: currentColor,
                                zIndex: 5,
                            })
                                .add();
                            // @ts-ignore
                            window === null || window === void 0 ? void 0 : window.chartDataHistory.push([chart.userOptions.chartIdentifier, 'elements']);
                            chart.userOptions.paintingData.elements.push(path);
                        }
                        else {
                            path.attr({
                                d: ['M'].concat(points),
                            });
                        }
                        lastX = e.chartX;
                        lastY = e.chartY;
                    }
                }
            });
            Highcharts.addEvent(chart.container, 'mouseup', function () {
                isDrawing = false;
                points = [];
                path = null;
            });
        }
        function drawArrow() {
            Highcharts.addEvent(chart.container, 'mousemove', function (e) {
                if (!chart.userOptions.paintingData.isPaintingEnabled) {
                    return;
                }
                if (isDrawing && isDrawingArrow) {
                    var endX = e.chartX;
                    var endY = e.chartY;
                    var arrowSize = 10;
                    var angle = Math.atan2(endY - startY, endX - startX);
                    var arrowHead1 = [
                        endX - arrowSize * Math.cos(angle - Math.PI / 6),
                        endY - arrowSize * Math.sin(angle - Math.PI / 6),
                    ];
                    var arrowHead2 = [
                        endX - arrowSize * Math.cos(angle + Math.PI / 6),
                        endY - arrowSize * Math.sin(angle + Math.PI / 6),
                    ];
                    if (!currentArrow) {
                        currentArrow = chart.renderer
                            .path([
                            'M',
                            startX,
                            startY,
                            'L',
                            endX,
                            endY,
                            'M',
                            arrowHead1[0],
                            arrowHead1[1],
                            'L',
                            endX,
                            endY,
                            'L',
                            arrowHead2[0],
                            arrowHead2[1],
                        ])
                            .attr({
                            'stroke-width': 2,
                            stroke: currentColor,
                            zIndex: 5,
                        })
                            .add();
                        // @ts-ignore
                        window === null || window === void 0 ? void 0 : window.chartDataHistory.push([chart.userOptions.chartIdentifier, 'elements']);
                        chart.userOptions.paintingData.elements.push(currentArrow);
                    }
                    else {
                        currentArrow.attr({
                            d: [
                                'M',
                                startX,
                                startY,
                                'L',
                                endX,
                                endY,
                                'M',
                                arrowHead1[0],
                                arrowHead1[1],
                                'L',
                                endX,
                                endY,
                                'L',
                                arrowHead2[0],
                                arrowHead2[1],
                            ],
                        });
                    }
                }
            });
            Highcharts.addEvent(chart.container, 'mouseup', function () {
                isDrawing = false;
                currentArrow = null;
            });
        }
        function handleTextClick(e) {
            if (!chart.userOptions.paintingData.isPaintingEnabled) {
                return;
            }
            // @ts-ignore
            var inputText = document.getElementById('textInput').value;
            if (!inputText) {
                toast.error('Enter text!');
                return;
            }
            var tempText = chart.renderer.text(inputText, 0, 0).css({ fontSize: '12px' }).add();
            var textWidth = tempText.getBBox().width;
            tempText.destroy();
            var textElement = chart.renderer
                .text(inputText, e.chartX - textWidth / 2, e.chartY)
                .attr({
                zIndex: 5,
                fill: currentColor,
            })
                .add();
            // @ts-ignore
            window === null || window === void 0 ? void 0 : window.chartDataHistory.push([chart.userOptions.chartIdentifier, 'textElements']);
            chart.userOptions.paintingData.textElements.push(textElement);
            // @ts-ignore
            document.getElementById('textInput').value = '';
        }
        function drawRect() {
            Highcharts.addEvent(chart.container, 'mousemove', function (e) {
                if (!chart.userOptions.paintingData.isPaintingEnabled) {
                    return;
                }
                if (isDrawing && isDrawingRect) {
                    var width = e.chartX - startX;
                    var height = e.chartY - startY;
                    if (!currentRect) {
                        currentRect = chart.renderer
                            .rect(startX, startY, width, height)
                            .attr({
                            stroke: currentColor,
                            'stroke-width': 2,
                            fill: 'none',
                            zIndex: 5,
                        })
                            .add();
                        // @ts-ignore
                        window === null || window === void 0 ? void 0 : window.chartDataHistory.push([chart.userOptions.chartIdentifier, 'elements']);
                        chart.userOptions.paintingData.elements.push(currentRect);
                    }
                    else {
                        if (width < 0) {
                            currentRect.attr({
                                x: startX + width,
                                width: -width,
                            });
                        }
                        else {
                            currentRect.attr({
                                width: width,
                            });
                        }
                        if (height < 0) {
                            currentRect.attr({
                                y: startY + height,
                                height: -height,
                            });
                        }
                        else {
                            currentRect.attr({
                                height: height,
                            });
                        }
                    }
                }
            });
            Highcharts.addEvent(chart.container, 'mouseup', function () {
                isDrawing = false;
                currentRect = null;
            });
        }
        Highcharts.addEvent(chart.container, 'mousedown', function (e) {
            if (isTextMode) {
                handleTextClick(e);
            }
            else {
                isDrawing = true;
                startX = e.chartX;
                startY = e.chartY;
                lastX = e.chartX;
                lastY = e.chartY;
                switch (currentTool) {
                    case 'pencil':
                        isDrawingRect = false;
                        isDrawingArrow = false;
                        drawPencil();
                        break;
                    case 'rect':
                        isDrawingRect = true;
                        isDrawingArrow = false;
                        drawRect();
                        break;
                    case 'arrow':
                        isDrawingArrow = true;
                        isDrawingRect = false;
                        drawArrow();
                        break;
                }
            }
        });
        Highcharts.addEvent(chart.container, 'mouseleave', function () {
            isDrawing = false;
            currentRect = null;
            currentArrow = null;
        });
        (_a = document.getElementById('pencilBtn')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
            currentTool = 'pencil';
            isTextMode = false;
        });
        (_b = document.getElementById('textBtn')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function () {
            currentTool = 'text';
            isTextMode = true;
        });
        (_c = document.getElementById('rectBtn')) === null || _c === void 0 ? void 0 : _c.addEventListener('click', function () {
            currentTool = 'rect';
            isTextMode = false;
        });
        (_d = document.getElementById('arrowBtn')) === null || _d === void 0 ? void 0 : _d.addEventListener('click', function () {
            currentTool = 'arrow';
            isTextMode = false;
        });
        (_e = document.getElementById('clearBtn')) === null || _e === void 0 ? void 0 : _e.addEventListener('click', function () {
            if (chart.userOptions.paintingData.elements.length) {
                chart.userOptions.paintingData.elements.forEach(function (el) {
                    el.destroy();
                });
                chart.userOptions.paintingData.elements = [];
            }
            if (chart.userOptions.paintingData.textElements.length) {
                chart.userOptions.paintingData.textElements.forEach(function (el) {
                    el.destroy();
                });
                chart.userOptions.paintingData.textElements = [];
            }
        });
        (_f = document.getElementById('indigoBtn')) === null || _f === void 0 ? void 0 : _f.addEventListener('click', function () {
            currentColor = 'indigo';
        });
        (_g = document.getElementById('blackBtn')) === null || _g === void 0 ? void 0 : _g.addEventListener('click', function () {
            currentColor = 'black';
        });
        (_h = document.getElementById('redBtn')) === null || _h === void 0 ? void 0 : _h.addEventListener('click', function () {
            currentColor = 'red';
        });
        (_j = document.getElementById('blueBtn')) === null || _j === void 0 ? void 0 : _j.addEventListener('click', function () {
            currentColor = 'blue';
        });
        (_k = document.getElementById('orangeBtn')) === null || _k === void 0 ? void 0 : _k.addEventListener('click', function () {
            currentColor = 'orange';
        });
        (_l = document.getElementById('greenBtn')) === null || _l === void 0 ? void 0 : _l.addEventListener('click', function () {
            currentColor = 'green';
        });
        (_m = document.getElementById('yellowBtn')) === null || _m === void 0 ? void 0 : _m.addEventListener('click', function () {
            currentColor = 'yellow';
        });
        (_o = document.getElementById('purpleBtn')) === null || _o === void 0 ? void 0 : _o.addEventListener('click', function () {
            currentColor = 'purple';
        });
    });
};
export var undoPaintingFigures = function () {
    // @ts-ignore
    if (!(window === null || window === void 0 ? void 0 : window.chartDataHistory.length)) {
        return;
    }
    var activeChart;
    Highcharts.charts.forEach(function (chartItem) {
        if (
        // @ts-ignore
        (window === null || window === void 0 ? void 0 : window.chartDataHistory[(window === null || window === void 0 ? void 0 : window.chartDataHistory.length) - 1][0]) !==
            chartItem.userOptions.chartIdentifier) {
            return;
        }
        activeChart = chartItem;
    });
    if (!activeChart) {
        return;
    }
    if (activeChart.userOptions.paintingData.elements.length &&
        // @ts-ignore
        (window === null || window === void 0 ? void 0 : window.chartDataHistory[(window === null || window === void 0 ? void 0 : window.chartDataHistory.length) - 1][1]) === 'elements') {
        var lastElement = activeChart.userOptions.paintingData.elements.pop();
        if (lastElement) {
            lastElement.destroy();
        }
    }
    if (activeChart.userOptions.paintingData.textElements.length &&
        // @ts-ignore
        (window === null || window === void 0 ? void 0 : window.chartDataHistory[(window === null || window === void 0 ? void 0 : window.chartDataHistory.length) - 1][1]) === 'textElements') {
        var lastElement = activeChart.userOptions.paintingData.textElements.pop();
        if (lastElement) {
            lastElement.destroy();
        }
    }
    // @ts-ignore
    window === null || window === void 0 ? void 0 : window.chartDataHistory.pop();
};
export var clearPaintingFigures = function (chartRef) {
    chartRef.current.chart.userOptions.paintingData.elements.forEach(function (el) {
        el.destroy();
    });
    chartRef.current.chart.userOptions.paintingData.elements = [];
    chartRef.current.chart.userOptions.paintingData.textElements.forEach(function (el) {
        el.destroy();
    });
    chartRef.current.chart.userOptions.paintingData.textElements = [];
    // @ts-ignore
    window.chartDataHistory = window.chartDataHistory.filter(function (el) { return el[0] !== chartRef.current.chart.userOptions.chartIdentifier; });
};
