import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { isMote } from '../../../widgets/sensor';
export var ReplacementBody = function (_a) {
    var sensor = _a.sensor, sensorPartNumber = _a.sensorPartNumber, isPreAi = _a.isPreAi;
    if (isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))) {
        return (_jsxs(React.Fragment, { children: [_jsxs("p", { children: ["Current sensor type is ", _jsx("b", { children: sensorPartNumber }), "."] }), _jsxs("p", { children: ["This will create and send an automated ", isPreAi ? 'Pre-' : '', "Action Item to the facility, to replace this mote. You will not be able to edit this ", isPreAi ? 'Pre-' : '', "Action Item before sending it. If you\u2019d like to customize the ", isPreAi ? 'Pre-' : '', "Action Item, please select \u201CCancel\u201D and create the ", isPreAi ? 'Pre-' : '', "Action Item manually."] })] }));
    }
    return (_jsxs(React.Fragment, { children: [_jsxs("p", { children: ["You have chosen to replace the ", sensorPartNumber, " sensor with a new one."] }), _jsx("p", { children: "Are you sure?" })] }));
};
export default ReplacementBody;
