import { jsx as _jsx } from "react/jsx-runtime";
import JoditEditor from 'jodit-react';
import { get as _get } from 'lodash';
import React, { memo, useRef } from 'react';
import './TextEditor.scss';
import { config } from './config/baseConfig';
var RichTextEditor = function (_a) {
    var _b = _a.content, content = _b === void 0 ? '' : _b, _c = _a.onChange, onChange = _c === void 0 ? function () { } : _c, _d = _a.onBlur, onBlur = _d === void 0 ? function () { } : _d, _e = _a.customerId, customerId = _e === void 0 ? null : _e, _f = _a.uploadImageUrl, uploadImageUrl = _f === void 0 ? void 0 : _f, highlight = _a.highlight, setHighlight = _a.setHighlight, storage = _a.storage;
    var editor = useRef(null);
    var validation = function (data) {
        return data.replaceAll('medium-zoom-image--hidden', '');
    };
    var onEditorBlur = function (data, ev) {
        if (_get(ev, 'relatedTarget.type', null) === 'file') {
            return;
        }
        if (onBlur) {
            onBlur();
            onChange(validation(data));
        }
    };
    return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    _jsx("div", { className: highlight ? 'highlight-wrapper' : '', onMouseOver: function () {
            if (setHighlight && highlight) {
                setHighlight(false);
            }
        }, children: _jsx(JoditEditor, { ref: editor, value: content || '', config: config(uploadImageUrl, customerId, storage || null), onBlur: function (data, ev) { return onEditorBlur(data, ev); } }) }));
};
export default memo(RichTextEditor);
