// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-item {
  position: relative;
  margin-top: 20px;
  z-index: 70;
}

.chart-item-active {
  z-index: 75;
}

.charts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid .highcharts-reset-zoom {
  transform: translate(28px, 41px) !important;
}

.highcharts-reset-zoom {
  transform: translate(40px, 42px) !important;
}
.highcharts-reset-zoom rect {
  fill: rgb(230, 230, 230) !important;
  stroke: none;
  height: 26.5px;
}
.highcharts-reset-zoom text {
  font-size: 11px;
  transform: translate(2px, -2px);
}

.highcharts-line-series .highcharts-series-0 path {
  stroke-width: 2 !important;
}

.highcharts-legend-item.highcharts-series-0 path {
  stroke-width: 3 !important;
}

.highcharts-legend-item.highcharts-series-0 text {
  text-decoration: underline;
}

.input-range__label--value {
  display: none;
}

.sideBandLine {
  stroke: #000000 !important;
}
.dark-layout .sideBandLine {
  stroke: #f1f1f1 !important;
}

.outlier-chart-label {
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 5px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AACA;EACE,WAAA;AAEF;;AACA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;AAEF;;AAEE;EACE,2CAAA;AACJ;;AAGA;EACE,2CAAA;AAAF;AACE;EACE,mCAAA;EACA,YAAA;EACA,cAAA;AACJ;AACE;EACE,eAAA;EACA,+BAAA;AACJ;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,0BAAA;AAAF;AACE;EACE,0BAAA;AACJ;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,SAAA;AAAF","sourcesContent":[".chart-item {\n  position: relative;\n  margin-top: 20px;\n  z-index: 70;\n}\n.chart-item-active {\n  z-index: 75;\n}\n\n.charts-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.grid {\n  & .highcharts-reset-zoom {\n    transform: translate(28px, 41px) !important;\n  }\n}\n\n.highcharts-reset-zoom {\n  transform: translate(40px, 42px) !important;\n  & rect {\n    fill: rgb(230, 230, 230) !important;\n    stroke: none;\n    height: 26.5px;\n  }\n  & text {\n    font-size: 11px;\n    transform: translate(2px, -2px);\n  }\n}\n\n.highcharts-line-series .highcharts-series-0 path {\n  stroke-width: 2 !important;\n}\n\n.highcharts-legend-item.highcharts-series-0 path {\n  stroke-width: 3 !important;\n}\n\n.highcharts-legend-item.highcharts-series-0 text {\n  text-decoration: underline;\n}\n\n.input-range__label--value {\n  display: none;\n}\n\n.sideBandLine {\n  stroke: #000000 !important;\n  .dark-layout & {\n    stroke: #f1f1f1 !important;\n  }\n}\n\n.outlier-chart-label {\n  position: absolute;\n  z-index: 1;\n  top: 45px;\n  left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
