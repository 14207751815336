import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaEdit } from 'react-icons/fa';
import FooterTools from '../../../widgets/FooterTools/FooterTools';
import FooterToolsExtraFrame from '../../../widgets/FooterTools/components/ExtraFrame/ExtraFrame';
import FooterToolsSimpleBtn from '../../../widgets/FooterTools/components/SimpleBtn/SimpleBtn';
import FooterToolsToggleBtn from '../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import { onToggleHandle, saveSortingHandle } from '../../features/dashboard';
var DashboardFooterTools = function (_a) {
    var defaultDashboardCardSorting = _a.defaultDashboardCardSorting, setActualLayout = _a.setActualLayout, actualLayout = _a.actualLayout, isSelected = _a.isSelected, setIsSelected = _a.setIsSelected, onOpenConfirmModal = _a.onOpenConfirmModal, isEqualDashboardCardSorting = _a.isEqualDashboardCardSorting, storageKey = _a.storageKey, titleList = _a.titleList;
    return (_jsxs(FooterTools, { children: [isSelected ? (_jsx(FooterToolsSimpleBtn, { onClick: onOpenConfirmModal, children: "Reset to Default" })) : (_jsx(_Fragment, {})), isSelected && !isEqualDashboardCardSorting ? (_jsx(FooterToolsSimpleBtn, { onClick: function () { return saveSortingHandle(storageKey, actualLayout, setIsSelected); }, isDisabled: isEqualDashboardCardSorting, children: "Save" })) : (_jsx(_Fragment, {})), isSelected && !isEqualDashboardCardSorting ? (_jsx(FooterToolsSimpleBtn, { onClick: function () { return setActualLayout(defaultDashboardCardSorting); }, isDisabled: isEqualDashboardCardSorting, children: "Cancel" })) : (_jsx(_Fragment, {})), _jsx(FooterToolsToggleBtn, { isSelected: isSelected, setIsSelected: setIsSelected, children: _jsx(FaEdit, {}) }), isSelected && actualLayout.hidden.length ? (_jsx(FooterToolsExtraFrame, { children: actualLayout.hidden.map(function (el) { return (_jsx("div", { className: "block", onClick: function () { return onToggleHandle('visible', el.i, setActualLayout); }, children: titleList[el.i] }, el.i)); }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(DashboardFooterTools);
