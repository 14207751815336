export var fetch = function (setFacilities, setLoader, endpoint) {
    setLoader(true);
    endpoint()
        .then(function (resp) {
        if (resp) {
            setFacilities(resp.data);
            setLoader(false);
        }
    })
        .catch(function () {
        setLoader(false);
    });
};
