import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
var CountActionItemsOnFacility = function (_a) {
    var countRegularAis = _a.countRegularAis, countHardwareAis = _a.countHardwareAis;
    if (!countRegularAis && !countHardwareAis) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("span", { className: "ms-2", children: ["(", _jsx("b", { id: "count-regular-ais-on-facility", className: "cursor-pointer", children: countRegularAis }), ' ', "/", ' ', _jsx("b", { id: "count-hardware-ais-on-facility", className: "cursor-pointer", children: countHardwareAis }), ")", _jsx(UncontrolledTooltip, { target: "count-regular-ais-on-facility", children: "Count asset Action Items on facility" }), _jsx(UncontrolledTooltip, { target: "count-hardware-ais-on-facility", children: "Count network Action Items on facility" })] }));
};
export default memo(CountActionItemsOnFacility);
