import { numberWithCommas } from '../../../features/watchersStatistic/costAvoided';
export var config = {
    columns: [
        {
            title: 'Name',
            name: 'username',
        },
        {
            title: 'From AI',
            name: 'from_ai',
            component: function (row) {
                return '$ ' + numberWithCommas(row.from_ai);
            },
        },
        {
            title: 'From Pre-AI',
            name: 'from_pre_ai',
            component: function (row) {
                return '$ ' + numberWithCommas(row.from_pre_ai);
            },
        },
        {
            title: 'Total',
            name: 'total',
            component: function (row) {
                return '$ ' + numberWithCommas(row.total);
            },
        },
    ],
    pagination: {
        disabled: true,
    },
    hideTotalLabel: true,
    disabledSearch: true,
    data: [],
    loader: false,
};
export var costAvoidedTableOptions = [
    {
        title: 'Cost Avoided by ukrainian analysts by',
        key: 'UA_team',
        shortDesc: 'UA Analysts',
    },
    {
        title: 'Cost Avoided by american analysts by',
        key: 'USA_team',
        shortDesc: 'USA Analysts',
    },
    // {
    //     title: "Cost Avoided by philippine analysts by",
    //     key: "PHL_team",
    //     shortDesc: "PHL Analysts"
    // },
    {
        title: 'Cost Avoided by ML-bot system by',
        key: 'ML_bot_system',
        shortDesc: 'ML-bot',
    },
];
