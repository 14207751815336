var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo, useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/watchList/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { exportToCsv, onChangeDateRangeFilter, onViewInstallationPoint, } from '../../../shared/features/shared';
import { deleteWatchListItem, onChangeState } from '../../../shared/features/watchList';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import User from '../../../shared/helper/user';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import EditingWatchListModal from '../../../shared/modals/watchList/Editing';
import { useConfirmModalActions } from '../../../shared/store/global/useConfirmModalStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint, 'Delete', function (row) { return +row.user_id === +User.getId(); }, true);
    return {
        selectedState: '',
        removedList: [],
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'zone',
                    title: 'Zone',
                    sortable: true,
                },
                {
                    name: 'area',
                    title: 'Area',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedFacility) {
    var _a, _b;
    var config = state.config, selectedState = state.selectedState;
    dispatch({ type: 'setState', state: { config: __assign(__assign({}, state.config), { loader: true }) } });
    Endpoints.amazon
        .getWatchList({
        query: {
            selected_state: selectedState,
            selected_facility: selectedFacility,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    removedList: resp.removedList,
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, config.filtersData), { isFilterClick: true }) }),
                },
            });
        }
    });
};
var WatchListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    useTitle('WatchList');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _d[0], dispatch = _d[1];
    var config = state.config, removedList = state.removedList, selectedState = state.selectedState;
    var _e = useState(null), editItemId = _e[0], setEditItemId = _e[1];
    var _f = useState(''), editItemDesc = _f[0], setEditItemDesc = _f[1];
    useResetDataTableEffect([selectedFacility, config.filtersData.dateRange], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedFacility);
    }, [refreshFlag, selectedFacility, selectedState, config.filtersData.dateRange]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var removedListBaseConfig = baseConfig(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); }, 'Confirm', function () { return true; });
    var columns = __spreadArray([], removedListBaseConfig.columns, true);
    columns.pop();
    return (_jsxs("div", { className: "page-watchList", children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["WatchList", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                        return exportToCsv(Endpoints.amazon.getWatchListCsv, 'amazon-watchlist', {
                                            query: {
                                                selected_state: selectedState,
                                                selected_facility: selectedFacility,
                                                sort: config.sort,
                                                search: config.search,
                                                date_from: config.filtersData.dateRange[0]
                                                    ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                                date_to: config.filtersData.dateRange[1]
                                                    ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                            },
                                            isBlob: true,
                                        });
                                    }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx("div", { className: "table_wrap align-pagination-section", children: _jsx(DataTable, { config: config, onPageChange: function (page) {
                                    return onPageChange(page, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                                }, onPageSizeChange: function (pageSize) {
                                    return onPageSizeChange(pageSize, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                                }, onSort: function (column_name, ev) {
                                    return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); }, ev);
                                }, callbacks: {
                                    onChangeState: function (selectedState) { return onChangeState(selectedState, dispatch); },
                                    deleteWatchListItem: function (id) {
                                        return deleteWatchListItem(Endpoints.amazon.removeWatchListItem, id, setConfirmData, function () { return fetch(state, dispatch, selectedFacility); }, function () { return setLoader(state, dispatch, true); });
                                    },
                                    editWatchListItem: function (id, desc) {
                                        setEditItemId(id);
                                        setEditItemDesc(desc);
                                    },
                                    onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                }, onSearch: function (search) {
                                    return onSearch(search, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                                } }) })] }) }), removedList.length > 0 && (_jsx(Row, { className: "removedList", children: _jsx(Col, { children: _jsx(DataTable, { config: __assign(__assign({}, removedListBaseConfig), { columns: __spreadArray(__spreadArray([
                                {
                                    name: 'facility_name',
                                    title: 'Facility',
                                    sortable: true,
                                },
                                {
                                    name: 'zone',
                                    title: 'Zone',
                                    sortable: true,
                                },
                                {
                                    name: 'area',
                                    title: 'Area',
                                    sortable: true,
                                },
                                {
                                    name: 'equipment_name',
                                    title: 'Equipment',
                                    sortable: true,
                                },
                                {
                                    name: 'component_name',
                                    title: 'Component',
                                    sortable: true,
                                }
                            ], columns, true), [
                                {
                                    name: 'deleted_comment',
                                    title: 'Reason',
                                    sortable: true,
                                },
                                removedListBaseConfig.columns[removedListBaseConfig.columns.length - 1],
                            ], false), data: removedList, disabledSearch: true, pagination: {
                                disabled: true,
                            }, hideTotalLabel: true, filters: function () {
                                return _jsx(_Fragment, {});
                            }, hideHeader: true, loader: false }), callbacks: {
                            deleteWatchListItem: function (id) {
                                return deleteWatchListItem(Endpoints.amazon.removeWatchListItem, id, setConfirmData, function () { return fetch(state, dispatch, selectedFacility); }, function () { return setLoader(state, dispatch, true); });
                            },
                        } }) }) })), _jsx(EditingWatchListModal, { description: editItemDesc, endpoint: Endpoints.amazon.editWatchListItem, onCancel: function () { return setEditItemId(null); }, visible: !!editItemId, callback: function () { return fetch(state, dispatch, selectedFacility); }, id: editItemId })] }));
};
export default memo(WatchListPage);
