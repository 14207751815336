var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeValidLink } from '../../helper/routeHelper';
import { setLoader } from '../../helper/table';
export var fetch = function (state, dispatch, endpoint, navigate, prefix) {
    var config = state.config;
    setLoader(state, dispatch, true);
    endpoint().then(function (resp) {
        if (resp) {
            navigate(makeValidLink("/".concat(prefix, "/action-list-comments")));
            dispatch({
                type: 'setState',
                state: {
                    isBlockResetSelectedUserName: false,
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                    userNames: resp.userNames,
                },
            });
        }
    });
};
export var parseDate = function (dateString) {
    if (!dateString) {
        return null;
    }
    var parsedDate = new Date(dateString);
    return !isNaN(parsedDate.getTime()) ? parsedDate : null;
};
