import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React, { memo } from 'react';
import { isAllFieldFalse } from '../../../../../../features/watchersStatistic';
import { selectDateRange } from '../../../../../../store/watchersStatistic/useDateRangeStore';
import LinkWithPrefix from '../../../../../LinkWithPrefix';
var TableRow = function (_a) {
    var _b = _a.rowData, rowData = _b === void 0 ? {} : _b;
    var dateRange = selectDateRange();
    if (!_size(rowData)) {
        return (_jsx("tr", { children: _jsx("td", { colSpan: 11, children: _jsx("i", { children: "Empty Data" }) }) }));
    }
    var username = rowData.username, created_ai = rowData.created_ai, no_action_required = rowData.no_action_required, hardware = rowData.hardware, processing_ai = rowData.processing_ai, closed_ai = rowData.closed_ai, declined_reports = rowData.declined_reports, ai_comments = rowData.ai_comments, raw_pre_ai = rowData.raw_pre_ai, created_pre_ai = rowData.created_pre_ai, ai_from_own_pre_ai = rowData.ai_from_own_pre_ai, accepted_pre_ai = rowData.accepted_pre_ai, declined_pre_ai = rowData.declined_pre_ai;
    if (isAllFieldFalse(rowData, ['username', 'changed_state', 'pre_ai_no_action_required', 'total_created'])) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("tr", { children: [_jsx("td", { "data-label": "User Name", children: username }), _jsxs("td", { "data-label": "AI", children: [created_ai || 0, "/", no_action_required || 0] }), _jsx("td", { "data-label": "WWAI", children: hardware || 0 }), _jsxs("td", { "data-label": "Report", children: [processing_ai || 0, "/", closed_ai || 0] }), _jsx("td", { "data-label": "Rejected", children: declined_reports || 0 }), _jsx("td", { "data-label": "Comments", children: ai_comments ? (_jsx(LinkWithPrefix, { target: "_blank", to: "/action-list-comments?selected_user_name=".concat(username, "&date_start=").concat(dateRange[0], "&date_end=").concat(dateRange[1]), children: ai_comments })) : (0) }), _jsx("td", { "data-label": "Raw_pre-ai", children: raw_pre_ai || 0 }), _jsx("td", { "data-label": "Pre-AI", children: created_pre_ai || 0 }), _jsx("td", { "data-label": "Own Pre-AI", children: ai_from_own_pre_ai || 0 }), _jsx("td", { "data-label": "Accepted", children: accepted_pre_ai || 0 }), _jsx("td", { "data-label": "Declined", children: declined_pre_ai || 0 })] }));
};
export default memo(TableRow);
