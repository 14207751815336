import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TbSearch } from 'react-icons/tb';
import { selectIsFullSizeSidebar } from '../../../../store/global/useGlobalStore';
import { onChangeIsOpenSearchFrame } from '../../features';
var SearchBtn = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var isOpenSearchFrame = state.isOpenSearchFrame;
    var isFullSizeSidebar = selectIsFullSizeSidebar();
    return (_jsxs("div", { className: "search-input", style: {
            left: isFullSizeSidebar ? '470px' : '280px',
        }, onClick: function () { return onChangeIsOpenSearchFrame(!isOpenSearchFrame, dispatch); }, children: [_jsx(TbSearch, {}), "Search"] }));
};
export default SearchBtn;
