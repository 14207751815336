import Api from '../../api';
export default {
    getStatistic: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer-statistic', options);
    },
    getSensors: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer/get-sensors', options);
    },
    getSensorPhotos: function (sensor_id, options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer/get-sensor-photos/' + sensor_id, options);
    },
    getNodePhotos: function (node_id, options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer/get-node-photos' + node_id, options);
    },
    getNodes: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer/get-nodes', options);
    },
    getSensorsWithoutAlarmLevels: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/customer/get-sensors-without-alarms', options);
    },
};
