import Api from '../../api';
export default {
    getAvgList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point-avg', options);
    },
    getAvgListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point-avg/csv', options);
    },
    getAvgSensorType: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point-avg/sensor-types', options);
    },
    getExistAiOnBed: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point/get-exist-ai-on-bed', options);
    },
    getInstallationPointInfo: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/installation-point/".concat(id), options);
    },
    changeSpeed: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/installation-point/change-speed', data);
    },
    changeIdleThreshold: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/installation-point/change-idle-threshold', data);
    },
    saveIntervals: function (installationPointId, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/installation-point-settings/".concat(installationPointId, "/update"), body, options);
    },
    saveIntervalsEquipment: function (equipmentId, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/installation-point-settings/".concat(equipmentId, "/equipment-update"), body, options);
    },
    saveHighPassBatch: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/installation-point-settings/batch-high-pass-update', body, options);
    },
    saveImpactVueBatch: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/installation-point-settings/batch-impact-vue-update', body, options);
    },
    getInstallationPointsSettings: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point-settings', options);
    },
};
