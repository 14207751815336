import Api from '../../api';
export default {
    deleteItem: function (data, options) {
        if (data === void 0) { data = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/in-list/delete', data, options);
    },
    addToInList: function (data, options) {
        if (data === void 0) { data = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/in-list/create', data, options);
    },
};
