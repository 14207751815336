import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'reactstrap';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import Filters from '../../../components/mlStatistic/rejected/Filter';
import { highlightRow } from '../../../helper/table';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_hex',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    return (_jsx("div", { className: "no-wrap", children: _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id, showDecId: false }) }));
                },
            },
            {
                name: 'created_at',
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "pre_ai_list_created_at_".concat(row.id), dateTime: row.created_at, timeBreak: true }));
                },
            },
            {
                name: 'user_name',
                title: 'Added by',
                sortable: true,
            },
            {
                name: 'declined_user_name',
                title: 'Declined by',
                sortable: true,
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "pre_ai_list_updated_at_".concat(row.id), dateTime: row.updated_at, timeBreak: true }));
                },
            },
            {
                name: 'no_actions_description',
                title: 'Close comment',
                sortable: false,
                component: function (row) {
                    return row.decline_reason || '---';
                },
            },
            {
                title: 'Analyst comment',
                name: 'ml_comment',
                sortable: false,
                component: function (row) {
                    return (_jsx("div", { className: "w-280 table-cell-with-img", children: row.mlTeamComments.map(function (mlTeamComment) { return (_jsxs("div", { children: [_jsx("span", { className: "fw-bold", children: mlTeamComment.user.name_first + ' ' + mlTeamComment.user.name_last }), ": ", mlTeamComment.comment] }, "ml_team_comment_".concat(mlTeamComment.id))); }) }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function (ev) {
                            onViewClick(row.id);
                            highlightRow(ev);
                        }, children: "View" }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData, selectedFilters) {
            return (_jsx(Filters, { onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, onClearCreated: callbacks.onClearCreated, onClearDeclined: callbacks.onClearDeclined, onChangeCreatedBy: callbacks.onChangeCreatedBy, onChangeDeclinedBy: callbacks.onChangeDeclinedBy, users: filtersData.users, passUserIdOnChange: true, exportToCsv: callbacks.exportToCsv, selected: selectedFilters }));
        },
        filtersData: {
            dateRange: [],
            users: [],
            selectedCustomers: [],
        },
    };
};
