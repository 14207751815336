import { createStore } from '../createStore';
export var ACTIVITY_LOG_SIZE_HIDE = 0;
export var ACTIVITY_LOG_SIZE_SMALL = 1;
export var ACTIVITY_LOG_SIZE_MEDIUM = 2;
export var ACTIVITY_LOG_SIZE_LARGE = 3;
export var ACTIVITY_LOG_SIZE_FULL = 4;
var defaultActivityLogSize = localStorage.getItem(window.location.pathname.includes('/action-item/')
    ? 'aiDefaultActivityLogSize'
    : 'preAiDefaultActivityLogSize')
    ? Number(localStorage.getItem(window.location.pathname.includes('/action-item/')
        ? 'aiDefaultActivityLogSize'
        : 'preAiDefaultActivityLogSize'))
    : null;
var useActionItemViewStore = createStore(function (set) {
    var _a;
    return ({
        activityLogSize: (_a = defaultActivityLogSize) !== null && _a !== void 0 ? _a : ACTIVITY_LOG_SIZE_SMALL,
        editComment: null,
        isGatheringData: false,
        isOnlyInternalComments: false,
        isInternalComment: false,
        actions: {
            setActivityLogSize: function (activityLogSize) { return set({ activityLogSize: activityLogSize }); },
            setEditComment: function (editComment) { return set({ editComment: editComment }); },
            setIsGatheringData: function (isGatheringData) { return set({ isGatheringData: isGatheringData }); },
            setIsOnlyInternalComments: function (isOnlyInternalComments) { return set({ isOnlyInternalComments: isOnlyInternalComments }); },
            setIsInternalComment: function (isInternalComment) { return set({ isInternalComment: isInternalComment }); },
        },
    });
});
export var useActionItemViewActions = function () {
    return useActionItemViewStore(function (state) { return state.actions; });
};
export var selectActivityLogSize = function () {
    return useActionItemViewStore(function (state) { return state.activityLogSize; });
};
export var selectEditComment = function () { return useActionItemViewStore(function (state) { return state.editComment; }); };
export var selectIsGatheringData = function () {
    return useActionItemViewStore(function (state) { return state.isGatheringData; });
};
export var selectIsOnlyInternalComments = function () {
    return useActionItemViewStore(function (state) { return state.isOnlyInternalComments; });
};
export var selectIsInternalComment = function () {
    return useActionItemViewStore(function (state) { return state.isInternalComment; });
};
