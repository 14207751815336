import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BearingsTable from '../../components/bearings/BearingsTable';
import BearingsTitle from '../../components/bearings/BearingsTitle';
import { onCancel } from '../../features/bearings';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
var ManageBearingsModal = function () {
    var _a = useState(false), smallLoader = _a[0], setSmallLoader = _a[1];
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: function () { return onCancel(setStatesChartFeaturesStore); }, isOpen: true, children: [_jsx(ModalHeader, { toggle: function () { return onCancel(setStatesChartFeaturesStore); }, children: _jsx(BearingsTitle, { smallLoader: smallLoader }) }), _jsx(ModalBody, { children: _jsx(BearingsTable, { setSmallLoader: setSmallLoader }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: function () { return onCancel(setStatesChartFeaturesStore); }, children: "Cancel" }) }) })] }));
};
export default memo(ManageBearingsModal);
