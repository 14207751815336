import Api from '../../api';
export default {
    getWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/watchlist', options);
    },
    getUsersWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/watchlist/users', options);
    },
    removeWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("all-analytics/watchlist/".concat(id), options);
    },
    editWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/watchlist/".concat(id), options);
    },
    softRemoveWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("all-analytics/watchlist/soft-delete/".concat(id), options);
    },
    getWatchListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/watchlist/csv', options);
    },
    getUsersWatchListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/watchlist/users-csv', options);
    },
};
