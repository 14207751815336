import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { actionItemKey as actionItemKeyAmazon } from '../../../amazon/services/actionItems/useActionItemQuery';
import Api from '../../../api/api';
import { actionItemKey as actionItemKeyRemastered } from '../../../remastered/services/actionItems/useActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var editFn = function (data, coreLink, storage) { return Api.put("".concat(coreLink, "/action-item/").concat(data.action_item_id), data, { host: getHost(storage) }); };
export var useEditMutation = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return editFn(data, getCoreUrl(selectedSystemType), storage); },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: selectedSystemType
                    ? actionItemKeyRemastered(actionItemId)
                    : actionItemKeyAmazon(actionItemId),
            });
        },
    });
};
