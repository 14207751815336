import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useController } from 'react-hook-form';
import RichTextEditor from '../../../../widgets/TextEditor/TextEditor';
var CommentEditor = function (_a) {
    var uploadImageUrl = _a.uploadImageUrl, control = _a.control, storage = _a.storage;
    var field = useController({
        control: control,
        name: 'comment',
    }).field;
    return (_jsx(_Fragment, { children: _jsx(RichTextEditor, { storage: storage, content: field.value, onChange: field.onChange, uploadImageUrl: uploadImageUrl }) }));
};
export default memo(CommentEditor);
