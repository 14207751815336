import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import DataTable from '../../shared/components/shared/Table/Table';
import { DateTimeLabel } from '../common';
var SuggestedLevelFormulaHistory = function (_a) {
    var history = _a.history;
    if (history.length === 0) {
        return _jsx(React.Fragment, {});
    }
    var config = {
        columns: [
            {
                name: 'user_id',
                title: 'User',
                sortable: false,
                component: function (row) {
                    return "".concat(row.user.name_first, " ").concat(row.user.name_last);
                },
            },
            {
                name: 'formula',
                title: 'Formula',
                sortable: false,
            },
            {
                name: 'created_at',
                title: 'Date',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "system_level_setting_date".concat(row.id), dateTime: row.created_at });
                },
            },
        ],
        search: '',
        pagination: {
            disabled: true,
            page: 1,
            pageSize: 20,
            total: 0,
        },
        disabledSearch: true,
        hideTotalLabel: true,
        data: history,
        loader: false,
    };
    return (_jsx(React.Fragment, { children: _jsx(DataTable, { config: config, onPageChange: function () { }, onPageSizeChange: function () { }, onSort: function () { }, onSearch: function () { } }) }));
};
export default SuggestedLevelFormulaHistory;
