import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';
import AlertLogContent from '../../../../../../../widgets/alertLogContent';
import { DateTimeLabel } from '../../../../../../../widgets/common';
import LinkWithPrefix from '../../../../../LinkWithPrefix';
export var config = function (data) {
    return {
        columns: [
            {
                name: data.length ? (Object.keys(data[0]).includes('customer_name') ? 'customer' : 'facility') : '',
                title: data.length ? (Object.keys(data[0]).includes('customer_name') ? 'Customer' : 'Facility') : '',
                sortable: false,
                component: function (row) {
                    return _get(row, 'customer_name') || _get(row, 'facility') || '---';
                },
            },
            {
                name: 'sensor_hex',
                title: 'Sensor',
                sortable: false,
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
            },
            {
                name: 'old_data',
                title: 'Old data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: +_get(row, 'val2'), cautionValue: +_get(row, 'old_data.levels.caution'), warningValue: +_get(row, 'old_data.levels.warning') }));
                },
            },
            {
                name: 'new_data',
                title: 'New data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: +_get(row, 'val2'), isNewData: true, cautionValue: +_get(row, 'new_data.levels.caution'), warningValue: +_get(row, 'new_data.levels.warning') }));
                },
            },
            {
                name: 'time',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "default-alert-log-".concat(_get(row, 'id')), dateTime: _get(row, 'date_create') || _get(row, 'dateCreate') }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(+_get(row, 'installationPoint_id'), "&scroll_to=").concat(_get(row, 'val2')), target: "_blank", children: _jsxs(Button, { size: "sm", color: "success", children: [_jsx(FaEye, {}), " View component"] }) }));
                },
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: data.length,
        },
        search: '',
        disabledSearch: true,
        data: data,
        loader: false,
    };
};
