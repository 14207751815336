import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import '../../../../../../styles/charts/chartsWrapper/LabelsChartActions.scss';
import WakeOnShakeIndicator from '../../shared/labelsChartActionsIndicators/WakeOnShakeIndicator';
var LabelsChartActions = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    var isSelectChartVisible = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
    }); }, shallow).isSelectChartVisible;
    var wosTrigger = InstallationPointStore(function (state) { return state.wosTrigger; });
    return (_jsx("div", { className: "labels-chart-actions", children: !isSelectChartVisible ? (_jsx(_Fragment, { children: wosTrigger ? _jsx(WakeOnShakeIndicator, { chartIdentifier: chartIdentifier }) : _jsx(_Fragment, {}) })) : (_jsx(_Fragment, {})) }));
};
export default memo(LabelsChartActions);
