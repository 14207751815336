import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, intersection as _intersection } from 'lodash';
import React, { memo, useMemo } from 'react';
var RowStatus = function (_a) {
    var installationPoints = _a.installationPoints, _b = _a.className, className = _b === void 0 ? 'equipment-status' : _b, inList = _a.inList, isInstallationPoint = _a.isInstallationPoint;
    var backgrounds = {
        detachedList: 'bg_grey',
        badList: 'bg_grey',
        wwActionList: 'bg_grey',
        watchList: 'bg_warning',
        alertsList: 'bg_orange',
        preActionList: 'bg_dark-blue',
        actionList: 'bg_danger',
        nodesAiList: 'bg_danger',
    };
    var color = useMemo(function () {
        var mainCalculatedColor = '';
        var secondCalculatedColor = '';
        Object.values(backgrounds).forEach(function (value, index) {
            var listName = _get(Object.keys(backgrounds), index), inListInstallationPointIds = Object.keys(inList[listName] || {}).map(function (id) { return +id; }), currentInstallationPointIds = installationPoints.map(function (installationPoint) { return +installationPoint.id; });
            if (_intersection(inListInstallationPointIds, currentInstallationPointIds).length > 0) {
                mainCalculatedColor = listName;
                secondCalculatedColor = ['badList', 'wwActionList'].includes(listName)
                    ? listName
                    : secondCalculatedColor;
            }
        });
        return {
            main: backgrounds[mainCalculatedColor],
            second: ['badList', 'wwActionList'].includes(mainCalculatedColor)
                ? ''
                : backgrounds[secondCalculatedColor],
        };
    }, [inList]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "".concat(className, " ").concat(color.main) }), isInstallationPoint ? _jsx("div", { className: "".concat(className, " ").concat(color.second, " second") }) : _jsx(_Fragment, {})] }));
};
export default memo(RowStatus);
