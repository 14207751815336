var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../api/api';
import { SystemTypeContext } from '../context/SystemTypeContext';
import { getCoreUrl } from '../helper/commonHelper';
import { initialState } from '../modals/ExistingAiPreAiOnBed/config/defaultTableConfig';
import { selectEquipmentId, selectInstallationPointId, selectIsVisibleModal, } from '../modals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore';
export var existAiPreAiOnBedKey = function (equipmentId, installationPointId) { return [
    'existAiPreAiOnBed',
    equipmentId,
    installationPointId,
]; };
var existAiPreAiOnBedFn = function (_a, coreLink, link) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/installation-point/").concat(link), {
        query: {
            equipmentId: queryKey[1],
            installationPointId: queryKey[2],
        },
    });
};
export var useExistAiPreAiBedQuery = function (_a) {
    var _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isVisible = selectIsVisibleModal();
    var installationPointId = selectInstallationPointId();
    var equipmentId = selectEquipmentId();
    var config = initialState(selectedSystemType, isPreAi).config;
    return useQuery({
        queryKey: existAiPreAiOnBedKey(equipmentId, installationPointId),
        queryFn: function (data) {
            return existAiPreAiOnBedFn(data, getCoreUrl(selectedSystemType), isPreAi ? 'get-exist-pre-ai-on-bed' : 'get-exist-ai-on-bed');
        },
        select: function (_a) {
            var data = _a.data;
            return {
                config: __assign(__assign({}, config), { loader: false, data: data }),
            };
        },
        enabled: !!installationPointId && isVisible,
    });
};
