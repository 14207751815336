import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var componentsKey = function (customerId, facilityId, locationIds, equipmentIds) { return ['components', customerId, facilityId, locationIds, equipmentIds]; };
var componentsFn = function (_a) {
    var _b, _c;
    var queryKey = _a.queryKey;
    return Api.get('all-analytics/customer/components', {
        query: {
            selected_customer_ids: queryKey[1] ? [queryKey[1]] : [],
            selected_facility_id: queryKey[2],
            selected_location_ids: (_b = queryKey[3]) !== null && _b !== void 0 ? _b : [],
            selected_equipment_ids: (_c = queryKey[4]) !== null && _c !== void 0 ? _c : [],
        },
    });
};
export var useComponentsQuery = function (_a) {
    var customerId = _a.customerId, facilityId = _a.facilityId, locationIds = _a.locationIds, equipmentIds = _a.equipmentIds;
    return useQuery({
        queryKey: componentsKey(customerId, facilityId, locationIds, equipmentIds),
        queryFn: componentsFn,
        select: function (_a) {
            var _b;
            var data = _a.data;
            return {
                components: (_b = data.map(function (_a) {
                    var component = _a.component;
                    return {
                        name: component,
                        component: component,
                    };
                })) !== null && _b !== void 0 ? _b : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!customerId,
    });
};
