var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import chartViewStorage from '../../../../storage/chartViewStorage';
var params = new URLSearchParams(window.location.search);
var initialState = {
    chartView: chartViewStorage.get() || 'list',
    countDays: 14,
    scaleUrlParam: params.get('scale') ? Number(params.get('scale')) : null,
    chartCursorType: 'normal',
    selectedChartsAvailable: {},
    chartsSelectedRanges: {},
    chartsSelectedPainting: {},
    isCustomZoom: false,
    customZoomExtremes: {
        min: null,
        max: null,
    },
    isSelectChartVisible: false,
    chartTooltipsToggle: false,
    chartAlertLevelsVisibleToggle: false,
    showSystemLevels: params.get('show_system_levels') ? !!Number(params.get('show_system_levels')) : false,
    showMlSuggestedLevels: params.get('show_ml_suggested_levels')
        ? !!Number(params.get('show_ml_suggested_levels'))
        : false,
    isVisibleFftShapes: false,
    fftShapesType: 'regular',
    isZoomEnabled: false,
    hideChartMarks: false,
    hidePeaks: false,
    isLockedRmsCursor: false,
    isClearFftTwfCursor: false,
    isZoom: false,
    isChartResetRmsZoom: false,
    twfMlDataItemId: params.get('twf_ml_data_item_id') ? Number(params.get('twf_ml_data_item_id')) : null,
    scrollTo: params.get('scroll_to') ? Number(params.get('scroll_to')) : null,
    storedTachDataReadings: [],
};
export var ChartsStore = create()(devtools(function (set) { return (__assign(__assign({}, initialState), { setChartView: function (chartView) {
        set({ chartView: chartView }, false, 'ChartsStoreSetChartView');
        chartViewStorage.set(chartView);
    }, setStates: function (states) {
        set(states, false, 'ChartsStoreSetStates');
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'ChartsStore' }));
