import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { Badge, Button, ButtonGroup } from 'reactstrap';
import { READING_TYPES } from '../../../constants';
import { UrgencyLabel } from '../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import { calcDaysForCharts } from '../../features/actionItems';
import User from '../../helper/user';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point Name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true, notShowEmptyHex: true });
                },
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
                style: { minWidth: 150 },
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "---" });
                    }
                    var charts = _get(row, 'userReport.charts') || _get(row, 'charts') || [];
                    return (_jsx(_Fragment, { children: charts
                            .map(function (chart) {
                            return "".concat(_get(READING_TYPES, [chart.reading_type_id, 'title'])).concat(_get(READING_TYPES, [chart.reading_type_id, 'axis'])
                                ? " ".concat(_get(READING_TYPES, [chart.reading_type_id, 'axis']))
                                : '');
                        })
                            .join(', ') }));
                },
            },
            {
                name: 'is_relevant',
                title: 'Relevant',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return (_jsx(Badge, { pill: true, color: "secondary", size: "sm", children: "Not Processed" }));
                    }
                    if (+row.is_relevant === 1) {
                        return (_jsx(Badge, { pill: true, color: "success", size: "sm", children: "Yes" }));
                    }
                    return (_jsx(Badge, { pill: true, color: "danger", size: "sm", children: "No" }));
                },
            },
            {
                name: 'defect_category',
                title: 'Defect category',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(_Fragment, { children: row.defect_category.split(';').join('; ') });
                },
            },
            {
                name: 'fault_type',
                title: 'Fault type',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null || row.fault_type === null || row.fault_type.length === 0) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(_Fragment, { children: row.fault_type.split(';').join('; ') });
                },
            },
            {
                name: 'urgency',
                title: 'Urgency',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(UrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                name: 'created_at',
                title: 'Created At',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_created_at"), dateTime: row.created_at });
                },
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_updated_at"), dateTime: row.updated_at });
                },
            },
            {
                name: 'comment',
                title: 'Comment',
                sortable: false,
            },
            {
                name: 'ai_created_at',
                title: 'AI Time Created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_ai_created_at"), dateTime: row.ai_created_at }));
                },
            },
            {
                name: 'ai_closed_at',
                title: 'AI Time Closed',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_ai_closed_at"), dateTime: row.ai_closed_at }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    var aiCreatedDaysAgo = Math.abs(moment(row.ai_created_at).diff(moment(), 'days'));
                    var createReportParam = row.processed_report_id !== null
                        ? ''
                        : "&twf_ml_data_item_id=".concat(row.id, "&scale=").concat(calcDaysForCharts(row.ai_created_at || '')).concat(aiCreatedDaysAgo <= 365 ? "&fft_date=".concat(row.ai_created_at) : '');
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { children: _jsx(Button, { color: "info", size: "sm", disabled: !row.userReport || !row.userReport.charts || row.userReport.charts.length === 0, onClick: function () { return callbacks.onViewImages(row.userReport.charts.map(function (chart) { return chart.image; })); }, children: "Images" }) }), _jsx(ButtonGroup, { className: "ms-2", children: _jsx(Button, { onClick: function () { return onViewClick(row.installation_point_id, createReportParam); }, className: "btn btn-sm btn-success", children: "View" }) }), +User.getId() === +row.user_id && (_jsx(ButtonGroup, { className: "ms-2", children: _jsx(React.Fragment, { children: _jsx(Button, { size: "sm", color: "primary", onClick: function () { return callbacks.setEditRow(row); }, children: "Edit" }) }) }))] }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        withScroll: true,
    };
};
