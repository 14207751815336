var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var fetch = function (intervalsFormData, setIntervalsFormData, setList, setTriggers, setLoader, endpoint) {
    setLoader(true);
    endpoint().then(function (resp) {
        if (resp) {
            setList(resp.list);
            setTriggers(resp.triggers);
            setIntervalsFormData(__assign(__assign({}, intervalsFormData), { visible: false, triggers: resp.triggers }));
            setLoader(false);
        }
    });
};
