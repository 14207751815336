import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { CONTROL_KEY_CODE, CONTROL_KEY_NAME } from '../../../../../../../constants/hotkeys';
import { ChartsStore } from '../../../../../../../store/charts/chartsContent/ChartsStore';
import DefaultTooltip from '../../../../../../DefaultTooltip';
import HotkeyInfo from '../../../../../../HotkeyInfo';
var ZoomActionActivity = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    var _b = ChartsStore(function (state) { return ({
        isZoomEnabled: state.isZoomEnabled,
        setStates: state.setStates,
    }); }, shallow), isZoomEnabled = _b.isZoomEnabled, setStates = _b.setStates;
    var handleToggleBtn = function () {
        setStates({
            isZoomEnabled: false,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "default-chart-label ".concat(isZoomEnabled ? 'label-active' : ''), onClick: handleToggleBtn, "data-tooltip-id": "zoom-action-activity-".concat(chartIdentifier), children: "Zoom" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "bottom", id: "zoom-action-activity-".concat(chartIdentifier), children: _jsx(HotkeyInfo, { hotkey: { codes: [CONTROL_KEY_CODE], names: [CONTROL_KEY_NAME] } }) })] }));
};
export default memo(ZoomActionActivity);
