// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts-footer-bar {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x proximity;
  width: calc(100vw - 400px);
  position: fixed;
  bottom: -110px;
  z-index: 1020;
  padding: 0 20px !important;
  height: 80px;
  transition: transform 0.3s;
  gap: 10px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  scroll-padding: 20px;
}
.dark-layout .charts-footer-bar {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}
.charts-footer-bar.active {
  transform: translateY(-120px);
}
.charts-footer-bar > label {
  padding: 10px 10px;
  flex: 0 0 auto;
  border: 1px dashed rgba(38, 132, 255, 0.3137254902);
  cursor: grab;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.charts-footer-bar > label.disabled {
  opacity: 0.5;
}
.charts-footer-bar > label:hover {
  background: rgba(38, 132, 255, 0.3137254902);
}
.charts-footer-bar > label.invalid {
  opacity: 0.4;
  border: 1px dashed #ff0000;
}
.charts-footer-bar > label.invalid:hover {
  background: rgba(255, 0, 0, 0.3137254902);
}
.charts-footer-bar > label > input {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/charts/footerBar/FooterBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6BAAA;EACA,0BAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,0BAAA;EACA,YAAA;EACA,0BAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,oBAAA;AACF;AAAE;EACE,mBAAA;EACA,yBAAA;AAEJ;AAAE;EACE,6BAAA;AAEJ;AAAE;EACE,kBAAA;EACA,cAAA;EACA,mDAAA;EACA,YAAA;EACA,kBAAA;EACA,iCAAA;AAEJ;AADI;EACE,YAAA;AAGN;AADI;EACE,4CAAA;AAGN;AADI;EACE,YAAA;EACA,0BAAA;AAGN;AAFM;EACE,yCAAA;AAIR;AADI;EACE,iBAAA;AAGN","sourcesContent":[".charts-footer-bar {\n  display: flex;\n  align-items: center;\n  overflow-x: auto;\n  overflow-y: hidden;\n  scroll-snap-type: x proximity;\n  width: calc(100vw - 400px);\n  position: fixed;\n  bottom: -110px;\n  z-index: 1020;\n  padding: 0 20px !important;\n  height: 80px;\n  transition: transform 0.3s;\n  gap: 10px;\n  font-size: 14px;\n  background: #fff;\n  border: 1px solid #e2e2e2;\n  border-radius: 10px;\n  scroll-padding: 20px;\n  .dark-layout & {\n    background: #1e222d;\n    border: 1px solid #3e3f4f;\n  }\n  &.active {\n    transform: translateY(-120px);\n  }\n  & > label {\n    padding: 10px 10px;\n    flex: 0 0 auto;\n    border: 1px dashed #2684ff50;\n    cursor: grab;\n    border-radius: 5px;\n    transition: background-color 0.3s;\n    &.disabled {\n      opacity: 0.5;\n    }\n    &:hover {\n      background: #2684ff50;\n    }\n    &.invalid {\n      opacity: 0.4;\n      border: 1px dashed #ff0000;\n      &:hover {\n        background: #ff000050;\n      }\n    }\n    & > input {\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
