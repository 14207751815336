import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../../api/api';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { getCoreUrl } from '../../../helper/commonHelper';
export var hardwareDataKey = function (installationPointId, countDays) { return [
    'hardwareData',
    installationPointId,
    countDays,
]; };
var hardwareDataFn = function (coreLink, installationPointId, countDays) {
    return Api.get("".concat(coreLink, "/charts/get-hardware-readings/").concat(installationPointId, "/").concat(countDays));
};
export var useHardwareDataQuery = function (installationPointId, countDays) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: hardwareDataKey(installationPointId, countDays),
        queryFn: function () { return hardwareDataFn(getCoreUrl(selectedSystemType), installationPointId, countDays); },
        select: function (_a) {
            var readings = _a.readings;
            return {
                readings: readings,
            };
        },
    });
};
