// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleSearchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 29px;
  margin-left: 10px;
  float: right;
}

.top-actions-container {
  position: fixed;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 5px;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  background: #f0ece7;
  align-items: center;
  z-index: 1;
  width: calc(100vw - 280px);
  padding: 5px 15px 5px 0;
}

.text-with-image img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/AIPreAi/actionItems.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACF;;AACA;EACE,eAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,6BAAA;EACA,gCAAA;EACA,mBAAA;EACA,mBAAA;EACA,UAAA;EACA,0BAAA;EACA,uBAAA;AAEF;;AACA;EACE,eAAA;AAEF","sourcesContent":[".toggleSearchBtn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 31px;\n  width: 29px;\n  margin-left: 10px;\n  float: right;\n}\n.top-actions-container {\n  position: fixed;\n  flex-wrap: wrap;\n  display: flex;\n  margin-bottom: 5px;\n  border-top: 1px solid #d0d0d0;\n  border-bottom: 1px solid #d0d0d0;\n  background: #f0ece7;\n  align-items: center;\n  z-index: 1;\n  width: calc(100vw - 280px);\n  padding: 5px 15px 5px 0;\n}\n\n.text-with-image img {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
