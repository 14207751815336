import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Badge } from 'reactstrap';
import { ACTION_LIST } from '../../constants';
var StatusLabelAmazon = function (_a) {
    var status = _a.status, _b = _a.isTroubleshootingHoldWaiting, isTroubleshootingHoldWaiting = _b === void 0 ? 0 : _b, _c = _a.commentsCount, commentsCount = _c === void 0 ? 0 : _c;
    return (_jsxs(Badge, { className: "position-relative", color: isTroubleshootingHoldWaiting
            ? 'warning'
            : ACTION_LIST.STATUS_COLORS[status], children: [isTroubleshootingHoldWaiting ? (_jsxs(_Fragment, { children: ["Troubleshooting Hold - ", _jsx("br", {}), "Question"] })) : (_jsxs(_Fragment, { children: [ACTION_LIST.STATUS_NAMES[status], " "] })), commentsCount > 0 && _jsx("span", { className: "sub-badge", children: commentsCount })] }));
};
export default memo(StatusLabelAmazon);
