import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from "react";
import ChartZoomSelect from "../../shared/components/charts/topBar/components/ChartToolsButtons/ChartZoomSelect";
import ClearAdditionalFftTwfPoints from "../../shared/components/charts/topBar/components/ChartToolsButtons/ClearAdditionalFftTwfPoints";
import FftCursorSelect from "../../shared/components/charts/topBar/components/ChartToolsButtons/FftCursorSelect";
import ResetRmsZoom from "../../shared/components/charts/topBar/components/ChartToolsButtons/ResetRmsZoom";
import "./FullscreenChartWrapper.scss";
var FullscreenChartWrapper = function (_a) {
    var visible = _a.visible, children = _a.children, typeChart = _a.typeChart, _b = _a.className, className = _b === void 0 ? '' : _b, chartView = _a.chartView;
    return (_jsxs("div", { className: "".concat(visible ? 'fullscreen-chart-wrapper-modal' : '', " ").concat(className), style: !visible ? (chartView === 'list' ? { width: '100%' } : { width: 'calc(50% - 10px)' }) : {}, children: [visible ? (_jsxs(_Fragment, { children: [typeChart === 'rms' ? (_jsxs("div", { className: "top-bar-btns d-flex mt-3", children: [_jsx(ChartZoomSelect, {}), _jsx(ResetRmsZoom, {})] })) : (_jsx(_Fragment, {})), typeChart === 'fft' ? (_jsxs("div", { className: "top-bar-btns d-flex justify-content-between mt-3", children: [_jsx(FftCursorSelect, {}), _jsx(ClearAdditionalFftTwfPoints, {})] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {})), children] }));
};
export default memo(FullscreenChartWrapper);
