import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTitle } from "../../../../customHooks";
import BaseActionItemsPage from "../../../components/actionItem/list/BasePage";
import { selectSelectedCustomers } from "../../../store/SelectedCustomersStore";
import { selectSelectedLocations } from "../../../store/SelectedLocationsStore";
var ArchiveActionListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Archive Action List');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    return (_jsx(BaseActionItemsPage, { refreshFlag: refreshFlag, installationPointId: null, selectedCustomers: selectedCustomers, selectedLocations: selectedLocations, title: "Archived Action items", isActive: false, tableName: 'actionListArchiveTable' }));
};
export default ArchiveActionListPage;
