import Api from '../../api';
export default {
    getChartTypesList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/get-chart-types-list', options);
    },
    getRmsReadings: function (installationPointId, readingTypeId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-rms-readings/".concat(installationPointId, "/").concat(readingTypeId, "/").concat(countDays), options);
    },
    getRmsReadingsByReadingTypes: function (installationPointId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-rms-readings-by-reading-types/".concat(installationPointId, "/").concat(countDays), options);
    },
    getTachReadings: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/charts/get-tach-data', options);
    },
    getRmsReadingsBatch: function (readingTypeId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-rms-readings-batch/".concat(readingTypeId, "/").concat(countDays), options);
    },
    getFftReadings: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-fft-readings/".concat(installationPointId), options);
    },
    getFftReadingsTimestamps: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-fft-readings-timestamps/".concat(installationPointId), options);
    },
    getFft: function (readingId, installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-fft/".concat(readingId, "/").concat(installationPointId), options);
    },
    getAllFftByIds: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/charts/get-all-fft-by-ids/".concat(installationPointId), options);
    },
    getFftAverageData: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/charts/get-chart-fft-average-data', options);
    },
    getChartsSettings: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/charts/get-settings', options);
    },
    removePoint: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/charts/remove-point', data);
    },
    outlier: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/charts/outlier', data);
    },
    getClosestFft: function (installationPointId, axisId, timestamp, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/fft-charts/get-closest-fft/".concat(installationPointId, "/").concat(axisId, "/").concat(timestamp), options);
    },
    saveChartSettings: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/settings', body, options);
    },
    searchTachometers: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/equipment/search-tachometers', options);
    },
};
