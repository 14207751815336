var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cookies from 'react-cookies';
var initialOptions = {
    path: '/',
    expires: new Date(Date.now() + 365 * 24 * 3600 * 1000),
};
export var set = function (key, value, options) {
    if (options === void 0) { options = {}; }
    return cookies.save(key, value, __assign(__assign({}, initialOptions), options));
};
export var get = function (key, defaultValue) { return cookies.load(key) || defaultValue; };
export var del = function (key, options) {
    if (options === void 0) { options = {}; }
    return cookies.remove(key, __assign({ path: '/' }, options));
};
export default { set: set, get: get, del: del };
