import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
import { selectSelectedCustomers } from '../store/SelectedCustomersStore';
export var locationsAssetsTreeKey = function (selectedCustomers) { return [
    'locationsAssetsTree',
    { selectedCustomers: selectedCustomers },
]; };
var locationsAssetsTreeFn = function (_a) {
    var queryKey = _a.queryKey;
    var selectedCustomers = queryKey[1].selectedCustomers;
    return Api.get('all-analytics/location/select-for-customer', {
        query: {
            selected_customer_ids: selectedCustomers,
        },
    });
};
export var useLocationsAssetsTreeQuery = function () {
    var selectedCustomers = selectSelectedCustomers();
    return useQuery({
        queryKey: locationsAssetsTreeKey(selectedCustomers),
        queryFn: locationsAssetsTreeFn,
        select: function (_a) {
            var list = _a.list;
            return {
                list: list !== null && list !== void 0 ? list : [],
            };
        },
        placeholderData: {
            list: [],
        },
        enabled: !!selectedCustomers.length,
    });
};
