// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fft-alert-action-settings {
  position: absolute;
  z-index: 2;
  top: -350px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background-color: #f1f1f1;
  padding: 10px;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.5);
}
.dark-layout .fft-alert-action-settings {
  background: #1e222d !important;
  box-shadow: 0px 0px 40px 1px rgba(255, 255, 255, 0.5);
}

.highcharts-plot-lines-4 {
  cursor: pointer;
  position: relative;
}
.highcharts-plot-lines-4:after {
  content: "";
  position: absolute;
  left: 42%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #e8e8e8;
  clear: both;
}

.band-plotline-label {
  cursor: pointer;
  transform: rotate(-90deg);
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/FftAlertsModal/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;EACA,UAAA;EACA,yBAAA;EACA,aAAA;EAGA,+CAAA;AACF;AACE;EACE,8BAAA;EAGA,qDAAA;AACJ;;AAGA;EACE,eAAA;EACA,kBAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,QAAA;EACA,SAAA;EACA,mCAAA;EACA,oCAAA;EACA,8BAAA;EACA,WAAA;AAAJ;;AAIA;EACE,eAAA;EACA,yBAAA;AADF","sourcesContent":[".fft-alert-action-settings {\n  position: absolute;\n  z-index: 2;\n  top: -350px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 90%;\n  background-color: #f1f1f1;\n  padding: 10px;\n  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.5);\n  -moz-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.5);\n  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.5);\n\n  .dark-layout & {\n    background: #1e222d !important;\n    -webkit-box-shadow: 0px 0px 40px 1px rgba(255, 255, 255, 0.5);\n    -moz-box-shadow: 0px 0px 40px 1px rgba(255, 255, 255, 0.5);\n    box-shadow: 0px 0px 40px 1px rgba(255, 255, 255, 0.5);\n  }\n}\n\n.highcharts-plot-lines-4 {\n  cursor: pointer;\n  position: relative;\n\n  &:after {\n    content: '';\n    position: absolute;\n    left: 42%;\n    top: 100%;\n    width: 0;\n    height: 0;\n    border-left: 20px solid transparent;\n    border-right: 20px solid transparent;\n    border-top: 20px solid #e8e8e8;\n    clear: both;\n  }\n}\n\n.band-plotline-label {\n  cursor: pointer;\n  transform: rotate(-90deg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
