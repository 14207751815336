import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { UncontrolledTooltip } from 'reactstrap';
import { selectActivityLogSize } from '../../../../../store/AIPreAi/useActionItemViewStore';
var SaveAsDefaultSize = function (_a) {
    var localStorageKey = _a.localStorageKey;
    var activityLogSize = selectActivityLogSize();
    var onSaveAsDefaultSize = function () {
        localStorage.setItem(localStorageKey, String(activityLogSize));
        setDefaultActivityLogSize(activityLogSize);
    };
    var _b = useState(localStorage.getItem(localStorageKey) ? Number(localStorage.getItem(localStorageKey)) : null), defaultActivityLogSize = _b[0], setDefaultActivityLogSize = _b[1];
    return (_jsx(_Fragment, { children: defaultActivityLogSize !== activityLogSize ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-save", id: "save_default", onClick: onSaveAsDefaultSize, children: _jsx(FaCheck, {}) }), _jsx(UncontrolledTooltip, { placement: "top", target: "save_default", children: "Save as default size" })] })) : (_jsx(_Fragment, {})) }));
};
export default memo(SaveAsDefaultSize);
