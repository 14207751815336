import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
var ConfirmModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, endpoint = _a.endpoint, callback = _a.callback;
    var _b = useState(''), comment = _b[0], setComment = _b[1];
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var onSave = function () {
        endpoint(comment).then(function (resp) {
            if (resp.success) {
                toast.success('Successfully confirmed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                callback();
                onCancel();
                setComment('');
                return;
            }
            toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "md", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Confirm" }), _jsx(ModalBody, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "comment", children: "Comment (optional):" }), _jsx(Input, { className: "input-cancel", id: "comment", bsSize: 'lg', value: comment, label: "comment", type: "textarea", onChange: function (ev) { return setComment(ev.currentTarget.value); } })] }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", outline: true, color: "primary", onClick: onSave, children: "Confirm" })] }) })] }));
};
export default ConfirmModal;
