import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var analystNotesUpdateFn = function (coreLink, note) {
    return Api.post("".concat(coreLink, "/analyst-notes/update"), note);
};
export var useAnalystNotesUpdateMutation = function () {
    var queryClient = useQueryClient();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (note) { return analystNotesUpdateFn(getCoreUrl(selectedSystemType), note); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['analystNotesList'], exact: false });
        },
    });
};
