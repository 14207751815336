import { useEffect } from 'react';
import { calculateWidth } from '../../../components/charts/chartsWrapper/features/shared/size';
import { getHiddenBtns } from '../../../features/charts/shared';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useCalculateHiddenBtns = function (state, type, setHiddenBtns) {
    var isVisibleFullscreenChart = state.isVisibleFullscreenChart, options = state.options, isDataAvailable = state.isDataAvailable;
    var chartView = ChartsStore(function (state) { return state.chartView; });
    var width = calculateWidth(chartView);
    useEffect(function () {
        if (isVisibleFullscreenChart) {
            setHiddenBtns([]);
        }
        else {
            setHiddenBtns(getHiddenBtns(type, width));
        }
    }, [isDataAvailable, options.chart.height]);
};
