import { get as _get } from 'lodash';
import { useContext } from 'react';
import { shallow } from 'zustand/shallow';
import { useOnScreen, usePrevious } from '../../../../customHooks';
import { fetch, updateSeries } from '../../../components/charts/chartsWrapper/features/rms';
import { updateOverlay } from '../../../components/charts/chartsWrapper/features/rms/overlays';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
import { useAlertConditions } from '../rms/useAlertConditions';
import { useAmbient } from '../rms/useAmbient';
import { useAnnotations } from '../rms/useAnnotations';
import { useBoostChart } from '../rms/useBoostChart';
import { useConnectNulls } from '../rms/useConnectNulls';
import { useFetchData } from '../rms/useFetchData';
import { useLoader } from '../rms/useLoader';
import { useLockRmsTooltip } from '../rms/useLockRmsTooltip';
import { useLockRmsTooltipPosition } from '../rms/useLockRmsTooltipPosition';
import { useLockedCursor } from '../rms/useLockedCursor';
import { useMainSeriesLoaded } from '../rms/useMainSeriesLoaded';
import { useNormalized } from '../rms/useNormalized';
import { useOverlays } from '../rms/useOverlays';
import { useRmsCursor } from '../rms/useRmsCursor';
import { useRmsSizeChartsEffect } from '../rms/useRmsSizeCharts';
import { useSeries } from '../rms/useSeries';
import { useSetChartRange } from '../rms/useSetChartRange';
import { useSetOptions } from '../rms/useSetOptions';
import { useSetXAxisExtremes } from '../rms/useSetXAxisExtremes';
import { useTachData } from '../rms/useTachData';
import { useXPlotLines } from '../rms/useXPlotLines';
import { useYAxisExtremes } from '../rms/useYAxisExtremes';
import { useZoom } from '../rms/useZoom';
import { useZoomEffect } from '../rms/useZoomEffect';
import { useAxisLabel } from '../shared/useAxisLabel';
import { useMessageOnChart } from '../shared/useMessageOnChart';
import { useResetChartSelection } from '../shared/useResetChartSelection';
import { useResetToolsToDefault } from '../shared/useResetToolsToDefault';
import { useSelectProblemChart } from '../shared/useSelectProblemChart';
import { useChangeAlertConditions, useChartAlertLevelsVisibleToggle, useResetAlertConditions, } from '../useRelatedHooks';
export var useRmsHooks = function (state, dispatch, chartIdentifier, chartRef, chartElementRef, timezone) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _a.installationPoint, overlayInstallationPoints = _a.overlayInstallationPoints, readingTypes = _a.readingTypes;
    var _b = ChartsStore(function (state) { return ({
        countDays: state.countDays,
        showSystemLevels: state.showSystemLevels,
        showMlSuggestedLevels: state.showMlSuggestedLevels,
    }); }, shallow), countDays = _b.countDays, showSystemLevels = _b.showSystemLevels, showMlSuggestedLevels = _b.showMlSuggestedLevels;
    var tachDataIsVisible = ChartFeaturesStore(function (state) { return ({
        tachDataIsVisible: state.tachDataIsVisible,
    }); }, shallow).tachDataIsVisible;
    var data = FftChartsStore(function (state) { return ({
        data: state.data,
    }); }, shallow).data;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var isVisible = useOnScreen(chartElementRef, [
        installationPoint.id,
        countDays,
        _get(data, 'sensor.is_hfdvue'),
    ]);
    var prevInstallationPoint = usePrevious(installationPoint);
    useSetOptions(state, dispatch, chartIdentifier, isVisible);
    useBoostChart(state, dispatch);
    useFetchData(state, function () {
        return fetch(chartIdentifier, installationPoint, dispatch, countDays, showSystemLevels, showMlSuggestedLevels, selectedSystemType, measure);
    });
    useOverlays(state, function (force) {
        return updateOverlay(state, dispatch, installationPoint, overlayInstallationPoints, countDays, chartIdentifier, selectedSystemType, readingTypes, force, measure, personalSettingMeasure);
    }, prevInstallationPoint);
    useAmbient(state, dispatch, chartIdentifier, function (series) { return updateSeries(state, dispatch, series); });
    useNormalized(state, dispatch, chartIdentifier, function (series) { return updateSeries(state, dispatch, series); });
    useTachData(state, dispatch, tachDataIsVisible[chartIdentifier] || false);
    useZoom(state, dispatch);
    useSetChartRange(state, chartRef, timezone);
    useSetXAxisExtremes(chartRef);
    useAlertConditions(state, dispatch, chartIdentifier);
    useConnectNulls(state, chartRef, chartIdentifier);
    useYAxisExtremes(state, dispatch);
    useLockedCursor(state, chartRef);
    useMessageOnChart(state, chartRef);
    useResetChartSelection(dispatch);
    useLoader(state, dispatch);
    useMainSeriesLoaded(state, dispatch);
    useResetToolsToDefault(state, chartRef);
    useSelectProblemChart(state, dispatch, chartIdentifier, chartRef);
    useAnnotations(state, dispatch, chartRef, isVisible);
    useXPlotLines(state, dispatch, chartRef);
    useRmsSizeChartsEffect(state, dispatch, chartRef);
    useRmsCursor(state, chartRef);
    useZoomEffect(state);
    useSeries(state, dispatch, chartIdentifier);
    useChangeAlertConditions(state, dispatch, chartIdentifier);
    useResetAlertConditions(state, dispatch, chartIdentifier);
    useChartAlertLevelsVisibleToggle(state, dispatch);
    useLockRmsTooltip(dispatch, chartRef);
    useLockRmsTooltipPosition(dispatch, chartRef);
    useAxisLabel(state, dispatch, chartIdentifier);
};
