import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { PRE_ACTION_LIST } from '../../../../constants';
import useToastErrorAlert from '../../../hooks/useToastErrorAlert';
import FormTextEditor from '../../AIPreAi/FormDescriptionTextEditor';
import Tags from '../../shared/Tags';
import { preAiFormDescriptionSchema } from './schemas';
var FormDescription = function (_a) {
    var id = _a.id, defaultTitle = _a.title, defaultDescription = _a.description, defaultUrgency = _a.urgency, editIsLoading = _a.editIsLoading, createIsLoading = _a.createIsLoading, editMutate = _a.editMutate, _b = _a.tags, tags = _b === void 0 ? [] : _b, preActionListTags = _a.preActionListTags, _c = _a.isCreate, isCreate = _c === void 0 ? false : _c, createMutate = _a.createMutate, installationPoint = _a.installationPoint, _d = _a.storage, storage = _d === void 0 ? null : _d;
    var _e = useState(isCreate || !preActionListTags
        ? []
        : preActionListTags.map(function (item) {
            return { id: _get(item, 'preActionListTag.id'), name: _get(item, 'preActionListTag.name') };
        })), selectedTags = _e[0], setSelectedTags = _e[1];
    var descriptionRef = useRef(defaultDescription);
    var _f = useForm({
        defaultValues: {
            description: defaultDescription,
            title: defaultTitle,
            urgency: defaultUrgency,
        },
        resolver: preAiFormDescriptionSchema,
    }), setValue = _f.setValue, control = _f.control, errors = _f.formState.errors, handleSubmit = _f.handleSubmit;
    useToastErrorAlert(errors);
    var _g = useWatch({
        control: control,
        name: ['title', 'urgency'],
    }), title = _g[0], urgency = _g[1];
    var onSubmit = handleSubmit(function () {
        if (!editMutate) {
            return;
        }
        editMutate({
            id: id,
            title: title,
            urgency: urgency,
            description: descriptionRef.current,
            tag_ids: selectedTags.map(function (selectedTag) { return selectedTag.id; }),
        }, {
            onSuccess: function () {
                toast.success('Successfully changed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            },
        });
    });
    var onCreate = handleSubmit(function () {
        if (!createMutate || !installationPoint) {
            return;
        }
        createMutate({
            title: title,
            installation_point_id: installationPoint.id,
            urgency: urgency,
            description: descriptionRef.current,
            tag_ids: selectedTags.map(function (selectedTag) { return selectedTag.id; }),
        }, {
            onSuccess: function () {
                toast.success('Successfully created', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            },
        });
    });
    var onTagsChange = useCallback(function (tags) {
        setSelectedTags(tags);
    }, []);
    return (_jsx("div", { children: _jsx("div", { children: _jsxs(Form, { children: [_jsx(Row, { children: _jsx(Col, { md: 8, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Title:" }), _jsx(Input, { type: "text", name: "title", id: "title", onChange: function (ev) { return setValue('title', ev.target.value); }, value: title })] }) }) }), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Urgency:" }), _jsxs(Input, { type: "select", name: "urgency", id: "urgency", onChange: function (ev) {
                                    return setValue('urgency', +ev.target.value);
                                }, value: urgency, children: [_jsx("option", { value: PRE_ACTION_LIST.URGENCY_STANDARD, children: PRE_ACTION_LIST.URGENCY_NAMES[PRE_ACTION_LIST.URGENCY_STANDARD] }), _jsx("option", { value: PRE_ACTION_LIST.URGENCY_HIGH, children: PRE_ACTION_LIST.URGENCY_NAMES[PRE_ACTION_LIST.URGENCY_HIGH] }), _jsx("option", { value: PRE_ACTION_LIST.URGENCY_CRITICAL, children: PRE_ACTION_LIST.URGENCY_NAMES[PRE_ACTION_LIST.URGENCY_CRITICAL] })] })] }), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Tags:" }), _jsx(Tags, { tags: tags, selectedTags: selectedTags, onTagsChange: onTagsChange })] }), _jsx(FormTextEditor, { control: control, installationPoint: installationPoint, descriptionRef: descriptionRef, storage: storage }), _jsx("div", { className: "d-flex justify-content-end", children: !id ? (_jsx(Button, { disabled: editIsLoading || createIsLoading, color: "success", size: "sm", onClick: function () {
                                setValue('description', descriptionRef.current);
                                onCreate();
                            }, children: "Create Pre Action Item" })) : (_jsx(_Fragment, { children: _jsx(Button, { disabled: editIsLoading || createIsLoading, color: "success", size: "sm", onClick: function () {
                                    setValue('description', descriptionRef.current);
                                    onSubmit();
                                }, children: "Save" }) })) })] }) }) }));
};
export default memo(FormDescription);
