import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import { INSTALLATION_POINT_GROUP, MULTI_GROUP, NODE_GROUP, selectSelectedGroup, useAnalystNotesModalsActions, } from "../../store/analystNotes/useAnalystNotesFormModalStore";
var AnalystNotesGroupSelect = function () {
    var selectedGroup = selectSelectedGroup();
    var setSelectedGroup = useAnalystNotesModalsActions().setSelectedGroup;
    var options = [
        {
            label: 'Multiple',
            value: MULTI_GROUP,
        },
        {
            label: 'Installation Point',
            value: INSTALLATION_POINT_GROUP,
        },
        {
            label: 'Node',
            value: NODE_GROUP,
        },
    ];
    return (_jsxs(FormGroup, { className: "col-12", children: [_jsx(Label, { children: "Groups:" }), _jsx(Select, { classNamePrefix: "customSelectStyleDefault", name: "analystNotesCustomerSelector", id: "analystNotesCustomerSelector", value: options.find(function (el) { return el.value === selectedGroup; }), onChange: function (_a) {
                    var value = _a.value;
                    setSelectedGroup(value);
                }, options: options })] }));
};
export default memo(AnalystNotesGroupSelect);
