import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import React, { memo } from 'react';
import { FaEye } from 'react-icons/fa';
import { WSStatusLabel } from '../../../../StatusLabel';
var TableRow = function (_a) {
    var _b = _a.item, item = _b === void 0 ? {} : _b, callback = _a.callback;
    return (_jsx(_Fragment, { children: !_size(item) && !callback ? (_jsx("tr", { children: _jsx("td", { className: "text-center", colSpan: 4, children: _jsx("b", { children: "There are no data" }) }) })) : (_jsxs("tr", { children: [_jsx("td", { children: item.username }), _jsxs("td", { children: [item.pre_ai || 0, " / ", item.no_action_required || 0] }), _jsx("td", { children: (Object.keys(_get(item, 'statusCount', {})) || []).map(function (statusName) { return (_jsx(WSStatusLabel, { status: statusName, label: _get(item, ['statusCount', statusName]) }, statusName)); }) }), _jsx("td", { children: _jsxs("button", { onClick: callback, className: "float-end btn btn-sm btn-primary", style: { width: '55px' }, children: [_jsx(FaEye, {}), " Info"] }) })] })) }));
};
export default memo(TableRow);
