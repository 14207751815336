import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FiRotateCw } from 'react-icons/fi';
var ResetFftTwfZoomButton = function (_a) {
    var chart = _a.chart, width = _a.width, isDisabled = _a.isDisabled;
    var resetZoomHandler = function () {
        chart.zoomOut();
    };
    return (_jsx("div", { className: "chart-btn me-auto d-block", children: _jsx("div", { className: "chart-fft-resetZoom chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsx("span", { className: "btn btn-secondary btn-sm", onClick: function () { return (!isDisabled ? resetZoomHandler() : {}); }, children: _jsx(FiRotateCw, { style: { fill: 'none' } }) }) }) }));
};
export default memo(ResetFftTwfZoomButton);
