// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting-modal .waiting-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d02d05;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.waiting-modal .waiting-info svg {
  fill: #fff;
}
.waiting-modal .hardware-assistance-subtitle {
  width: calc(100% - 30px);
}
.waiting-modal .waiting-toggle-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid #2684ff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 20px;
  background: rgba(38, 132, 255, 0.1254901961);
}
.dark-layout .waiting-modal .waiting-toggle-comment {
  border: 1px solid #2684ff;
}
.waiting-modal .waiting-toggle-comment:hover {
  background: #2684ff;
  color: #fff;
}
.dark-layout .waiting-modal .waiting-toggle-comment:hover {
  background: #2684ff;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/modals/actionItem/WaitingModal/index.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAEI;EACE,UAAA;AAAN;AAIE;EACE,wBAAA;AAFJ;AAKE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,4CAAA;AAHJ;AAII;EACE,yBAAA;AAFN;AAKI;EACE,mBAAA;EACA,WAAA;AAHN;AAIM;EACE,mBAAA;AAFR","sourcesContent":[".waiting-modal {\n  & .waiting-info {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #d02d05;\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n\n    & svg {\n      fill: #fff;\n    }\n  }\n\n  & .hardware-assistance-subtitle {\n    width: calc(100% - 30px);\n  }\n\n  & .waiting-toggle-comment {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 40px;\n    border-radius: 5px;\n    margin-bottom: 20px;\n    cursor: pointer;\n    transition: 0.2s;\n    border: 1px solid #2684ff;\n    width: fit-content;\n    padding: 0 20px;\n    background: #2684ff20;\n    .dark-layout & {\n      border: 1px solid #2684ff;\n    }\n\n    &:hover {\n      background: #2684ff;\n      color: #fff;\n      .dark-layout & {\n        background: #2684ff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
