import { find as _find, first as _first, get as _get } from 'lodash';
import { READING_TYPE_TEMPERATURE, TYPE_US } from '../../../../../../../../../constants';
import { getUoms } from '../../../../../../../../helper/chart';
import { getMeasure } from '../../../../../../../../helper/user';
export var hideNormalizedReadings = function (series, updateSeries) {
    var existingNormalizedSeriesIndex = findNormalizedSeriesIndex(series);
    if (existingNormalizedSeriesIndex !== -1) {
        // @ts-ignore
        series[existingNormalizedSeriesIndex].visible = false;
        return updateSeries(series);
    }
};
export var showNormalizedReadings = function (series, updateSeries, readingTypes, measure, personalSettingMeasure) {
    var existingNormalizedSeriesIndex = findNormalizedSeriesIndex(series);
    if (existingNormalizedSeriesIndex !== -1) {
        // @ts-ignore
        series[existingNormalizedSeriesIndex].visible = true;
        return updateSeries(series);
    }
    calculateNormalizedReadings(series, updateSeries, readingTypes, measure, personalSettingMeasure);
};
export var findNormalizedSeriesIndex = function (series) {
    var existingAmbientSeriesIndex = -1;
    series.map(function (seriesItem, index) {
        if (seriesItem.id === 'ambientNormalizeData') {
            existingAmbientSeriesIndex = index;
        }
    });
    return existingAmbientSeriesIndex;
};
export var calculateNormalizedReadings = function (series, updateSeries, readingTypes, measure, personalSettingMeasure) {
    var data = [];
    // @ts-ignore
    var temperatureReadings = series[0].data, ambientReadings = series.filter(function (seriesConfig) { return seriesConfig.id && seriesConfig.id.indexOf('ambientData') !== -1; });
    temperatureReadings.map(function (values) {
        // @ts-ignore
        data.push(calculateNormalizedPoint(values, ambientReadings));
    });
    series.push({
        id: 'ambientNormalizeData',
        // @ts-ignore
        visible: true,
        name: 'Normalize temperature readings',
        data: data.filter(function (item) { return item; }),
        units: getUoms(READING_TYPE_TEMPERATURE, measure, personalSettingMeasure),
        chartType: 1,
        showInLegend: false,
        connectNulls: true,
        precision: readingTypes[READING_TYPE_TEMPERATURE].precision,
        dataGrouping: {
            approximation: 'high',
            forced: true,
        },
        color: '#ff0000',
        dashStyle: 'Solid',
        zones: [],
    });
    updateSeries(series);
};
export var calculateNormalizedPoint = function (point, ambientReadings, measure, personalSettingMeasure) {
    var ambientValue = _get(_find(ambientReadings[0].data, { 0: _first(point) }), 1), temperatureValue = +_get(point, '1'), value = +temperatureValue - +ambientValue;
    if (onZero(ambientValue, measure, personalSettingMeasure)) {
        return [_first(point), +value];
    }
    return null;
};
export var onZero = function (value, measure, personalSettingMeasure) {
    var convertedValue = value;
    if (getMeasure(READING_TYPE_TEMPERATURE, measure, personalSettingMeasure) === TYPE_US) {
        convertedValue = (value - 32) * (5 / 9);
    }
    return convertedValue !== 0;
};
