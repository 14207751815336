var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, head as _head } from 'lodash';
import moment from 'moment/moment';
import React, { useContext, useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import UserSourceBadge from '../../../shared/components/UserSourceBadge';
import DataTable from '../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../shared/config/onDemandUsersRequests/dataTableConfig';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { exportToCsv, onChangeDateRangeFilter, onUserChange, onViewInstallationPoint, } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../shared/hooks/useResetDataTableEffect';
import { Filters } from '../../components/onDemandUsersRequests/Filter';
import { onToggleWhoCreated } from '../../features/onDemandUsersRequests';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint, function (callbacks, filtersData) { return (_jsx(Filters, { callbacks: callbacks, filtersData: filtersData })); });
    return {
        activeUserId: '',
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'ssoPermissions',
                    title: '',
                    sortable: false,
                    component: function (row) {
                        var permissions = _get(row, 'user.ssoPermissions') || [];
                        return _jsx(UserSourceBadge, { permissions: permissions, fieldName: "permission" });
                    },
                },
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true), filtersData: __assign(__assign({}, config.filtersData), { dateRange: [], activeWhoCreated: '' }) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var _a, _b, _c, _d;
    setLoader(state, dispatch, true);
    var config = state.config, activeUserId = state.activeUserId;
    var filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    var activeWhoCreated = filtersData.activeWhoCreated;
    Endpoints.remastered
        .getOnDemandUsersFftRequests({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_id: _head(selectedLocations),
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            user_id: activeUserId,
            source: activeWhoCreated,
            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                ? moment((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                ? moment((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, filtersData), { users: resp.users || [] }) }),
                },
            });
        }
    });
};
var OnDemandUsersRequestsPage = function (_a) {
    var _b, _c;
    var refreshFlag = _a.refreshFlag;
    useTitle("On Demand FFT's");
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _d[0], dispatch = _d[1];
    var config = state.config, activeUserId = state.activeUserId;
    var filtersData = config.filtersData;
    var activeWhoCreated = filtersData.activeWhoCreated;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var callbackFetch = function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); };
    useResetDataTableEffect([selectedCustomers, selectedLocations, (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange], state, dispatch);
    useEffect(function () {
        callbackFetch();
    }, [
        refreshFlag,
        selectedCustomers,
        selectedLocations,
        activeUserId,
        activeWhoCreated,
        (_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange,
    ]);
    return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["On Demand ", "FFT's", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                    var _a, _b, _c, _d;
                                    return exportToCsv(Endpoints.remastered.getOnDemandUsersFftRequestsCsv, 'on-demand-fft', {
                                        query: {
                                            selected_customer_ids: selectedCustomers,
                                            selected_location_id: _head(selectedLocations),
                                            sort: config.sort,
                                            search: config.search,
                                            user_id: activeUserId,
                                            source: activeWhoCreated,
                                            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                                                ? moment((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                            date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                                                ? moment((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                        },
                                        isBlob: true,
                                    });
                                }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, onPageSizeChange: function (pageSize) {
                            return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                        }, onSort: function (column_name, ev) {
                            return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev);
                        }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); }, callbacks: {
                            onUserChange: function (selectedActiveUserId) { return onUserChange(dispatch, selectedActiveUserId); },
                            onToggleWhoCreated: function (source) { return onToggleWhoCreated(state, dispatch, source); },
                            onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                        } })] }) }) }));
};
export default OnDemandUsersRequestsPage;
