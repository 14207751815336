import { jsx as _jsx } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
export var config = function (visibleState) {
    return {
        columns: [
            {
                name: 'facility_name',
                visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
                title: 'Facility',
                sortable: false,
            },
            {
                name: 'equipment_name',
                visible: _has(visibleState, 'equipment_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.equipment_name : true,
                title: 'Equipment',
                sortable: false,
            },
            {
                name: 'point_name',
                visible: _has(visibleState, 'point_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.point_name : true,
                title: 'Name',
                sortable: false,
            },
            {
                name: 'sensor_id',
                visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true, notShowEmptyHex: true });
                },
            },
            {
                name: 'last_seen',
                visible: _has(visibleState, 'last_seen') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.last_seen : true,
                title: 'Last Seen',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "old_points_last_seen_widget_".concat(row.id), dateTime: row.last_seen, timeBreak: true }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(row.id, "&fromTodo=1"), target: "_blank", children: _jsx(Button, { color: "primary", size: "sm", children: "View" }) }));
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: false,
        hideTotalLabel: true,
        noDataLabel: 'There are no data',
    };
};
