import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import LinkWithPrefix from '../../../LinkWithPrefix';
import './Tabs.scss';
var Tabs = function (_a) {
    var activeTab = _a.activeTab;
    return (_jsxs(Nav, { tabs: true, className: "mb-2 rejected-tabs", children: [_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === 'ai', children: _jsx(LinkWithPrefix, { to: "/ml-statistic/rejected-ai", children: "Rejected AI" }) }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === 'pre-ai', children: _jsx(LinkWithPrefix, { to: "/ml-statistic/rejected-pre-ai", children: "Rejected Pre-AI" }) }) })] }));
};
export default Tabs;
