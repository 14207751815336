// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.missed-attachments {
  max-height: 150px;
  height: 150px;
  max-width: 150px;
  width: 150px;
  position: relative;
  margin: 5px;
  padding: 13px;
}

.analyst-notes-content img {
  max-width: 100%;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/analystNotes/AnalystNotes.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;AACF","sourcesContent":[".missed-attachments {\n  max-height: 150px;\n  height: 150px;\n  max-width: 150px;\n  width: 150px;\n  position: relative;\n  margin: 5px;\n  padding: 13px;\n}\n\n.analyst-notes-content img {\n  max-width: 100%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
