import Highcharts from "highcharts";
import HcMore from "highcharts/highcharts-more";
import Highstock from "highcharts/highstock";
import Annotations from "highcharts/modules/annotations";
import HcExportData from "highcharts/modules/export-data";
import HcExporting from "highcharts/modules/exporting";
import { get as _get } from "lodash";
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";
import { syncOverlays } from "../components/charts/chartsWrapper/components/shared/tooltip";
import { initMovableTooltip, moveCursorByKeys, setHarmonicsByPeaks, } from "../components/charts/chartsWrapper/features/shared/cursor";
import { initPainting } from "../components/charts/chartsWrapper/features/shared/painting";
import { initSymbols } from "../components/charts/chartsWrapper/features/shared/symbols";
import { isRmsChart } from "./chart";
export var initFeatures = function () {
    NoDataToDisplay(Highcharts);
    Annotations(Highcharts);
    Annotations(Highstock);
    HcExporting(Highcharts);
    HcExportData(Highcharts);
    HcMore(Highcharts);
    //@ts-ignore
    Highcharts.Chart.prototype.isSidebandPoint = function () {
        //@ts-ignore
        var element = this.renderTo.closest('.chart-item');
        if (!element) {
            return false;
        }
        return element.getElementsByClassName('sideBand-point').length > 0;
    };
    Highcharts.wrap(Highcharts.Series.prototype, 'onMouseOver', function () {
        return false;
    });
    Highcharts.wrap(Highcharts.Series.prototype, 'onMouseOut', function () {
        return false;
    });
    //@ts-ignore
    Highcharts.Chart.prototype.isZoomed = function () {
        //@ts-ignore
        var element = this.renderTo.closest('.chart-item');
        if (!element) {
            return false;
        }
        return element.getElementsByClassName('highcharts-reset-zoom').length > 0;
    };
    Highcharts.wrap(Highcharts.Chart.prototype, 'getSVG', function (proceed) {
        var chart = $('.chart-item-' + this.userOptions.chartIdentifier);
        var crosshair = chart.find('.highcharts-crosshair');
        var tooltip = chart.find(isRmsChart(this.userOptions.chartIdentifier) ? 'div.highcharts-tooltip' : 'div.highcharts-tooltip');
        var dot = chart.find('.highcharts-markers');
        // eslint-disable-next-line prefer-rest-params
        var svg = proceed.apply(this, [].slice.call(arguments, 1));
        svg = svg.slice(0, -6);
        svg += _get(dot, '0.outerHTML');
        svg += _get(crosshair, '0.outerHTML');
        tooltip
            .find('.custom_tooltip')
            .css('background-color', 'rgba(255, 255, 255, 0.85)')
            .css('color', '#0a0a0a');
        this.userOptions.paintingData.elements.forEach(function (_a) {
            var element = _a.element;
            svg += element.outerHTML;
        });
        this.userOptions.paintingData.textElements.forEach(function (_a) {
            var element = _a.element;
            svg += element.outerHTML;
        });
        if (isRmsChart(this.userOptions.chartIdentifier)) {
            svg = svg
                .replaceAll('class="highcharts-plot-line-label "', 'class="highcharts-plot-line-label " style="display:none;"')
                .replaceAll('class="highcharts-plot-line hidden"', 'class="highcharts-plot-line hidden" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line caution "', 'class="highcharts-plot-line alarm-plot-line caution" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line caution not-exist"', 'class="highcharts-plot-line alarm-plot-line caution not-exist" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line caution  not-exist"', 'class="highcharts-plot-line alarm-plot-line caution not-exist" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient caution hide not-exist"', 'class="highcharts-plot-line alarm-plot-line-ambient caution hide not-exist" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient caution hide"', 'class="highcharts-plot-line alarm-plot-line-ambient caution hide" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient caution"', 'class="highcharts-plot-line alarm-plot-line-ambient caution" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient warning hide not-exist"', 'class="highcharts-plot-line alarm-plot-line-ambient warning hide not-exist" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient warning hide"', 'class="highcharts-plot-line alarm-plot-line-ambient warning hide" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line-ambient warning"', 'class="highcharts-plot-line alarm-plot-line-ambient warning" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line warning "', 'class="highcharts-plot-line alarm-plot-line warning" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line warning not-exist"', 'class="highcharts-plot-line alarm-plot-line warning not-exist" style="display:none;"')
                .replaceAll('class="highcharts-plot-line alarm-plot-line warning  not-exist"', 'class="highcharts-plot-line alarm-plot-line warning not-exist" style="display:none;"')
                .replaceAll('stroke="#eab700"', 'stroke="#7cb5ec"')
                .replaceAll('stroke="#ff0000"', 'stroke="#7cb5ec"');
            return ('<div class="saved-svg"><?xml-stylesheet type="text/css" ' +
                'href="http://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" ?>' +
                svg +
                '</svg>' +
                _get(tooltip, '0.outerHTML', '') +
                '</div>');
        }
        svg = svg
            .replaceAll('class="highcharts-plot-line-label "', 'class="highcharts-plot-line-label " style="display:none;"')
            .replaceAll('class="highcharts-plot-lines-0"', 'class="highcharts-plot-lines-0" style="display:none;"')
            .replaceAll('class="highcharts-plot-line "', 'class="highcharts-plot-line " style="display:none;"');
        svg += _get(tooltip, '0.outerHTML');
        return ('<div class="saved-svg"><?xml-stylesheet type="text/css" ' +
            'href="http://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" ?>' +
            svg +
            '</svg></div>');
    });
    Highcharts.wrap(Highcharts.PlotLineOrBand.prototype, 'renderLabel', function (proceed) {
        var _this = this;
        var eventType;
        // eslint-disable-next-line prefer-rest-params
        proceed.apply(this, [].slice.call(arguments, 1));
        var events = this.options.events;
        var label = this.label;
        if (events && label) {
            var addEvent = function (eventType) {
                label.on(eventType, function (e) {
                    events[eventType].apply(_this, [e]);
                });
            };
            for (eventType in events) {
                addEvent(eventType);
            }
        }
    });
    Highcharts.Tooltip.prototype.hide = function () { };
    Highcharts.Pointer.prototype.reset = function () {
        return undefined;
    };
    Highcharts.wrap(Highcharts.Tooltip.prototype, 'refresh', syncOverlays);
    document.removeEventListener('keydown', moveCursorByKeys);
    document.addEventListener('keydown', moveCursorByKeys);
    document.removeEventListener('keydown', setHarmonicsByPeaks);
    document.addEventListener('keydown', setHarmonicsByPeaks);
    var setAltIsPressed = function (ev) {
        //@ts-ignore
        Highcharts.Chart.prototype.altIsPressed = ev.altKey;
    };
    var setCtrlIsPressed = function (ev) {
        //@ts-ignore
        Highcharts.Chart.prototype.ctrlIsPressed = ev.ctrlKey || ev.metaKey;
    };
    document.removeEventListener('keydown', setAltIsPressed);
    document.removeEventListener('keyup', setAltIsPressed);
    document.addEventListener('keydown', setAltIsPressed);
    document.addEventListener('keyup', setAltIsPressed);
    document.removeEventListener('keydown', setCtrlIsPressed);
    document.removeEventListener('keyup', setCtrlIsPressed);
    document.addEventListener('keydown', setCtrlIsPressed);
    document.addEventListener('keyup', setCtrlIsPressed);
    initPainting();
    initSymbols();
    initMovableTooltip();
};
