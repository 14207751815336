var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
export var useResetSelectedUserName = function (deps, state, dispatch) {
    var isBlockResetSelectedUserName = state.isBlockResetSelectedUserName;
    useEffect(function () {
        if (isBlockResetSelectedUserName) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                selectedUserName: null,
            },
        });
    }, __spreadArray([], deps, true));
};
