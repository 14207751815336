import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { useTitle } from '../../../customHooks';
import Calendar from '../../../shared/components/watchersStatistic/Calendar';
import Tabs from '../../../shared/components/watchersStatistic/Tabs';
import { tabs } from '../../../shared/config/watchersStatistic';
import { selectDateRange, useActionItemModalsActions, } from '../../../shared/store/watchersStatistic/useDateRangeStore';
import '../../../shared/styles/watchersStatistic/watchersStatistic.scss';
var WatchersStatistic = function (_a) {
    var selectedFacility = _a.selectedFacility;
    useTitle('Watchers Statistic');
    var _b = useState(tabs[0].title), activeTabTitle = _b[0], setActiveTabTitle = _b[1];
    var dateRange = selectDateRange();
    var setDateRange = useActionItemModalsActions().setDateRange;
    var isDisabled = ['Count of alarm levels'].includes(activeTabTitle);
    return (_jsxs("div", { className: "page-watchersStatistic", children: [_jsxs("h3", { className: "page-title d-flex align-items-center", children: ["Watchers Statistic", !isDisabled ? _jsx(Calendar, { setDateRange: setDateRange, dateRange: dateRange }) : _jsx(_Fragment, {})] }), dateRange ? (_jsx(Tabs, { activeTabTitle: activeTabTitle, setActiveTabTitle: setActiveTabTitle, queryAdditionProperties: { selected_facility: selectedFacility } })) : (_jsx(_Fragment, {}))] }));
};
export default memo(WatchersStatistic);
