var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SYSTEM_TYPES_DATA } from '../../constants';
import { SystemTypeContext } from '../context/SystemTypeContext';
import { makeValidLink } from '../helper/routeHelper';
var LinkWithPrefix = function (props) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    return (_jsx(Link, __assign({}, props, { to: makeValidLink('/' + prefix + props.to), children: props.children })));
};
export default LinkWithPrefix;
