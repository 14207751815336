import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { POINT_TYPE_ADAPTER, POINT_TYPE_SENSOR } from '../../../../constants';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import HighlighterWord from '../../shared/HighlighterWord';
import InstallationPointRow from './InstallationPointRow';
var ComponentRow = function (_a) {
    var installationPoints = _a.installationPoints, componentName = _a.componentName, equipmentName = _a.equipmentName, activeInstallationPointId = _a.activeInstallationPointId, overlayInstallationPoints = _a.overlayInstallationPoints, onOverlayCheck = _a.onOverlayCheck, equipment = _a.equipment;
    var _b = useState(true), isActive = _b[0], setIsActive = _b[1];
    var filter = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
    }); }, shallow).filter;
    return (_jsxs(React.Fragment, { children: [_jsx("div", { className: "equipment-item-sub-header", onClick: function () { return setIsActive(!isActive); }, children: _jsx("div", { className: "equipment-code", children: _jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: componentName }) }) }), _jsx("div", { className: "equipment-select-wrap clearfix component-installation-points-container ".concat(isActive && 'active-component'), children: installationPoints.map(function (installationPoint) { return (_jsx("div", { children: installationPoint.point_type !== POINT_TYPE_SENSOR &&
                        installationPoint.point_type !== POINT_TYPE_ADAPTER ? (_jsx(_Fragment, {})) : (_jsx(InstallationPointRow, { installationPoint: installationPoint, activeInstallationPointId: activeInstallationPointId, equipment: equipment, onOverlayCheck: onOverlayCheck, overlayInstallationPoints: overlayInstallationPoints })) }, "".concat(equipmentName, "_").concat(componentName, "_").concat(installationPoint.id))); }) })] }));
};
export default memo(ComponentRow);
