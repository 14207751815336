import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaRegComments } from 'react-icons/fa';
import { selectEditComment, selectIsGatheringData, selectIsInternalComment, useActionItemViewActions, } from '../../../../../store/AIPreAi/useActionItemViewStore';
import DefaultTooltip from '../../../../DefaultTooltip';
var InternalCommentBtn = function () {
    var isInternalComment = selectIsInternalComment();
    var setIsInternalComment = useActionItemViewActions().setIsInternalComment;
    var editComment = selectEditComment();
    var isGatheringData = selectIsGatheringData();
    if (editComment || isGatheringData) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "left-menu-btn ".concat(isInternalComment ? 'active' : ''), onClick: function () { return setIsInternalComment(!isInternalComment); }, "data-tooltip-id": "internal-comment", children: _jsx(FaRegComments, {}) }), _jsxs(DefaultTooltip, { place: "left", openEvents: { mouseenter: true }, id: "internal-comment", children: [isInternalComment ? 'Disabled' : 'Enabled', " internal comment"] })] }));
};
export default memo(InternalCommentBtn);
