var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { ALERTS_LIST, IS_SHOW_LAST_VIEWED_INACTIVE, IS_SHOW_LAST_VIEWED_SUCCESS, IS_SHOW_LAST_VIEWED_WAITING_REQUEST, } from '../../../../constants';
import { useTitle } from '../../../../customHooks';
import { SelectTypes } from '../../../../shared/components/alerts/triggered/selects/SelectTypes';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/alerts/triggered/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { acknowledgeItem, getNewDataFromResp, onBatchSelectChange, onChangeLevel, onClickViewBtn, onLastViewed, onRequestChildren, onSelectProcess, onSelectReadingTypes, onShowAcknowledge, onToggleIsSelectedAlert, onToggleTree, } from '../../../../shared/features/alerts/triggered';
import { exportToCsv, onChangeDateRangeFilter, onViewInstallationPoint, } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../../shared/helper/table';
import { useShowTreeLastViewedEffect } from '../../../../shared/hooks/alerts/triggered/useShowTreeLastViewedEffect';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
import AcknowledgeModal from '../../../../shared/modals/acknowledge/Acknowledge';
import '../../../../shared/styles/alerts/triggered/triggered.scss';
import { AlarmLevel } from '../../../../widgets/AlarmLevel';
var initialState = function (onViewInstallationPoint, type) {
    var config = baseConfig(onViewInstallationPoint, type);
    return {
        isVisibleAcknowledgeModal: false,
        isShowLastViewed: IS_SHOW_LAST_VIEWED_INACTIVE,
        ids: [],
        isTriggerFetchLastViewed: false,
        lastViewedItem: {
            equipmentId: null,
            exists: null,
            alertId: null,
            installationPoint: null,
            page: null,
        },
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], config.columns.filter(function (el) {
                return !['point_name', 'sensor_id', 'count', 'reading_type', 'timestamp', 'level_name'].includes(el.name);
            }), true), [
                {
                    name: 'level',
                    title: '',
                    className: 'alarm-block',
                    sortable: false,
                    component: function (row) {
                        return _jsx(AlarmLevel, { levelName: +row.level === 2 ? ALERTS_LIST.WARNING : ALERTS_LIST.CAUTION });
                    },
                },
                {
                    name: 'title',
                    titleComponent: function (callbacks, filtersData) {
                        return (_jsx(SelectTypes, { type: type, readingTypes: filtersData.readingTypes, onSelectReadingTypes: callbacks.onSelectReadingTypes }));
                    },
                    sortable: false,
                }
            ], false), config.columns.filter(function (el) { return el.name === 'timestamp'; }), true), [
                {
                    name: 'facility',
                    title: 'Facility',
                    sortable: false,
                },
                {
                    name: 'zone',
                    title: 'Zone',
                    sortable: false,
                },
                {
                    name: 'area',
                    title: 'Area',
                    sortable: false,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: false,
                },
                {
                    name: 'point_name',
                    title: 'Point Name',
                    sortable: false,
                }
            ], false), config.columns.filter(function (el) { return el.name === 'sensor_id'; }), true), config.columns.filter(function (el) { return el.name === 'count'; }), true) }),
    };
};
var fetchLastViewed = function (state, dispatch) {
    var _a;
    var config = state.config, lastViewedItem = state.lastViewedItem;
    Endpoints.amazon
        .getLastViewedAlert({
        query: {
            pageSize: (_a = config.pagination) === null || _a === void 0 ? void 0 : _a.pageSize,
        },
    })
        .then(function (response) {
        if (response) {
            dispatch({
                type: 'setState',
                state: {
                    lastViewedItem: response.data || lastViewedItem,
                    config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { hasViewedAlert: response.data.exists || false }) }),
                },
            });
        }
    });
};
var fetch = function (state, dispatch, selectedFacility) {
    var config = state.config, isShowLastViewed = state.isShowLastViewed;
    if (!config.filtersData) {
        return;
    }
    var _a = config.filtersData, selectedLevel = _a.selectedLevel, showAcknowledge = _a.showAcknowledge, processStatus = _a.processStatus, readingTypes = _a.readingTypes, hasViewedAlert = _a.hasViewedAlert;
    setLoader(state, dispatch, true);
    Endpoints.amazon
        .getAlertsTriggeredList({
        query: {
            selected_facility: selectedFacility,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            level: selectedLevel,
            process_status: processStatus || null,
            reading_types: readingTypes || null,
            date_from: config.filtersData.dateRange[0]
                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: config.filtersData.dateRange[1]
                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    isTriggerFetchLastViewed: true,
                    ids: [],
                    isShowLastViewed: isShowLastViewed === IS_SHOW_LAST_VIEWED_WAITING_REQUEST
                        ? IS_SHOW_LAST_VIEWED_SUCCESS
                        : IS_SHOW_LAST_VIEWED_INACTIVE,
                    config: __assign(__assign({}, config), { data: getNewDataFromResp(resp, showAcknowledge), loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, config.filtersData), { isFilterClick: true, processStatus: processStatus || '', readingTypes: readingTypes || '', batchSelectedLevelsType: '', selectedLevel: selectedLevel || '', hasViewedAlert: hasViewedAlert || false, showAcknowledge: showAcknowledge || null }) }),
                },
            });
        }
    });
};
var TriggeredListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    var type = useParams().type;
    var navigate = useNavigate();
    if (type === 'fft') {
        navigate('/404');
    }
    useTitle("Alerts ".concat(type.toUpperCase()));
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(function (installationPointId, params) {
        return onViewInstallationPoint(installationPointId, selectedSystemType, params);
    }, type)), state = _d[0], dispatch = _d[1];
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: initialState(function (installationPointId, params) {
                return onViewInstallationPoint(installationPointId, selectedSystemType, params);
            }, type),
        });
    }, [type]);
    var config = state.config, isVisibleAcknowledgeModal = state.isVisibleAcknowledgeModal, isTriggerFetchLastViewed = state.isTriggerFetchLastViewed, isShowLastViewed = state.isShowLastViewed, ids = state.ids;
    var filtersData = config.filtersData, pagination = config.pagination;
    var processStatus = filtersData.processStatus, readingTypes = filtersData.readingTypes, selectedLevel = filtersData.selectedLevel, batchSelectedLevelsType = filtersData.batchSelectedLevelsType;
    var page = pagination.page;
    var callbackFetch = function () { return fetch(state, dispatch, selectedFacility); };
    useResetDataTableEffect([selectedFacility, config.filtersData.dateRange], state, dispatch);
    useShowTreeLastViewedEffect([isShowLastViewed], state, dispatch, function (id) {
        return Endpoints.amazon.getAlertsTriggeredByEquipment(id, {
            query: {
                selected_facility: selectedFacility,
                pagination: config.pagination,
                sort: config.sort,
                search: config.search,
                level: selectedLevel,
                process_status: processStatus,
                reading_types: readingTypes,
            },
        });
    });
    useEffect(function () {
        if (isTriggerFetchLastViewed) {
            fetchLastViewed(state, dispatch);
        }
    }, [isTriggerFetchLastViewed]);
    useEffect(function () {
        callbackFetch();
    }, [
        refreshFlag,
        selectedFacility,
        selectedLevel,
        processStatus,
        readingTypes,
        page,
        config.filtersData.dateRange,
    ]);
    return (_jsxs("div", { className: "page-AlertTriggered", children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("h3", { className: "page-title", children: ["Triggered alerts ", type.toUpperCase(), config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                    return exportToCsv(Endpoints.amazon.getAlertsTriggeredListCsv, 'amazon-alerts', {
                                        query: {
                                            selected_facility: selectedFacility,
                                            sort: config.sort,
                                            search: config.search,
                                            level: selectedLevel,
                                            process_status: processStatus || null,
                                            reading_types: readingTypes || null,
                                            date_from: config.filtersData.dateRange[0]
                                                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                            date_to: config.filtersData.dateRange[1]
                                                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                : '',
                                        },
                                        isBlob: true,
                                    });
                                }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { }); }, onPageSizeChange: function (pageSize) {
                            return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                        }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, callbacks: {
                            onLastViewed: function () { return onLastViewed(state, dispatch); },
                            onBatchSelectChange: function (batchSelectedLevelsType) {
                                return onBatchSelectChange(state, dispatch, batchSelectedLevelsType);
                            },
                            acknowledgeItem: function (itemId) { return acknowledgeItem(state, dispatch, itemId); },
                            onShowAcknowledge: function () { return onShowAcknowledge(state, dispatch); },
                            onChangeLevel: function (selectedLevel) { return onChangeLevel(state, dispatch, selectedLevel); },
                            onToggleIsSelectedAlert: function (item) {
                                return onToggleIsSelectedAlert(state, dispatch, item);
                            },
                            onRequestChildren: function (item) {
                                return onRequestChildren(state, dispatch, item, function () {
                                    return Endpoints.amazon.getAlertsTriggeredByEquipment(item.equipment_id, {
                                        query: {
                                            selected_facility: selectedFacility,
                                            pagination: config.pagination,
                                            sort: config.sort,
                                            search: config.search,
                                            level: selectedLevel,
                                            process_status: config.filtersData ? config.filtersData.processStatus : null,
                                            reading_types: config.filtersData ? config.filtersData.readingTypes : null,
                                        },
                                    });
                                });
                            },
                            onToggleTree: function (item, isOpenTree) {
                                return onToggleTree(state, dispatch, item, isOpenTree);
                            },
                            onSelectProcess: function (processStatus) { return onSelectProcess(state, dispatch, processStatus); },
                            onSelectReadingTypes: function (readingTypes) {
                                return onSelectReadingTypes(state, dispatch, readingTypes);
                            },
                            onClickViewBtn: function (equipmentId, pointId, alertId) {
                                return onClickViewBtn(state, dispatch, equipmentId, pointId, alertId);
                            },
                            onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                        }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); } }) }) }), _jsx(AcknowledgeModal, { state: state, dispatch: dispatch, callbackFetch: function () { return callbackFetch(); }, endpoint: function (options) {
                    return Endpoints.amazon.acknowledgeAlerts(__assign({ ids: batchSelectedLevelsType === 'all' ? 'full-all' : ids }, options));
                }, acknowledgeNotesName: "notes", onCancel: function () { return dispatch({ type: 'setState', state: { isVisibleAcknowledgeModal: false } }); }, visible: isVisibleAcknowledgeModal })] }));
};
export default TriggeredListPage;
