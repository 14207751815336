import { ALT_KEY_CODE, ALT_KEY_NAME, CONTROL_KEY_CODE, CONTROL_KEY_NAME, META_KEY_CODE, META_KEY_NAME, SHIFT_KEY_CODE, SHIFT_KEY_NAME, } from '../../constants/hotkeys';
export var getHotkey = function (_a) {
    var repeat = _a.repeat, ctrlKey = _a.ctrlKey, altKey = _a.altKey, metaKey = _a.metaKey, shiftKey = _a.shiftKey, keyCode = _a.keyCode, key = _a.key;
    var hotkeyCodes = [];
    var hotkeyNames = [];
    if (repeat) {
        return {
            codes: [],
            names: [],
        };
    }
    if (ctrlKey) {
        hotkeyCodes.push(CONTROL_KEY_CODE);
        hotkeyNames.push(CONTROL_KEY_NAME);
    }
    if (altKey) {
        hotkeyCodes.push(ALT_KEY_CODE);
        hotkeyNames.push(ALT_KEY_NAME);
    }
    if (metaKey) {
        hotkeyCodes.push(META_KEY_CODE);
        hotkeyNames.push(META_KEY_NAME);
    }
    if (shiftKey) {
        hotkeyCodes.push(SHIFT_KEY_CODE);
        hotkeyNames.push(SHIFT_KEY_NAME);
    }
    if (!hotkeyCodes.includes(keyCode)) {
        hotkeyCodes.push(keyCode);
        hotkeyNames.push(key.toLowerCase());
    }
    return {
        codes: hotkeyCodes,
        names: hotkeyNames,
    };
};
