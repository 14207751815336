import { get as _get, size as _size } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { setOptions } from '../../../components/charts/chartsWrapper/features/fftTwf';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { useAxisLabelQuery } from '../../../services/charts/useAxisLabelQuery';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useSetOptions = function (state, dispatch, chartIdentifier, chartRef, loader, data, fftTimestampDates, readings, isVisible, fftAlerts) {
    var _a, _b, _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var mainSeriesLoaded = state.mainSeriesLoaded;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        currentSpeed: state.currentSpeed,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _d.installationPoint, currentSpeed = _d.currentSpeed, readingTypes = _d.readingTypes;
    var chartView = ChartsStore(function (state) { return state.chartView; });
    var axisLabelData = useAxisLabelQuery({
        axisLabelExtraField: selectedSystemType === SYSTEM_TYPE_REMASTERED
            ? {
                customer_id: (_a = installationPoint.customer_id) !== null && _a !== void 0 ? _a : null,
            }
            : {
                facility: (_b = installationPoint.facility) !== null && _b !== void 0 ? _b : null,
            },
        equipmentId: (_c = installationPoint.equipment_id) !== null && _c !== void 0 ? _c : null,
    }).data;
    var axisLabelName = useMemo(function () {
        var _a, _b;
        return (_b = (_a = axisLabelData === null || axisLabelData === void 0 ? void 0 : axisLabelData[chartIdentifier]) === null || _a === void 0 ? void 0 : _a[installationPoint.id]) !== null && _b !== void 0 ? _b : _get(readingTypes, [chartIdentifier, 'axis'], '');
    }, [axisLabelData, chartIdentifier, installationPoint.id, readingTypes]);
    useEffect(function () {
        if (mainSeriesLoaded || loader || !isVisible) {
            return;
        }
        if (_size(_get(readings, chartIdentifier))) {
            setOptions(dispatch, state, data, fftTimestampDates, chartIdentifier, readings, fftAlerts, chartView, readingTypes, currentSpeed, chartRef, measure, personalSettingMeasure, axisLabelName);
            return;
        }
    }, [readings[chartIdentifier], isVisible]);
};
