import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var analystNotesCommentsByReadingTypeKey = function (installationPointIds, readingTypeIds) { return ['analystNotesCommentsByReadingType', installationPointIds, readingTypeIds]; };
var analystNotesCommentsByReadingTypeFn = function (coreLink, installationPointIds, readingTypeIds, selectedSystemType) {
    return Api.get("".concat(coreLink, "/alert-condition").concat(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 's' : '', "/get-comments"), {
        query: {
            duplicated: true,
            installationPointIds: installationPointIds,
            readingTypeIds: readingTypeIds,
        },
    });
};
export var useAnalystNotesCommentsByReadingTypeQuery = function (_a) {
    var installationPointIds = _a.installationPointIds, readingTypeIds = _a.readingTypeIds;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: analystNotesCommentsByReadingTypeKey(installationPointIds, readingTypeIds),
        queryFn: function () {
            return analystNotesCommentsByReadingTypeFn(getCoreUrl(selectedSystemType), installationPointIds, readingTypeIds, selectedSystemType);
        },
        select: function (_a) {
            var data = _a.data;
            return data;
        },
        placeholderData: function (previousData) {
            return (previousData !== null && previousData !== void 0 ? previousData : {
                data: [],
            });
        },
        enabled: !!installationPointIds && !!readingTypeIds,
    });
};
