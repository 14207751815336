var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { size as _size } from "lodash";
import React, { memo } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { InstallationPointStore } from "../../store/charts/chartsContent/InstallationPointStore";
import SensorLocationBlock from "./SensorLocationBlock";
var EquipmentPointsList = function (_a) {
    var index = _a.index, setIndex = _a.setIndex, id = _a.id, imageRef = _a.imageRef, positions = _a.positions, setPositions = _a.setPositions, imageInstallationPoint = _a.imageInstallationPoint, setImageInstallationPoint = _a.setImageInstallationPoint;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }), equipment = ((installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) || {});
    var onSelectInstallationPoint = function (pointId) {
        var imageInstallationPointLocal = __spreadArray([], imageInstallationPoint, true);
        imageInstallationPointLocal.forEach(function (item) {
            if (item.imgId === id) {
                item.installationPointId = pointId;
            }
        });
        setImageInstallationPoint(imageInstallationPointLocal);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "small text-secondary border-bottom pb-2", children: [_jsx(FaInfoCircle, {}), " Drag the marker to the location of the installation point"] }), _jsx(SensorLocationBlock, { checked: _size(imageInstallationPoint.find(function (imagePoint) {
                    return imagePoint.imgId === id && imagePoint.installationPointId === installationPoint.id;
                })), onSelectInstallationPoint: function () { return onSelectInstallationPoint(installationPoint.id); }, pointIndex: 999, setIndex: setIndex, index: index, imageId: id, installationPointId: 0, name: equipment.name, color: 'rgb(255, 193, 7)', id: equipment.id, positions: positions, setPositions: setPositions, imageRef: imageRef }, "installation-point-image-checker-".concat(index, "-").concat(999)), _jsx("div", { className: "border-bottom" }), ((equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) || []).map(function (installationPoint, indexInstallationPoint) { return (_jsx(SensorLocationBlock, { checked: _size(imageInstallationPoint.find(function (imagePoint) {
                    return imagePoint.imgId === id && imagePoint.installationPointId === installationPoint.id;
                })), onSelectInstallationPoint: function () { return onSelectInstallationPoint(installationPoint.id); }, pointIndex: indexInstallationPoint, setIndex: setIndex, index: index, imageId: id, installationPointId: installationPoint.id, name: installationPoint.name, color: installationPoint.color, id: installationPoint.id, positions: positions, setPositions: setPositions, imageRef: imageRef }, "installation-point-image-checker-".concat(index, "-").concat(indexInstallationPoint))); })] }));
};
export default memo(EquipmentPointsList);
