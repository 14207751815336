import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useActionItemModalsActions } from '../../../store/AIPreAi/useActionItemModalsStore';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
import './index.scss';
var StatusSelect = function (_a) {
    var status = _a.status, options = _a.options, cancelSnoozeMutate = _a.cancelSnoozeMutate, waitesNewMutate = _a.waitesNewMutate, inProgressMutate = _a.inProgressMutate, isReportExist = _a.isReportExist, isNoActionReport = _a.isNoActionReport, isViewMode = _a.isViewMode, onChange = _a.onChange;
    var actionItemId = useParams().id;
    var _b = useState(false), isDropdownOpen = _b[0], setIsDropdownOpen = _b[1];
    var color = status.color, name = status.name, buttons = status.buttons;
    var _c = useActionItemModalsActions(), setIsVisibleSnoozeModal = _c.setIsVisibleSnoozeModal, setIsVisibleAwaitingUpdateModal = _c.setIsVisibleAwaitingUpdateModal, setIsVisibleChangeToHardwareIssueModal = _c.setIsVisibleChangeToHardwareIssueModal, setIsVisibleHardwareAssistanceModal = _c.setIsVisibleHardwareAssistanceModal, setIsVisibleCancelHardwareAssistanceModal = _c.setIsVisibleCancelHardwareAssistanceModal, setIsVisibleCancelAndCloseModal = _c.setIsVisibleCancelAndCloseModal, setIsVisibleWaitingModal = _c.setIsVisibleWaitingModal, setIsVisibleQuestionModal = _c.setIsVisibleQuestionModal;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var methods = {
        cancel_and_close: function () {
            if (isReportExist || isNoActionReport) {
                return setConfirmData({
                    isVisible: true,
                    textConfirmationModal: 'Are you sure you want to close report? This will close Action Item without saving report data.',
                    onConfirm: function () {
                        setConfirmData({
                            isVisible: false,
                        });
                        setIsVisibleCancelAndCloseModal(true);
                    },
                });
            }
            return setIsVisibleCancelAndCloseModal(true);
        },
        snooze: function () { return setIsVisibleSnoozeModal(true); },
        waites_new: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to change the AI status to Waites New? ',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    waitesNewMutate({
                        action_item_id: +actionItemId,
                    }, {
                        onSuccess: function () {
                            toast.success('Successfully updated', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                },
            });
        },
        cancel_snooze: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to update action item?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    cancelSnoozeMutate({
                        action_item_id: actionItemId,
                    }, {
                        onSuccess: function () {
                            toast.success('Successfully snoozed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                },
            });
        },
        awaiting_update: function () { return setIsVisibleAwaitingUpdateModal(true); },
        change_to_hardware_issue: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to change the asset AI type to network AI? This AI will be processed by the Network team and will not be available for changes from Analytics.',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    setIsVisibleChangeToHardwareIssueModal(true);
                },
            });
        },
        get_hardware_assistance: function () { return setIsVisibleHardwareAssistanceModal(true); },
        waiting: function () { return setIsVisibleWaitingModal(true); },
        question: function () { return setIsVisibleQuestionModal(true); },
        cancel_hardware_assistance: function () { return setIsVisibleCancelHardwareAssistanceModal(true); },
        in_progress: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to update AI to "In Progress" status?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    if (!inProgressMutate) {
                        return;
                    }
                    inProgressMutate({
                        action_item_id: actionItemId,
                    }, {
                        onSuccess: function () {
                            toast.success('Successfully updated', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                },
            });
        },
    };
    var handleSelect = function (btnKeyName) {
        if ((onChange === null || onChange === void 0 ? void 0 : onChange(btnKeyName)) === false) {
            return;
        }
        methods[btnKeyName]();
    };
    return (_jsx("div", { className: "d-flex align-items-center", children: _jsxs(Dropdown, { className: "status-select-dropdown", size: "sm", isOpen: isDropdownOpen, toggle: function () { return setIsDropdownOpen(function (prev) { return !prev; }); }, children: [_jsx(DropdownToggle, { color: color, caret: !!buttons.length && !isViewMode, children: name }), buttons.length && !isViewMode ? (_jsx(DropdownMenu, { children: buttons.map(function (btnKeyName) { return (_jsx("div", { children: options[btnKeyName] ? (_jsxs(DropdownItem, { disabled: false, onClick: function () { return handleSelect(btnKeyName); }, children: [_jsx("div", { className: "label-status", children: options[btnKeyName].label }), _jsx("div", { className: "to-status", children: options[btnKeyName].to ? (_jsxs(_Fragment, { children: ["Changes the status to ", _jsx(FaArrowRightLong, {}), _jsx("b", { children: options[btnKeyName].to })] })) : (_jsx(_Fragment, {})) })] })) : (_jsx(_Fragment, {})) }, btnKeyName)); }) })) : (_jsx(_Fragment, {}))] }) }));
};
export default memo(StatusSelect);
