import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import AnalystNotesGroupSelect from '../../../shared/components/analystNotes/AnalystNotesGroupSelect';
import { INSTALLATION_POINT_GROUP, MULTI_GROUP, NODE_GROUP, selectNote, selectSelectedGroup, useAnalystNotesModalsActions, } from '../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import { useAreasQuery } from '../../services/useAreasQuery';
import { useComponentsQuery } from '../../services/useComponentsQuery';
import { useEquipmentsQuery } from '../../services/useEquipmentsQuery';
import { useFacilitiesQuery } from '../../services/useFacilitiesQuery';
import { useZonesQuery } from '../../services/useZonesQuery';
import FacilitySelectAnalystNotes from './components/FacilitySelectAnalystNotes';
var SelectsGroup = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var note = selectNote();
    var facility = note.facility, zone = note.zone, area = note.area, equipmentsOrigin = note.equipments, components = note.components;
    var equipment_ids = useMemo(function () { var _a; return (_a = equipmentsOrigin === null || equipmentsOrigin === void 0 ? void 0 : equipmentsOrigin.map(function (equipment) { return equipment.id; })) !== null && _a !== void 0 ? _a : null; }, [equipmentsOrigin]);
    var selectedGroup = selectSelectedGroup();
    var _h = useAnalystNotesModalsActions(), setZone = _h.setZone, setArea = _h.setArea, setEquipments = _h.setEquipments, setComponents = _h.setComponents, setInstallationPointId = _h.setInstallationPointId, setNode = _h.setNode;
    var facilitiesData = useFacilitiesQuery().data;
    var facilities = facilitiesData.facilities;
    var facilityName = useMemo(function () { var _a, _b; return (_b = (_a = facilities.find(function (el) { return el.facility_id === (facility === null || facility === void 0 ? void 0 : facility.id); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; }, [facilities, facility === null || facility === void 0 ? void 0 : facility.id]);
    var _j = useZonesQuery({ facility: facilityName }), zonesData = _j.data, zonesIsLoading = _j.isLoading, zonesIsFetching = _j.isFetching;
    var zones = zonesData.zones;
    var zoneName = useMemo(function () { var _a, _b; return (_b = (_a = zones.find(function (el) { return el.zone === (zone === null || zone === void 0 ? void 0 : zone.name); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; }, [zones, zone]);
    var _k = useAreasQuery({ facility: facilityName, zone: (_a = zone === null || zone === void 0 ? void 0 : zone.name) !== null && _a !== void 0 ? _a : null }), areasData = _k.data, areasIsLoading = _k.isLoading, areasIsFetching = _k.isFetching;
    var areas = areasData.areas;
    var areaName = useMemo(function () { var _a, _b; return (_b = (_a = areas.find(function (el) { return el.area === (area === null || area === void 0 ? void 0 : area.name); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; }, [areas, area]);
    var _l = useEquipmentsQuery({ zone: (_b = zone === null || zone === void 0 ? void 0 : zone.name) !== null && _b !== void 0 ? _b : null, area: (_c = area === null || area === void 0 ? void 0 : area.name) !== null && _c !== void 0 ? _c : null }), equipmentsData = _l.data, equipmentsIsLoading = _l.isLoading, equipmentsIsFetching = _l.isFetching;
    var equipments = equipmentsData.equipments;
    var _m = useComponentsQuery({
        facility: facilityName,
        zone: (_d = zone === null || zone === void 0 ? void 0 : zone.name) !== null && _d !== void 0 ? _d : null,
        area: (_e = area === null || area === void 0 ? void 0 : area.name) !== null && _e !== void 0 ? _e : null,
        equipmentIds: equipment_ids,
    }), componentsData = _m.data, componentsIsLoading = _m.isLoading, componentsIsFetching = _m.isFetching;
    var componentsList = componentsData.components;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(AnalystNotesGroupSelect, {}) }), selectedGroup === MULTI_GROUP ? (_jsxs(Row, { children: [_jsx(FacilitySelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Zone:" }), _jsx(Select, { isClearable: true, placeholder: "Select Zone", classNamePrefix: "customSelectStyleDefault", name: "analystNotesZoneSelector", id: "analystNotesZoneSelector", isLoading: zonesIsLoading || zonesIsFetching, value: zoneName ? { label: zoneName, value: zone === null || zone === void 0 ? void 0 : zone.name } : null, onChange: function (event) {
                                    var zone = event
                                        ? {
                                            name: event.value,
                                        }
                                        : null;
                                    setZone(zone);
                                    setArea(null);
                                    setEquipments(null);
                                    setComponents(null);
                                }, options: zones.map(function (_a) {
                                    var name = _a.name, zone = _a.zone;
                                    return {
                                        label: name,
                                        value: zone,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Area:" }), _jsx(Select, { isClearable: true, placeholder: "Select Area", classNamePrefix: "customSelectStyleDefault", name: "analystNotesAreaSelector", id: "analystNotesAreaSelector", isLoading: areasIsLoading || areasIsFetching, value: areaName ? { label: areaName, value: area === null || area === void 0 ? void 0 : area.name } : null, onChange: function (event) {
                                    var area = event
                                        ? {
                                            name: event.value,
                                        }
                                        : null;
                                    setArea(area);
                                    setEquipments(null);
                                    setComponents(null);
                                }, options: areas.map(function (_a) {
                                    var name = _a.name, area = _a.area;
                                    return {
                                        label: name,
                                        value: area,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Equipment:" }), _jsx(Select, { isMulti: true, placeholder: "Select Equipments", classNamePrefix: "customSelectStyleDefault", name: "analystNotesEquipmentSelector", id: "analystNotesEquipmentSelector", isLoading: equipmentsIsLoading || equipmentsIsFetching, value: (equipment_ids !== null && equipment_ids !== void 0 ? equipment_ids : []).map(function (equipmentId) {
                                    var _a;
                                    return {
                                        label: (_a = equipments.find(function (equipment) { return equipment.equipment_id === equipmentId; })) === null || _a === void 0 ? void 0 : _a.name,
                                        value: equipmentId,
                                    };
                                }), onChange: function (data) {
                                    setEquipments(data.map(function (el) {
                                        return {
                                            id: el.value,
                                            name: el.label,
                                        };
                                    }));
                                    setComponents(null);
                                }, options: equipments.map(function (_a) {
                                    var name = _a.name, equipment_id = _a.equipment_id;
                                    return {
                                        label: name,
                                        value: equipment_id,
                                    };
                                }) })] }), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Component Unit:" }), _jsx(Select, { isMulti: true, placeholder: "Select Components", classNamePrefix: "customSelectStyleDefault", name: "analystNotesComponentSelector", id: "analystNotesComponentSelector", isLoading: componentsIsLoading || componentsIsFetching, value: (components !== null && components !== void 0 ? components : []).map(function (component) {
                                    var _a;
                                    return {
                                        label: (_a = componentsList.find(function (el) { return el.component === (component === null || component === void 0 ? void 0 : component.name); })) === null || _a === void 0 ? void 0 : _a.name,
                                        value: component,
                                    };
                                }), onChange: function (data) {
                                    setComponents(data.map(function (el) {
                                        return {
                                            name: el.value,
                                        };
                                    }));
                                }, options: componentsList.map(function (_a) {
                                    var name = _a.name, component = _a.component;
                                    return {
                                        label: name,
                                        value: component,
                                    };
                                }) })] })] })) : (_jsx(_Fragment, {})), selectedGroup === INSTALLATION_POINT_GROUP ? (_jsxs(Row, { children: [_jsx(FacilitySelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Installation Point:" }), _jsx(Input, { type: "number", min: 0, value: (_f = note.installationPointId) !== null && _f !== void 0 ? _f : '', onChange: function (ev) {
                                    setInstallationPointId(ev.target.value ? +ev.target.value : null);
                                } })] })] })) : (_jsx(_Fragment, {})), selectedGroup === NODE_GROUP ? (_jsxs(Row, { children: [_jsx(FacilitySelectAnalystNotes, {}), _jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Node:" }), _jsx(Input, { type: "number", min: 0, value: (_g = note.node_id) !== null && _g !== void 0 ? _g : '', onChange: function (ev) {
                                    setNode(ev.target.value ? +ev.target.value : null);
                                } })] })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(SelectsGroup);
