var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { isTwfChart } from '../../../../../../helper/chart';
import { ChartFeaturesStore } from '../../../../../../store/charts/chartsContent/ChartFeaturesStore';
var WaterfallPlotButton = function (_a) {
    var chartIdentifier = _a.chartIdentifier, selectedUnits = _a.selectedUnits, width = _a.width, isDisabled = _a.isDisabled;
    if (isTwfChart(chartIdentifier)) {
        return _jsx(React.Fragment, {});
    }
    var _b = ChartFeaturesStore(function (state) { return ({
        waterfallPlotModal: state.waterfallPlotModal,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), waterfallPlotModal = _b.waterfallPlotModal, setStatesChartFeaturesStore = _b.setStatesChartFeaturesStore;
    var onClick = useCallback(function () {
        setStatesChartFeaturesStore({
            waterfallPlotModal: __assign(__assign({}, waterfallPlotModal), { visible: true, chartIdentifier: chartIdentifier, selectedUnits: selectedUnits }),
        });
    }, [selectedUnits, waterfallPlotModal, chartIdentifier]);
    return (_jsx("div", { className: "chart-btn", children: _jsxs(Button, { style: { width: width }, className: "waterfall-plot chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", size: "sm", disabled: isDisabled, onClick: onClick, children: ["Show ", _jsx("b", { children: "Waterfall Plot" })] }) }));
};
export default React.memo(WaterfallPlotButton);
