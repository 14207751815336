import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import Endpoints from "../../../api/endpoints/endpoints";
import { READING_TYPES_KEYS } from "../../../constants";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import Loader from "../Loader";
var ReadingTypes = function (_a) {
    var readingTypes = _a.readingTypes, onClick = _a.onClick, activeReadingType = _a.activeReadingType;
    if (!readingTypes) {
        return _jsx(_Fragment, {});
    }
    var newReadingTypes = READING_TYPES_KEYS.map(function (key) {
        return {
            name: readingTypes[key],
            readingTypeId: key,
        };
    });
    return (_jsx("div", { className: "mb-2 pl-2", children: newReadingTypes.map(function (readingType) { return (_jsx(Button, { color: "info", className: "me-2 response-p response-button-width", size: "sm", active: activeReadingType && +activeReadingType === +readingType.readingTypeId, onClick: function () { return onClick(readingType.readingTypeId); }, children: readingType.name }, "reading_type_filter".concat(readingType.readingTypeId))); }) }));
};
var QuickFilters = function (_a) {
    var onClick = _a.onClick, activeQuickFilters = _a.activeQuickFilters, avgFilterFields = _a.avgFilterFields;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState([]), sensorTypes = _b[0], setSensorTypes = _b[1];
    useEffect(function () {
        Endpoints[selectedSystemType].getAvgSensorType().then(function (resp) {
            setSensorTypes(resp.data);
        });
    }, []);
    if (!activeQuickFilters) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-2 float-start response-pad", children: _jsx(ButtonGroup, { className: "response_group d-inline", children: avgFilterFields.map(function (el) { return (_jsx(Button, { className: "response-button-width", color: activeQuickFilters[el.name] ? 'primary' : 'secondary', size: "sm", onClick: function () { return onClick(el.name); }, children: el.title }, el.name)); }) }) }), _jsxs("div", { className: "mb-2 float-start response-pad", children: [_jsx(ButtonGroup, { className: "response_group d-inline", children: sensorTypes.map(function (sensorType) { return (_jsx(Button, { className: "response-button-width", color: activeQuickFilters.sensorVersionTypes &&
                                activeQuickFilters.sensorVersionTypes.includes(sensorType.version_type)
                                ? 'primary'
                                : 'secondary', size: "sm", onClick: function () { return onClick(sensorType.version_type, true); }, children: sensorType.partNumber }, sensorType.partNumber)); }) }), sensorTypes.length ? _jsx(Loader, { variant: "loader-xs" }) : _jsx(_Fragment, {})] })] }));
};
var TimeRange = function (_a) {
    var timeRange = _a.timeRange, onClick = _a.onClick;
    return (_jsx("div", { className: "float-end mb-2", children: _jsxs(ButtonGroup, { children: [_jsx(Button, { color: timeRange === '1' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('1'); }, children: "24h" }), _jsx(Button, { color: timeRange === '2' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('2'); }, children: "48h" }), _jsx(Button, { color: timeRange === '7' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('7'); }, children: "1w" }), _jsx(Button, { color: timeRange === '14' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('14'); }, children: "2w" }), _jsx(Button, { color: timeRange === '31' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('31'); }, children: "1m" }), _jsx(Button, { color: timeRange === '62' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('62'); }, children: "2m" }), _jsx(Button, { color: timeRange === '93' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('93'); }, children: "3m" }), _jsx(Button, { color: timeRange === '186' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('186'); }, children: "6m" }), _jsx(Button, { color: timeRange === '365' ? 'light' : 'primary', size: "sm", onClick: function () { return onClick('365'); }, children: "1y" })] }) }));
};
var Filters = function (_a) {
    var callbacks = _a.callbacks, filtersData = _a.filtersData, avgFilterFields = _a.avgFilterFields;
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(ReadingTypes, { activeReadingType: filtersData.activeReadingType, readingTypes: filtersData.readingTypes, onClick: callbacks.onChangeReadingType }) }), _jsxs(Row, { className: "response-flex", children: [_jsx(QuickFilters, { onClick: callbacks.onQuickFilterChange, activeQuickFilters: filtersData.activeQuickFilters, avgFilterFields: avgFilterFields }), _jsx(Col, { className: "response-item time-range-wrapper", children: _jsx(TimeRange, { timeRange: filtersData.timeRange, onClick: callbacks.onTimeRangeChange }) })] })] }));
};
export default Filters;
