// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-log-tabs .nav-link a {
  color: #212529;
  text-decoration: none;
}
.dark-layout .alert-log-tabs .nav-link a {
  color: #f1f1f1;
}
.alert-log-tabs .nav-link.active a {
  color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/alertLog/Tabs/Tabs.scss"],"names":[],"mappings":"AAEI;EACE,cAAA;EACA,qBAAA;AADN;AAEM;EACE,cAAA;AAAR;AAIM;EACE,cAAA;AAFR","sourcesContent":[".alert-log-tabs {\n  & .nav-link {\n    & a {\n      color: #212529;\n      text-decoration: none;\n      .dark-layout & {\n        color: #f1f1f1;\n      }\n    }\n    &.active {\n      & a {\n        color: #f1f1f1;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
