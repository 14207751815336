import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { preActionItemKey as preActionItemKeyAmazon } from '../../../amazon/services/preActionItems/usePreActionItemQuery';
import Api from '../../../api/api';
import { preActionItemKey as preActionItemKeyRemastered } from '../../../remastered/services/preActionItems/usePreActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var addReplaceMoteBatteryFn = function (data, coreLink, storage) {
    return Api.post("".concat(coreLink, "/action-item/replace-mote-battery"), data, { host: getHost(storage) });
};
export var useReplaceMoteBatteryMutation = function (_a) {
    var preActionItemId = _a.preActionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return addReplaceMoteBatteryFn(data, getCoreUrl(selectedSystemType), storage); },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: selectedSystemType
                    ? preActionItemKeyRemastered(preActionItemId)
                    : preActionItemKeyAmazon(preActionItemId),
            });
        },
    });
};
