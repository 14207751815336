var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStore } from '../createStore';
export var MULTI_GROUP = 1;
export var INSTALLATION_POINT_GROUP = 2;
export var NODE_GROUP = 3;
var useAnalystNotesFormModalsStore = createStore(function (set, get) { return ({
    isVisibleModal: false,
    selectedGroup: MULTI_GROUP,
    note: {
        id: null,
        text: '',
        title: '',
        tags: null,
        attachments: [],
        customer: null,
        facility: null,
        locations: null,
        zone: null,
        area: null,
        equipments: null,
        components: null,
        installationPointId: null,
        node_id: null,
    },
    actions: {
        setIsVisibleModal: function (isVisibleModal) { return set({ isVisibleModal: isVisibleModal }); },
        setSelectedGroup: function (selectedGroup) { return set({ selectedGroup: selectedGroup }); },
        setNote: function (note) { return set({ note: note }); },
        setCustomer: function (customer) {
            set({
                note: __assign(__assign({}, get().note), { customer: customer }),
            });
        },
        setFacility: function (facility) {
            set({
                note: __assign(__assign({}, get().note), { facility: facility }),
            });
        },
        setLocations: function (locations) {
            set({
                note: __assign(__assign({}, get().note), { locations: locations }),
            });
        },
        setZone: function (zone) {
            set({
                note: __assign(__assign({}, get().note), { zone: zone }),
            });
        },
        setArea: function (area) {
            set({
                note: __assign(__assign({}, get().note), { area: area }),
            });
        },
        setEquipments: function (equipments) {
            set({
                note: __assign(__assign({}, get().note), { equipments: equipments }),
            });
        },
        setComponents: function (components) {
            set({
                note: __assign(__assign({}, get().note), { components: components }),
            });
        },
        setAttachments: function (attachments) {
            set({
                note: __assign(__assign({}, get().note), { attachments: attachments }),
            });
        },
        setInstallationPointId: function (installationPointId) {
            set({
                note: __assign(__assign({}, get().note), { installationPointId: installationPointId }),
            });
        },
        setNode: function (node_id) {
            set({
                note: __assign(__assign({}, get().note), { node_id: node_id }),
            });
        },
        resetNote: function () {
            return set({
                note: {
                    id: null,
                    text: '',
                    title: '',
                    tags: null,
                    attachments: [],
                    customer: null,
                    facility: null,
                    locations: null,
                    zone: null,
                    area: null,
                    equipments: null,
                    components: null,
                    installationPointId: null,
                    node_id: null,
                },
            });
        },
    },
}); });
export var useAnalystNotesModalsActions = function () {
    return useAnalystNotesFormModalsStore(function (state) { return state.actions; });
};
export var selectIsVisibleModal = function () {
    return useAnalystNotesFormModalsStore(function (state) { return state.isVisibleModal; });
};
export var selectNote = function () {
    return useAnalystNotesFormModalsStore(function (state) { return state.note; });
};
export var selectSelectedGroup = function () {
    return useAnalystNotesFormModalsStore(function (state) { return state.selectedGroup; });
};
