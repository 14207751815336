import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var areasKey = function (facility, zone) { return ['areas', facility, zone]; };
var areasFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/facility/areas/".concat(queryKey[1], "/").concat(queryKey[2]));
};
export var useAreasQuery = function (_a) {
    var facility = _a.facility, zone = _a.zone;
    return useQuery({
        queryKey: areasKey(facility, zone),
        queryFn: areasFn,
        select: function (_a) {
            var areas = _a.areas;
            return {
                areas: areas.map(function (area) {
                    return {
                        name: area,
                        area: area,
                    };
                }),
            };
        },
        placeholderData: {
            areas: [],
        },
        enabled: !!facility && !!zone,
    });
};
