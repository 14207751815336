import { get as _get } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { setOptions } from '../../../components/charts/chartsWrapper/features/rms';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { useAxisLabelQuery } from '../../../services/charts/useAxisLabelQuery';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useSetOptions = function (state, dispatch, chartIdentifier, isVisible) {
    var _a, _b, _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _d.installationPoint, readingTypes = _d.readingTypes;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var _e = ChartsStore(function (state) { return ({
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
        setStatesChartStore: state.setStates,
        chartView: state.chartView,
    }); }, shallow), chartAlertLevelsVisibleToggle = _e.chartAlertLevelsVisibleToggle, setStatesChartStore = _e.setStatesChartStore, chartView = _e.chartView;
    var mainSeriesLoaded = state.mainSeriesLoaded, seriesIsRendered = state.seriesIsRendered, readings = state.readings;
    var data = useAxisLabelQuery({
        axisLabelExtraField: selectedSystemType === SYSTEM_TYPE_REMASTERED
            ? {
                customer_id: (_a = installationPoint.customer_id) !== null && _a !== void 0 ? _a : null,
            }
            : {
                facility: (_b = installationPoint.facility) !== null && _b !== void 0 ? _b : null,
            },
        equipmentId: (_c = installationPoint.equipment_id) !== null && _c !== void 0 ? _c : null,
    }).data;
    var axisLabelName = useMemo(function () { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data[chartIdentifier]) === null || _a === void 0 ? void 0 : _a[installationPoint.id]) !== null && _b !== void 0 ? _b : _get(readingTypes, [chartIdentifier, 'axis']); }, [data, chartIdentifier, installationPoint.id, readingTypes]);
    useEffect(function () {
        if (!mainSeriesLoaded || !isVisible || seriesIsRendered) {
            return;
        }
        setOptions(chartIdentifier, installationPoint, state, dispatch, setStatesChartStore, chartAlertLevelsVisibleToggle, setStatesChartFeaturesStore, readingTypes, chartView, measure, personalSettingMeasure, axisLabelName);
    }, [isVisible, readings, mainSeriesLoaded, data]);
};
