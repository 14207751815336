import Api from '../../api';
export default {
    getWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist', options);
    },
    removeWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("amazon-analytic/watchlist/".concat(id), options);
    },
    editWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/watchlist/".concat(id), options);
    },
    getDashboardWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist/dashboard', options);
    },
    getUsersWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist/users', options);
    },
    softRemoveWatchListItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("amazon-analytic/watchlist/soft-delete/".concat(id), options);
    },
    getWatchListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist/csv', options);
    },
    getUsersWatchListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist/users-csv', options);
    },
};
