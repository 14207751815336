import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { actionItemKey } from './useActionItemQuery';
var inProgressSaveFn = function (data) {
    return Api.post('amazon-analytic/action-item/change-to-in-progress', data);
};
export var useInProgressMutation = function (_a) {
    var actionItemId = _a.actionItemId;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: inProgressSaveFn,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: actionItemKey(actionItemId) });
        },
    });
};
