var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { chunk as _chunk, cloneDeep as _cloneDeep, difference as _difference, get as _get, size as _size, } from 'lodash';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { CHARTS_CHUNK, METRIC_KEY } from '../../../../../../constants';
import { DEFAULT_CHARTS_CONFIG } from '../../../../../config/charts/defaultChartsConfig';
import { getTimezone } from '../../../../../features/shared';
import { getUoms } from '../../../../../helper/chart';
import { copyTextToClipboard } from '../../../../../helper/dom';
export var updateOverlay = function (state, dispatch, installationPoint, overlayInstallationPoints, countDays, chartIdentifier, selectedSystemType, readingTypes, force, measure, personalSettingMeasure) {
    if (force === void 0) { force = false; }
    var options = state.options;
    var series = _cloneDeep(options.series);
    var overlayInstallationPointsIds = overlayInstallationPoints
        .map(function (overlayInstallationPoint) { return +overlayInstallationPoint.id; })
        .filter(function (el) { return el !== +installationPoint.id; });
    var optionsChanged = false;
    var alreadyLoadedInstallationPointsIds = [];
    if (!overlayInstallationPointsIds.length && series.length === 1) {
        return;
    }
    if (force) {
        series = series.filter(function (item) { return !item.isOverlay; });
    }
    else {
        series.map(function (seriesItem) {
            if (!seriesItem.isOverlay) {
                return;
            }
            if (overlayInstallationPointsIds.includes(+seriesItem.installationPointId)) {
                alreadyLoadedInstallationPointsIds.push(+seriesItem.installationPointId);
                if (!seriesItem.visible) {
                    seriesItem.visible = true;
                    seriesItem.showInLegend = true;
                    optionsChanged = true;
                    return;
                }
            }
            if (!overlayInstallationPointsIds.includes(+seriesItem.installationPointId) && seriesItem.visible) {
                optionsChanged = true;
                seriesItem.visible = false;
                seriesItem.showInLegend = false;
            }
        });
        if (optionsChanged) {
            dispatch({
                type: 'setState',
                state: {
                    options: __assign(__assign({}, options), { series: series }),
                },
            });
        }
    }
    fetchOverlay(state, dispatch, force
        ? overlayInstallationPointsIds
        : _difference(overlayInstallationPointsIds, alreadyLoadedInstallationPointsIds), countDays, chartIdentifier, series, selectedSystemType, readingTypes, measure, personalSettingMeasure, installationPoint);
};
var fetchOverlay = function (state, dispatch, overlayInstallationPointIds, countDays, chartIdentifier, series, selectedSystemType, readingTypes, measure, personalSettingMeasure, installationPoint) {
    if (!overlayInstallationPointIds.length) {
        return;
    }
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var options = state.options;
    var overlayLoader = _chunk(overlayInstallationPointIds, CHARTS_CHUNK).length;
    dispatch({
        type: 'setState',
        state: {
            overlayInstallationPointsLoader: overlayLoader,
        },
    });
    _chunk(overlayInstallationPointIds, CHARTS_CHUNK).map(function (installationPointIds) {
        Endpoints[selectedSystemType]
            .getRmsReadingsBatch(chartIdentifier, countDays, {
            query: {
                duplicated: true,
                installation_points_ids: installationPointIds,
                selected_facility_metric: METRIC_KEY[measure],
                timezone: timezone,
            },
        })
            .then(function (resp) {
            if (resp && resp.data.length) {
                resp.data.map(function (item) {
                    if (_size(item.readings) > 0) {
                        series.push({
                            id: "rms_".concat(chartIdentifier, "_").concat(item.installationPoint.id),
                            // @ts-ignore
                            installationPointId: item.installationPoint.id,
                            isOverlay: true,
                            data: item.readings,
                            name: item.installationPoint.name ||
                                "".concat(item.installationPoint.component, " - ").concat(item.installationPoint.point_name),
                            units: getUoms(chartIdentifier, measure, personalSettingMeasure),
                            chartType: chartIdentifier,
                            legendIndex: 1,
                            precision: readingTypes[chartIdentifier].precision,
                            color: _get(DEFAULT_CHARTS_CONFIG, ['colors', series.length], '#2f7ed8'),
                            sensorId: item.installationPoint.sensor_id,
                            visible: true,
                            showInLegend: true,
                            events: {
                                legendItemClick: function () {
                                    var overlayNames = this.chart.series
                                        // @ts-ignore
                                        .filter(function (item) { return item.hasRendered; })
                                        .map(function (item) { return item.name; })
                                        .join(', ');
                                    copyTextToClipboard(overlayNames, 'Name copied to clipboard');
                                },
                            },
                            dataGrouping: {
                                approximation: 'high',
                                forced: true,
                            },
                        });
                    }
                });
                overlayLoader = overlayLoader - 1;
                return dispatch({
                    type: 'setState',
                    state: {
                        options: __assign(__assign({}, options), { series: series }),
                        overlayInstallationPointsLoader: overlayLoader,
                    },
                });
            }
            else {
                overlayLoader = overlayLoader - 1;
                dispatch({
                    type: 'setState',
                    state: {
                        overlayInstallationPointsLoader: overlayLoader,
                    },
                });
            }
        })
            .catch(function () {
            overlayLoader = overlayLoader - 1;
            dispatch({
                type: 'setState',
                state: {
                    overlayInstallationPointsLoader: overlayLoader,
                },
            });
        });
    });
};
