import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var updateMissedAiFn = function (coreLink, data, options) {
    return Api.post("".concat(coreLink, "/ml-statistic/missed-ai/update/").concat(data.id), data, options);
};
export var useUpdateMissedAiMutation = function (_a) {
    var host = _a.host;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) {
            return updateMissedAiFn(getCoreUrl(selectedSystemType), data, { host: host });
        },
    });
};
