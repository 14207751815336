var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FaEdit } from "react-icons/fa";
import { Card, CardBody, CardHeader, Input, Label } from "reactstrap";
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from "../../../../constants";
import { get } from "../../../../storage/tableColumn";
import { config as baseConfig } from "../../../config/dashboard/aiWaiting/dataTableConfig";
import { SystemTypeContext } from "../../../context/SystemTypeContext";
import { fetch } from "../../../features/dashboard/aiWaiting";
import { reducer } from "../../../helper/reducer";
import { onSort } from "../../../helper/table";
import LinkWithPrefix from "../../LinkWithPrefix";
import DataTable from "../../shared/Table/Table";
var initialState = function (tableName, tableColumns, selectedSystemType) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, selectedSystemType);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
var AiWaiting = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, tableName = _a.tableName, tableColumns = _a.tableColumns, endpoint = _a.endpoint;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(tableName, tableColumns, selectedSystemType)), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var sort = config.sort;
    var _d = useState(true), hideHardwareAi = _d[0], setHideHardwareAi = _d[1];
    var callbackFetch = function () { return fetch(state, dispatch, function () { return endpoint(hideHardwareAi, sort); }); };
    useEffect(function () {
        callbackFetch();
    }, __spreadArray([hideHardwareAi], deps, true));
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", children: [_jsx(LinkWithPrefix, { to: "/action-list?state=".concat(ACTION_LIST.STATE_WAITING), children: _jsxs("div", { className: "float-start", children: ["Action Items - ", selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Waiting' : 'Question'] }) }), _jsxs(Label, { check: true, className: "float-end", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setHideHardwareAi(!hideHardwareAi); } }), _jsx("span", { children: "Hide network Ais" })] })] }), _jsx(CardBody, { className: "p-0", children: _jsx(Scrollbars, { style: { height: '100%' }, children: _jsx(DataTable, { config: config, tableName: tableName, dispatchTable: dispatch, onSort: function (column_name, ev) {
                            return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev);
                        } }) }) })] }));
};
export default AiWaiting;
