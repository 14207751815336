import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore, } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useSetInstallationPointStore = function (endpoint) {
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _a = InstallationPointStore(function (state) { return ({
        setStatesInstallationPointStore: state.setStates,
        installationPoint: state.installationPoint,
    }); }, shallow), setStatesInstallationPointStore = _a.setStatesInstallationPointStore, installationPoint = _a.installationPoint;
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    useEffect(function () {
        if (!installationPoint.id) {
            return;
        }
        endpoint(installationPoint.id).then(function (resp) {
            setStatesInstallationPointStore(resp);
            setStatesChartFeaturesStore({
                tachDataIsVisible: {},
            });
        });
    }, [installationPoint.id, measure, personalSettingMeasure]);
};
