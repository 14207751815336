import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
import { translateByKey } from '../../../features/shared';
export var config = function (visibleState) {
    return {
        columns: [
            {
                name: 'sensor_id',
                visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true });
                },
            },
            {
                name: 'description',
                visible: _has(visibleState, 'description') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.description : true,
                title: 'Description',
                sortable: false,
                component: function (row) {
                    var description = translateByKey(row, 'translates.en.description.text', row.description);
                    return _jsx(_Fragment, { children: description || '---' });
                },
            },
            {
                name: 'time_create',
                visible: _has(visibleState, 'time_create') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.time_create : true,
                title: 'Created at',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "watchlist_widget_".concat(row.id), dateTime: row.time_create, timeBreak: true }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(row.installation_point_id), rel: "noreferrer", target: "_blank", children: _jsx(Button, { color: "primary", size: "sm", children: "View" }) }));
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: true,
        hideTotalLabel: true,
    };
};
