import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import CustomersSelect from './components/CustomersSelect';
import LeadAnalystSelect from './components/LeadAnalystSelect';
import LocationsSelect from './components/LocationsSelect';
import './index.scss';
var SelectsGroup = function () {
    return (_jsxs("div", { className: "gap-3 selects-group", children: [_jsx(LeadAnalystSelect, {}), _jsx(LocationsSelect, {}), _jsx(CustomersSelect, {})] }));
};
export default memo(SelectsGroup);
