import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var equipmentsKey = function (locationIds) { return ['equipments', locationIds]; };
var equipmentsFn = function (_a) {
    var _b;
    var queryKey = _a.queryKey;
    return Api.get('all-analytics/location/equipments', {
        query: {
            selected_location_ids: (_b = queryKey[1]) !== null && _b !== void 0 ? _b : [],
        },
    });
};
export var useEquipmentsQuery = function (_a) {
    var locationIds = _a.locationIds;
    return useQuery({
        queryKey: equipmentsKey(locationIds),
        queryFn: equipmentsFn,
        select: function (_a) {
            var data = _a.data;
            return {
                equipments: data !== null && data !== void 0 ? data : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!locationIds,
    });
};
