import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
export var NormalizedButton = function (_a) {
    var dispatch = _a.dispatch, normalizedReadingsIsVisible = _a.normalizedReadingsIsVisible, width = _a.width, isDisabled = _a.isDisabled;
    var onNormalized = useCallback(function () {
        dispatch({
            type: 'setState',
            state: {
                normalizedReadingsIsVisible: !normalizedReadingsIsVisible,
            },
        });
    }, [normalizedReadingsIsVisible]);
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { className: "normalized-btn chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", onClick: onNormalized, style: { width: width }, disabled: isDisabled, size: "sm", children: normalizedReadingsIsVisible ? 'Hide Normalize Reading' : 'Show Normalize Readings' }) }));
};
