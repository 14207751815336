export var initialState = {
    config: {
        columns: [
            {
                name: 'notes',
                title: 'Notes',
                sortable: false,
            },
            {
                name: 'action',
                title: 'Action',
                sortable: false,
            },
            {
                name: 'user_name',
                title: 'User Name',
                sortable: false,
            },
            {
                name: 'created_at',
                title: 'Created At',
                sortable: false,
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        search: '',
        disabledSearch: true,
        data: [],
        loader: true,
    },
};
