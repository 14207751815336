import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
import { WSStatusLabel } from '../../../components/watchersStatistic/StatusLabel';
import { getUsdFormat } from '../../../features/watchersStatistic';
export var initialState = {
    defaultData: [],
    config: {
        columns: [
            {
                name: 'created_by',
                title: 'Created By',
                sortable: true,
            },
            {
                name: 'detected_by',
                title: 'Detected By',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'cost_avoided',
                title: 'Cost Avoided',
                sortable: true,
                component: function (row) {
                    return getUsdFormat(row.cost_avoided);
                },
            },
            {
                name: 'date_created',
                title: 'Date Created',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { dateTime: row.date_created });
                },
            },
            {
                name: 'date_updated',
                title: 'Date Updated',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { dateTime: row.date_updated });
                },
            },
            {
                name: 'status',
                title: 'Status',
                sortable: true,
                component: function (row) {
                    return _jsx(WSStatusLabel, { status: row.status });
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(React.Fragment, { children: _jsx("div", { className: "button-wrapper", children: _jsx(LinkWithPrefix, { target: "_blank", to: "/action-item/".concat(row.action_item_id), className: "btn btn-sm btn-primary", children: "View" }) }) }));
                },
            },
        ],
        sort: [
            {
                field: 'date_updated',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        disabledSearch: true,
        hideTotalLabel: true,
        loader: false,
    },
    filters: {
        sortField: 'date_created',
        isMoreThen25: true,
    },
};
