import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import { Badge } from 'reactstrap';
import { DateTimeLabel } from './common';
var OnDemandStatus = function (_a) {
    var _b;
    var requestId = _a.requestId, estimatedAt = _a.estimatedAt, readingFftOnDemand = _a.readingFftOnDemand;
    if (requestId === null) {
        return _jsx(Badge, { color: "secondary", children: "Legacy request" });
    }
    if (readingFftOnDemand === null) {
        if (moment().diff(moment.utc(estimatedAt).toDate(), 'hours') > 24) {
            return _jsx(Badge, { color: "danger", children: "Failed" });
        }
        return (_jsxs(_Fragment, { children: [_jsx(Badge, { color: "info", className: "me-2", children: "Pending" }), estimatedAt && (_jsx("b", { children: _jsxs("div", { children: ["Data should return by", ' ', _jsx(DateTimeLabel, { id: "on_demand_users_requests_status".concat(requestId + moment(estimatedAt).unix()), dateTime: estimatedAt })] }) }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { color: "success", className: "me-2", children: "Received" }), ((_b = readingFftOnDemand === null || readingFftOnDemand === void 0 ? void 0 : readingFftOnDemand.data) === null || _b === void 0 ? void 0 : _b.timestamp) ? (_jsx("b", { children: _jsxs("div", { children: ["Data returned at", ' ', _jsx(DateTimeLabel, { id: "on_demand_users_requests_status".concat(requestId + (readingFftOnDemand === null || readingFftOnDemand === void 0 ? void 0 : readingFftOnDemand.readingFFT_id)), dateTime: readingFftOnDemand.data.timestamp })] }) })) : (_jsx(_Fragment, {}))] }));
};
export default OnDemandStatus;
