var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { READING_TYPES } from '../../../constants';
import { RmsTooltipFormatter, positioner, } from '../../../shared/components/charts/chartsWrapper/components/shared/tooltip';
import { DEFAULT_CHARTS_CONFIG } from '../../../shared/config/charts/defaultChartsConfig';
import { getRmsAccVelExtraType } from '../../../shared/features/charts/shared';
import { getUoms, isCfChart, isPinChart } from '../../../shared/helper/chart';
export var getHidePeaksChartOptions = function (options, chartIdentifier, peak, measure, personalSettingMeasure, timezone) {
    var title = options.title, yAxis = options.yAxis, series = options.series;
    return __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { title: title, yAxis: [
            __assign(__assign({}, yAxis[0]), { title: {
                    text: "".concat(_get(READING_TYPES, [chartIdentifier, 'title'], ''), " ").concat(isCfChart(chartIdentifier) || isPinChart(chartIdentifier)
                        ? ''
                        : "(".concat(getUoms(chartIdentifier, measure, personalSettingMeasure), ") ").concat(getRmsAccVelExtraType(chartIdentifier))),
                }, plotLines: peak
                    ? [
                        {
                            className: 'alarm-plot-line-hide-peak',
                            value: peak,
                            zIndex: 4,
                        },
                    ]
                    : [] }),
        ], series: series.map(function (el, index) {
            if (index) {
                return el;
            }
            return __assign(__assign({}, el), { data: el.archiveData, zones: peak
                    ? [
                        {
                            value: +peak,
                            color: '#7cb5ec',
                        },
                        {
                            color: '#ff0000',
                        },
                    ]
                    : [] });
        }), tooltip: __assign(__assign({}, options.tooltip), { positioner: function (labelWidth, labelHeight, point) {
                return positioner(labelWidth, labelHeight, point, this.chart);
            }, formatter: function () {
                var points = this.points.filter(function (point) {
                    return point.series.options.id;
                });
                return ReactDOMServer.renderToStaticMarkup((_jsx(RmsTooltipFormatter, { points: points, allPoints: this.points, timezone: timezone !== null && timezone !== void 0 ? timezone : 'America/New_York', xValue: this.x, isVisibleLocker: false })));
            } }) });
};
export var onChangePeak = function (val, hidePeaksDispatch) {
    hidePeaksDispatch({
        type: 'setState',
        state: {
            peak: val === '' ? null : +val,
        },
    });
};
export var onCancel = function (dispatch, hidePeaksDispatch, hidePeaksOptions, setStates) {
    hidePeaksDispatch({
        type: 'setState',
        state: {
            peak: hidePeaksOptions.peak,
        },
    });
    setStates({
        hidePeaksModal: {
            visible: false,
            state: {},
            dispatch: function () { },
            chartIdentifier: null,
        },
    });
};
export var onSave = function (dispatch, peak, setStates) {
    setStates({
        hidePeaksModal: {
            visible: false,
            state: {},
            dispatch: function () { },
            chartIdentifier: null,
        },
    });
    dispatch({
        type: 'setState',
        state: {
            hidePeaksOptions: {
                peak: peak,
            },
        },
    });
};
