// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-chart-tools-resetZoom > svg > path {
  fill: none !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/topBar/ResetRmsZoom.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".btn-chart-tools-resetZoom > svg > path {\n  fill: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
