var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { size as _size } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { getPlotLines } from '../../../components/charts/chartsWrapper/features/rms/chartMarks';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectChartZoomDays } from '../../../store/charts/useChartSettingsStore';
export var useXPlotLines = function (state, dispatch, chartRef) {
    var mainSeriesLoaded = state.mainSeriesLoaded, readings = state.readings, options = state.options, chartMarks = state.chartMarks;
    var _a = ChartsStore(function (state) { return ({
        hideChartMarks: state.hideChartMarks,
        customZoomExtremes: state.customZoomExtremes,
    }); }, shallow), hideChartMarks = _a.hideChartMarks, customZoomExtremes = _a.customZoomExtremes;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var chartZoomDays = selectChartZoomDays();
    useEffect(function () {
        if (!mainSeriesLoaded && !_size(readings)) {
            return;
        }
        var plotLines = getPlotLines(installationPoint.last_watch || '', chartMarks, chartRef.current.chart.xAxis[0], chartZoomDays, hideChartMarks, customZoomExtremes);
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { xAxis: [
                        __assign(__assign({}, options.xAxis[0]), { plotLines: plotLines, initialPlotLines: plotLines }),
                    ] }),
            },
        });
    }, [hideChartMarks, options.series, customZoomExtremes]);
};
