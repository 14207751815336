var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var fetch = function (state, dispatch, endpoint) {
    var config = state.config;
    endpoint()
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false }),
                },
            });
        }
    })
        .catch(function () {
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { loader: false }),
            },
        });
    });
};
