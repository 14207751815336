export var fetchCountNotViewed = function (dispatch, endpoint) {
    endpoint().then(function (resp) {
        if (resp && resp.status === 'ok') {
            dispatch({
                type: 'setState',
                state: {
                    notViewedNews: resp.count,
                },
            });
        }
    });
};
