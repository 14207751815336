// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-item-row {
  transition: 0.2s;
  border-bottom: 1px solid #e2e5ea;
}
.dark-layout .active-item-row {
  border-bottom: 1px solid #2b2f3d;
}
.active-item-row:hover {
  border-radius: 5px;
  background: #e2e5ea;
}
.dark-layout .active-item-row:hover {
  background: #2b2f3d;
}
.active-item-row-show-report-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 0.5px solid rgba(0, 105, 217, 0.5019607843);
  transition: 0.3s;
}
.active-item-row-show-report-btn svg {
  transition: 0.2s;
  stroke: #0b84ff;
  width: 18px;
  height: 18px;
}
.active-item-row-show-report-btn:hover {
  background: #0069d9;
}
.active-item-row-show-report-btn:hover svg {
  stroke: #ffffff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/OnEquipment/components/ActionItemRow/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gCAAA;AACF;AAAE;EACE,gCAAA;AAEJ;AAAE;EACE,kBAAA;EACA,mBAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,mDAAA;EACA,gBAAA;AAEJ;AADI;EACE,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AAGN;AADI;EACE,mBAAA;AAGN;AAFM;EACE,eAAA;AAIR","sourcesContent":[".active-item-row {\n  transition: 0.2s;\n  border-bottom: 1px solid #e2e5ea;\n  .dark-layout & {\n    border-bottom: 1px solid #2b2f3d;\n  }\n  &:hover {\n    border-radius: 5px;\n    background: #e2e5ea;\n    .dark-layout & {\n      background: #2b2f3d;\n    }\n  }\n  &-show-report-btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n    cursor: pointer;\n    width: 30px;\n    height: 30px;\n    border: 0.5px solid #0069d980;\n    transition: 0.3s;\n    & svg {\n      transition: 0.2s;\n      stroke: #0b84ff;\n      width: 18px;\n      height: 18px;\n    }\n    &:hover {\n      background: #0069d9;\n      & svg {\n        stroke: #ffffff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
