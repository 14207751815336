import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useZoomingImg } from '../../../../../hooks/zoomingImg/useZoomingImg';
import HighlighterWord from '../../../../shared/HighlighterWord';
import ReportAttachments from '../ReportAttachments/ReportAttachments';
import './index.scss';
var WwReportBody = function (_a) {
    var activeReport = _a.activeReport, isVisibleReportBody = _a.isVisibleReportBody, actionListReportsSeverity = _a.actionListReportsSeverity;
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    useZoomingImg('.zoomImg-CompletionReport-WwReportBody img', [isVisibleReportBody]);
    return (_jsxs("div", { className: "ww-report-body", children: [activeReport.severity && actionListReportsSeverity[+activeReport.severity] ? (_jsxs(Row, { className: "report-row", children: [_jsx(Col, { md: 4, children: _jsx("span", { children: "Completion Report Severity:" }) }), _jsx(Col, { md: 8, children: actionListReportsSeverity[+activeReport.severity].name })] })) : (_jsx(_Fragment, {})), _jsxs(Row, { className: "report-row", children: [_jsx(Col, { md: 4, children: _jsx("span", { children: "Completion Report details:" }) }), _jsx(Col, { md: 8, children: _jsx(HighlighterWord, { searchWords: highlightSearch || '', textToHighlight: activeReport.text }) })] }), activeReport.file.length ? _jsx(ReportAttachments, { files: activeReport.file }) : _jsx(_Fragment, {}), activeReport.ww_comment ? (_jsxs(Row, { className: "report-row zoomImg-CompletionReport-WwReportBody", children: [_jsx(Col, { md: 4, children: _jsx("span", { children: "WW Analyst Closing Comments:" }) }), _jsx(Col, { md: 8, children: _jsx("div", { dangerouslySetInnerHTML: { __html: activeReport.ww_comment } }) })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(WwReportBody);
