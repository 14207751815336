import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { indexOf as _indexOf, last as _last } from 'lodash';
import React, { memo } from 'react';
import { FaFile } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { useZoomingImg } from '../../../../../hooks/zoomingImg/useZoomingImg';
var ReportAttachments = function (_a) {
    var _b = _a.files, files = _b === void 0 ? [] : _b;
    useZoomingImg('.zoomImg-CompletionReport-attachments img', [files]);
    return (_jsx(Row, { className: "report-row zoomImg-CompletionReport-attachments", children: _jsxs(Col, { children: [_jsx("div", { children: _jsx("span", { children: "Attachments:" }) }), files.map(function (file) { return (_jsx("span", { className: "me-2", children: file.name && _indexOf(['jpg', 'png', 'jpeg'], _last(file.name.split('.')).toLowerCase()) > -1 ? (_jsx("span", { className: "attachment-title", children: _jsx("img", { "data-attr": 'custom', className: "report-attachment-image", src: file.path, alt: file.name }) })) : (_jsxs("span", { children: [_jsx(FaFile, {}), _jsx("span", { className: "attachment-title", children: _jsx("a", { target: "_blank", rel: "noreferrer", href: file.path, children: file.name }) })] })) }, "file_".concat(file.path))); })] }) }));
};
export default memo(ReportAttachments);
