// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotkey-item {
  display: flex;
  align-items: center;
  margin: 6px 0;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
}
.hotkey-item:hover {
  background: rgba(33, 150, 243, 0.062745098);
}
.hotkey-item-keys {
  display: flex;
  align-items: center;
  color: #d37c12;
  gap: 3px;
  width: 200px;
}
.dark-layout .hotkey-item-keys {
  color: #b86703;
}
.hotkey-item-keys span {
  border-radius: 6px;
  padding: 4px 6px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  border: 1px solid #d37c12;
}
.dark-layout .hotkey-item-keys span {
  border: 1px solid #b86703;
}
.hotkey-item-description {
  font-size: 15px;
}
.hotkey-item-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
}
.hotkey-item-edit.active {
  color: #758aff;
}
.hotkey-item-edit.active:hover {
  color: #fff;
  background: #758aff;
}
.hotkey-item-edit.active.active {
  color: #fff;
  background: #758aff;
}
.hotkey-item-edit:hover {
  color: #fff;
  background: #758aff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/Hotkeys/components/HotkeyItem/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACF;AAAE;EACE,2CAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,QAAA;EACA,YAAA;AAEJ;AADI;EACE,cAAA;AAGN;AADI;EACE,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AAGN;AAFM;EACE,yBAAA;AAIR;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,uCACE;AACN;AACI;EACE,cAAA;AACN;AAAM;EACE,WAAA;EACA,mBAAA;AAER;AAAM;EACE,WAAA;EACA,mBAAA;AAER;AACI;EACE,WAAA;EACA,mBAAA;AACN","sourcesContent":[".hotkey-item {\n  display: flex;\n  align-items: center;\n  margin: 6px 0;\n  height: 40px;\n  padding: 10px;\n  border-radius: 5px;\n  &:hover {\n    background: #2196f310;\n  }\n  &-keys {\n    display: flex;\n    align-items: center;\n    color: #d37c12;\n    gap: 3px;\n    width: 200px;\n    .dark-layout & {\n      color: #b86703;\n    }\n    & span {\n      border-radius: 6px;\n      padding: 4px 6px;\n      text-transform: uppercase;\n      font-size: 14px;\n      font-weight: 700;\n      line-height: 14px;\n      border: 1px solid #d37c12;\n      .dark-layout & {\n        border: 1px solid #b86703;\n      }\n    }\n  }\n  &-description {\n    font-size: 15px;\n  }\n  &-edit {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 5px;\n    margin-left: auto;\n    border-radius: 5px;\n    cursor: pointer;\n    transition:\n      0.3s color,\n      0.3s background;\n    &.active {\n      color: #758aff;\n      &:hover {\n        color: #fff;\n        background: #758aff;\n      }\n      &.active {\n        color: #fff;\n        background: #758aff;\n      }\n    }\n    &:hover {\n      color: #fff;\n      background: #758aff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
