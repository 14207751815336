var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { get as _get, size as _size } from "lodash";
import React, { useReducer, useRef } from "react";
import { Badge } from "reactstrap";
import { shallow } from "zustand/shallow";
import FullscreenChartWrapper from "../../../../modals/fullscreenChartWrapper/FullscreenChartWrapper";
import { isTwfChart } from "../../../helper/chart";
import { reducer } from "../../../helper/reducer";
import { useFftTwfHooks } from "../../../hooks/charts/initHooks/useFftTwfImpactVueHooks";
import { ChartsStore } from "../../../store/charts/chartsContent/ChartsStore";
import { FftImpactVueChartsStore } from "../../../store/charts/chartsContent/FftImpactVueChartsStore";
import { InstallationPointStore } from "../../../store/charts/chartsContent/InstallationPointStore";
import { selectMeasure, selectPersonalSettingMeasure, } from "../../../store/global/useGlobalStore";
import Loader from "../../Loader";
import { FftContextMenu } from "./components/fftTwf/ContextMenu";
import FftXAxisDropdown from "./components/fftTwf/FftAxisFormatter";
import ChartToolsButtonsWrapper from "./components/fftTwfImpactVue/ChartToolsButtonsWrapper";
import { config as baseConfig } from "./config/fftTwfDefaultConfig";
var initialState = function (chartIdentifier, chartCursorType, installationPoint, readingTypes, currentSpeed, chartView, measure, personalSettingMeasure) {
    var config = baseConfig(chartIdentifier, chartCursorType, installationPoint, readingTypes, currentSpeed, chartView, measure, personalSettingMeasure);
    return __assign(__assign({}, config), { isVisibleFaultFrequencyIds: [], isVisibleFaultFrequenciesLabels: false, isCircleTwfType: false });
};
var FftTwfChartImpactVue = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    var _b = ChartsStore(function (state) { return ({
        chartCursorType: state.chartCursorType,
        chartTooltipsToggle: state.chartTooltipsToggle,
        chartView: state.chartView,
    }); }, shallow), chartCursorType = _b.chartCursorType, chartTooltipsToggle = _b.chartTooltipsToggle, chartView = _b.chartView;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
        currentSpeed: state.currentSpeed,
    }); }, shallow), installationPoint = _c.installationPoint, readingTypes = _c.readingTypes, currentSpeed = _c.currentSpeed;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var sensor = _get(installationPoint, 'sensor') || {};
    var isSensorExists = _size(sensor) > 0;
    var _d = useReducer(reducer, initialState(chartIdentifier, chartCursorType, installationPoint, readingTypes, currentSpeed, chartView, measure, personalSettingMeasure)), state = _d[0], dispatch = _d[1];
    var options = state.options, units = state.units, customCursorPointsCount = state.customCursorPointsCount, isVisibleFullscreenChart = state.isVisibleFullscreenChart, isDataAvailable = state.isDataAvailable, isCircleTwfType = state.isCircleTwfType;
    var _e = FftImpactVueChartsStore(function (state) { return ({
        loader: state.loader,
        data: state.data,
    }); }, shallow), loader = _e.loader, data = _e.data;
    var chartRef = useRef(null);
    var chartContainerRef = useRef(null);
    useFftTwfHooks(state, dispatch, chartIdentifier, chartRef, chartContainerRef);
    return (_jsx(FullscreenChartWrapper, { chartView: chartView, visible: isVisibleFullscreenChart, typeChart: 'fft', chartIdentifier: chartIdentifier, children: _jsxs("div", { ref: chartContainerRef, id: "chart-".concat(chartIdentifier), className: "chart-item-".concat(chartIdentifier, " chart-item ").concat(isTwfChart(chartIdentifier) ? 'chart-twf' : 'chart-fft', " fft ").concat(chartTooltipsToggle ? 'hidden-tooltips' : ''), "data-chart-identifier": chartIdentifier, "data-chart-type": isTwfChart(chartIdentifier) ? 'twf' : 'fft', children: [loader ? (_jsx("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        zIndex: 10000,
                        backdropFilter: 'blur(2px)',
                        width: '100%',
                        height: '100%',
                    }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), !loader && isDataAvailable ? (_jsx(ChartToolsButtonsWrapper, { state: state, dispatch: dispatch, chartRef: chartRef, chartIdentifier: chartIdentifier })) : (_jsx(_Fragment, {})), _jsxs(FftContextMenu, { chartIdentifier: chartIdentifier, chartRef: chartRef, children: [+_get(data, ['meta', chartIdentifier, 'is_outlier'], 0) === 1 ? (_jsx(Badge, { className: "outlier-chart-label", color: "warning", children: "Outlier" })) : (_jsx(_Fragment, {})), _jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: options })] }), isSensorExists ? (_jsx(FftXAxisDropdown, { customCursorPointsCount: customCursorPointsCount, isVisibleFullscreenChart: isVisibleFullscreenChart, chartIdentifier: chartIdentifier, units: units, dispatch: dispatch, currentSpeed: currentSpeed, isCircleTwfType: isCircleTwfType })) : (_jsx(_Fragment, {}))] }) }));
};
export default React.memo(FftTwfChartImpactVue);
