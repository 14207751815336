import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DateTimeLabel } from '../../../../../widgets/common';
export var config = function (onViewActionCallback, setOpenPhotosModal) {
    return {
        columns: [
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
            },
            {
                name: 'hex_code',
                title: 'Hex Code',
                sortable: true,
            },
            {
                name: 'version_type',
                title: 'Version',
                sortable: true,
            },
            {
                name: 'last_seen',
                title: 'Last Seen',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "last_seen_".concat(row.id), dateTime: row.last_seen, timeBreak: true });
                },
            },
            {
                name: 'name',
                title: 'Name',
                sortable: true,
            },
            {
                name: 'actions',
                title: '',
                sortable: false,
                component: function (item) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { size: "sm", onClick: function () { return onViewActionCallback(item); }, color: "success", children: "View" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", onClick: function () { return setOpenPhotosModal(item.sensor_id); }, color: "info", children: "Images" }) })] }));
                },
            },
        ],
        search: '',
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        data: [],
        loader: true,
    };
};
