var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { size as _size } from 'lodash';
import { useContext } from 'react';
import Api from '../../../api/api';
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectMeasure } from '../../store/global/useGlobalStore';
export var installationPointForActionItemKey = function (id, type) { return [
    'installationPointForActionItem',
    +id,
    type,
]; };
var installationPointForActionItemFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/action-item/get-info-by-installation-point/").concat(queryKey[1]), {
        query: {},
    });
};
export var useInstallationPointForActionItemQuery = function (_a) {
    var itemId = _a.itemId, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b;
    var measure = selectMeasure();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: installationPointForActionItemKey(itemId, isPreAi ? 'PreAi' : 'Ai'),
        queryFn: function (data) { return installationPointForActionItemFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var _b, _c;
            var data = _a.data, actionItemCharts = _a.actionItemCharts, activePreAiResp = _a.activePreAi, tags = _a.tags, photos = _a.photos;
            var additionalFields = selectedSystemType === SYSTEM_TYPE_REMASTERED
                ? {
                    customerId: (data === null || data === void 0 ? void 0 : data.customer_id) || null,
                    storage: data === null || data === void 0 ? void 0 : data.equipment.location.facility.customer.storage,
                    customer: (data === null || data === void 0 ? void 0 : data.customer) || null,
                }
                : {
                    woNumber: (data === null || data === void 0 ? void 0 : data.woNumber) || null,
                };
            return __assign({ installationPointName: (data === null || data === void 0 ? void 0 : data.name) || null, installationPointIdsOnEquipment: (data === null || data === void 0 ? void 0 : data.installationPointIdsOnEquipment) || [], sensorId: data.sensor_id || null, equipmentType: ACTION_LIST.EQUIPMENT_TYPE_SENSOR, installationPoint: data, actionListCharts: actionItemCharts || [], existingActionItems: ((_b = data === null || data === void 0 ? void 0 : data.equipment) === null || _b === void 0 ? void 0 : _b.existingActionItems) || [], activePreAi: _size(activePreAiResp) ? activePreAiResp : null, installationPointId: (data === null || data === void 0 ? void 0 : data.id) || null, tags: tags, activeRegularActionList: (data === null || data === void 0 ? void 0 : data.activeRegularActionList) || null, activeActionList: (data === null || data === void 0 ? void 0 : data.activeActionList) || null, facility: (data === null || data === void 0 ? void 0 : data.facility) || null, facilityMeasure: ((_c = data === null || data === void 0 ? void 0 : data.facility) === null || _c === void 0 ? void 0 : _c.measurement_units) || measure, photos: photos }, additionalFields);
        },
        enabled: !!itemId,
    });
};
