import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useState } from 'react';
import { FaCheckCircle, FaEdit, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button } from 'reactstrap';
import { ACTION_LIST, SYSTEM_TYPES_DATA } from '../../../../constants';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { makeValidLink } from '../../../helper/routeHelper';
import User from '../../../helper/user';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import { useZoomingImg } from '../../../hooks/zoomingImg/useZoomingImg';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
import DecliningForm from '../DecliningForm/index';
import FormDescription from '../FormDescription';
var DEFAULT_DIRECTION = 0;
var Description = function (_a) {
    var description = _a.description, isShowEdit = _a.isShowEdit, id = _a.id, title = _a.title, installationPoint = _a.installationPoint, urgency = _a.urgency, installationPointId = _a.installationPointId, equipmentType = _a.equipmentType, activeActionList = _a.activeActionList, serial = _a.serial, isReplaceEquipment = _a.isReplaceEquipment, replacementType = _a.replacementType, defaultDeclineReasons = _a.defaultDeclineReasons, editIsLoading = _a.editIsLoading, createIsLoading = _a.createIsLoading, approveIsLoading = _a.approveIsLoading, replaceMoteBatteryIsLoading = _a.replaceMoteBatteryIsLoading, replaceNodeBatteriesIsLoading = _a.replaceNodeBatteriesIsLoading, replaceSensorMoteIsLoading = _a.replaceSensorMoteIsLoading, replaceSensorIsLoading = _a.replaceSensorIsLoading, editMutate = _a.editMutate, createMutate = _a.createMutate, approveMutate = _a.approveMutate, replaceMoteBatteryMutate = _a.replaceMoteBatteryMutate, replaceNodeBatteriesMutate = _a.replaceNodeBatteriesMutate, replaceSensorMoteMutate = _a.replaceSensorMoteMutate, replaceSensorMutate = _a.replaceSensorMutate, declinePreActionItemMutate = _a.declinePreActionItemMutate, existingActionItems = _a.existingActionItems, declineReason = _a.declineReason, preActionListTags = _a.preActionListTags, tags = _a.tags, _b = _a.storage, storage = _b === void 0 ? null : _b, localStorageKey = _a.localStorageKey;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var navigate = useNavigate();
    var _c = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _c.isContentVisible, storageStateMemory = _c.storageStateMemory, setIsContentVisible = _c.setIsContentVisible, onSaveAsDefaultSize = _c.onSaveAsDefaultSize;
    var _d = useState(false), isEditState = _d[0], setIsEditState = _d[1];
    var _e = useState(false), isDeclining = _e[0], setIsDeclining = _e[1];
    useZoomingImg('.zoomImg-preAction img', [isContentVisible]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onApprove = function (activeInstallationPointId) {
        var callback = function () {
            if (approveIsLoading ||
                replaceMoteBatteryIsLoading ||
                replaceNodeBatteriesIsLoading ||
                replaceSensorMoteIsLoading ||
                replaceSensorIsLoading) {
                return;
            }
            setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to <b>approve</b> pre-action item?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    if (isReplaceEquipment) {
                        if (replacementType === 'battery') {
                            if (equipmentType === 'mote') {
                                return replaceMoteBatteryMutate({
                                    inst_point_id: activeInstallationPointId,
                                    serial: serial,
                                    equipment_type: ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                                }, {
                                    onSuccess: function () {
                                        toast.success('Action item created successfully!', {
                                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                        });
                                    },
                                });
                            }
                            return replaceNodeBatteriesMutate({
                                inst_point_id: activeInstallationPointId,
                                serial: serial,
                                user_id: User.getId(),
                                equipment_type: ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                            }, {
                                onSuccess: function () {
                                    toast.success('Action item created successfully!', {
                                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                    });
                                },
                            });
                        }
                        if (equipmentType === 'mote') {
                            return replaceSensorMoteMutate({
                                inst_point_id: activeInstallationPointId,
                                equipment_type: ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                            }, {
                                onSuccess: function () {
                                    toast.success('Action item created successfully!', {
                                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                    });
                                },
                            });
                        }
                        return replaceSensorMutate({
                            inst_point_id: activeInstallationPointId,
                            user_id: User.getId(),
                            equipment_type: ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                        }, {
                            onSuccess: function () {
                                toast.success('Action item created successfully!', {
                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                });
                            },
                        });
                    }
                    if (activeActionList &&
                        activeActionList.id &&
                        activeActionList.type === ACTION_LIST.AI_TYPE_REGULAR) {
                        return approveMutate(id, {
                            onSuccess: function () {
                                toast.success('Successfully approved', {
                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                });
                            },
                        });
                    }
                    return navigate({
                        pathname: makeValidLink("/".concat(prefix, "/action-item/create/").concat(+activeInstallationPointId)),
                    });
                },
            });
        };
        callback();
    };
    return (_jsxs("div", { className: "aiPreAi-description ai-on-equipment default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsxs("div", { className: "default-ai-block-header-title d-flex justify-content-between w-100", children: ["Pre-Action Item description:", isShowEdit && !isDeclining && isContentVisible ? (_jsx("div", { children: _jsx(Button, { title: "Edit", color: "primary", size: "sm", className: "me-2", onClick: function () { return setIsEditState(function (prev) { return !prev; }); }, children: isEditState ? _jsx(FaTimes, {}) : _jsx(FaEdit, {}) }) })) : (_jsx(_Fragment, {}))] }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), isContentVisible ? (_jsxs("div", { className: "default-ai-block-content zoomImg-preAction", children: [isShowEdit && !isEditState && !isDeclining ? (_jsxs("div", { className: "aiPreAi-description-buttons gap-2", children: [_jsx("div", { className: "btn-shared btn-decline", onClick: function () { return setIsDeclining(function (prev) { return !prev; }); }, children: "Decline" }), _jsx("div", { className: "btn-shared btn-approve", onClick: function () {
                                    return onApprove(installationPointId);
                                }, children: "Approve" })] })) : (_jsx(_Fragment, {})), isShowEdit && !isEditState && isDeclining ? (_jsx("div", { className: "mb-3 decline-form-wrapper", children: _jsx(DecliningForm, { defaultDeclineReasons: defaultDeclineReasons, declinePreActionItemMutate: declinePreActionItemMutate, onCancel: function () { return setIsDeclining(false); }, existingActionItems: existingActionItems.filter(function (actionItem) {
                                var inArchiveState = [ACTION_LIST.STATE_ARCHIVE, ACTION_LIST.STATE_CLOSED].includes(+actionItem.state);
                                var isWwAi = actionItem.type === ACTION_LIST.AI_TYPE_WW;
                                return !inArchiveState && !isWwAi;
                            }) }) })) : (_jsx(_Fragment, {})), declineReason ? (_jsxs("div", { className: "my-2", children: [_jsx("span", { className: "fw-bold", children: "Decline reason:" }), " ", declineReason] })) : (_jsx(_Fragment, {})), _jsx("div", { className: "aiPreAi-description-text", children: !isEditState ? (_jsxs(_Fragment, { children: [preActionListTags ? (_jsx("div", { className: "d-flex flex-wrap gap-2 mb-2", children: preActionListTags.map(function (preActionListTag) { return (_jsx(Badge, { color: "primary", children: _get(preActionListTag, 'preActionListTag.name') }, "tag_".concat(preActionListTag.pre_action_list_tag_id, "_").concat(preActionListTag.pre_action_list_id))); }) })) : (_jsx(_Fragment, {})), _jsx("div", { dangerouslySetInnerHTML: { __html: description || '' } })] })) : (_jsx(_Fragment, {})) }), isShowEdit ? (_jsx(_Fragment, { children: isEditState ? (_jsx(FormDescription, { id: id, title: title, description: description, installationPoint: installationPoint, urgency: urgency, editIsLoading: editIsLoading, createIsLoading: createIsLoading, editMutate: editMutate, createMutate: createMutate, storage: storage, tags: tags, preActionListTags: preActionListTags })) : (_jsx(_Fragment, {})) })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Description);
