import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import SensorLocationBlock from '../SensorLocationBlock';
import './index.scss';
var ImageDropLayout = function (_a) {
    var imageRef = _a.imageRef, positions = _a.positions, flipTurns = _a.flipTurns, index = _a.index, setIndex = _a.setIndex, id = _a.id, setPositions = _a.setPositions;
    var equipmentPhotos = InstallationPointStore(function (state) { return state.equipmentPhotos; });
    var _b = useDrop({
        accept: 'SensorLocationBlock',
        drop: function (item, monitor) { return ({ name: item.name, index: index, monitor: monitor.getClientOffset() }); },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }); },
    }), _c = _b[0], canDrop = _c.canDrop, isOver = _c.isOver, drop = _b[1];
    var isActive = canDrop && isOver;
    var opacity = '1';
    if (isActive) {
        opacity = '0.9';
    }
    else if (canDrop) {
        opacity = '1';
    }
    useEffect(function () {
        var _a, _b;
        var $drop = $('.assign-photo-drop');
        var $dropTooltip = $('.img-tooltip-wrapper');
        var $dropTooltipText = $('.img-text-number');
        $drop.height(((_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.height) || '100%');
        $drop.width(((_b = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _b === void 0 ? void 0 : _b.width) || '100%');
        if (Math.abs((flipTurns[id] || 0) % 0.5) === 0.25) {
            $dropTooltip.css({ transform: 'inherit' });
            var flipText = '' + flipTurns[id];
            if (flipText.split('.', 2)[1] === '25') {
                $dropTooltipText.css({ transform: 'rotate(90deg)' });
            }
            else {
                $dropTooltipText.css({ transform: 'rotate(-90deg)' });
            }
            $drop.addClass('d-inline-block');
        }
        else {
            $dropTooltip.css({ transform: 'initial' });
            $drop.removeClass('d-inline-block');
        }
    }, [imageRef.current, index, flipTurns, positions]);
    return (_jsx("div", { style: { opacity: opacity }, className: "assign-photo-main-wrapper", children: _jsx("div", { className: "assign-photo-main-block", children: _jsx("div", { className: "assign-photo-item", children: _jsxs("div", { className: "assign-photo position-relative", children: [_jsx(Lightbox, { index: index, on: { view: function (_a) {
                                    var index = _a.index;
                                    return setIndex(index);
                                } }, carousel: { finite: true }, styles: {
                                container: { backgroundColor: 'rgb(241, 241, 241)' },
                                icon: { color: '#fff', backgroundColor: '#6c757d', padding: 5 },
                                button: { zIndex: 999 },
                            }, inline: { style: { width: '100%', aspectRatio: '3 / 2' } }, counter: { container: { style: { top: 0, color: '#6c757d', filter: 'unset' } } }, slides: equipmentPhotos.map(function (photo, indexSlide) { return ({ src: photo.url, indexSlide: indexSlide }); }), render: {
                                slide: function (_a) {
                                    var slide = _a.slide;
                                    return slide && index === slide.indexSlide ? (_jsx("img", { style: {
                                            display: 'block',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            transform: "rotate(".concat(flipTurns[id] || 0, "turn)"),
                                        }, ref: imageRef, src: slide.src, alt: slide.src })) : (_jsx(_Fragment, {}));
                                },
                            }, plugins: [Inline, Fullscreen, Counter] }), _jsx("div", { className: "assign-photo-drop", ref: drop, style: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }, children: Object.keys(positions[index] || {}).map(function (pointIndex) { return (_jsx(SensorLocationBlock, { imageId: id, setIndex: setIndex, index: index, pointIndex: +pointIndex, id: id, column: "left", positions: positions, setPositions: setPositions, imageRef: imageRef }, "point-position-".concat(index, "-").concat(pointIndex))); }) })] }) }) }) }));
};
export default ImageDropLayout;
