import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { DateInput } from '../../../../widgets/common';
import { CALENDAR_CURRENT_BUTTONS, CALENDAR_LAST_BUTTONS } from '../../../config/watchersStatistic';
import './index.scss';
var Calendar = function (_a) {
    var dateRange = _a.dateRange, setDateRange = _a.setDateRange;
    return (_jsx("div", { className: "custom-calendar", children: _jsx(DatePicker, { selected: dateRange[0], onChange: function (newRange) { return setDateRange(newRange); }, monthsShown: 2, startDate: dateRange[0], endDate: dateRange[1], maxDate: new Date(), selectsRange: true, fixedHeight: true, calendarStartDay: 1, customInput: _jsx(DateInput, {}), children: _jsxs("div", { className: "custom-calendar-additional-content", children: [_jsxs("div", { className: "custom-calendar-additional-content-last", children: [_jsx("div", { children: "Last:" }), _jsx("span", { className: "gap-2", children: CALENDAR_LAST_BUTTONS(dateRange).map(function (el) { return (_jsx("div", { className: "calendar-period ".concat(el.isActive ? 'calendar-period-active' : ''), onClick: function () { return setDateRange(el.value); }, children: el.label }, el.label)); }) })] }), _jsxs("div", { className: "custom-calendar-additional-content-current", children: [_jsx("div", { children: "Current:" }), _jsx("span", { className: "gap-2", children: CALENDAR_CURRENT_BUTTONS(dateRange).map(function (el) { return (_jsx("div", { className: "calendar-period ".concat(el.isActive ? 'calendar-period-active' : ''), onClick: function () { return setDateRange(el.value); }, children: el.label }, el.label)); }) })] })] }) }) }));
};
export default memo(Calendar);
