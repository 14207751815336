var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var statisticUsersListKey = function () { return ['statisticUsersList']; };
var statisticUsersListFn = function (coreLink) {
    return Api.get("".concat(coreLink, "/statistic/users-for-filter"));
};
export var useStatisticUsersListQuery = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticUsersListKey(),
        queryFn: function () { return statisticUsersListFn(getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var data = _a.data;
            return __spreadArray([
                {
                    id: 0,
                    username: 'All Users',
                }
            ], data, true);
        },
    });
};
