import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { reportEditorOptionsKey } from './useReportEditorOptionsQuery';
var reportEditorOptionDeleteFn = function (coreLink, id, entityName) {
    return Api.post("".concat(coreLink, "/report-editor/delete-item/").concat(id), { entityName: entityName });
};
export var useReportEditorOptionDeleteMutation = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var id = _a.id, entityName = _a.entityName;
            return reportEditorOptionDeleteFn(getCoreUrl(selectedSystemType), id, entityName);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: reportEditorOptionsKey() });
        },
    });
};
