import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, has as _has } from 'lodash';
import React, { memo, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
var Row = function (_a) {
    var index = _a.index, columns = _a.columns, item = _a.item, textAlignment = _a.textAlignment, callbacks = _a.callbacks, config = _a.config, tableName = _a.tableName, additionContent = _a.additionContent, getIsShowAdditionContent = _a.getIsShowAdditionContent;
    var _b = useState(false), isShowAddition = _b[0], setIsShowAddition = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("tr", { className: _has(item, 'is_selected') && +item.is_selected === 1
                    ? 'active-row'
                    : _has(item, 'is_startup') && +item.is_startup === 1
                        ? 'startup-row'
                        : '' + _get(item, 'className', ''), style: { backgroundColor: _get(item, 'bg_color', 'inherit') }, children: [additionContent && getIsShowAdditionContent(item) ? (_jsx("td", { className: "text-center cursor-pointer", onClick: function () { return setIsShowAddition(function (prev) { return !prev; }); }, children: isShowAddition ? _jsx(IoIosArrowUp, {}) : _jsx(IoIosArrowDown, {}) })) : (_jsx(_Fragment, {})), columns.map(function (column) { return (_jsx("td", { "data-label": column.title, style: _get(column, 'style', {}), className: "".concat(column.hiddenDefaultStyles ? '' : "card-row_title ".concat(textAlignment), " ").concat(_get(column, 'className', ''), " ").concat(_has(column, 'visible') && !column.visible ? 'hidden' : ''), children: column.name === 'table_menu'
                            ? ''
                            : _has(column, 'component')
                                ? column.component(item, callbacks, config.filtersData)
                                : _get(item, column.name, '---') || '---' }, "".concat(tableName, "-").concat(column.name, "-").concat(index))); })] }, "tr-".concat(index)), additionContent && getIsShowAdditionContent(item) && isShowAddition ? (_jsx("tr", { children: _jsx("td", { colSpan: 100, children: additionContent(item, callbacks, config.filtersData) }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Row);
