import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaTimes } from 'react-icons/fa';
import { RiSortAsc } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import Header from '../../../../shared/components/preActionItem/create/Header';
import { useInstallationPointForActionItemQuery } from '../../../../shared/services/actionItems/useInstallationPointForActionItemQuery';
import { selectIsEditSections, usePreActionItemCreateActions, } from '../../../../shared/store/preActionItem/usePreActionItemCreateStore';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/preActionItem/create/Content';
var PreAiCreatePage = function () {
    var itemId = useParams().id;
    useTitle('Pre-Action Item');
    var navigate = useNavigate();
    var _a = useInstallationPointForActionItemQuery({ itemId: itemId, isPreAi: true }), installationPointForActionItemData = _a.data, isLoading = _a.isLoading;
    if (installationPointForActionItemData === null || installationPointForActionItemData === void 0 ? void 0 : installationPointForActionItemData.activeRegularActionList) {
        toast.error('An active action item already exists for this installation point!', {
            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
        });
        return navigate('/amazon/action-item/' + installationPointForActionItemData.activeRegularActionList.id);
    }
    if ((installationPointForActionItemData === null || installationPointForActionItemData === void 0 ? void 0 : installationPointForActionItemData.activePreAi) &&
        !(installationPointForActionItemData === null || installationPointForActionItemData === void 0 ? void 0 : installationPointForActionItemData.activeRegularActionList)) {
        toast.error('Pre-Action Item exist for this sensor!', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    }
    var isEditSections = selectIsEditSections();
    var setIsEditSections = usePreActionItemCreateActions().setIsEditSections;
    return (_jsx("div", { className: "page-preActionItem", children: isLoading ? (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 146px)',
            }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs("div", { children: [_jsx(Header, { installationPointForActionItemData: installationPointForActionItemData }), _jsx(Content, {}), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) })] })) }));
};
export default memo(PreAiCreatePage);
