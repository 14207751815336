import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Filters from '../../components/alertLog/Filter/Filter';
export var config = {
    columns: [],
    sort: [
        {
            field: 'date_create',
            direction: 'desc',
        },
    ],
    pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
    },
    filters: function (callbacks, filtersData) {
        return _jsx(Filters, { filtersData: filtersData, callbacks: callbacks });
    },
    filtersData: {
        user_name: '',
        startDate: null,
        endDate: null,
        userNames: [],
    },
    search: '',
    data: [],
    loader: true,
};
