import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { Button } from 'reactstrap';
import { usePreActionItemModalsActions } from '../../../store/AIPreAi/usePreActionItemModalsStore';
import DefaultTooltip from '../../DefaultTooltip';
var RateMlDiagnosisButton = function (_a) {
    var activeMlReport = _a.activeMlReport;
    var setMlReportModal = usePreActionItemModalsActions().setMlReportModal;
    if (!(activeMlReport === null || activeMlReport === void 0 ? void 0 : activeMlReport.id)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: true, color: "primary", "data-tooltip-id": "rate-to-ml-btn", className: "top-actions-fill", onClick: function () { return setMlReportModal({ visible: true, activeMlReport: activeMlReport, callback: function () { } }); }, children: _jsx(FaTriangleExclamation, {}) }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "rate-to-ml-btn", children: "Rate ML Diagnosis" })] }));
};
export default memo(RateMlDiagnosisButton);
