import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { selectIsVisibleCommentForMlTeamModal } from '../../store/AIPreAi/useActionItemModalsStore';
var CommentForMlTeamModal = function (_a) {
    var onCancel = _a.onCancel, addMlCommentMutate = _a.addMlCommentMutate, addMlCommentIsLoading = _a.addMlCommentIsLoading, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b;
    var id = useParams().id;
    var _c = useState(''), mlComment = _c[0], setMlComment = _c[1];
    var isVisibleCommentForMlTeamModal = selectIsVisibleCommentForMlTeamModal();
    var onConfirm = function () {
        addMlCommentMutate(isPreAi
            ? {
                pre_action_list_id: +id,
                comment: mlComment,
            }
            : {
                action_list_id: +id,
                ml_comment: mlComment,
            }, {
            onSuccess: function () {
                toast.success('Comment successfully added!', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                onCancel();
            },
        });
    };
    return (_jsxs(Modal, { size: "md", className: "modal-response", toggle: onCancel, isOpen: isVisibleCommentForMlTeamModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Comment for ML Team" }), _jsx(ModalBody, { children: _jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "ml_comment", children: "Comment for ML Team:" }), _jsx(Input, { className: "input-cancel", id: "ml_comment", bsSize: 'lg', label: "ml_comment", type: "textarea", required: true, onChange: function (ev) { return setMlComment(ev.currentTarget.value); } })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", disabled: addMlCommentIsLoading, color: "primary", onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(CommentForMlTeamModal);
