import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React, { memo, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { RiSortAsc } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import Header from '../../../../shared/components/actionItem/create/Header';
import { useInstallationPointForActionItemQuery } from '../../../../shared/services/actionItems/useInstallationPointForActionItemQuery';
import { selectIsEditSections, useActionItemCreateActions, } from '../../../../shared/store/actionItem/useActionItemCreateStore';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/actionItem/create/Content';
var ActionItemCreatePage = function () {
    var itemId = useParams().id;
    var navigate = useNavigate();
    useTitle('Action Item');
    var _a = useInstallationPointForActionItemQuery({ itemId: itemId }), installationPointForActionItemData = _a.data, isLoading = _a.isLoading;
    useEffect(function () {
        if (!installationPointForActionItemData) {
            return;
        }
        var activeRegularActionList = installationPointForActionItemData.activeRegularActionList, activeActionList = installationPointForActionItemData.activeActionList, activePreAi = installationPointForActionItemData.activePreAi;
        if (_size(activeRegularActionList)) {
            toast.error('An active action item already exists for this installation point!', {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
            return navigate('/amazon/action-item/' + (activeRegularActionList === null || activeRegularActionList === void 0 ? void 0 : activeRegularActionList.id));
        }
        if (_size(activePreAi) && !_size(activeActionList)) {
            toast.error('Pre-Action Item exist for this sensor!', {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
        }
    }, [installationPointForActionItemData]);
    var isEditSections = selectIsEditSections();
    var setIsEditSections = useActionItemCreateActions().setIsEditSections;
    return (_jsx("div", { className: "page-actionItem", children: isLoading ? (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 146px)',
            }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs("div", { children: [_jsx(Header, { installationPointForActionItemData: installationPointForActionItemData }), _jsx(Content, {}), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) })] })) }));
};
export default memo(ActionItemCreatePage);
