var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PreAiCreatePage from '../page/preActionItem/Create';
import { PreActionItemsList } from '../page/preActionItem/List';
import PreActionItemViewPage from '../page/preActionItem/View';
export var preActionList = function (props) {
    return [
        {
            path: '/pre-action-list',
            element: _jsx(PreActionItemsList, __assign({}, props)),
        },
        {
            path: '/pre-action-list/:id',
            element: _jsx(PreActionItemViewPage, __assign({}, props)),
        },
        {
            path: '/pre-action-list/create/:id',
            element: _jsx(PreAiCreatePage, __assign({}, props)),
        },
    ];
};
