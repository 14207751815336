import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import DatePicker from "react-datepicker";
import { Input } from "reactstrap";
import { DateInput } from "../../../widgets/common";
export var Filters = function (_a) {
    var filtersData = _a.filtersData, onUserChange = _a.onUserChange, children = _a.children, onChangeDateRange = _a.onChangeDateRange;
    var users = filtersData.users;
    return (_jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }), _jsxs(Input, { type: "select", name: "select", className: "select-sm w-auto me-2", onChange: function (ev) { return onUserChange(ev.target.value); }, children: [_jsx("option", { value: "", children: "All users" }), (users === null || users === void 0 ? void 0 : users.length) ? (users.map(function (user) { return (_jsx("option", { value: user.user_id, children: user.username }, "users-on-demand-filter-".concat(user.user_id))); })) : (_jsx(_Fragment, {}))] }), children ? children : _jsx(_Fragment, {})] }));
};
