import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var bandsDeleteFn = function (coreLink, data, installationPointId) {
    return Api.post("".concat(coreLink, "/alert-conditions/delete/").concat(installationPointId), data);
};
export var useFftAlertLevelDeleteMutation = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) { return bandsDeleteFn(getCoreUrl(selectedSystemType), data, installationPointId); },
        onSuccess: function () { },
    });
};
