import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { get as _get, has as _has } from 'lodash';
import React from 'react';
export var config = function (visibleState) {
    return {
        columns: [
            {
                name: 'username',
                visible: _has(visibleState, 'username') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.username : true,
                title: 'User Name',
                sortable: false,
            },
            {
                name: 'no_action_required',
                visible: _has(visibleState, 'no_action_required') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.no_action_required : true,
                title: 'AI',
                sortable: false,
                component: function (row) {
                    return (_jsxs("span", { children: [_get(row, 'created_ai', 0) || 0, "/", _get(row, 'no_action_required', 0) || 0] }));
                },
            },
            {
                name: 'hardware',
                visible: _has(visibleState, 'hardware') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.hardware : true,
                title: 'WWAI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'hardware', 0) || 0 });
                },
            },
            {
                name: 'processing_ai',
                visible: _has(visibleState, 'processing_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.processing_ai : true,
                title: 'Report',
                sortable: false,
                component: function (row) {
                    return (_jsxs("span", { children: [_get(row, 'processing_ai', 0) || 0, "/", _get(row, 'closed_ai', 0) || 0] }));
                },
            },
            {
                name: 'declined_reports',
                visible: _has(visibleState, 'declined_reports') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.declined_reports : true,
                title: 'Rejected',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'declined_reports', 0) || 0 });
                },
            },
            {
                name: 'ai_comments',
                visible: _has(visibleState, 'ai_comments') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.ai_comments : true,
                title: 'Comments',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'ai_comments', 0) || 0 });
                },
            },
            {
                name: 'raw_pre_ai',
                visible: _has(visibleState, 'raw_pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.raw_pre_ai : true,
                title: 'Raw pre-ai',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'raw_pre_ai', 0) || 0 });
                },
            },
            {
                name: 'created_pre_ai',
                visible: _has(visibleState, 'created_pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.created_pre_ai : true,
                title: 'Pre-AI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'created_pre_ai', 0) || 0 });
                },
            },
            {
                name: 'ai_from_own_pre_ai',
                visible: _has(visibleState, 'ai_from_own_pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.ai_from_own_pre_ai : true,
                title: 'Own Pre-AI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'ai_from_own_pre_ai', 0) || 0 });
                },
            },
            {
                name: 'accepted_pre_ai',
                visible: _has(visibleState, 'accepted_pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.accepted_pre_ai : true,
                title: 'Accepted',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'accepted_pre_ai', 0) || 0 });
                },
            },
            {
                name: 'declined_pre_ai',
                visible: _has(visibleState, 'declined_pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.declined_pre_ai : true,
                title: 'Declined',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'declined_pre_ai', 0) || 0 });
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: false,
        hideTotalLabel: true,
    };
};
