import { createStore } from '../../../store/createStore';
var useExistingAiPreAiOnBedStore = createStore(function (set) { return ({
    isVisible: false,
    installationPointId: null,
    equipmentId: null,
    actions: {
        setIsVisible: function (isVisible) { return set({ isVisible: isVisible }); },
        setInstallationPointId: function (installationPointId) { return set({ installationPointId: installationPointId }); },
        setEquipmentId: function (equipmentId) { return set({ equipmentId: equipmentId }); },
    },
}); }, 'ExistingAiPreAiOnBedStore');
export var useExistingAiPreAiActions = function () {
    return useExistingAiPreAiOnBedStore(function (state) { return state.actions; });
};
export var selectIsVisibleModal = function () {
    return useExistingAiPreAiOnBedStore(function (state) { return state.isVisible; });
};
export var selectInstallationPointId = function () {
    return useExistingAiPreAiOnBedStore(function (state) { return state.installationPointId; });
};
export var selectEquipmentId = function () {
    return useExistingAiPreAiOnBedStore(function (state) { return state.equipmentId; });
};
