import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useTitle } from '../../../customHooks';
import DataTable from '../../../shared/components/shared/Table/Table';
import User from '../../../shared/helper/user';
import ReportEditorFormModal from '../../../shared/modals/reportEditor/ReportEditorFormModal';
import { useReportEditorListQuery, } from '../../../shared/services/reportEditor/useReportEditorListQuery';
import { useReportEditorOptionsQuery, } from '../../../shared/services/reportEditor/useReportEditorOptionsQuery';
import { selectPage, selectPageSize, selectSearch, selectSort, useReportEditorTableActions, } from '../../../shared/store/reportEditor/useReportEditorTableStore';
var ReportEditorPage = function () {
    var navigate = useNavigate();
    useTitle('Report Editor');
    var _a = useReportEditorListQuery(), data = _a.data, isPending = _a.isPending;
    var reportEditorData = useReportEditorOptionsQuery().data;
    var _b = data || {}, list = _b.list, total = _b.total;
    var _c = useReportEditorTableActions(), setPage = _c.setPage, setSearch = _c.setSearch, setPageSize = _c.setPageSize;
    var search = selectSearch();
    var page = selectPage();
    var pageSize = selectPageSize();
    var sort = selectSort();
    var _d = useState({
        visible: false,
    }), reportEditorModalState = _d[0], setReportEditorModalState = _d[1];
    if (!User.can('manageReportEditor')) {
        navigate('/401');
    }
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("h3", { className: "page-title", children: ["Report Editor", _jsxs(Button, { className: "float-end", onClick: function () {
                                    return setReportEditorModalState({
                                        visible: true,
                                        componentType: undefined,
                                        subType: undefined,
                                        faults: [],
                                        recommendations: [],
                                        isNew: true,
                                    });
                                }, color: "success", size: "sm", children: [_jsx(FaPlus, {}), " Add Fault"] })] }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx(DataTable, { config: {
                            columns: [
                                {
                                    name: 'componentType',
                                    title: '1st Level Selection - Component Type',
                                    sortable: false,
                                },
                                {
                                    name: 'subType',
                                    title: 'Sub Type',
                                    sortable: false,
                                },
                                {
                                    name: 'faults',
                                    title: '2nd Level Selection - Fault Name',
                                    sortable: false,
                                    component: function (row) {
                                        var _a;
                                        return (_a = row === null || row === void 0 ? void 0 : row.faults) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ');
                                    },
                                },
                                {
                                    name: 'faults',
                                    title: '2nd Level Recommendations',
                                    sortable: false,
                                    component: function (row) {
                                        var _a;
                                        return (_a = row === null || row === void 0 ? void 0 : row.recommendations) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.name; }).join(', ');
                                    },
                                },
                                {
                                    name: 'actions',
                                    sortable: false,
                                    component: function (row) {
                                        return (_jsx(Button, { onClick: function () {
                                                var _a, _b, _c, _d;
                                                return setReportEditorModalState({
                                                    visible: true,
                                                    componentType: ((_a = row.faults[0]) === null || _a === void 0 ? void 0 : _a.componentType) ||
                                                        ((_b = row.recommendations[0]) === null || _b === void 0 ? void 0 : _b.componentType) || {
                                                        id: row.componentTypeId,
                                                        name: row.componentType,
                                                    },
                                                    subType: ((_c = row.faults[0]) === null || _c === void 0 ? void 0 : _c.subType) ||
                                                        ((_d = row.recommendations[0]) === null || _d === void 0 ? void 0 : _d.subType) || { id: row.subTypeId, name: row.subType },
                                                    faults: row.faults,
                                                    recommendations: row.recommendations,
                                                    isNew: false,
                                                });
                                            }, color: "primary", size: "sm", children: _jsx(FaPencil, {}) }));
                                    },
                                    style: { verticalAlign: 'middle' },
                                },
                            ],
                            pagination: {
                                page: page,
                                pageSize: pageSize,
                                total: total || 0,
                            },
                            sort: sort,
                            search: search,
                            data: list || [],
                            loader: isPending,
                        }, onPageChange: function (page) { return setPage(page); }, onPageSizeChange: function (pageSize) { return setPageSize(pageSize); }, onSearch: function (search) { return setSearch(search); } }) }) }), reportEditorModalState.visible ? (_jsx(ReportEditorFormModal, { reportEditorData: reportEditorData, reportEditorModalState: reportEditorModalState, onCancel: function () { return setReportEditorModalState({ visible: false }); } })) : (_jsx(_Fragment, {}))] }));
};
export default memo(ReportEditorPage);
