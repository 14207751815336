import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var bearingMfrsKey = function (installationPointId) { return ['bearingMfrs', installationPointId]; };
var bearingMfrsFn = function (coreLink, installationPointId) {
    return Api.get("".concat(coreLink, "/bearing/mfrs"), { query: { installation_point_id: installationPointId } });
};
export var useBearingMfrsQuery = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: bearingMfrsKey(installationPointId),
        queryFn: function () { return bearingMfrsFn(getCoreUrl(selectedSystemType), installationPointId); },
        select: function (_a) {
            var list = _a.list;
            return {
                list: list,
            };
        },
    });
};
