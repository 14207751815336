var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { head as _head } from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { useTitle } from '../../../../customHooks';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/alerts/counts/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { onFilterChange, onFilterLevelChange, onRefreshClick, } from '../../../../shared/features/alerts/counts';
import { exportToCsv, onViewInstallationPoint } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint);
    return {
        activeFilters: {
            hide_ai: 1,
            hide_bs: 1,
            hide_on_bed_ai: 1,
            in_alarm: 0,
            level: '',
        },
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility_name',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                    component: function (row) {
                        return (_jsxs(_Fragment, { children: [_jsx("p", { id: 'id-' + row.id, children: row.location_name }), _jsx(UncontrolledTooltip, { target: 'id-' + row.id, children: row.equipment_name })] }));
                    },
                },
                {
                    name: 'inst_point_name',
                    title: 'Point name',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations) {
    var config = state.config, activeFilters = state.activeFilters;
    Endpoints.remastered
        .getAlertCountsList({
        query: __assign({ selected_customer_ids: selectedCustomers, selected_location_id: _head(selectedLocations), pagination: config.pagination, sort: config.sort, search: config.search }, activeFilters),
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: {
                            activeFilters: activeFilters,
                            isRefreshInProgress: resp.is_refresh_in_progress,
                            lastRefreshRequest: resp.last_refresh_request,
                        } }),
                },
            });
        }
    });
};
var AlertCountsPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Alarm Counts');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _c[0], dispatch = _c[1];
    var config = state.config, activeFilters = state.activeFilters;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var callbackFetch = function () { return fetch(state, dispatch, selectedCustomers, selectedLocations); };
    useResetDataTableEffect([selectedCustomers, selectedLocations], state, dispatch);
    useEffect(function () {
        callbackFetch();
    }, [refreshFlag, selectedCustomers, selectedLocations, activeFilters]);
    return (_jsx(React.Fragment, { children: _jsx(Row, { className: "align-pagination-section move-pagination-top", children: _jsxs(Col, { children: [_jsxs("h3", { className: "page-title", children: ["Percentage of readings in alarm", config.data.length > 0 ? (_jsx(Button, { color: "success", className: "float-end", style: { margin: '4px 75px' }, size: "sm", onClick: function () {
                                    return exportToCsv(Endpoints.remastered.getAlertCountsListCsv, 'alarm-counts', {
                                        query: __assign({ selected_customer_ids: selectedCustomers, selected_location_id: _head(selectedLocations), sort: config.sort, search: config.search }, activeFilters),
                                        isBlob: true,
                                    });
                                }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }), _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, onPageSizeChange: function (pageSize) {
                            return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                        }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, callbacks: {
                            onFilterChange: function (filter) { return onFilterChange(state, dispatch, filter); },
                            onFilterLevelChange: function (level) { return onFilterLevelChange(state, dispatch, level); },
                            onRefreshClick: function () { return onRefreshClick(state, dispatch, Endpoints.remastered.refreshAlertCounts); },
                        }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); } })] }) }) }));
};
export default AlertCountsPage;
