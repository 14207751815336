// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-watchList .removedList tr {
  background: rgba(222, 55, 70, 0.8) !important;
}
.page-watchList .removedList tr .dark-layout {
  background: rgba(206, 78, 92, 0.5333333333) !important;
}
.page-watchList .removedList tr td {
  color: #f1f1f1 !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/watchList/watchList.scss"],"names":[],"mappings":"AAEI;EACE,6CAAA;AADN;AAEM;EACE,sDAAA;AAAR;AAEM;EACE,yBAAA;AAAR","sourcesContent":[".page-watchList {\n  & .removedList {\n    & tr {\n      background: #de3746cc !important;\n      .dark-layout {\n        background: #ce4e5c88 !important;\n      }\n      & td {\n        color: #f1f1f1 !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
