import Api from "../../api";
export default {
    getAlertConditionLog: function (installationPointId, readingTypeId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/alert-condition/get-log/".concat(installationPointId, "/").concat(readingTypeId), options);
    },
    getAlertConditionComments: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alert-condition/get-comments', options);
    },
    getAlertConditionCommentsBatch: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/alert-condition/get-comments-batch/".concat(installationPointId), options);
    },
    deleteAlertConditionComment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/alert-condition/delete-comment/".concat(id), options);
    },
    saveAlertConditions: function (installationPointId, readingTypeId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("amazon-analytic/alert-condition/save/".concat(installationPointId, "/").concat(readingTypeId), data);
    },
    saveAlertConditionsComment: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-condition/save-comment', data);
    },
    createFftAlertCondition: function (installationPointId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("amazon-analytic/alert-conditions/create-fft/".concat(installationPointId), data);
    },
    updateFftAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-conditions/update-fft', data);
    },
    deleteAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-conditions/delete', data);
    },
};
