import { createStore } from '../createStore';
var useActionItemModalsStore = createStore(function (set) { return ({
    dateRange: [new Date(new Date().setDate(1)), new Date()],
    actions: {
        setDateRange: function (dateRange) { return set({ dateRange: dateRange }); },
    },
}); });
export var useActionItemModalsActions = function () {
    return useActionItemModalsStore(function (state) { return state.actions; });
};
export var selectDateRange = function () {
    return useActionItemModalsStore(function (state) { return state.dateRange; });
};
