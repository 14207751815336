import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useSetXAxisExtremes = function (chartRef) {
    var _a = ChartsStore(function (state) { return ({
        isZoom: state.isZoom,
        customZoomExtremes: state.customZoomExtremes,
        setStatesChartStore: state.setStates,
    }); }, shallow), isZoom = _a.isZoom, customZoomExtremes = _a.customZoomExtremes, setStatesChartStore = _a.setStatesChartStore;
    useEffect(function () {
        if (!customZoomExtremes) {
            return;
        }
        chartRef.current.chart.xAxis[0].setExtremes(customZoomExtremes.min, customZoomExtremes.max);
        if (isZoom) {
            setStatesChartStore({
                isCustomZoom: true,
            });
        }
    }, [customZoomExtremes]);
};
