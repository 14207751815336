import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaEdit } from 'react-icons/fa';
import './index.scss';
var HotkeyItem = function (_a) {
    var description = _a.description, hotkey = _a.hotkey, _b = _a.itemKey, itemKey = _b === void 0 ? null : _b, editItem = _a.editItem, setEditItem = _a.setEditItem;
    var handler = function () {
        if (!setEditItem) {
            return;
        }
        setEditItem(editItem === itemKey ? null : itemKey);
    };
    return (_jsxs("div", { className: "hotkey-item", children: [_jsx("div", { className: "hotkey-item-keys", children: hotkey.names.map(function (key, index) { return (_jsxs(_Fragment, { children: [_jsx("span", { children: key }, key), index === hotkey.names.length - 1 ? '' : ' + '] })); }) }), _jsx("div", { className: "hotkey-item-description", children: description }), setEditItem && (!editItem || editItem === itemKey) ? (_jsx(_Fragment, { children: _jsx("div", { className: "hotkey-item-edit ".concat(editItem === itemKey ? 'active' : ''), onClick: handler, children: _jsx(FaEdit, {}) }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(HotkeyItem);
