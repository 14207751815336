// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sensorIntervals .header-block {
  background: #fff;
  border: 1px solid #d9d9d9;
}
.dark-layout .page-sensorIntervals .header-block {
  background: #252a38;
  border: 1px solid #3e3f4f;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/page/sensorIntervals/index.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,yBAAA;AAAJ;AACI;EACE,mBAAA;EACA,yBAAA;AACN","sourcesContent":[".page-sensorIntervals {\n  & .header-block {\n    background: #fff;\n    border: 1px solid #d9d9d9;\n    .dark-layout & {\n      background: #252a38;\n      border: 1px solid #3e3f4f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
