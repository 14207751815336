import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '../../../../../store/charts/chartsContent/ChartFeaturesStore';
var InterpolationToggle = function () {
    var _a = ChartFeaturesStore(function (state) { return ({
        interpolationDisabled: state.interpolationDisabled,
        setInterpolationState: state.setInterpolationState,
    }); }, shallow), interpolationDisabled = _a.interpolationDisabled, setInterpolationState = _a.setInterpolationState;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return setInterpolationState(!interpolationDisabled); }, outline: Boolean(interpolationDisabled), color: "customChartTopBarSelect", className: "btn-chart-tools position-relative impact_vue", size: "sm", style: { height: '30px' }, id: "interpolation_state", children: "Interpolation" }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "interpolation_state", children: interpolationDisabled ? 'On Interpolation' : 'Off Interpolation' })] }));
};
export default React.memo(InterpolationToggle);
