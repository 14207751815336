var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { saveAs } from 'file-saver';
import { get as _get } from 'lodash';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_REMASTERED } from '../../../constants';
export var onViewInstallationPoint = function (installationPointId, selectedSystemType, params) {
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    window.open("/".concat(prefix, "/dashboard?installation_point=").concat(installationPointId).concat(params || '').replaceAll('//', '/'), '_blank');
};
export var onViewActionItem = function (actionItemId, selectedSystemType, advancedSearch) {
    if (advancedSearch === void 0) { advancedSearch = ''; }
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    window.open("/".concat(prefix, "/action-item/").concat(actionItemId).concat(advancedSearch ? "?search=".concat(advancedSearch) : '').replaceAll('//', '/'), '_blank');
};
export var onViewPreActionItem = function (perActionItemId, selectedSystemType, params) {
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    window.open("/".concat(prefix, "/pre-action-list/").concat(perActionItemId).concat(params || '').replaceAll('//', '/'), '_blank');
};
export var exportToCsv = function (endpoint, filename, options) {
    endpoint(options).then(function (resp) {
        if (resp) {
            saveAs(new Blob([resp], { type: 'text/plain;charset=utf-8' }), "".concat(filename, ".csv"));
        }
    });
};
export var onViewImages = function (setImagesModalData, images) {
    setImagesModalData({
        visible: true,
        images: images,
    });
};
export var onUserChange = function (dispatch, selectedActiveUserId) {
    dispatch({
        type: 'setState',
        state: {
            activeUserId: selectedActiveUserId,
        },
    });
};
export var setLoader = function (dispatch, value) {
    dispatch({
        type: 'setState',
        state: {
            loader: value,
        },
    });
};
export var translateByKey = function (data, path, origin) {
    return _get(data, path, origin);
};
export var onChangeDateRangeFilter = function (state, dispatch, dateRange) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { dateRange: dateRange }) }),
        },
    });
};
export var getTimezone = function (installationPoint, selectedSystemType) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return selectedSystemType === SYSTEM_TYPE_REMASTERED
        ? (_h = (_f = (_d = (_c = (_b = (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.locationTable) === null || _b === void 0 ? void 0 : _b.facility) === null || _c === void 0 ? void 0 : _c.facility_timezone) !== null && _d !== void 0 ? _d : (_e = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer) === null || _e === void 0 ? void 0 : _e.customer_timezone) !== null && _f !== void 0 ? _f : (_g = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _g === void 0 ? void 0 : _g.default_timezone) !== null && _h !== void 0 ? _h : null
        : (_m = (_k = (_j = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.facilityTable) === null || _j === void 0 ? void 0 : _j.facility_timezone) !== null && _k !== void 0 ? _k : (_l = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.facilityTable) === null || _l === void 0 ? void 0 : _l.default_timezone) !== null && _m !== void 0 ? _m : null;
};
