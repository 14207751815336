var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useMemo, useState } from 'react';
import { FaCheck, FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Label, Row } from 'reactstrap';
import { REPORT_EDITOR } from '../../../../constants';
import { BAD_DATA_COMPONENT_TYPE_ID, BAD_DATA_RECOMMENDATION_ID, COMPONENT_TYPE_BELT_AND_CHAINS, COMPONENT_TYPE_COUPLING, } from '../../../../remastered/constants';
import { formatOptionLabel } from '../../../features/reportEditor';
import User from '../../../helper/user';
import { useReportEditorOptionCreateMutation } from '../../../services/reportEditor/useReportEditorOptionCreateMutation';
import { useReportEditorOptionDeleteMutation } from '../../../services/reportEditor/useReportEditorOptionDeleteMutation';
import { useActionItemModalsActions } from '../../../store/AIPreAi/useActionItemModalsStore';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
var TITLE_MAPPING = {
    componentType: 'Component Type',
    subType: 'Sub Type',
    fault: 'Fault',
    recommendations: 'Recommendation',
};
var ReportEditorForm = function (_a) {
    var _b = _a.isEditForm, isEditForm = _b === void 0 ? false : _b, reportEditorData = _a.reportEditorData, isPending = _a.isPending, selectedReportEditorData = _a.selectedReportEditorData, setValue = _a.setValue, unregister = _a.unregister, index = _a.index, firstSelectedComponentTypeId = _a.firstSelectedComponentTypeId, countFaultRecommendations = _a.countFaultRecommendations, _c = _a.isEdit, isEdit = _c === void 0 ? false : _c, _d = _a.disableResetByFirstComponent, disableResetByFirstComponent = _d === void 0 ? false : _d, _e = _a.isReassign, isReassign = _e === void 0 ? false : _e;
    var setReportEditorEntityEditState = useActionItemModalsActions().setReportEditorEntityEditState;
    var reportEditorOptionDeleteMutation = useReportEditorOptionDeleteMutation().mutate;
    var reportEditorOptionCreateMutation = useReportEditorOptionCreateMutation().mutate;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _f = reportEditorData || {
        componentTypes: [],
        subTypes: [],
        faults: [],
        recommendations: [],
    }, componentTypes = _f.componentTypes, subTypes = _f.subTypes, faults = _f.faults, recommendations = _f.recommendations;
    var getSubTypesOptions = function (componentType) { return __spreadArray([
        { label: 'All', value: null }
    ], subTypes
        .filter(function (subType) { return subType.component_type_id === componentType || subType.component_type_id === null; })
        .map(function (subType) { return ({
        label: subType.name,
        value: subType.id,
    }); }), true); };
    var getFaultsOptions = function (componentType, subType) {
        return faults
            .filter(function (fault) { return fault.component_type_id === componentType || fault.component_type_id === null; })
            .filter(function (fault) { return !subType || fault.sub_type_id === subType || fault.sub_type_id === null; })
            .map(function (fault) { return ({
            label: fault.name,
            value: fault.id,
        }); });
    };
    var getRecommendationsOptions = function (componentType, subType) {
        return recommendations
            .filter(function (recommendation) {
            return recommendation.component_type_id === componentType || recommendation.component_type_id === null;
        })
            .filter(function (recommendation) {
            return !subType || recommendation.sub_type_id === subType || recommendation.sub_type_id === null;
        })
            .map(function (recommendation) { return ({
            label: recommendation.name,
            value: recommendation.id,
        }); });
    };
    var _g = useState([]), subTypeOptions = _g[0], setSubTypeOptions = _g[1];
    var _h = useState(getFaultsOptions(selectedReportEditorData.componentType, selectedReportEditorData.subType)), faultsOptions = _h[0], setFaultsOptions = _h[1];
    var _j = useState(getRecommendationsOptions(selectedReportEditorData.componentType, selectedReportEditorData.subType)), recommendationsOptions = _j[0], setRecommendationsOptions = _j[1];
    var componentTypeOptions = useMemo(function () {
        if (index === 0 && !isEdit) {
            return componentTypes
                .map(function (componentType) { return ({
                label: componentType.name,
                value: componentType.id,
            }); })
                .filter(function (item) { return (isReassign ? true : +item.value !== BAD_DATA_COMPONENT_TYPE_ID); });
        }
        if (firstSelectedComponentTypeId) {
            return componentTypes
                .filter(function (componentType) {
                return [firstSelectedComponentTypeId, COMPONENT_TYPE_COUPLING, COMPONENT_TYPE_BELT_AND_CHAINS].includes(componentType.id);
            })
                .map(function (componentType) { return ({
                label: componentType.name,
                value: componentType.id,
            }); })
                .filter(function (item) { return (isReassign ? true : +item.value !== BAD_DATA_COMPONENT_TYPE_ID); });
        }
        return [];
    }, [componentTypes, firstSelectedComponentTypeId]);
    useEffect(function () {
        if (disableResetByFirstComponent || index === 0 || isPending) {
            return;
        }
        setValue("faultRecommendations.".concat(index), {
            componentType: null,
            subType: null,
            fault: null,
            recommendations: [],
        });
    }, [firstSelectedComponentTypeId]);
    useEffect(function () {
        setSubTypeOptions(getSubTypesOptions(selectedReportEditorData.componentType));
    }, [subTypes]);
    useEffect(function () {
        setFaultsOptions(getFaultsOptions(selectedReportEditorData.componentType, selectedReportEditorData.subType));
    }, [faults]);
    useEffect(function () {
        setRecommendationsOptions(getRecommendationsOptions(selectedReportEditorData.componentType, selectedReportEditorData.subType));
    }, [recommendations]);
    var afterComponentTypeChange = function (componentType) {
        setValue("faultRecommendations.".concat(index), {
            componentType: componentType,
            subType: null,
            fault: null,
            recommendations: componentType && +componentType === BAD_DATA_COMPONENT_TYPE_ID ? [BAD_DATA_RECOMMENDATION_ID] : [],
        });
        setSubTypeOptions(getSubTypesOptions(componentType));
        setFaultsOptions(getFaultsOptions(componentType, selectedReportEditorData.subType));
        setRecommendationsOptions(getRecommendationsOptions(componentType, selectedReportEditorData.subType));
    };
    var onComponentTypeChange = function (item, meta) {
        if (meta.action === 'create-option') {
            reportEditorOptionCreateMutation({ componentType: null, subType: null, name: item === null || item === void 0 ? void 0 : item.label, entityName: 'componentType' }, {
                onSuccess: function (resp) {
                    if (resp.success) {
                        toast.success('Successfully created', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                        afterComponentTypeChange(resp.entity.id);
                    }
                },
            });
            return;
        }
        afterComponentTypeChange((item === null || item === void 0 ? void 0 : item.value) || null);
    };
    var afterSubTypeChange = function (subType) {
        setValue("faultRecommendations.".concat(index), {
            componentType: selectedReportEditorData.componentType,
            subType: subType,
            fault: null,
            recommendations: [],
        });
        setFaultsOptions(getFaultsOptions(selectedReportEditorData.componentType, subType));
        setRecommendationsOptions(getRecommendationsOptions(selectedReportEditorData.componentType, subType));
    };
    var onSubTypeChange = function (item, meta) {
        if (meta.action === 'create-option') {
            reportEditorOptionCreateMutation({
                componentType: selectedReportEditorData.componentType,
                subType: null,
                name: item === null || item === void 0 ? void 0 : item.label,
                entityName: 'subType',
            }, {
                onSuccess: function (resp) {
                    var _a;
                    if (resp.success) {
                        toast.success('Successfully created', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                        afterSubTypeChange(+((_a = resp.entity) === null || _a === void 0 ? void 0 : _a.id) || null);
                    }
                },
            });
            return;
        }
        afterSubTypeChange((item === null || item === void 0 ? void 0 : item.value) || null);
    };
    var afterFaultChange = function (fault) {
        setValue("faultRecommendations.".concat(index, ".fault"), fault);
        setValue("faultRecommendations.".concat(index, ".recommendations"), []);
    };
    var onFaultChange = function (item, meta) {
        if (meta.action === 'create-option') {
            reportEditorOptionCreateMutation({
                componentType: selectedReportEditorData.componentType,
                subType: selectedReportEditorData.subType,
                name: item === null || item === void 0 ? void 0 : item.label,
                entityName: 'fault',
            }, {
                onSuccess: function (resp) {
                    var _a;
                    if (resp.success) {
                        toast.success('Successfully created', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                        afterFaultChange(+((_a = resp.entity) === null || _a === void 0 ? void 0 : _a.id) || null);
                    }
                },
            });
            return;
        }
        afterFaultChange((item === null || item === void 0 ? void 0 : item.value) || null);
    };
    var onRecommendationsChange = function (items, meta) {
        var _a;
        if (meta.action === 'create-option') {
            reportEditorOptionCreateMutation({
                componentType: selectedReportEditorData.componentType,
                subType: selectedReportEditorData.subType,
                name: (_a = meta.option) === null || _a === void 0 ? void 0 : _a.label,
                entityName: 'recommendations',
            }, {
                onSuccess: function (resp) {
                    var _a;
                    if (resp.success) {
                        toast.success('Successfully created', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                        setValue("faultRecommendations.".concat(index, ".recommendations"), __spreadArray(__spreadArray([], (items ? items.filter(function (item) { return !item.__isNew__; }).map(function (item) { return item.value; }) : []), true), [
                            +((_a = resp.entity) === null || _a === void 0 ? void 0 : _a.id),
                        ], false));
                    }
                },
            });
            return;
        }
        setValue("faultRecommendations.".concat(index, ".recommendations"), items ? items.map(function (item) { return item.value; }) : []);
    };
    var onEdit = function (id, name, entityName) {
        setReportEditorEntityEditState({ visible: true, id: id, name: name, entityName: entityName });
    };
    var onDelete = function (id, entityName) {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: "Are you sure you want to delete this ".concat(TITLE_MAPPING[entityName], "?"),
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                reportEditorOptionDeleteMutation({ id: id, entityName: entityName }, {
                    onSuccess: function (resp) {
                        if (resp.success) {
                            toast.success('Successfully removed', { icon: _jsx(FaTrash, { style: { fill: '#198754' } }) });
                        }
                    },
                });
            },
        });
    };
    return (_jsxs(Row, { children: [_jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Component Type" }), _jsx(CreatableSelect, { isClearable: true, isDisabled: isPending, placeholder: "Select Component Type", classNamePrefix: "customSelectStyleDefault", isValidNewOption: function (value) { return value.length > 0 && User.can('manageReportEditor'); }, formatOptionLabel: function (item, options) {
                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'componentType'); }, function () { return onDelete(item.value, 'componentType'); });
                            }, onChange: onComponentTypeChange, value: selectedReportEditorData.componentType
                                ? componentTypeOptions.find(function (componentTypeOption) {
                                    return componentTypeOption.value === selectedReportEditorData.componentType;
                                })
                                : null, options: componentTypeOptions })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Sub Type" }), _jsx(CreatableSelect, { isClearable: true, isDisabled: isPending ||
                                !selectedReportEditorData.componentType ||
                                +selectedReportEditorData.componentType === BAD_DATA_COMPONENT_TYPE_ID, placeholder: "Select Sub Type", classNamePrefix: "customSelectStyleDefault", isValidNewOption: function (value) { return value.length > 0 && User.can('manageReportEditor'); }, formatOptionLabel: function (item, options) {
                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'subType'); }, function () { return onDelete(item.value, 'subType'); });
                            }, onChange: onSubTypeChange, value: subTypeOptions.find(function (subTypeOption) { return subTypeOption.value === selectedReportEditorData.subType; }), options: subTypeOptions })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Fault Name" }), _jsx(CreatableSelect, { isClearable: true, isDisabled: isPending ||
                                !selectedReportEditorData.componentType ||
                                +selectedReportEditorData.componentType === BAD_DATA_COMPONENT_TYPE_ID, placeholder: "Select Fault Name", classNamePrefix: "customSelectStyleDefault", isValidNewOption: function (value) { return value.length > 0 && User.can('manageReportEditor'); }, formatOptionLabel: function (item, options) {
                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'fault'); }, function () { return onDelete(item.value, 'fault'); });
                            }, onChange: onFaultChange, value: selectedReportEditorData.fault
                                ? faultsOptions.find(function (faultOption) { return faultOption.value === selectedReportEditorData.fault; })
                                : null, options: faultsOptions })] }) }), _jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Recommendations" }), _jsx(CreatableSelect, { isClearable: true, isMulti: true, isDisabled: isPending ||
                                !selectedReportEditorData.componentType ||
                                +selectedReportEditorData.componentType === BAD_DATA_COMPONENT_TYPE_ID, placeholder: "Select Recommendations", classNamePrefix: "customSelectStyleDefault", isValidNewOption: function (value) { return value.length > 0 && User.can('manageReportEditor'); }, formatOptionLabel: function (item, options) {
                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'recommendations'); }, function () { return onDelete(item.value, 'recommendations'); });
                            }, onChange: onRecommendationsChange, value: selectedReportEditorData.recommendations &&
                                selectedReportEditorData.recommendations.length > 0 &&
                                selectedReportEditorData.componentType !== BAD_DATA_COMPONENT_TYPE_ID
                                ? recommendationsOptions.filter(function (recommendationsOption) {
                                    return recommendationsOption.value &&
                                        selectedReportEditorData.recommendations.includes(recommendationsOption.value);
                                })
                                : null, options: recommendationsOptions })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsxs(Label, { children: ["Fault's", " Severity"] }), _jsx(Select, { isClearable: !isEditForm, isDisabled: isEditForm
                                ? false
                                : isPending ||
                                    !selectedReportEditorData.componentType ||
                                    +selectedReportEditorData.componentType === BAD_DATA_COMPONENT_TYPE_ID, placeholder: "Select Fault's Severity", classNamePrefix: "customSelectStyleDefault", onChange: function (item) {
                                return setValue("faultRecommendations.".concat(index, ".severity"), item === null || item === void 0 ? void 0 : item.value);
                            }, value: selectedReportEditorData.severity
                                ? REPORT_EDITOR.FAULTS_SEVERITY.find(function (severity) { return severity.value === selectedReportEditorData.severity; })
                                : null, options: REPORT_EDITOR.FAULTS_SEVERITY })] }) }), isPending ? (_jsx(_Fragment, {})) : (_jsx(Col, { md: 1, className: "d-flex align-items-center justify-content-center", children: _jsxs(ButtonGroup, { className: "mt-3", children: [index + 1 === countFaultRecommendations &&
                            selectedReportEditorData.componentType !== BAD_DATA_COMPONENT_TYPE_ID ? (_jsx(Button, { size: "sm", outline: true, onClick: function () {
                                return setValue("faultRecommendations.".concat(index + 1), {
                                    componentType: null,
                                    subType: null,
                                    fault: null,
                                    recommendations: [],
                                });
                            }, children: _jsx(FaPlus, {}) })) : (_jsx(_Fragment, {})), index > 0 ? (_jsx(Button, { size: "sm", outline: true, onClick: function () { return unregister("faultRecommendations.".concat(index)); }, children: _jsx(FaMinus, {}) })) : (_jsx(_Fragment, {}))] }) }))] }));
};
export default memo(ReportEditorForm);
