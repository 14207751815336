var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useState } from 'react';
import React, { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { ACTION_LIST } from '../../../../constants';
import FormDescriptionWrapper from '../../../../shared/components/AIPreAi/FormDescriptionWrapper/FormDescriptionWrapper';
import ActiveActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/ActiveActionItemsOnEquipment';
import PreviousActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/PreviousActionItemsOnEquipment';
import SectionPosition from '../../../../shared/components/AIPreAi/SectionPosition';
import SensorInfoBlock, { DEFAULT_DIRECTION, } from '../../../../shared/components/AIPreAi/SensorInfoBlock/SensorInfoBlock';
import ChartsImages from '../../../../shared/components/actionItem/ChartsImages';
import FormDescription from '../../../../shared/components/actionItem/FormDescription';
import PreviousAiReportModal from '../../../../shared/modals/actionItem/PreviousAiReport/PreviousAiReport';
import { useCreateMutation } from '../../../../shared/services/actionItems/useCreateMutation';
import { useInstallationPointForActionItemQuery } from '../../../../shared/services/actionItems/useInstallationPointForActionItemQuery';
import { useActionItemModalsActions } from '../../../../shared/store/AIPreAi/useActionItemModalsStore';
import { selectIsEditSections, selectSectionPosition, useActionItemCreateActions, } from '../../../../shared/store/actionItem/useActionItemCreateStore';
import { sensorInfoConfig } from '../../../config/actionItem/sensorInfo';
import StatusLabelRemastered from '../../AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../AIPreAi/UrgencyLabel';
var Content = function () {
    var itemId = useParams().id;
    var installationPointForActionItemData = useInstallationPointForActionItemQuery({ itemId: itemId }).data;
    var actionListCharts = installationPointForActionItemData.actionListCharts, existingActionItems = installationPointForActionItemData.existingActionItems, tags = installationPointForActionItemData.tags, activePreAi = installationPointForActionItemData.activePreAi, customerId = installationPointForActionItemData.customerId, installationPoint = installationPointForActionItemData.installationPoint, photos = installationPointForActionItemData.photos, storage = installationPointForActionItemData.storage;
    var setIdPreviousAiReportForModal = useActionItemModalsActions().setIdPreviousAiReportForModal;
    var isEditSections = selectIsEditSections();
    var sectionPosition = selectSectionPosition();
    var setSectionPosition = useActionItemCreateActions().setSectionPosition;
    var _a = useCreateMutation({ actionItemId: itemId, storage: storage }), createMutate = _a.mutate, createIsLoading = _a.isPending;
    var localStorageKey = 'aiSensorInfoBlockSaveAsDefault';
    var storageState = localStorage.getItem(localStorageKey)
        ? Number(localStorage.getItem(localStorageKey))
        : DEFAULT_DIRECTION;
    var _b = useState(storageState), isContentVisible = _b[0], setIsContentVisible = _b[1];
    var config = useMemo(function () {
        return sensorInfoConfig('', installationPointForActionItemData, ['sla'], isContentVisible);
    }, [installationPointForActionItemData, isContentVisible]);
    var sections = {
        'Technical info': (_jsx(SensorInfoBlock, { blocks: config, photos: photos, isContentVisible: isContentVisible, setIsContentVisible: setIsContentVisible, localStorageKey: localStorageKey })),
        "Action Item's charts": (_jsx(ChartsImages, { charts: actionListCharts, localStorageKey: "aiChartImagesBlockSaveAsDefault" })),
        'Action Items Already Exists On This Equipment': (_jsx(ActiveActionItemsOnEquipment, { localStorageKey: "aiOnEquipmentSaveAsDefault", existingActionItems: existingActionItems, archiveStates: [ACTION_LIST.STATE_ARCHIVE, ACTION_LIST.STATE_CLOSED], statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelRemastered, { commentsCount: 0, status: +existingActionItem.state, isWaitesNew: existingActionItem.is_waites_new, isTroubleshootingHold: +existingActionItem.is_troubleshooting_hold, isTroubleshootingWaiting: +existingActionItem.is_troubleshooting_waiting }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency }) })] })); } })),
        'Action Item details': (_jsx(FormDescriptionWrapper, { title: 'Action Item details', localStorageKey: "aiFormDescriptionSaveAsDefault", children: _jsx(FormDescription, { isCreate: true, tags: tags, onCancel: function () { }, description: '', activeListTags: activePreAi ? activePreAi.preActionListTags : [], title: '', id: null, isReplaceEquipment: false, urgency: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD, isPreAi: !!activePreAi, installationPoint: installationPoint, woNumber: null, editIsLoading: false, createIsLoading: createIsLoading, createMutate: function (body, methods) {
                    return createMutate(__assign(__assign({}, body), { customer_id: customerId }), methods);
                }, customerId: customerId, storage: storage, recommendations: [] }) })),
        'Previous Action Items': (_jsx(PreviousActionItemsOnEquipment, { localStorageKey: "aiPreviousActionItemsOnEquipmentSaveAsDefault", installationPointCurrent: installationPoint, existingActionItems: existingActionItems, archiveStates: [ACTION_LIST.STATE_ARCHIVE, ACTION_LIST.STATE_CLOSED], onViewCommentsClickAction: function (id) { return setIdPreviousAiReportForModal(id); }, statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelRemastered, { commentsCount: 0, status: +existingActionItem.state, isWaitesNew: existingActionItem.is_waites_new, isTroubleshootingHold: +existingActionItem.is_troubleshooting_hold, isTroubleshootingWaiting: +existingActionItem.is_troubleshooting_waiting }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency }) })] })); } })),
    };
    return (_jsxs(ReactSortable, { animation: 250, ghostClass: "active", list: sectionPosition, setList: setSectionPosition, disabled: !isEditSections, style: { width: '100%' }, children: [_jsx(SectionPosition, { sectionPosition: sectionPosition, isEditSections: isEditSections, sections: sections }), _jsx(PreviousAiReportModal, { existingActionItems: existingActionItems, onCancel: function () { return setIdPreviousAiReportForModal(null); } })] }));
};
export default memo(Content);
