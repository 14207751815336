import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { get as _get } from 'lodash';
import React, { memo, useEffect } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ALERT_LOG_TYPE_ALERTS, ALERT_LOG_TYPE_BATCH, ALERT_LOG_TYPE_LIST, } from '../../../../../../constants';
import { useStatisticCountAlertLevelForUserQuery, } from '../../../../../services/watchersStatistic/useStatisticCountAlarmLevelForUserQuery';
import { selectActiveDayData, useAlarmLevelCountModalActions, } from '../../../../../store/watchersStatistic/useAlarmLevelCountModalStore';
import { selectIsVisibleAlarmLevelCountModal, useWatchersStatisticModalsActions, } from '../../../../../store/watchersStatistic/useWatchersStatisticModalsStore';
import Loader from '../../../../Loader';
import AlertsTable from '../AlertsTable';
import BatchTable from '../BatchTable';
var AlarmLevelCountModal = function (_a) {
    var _b;
    var selectedMonth = _a.selectedMonth;
    var activeDayData = selectActiveDayData();
    var setActiveDayData = useAlarmLevelCountModalActions().setActiveDayData;
    var _c = useStatisticCountAlertLevelForUserQuery({ selectedMonth: selectedMonth }), statisticCountAlertLevelForUserData = _c.data, isLoading = _c.isLoading, isFetching = _c.isFetching, fetchNextPage = _c.fetchNextPage, hasNextPage = _c.hasNextPage;
    var setIsVisibleAlarmLevelCountModal = useWatchersStatisticModalsActions().setIsVisibleAlarmLevelCountModal;
    var isVisibleAlarmLevelCountModal = selectIsVisibleAlarmLevelCountModal();
    var _d = useIntersectionObserver({
        threshold: 0,
        root: null,
        rootMargin: '0px',
    }), ref = _d[0], entry = _d[1];
    useEffect(function () {
        if ((entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && isVisibleAlarmLevelCountModal && hasNextPage) {
            fetchNextPage();
        }
    }, [entry === null || entry === void 0 ? void 0 : entry.isIntersecting, hasNextPage, isVisibleAlarmLevelCountModal]);
    return (_jsxs(Modal, { size: "xl", isOpen: isVisibleAlarmLevelCountModal, toggle: function () {
            setActiveDayData(null);
            setIsVisibleAlarmLevelCountModal(false);
        }, children: [_jsxs(ModalHeader, { toggle: function () {
                    setActiveDayData(null);
                    setIsVisibleAlarmLevelCountModal(false);
                }, children: ["Alarm levels by ", (_b = activeDayData === null || activeDayData === void 0 ? void 0 : activeDayData.createdBy) !== null && _b !== void 0 ? _b : ''] }), _jsxs(ModalBody, { style: { minHeight: '300px' }, children: [ALERT_LOG_TYPE_LIST.map(function (type) {
                        var data = _get(statisticCountAlertLevelForUserData, type, []);
                        return !!data.length && _jsx(DefinitionTypeTable, { type: type, data: data });
                    }), _jsx("div", { className: "w-100", ref: ref }), (isLoading || isFetching) && (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }))] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { color: "secondary", outline: true, onClick: function () {
                            setActiveDayData(null);
                            setIsVisibleAlarmLevelCountModal(false);
                        }, children: "Cancel" }) }) })] }));
};
var DefinitionTypeTable = function (_a) {
    var data = _a.data, type = _a.type;
    switch (type) {
        case ALERT_LOG_TYPE_ALERTS:
            return _jsx(AlertsTable, { data: data });
        case ALERT_LOG_TYPE_BATCH:
            return _jsx(BatchTable, { data: data });
        default:
            return _jsx(_Fragment, {});
    }
};
export default memo(AlarmLevelCountModal);
