import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useResetChartSelection = function (dispatch) {
    var isSelectChartVisible = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
    }); }, shallow).isSelectChartVisible;
    useEffect(function () {
        if (!isSelectChartVisible) {
            dispatch({
                type: 'setState',
                state: {
                    isChartSelectionChecked: false,
                    isChartPainting: false,
                },
            });
        }
    }, [isSelectChartVisible]);
};
