import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useState } from 'react';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { useInterval } from '../../../../../customHooks';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { selectMeasure, useGlobalActions } from '../../../../store/global/useGlobalStore';
import DefaultTooltip from '../../../DefaultTooltip';
var Title = function (_a) {
    var installationPoint = _a.installationPoint, installationPointAnalystLog = _a.installationPointAnalystLog, title = _a.title, country = _a.country, facilityMeasure = _a.facilityMeasure;
    var _b = useState(installationPointAnalystLog || {}), analystLogData = _b[0], setAnalystLogData = _b[1];
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var measure = selectMeasure();
    var setMeasure = useGlobalActions().setMeasure;
    useInterval(function () {
        Endpoints[selectedSystemType].checkInstallationPointAnalystLog(installationPoint.id).then(function (resp) {
            setAnalystLogData(resp.data || {});
        });
    }, 60000);
    var handler = function () {
        setMeasure(facilityMeasure);
        toast.success('Metrics successfully updated', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
    };
    return (_jsxs("div", { className: "title", children: [_jsx(_Fragment, { children: 'id' in analystLogData && _get(analystLogData, 'id') && (_jsxs(React.Fragment, { children: [_jsx("span", { id: "installation-point-analyst-log", className: "text-danger d-flex align-items-center me-2 cursor-pointer", children: _jsx(FaInfoCircle, {}) }), _jsxs(UncontrolledTooltip, { target: "installation-point-analyst-log", children: ['username' in analystLogData ? _get(analystLogData, 'username') : '', " analyst is also working on this point!"] })] })) }), _jsx(_Fragment, { children: title }), country ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "title-badge title-badge-country ".concat(measure !== facilityMeasure ? 'warning' : ''), "data-tooltip-id": "country", onClick: handler, children: country }), measure !== facilityMeasure ? (_jsxs(DefaultTooltip, { clickable: true, style: { fontSize: '14px', fontWeight: 'normal' }, openEvents: { mouseenter: true }, place: "bottom", id: "country", children: ["Apply measurement unit settings as at facility (", facilityMeasure, ")"] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Title);
