var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// FIXME: when types of `@tanstack/react-query` will be fixed, fix types
import { useInfiniteQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectActiveDayData } from '../../store/watchersStatistic/useAlarmLevelCountModalStore';
export var statisticCountAlarmLevelForUserKey = function (payload) { return ['statisticCountAlarmLevelForUser', payload]; };
var PAGE_SIZE = 50;
var statisticCountAlarmLevelForUserFn = function (_a, coreLink) {
    var queryKey = _a.queryKey, pageParam = _a.pageParam;
    return __awaiter(void 0, void 0, Promise, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Api.get("".concat(coreLink, "/statistic/count-alarm-level-for-user"), {
                        query: {
                            date_from: queryKey[1].specificDayDate,
                            date_to: queryKey[1].specificDayDate,
                            'created-by': queryKey[1].createdBy,
                            pagination: {
                                page: pageParam,
                                pageSize: PAGE_SIZE,
                            },
                        },
                    })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data[0]];
            }
        });
    });
};
export var useStatisticCountAlertLevelForUserQuery = function (_a) {
    var _b, _c;
    var selectedMonth = _a.selectedMonth;
    var activeDayData = selectActiveDayData();
    var day = (_b = activeDayData === null || activeDayData === void 0 ? void 0 : activeDayData.day) !== null && _b !== void 0 ? _b : null;
    var createdBy = (_c = activeDayData === null || activeDayData === void 0 ? void 0 : activeDayData.createdBy) !== null && _c !== void 0 ? _c : null;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var currentDate = selectedMonth !== null && selectedMonth !== void 0 ? selectedMonth : new Date();
    if (day) {
        currentDate.setDate(day);
    }
    var specificDayDate = day ? getFormattedDateFromOrDefault(currentDate, 'YYYY-MM-DD 00:00:00') : '';
    return useInfiniteQuery({
        queryKey: statisticCountAlarmLevelForUserKey({ createdBy: createdBy, specificDayDate: specificDayDate }),
        initialPageParam: 1,
        getNextPageParam: function (lastPage, _allPages, lastPageParam) {
            var _a;
            return ((_a = lastPage.alerts) === null || _a === void 0 ? void 0 : _a.length) === PAGE_SIZE ? lastPageParam + 1 : null;
        },
        queryFn: function (data) {
            return statisticCountAlarmLevelForUserFn(data, getCoreUrl(selectedSystemType));
        },
        select: function (_a) {
            var pages = _a.pages;
            return {
                alerts: pages.flatMap(function (page) { var _a; return (_a = page.alerts) !== null && _a !== void 0 ? _a : []; }),
                batch: pages.flatMap(function (page) { var _a; return (_a = page.batch) !== null && _a !== void 0 ? _a : []; }),
            };
        },
        enabled: Boolean(day && createdBy),
    });
};
