import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import WatchersStatisticTooltip from '../../../components/watchersStatistic/Tooltip';
export var initialState = {
    data: {
        Hardware_team: [],
        ML_bot_system: [],
        UA_team: [],
        USA_team: [],
        // PHL_team: [],
    },
    allStatisticChartsConfig: {
        title: {
            text: 'Pre-Action Items All Analyst',
        },
        plotOptions: {
            series: {
                showInNavigator: true,
            },
        },
        tooltip: {
            shared: true,
            split: false,
            useHTML: true,
            formatter: function () {
                return ReactDOMServer.renderToStaticMarkup((_jsx(WatchersStatisticTooltip, { points: this.points })));
            },
        },
        yAxis: {
            opposite: false,
            title: {
                text: 'Count Pre-Action Items',
            },
        },
        series: [],
        disabledNearestPoint: true,
    },
    stateChartsConfig: {
        title: {
            text: 'Statistic By State',
        },
        series: [
            {
                colorByPoint: true,
                data: [],
            },
        ],
        xAxis: {
            type: 'category',
        },
        yAxis: {
            title: {
                text: 'Count Pre-Action Items',
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: 'AI Counts: <b>{point.y}</b>',
        },
    },
    pieChartOptions: {
        title: {
            text: 'Statistic By Analyst',
        },
        series: [
            {
                type: 'pie',
                colorByPoint: true,
                data: [],
            },
        ],
        tooltip: {
            pointFormat: 'Pre-AI Count: <b>{point.y}</b>',
        },
    },
};
