var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useReducer, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Alert, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import SuggestedLevelFormulaModal from '../../../remastered/modals/suggestedLevelFormula';
import { SuggestedLevelsContent } from '../../../shared/components/suggestedLevels/Content/Content';
import Filters from '../../../shared/components/suggestedLevels/Filter/Filter';
import RecalculationQueue from '../../../shared/components/suggestedLevels/RecalculationQueue';
import SettingsButton from '../../../shared/components/suggestedLevels/SettingsButton';
import { initialModalStateConfig, initialStateConfig } from '../../../shared/config/suggestedLevels';
import { setLoader } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import SuggestedLevelsSettingsModal from '../../../shared/modals/suggestedLevel/SuggestedLevelsSettingsModal';
import SuggestedLevelModal from '../../../shared/modals/suggestedLevel/index';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../shared/store/global/useGlobalStore';
import '../../../shared/styles/suggestedLevels/suggestedLevels.scss';
import { getNewUpdatedData, getSuggestedAlertConditionHandler } from '../../features/suggestedLevels';
var fetch = function (state, dispatch, selectedFacility, visibleEquipmentId, selectedZone, selectedArea) {
    var pagination = state.pagination, search = state.search, filters = state.filters, units = state.units, onlyIntralox = state.onlyIntralox;
    if (filters.less && filters.less < 0) {
        return toast.error('Value must be > 0', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    }
    setLoader(dispatch, true);
    Endpoints.amazon
        .suggestedLevelsList({
        query: {
            selected_facility: selectedFacility,
            selected_zone: selectedZone,
            selected_area: selectedArea,
            filters: {
                not_set: filters.notSet,
                is_ai: filters.isActions,
                is_pre_ai: filters.isActions,
                less: filters.less,
                greater: filters.greater,
            },
            search: search,
            onlyIntralox: onlyIntralox,
            pagination: __assign({}, pagination),
        },
    })
        .then(function (resp) {
        if (resp.status !== 'ok') {
            setLoader(dispatch, false);
            return toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        dispatch({
            type: 'setState',
            state: {
                data: getNewUpdatedData(resp.data, visibleEquipmentId || null) || {},
                units: resp.units || units,
                loader: false,
                pagination: __assign(__assign({}, pagination), { total: resp.total }),
            },
        });
    });
};
var SuggestedLevels = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c, selectedZone = _a.selectedZone, selectedArea = _a.selectedArea;
    useTitle('Suggested Levels');
    var _d = useReducer(reducer, initialStateConfig), state = _d[0], dispatch = _d[1];
    var search = state.search, pagination = state.pagination, filters = state.filters, onlyIntralox = state.onlyIntralox, settingsMode = state.settingsMode, selectedInstallationPoints = state.selectedInstallationPoints;
    var page = pagination.page, pageSize = pagination.pageSize, total = pagination.total;
    var fetchCallback = function (visibleEquipmentId) {
        return fetch(state, dispatch, selectedFacility, visibleEquipmentId || null, selectedZone, selectedArea);
    };
    var _e = useReducer(reducer, initialModalStateConfig(function (visibleEquipmentId) { return fetchCallback(visibleEquipmentId); })), stateSuggestedLevelModal = _e[0], dispatchSuggestedLevelModal = _e[1];
    var _f = useState(false), isVisibleSetSettingsModal = _f[0], setIsVisibleSetSettingsModal = _f[1];
    var visible = stateSuggestedLevelModal.visible;
    var _g = useReducer(reducer, {
        visible: false,
        overwriteLevelsCallback: function () { },
        confirmFromChartCallback: function () { },
        onCancel: function () {
            return setStateSuggestedLevelFormulaModal({
                type: 'setState',
                state: __assign(__assign({}, stateSuggestedLevelFormulaModal), { visible: false, data: {} }),
            });
        },
        data: {},
    }), stateSuggestedLevelFormulaModal = _g[0], setStateSuggestedLevelFormulaModal = _g[1];
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    useEffect(function () {
        if (!visible && selectedFacility) {
            fetchCallback();
        }
    }, [
        refreshFlag,
        selectedFacility,
        search,
        filters,
        page,
        pageSize,
        onlyIntralox,
        selectedZone,
        selectedArea,
        measure,
        personalSettingMeasure,
    ]);
    if (!selectedFacility) {
        return (_jsx(React.Fragment, { children: _jsx(Row, { className: 'my-3', children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { children: "Suggested Levels" }), _jsx(Row, { children: _jsx(Col, { md: 3, children: _jsx(Alert, { color: "danger", className: "mt-3", children: "Choose facility first" }) }) })] }) }) }) }));
    }
    return (_jsxs("div", { className: "page-suggestedLevels", children: [_jsxs("h3", { className: "page-title justify-content-between d-flex", children: ["Suggested Levels", _jsxs("div", { children: [total > 0 ? (_jsx(SettingsButton, { dispatch: dispatch, settingsMode: settingsMode, setIsVisibleSetSettingsModal: setIsVisibleSetSettingsModal, selectedInstallationPoints: Object.values(selectedInstallationPoints) })) : (_jsx(_Fragment, {})), _jsx(RecalculationQueue, { payload: {
                                    facility_name: selectedFacility,
                                    zone: selectedZone,
                                    area: selectedArea,
                                    search_value: search,
                                }, getForString: function (queueItem) {
                                    return "".concat(queueItem.facility.name, " ").concat(queueItem.zone ? " - ".concat(queueItem.zone) : '', " ").concat(queueItem.area ? " - ".concat(queueItem.area) : '');
                                } })] })] }), _jsx(Filters, { state: state, dispatch: dispatch }), _jsx(SuggestedLevelsContent, { state: state, dispatch: dispatch, stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal: setStateSuggestedLevelFormulaModal, fetchCallback: fetchCallback, dispatchSuggestedLevelModal: dispatchSuggestedLevelModal }), _jsx(SuggestedLevelModal, { stateSuggestedLevelModal: stateSuggestedLevelModal, dispatchSuggestedLevelModal: dispatchSuggestedLevelModal, getSuggestedAlertConditionDateEndpoint: function (options) {
                    return Endpoints.amazon.getSuggestedAlertConditionData(options);
                }, getSuggestedAlertConditionHandler: function (resp, dispatchSuggestedLevelModal) {
                    return getSuggestedAlertConditionHandler(resp, dispatchSuggestedLevelModal);
                }, updateLevelBySuggestedValueEndpoint: function (options) {
                    return Endpoints.amazon.updateLevelBySuggestedValue(options);
                }, deleteLevelBySuggestedValueEndpoint: function (options) {
                    return Endpoints.amazon.deleteLevelBySuggestedValue(__assign(__assign({}, options), { selected_facility: selectedFacility }));
                }, deleteAlertConditionComment: function (id) { return Endpoints.amazon.deleteAlertConditionComment(id); }, saveAlertConditionsComment: function (options) { return Endpoints.amazon.saveAlertConditionsComment(options); } }), stateSuggestedLevelFormulaModal.visible ? (_jsx(SuggestedLevelFormulaModal, { callback: function () { return fetchCallback(); }, stateSuggestedLevelFormulaModal: stateSuggestedLevelFormulaModal })) : (_jsx(_Fragment, {})), isVisibleSetSettingsModal ? (_jsx(SuggestedLevelsSettingsModal, { visible: isVisibleSetSettingsModal, onCancel: function () { return setIsVisibleSetSettingsModal(false); }, selectedInstallationPoints: Object.values(selectedInstallationPoints), filters: filters, onlyIntralox: !!onlyIntralox, search: search, savingQuery: { selected_facility: selectedFacility }, recalculatePayload: {
                    facility_name: selectedFacility,
                    zone: selectedZone,
                    area: selectedArea,
                    search_value: search,
                }, selectedEntity: "".concat(selectedFacility, " facility ").concat(selectedZone ? "".concat(selectedZone, " zone") : '', " ").concat(selectedArea ? "".concat(selectedArea, " area") : '') })) : (_jsx(_Fragment, {}))] }));
};
export default memo(SuggestedLevels);
