import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var customersKey = function () { return ['customers']; };
var customersFn = function () {
    return Api.get('all-analytics/full-service-customers');
};
export var useCustomersQuery = function () {
    return useQuery({
        queryKey: customersKey(),
        queryFn: customersFn,
        select: function (_a) {
            var customers = _a.customers;
            return {
                customers: customers,
            };
        },
        placeholderData: {
            customers: [],
        },
    });
};
