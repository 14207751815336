import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { useWatch } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";
import RichTextEditor from "../../../widgets/TextEditor/TextEditor";
var FormDescriptionTextEditor = function (_a) {
    var installationPoint = _a.installationPoint, control = _a.control, storage = _a.storage, descriptionRef = _a.descriptionRef, label = _a.label;
    var description = useWatch({
        control: control,
        name: 'description',
    });
    return (_jsxs(FormGroup, { children: [_jsx(Label, { for: "description", children: label || 'Description:' }), _jsx(RichTextEditor, { customerId: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer_id) || undefined, content: description !== null && description !== void 0 ? description : undefined, storage: storage, onChange: function (content) {
                    descriptionRef.current = content;
                } })] }));
};
export default memo(FormDescriptionTextEditor);
