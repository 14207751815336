var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { DateTimeLabel } from '../../../widgets/common';
import { SystemTypeContext } from '../../context/SystemTypeContext';
var RecalculationQueue = function (_a) {
    var payload = _a.payload, getForString = _a.getForString;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState([]), queue = _b[0], setQueue = _b[1];
    useEffect(function () {
        // @ts-ignore
        Endpoints[selectedSystemType].getSuggestedAlertConditionGetLastQueue().then(function (resp) {
            setQueue(resp.list);
        });
    }, []);
    var recalculateAlertLevels = function () {
        // @ts-ignore
        Endpoints[selectedSystemType]
            .recalculateSuggestedLevels(__assign(__assign({}, payload), { type: 'system' }))
            .then(function (resp) {
            if (resp.success) {
                return toast.success(resp.message);
            }
            toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { color: "dark", onClick: recalculateAlertLevels, outline: true, size: "sm", className: "ms-2", id: "recalculate-system-levels", children: _jsx(FiRefreshCcw, {}) }), _jsx(UncontrolledTooltip, { placement: "bottom", target: "recalculate-system-levels", children: _jsx("div", { children: queue.map(function (queueItem) {
                        var _a, _b;
                        return (_jsxs("div", { children: ["\u25CF", ' ', _jsx(DateTimeLabel, { id: "queue_item_created_".concat(queueItem.id), dateTime: queueItem.created_at, timeBreak: false }), "\u00A0", ((_a = queueItem === null || queueItem === void 0 ? void 0 : queueItem.user) === null || _a === void 0 ? void 0 : _a.name_first) || '', " ", ((_b = queueItem === null || queueItem === void 0 ? void 0 : queueItem.user) === null || _b === void 0 ? void 0 : _b.name_last) || '', " for", ' ', getForString(queueItem), " ", queueItem.search_value ? " - ".concat(queueItem.search_value) : '', queueItem.status === 0 ? ' (New)' : '', queueItem.status === 1 ? ' (In Progress)' : '', queueItem.status === 2 ? ' (Finished)' : '', queueItem.status === 3 ? ' (Error)' : ''] }, "queue_item_".concat(queueItem.id)));
                    }) }) })] }));
};
export default RecalculationQueue;
