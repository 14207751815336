// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggestedLevels-filters .second-filter {
  display: flex;
  justify-content: flex-end;
}
.suggestedLevels-filters .second-filter .search-block {
  display: flex;
}
.suggestedLevels-filters .second-filter .btn {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
  background: #fff;
  color: #212325;
  border-color: #d9d9d9;
}
.dark-layout .suggestedLevels-filters .second-filter .btn {
  color: #dcdcdc;
  background: #252a38;
  border-color: #3e3f4f;
}
.suggestedLevels-filters .second-filter .btn:hover {
  background: #2962ff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/suggestedLevels/Filter/Filter.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,yBAAA;AAAJ;AACI;EACE,aAAA;AACN;AACI;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AACN;AAAM;EACE,cAAA;EACA,mBAAA;EACA,qBAAA;AAER;AAAM;EACE,mBAAA;EACA,WAAA;AAER","sourcesContent":[".suggestedLevels-filters {\n  & .second-filter {\n    display: flex;\n    justify-content: flex-end;\n    & .search-block {\n      display: flex;\n    }\n    & .btn {\n      padding: 5px 10px;\n      font-size: 13px;\n      border-radius: 4px;\n      background: #fff;\n      color: #212325;\n      border-color: #d9d9d9;\n      .dark-layout & {\n        color: #dcdcdc;\n        background: #252a38;\n        border-color: #3e3f4f;\n      }\n      &:hover {\n        background: #2962ff;\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
