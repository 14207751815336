import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { isTwfChart } from '../../../../../../helper/chart';
import { ModalsStore } from '../../../../../../store/charts/chartsContent/ModalsStore';
var PeaksButton = function (_a) {
    var chartIdentifier = _a.chartIdentifier, width = _a.width, isDisabled = _a.isDisabled;
    if (isTwfChart(chartIdentifier)) {
        return _jsx(React.Fragment, {});
    }
    var setStatesModalsStore = ModalsStore(function (state) { return ({
        setStatesModalsStore: state.setStates,
    }); }, shallow).setStatesModalsStore;
    var onClick = useCallback(function () {
        setStatesModalsStore({
            peaksModal: {
                visible: true,
                chartIdentifier: chartIdentifier,
            },
        });
    }, []);
    return (_jsxs("div", { className: "chart-btn", children: [_jsx(Button, { style: { width: width }, id: "peaks-modal-button", className: "chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", size: "sm", disabled: isDisabled, onClick: onClick, children: "Peaks" }), _jsxs(UncontrolledTooltip, { target: "peaks-modal-button", children: ["Press ", "'X'", " key when harmonic cursor enabled to show harmonics by peaks (", "'X'", " again to show next ones)"] })] }));
};
export default React.memo(PeaksButton);
