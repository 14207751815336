var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { ALERTS_LIST, GROUP_RMS, READING_TYPE_TEMPERATURE } from '../../../../constants';
import { AlarmLevel } from '../../../../widgets/AlarmLevel';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import { Filters } from '../../../components/alerts/triggered/Filter/Filter';
import { ActionBar } from '../../../components/alerts/triggered/actionBar';
import { SelectProcess } from '../../../components/alerts/triggered/selects/SelectProcess';
import { SelectTypes } from '../../../components/alerts/triggered/selects/SelectTypes';
import Cookie from '../../../helper/cookie';
var query = new URLSearchParams(window.location.search);
export var config = function (onViewClick, type) {
    return {
        columns: [
            {
                name: '',
                titleComponent: function (callbacks, filtersData) {
                    return (_jsx(SelectProcess, { processStatus: filtersData.processStatus, onSelectProcess: callbacks.onSelectProcess }));
                },
                sortable: false,
                component: function (row, callbacks, filtersData) {
                    return _jsx(ActionBar, { item: row, callbacks: callbacks, filtersData: filtersData });
                },
            },
            {
                name: 'level_name',
                title: '',
                className: 'alarm-block',
                sortable: false,
                component: function (row) {
                    return _jsx(AlarmLevel, { levelName: row.level_name || ALERTS_LIST.CAUTION });
                },
            },
            {
                name: 'reading_type',
                titleComponent: function (callbacks, filtersData) {
                    return (_jsx(SelectTypes, { type: type, readingTypes: filtersData.readingTypes, onSelectReadingTypes: callbacks.onSelectReadingTypes }));
                },
                sortable: false,
            },
            {
                name: 'timestamp',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { timeBreak: true, dateTime: row.timestamp });
                },
            },
            {
                name: 'point_name',
                title: 'Point name',
                sortable: false,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'count',
                title: 'Count',
                sortable: false,
                component: function (row, callbacks) {
                    return row.isFirstLevel || (row.children && row.children.length >= 2) ? (_jsx(_Fragment, { children: row.count_items })) : (_jsx(ButtonGroup, { className: "button-ml button-flex", children: _jsx(Button, { className: "btn btn-sm btn-primary", onClick: function () {
                                var params = "&fromAlerts=1&scroll_to=".concat(row.reading_type_id, "&alert=").concat(row.id, "&equipmentId=").concat(row.equipment_id);
                                onViewClick(row.installation_point_id, params);
                                callbacks.onClickViewBtn(row.equipment_id, row.installation_point_id, row.id);
                            }, children: "View" }) }));
                },
            },
        ],
        sort: [
            {
                field: 'timestamp',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return _jsx(Filters, { callbacks: callbacks, filtersData: filtersData });
        },
        filtersData: {
            dateRange: [],
            isFilterClick: false,
            processStatus: '',
            readingTypes: !type
                ? ''
                : type === 'fft'
                    ? 'fft'
                    : __spreadArray([String(READING_TYPE_TEMPERATURE)], GROUP_RMS.map(function (el) { return String(el); }), true).join(','),
            batchSelectedLevelsType: '',
            selectedLevel: query.get('level') || (Cookie.get('showAllAlertsLevel') === 'true' ? ALERTS_LIST.CAUTION : ''),
            hasViewedAlert: false,
            showAcknowledge: false,
            disabledBtns: false,
        },
    };
};
