import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getColumns, getDateDetails } from '../../features/watchersStatistic/сountAlarmLevel';
import { getCoreUrl } from '../../helper/commonHelper';
import { useAlarmLevelCountModalActions } from '../../store/watchersStatistic/useAlarmLevelCountModalStore';
import { selectSortDataTable } from '../../store/watchersStatistic/useCountOfAlarmLevelsDataTableStore';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
import { useWatchersStatisticModalsActions } from '../../store/watchersStatistic/useWatchersStatisticModalsStore';
export var statisticCountAlarmLevelKey = function (selectedMonth, sort) { return [
    'statisticCountAlarmLevel',
    { selectedMonth: selectedMonth, sort: sort },
]; };
var statisticCountAlarmLevelFn = function (_a, coreLink, sort) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/count-alarm-level"), {
        query: {
            date_from: moment(queryKey[1].selectedMonth).startOf('month').format('YYYY-MM-DD hh:mm:00'),
            date_to: moment(queryKey[1].selectedMonth).endOf('month').format('YYYY-MM-DD hh:mm:00'),
            pagination: {
                page: 1,
                pageSize: 500,
            },
            sort: sort,
        },
    });
};
export var useStatisticCountAlertLevelQuery = function (_a) {
    var selectedMonth = _a.selectedMonth;
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var sort = selectSortDataTable();
    var setActiveDayData = useAlarmLevelCountModalActions().setActiveDayData;
    var setIsVisibleAlarmLevelCountModal = useWatchersStatisticModalsActions().setIsVisibleAlarmLevelCountModal;
    return useQuery({
        queryKey: statisticCountAlarmLevelKey(selectedMonth, sort),
        queryFn: function (data) { return statisticCountAlarmLevelFn(data, getCoreUrl(selectedSystemType), sort); },
        select: function (_a) {
            var _b;
            var data = _a.data;
            var _c = getDateDetails(dateRange), countDaysInMonth = _c.countDaysInMonth, month = _c.month, year = _c.year;
            return {
                moreThen25: true,
                config: {
                    disabledSearch: true,
                    hideTotalLabel: true,
                    columns: getColumns(countDaysInMonth, month, year, function (data) {
                        setIsVisibleAlarmLevelCountModal(true);
                        setActiveDayData(data);
                    }),
                    pagination: {
                        disabled: true,
                        page: 1,
                        pageSize: 500,
                        total: 0,
                    },
                    search: '',
                    data: (_b = data === null || data === void 0 ? void 0 : data.analysts) !== null && _b !== void 0 ? _b : [],
                    loader: false,
                },
            };
        },
    });
};
