var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { selectChartZoomDays } from '../../../store/charts/useChartSettingsStore';
export var useBoostChart = function (state, dispatch) {
    var countDays = ChartsStore(function (state) { return ({
        countDays: state.countDays,
    }); }, shallow).countDays;
    var chartZoomDays = selectChartZoomDays();
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { boost: {
                        enabled: chartZoomDays > 180,
                        useGPUTranslations: chartZoomDays > 180,
                    } }),
            },
        });
    }, [countDays]);
};
