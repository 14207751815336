import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import TopLogoWithoutFlagForLightModeSvg from '@assets/images/top-logo-without-flag-for-light-mode.svg';
import TopLogoWithoutFlagSvg from '@assets/images/top-logo-without-flag.svg';
import UkrainePng from '@assets/images/ukraine.png';
import LinkWithPrefix from '../../../../shared/components/LinkWithPrefix';
import { selectTheme } from '../../../../shared/store/global/useGlobalStore';
var Logo = function () {
    var theme = selectTheme();
    return (_jsxs("div", { className: "logo", children: [_jsx(LinkWithPrefix, { to: "/", children: _jsx("img", { className: "waites-title", src: theme === 'dark' ? TopLogoWithoutFlagSvg : TopLogoWithoutFlagForLightModeSvg, alt: "logo" }) }), _jsx("a", { href: "https://ukraine.waites.net/", target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { className: "flag-logo", src: UkrainePng, alt: "logo" }) })] }));
};
export default memo(Logo);
