import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import { selectNote, useAnalystNotesModalsActions, } from "../../../../shared/store/analystNotes/useAnalystNotesFormModalStore";
import { useFacilitiesQuery } from "../../../services/useFacilitiesQuery";
var FacilitySelectAnalystNotes = function () {
    var note = selectNote();
    var facility = note.facility;
    var _a = useFacilitiesQuery(), facilitiesData = _a.data, facilitiesIsLoading = _a.isLoading, facilitiesIsFetching = _a.isFetching;
    var facilities = facilitiesData.facilities;
    var _b = useAnalystNotesModalsActions(), setFacility = _b.setFacility, setZone = _b.setZone, setArea = _b.setArea, setEquipments = _b.setEquipments, setComponents = _b.setComponents;
    var facilityName = useMemo(function () { var _a, _b; return (_b = (_a = facilities.find(function (el) { return el.facility_id === (facility === null || facility === void 0 ? void 0 : facility.id); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; }, [facilities, facility === null || facility === void 0 ? void 0 : facility.id]);
    return (_jsxs(FormGroup, { className: "col-4", children: [_jsx(Label, { children: "Facility:" }), _jsx(Select, { placeholder: "Select Facility", classNamePrefix: "customSelectStyleDefault", name: "analystNotesFacilitySelector", id: "analystNotesFacilitySelector", isLoading: facilitiesIsLoading || facilitiesIsFetching, value: facilityName ? { label: facilityName, value: facility === null || facility === void 0 ? void 0 : facility.id } : null, onChange: function (_a) {
                    var value = _a.value, label = _a.label;
                    setFacility({
                        id: value,
                        name: label,
                    });
                    setZone(null);
                    setArea(null);
                    setEquipments(null);
                    setComponents(null);
                }, options: facilities.map(function (_a) {
                    var name = _a.name, facility_id = _a.facility_id;
                    return {
                        label: name,
                        value: facility_id,
                    };
                }) })] }));
};
export default memo(FacilitySelectAnalystNotes);
