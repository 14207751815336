import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { Badge, Button, ButtonGroup } from 'reactstrap';
import { READING_TYPES } from '../../../../constants';
import { UrgencyLabel } from '../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import Filters from '../../../components/twfDetections/reports/Filter';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point Name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true, notShowEmptyHex: true });
                },
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
                style: { minWidth: 150 },
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "---" });
                    }
                    var charts = _get(row, 'userReport.charts') || _get(row, 'charts') || [];
                    return (_jsx(_Fragment, { children: charts
                            .map(function (chart) {
                            return "".concat(_get(READING_TYPES, [chart.reading_type_id, 'title'])).concat(_get(READING_TYPES, [chart.reading_type_id, 'axis'])
                                ? " ".concat(_get(READING_TYPES, [chart.reading_type_id, 'axis']))
                                : '');
                        })
                            .join(', ') }));
                },
            },
            {
                name: 'is_relevant',
                title: 'Relevant',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return (_jsx(Badge, { pill: true, color: "secondary", size: "sm", children: "Not Processed" }));
                    }
                    if (+row.is_relevant === 1) {
                        return (_jsx(Badge, { pill: true, color: "success", size: "sm", children: "Yes" }));
                    }
                    return (_jsx(Badge, { pill: true, color: "danger", size: "sm", children: "No" }));
                },
            },
            {
                name: 'defect_category',
                title: 'Defect category',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(_Fragment, { children: row.defect_category.split(';').join('; ') });
                },
            },
            {
                name: 'fault_type',
                title: 'Fault type',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null || row.fault_type === null || row.fault_type.length === 0) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(_Fragment, { children: row.fault_type.split(';').join('; ') });
                },
            },
            {
                name: 'urgency',
                title: 'Urgency',
                sortable: true,
                component: function (row) {
                    if (row.processed_report_id === null) {
                        return _jsx(_Fragment, { children: "Not set" });
                    }
                    return _jsx(UrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                name: 'created_at',
                title: 'Created At',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_created_at"), dateTime: row.created_at });
                },
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "twf_detections_log_".concat(row.id, "_updated_at"), dateTime: row.updated_at });
                },
            },
            {
                name: 'comment',
                title: 'Comment',
                sortable: false,
            },
            {
                name: 'username',
                title: 'Username',
                sortable: true,
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    var createReportParam = row.processed_report_id !== null ? '' : "&twf_ml_data_item_id=".concat(row.id);
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { children: _jsx(Button, { color: "info", size: "sm", disabled: _get(row, 'charts', []).length === 0, onClick: function () {
                                        return callbacks.onViewImages(_get(row, 'charts', []).map(function (chart) { return chart.image; }));
                                    }, children: "Images" }) }), _jsx(ButtonGroup, { className: "ms-1", children: _jsx(Button, { onClick: function () { return onViewClick(row.installation_point_id, createReportParam); }, className: "btn btn-sm btn-success", children: "View" }) })] }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filtersData: {
            users: [],
        },
        filters: function (callbacks, filtersData) {
            return _jsx(Filters, { callbacks: callbacks, filtersData: filtersData });
        },
    };
};
