var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { cloneDeep as _cloneDeep, get as _get, set as _set, size as _size } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import Endpoints from "../../api/endpoints/endpoints";
import { METRIC_KEY, READING_TYPES } from "../../constants";
import Loader from "../../shared/components/Loader";
import { getAlertConditionsConfig } from "../../shared/components/charts/chartsWrapper/features/rms/alertConditions";
import { calculateMaxYAxisValue, calculateMinYAxisValue, } from "../../shared/components/charts/chartsWrapper/features/shared/extremes";
import { SystemTypeContext } from "../../shared/context/SystemTypeContext";
import { getRmsAccVelExtraType } from "../../shared/features/charts/shared";
import { getTimezone } from "../../shared/features/shared";
import { getUoms, isCfChart, isPinChart } from "../../shared/helper/chart";
import { selectMeasure, selectPersonalSettingMeasure, } from "../../shared/store/global/useGlobalStore";
var defaultOptions = {
    chart: {
        style: {
            fontFamily: "'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, Helvetica, sans-serif",
            fontSize: '12px',
        },
        type: 'line',
        borderColor: 'rgb(241, 238, 233)',
        backgroundColor: 'rgb(241, 238, 233)',
        borderWidth: 2,
        animation: false,
        marginTop: 50,
    },
    boost: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    navigator: {
        enabled: false,
    },
    scrollbar: {
        enabled: false,
    },
    colors: ['#7cb5ec'],
    rangeSelector: {
        enabled: false,
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },
    title: {
        text: '',
        style: {
            fontSize: '12px',
        },
    },
    series: [],
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        series: {
            animation: false,
            connectNulls: false,
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1,
                },
                inactive: {
                    opacity: 1,
                },
            },
        },
    },
    xAxis: [
        {
            crosshair: true,
            ordinal: false,
            type: 'datetime',
            plotLines: [],
        },
    ],
    yAxis: [
        {
            tickWidth: 1,
            tickLength: 20,
            startOnTick: false,
            endOnTick: false,
            opposite: false,
            title: {
                align: 'middle',
                text: '',
            },
        },
    ],
    legend: {
        align: 'center',
        verticalAlign: 'top',
        y: 10,
        floating: true,
        enabled: true,
    },
    exporting: {
        enabled: false,
    },
};
var fetch = function (options, setOptions, setLoader, installationPoint, readingTypeId, selectedSystemType, showSystemLevels, showMlSuggestedLevels, measure, personalSettingMeasure) {
    setLoader(true);
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var queryOptions = {
        query: {
            show_system_levels: +showSystemLevels,
            show_ml_suggested_levels: +showMlSuggestedLevels,
            selected_facility_metric: METRIC_KEY[measure],
            timezone: timezone,
        },
    };
    // @ts-ignore
    var request = Endpoints[selectedSystemType].getRmsReadings(installationPoint.id, readingTypeId, 21, queryOptions);
    request
        .then(function (resp) {
        if (resp) {
            if (!_size(resp.readings)) {
                return setLoader(false);
            }
            var alertConditions = resp.alertConditions;
            setLoader(false);
            return setOptions(__assign(__assign({}, options), { series: [
                    {
                        id: "rms_".concat(readingTypeId, "_").concat(installationPoint.id),
                        installationPointId: installationPoint.id,
                        isOverlay: false,
                        data: resp.readings,
                        color: '#7cb5ec',
                        name: resp.installationPoint.name,
                        units: getUoms(readingTypeId, measure, personalSettingMeasure),
                        chartType: readingTypeId,
                        precision: READING_TYPES[readingTypeId].precision,
                        visible: true,
                        showInLegend: true,
                        events: {
                            legendItemClick: function (e) {
                                e.preventDefault();
                            },
                        },
                        dataGrouping: {
                            approximation: 'high',
                            forced: true,
                        },
                    },
                ], yAxis: [
                    __assign(__assign({}, options.yAxis[0]), { title: {
                            text: "".concat(_get(READING_TYPES, [readingTypeId, 'title'], ''), " ").concat(isCfChart(readingTypeId) || isPinChart(readingTypeId)
                                ? ''
                                : "(".concat(getUoms(readingTypeId, measure, personalSettingMeasure), ") ").concat(getRmsAccVelExtraType(readingTypeId))),
                        }, plotLines: getAlertConditionsConfig(alertConditions, readingTypeId, function () { }, READING_TYPES, measure, personalSettingMeasure, false), initialPlotLines: getAlertConditionsConfig(alertConditions, readingTypeId, function () { }, READING_TYPES, measure, personalSettingMeasure, false), softMax: calculateMaxYAxisValue(alertConditions, readingTypeId, resp.readings, READING_TYPES, measure, personalSettingMeasure), softMin: calculateMinYAxisValue(alertConditions, readingTypeId, READING_TYPES, measure, personalSettingMeasure) }),
                ] }));
        }
    })
        .catch(function () {
        setLoader(false);
    });
};
var SuggestedLevelFormulaChart = function (_a) {
    var installationPoint = _a.installationPoint, selectedAxis = _a.selectedAxis, readingTypeId = _a.readingTypeId, cautionSuggestedLevelValue = _a.cautionSuggestedLevelValue, warningSuggestedLevelValue = _a.warningSuggestedLevelValue, setChartIsLoaded = _a.setChartIsLoaded, setMaxValue = _a.setMaxValue, chartIsLoaded = _a.chartIsLoaded, _b = _a.showSystemLevels, showSystemLevels = _b === void 0 ? 1 : _b, _c = _a.showMlSuggestedLevels, showMlSuggestedLevels = _c === void 0 ? 0 : _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var chartRef = useRef(null);
    var _d = useState(defaultOptions), options = _d[0], setOptions = _d[1];
    var _e = useState(true), loader = _e[0], setLoader = _e[1];
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    useEffect(function () {
        fetch(options, setOptions, setLoader, installationPoint, +readingTypeId, selectedSystemType, showSystemLevels, showMlSuggestedLevels, measure, personalSettingMeasure);
    }, [selectedAxis, installationPoint]);
    useEffect(function () {
        var updatedOptions = _cloneDeep(options);
        if (!updatedOptions.yAxis[0].plotLines) {
            return;
        }
        _set(updatedOptions, ['yAxis', 0, 'plotLines', 2, 'value'], cautionSuggestedLevelValue);
        _set(updatedOptions, ['yAxis', 0, 'plotLines', 3, 'value'], warningSuggestedLevelValue);
        if (_get(updatedOptions, ['yAxis', 0, 'softMax'], 0) <= warningSuggestedLevelValue) {
            _set(updatedOptions, ['yAxis', 0, 'softMax'], warningSuggestedLevelValue + warningSuggestedLevelValue / 10);
        }
        setOptions(updatedOptions);
    }, [cautionSuggestedLevelValue, warningSuggestedLevelValue, chartIsLoaded]);
    useEffect(function () {
        if (options.series.length !== 0) {
            setMaxValue(Math.max.apply(Math, _get(options.series, [0, 'data'], []).map(function (item) { return item[1]; })));
            setChartIsLoaded(true);
        }
    }, [options]);
    return (_jsx("div", { className: "mt-2 position-relative", children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: options })) }));
};
export default SuggestedLevelFormulaChart;
