import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useLockedCursor = function (state, chartRef) {
    var isLockedRmsCursor = ChartsStore(function (state) { return ({
        isLockedRmsCursor: state.isLockedRmsCursor,
    }); }, shallow).isLockedRmsCursor;
    var options = state.options;
    useEffect(function () {
        var elements = document.querySelectorAll('.chart-rms .highcharts-tooltip');
        // @ts-ignore
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            element.style.left = '0px';
            element.style.top = '0px';
        }
        chartRef.current.chart.userOptions.isLockedRmsCursor = isLockedRmsCursor;
    }, [isLockedRmsCursor, options]);
};
