var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, toArray as _toArray } from 'lodash';
import React, { memo, useEffect, useReducer } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import Endpoints from '../../api/endpoints/endpoints';
import { METRIC_KEY, METRIC_KEY_NAME, READING_TYPE_ACCELERATION_IMPACT_VUE_X, READING_TYPE_ACCELERATION_IMPACT_VUE_Y, READING_TYPE_ACCELERATION_IMPACT_VUE_Z, READING_TYPE_ACCELERATION_X, READING_TYPE_ACCELERATION_Y, READING_TYPE_ACCELERATION_Z, READING_TYPE_PK_PK_IMPACT_VUE_X, READING_TYPE_PK_PK_X, READING_TYPE_PK_PK_Y, READING_TYPE_PK_PK_Z, READING_TYPE_RANGE, READING_TYPE_TEMPERATURE, READING_TYPE_VELOCITY_X, READING_TYPE_VELOCITY_Y, READING_TYPE_VELOCITY_Z, TYPE_CUSTOM, TYPE_METRIC, TYPE_US, } from '../../constants';
import { reducer } from '../../shared/helper/reducer';
import { selectMeasure, selectPersonalSettingMeasure, useGlobalActions, } from '../../shared/store/global/useGlobalStore';
var READING_TYPES_WITH_SETTINGS = [
    READING_TYPE_TEMPERATURE,
    READING_TYPE_ACCELERATION_X,
    READING_TYPE_ACCELERATION_Y,
    READING_TYPE_ACCELERATION_Z,
    READING_TYPE_VELOCITY_X,
    READING_TYPE_VELOCITY_Y,
    READING_TYPE_VELOCITY_Z,
    READING_TYPE_PK_PK_X,
    READING_TYPE_PK_PK_Y,
    READING_TYPE_PK_PK_Z,
    READING_TYPE_RANGE,
    READING_TYPE_ACCELERATION_IMPACT_VUE_X,
    READING_TYPE_ACCELERATION_IMPACT_VUE_Y,
    READING_TYPE_ACCELERATION_IMPACT_VUE_Z,
    READING_TYPE_PK_PK_IMPACT_VUE_X,
];
var selectedCustomMetricsInitial = {};
var initialState = function (measure, personalSettingMeasure) {
    return {
        chartTypes: [],
        selectedPersonalSettingMeasure: personalSettingMeasure,
        buttonIsDisabled: false,
        selectedMeasure: measure,
    };
};
var MetricsModal = function (_a) {
    var layoutDispatch = _a.layoutDispatch;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _b = useReducer(reducer, initialState(measure, personalSettingMeasure)), state = _b[0], dispatch = _b[1];
    var chartTypes = state.chartTypes, selectedPersonalSettingMeasure = state.selectedPersonalSettingMeasure, buttonIsDisabled = state.buttonIsDisabled, selectedMeasure = state.selectedMeasure;
    var _c = useGlobalActions(), setMeasure = _c.setMeasure, setPersonalSettingMeasure = _c.setPersonalSettingMeasure;
    var onChangeMeasure = function (newSelectedMeasure) {
        dispatch({
            type: 'setState',
            state: {
                selectedMeasure: newSelectedMeasure,
            },
        });
    };
    useEffect(function () {
        READING_TYPES_WITH_SETTINGS.forEach(function (readingType) {
            //@ts-ignore
            selectedCustomMetricsInitial[readingType] = personalSettingMeasure.units[readingType] || 'us';
        });
        dispatch({
            type: 'setState',
            state: {
                selectedMeasure: measure,
                selectedPersonalSettingMeasure: __assign(__assign({}, personalSettingMeasure), { units: selectedCustomMetricsInitial }),
            },
        });
    }, []);
    useEffect(function () {
        Endpoints.remastered.getChartTypesList().then(function (resp) {
            dispatch({
                type: 'setState',
                state: {
                    chartTypes: _toArray(resp),
                },
            });
        });
    }, []);
    var onCancel = function () {
        dispatch({
            type: 'setState',
            state: __assign({}, initialState),
        });
        closeModal();
    };
    var closeModal = function () { return layoutDispatch({ type: 'setMetricsModal', isVisibleMetricsModal: false }); };
    var onSave = function () {
        dispatch({ type: 'setState', state: { buttonIsDisabled: true } });
        Endpoints.sso
            .updateMeasure({
            personal_settings: selectedPersonalSettingMeasure,
            use_metric: METRIC_KEY[selectedMeasure],
        })
            .then(function () {
            closeModal();
            dispatch({ type: 'setState', state: { buttonIsDisabled: false } });
            setMeasure(selectedMeasure);
            setPersonalSettingMeasure(selectedPersonalSettingMeasure);
            toast.success('Metrics successfully updated', {
                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
            });
        });
    };
    var onCustomMetricChange = function (readingTypes, metric) {
        var chartGroupData = {};
        readingTypes.map(function (readingType) {
            chartGroupData[readingType] = metric;
        });
        dispatch({
            type: 'setState',
            state: {
                selectedPersonalSettingMeasure: {
                    datetime_format: selectedPersonalSettingMeasure.datetime_format,
                    units: __assign(__assign({}, selectedPersonalSettingMeasure.units), chartGroupData),
                },
            },
        });
    };
    return (_jsxs(Modal, { className: "modal-response", toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Measurement" }), _jsxs(ModalBody, { children: [_jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "use_metric", checked: selectedMeasure === METRIC_KEY_NAME[TYPE_METRIC], onChange: function () { return onChangeMeasure(METRIC_KEY_NAME[TYPE_METRIC]); } }), "Metric (mm, mm/sec, \u00B0C)"] }) }), _jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "use_metric", checked: selectedMeasure === METRIC_KEY_NAME[TYPE_US], onChange: function () { return onChangeMeasure(METRIC_KEY_NAME[TYPE_US]); } }), "US (mils, in/sec, \u00B0F)"] }) }), _jsx(FormGroup, { check: true, className: "mb-3", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "use_metric", checked: selectedMeasure === METRIC_KEY_NAME[TYPE_CUSTOM], onChange: function () { return onChangeMeasure(METRIC_KEY_NAME[TYPE_CUSTOM]); } }), "Custom"] }) }), selectedMeasure === METRIC_KEY_NAME[TYPE_CUSTOM] ? (_jsx(_Fragment, { children: chartTypes.map(function (type) {
                            return type.personalSettings &&
                                (!type.projects ||
                                    (Array.isArray(type.projects) && !type.projects.length) ||
                                    (Array.isArray(type.projects) && type.projects.includes('allAnalytics'))) && (_jsxs(Row, { className: "mt-1 ".concat(type.alias === 'dis' ? 'hidden' : ''), children: [_jsx(Col, { children: type.label }), _jsx(Col, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: type.alias, defaultChecked: _get(selectedPersonalSettingMeasure, ['units', type.readingTypes[0]], METRIC_KEY_NAME[TYPE_US]) === METRIC_KEY_NAME[TYPE_METRIC], onChange: function () {
                                                        return onCustomMetricChange(type.readingTypes, METRIC_KEY_NAME[TYPE_METRIC]);
                                                    } }), _jsx("span", { className: "ms-1", children: type.unitsList.metric })] }) }), _jsx(Col, { children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: type.alias, defaultChecked: _get(selectedPersonalSettingMeasure, ['units', type.readingTypes[0]], METRIC_KEY_NAME[TYPE_US]) === METRIC_KEY_NAME[TYPE_US], onChange: function () { return onCustomMetricChange(type.readingTypes, METRIC_KEY_NAME[TYPE_US]); } }), _jsx("span", { className: "ms-1", children: type.unitsList.us })] }) })] }, type.alias));
                        }) })) : (_jsx(_Fragment, {}))] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, disabled: buttonIsDisabled, children: "Save" })] }) })] }));
};
export default memo(MetricsModal);
