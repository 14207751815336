import { createStore } from '../createStore';
var usePreActionItemModalsStore = createStore(function (set) { return ({
    mlReportModal: {
        visible: false,
        activeMlReport: null,
        callback: function () { },
    },
    actions: {
        setMlReportModal: function (mlReportModal) { return set({ mlReportModal: mlReportModal }); },
    },
}); });
export var usePreActionItemModalsActions = function () {
    return usePreActionItemModalsStore(function (state) { return state.actions; });
};
export var selectMlReportModal = function () {
    return usePreActionItemModalsStore(function (state) { return state.mlReportModal; });
};
