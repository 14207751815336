import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheckCircle, FaUserCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useCustomerCreatedMutation, } from '../../../../services/actionItems/useCustomerCreatedAiMutation';
import { useConfirmModalActions } from '../../../../store/global/useConfirmModalStore';
import DefaultTooltip from '../../../DefaultTooltip';
var CustomerCreatedBtn = function (_a) {
    var isCustomerCreated = _a.isCustomerCreated, storage = _a.storage, id = _a.id, onInvalidateData = _a.onInvalidateData;
    var customerCreatedMutate = useCustomerCreatedMutation({
        storage: storage,
    }).mutate;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var handler = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                customerCreatedMutate({
                    action_item_id: id,
                    is_customer_created: !isCustomerCreated,
                }, {
                    onSuccess: function () {
                        toast.success('Successfully changed!', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                        onInvalidateData();
                    },
                });
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: !isCustomerCreated, color: isCustomerCreated ? 'warning' : 'primary', "data-tooltip-id": "customer-created-ai-btn", className: "top-actions-fill", onClick: handler, children: _jsx(FaUserCheck, { style: isCustomerCreated ? { fill: '#000' } : {} }) }), _jsxs(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "customer-created-ai-btn", children: [isCustomerCreated ? 'Unset' : 'Set', " AI as created by customer"] })] }));
};
export default memo(CustomerCreatedBtn);
