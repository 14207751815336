import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var bearingSpecificationsKey = function () { return ['bearingSpecifications']; };
var bearingSpecificationsFn = function (coreLink) { return Api.get("".concat(coreLink, "/bearing/specifications")); };
export var useBearingSpecificationsQuery = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: bearingSpecificationsKey(),
        queryFn: function () { return bearingSpecificationsFn(getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var list = _a.list;
            return {
                list: list,
            };
        },
    });
};
