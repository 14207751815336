var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { costAvoidedTableOptions } from '../../../config/watchersStatistic/costAvoided';
export var buildUsersChartSeries = function (data) {
    var chartSeriesData = [];
    (data || []).map(function (user) {
        chartSeriesData.push({
            name: user.username,
            y: user.total,
        });
    });
    return chartSeriesData;
};
export var getAnalystsChartOptions = function (analystsData) {
    var chartSeriesData = [];
    costAvoidedTableOptions.map(function (el) {
        if (analystsData[el.key]) {
            chartSeriesData = __spreadArray(__spreadArray([], chartSeriesData, true), buildUsersChartSeries(analystsData[el.key] || []), true);
        }
    });
    return chartSeriesData;
};
export var numberWithCommas = function (number) {
    if (!number)
        return 0;
    return (Math.floor(number * 100) / 100).toString();
};
