var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useSeries = function (state, dispatch, chartIdentifier) {
    var hidePeaks = ChartsStore(function (state) { return ({
        hidePeaks: state.hidePeaks,
    }); }, shallow).hidePeaks;
    var removePoint = ChartFeaturesStore(function (state) { return ({
        removePoint: state.removePoint,
    }); }, shallow).removePoint;
    var options = state.options, mainSeriesLoaded = state.mainSeriesLoaded, hidePeaksOptions = state.hidePeaksOptions;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        var newSeries = options.series.map(function (el) {
            if (!el.isMainSeries || !(el.archiveData || []).length) {
                return el;
            }
            var newData = [];
            for (var i = 0; i < el.archiveData.length; i++) {
                if (!el.archiveData[i][1] || !hidePeaks) {
                    newData.push(el.archiveData[i]);
                }
                if (el.archiveData[i][1] < hidePeaksOptions.peak || hidePeaksOptions.peak === null) {
                    newData.push([el.archiveData[i][0], el.archiveData[i][1]]);
                }
            }
            return __assign(__assign({}, el), { data: newData });
        });
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { series: newSeries }),
            },
        });
    }, [hidePeaksOptions.peak, hidePeaks]);
    useEffect(function () {
        if (!removePoint.removedReading) {
            return;
        }
        var series = _cloneDeep(options.series);
        if (!series[0]) {
            return;
        }
        var chartIdentifierPoint = _get(removePoint.point, 'series.chart.userOptions.chartIdentifier');
        if (+chartIdentifierPoint !== +chartIdentifier) {
            return;
        }
        series[0].data = series[0].data.filter(function (item) { return +item[0] !== +_get(removePoint, 'removedReading'); });
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { series: series }),
            },
        });
    }, [removePoint.removedReading]);
};
