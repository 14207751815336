import { createStore } from '../createStore';
var useViewedLogStore = createStore(function (set) { return ({
    isCustomerDashboard: false,
    actions: {
        setIsCustomerDashboard: function (isCustomerDashboard) { return set({ isCustomerDashboard: isCustomerDashboard }); },
    },
}); });
export var useViewedLogActions = function () {
    return useViewedLogStore(function (state) { return state.actions; });
};
export var selectIsCustomerDashboard = function () {
    return useViewedLogStore(function (state) { return state.isCustomerDashboard; });
};
