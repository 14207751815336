import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useState } from 'react';
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap';
import './Statistic.scss';
import AiRow from './components/AiRow';
import AlertsRow from './components/AlertsRow';
import WatchListRow from './components/WatchListRow';
var HomePageStatistic = function (_a) {
    var client = _a.client, statistic = _a.statistic, setOpenNodesModal = _a.setOpenNodesModal, setOpenSensorsModal = _a.setOpenSensorsModal, setSelectedClient = _a.setSelectedClient, setOpenSensorsWithoutAlarmLevelsModal = _a.setOpenSensorsWithoutAlarmLevelsModal;
    if (!statistic) {
        return _jsx(_Fragment, {});
    }
    var _b = useState(false), isActiveAiStatistic = _b[0], setIsActiveAiStatistic = _b[1];
    var _c = useState(false), isActiveAlertsStatistic = _c[0], setIsActiveAlertsStatistic = _c[1];
    var _d = useState(false), isActiveWatchListStatistic = _d[0], setIsActiveWatchListStatistic = _d[1];
    return (_jsxs("div", { className: "card-statistic", children: [_jsx(AiRow, { client: client, statistic: statistic, isActiveAiStatistic: isActiveAiStatistic, setIsActiveAiStatistic: setIsActiveAiStatistic }), _jsx(AlertsRow, { client: client, statistic: statistic, isActiveAlertsStatistic: isActiveAlertsStatistic, setIsActiveAlertsStatistic: setIsActiveAlertsStatistic }), _jsx(WatchListRow, { client: client, statistic: statistic, isActiveWatchListStatistic: isActiveWatchListStatistic, setIsActiveWatchListStatistic: setIsActiveWatchListStatistic }), _jsxs(Row, { children: [_jsx(Col, { md: 5, className: "card-statistic-title", children: _jsx("span", { children: "Bad Sensors:" }) }), _jsxs(Col, { md: 4, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "bad-data-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "bad-data-total-".concat(client.customer_id), color: _get(statistic, 'badData.count', 0) > 0 ? 'info' : 'none', children: _get(statistic, 'badData.count', 0) })] }), _jsx(Col, { md: 3 })] }), _jsxs(Row, { children: [_jsx(Col, { md: 5, className: "card-statistic-title", children: _jsx("span", { children: "Nodes:" }) }), _jsxs(Col, { md: 4, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "nodes-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "nodes-total-".concat(client.customer_id), color: _get(statistic, 'nodes.count', 0) > 0 ? 'info' : 'none', children: _get(statistic, 'nodes.count', 0) })] }), _jsx(Col, { md: 3, className: "card-statistic-actions", children: _get(statistic, 'nodes.count', 0) > 0 ? (_jsx(Badge, { onClick: function () {
                                setOpenNodesModal(true);
                                setSelectedClient(client);
                            }, color: "primary", children: _jsx("i", { className: "fa fa-eye" }) })) : (_jsx(Badge, { children: _jsx("i", { className: "fa fa-eye" }) })) })] }), _jsxs(Row, { children: [_jsx(Col, { md: 5, className: "card-statistic-title", children: _jsx("span", { children: "Sensors:" }) }), _jsxs(Col, { md: 4, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "sensors-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "sensors-total-".concat(client.customer_id), color: _get(statistic, 'sensors.count', 0) > 0 ? 'info' : 'none', children: _get(statistic, 'sensors.count', 0) })] }), _jsx(Col, { md: 3, className: "card-statistic-actions", children: _get(statistic, 'sensors.count', 0) > 0 ? (_jsx(Badge, { onClick: function () {
                                setOpenSensorsModal(true);
                                setSelectedClient(client);
                            }, color: "primary", children: _jsx("i", { className: "fa fa-eye" }) })) : (_jsx(Badge, { children: _jsx("i", { className: "fa fa-eye" }) })) })] }), _jsxs(Row, { children: [_jsx(Col, { md: 7, className: "card-statistic-title", children: _jsx("span", { children: "Sensors without alarm levels:" }) }), _jsxs(Col, { md: 2, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "sensors-without-alarms-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "sensors-without-alarms-total-".concat(client.customer_id), color: _get(statistic, 'sensorsWithoutAlarms.count', 0) > 0 ? 'info' : 'none', children: _get(statistic, 'sensorsWithoutAlarms.count', 0) })] }), _jsx(Col, { md: 3, className: "card-statistic-actions", children: _get(statistic, 'sensorsWithoutAlarms.count', 0) > 0 ? (_jsx(Badge, { onClick: function () {
                                setOpenSensorsWithoutAlarmLevelsModal(true);
                                setSelectedClient(client);
                            }, color: "primary", children: _jsx("i", { className: "fa fa-eye" }) })) : (_jsx(Badge, { children: _jsx("i", { className: "fa fa-eye" }) })) })] }), _jsxs(Row, { children: [_jsx(Col, { md: 12, className: "card-statistic-title", children: _jsx("span", { children: "Lead Analyst:" }) }), _jsx(Col, { md: 12, className: "d-flex flex-wrap", children: (statistic.analytic_leads || []).length ? (statistic.analytic_leads.map(function (lead) {
                            return (_jsxs(Badge, { color: "primary", className: "mt-1 me-1", children: [_jsx("i", { className: "fa fa-user me-1" }), "".concat(lead.name_first, " ").concat(lead.name_last)] }, lead.id));
                        })) : (_jsx("span", { children: _jsx("i", { children: "Not Set" }) })) })] })] }));
};
export default HomePageStatistic;
