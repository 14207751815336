import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { preActionItemKey as preActionItemKeyAmazon } from '../../../amazon/services/preActionItems/usePreActionItemQuery';
import Api from '../../../api/api';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { preActionItemKey as preActionItemKeyRemastered } from '../../../remastered/services/preActionItems/usePreActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
import { makeValidLink } from '../../helper/routeHelper';
var createFn = function (data, coreLink, storage, selectedSystemType) {
    return Api.post("".concat(coreLink, "/pre-action-list").concat(selectedSystemType === SYSTEM_TYPE_REMASTERED ? '/create' : ''), data, { host: getHost(storage) });
};
export var useCreateMutation = function (_a) {
    var preActionItemId = _a.preActionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var trackEvent = useMatomo().trackEvent;
    var queryClient = useQueryClient();
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var navigate = useNavigate();
    return useMutation({
        mutationFn: function (data) { return createFn(data, getCoreUrl(selectedSystemType), storage, selectedSystemType); },
        onSuccess: function (data) {
            navigate(makeValidLink("/".concat(prefix, "/pre-action-list/").concat(data.model.id)));
            queryClient.invalidateQueries({
                queryKey: selectedSystemType
                    ? preActionItemKeyRemastered(preActionItemId)
                    : preActionItemKeyAmazon(preActionItemId),
            });
            trackEvent({
                category: 'Pre-Action item',
                action: 'Create',
                name: "ID: ".concat(preActionItemId),
                href: window.location.href,
            });
        },
    });
};
