import { useQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
export var templatesKey = function (customerId) { return ['templates', customerId]; };
var templatesFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get('all-analytics/nameplate-template/list', { query: { selected_customer_ids: [queryKey[1]] } });
};
export var useTemplatesQuery = function (_a) {
    var customerId = _a.customerId;
    return useQuery({
        queryKey: templatesKey(customerId),
        queryFn: templatesFn,
        select: function (_a) {
            var list = _a.list;
            return { list: list };
        },
        enabled: !!customerId,
    });
};
