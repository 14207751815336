import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
var ImagesModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, images = _a.images, title = _a.title;
    var _b = useState(''), activeImg = _b[0], setActiveImg = _b[1];
    useEffect(function () {
        setActiveImg('');
    }, [visible]);
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: title || 'Images' }), _jsxs(ModalBody, { children: [images.length === 0 && _jsx("p", { className: "text-center", children: "There are no images" }), images.map(function (image) { return (_jsx(_Fragment, { children: activeImg === '' || activeImg === image ? (_jsx(_Fragment, { children: _jsx("img", { onClick: function () { return setActiveImg(activeImg === image ? '' : image); }, className: "".concat(activeImg === image ? 'cursor-zoom-out' : 'cursor-zoom-in', " p-2 d-inline-block"), style: { maxWidth: activeImg === image ? '100%' : '20%' }, src: image, alt: image }, image) })) : (_jsx(_Fragment, {})) })); })] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default ImagesModal;
