var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, size as _size, sortBy as _sortBy, uniq as _uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../../../../../../constants';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
import './index.scss';
var BatchTimestampsCalendar = function (_a) {
    var chartIdentifier = _a.chartIdentifier, onApply = _a.onApply, _b = _a.inititalTimestamps, inititalTimestamps = _b === void 0 ? [] : _b;
    var getTime = function (date) {
        return _get(date.split(' '), 1);
    };
    var getDate = function (date) {
        return _get(date.split(' '), 0);
    };
    var _c = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _c.data, fftTimestampDates = _c.fftTimestampDates;
    var _d = useState(inititalTimestamps), timestamps = _d[0], setTimestamps = _d[1];
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var _f = useState(false), isTimeDropdownOpen = _f[0], setIsTimeDropdownOpen = _f[1];
    var readingType = READING_TYPES[chartIdentifier];
    var _g = useState(_get(data.fftTimestamps[readingType.axisId], [
        getDate(_get(fftTimestampDates, chartIdentifier)),
        0,
        'timestamp',
    ])), chosenDate = _g[0], setChosenDate = _g[1];
    var _h = useState(getTime(chosenDate)), selectedTime = _h[0], setSelectedTime = _h[1];
    var axisId = READING_TYPES[chartIdentifier].axisId;
    var availableDates = data.fftTimestamps[axisId];
    var times = _uniq(_get(availableDates, getDate(chosenDate), []).map(function (item) {
        return getTime(item.timestamp);
    }));
    useEffect(function () {
        setTimestamps(inititalTimestamps);
    }, [inititalTimestamps]);
    useEffect(function () {
        setSelectedTime(getTime(chosenDate));
    }, [chosenDate]);
    var onRemoveTimestamp = function (removedTimestamp) {
        setTimestamps(timestamps.filter(function (timestamp) { return timestamp !== removedTimestamp; }));
    };
    var onSelectTimestamp = function (selectedTimestamp) {
        if (timestamps.length === 10) {
            return toast.error('The maximum number of readings has been reached', {
                icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
            });
        }
        if (timestamps.includes(selectedTimestamp)) {
            return toast.error('Timestamp already exist', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
        }
        setTimestamps(_sortBy(__spreadArray(__spreadArray([], timestamps, true), [selectedTimestamp], false)));
    };
    return (_jsxs(Dropdown, { size: "sm", isOpen: isOpen, toggle: function () { }, children: [_jsx(DropdownToggle, { caret: true, outline: true, className: "calendar-dropdown btn-white", onClick: function () { return setIsOpen(!isOpen); }, children: "Select Dates" }), _jsx(DropdownMenu, { className: "p-0 mt-2", children: _jsxs("div", { className: "batch-timestamps-calendar", children: [_jsxs("div", { children: [_jsx(DatePicker, { value: chosenDate, selected: new Date(chosenDate), onChange: function (date) {
                                        return setChosenDate(_get(availableDates, [moment(date).format('YYYY-MM-DD'), 0, 'timestamp']));
                                    }, filterDate: function (date) { return _size(_get(availableDates, moment(date).format('YYYY-MM-DD'))); }, highlightDates: timestamps.map(function (timestamp) { return new Date(timestamp); }), inline: true }), _jsxs("div", { className: "batch-timestamps-calendar-time_and_apply", children: [_jsxs(Dropdown, { size: "sm", isOpen: isTimeDropdownOpen, toggle: function () { return setIsTimeDropdownOpen(!isTimeDropdownOpen); }, children: [_jsx(DropdownToggle, { caret: true, className: "btn-default", disabled: (times || []).length < 2, children: selectedTime || '-' }), _jsx(DropdownMenu, { className: (times || []).length > 8 ? 'custom-scroll' : '', children: (times || []).map(function (time) { return (_jsx(DropdownItem, { onClick: function () { return setSelectedTime(time); }, disabled: false, children: time }, time)); }) })] }), _jsxs(ButtonGroup, { children: [_jsxs(Button, { color: "info", size: "sm", onClick: function () { return onSelectTimestamp("".concat(getDate(chosenDate), " ").concat(selectedTime)); }, disabled: timestamps.includes("".concat(getDate(chosenDate), " ").concat(selectedTime)) || timestamps.length === 10, children: [_jsx(FaArrowRight, {}), " Select"] }), _jsx(Button, { color: "success", size: "sm", onClick: function () {
                                                        onApply(timestamps);
                                                        setIsOpen(false);
                                                    }, children: "Apply" })] })] })] }), _jsxs("div", { children: [_jsx(Button, { className: "batch-timestamps-calendar-close", outline: true, onClick: function () { return setIsOpen(false); }, children: _jsx(FaTimes, {}) }), _jsxs("div", { className: "selected-date-list", children: [timestamps.map(function (timestamp, index) { return (_jsxs("div", { className: "selected-date", children: [_jsx("span", { className: "selected-date-timestamp", children: timestamp }), _jsx("span", { className: "selected-date-remove-btn", onClick: function () { return onRemoveTimestamp(timestamp); }, children: _jsx(FaTimes, {}) })] }, "".concat(timestamp, " ").concat(index))); }), timestamps.length === 10 && (_jsx("div", { className: "text-danger", children: "The maximum number of readings has been reached." }))] })] })] }) })] }));
};
export default BatchTimestampsCalendar;
