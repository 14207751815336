import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var equipmentPhotoAttachFn = function (coreLink, data, equipmentId, installationPointId) {
    return Api.file("".concat(coreLink, "/equipment-photos/attach-image"), data, {
        query: { equipmentId: equipmentId, installationPointId: installationPointId },
    });
};
export var useEquipmentPhotoAttachMutation = function (equipmentId, installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) {
            return equipmentPhotoAttachFn(getCoreUrl(selectedSystemType), data, equipmentId, installationPointId);
        },
    });
};
