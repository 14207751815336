import { jsx as _jsx } from "react/jsx-runtime";
import { find as _find, size as _size } from 'lodash';
import React from 'react';
import { Badge } from 'reactstrap';
var UserSourceBadge = function (_a) {
    var _b, _c;
    var permissions = _a.permissions, _d = _a.fieldName, fieldName = _d === void 0 ? 'name' : _d;
    if (_size(permissions) === 0) {
        return _jsx(Badge, { color: "success", children: "Analytic" });
    }
    var hasAccessToAnalytic = _size(_find(permissions, (_b = {}, _b[fieldName] = 'superAdmin', _b))) > 0 ||
        _size(_find(permissions, (_c = {}, _c[fieldName] = 'analyticAccess', _c))) > 0;
    if (hasAccessToAnalytic) {
        return _jsx(Badge, { color: "success", children: "Analytic" });
    }
    return _jsx(Badge, { color: "warning", children: "Customer" });
};
export default UserSourceBadge;
