import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { REPORT_EDITOR } from '../../../../constants';
import { BAD_DATA_COMPONENT_TYPE_ID } from '../../../../remastered/constants';
import { groupRecommendations } from '../../../features/actionItems';
import '../../../styles/AIPreAi/description.scss';
var SelectedRecommendations = function (_a) {
    var recommendations = _a.recommendations;
    if (recommendations.length === 0) {
        return _jsx(_Fragment, {});
    }
    var recommendationsGroupedByComponentType = groupRecommendations(recommendations);
    return (_jsx(_Fragment, { children: Object.values(recommendationsGroupedByComponentType).map(function (recommendationsGrouped, parentIndex) { return (_jsx(React.Fragment, { children: Object.values(recommendationsGrouped).map(function (recommendations, index) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                return (_jsxs(Row, { className: "g-0", children: [_jsx(Col, { md: 2, className: index === Object.values(recommendationsGrouped).length - 1 ? 'border-bottom' : '', children: index === 0 ? (_jsxs("b", { children: [((_b = (_a = recommendations[0]) === null || _a === void 0 ? void 0 : _a.componentType) === null || _b === void 0 ? void 0 : _b.name) || 'Generic Recommendations', ((_c = recommendations[0]) === null || _c === void 0 ? void 0 : _c.component_type_id) === BAD_DATA_COMPONENT_TYPE_ID ? '' : ':'] })) : (_jsx(_Fragment, {})) }), _jsx(Col, { md: 2, className: "border-bottom", children: ((_d = recommendations[0]) === null || _d === void 0 ? void 0 : _d.component_type_id) === BAD_DATA_COMPONENT_TYPE_ID
                                ? ''
                                : ((_f = (_e = recommendations[0]) === null || _e === void 0 ? void 0 : _e.fault) === null || _f === void 0 ? void 0 : _f.name) || 'All' }), _jsx(Col, { md: 7, className: "border-bottom", children: ((_g = recommendations[0]) === null || _g === void 0 ? void 0 : _g.component_type_id) === BAD_DATA_COMPONENT_TYPE_ID ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: recommendations
                                    .map(function (actionItemRecommendation) { var _a; return (_a = actionItemRecommendation.recommendation) === null || _a === void 0 ? void 0 : _a.name; })
                                    .join(', ') })) }), _jsx(Col, { md: 1, className: "border-bottom pb-2", children: ((_h = recommendations[0]) === null || _h === void 0 ? void 0 : _h.component_type_id) === BAD_DATA_COMPONENT_TYPE_ID ? (_jsx(_Fragment, {})) : (_jsx(Badge, { size: "sm", color: (_j = REPORT_EDITOR.FAULTS_SEVERITY.find(function (severity) { return severity.value === recommendations[0].severity; })) === null || _j === void 0 ? void 0 : _j.color, children: (_k = REPORT_EDITOR.FAULTS_SEVERITY.find(function (severity) { return severity.value === recommendations[0].severity; })) === null || _k === void 0 ? void 0 : _k.label })) })] }, "existing-recommendation-".concat(parentIndex, "-").concat(index)));
            }) }, "existing-recommendation-".concat(parentIndex))); }) }));
};
export default memo(SelectedRecommendations);
