var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { getHotkey } from '../../features/hotkey';
export var useHotkeyEvents = function (callback, deps) {
    var handler = function (event) {
        var _a, _b, _c, _d;
        if (event.repeat ||
            ['INPUT', 'TEXTAREA'].includes((_b = (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : '') ||
            ['jodit-wysiwyg'].includes((_d = (_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.className) !== null && _d !== void 0 ? _d : '')) {
            return;
        }
        return callback(getHotkey(event));
    };
    useEffect(function () {
        document.addEventListener('keydown', handler);
        return function () {
            document.removeEventListener('keydown', handler);
        };
    }, __spreadArray([], deps, true));
};
