import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FaGear } from 'react-icons/fa6';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { onChangeSettingsMode } from '../../features/suggestedLevels';
var SettingsButton = function (_a) {
    var dispatch = _a.dispatch, settingsMode = _a.settingsMode, selectedInstallationPoints = _a.selectedInstallationPoints, setIsVisibleSetSettingsModal = _a.setIsVisibleSetSettingsModal;
    return (_jsxs(_Fragment, { children: [settingsMode ? (_jsxs(_Fragment, { children: [_jsx(Button, { color: "dark", onClick: function () { return setIsVisibleSetSettingsModal(true); }, outline: true, size: "sm", className: "ms-2", id: "set-system-levels-settings", children: "Set Suggested Levels Settings" }), _jsxs(UncontrolledTooltip, { placement: "bottom", target: "set-system-levels-settings", children: ["Set Suggested Levels Settings For", ' ', selectedInstallationPoints && selectedInstallationPoints.length > 0
                                ? "".concat(selectedInstallationPoints.length, " Selected")
                                : 'All Presented', ' ', "Installation Points"] })] })) : (_jsx(_Fragment, {})), _jsx(Button, { color: "dark", onClick: function () { return onChangeSettingsMode(dispatch, settingsMode); }, outline: !settingsMode, size: "sm", className: "ms-2", id: "system-levels-settings", children: _jsx(FaGear, {}) }), _jsx(UncontrolledTooltip, { placement: "bottom", target: "system-levels-settings", children: "Suggested Levels Settings Mode" })] }));
};
export default SettingsButton;
