import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { selectTooltipsHotkey } from '../../../../../store/hotkeys/useChartsHotkeysStore';
import DefaultTooltip from '../../../../DefaultTooltip';
import HotkeyInfo from '../../../../HotkeyInfo';
var ChartDataVisibleToggle = function () {
    var _a = ChartsStore(function (state) { return ({
        setStatesChartsStore: state.setStates,
        chartTooltipsToggle: state.chartTooltipsToggle,
    }); }, shallow), setStatesChartsStore = _a.setStatesChartsStore, chartTooltipsToggle = _a.chartTooltipsToggle;
    var onCursorToggleTooltip = function (chartTooltipsToggle) {
        setStatesChartsStore({
            chartTooltipsToggle: !chartTooltipsToggle,
        });
    };
    var tooltipsHotkey = selectTooltipsHotkey();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return onCursorToggleTooltip(chartTooltipsToggle); }, className: "btn-chart-tools btn-chart-tools-menubtn", color: "customChartTopBarSelect", outline: !chartTooltipsToggle, "data-tooltip-id": "chartTooltipDataVisible", size: "sm", children: "Hide Data" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "chartTooltipDataVisible", clickable: true, children: _jsx(HotkeyInfo, { hotkey: tooltipsHotkey }) })] }));
};
export default memo(ChartDataVisibleToggle);
