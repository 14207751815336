import { useMutation } from '@tanstack/react-query';
import Api from '../../../api/api';
var heatsinkUpdateFn = function (isHeatsink, installationPointId) {
    return Api.post("all-analytics/installation-point/update-heat-sink/".concat(installationPointId), {
        is_heatsink: +isHeatsink,
    });
};
export var useHeatsinkUpdateMutation = function () {
    return useMutation({
        mutationFn: function (_a) {
            var isHeatsink = _a.isHeatsink, installationPointId = _a.installationPointId;
            return heatsinkUpdateFn(isHeatsink, installationPointId);
        },
        onSuccess: function () { },
    });
};
