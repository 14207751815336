var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment/moment';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { setLoader } from '../../helper/table';
export var fetch = function (state, dispatch, endpoint) {
    var config = state.config, sensorIds = state.sensorIds;
    var pagination = config.pagination, sort = config.sort, search = config.search, filtersData = config.filtersData;
    setLoader(state, dispatch, true);
    endpoint({
        query: {
            sensorIds: sensorIds,
            pagination: pagination,
            sort: sort,
            search: search,
            date_from: (filtersData === null || filtersData === void 0 ? void 0 : filtersData.dateRange[0])
                ? moment(filtersData === null || filtersData === void 0 ? void 0 : filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: (filtersData === null || filtersData === void 0 ? void 0 : filtersData.dateRange[1])
                ? moment(filtersData === null || filtersData === void 0 ? void 0 : filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    }).then(function (resp) {
        if (!resp.data && resp.message) {
            setLoader(state, dispatch, false);
            return toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, pagination), { total: resp.total }) }),
            },
        });
    });
};
