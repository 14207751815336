import Api from "../../api";
export default {
    getAlertsTriggeredList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alerts', options);
    },
    getAlertsTriggeredListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alerts/csv', options);
    },
    getAlertsTriggeredByEquipment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/alerts-by-equipment/".concat(id), options);
    },
    getLastViewedAlert: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/last-viewed-alert', options);
    },
    getAlertCountsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alert-counts', options);
    },
    acknowledgeAlerts: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/acknowledge', { data: data });
    },
    refreshAlertCounts: function () { return Api.post('amazon-analytic/alert-counts/refresh-alert-counts'); },
    getAlertCountsListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alert-counts/csv', options);
    },
};
