import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
var DefaultLightBox = function (_a) {
    var photos = _a.photos, index = _a.index, setIndex = _a.setIndex;
    return (_jsx(_Fragment, { children: _jsx(Lightbox, { slides: photos.map(function (path) {
                return {
                    src: path,
                };
            }), open: index >= 0, index: index, close: function () { return setIndex(-1); }, plugins: [Fullscreen, Zoom, Thumbnails, Counter], counter: { container: { style: { top: 0 } } }, carousel: {
                finite: true,
                preload: 5,
            }, animation: {
                fade: 100,
            } }) }));
};
export default memo(DefaultLightBox);
