import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var mlStatisticSaveChartsDataFn = function (coreLink, data) {
    return Api.post("".concat(coreLink, "/ml-statistic/save-charts-data"), data);
};
export var useMlStatisticSaveChartsDataMutation = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) { return mlStatisticSaveChartsDataFn(getCoreUrl(selectedSystemType), data); },
    });
};
