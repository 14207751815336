// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fft-x-axis-labels {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2px;
}
.fft-x-axis-labels .disabled {
  opacity: 0.5;
}
.fft-x-axis-labels .dropdown {
  margin-top: -5px;
}

.fft-units-dropdown-toggle-link {
  font-size: 12px;
  cursor: pointer;
  color: #666666;
}

.fft-x-axis-labels .dropup .dropdown-toggle::after {
  vertical-align: 0.1em;
  cursor: pointer;
}

.fft-harmonics-count {
  margin-left: 10px;
  font-size: 12px;
  color: #666666;
}
.fft-harmonics-count input {
  width: 45px;
  padding: 3px;
  font-size: 14px !important;
  margin-left: 10px;
}
.charts-wrapper.grid .fft-harmonics-count {
  left: 73%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/fftXAxisDropdown.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;AACF;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,eAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,cAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,0BAAA;EACA,iBAAA;AACJ;AAEE;EACE,SAAA;AAAJ","sourcesContent":[".fft-x-axis-labels {\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 2px;\n  & .disabled {\n    opacity: 0.5;\n  }\n  & .dropdown {\n    margin-top: -5px;\n  }\n}\n\n.fft-units-dropdown-toggle-link {\n  font-size: 12px;\n  cursor: pointer;\n  color: #666666;\n}\n\n.fft-x-axis-labels .dropup .dropdown-toggle::after {\n  vertical-align: 0.1em;\n  cursor: pointer;\n}\n\n.fft-harmonics-count {\n  margin-left: 10px;\n  font-size: 12px;\n  color: #666666;\n\n  & input {\n    width: 45px;\n    padding: 3px;\n    font-size: 14px !important;\n    margin-left: 10px;\n  }\n\n  .charts-wrapper.grid & {\n    left: 73%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
