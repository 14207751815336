import { valibotResolver } from '@hookform/resolvers/valibot';
import { custom, forward, minLength, object, string } from 'valibot';
export var actionItemReportFormsSchemaAccept = valibotResolver(object({
    reportProcessingReason: string('Reason should be string', [minLength(1, 'Reason cannot be empty')]),
}, [
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return reportProcessingReason !== '<p><br></p>' && reportProcessingReason !== '<p></p>';
    }, 'Reason cannot be empty'), ['reportProcessingReason']),
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return /^[^\u0400-\u04FF]+$/.test(reportProcessingReason);
    }, 'You cannot use Cyrillic characters in reason'), ['reportProcessingReason']),
]));
export var actionItemReportFormsSchemaClosed = valibotResolver(object({
    reportProcessingReason: string('Reason should be string', [minLength(1, 'Reason cannot be empty')]),
}, [
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return reportProcessingReason !== '<p><br></p>' && reportProcessingReason !== '<p></p>';
    }, 'Reason cannot be empty'), ['reportProcessingReason']),
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return /^[^\u0400-\u04FF]+$/.test(reportProcessingReason);
    }, 'You cannot use Cyrillic characters in reason'), ['reportProcessingReason']),
]));
export var actionItemReportFormsSchemaDecline = valibotResolver(object({
    reportProcessingReason: string('Reason should be string', [minLength(1, 'Reason cannot be empty')]),
}, [
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return reportProcessingReason !== '<p><br></p>' && reportProcessingReason !== '<p></p>';
    }, 'Reason cannot be empty'), ['reportProcessingReason']),
    forward(custom(function (_a) {
        var reportProcessingReason = _a.reportProcessingReason;
        return /^[^\u0400-\u04FF]+$/.test(reportProcessingReason);
    }, 'You cannot use Cyrillic characters in reason'), ['reportProcessingReason']),
]));
