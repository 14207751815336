import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onChangeState = function (selectedState, dispatch) {
    dispatch({
        type: 'setState',
        state: {
            selectedState: selectedState,
        },
    });
};
export var deleteWatchListItem = function (endpoint, id, setConfirmData, callback, setLoader) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want remove watch list item?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            setLoader();
            endpoint(id).then(function (resp) {
                if (resp.success) {
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    callback();
                }
            });
        },
    });
};
