import Api from '../../api';
export default {
    getWhatsNewReleaseList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/whats-new', options);
    },
    getWhatsNewArticles: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/whats-new', options);
    },
    getWhatsNewSearch: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/whats-new', options);
    },
    setViewedNews: function (id, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/whats-new/set-viewed/".concat(id), body, options);
    },
    getCountNotViewedNews: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/whats-new/get-count-not-viewed', options);
    },
};
