import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { FaArrowsAlt } from 'react-icons/fa';
import React from 'react-router-dom';
var SectionPosition = function (_a) {
    var sectionPosition = _a.sectionPosition, isEditSections = _a.isEditSections, sections = _a.sections;
    return (_jsx(_Fragment, { children: sectionPosition.map(function (_a) {
            var title = _a.title;
            return (_jsx(Fragment, { children: isEditSections ? (_jsx("div", { className: "mb-3 default-ai-block cursor-grab", children: _jsx("div", { className: "default-ai-block-header", children: _jsxs("div", { className: "default-ai-block-header-title d-flex justify-content-between w-100 align-items-center", children: [title, " ", _jsx(FaArrowsAlt, {})] }) }) })) : (sections[title]) }, title));
        }) }));
};
export default memo(SectionPosition);
