import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
/*
 *
 * @param: persistProp
 *
 * All fields are saved to localStorage
 * persistProp: true
 *
 * List of keys in an array that are stored in localStorage
 * persistProp: ["key1", "key2"]
 *
 */
export var createStore = function (fn, name, persistProp, version) {
    if (name === void 0) { name = ''; }
    if (persistProp === void 0) { persistProp = false; }
    if (!IS_PROD) {
        if (!persistProp) {
            return create(devtools(fn, { name: name }));
        }
        return create(devtools(persist(fn, {
            partialize: function (state) {
                return Object.fromEntries(Object.entries(state).filter(function (_a) {
                    var key = _a[0];
                    return typeof persistProp == 'boolean' ? !['actions'].includes(key) : persistProp.includes(key);
                }));
            },
            name: name,
            version: version !== null && version !== void 0 ? version : 0,
            storage: createJSONStorage(function () { return localStorage; }),
        }), { name: name }));
    }
    if (!persistProp) {
        return create(fn);
    }
    return create(persist(fn, {
        partialize: function (state) {
            return Object.fromEntries(Object.entries(state).filter(function (_a) {
                var key = _a[0];
                return typeof persistProp == 'boolean' ? !['actions'].includes(key) : persistProp.includes(key);
            }));
        },
        name: name,
        version: version !== null && version !== void 0 ? version : 0,
        storage: createJSONStorage(function () { return localStorage; }),
    }));
};
