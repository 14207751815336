import { createStore } from '../createStore';
var useHotkeysModalStore = createStore(function (set) { return ({
    isVisible: false,
    actions: {
        setIsVisible: function (isVisible) { return set({ isVisible: isVisible }); },
    },
}); }, 'HotkeysModalStore');
export var useHotkeysModalActions = function () {
    return useHotkeysModalStore(function (state) { return state.actions; });
};
export var selectIsVisibleHotkeysModal = function () {
    return useHotkeysModalStore(function (state) { return state.isVisible; });
};
