import Api from "../../api";
export default {
    getActionItemsCommentsStats: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-item/comments-stats', options);
    },
    replaceSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-sensor', options);
    },
    replaceMoteSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-mote-sensor', options);
    },
    replaceNodeBatteries: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-node-batteries', options);
    },
    replaceMoteBattery: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-mote-battery', options);
    },
    removeSeverityIncreased: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/remove-severity-increase', body, options);
    },
};
