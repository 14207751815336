import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap';
var WatchListRow = function (_a) {
    var client = _a.client, statistic = _a.statistic, isActiveWatchListStatistic = _a.isActiveWatchListStatistic, setIsActiveWatchListStatistic = _a.setIsActiveWatchListStatistic;
    if (!statistic) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Col, { md: 4, className: "card-statistic-title", children: _jsx("span", { children: "Watch List:" }) }), _jsxs(Col, { md: 5, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "watch-list-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "watch-list-total-".concat(client.customer_id), color: _get(statistic, 'watchList.count', 0) > 0 ? 'info' : 'none', children: _get(statistic, 'watchList.count', 0) })] }), +_get(statistic, 'watchList.count') ? (_jsx(Col, { md: 3, className: "card-statistic-actions", children: _jsx(Badge, { onClick: function () { return setIsActiveWatchListStatistic(!isActiveWatchListStatistic); }, color: "primary", id: "show-watch-list-statistic".concat(client.customer_id), children: _jsx("i", { className: "fa ".concat(isActiveWatchListStatistic ? 'fa-arrow-up' : 'fa-arrow-down') }) }) })) : (_jsx(Col, { md: 3, className: "card-statistic-actions", children: _jsx(Badge, { children: _jsx("i", { className: "fa fa-arrow-down" }) }) }))] }), isActiveWatchListStatistic ? (_jsx(Row, { children: _jsx(Col, { md: 12, className: "card-statistic-table", children: _jsx("table", { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsxs("td", { children: [_jsx("span", { children: "Follow Up" }), _jsx("span", { children: _get(statistic, 'watchList.followUp', 0) || 0 })] }) }), _jsx("tr", { children: _jsxs("td", { children: [_jsx("span", { children: "Potential Problem" }), _jsx("span", { children: _get(statistic, 'watchList.potentialProblem', 0) || 0 })] }) })] }) }) }) })) : (_jsx(_Fragment, {}))] }));
};
export default WatchListRow;
