import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { MdOutlineTranslate } from 'react-icons/md';
import DefaultTooltip from '../../DefaultTooltip';
import './index.scss';
var TranslatesToggleBtn = function (_a) {
    var translates = _a.translates, fieldNameId = _a.fieldNameId, fieldName = _a.fieldName, _b = _a.buttonStyle, buttonStyle = _b === void 0 ? {} : _b, isShowTranslates = _a.isShowTranslates, setIsShowTranslates = _a.setIsShowTranslates;
    var englishTranslate = _get(translates, ['en', fieldName]);
    if (!englishTranslate || englishTranslate.sourceLanguage === englishTranslate.targetLanguage) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "translate-button ".concat(isShowTranslates ? 'active' : ''), "data-tooltip-id": fieldNameId, style: buttonStyle, onClick: function () { return setIsShowTranslates(function (prev) { return !prev; }); }, children: _jsx(MdOutlineTranslate, {}) }), _jsxs(DefaultTooltip, { place: "left", id: fieldNameId, children: ["Show ", isShowTranslates ? 'original' : 'translation'] })] }));
};
export default memo(TranslatesToggleBtn);
