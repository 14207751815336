import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import AllStatisticTab from '../../components/watchersStatistic/AllStatisticTab';
import CostAvoidedReportTab from '../../components/watchersStatistic/CostAvoidedReportTab';
import CostAvoidedTab from '../../components/watchersStatistic/CostAvoidedTab';
import CountAiTab from '../../components/watchersStatistic/CountAiTab';
import CountAlarmLevelTab from '../../components/watchersStatistic/CountAlarmLevelTab';
import KPITab from '../../components/watchersStatistic/KPITab';
import PreAiStatisticTab from '../../components/watchersStatistic/PreAiStatisticTab';
import TotalSystemMetricsTab from '../../components/watchersStatistic/TotalSystemMetricsTab';
export var tabs = [
    {
        title: 'Cost Avoided Statistic',
        component: function () { return _jsx(CostAvoidedTab, {}); },
    },
    {
        title: 'All AI Statistic',
        component: function () { return _jsx(AllStatisticTab, {}); },
    },
    {
        title: 'Pre AI Statistic',
        component: function () { return _jsx(PreAiStatisticTab, {}); },
    },
    {
        title: 'Count AI/Pre-AI',
        component: function () { return _jsx(CountAiTab, {}); },
    },
    {
        title: 'Cost Avoided Report',
        component: function () { return _jsx(CostAvoidedReportTab, {}); },
    },
    {
        title: 'Count of alarm levels',
        component: function () { return _jsx(CountAlarmLevelTab, {}); },
    },
    {
        title: 'Total System Metrics',
        component: function (queryAdditionProperties) { return (_jsx(TotalSystemMetricsTab, { queryAdditionProperties: queryAdditionProperties || {} })); },
    },
    {
        title: 'KPI’s',
        component: function (queryAdditionProperties) { return (_jsx(KPITab, { queryAdditionProperties: queryAdditionProperties || {} })); },
    },
];
export var CALENDAR_LAST_BUTTONS = function (dateRange) {
    return [
        {
            label: '7 days',
            id: 'last7',
            value: [new Date(moment().add(-6, 'days').format('YYYY-MM-DD')), new Date()],
            isActive: moment().add(-6, 'days').format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: '14 days',
            id: 'last14',
            value: [new Date(moment().add(-13, 'days').format('YYYY-MM-DD')), new Date()],
            isActive: moment().add(-13, 'days').format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: '30 days',
            id: 'last30',
            value: [new Date(moment().add(-29, 'days').format('YYYY-MM-DD')), new Date()],
            isActive: moment().add(-29, 'days').format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: '90 days',
            id: 'last90',
            value: [new Date(moment().add(-89, 'days').format('YYYY-MM-DD')), new Date()],
            isActive: moment().add(-89, 'days').format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: 'Week',
            id: 'lastWeek',
            value: [
                new Date(moment().isoWeekday(-6).format('YYYY-MM-DD')),
                new Date(moment().isoWeekday(0).format('YYYY-MM-DD')),
            ],
            isActive: moment().isoWeekday(-6).format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment().isoWeekday(0).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: 'Month',
            id: 'lastMonth',
            value: [
                new Date(moment()
                    .month(+moment().format('M') - 2)
                    .format('YYYY-MM-01')),
                new Date(moment()
                    .month(+moment().format('M') - 2)
                    .format("YYYY-MM-".concat(moment()
                    .month(+moment().format('M') - 2)
                    .daysInMonth()))),
            ],
            isActive: moment()
                .month(+moment().format('M') - 2)
                .format('YYYY-MM-01') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment()
                    .month(+moment().format('M') - 2)
                    .format("YYYY-MM-".concat(moment()
                    .month(+moment().format('M') - 2)
                    .daysInMonth())) === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
    ];
};
export var CALENDAR_CURRENT_BUTTONS = function (dateRange) {
    return [
        {
            label: 'Week',
            id: 'currentWeek',
            value: [new Date(moment().isoWeekday(1).format('YYYY-MM-DD')), new Date()],
            isActive: moment().isoWeekday(1).format('YYYY-MM-DD') === moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
        {
            label: 'Month',
            id: 'currentMonth',
            value: [new Date(moment().month(moment().format('MMMM')).format('YYYY-MM-01')), new Date()],
            isActive: moment().month(moment().format('MMMM')).format('YYYY-MM-01') ===
                moment(dateRange[0]).format('YYYY-MM-DD') &&
                moment(new Date()).format('YYYY-MM-DD') === moment(dateRange[1]).format('YYYY-MM-DD'),
        },
    ];
};
