import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button, ButtonGroup } from 'reactstrap';
import User from '../../helper/user';
export var formatOptionLabel = function (_a, _b, onEdit, onDelete) {
    var value = _a.value, label = _a.label;
    var context = _b.context;
    if (!User.can('manageReportEditor')) {
        return _jsx("span", { className: "fz-12", children: label });
    }
    if (context !== 'menu' || !value || label === "Create \"".concat(value, "\"")) {
        return _jsx("span", { className: "fz-12", children: label });
    }
    return (_jsxs("div", { className: "d-flex justify-content-between", children: [_jsx("div", { className: "fz-12", children: label }), _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "warning", onClick: function (ev) {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onEdit();
                        }, children: _jsx(FaEdit, {}) }), _jsx(Button, { size: "sm", color: "danger", onClick: function (ev) {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onDelete();
                        }, children: _jsx(FaTrash, {}) })] })] }));
};
