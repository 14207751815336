// Valid ranges for the threshold amplitude are 78.1 mG to 1G where each tick represent 1/256 Gs. (ie the valid range is 20-256 ticks)
import { compare } from 'compare-versions';
var generateAmplitudeArray = function () {
    var amplitudesArray = [];
    for (var i = 20; i <= 256; i++) {
        amplitudesArray.push({ key: i, value: i / 256 });
    }
    return amplitudesArray;
};
export var amplitudesArray = generateAmplitudeArray();
export var frequenciesArray = [
    { key: 0, value: 0.78 },
    { key: 1, value: 1.56 },
    { key: 2, value: 3.12 },
    { key: 3, value: 6.25 },
    { key: 4, value: 12.5 },
    { key: 5, value: 25 },
    { key: 6, value: 50 },
];
export var checkWosSupport = function (restrictions, sensorVersionType, sensorVersion) {
    if (!restrictions) {
        return false;
    }
    var wosSupport;
    var wosSensorTypes = restrictions.wos_sensor_types;
    wosSupport = wosSensorTypes.includes(+sensorVersionType);
    if (wosSupport) {
        wosSupport = compare(sensorVersion, restrictions.wos_sensor_version, '>=');
    }
    return wosSupport;
};
