import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useMainSeriesLoaded = function (state, dispatch, chartIdentifier, loader, readings) {
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var mainSeriesLoaded = state.mainSeriesLoaded;
    useEffect(function () {
        if (_get(readings, chartIdentifier, null) === null && !mainSeriesLoaded) {
            dispatch({
                type: 'setState',
                state: {
                    mainSeriesLoaded: true,
                },
            });
        }
        if (!loader) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                mainSeriesLoaded: false,
            },
        });
    }, [loader]);
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                mainSeriesLoaded: false,
            },
        });
    }, [installationPoint.id]);
};
