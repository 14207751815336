import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useAnalystNotesCommentsByReadingTypeQuery, } from '../../../../services/charts/useAnalystNotesCommnetsByReadingTypeQuery';
import { selectIsVisibleAnalystNotes } from '../../../../store/suggestedLevel/useSuggestedLevelModalStore';
import './index.scss';
var AnalystNotesContent = function (_a) {
    var readingType = _a.readingType, installationPoint = _a.installationPoint;
    var isVisible = selectIsVisibleAnalystNotes();
    var data = useAnalystNotesCommentsByReadingTypeQuery({
        installationPointIds: [installationPoint.id],
        readingTypeIds: [readingType],
    }).data;
    if (!isVisible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "suggestedLevels-analyst-notes-content d-flex flex-column", children: ["Analyst Notes:", data.map(function (_a) {
                var id = _a.id, user_name = _a.user_name, comment = _a.comment;
                return (_jsxs("div", { className: "d-flex gap-2", children: [_jsxs("b", { children: [user_name, ":"] }), _jsx("span", { dangerouslySetInnerHTML: { __html: comment } })] }, id));
            })] }));
};
export default memo(AnalystNotesContent);
