var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, head as _head } from "lodash";
import React, { useState } from "react";
import { FaCheckCircle, FaInfoCircle, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip, } from "reactstrap";
import Endpoints from "../../../api/endpoints/endpoints";
import InputRange from "../../../shared/components/InputRange";
import "../../../shared/modals/machineInfo/MachineInfo.scss";
var HighPassForm = function (_a) {
    var highPassFormData = _a.highPassFormData, callback = _a.callback;
    var visible = highPassFormData.visible, onCancel = highPassFormData.onCancel, equipment = highPassFormData.equipment, selectedCustomers = highPassFormData.selectedCustomers, selectedLocations = highPassFormData.selectedLocations;
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var _b = useState({
        equipment_id: equipment === null || equipment === void 0 ? void 0 : equipment.id,
        selected_customer_ids: selectedCustomers,
        selected_location_id: _head(selectedLocations),
        high_pass: _get(equipment, 'installationPoints.0.settings.high_pass', 0),
    }), data = _b[0], setData = _b[1];
    var onConfirm = function () {
        Endpoints.remastered.saveHighPassBatch(data).then(function (resp) {
            if (resp.success) {
                onCancel();
                callback();
                return toast.success(resp.message || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
            }
            return toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    var maxHighPass = 400;
    return (_jsxs(Modal, { className: "modal-response", size: "lg", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["RMS HIGH-PASS filter for ", (equipment === null || equipment === void 0 ? void 0 : equipment.id) ? " equipment - ".concat(equipment.name) : ' all equipment'] }), _jsx(ModalBody, { className: "fz-13", children: _jsx(Row, { children: _jsx(Col, { md: 12, className: "border-bottom pb-2", children: _jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs(Label, { for: "high_pass", children: [_jsx("span", { id: "high_pass_notification", className: "me-2 cursor-pointer", children: _jsx(FaInfoCircle, {}) }), _jsx(UncontrolledTooltip, { target: "high_pass_notification", children: "When setting a high-pass filter for tethered sensor, it will apply to all sensors associated with this node" }), _jsx("b", { children: "RMS High-Pass Filter:" })] }) }), _jsxs(Col, { md: 4, children: [_jsx(Input, { bsSize: "sm", type: "number", name: 'high_pass', id: "title", value: data.high_pass, step: 1, min: 1, max: maxHighPass, onChange: function (ev) {
                                                    var value = +ev.target.value;
                                                    if (value <= maxHighPass && value >= 1) {
                                                        setData(__assign(__assign({}, data), { high_pass: value }));
                                                    }
                                                } }), _jsx("div", { className: "mt-1", children: _jsx(InputRange, { maxValue: maxHighPass, minValue: 1, value: +data.high_pass, onChange: function (value) {
                                                        if (+value <= maxHighPass && +value >= 1) {
                                                            setData(__assign(__assign({}, data), { high_pass: +value }));
                                                        }
                                                    } }) })] })] }) }) }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onConfirm, children: "Save" })] }) })] }));
};
export default HighPassForm;
