import { get as _get, isEqual as _isEqual } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { FFT_ALERT_TYPES } from '../../../../constants';
import { usePrevious } from '../../../../customHooks';
import { getEnvelopeSeries } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/EnvelopeAlarms';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useEnvelope = function (state, dispatch, chartRef, chartIdentifier) {
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _a.installationPoint, readingTypes = _a.readingTypes;
    var _b = FftChartsStore(function (state) { return ({
        loader: state.loader,
        fftTimestampDates: state.fftTimestampDates,
        isChangedCalendar: state.isChangedCalendar,
        fftSelectedDate: state.fftSelectedDate,
        fftAlerts: state.fftAlerts,
    }); }, shallow), loader = _b.loader, fftTimestampDates = _b.fftTimestampDates, isChangedCalendar = _b.isChangedCalendar, fftSelectedDate = _b.fftSelectedDate, fftAlerts = _b.fftAlerts;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var options = state.options, isVisibleEnvelope = state.isVisibleEnvelope;
    useEffect(function () {
        if (loader || !isVisibleEnvelope) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                isVisibleEnvelope: false,
            },
        });
    }, [installationPoint.settings, fftSelectedDate]);
    useEffect(function () {
        if (loader) {
            return;
        }
        if (isChangedCalendar) {
            dispatch({
                type: 'setState',
                state: {
                    isVisibleEnvelope: false,
                },
            });
        }
    }, [fftTimestampDates[chartIdentifier]]);
    var prevIsVisibleEnvelope = usePrevious(isVisibleEnvelope);
    var prevEnvelopes = usePrevious(_get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.ENVELOPE; }));
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        var currentEnvelopes = _get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.ENVELOPE; });
        var visibilityIsChanged = !!prevIsVisibleEnvelope !== !!isVisibleEnvelope;
        var envelopesIsChanged = !_isEqual(prevEnvelopes, currentEnvelopes);
        if (!visibilityIsChanged && !envelopesIsChanged) {
            return;
        }
        chartRef.current.chart.series.forEach(function (series) {
            if (series.userOptions.seriesType === 'envelope') {
                series.update({ visible: false }, false);
            }
        });
        if (isVisibleEnvelope) {
            (getEnvelopeSeries(chartIdentifier, _get(fftAlerts, chartIdentifier, []), readingTypes, measure, personalSettingMeasure) || []).forEach(function (series) {
                if (!chartRef.current.chart.get(series.id)) {
                    chartRef.current.chart.addSeries(series, false);
                    return;
                }
                chartRef.current.chart.get(series.id).update({ visible: true }, false);
            });
        }
        chartRef.current.chart.redraw(false);
    }, [isVisibleEnvelope, fftAlerts]);
};
