// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotkey-info {
  display: flex;
  align-items: center;
  color: #d37c12;
  gap: 1px;
}
.dark-layout .hotkey-info {
  color: #b86703;
}
.hotkey-info span {
  border-radius: 4px;
  padding: 2px 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  border: 1px solid #d37c12;
}
.dark-layout .hotkey-info span {
  border: 1px solid #b86703;
}
.hotkey-info .hotkey-edit {
  margin-left: 4px;
  cursor: pointer;
  border: 1px solid #758aff;
  border-radius: 4px;
  padding: 1px;
  height: 19px;
  width: 19px;
  transition: 0.3s color, 0.3s background;
  color: #758aff;
}
.hotkey-info .hotkey-edit:hover {
  color: #fff;
  background: #758aff;
}
.hotkey-info .hotkey-edit.active {
  color: #fff;
  background: #758aff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/HotkeyInfo/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,QAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AAEJ;AADI;EACE,yBAAA;AAGN;AAAE;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,uCACE;EAEF,cAAA;AAAJ;AACI;EACE,WAAA;EACA,mBAAA;AACN;AACI;EACE,WAAA;EACA,mBAAA;AACN","sourcesContent":[".hotkey-info {\n  display: flex;\n  align-items: center;\n  color: #d37c12;\n  gap: 1px;\n  .dark-layout & {\n    color: #b86703;\n  }\n  & span {\n    border-radius: 4px;\n    padding: 2px 5px;\n    text-transform: uppercase;\n    font-size: 12px;\n    font-weight: 700;\n    line-height: 13px;\n    border: 1px solid #d37c12;\n    .dark-layout & {\n      border: 1px solid #b86703;\n    }\n  }\n  & .hotkey-edit {\n    margin-left: 4px;\n    cursor: pointer;\n    border: 1px solid #758aff;\n    border-radius: 4px;\n    padding: 1px;\n    height: 19px;\n    width: 19px;\n    transition:\n      0.3s color,\n      0.3s background;\n    color: #758aff;\n    &:hover {\n      color: #fff;\n      background: #758aff;\n    }\n    &.active {\n      color: #fff;\n      background: #758aff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
