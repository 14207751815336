import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { getHost } from '../../../shared/helper/customer';
import { actionItemKey } from './useActionItemQuery';
var questionFn = function (data, storage) {
    return Api.post('amazon-analytic/action-item/set-state-question', data, { host: getHost(storage) });
};
export var useQuestionMutation = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return questionFn(data, storage); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: actionItemKey(actionItemId) });
        },
    });
};
