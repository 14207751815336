// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-Structure .structure-firstLayer-badge {
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  background: #1857c9;
  border-radius: 9px;
  line-height: 16px;
  min-width: 17px;
  min-height: 17px;
  text-align: center;
  position: absolute;
  top: -3px;
  right: -9px;
  box-shadow: 0 0 3px -1px #000;
  padding: 0 4px;
}
.page-Structure .structure-firstLayer-badge.empty {
  background: #c50808;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/structure/structure.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,6BAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[".page-Structure {\n  & .structure-firstLayer-badge {\n    font-size: 10px;\n    color: #fff;\n    font-weight: 600;\n    background: #1857c9;\n    border-radius: 9px;\n    line-height: 16px;\n    min-width: 17px;\n    min-height: 17px;\n    text-align: center;\n    position: absolute;\n    top: -3px;\n    right: -9px;\n    box-shadow: 0 0 3px -1px #000;\n    padding: 0 4px;\n  }\n  & .structure-firstLayer-badge.empty {\n    background: #c50808;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
