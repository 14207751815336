import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { ACTION_LIST } from '../../../../constants';
import DefaultTooltip from '../../DefaultTooltip';
import './index.scss';
var options = [
    {
        label: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD],
        value: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
    },
    {
        label: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_HIGH],
        value: ACTION_LIST.ACTION_ITEM_URGENCY_HIGH,
    },
    {
        label: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL],
        value: ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL,
    },
];
var UrgencySelect = function (_a) {
    var control = _a.control;
    var urgency = useWatch({
        control: control,
        name: 'urgency',
    });
    var field = useController({
        control: control,
        name: 'urgency',
    }).field;
    return (_jsxs("div", { "data-tooltip-id": "urgency_select", className: "urgency-select ".concat(ACTION_LIST.URGENCY_COLORS[urgency]), children: [_jsx(Select, { classNamePrefix: "customSelectStyleUrgency", placeholder: 'Urgency', isSearchable: false, value: {
                    label: ACTION_LIST.URGENCY_NAMES[urgency],
                    value: urgency,
                }, onChange: function (_a) {
                    var value = _a.value;
                    return field.onChange(value);
                }, options: options }), _jsx(DefaultTooltip, { id: "urgency_select", place: "left", children: "Urgency" })] }));
};
export default memo(UrgencySelect);
