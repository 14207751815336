// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gathering-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  cursor: pointer;
  margin-right: 15px;
  font-weight: 400;
  width: 45px;
}
.gathering-toggle input {
  cursor: pointer;
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.gathering-toggle span {
  margin-left: 22px !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/activityLogHeader/components/GatheringDataToggle/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;EACA,yBAAA;EACA,wBAAA;AAEJ;AAAE;EACE,4BAAA;AAEJ","sourcesContent":[".gathering-toggle {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0 !important;\n  cursor: pointer;\n  margin-right: 15px;\n  font-weight: 400;\n  width: 45px;\n  & input {\n    cursor: pointer;\n    margin-left: 0 !important;\n    margin-top: 0 !important;\n  }\n  & span {\n    margin-left: 22px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
