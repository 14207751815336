var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { find as _find, first as _first, get as _get } from 'lodash';
import React, { memo, useContext, useEffect, useState, } from 'react';
import Select from 'react-select';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { fetchFacilitiesList, fetchFacilityAreaTree, fetchFacilityZoneTree, onChangeArea, onChangeFacility, onChangeZone, } from '../../../layout/components/Header/features';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { FacilitiesListStore } from '../../store/FacilitiesListStore';
var SelectsGroup = function (_a) {
    var selectedFacility = _a.selectedFacility, selectedZone = _a.selectedZone, selectedArea = _a.selectedArea, layoutDispatch = _a.layoutDispatch;
    var trackEvent = useMatomo().trackEvent;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setFacilitiesListFacilitiesListStore = FacilitiesListStore(function (state) { return state.setFacilitiesList; });
    var defaultZone = { id: 0, label: 'All zones', value: '' };
    var defaultArea = { id: 0, label: 'All areas', value: '' };
    var _b = useState(false), fetchFacility = _b[0], setFetchFacility = _b[1];
    var _c = useState(false), fetchZone = _c[0], setFetchZone = _c[1];
    var _d = useState([]), facilityList = _d[0], setFacilityList = _d[1];
    var _e = useState(defaultZone), selected_zone = _e[0], setZone = _e[1];
    var _f = useState([]), zones = _f[0], setZones = _f[1];
    var _g = useState(defaultArea), selected_area = _g[0], setArea = _g[1];
    var _h = useState([]), areas = _h[0], setAreas = _h[1];
    var _j = useState(null), showAmazon = _j[0], setShowAmazon = _j[1];
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
            return;
        }
        fetchFacilitiesList(setFetchFacility, setFetchZone, setFacilityList, setZones, setAreas, setShowAmazon, setFacilitiesListFacilitiesListStore);
    }, [selectedSystemType]);
    useEffect(function () {
        if (selectedZone && fetchZone) {
            setShowAmazon('area');
            fetchFacilityAreaTree(selectedFacility, selectedZone, function (resp) {
                setAreas((resp.areas || []).map(function (el) {
                    return {
                        label: el,
                        value: el,
                    };
                }));
            });
            return;
        }
        setShowAmazon('zone');
    }, [selectedZone, fetchZone]);
    useEffect(function () {
        if (selectedFacility && fetchFacility) {
            setShowAmazon('zone');
            fetchFacilityZoneTree(selectedFacility, function (resp) {
                setZones((resp.zones || []).map(function (el) {
                    return {
                        label: el,
                        value: el,
                    };
                }));
            }, setShowAmazon, setFetchZone, setZones, setAreas);
            return;
        }
        setShowAmazon('facility');
    }, [selectedFacility, fetchFacility]);
    var selectedFacilityInfo = selectedFacility ? _find(facilityList, { name: selectedFacility }) : null;
    if (!selectedFacilityInfo && facilityList.length === 1) {
        selectedFacilityInfo = _first(facilityList);
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "d-flex", children: [_jsx("div", { className: "me-3 ".concat(showAmazon === 'area' ? '' : 'hidden'), children: _jsx(Select, { name: "areaSelector", classNamePrefix: "customSelectStyleDefault", placeholder: 'Areas tree', onChange: function (item) {
                            return onChangeArea(layoutDispatch, item, setArea, trackEvent);
                        }, value: selectedArea
                            ? _get(areas.filter(function (area) { return area.value === selectedArea; }), 0, selected_area)
                            : selected_area, options: __spreadArray([
                            defaultArea
                        ], areas.map(function (area) { return ({ label: area.label, id: area.value, value: area.value }); }), true) }) }), _jsx("div", { className: "me-3 ".concat(showAmazon === 'zone' || showAmazon === 'area' ? '' : 'hidden'), children: _jsx(Select, { name: "zoneSelector", classNamePrefix: "customSelectStyleDefault", placeholder: 'Zones tree', onChange: function (item) {
                            return onChangeZone(layoutDispatch, item, setZone, setArea, defaultArea, trackEvent);
                        }, value: selectedZone
                            ? _get(zones.filter(function (zone) { return zone.value === selectedZone; }), 0, selected_zone)
                            : selected_zone, options: __spreadArray([
                            defaultZone
                        ], zones.map(function (zone) { return ({ label: zone.label, id: zone.value, value: zone.value }); }), true) }) }), _jsx(Select, { name: "facilitySelector", classNamePrefix: "customSelectStyleDefault", placeholder: 'All facility', value: selectedFacilityInfo
                        ? {
                            label: selectedFacilityInfo.name,
                            id: selectedFacilityInfo.name,
                            value: selectedFacilityInfo.name,
                        }
                        : null, onChange: function (item) {
                        return onChangeFacility(layoutDispatch, item, setZone, defaultZone, setArea, defaultArea, trackEvent);
                    }, options: __spreadArray([
                        { label: 'All facility', value: '' }
                    ], facilityList.map(function (facility) { return ({
                        label: facility.name,
                        id: facility.name,
                        value: facility.name,
                    }); }), true) })] }) }));
};
export default memo(SelectsGroup);
