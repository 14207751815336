import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var facilitiesKey = function (customerId) { return ['facilities', customerId]; };
var facilitiesFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get('all-analytics/customer/facilities', {
        query: {
            selected_customer_ids: queryKey[1] ? [queryKey[1]] : [],
        },
    });
};
export var useFacilitiesQuery = function (_a) {
    var customerId = _a.customerId;
    return useQuery({
        queryKey: facilitiesKey(customerId),
        queryFn: facilitiesFn,
        select: function (_a) {
            var data = _a.data;
            return {
                facilities: data !== null && data !== void 0 ? data : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!customerId,
    });
};
