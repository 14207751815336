// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-ai-stats-data {
  min-width: 45px;
}

.filter-tooltip .dark-layout {
  background-color: #591717 !important;
  border: 1px solid #b01919 !important;
  color: #f1f1f1 !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/preActionItem/Filter/Filter.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAGE;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAAJ","sourcesContent":[".pre-ai-stats-data {\n  min-width: 45px;\n}\n\n.filter-tooltip {\n  .dark-layout {\n    background-color: #591717 !important;\n    border: 1px solid #b01919 !important;\n    color: #f1f1f1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
