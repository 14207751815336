import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { filter as _filter, get as _get } from 'lodash';
import React from 'react';
import { FaCog, FaPencilAlt, FaUser } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import { Badge, Button, ButtonGroup, Input, Table } from 'reactstrap';
import { READING_TYPE_TEMPERATURE } from '../../../../../../constants';
import { getAxisName } from '../../../../../../widgets/sensor';
import { getCalculationAlertLevelsSettings, onChangeToggleCheckedInstallPoint, } from '../../../../../features/suggestedLevels';
import SuggestedLevelsUnitData from '../SuggestedLevelsUnitData/SuggestedLevelsUnitData';
export var SuggestedLevelsNestedContent = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, certain = _a.certain, stateSuggestedLevelFormulaModal = _a.stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal = _a.setStateSuggestedLevelFormulaModal, dispatchSuggestedLevelModal = _a.dispatchSuggestedLevelModal, fetchCallback = _a.fetchCallback, installationPoint = _a.installationPoint, equipment = _a.equipment, readingTypes = _a.readingTypes, units = _a.units, onUpdateButtonClick = _a.onUpdateButtonClick, onEditFormulaButtonClick = _a.onEditFormulaButtonClick;
    var systemLevels = _filter(installationPoint.systemAlertConditions, function (systemAlertCondition) {
        return readingTypes.includes(+systemAlertCondition.reading_type_id) &&
            systemAlertCondition.equipment_type === 'sensor';
    }), alertConditions = _filter(installationPoint.alertCondition, function (alertCondition) {
        return readingTypes.includes(+alertCondition.reading_type_id) && alertCondition.equipment_type === 'sensor';
    }), alertConditionCaution = _filter(alertConditions, function (alertCondition) {
        return _get(alertCondition, 'alertLevel.name', '').toLowerCase() === 'caution';
    }), alertConditionWarning = _filter(alertConditions, function (alertCondition) {
        return _get(alertCondition, 'alertLevel.name', '').toLowerCase() === 'warning';
    }), suggestedCautionLevels = _filter(systemLevels, function (systemLevel) {
        return _get(systemLevel, 'alertLevel.name', '').toLowerCase() === 'caution';
    }), suggestedWarningLevels = _filter(systemLevels, function (systemLevel) {
        return _get(systemLevel, 'alertLevel.name', '').toLowerCase() === 'warning';
    }), precision = readingTypes.includes(READING_TYPE_TEMPERATURE) ? 1 : 4;
    var alertLevelsSettings = getCalculationAlertLevelsSettings(installationPoint, readingTypes);
    return (_jsx(Table, { responsive: "sm", borderless: true, size: "sm", className: "nested-table", children: _jsxs("tbody", { children: [_jsxs("tr", { children: [certain && certain.is_visible ? (_jsx("td", { children: _jsx(Input, { type: "checkbox", readOnly: true, checked: certain.installPointIds.includes(+installationPoint.id), onClick: function () {
                                    return onChangeToggleCheckedInstallPoint(state, dispatch, certain.type, +installationPoint.id, equipment.id);
                                } }) })) : (_jsx("td", {})), _jsx("td", { className: "text-center", children: _jsx(Badge, { pill: true, color: alertConditionCaution.length ? 'warning' : 'secondary', children: "Caution" }) }), _jsx("td", { className: "text-center", children: _jsx(Badge, { pill: true, color: alertConditionWarning.length ? 'danger' : 'secondary', children: "Warning" }) }), _jsx("td", { children: _jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: certain && !certain.is_visible ? (_jsx(Button, { color: "info", size: "sm", onClick: function () {
                                                var _a, _b;
                                                return onUpdateButtonClick(dispatchSuggestedLevelModal, fetchCallback, installationPoint, equipment, units, readingTypes, ((_b = (_a = installationPoint.sensor) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.serial) || '---');
                                            }, children: _jsx(FaPencilAlt, {}) })) : (_jsx(_Fragment, {})) }), _jsx(ButtonGroup, { children: stateSuggestedLevelFormulaModal && setStateSuggestedLevelFormulaModal ? (_jsx(Button, { color: "primary", size: "sm", onClick: function () {
                                                return onEditFormulaButtonClick(stateSuggestedLevelFormulaModal, setStateSuggestedLevelFormulaModal, { installationPoint: installationPoint, equipment: equipment, units: units, readingTypes: readingTypes });
                                            }, children: _jsx(GoGear, {}) })) : (_jsx(_Fragment, {})) })] }) })] }), _jsxs("tr", { className: "border-bottom", children: [_jsxs("td", { className: "fw-bold text-nowrap", style: { verticalAlign: 'middle' }, children: [_jsx(FaCog, { id: "FaCog" }), "\u00A0/\u00A0", _jsx(FaUser, { id: "FaUser" })] }), _jsx("td", { className: "text-center", children: _jsx(SuggestedLevelsUnitData, { readingTypes: readingTypes, suggestedLevels: suggestedCautionLevels, alertLevelsSettings: alertLevelsSettings, installationPoint: installationPoint, precision: precision, units: units, type: "caution" }) }), _jsx("td", { className: "text-center", children: _jsx(SuggestedLevelsUnitData, { readingTypes: readingTypes, suggestedLevels: suggestedWarningLevels, alertLevelsSettings: alertLevelsSettings, installationPoint: installationPoint, precision: precision, units: units, type: "warning" }) }), _jsx("td", {})] }), _jsxs("tr", { children: [_jsx("td", { className: "fw-bold text-nowrap", style: { fontSize: '10px' }, children: "STD DEV" }), _jsxs("td", { colSpan: 3, className: "text-center text-nowrap", children: [suggestedCautionLevels.map(function (cautionLevel) { return (_jsx("span", { className: "me-2", children: _jsxs("span", { children: [cautionLevel.stddev_value
                                                ? parseFloat(String(cautionLevel.stddev_value)).toFixed(precision)
                                                : 'Not set', suggestedCautionLevels.length > 1 && (_jsxs("b", { className: "ms-1", children: ["(", getAxisName(cautionLevel.reading_type_id), ")"] }))] }) }, "suggested_value_".concat(installationPoint.id, "_").concat(cautionLevel.reading_type_id, "_").concat(cautionLevel.alert_level_id))); }), suggestedCautionLevels.length !== 0 ? (_jsx("span", { className: "units-sub", children: units })) : ('No suggested values')] })] })] }) }));
};
