var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { memo } from 'react';
export var PieChart = function (_a) {
    var _b = _a.options, options = _b === void 0 ? {} : _b;
    var chartOptions = {
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
            },
        },
        lang: {
            noData: 'No data to display',
        },
        tooltip: {
            pointFormat: '<b>$ {point.y}</b>',
        },
        credits: {
            enabled: false,
        },
    };
    return (_jsx(HighchartsReact, { highcharts: Highcharts, options: __assign(__assign({}, chartOptions), options) }));
};
export default memo(PieChart);
