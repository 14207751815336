import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { reportEditorOptionsKey } from './useReportEditorOptionsQuery';
var reportEditorOptionCreateFn = function (coreLink, componentType, subType, name, entityName) { return Api.post("".concat(coreLink, "/report-editor/create-item"), { entityName: entityName, name: name, componentType: componentType, subType: subType }); };
export var useReportEditorOptionCreateMutation = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var componentType = _a.componentType, subType = _a.subType, name = _a.name, entityName = _a.entityName;
            return reportEditorOptionCreateFn(getCoreUrl(selectedSystemType), componentType, subType, name, entityName);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: reportEditorOptionsKey() });
        },
    });
};
