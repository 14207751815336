var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaInfoCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip, } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { METRIC_KEY, READING_TYPE_ACCELERATION_X, READING_TYPE_FFT_VELOCITY_X } from '../../../constants';
import SensorsByEquipment from '../../components/SensorsByEquipment';
import DataTable from '../../components/shared/Table/Table';
import { config as baseConfig } from '../../config/idleThreshold/dataTableConfig';
import { getUoms } from '../../helper/chart';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../store/global/useGlobalStore';
import SetFor from './components/SetFor';
var fetchHistory = function (equipmentId, config, setConfig, setSmallLoader, measure) {
    setSmallLoader(true);
    Endpoints.remastered
        .getIdleThresholdLog({
        query: {
            equipment_id: equipmentId,
            pagination: { pageSize: 5, page: 1 },
            sort: config.sort,
            selected_facility_metric: METRIC_KEY[measure],
        },
    })
        .then(function (resp) {
        setSmallLoader(false);
        if (resp) {
            setConfig(__assign(__assign({}, config), { data: resp.data }));
        }
    });
};
var SetIdleThresholdModal = function () {
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
        isSupportHfdvue: state.isSupportHfdvue,
    }); }), installationPoint = _a.installationPoint, setActiveInstallationPoint = _a.setActiveInstallationPoint, isSupportHfdvue = _a.isSupportHfdvue, equipment = _get(installationPoint, 'equipment') || {};
    var _b = useState({
        idle_threshold: installationPoint.idle_threshold || 0.1,
        impact_idle_threshold: installationPoint.impact_idle_threshold || 0.1,
        idle_threshold_type: installationPoint.idle_threshold_type || 'acceleration',
        impact_idle_threshold_type: installationPoint.impact_idle_threshold_type || 'acceleration',
        installation_point_id: installationPoint.id,
        type: 'point',
    }), data = _b[0], setData = _b[1];
    var _c = useState(__assign(__assign({}, baseConfig), { columns: __spreadArray([
            {
                name: 'customer_name',
                title: 'Customer',
                sortable: false,
            },
            {
                name: 'location_name',
                title: 'Location',
                sortable: false,
            }
        ], baseConfig.columns.map(function (column) {
            column.sortable = false;
            return column;
        }), true), sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ], pagination: {
            disabled: true,
        }, search: '', disabledSearch: true, hideTotalLabel: true, data: [], loader: false, filters: function () {
            return _jsx(_Fragment, {});
        } })), config = _c[0], setConfig = _c[1];
    var _d = useState(false), smallLoader = _d[0], setSmallLoader = _d[1];
    useEffect(function () {
        fetchHistory(equipment.id, config, setConfig, setSmallLoader, measure);
    }, []);
    var onSave = function () {
        if (!data.idle_threshold_type) {
            toast.error("Threshold type can't be empty.", { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            return;
        }
        if (+data.idle_threshold < 0 || +data.idle_threshold > 1) {
            toast.error("Threshold can't be less than 0 and more than 1", {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
            return;
        }
        if (data.impact_idle_threshold && +data.impact_idle_threshold < 0) {
            toast.error("ImpactVue Threshold can't be less than 0", {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
            return;
        }
        if (!isSupportHfdvue) {
            //@ts-ignore
            data.impact_idle_threshold = null;
            //@ts-ignore
            data.impact_idle_threshold_type = null;
        }
        Endpoints.remastered
            .changeIdleThreshold(__assign(__assign({}, data), { selected_facility_metric: METRIC_KEY[measure] }))
            .then(function (resp) {
            if (resp) {
                if (resp.success) {
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    if (data.type === 'point') {
                        setActiveInstallationPoint(__assign(__assign({}, installationPoint), { idle_threshold: data.idle_threshold, impact_idle_threshold: data.impact_idle_threshold, idle_threshold_type: data.idle_threshold_type, impact_idle_threshold_type: data.impact_idle_threshold_type }));
                    }
                    else {
                        setActiveInstallationPoint(__assign(__assign({}, installationPoint), { idle_threshold: data.idle_threshold, impact_idle_threshold: data.impact_idle_threshold, idle_threshold_type: data.idle_threshold_type, impact_idle_threshold_type: data.impact_idle_threshold_type, equipment: __assign(__assign({}, equipment), { idle_threshold: data.idle_threshold, impact_idle_threshold: data.impact_idle_threshold, idle_threshold_type: data.idle_threshold_type, impact_idle_threshold_type: data.impact_idle_threshold_type }) }));
                    }
                    return onCancel();
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onCancel = function () {
        setStatesChartFeaturesStore({ isVisibleIdleThresholdModal: false });
    };
    useEffect(function () {
        setData(__assign(__assign({}, data), { idle_threshold: data.type === 'equipment' ? equipment.idle_threshold || 0.1 : installationPoint.idle_threshold || 0.1, idle_threshold_type: data.type === 'equipment'
                ? equipment.idle_threshold_type || 'acceleration'
                : installationPoint.idle_threshold_type || 'acceleration', impact_idle_threshold: data.type === 'equipment'
                ? equipment.impact_idle_threshold || 0.1
                : installationPoint.impact_idle_threshold || 0.1, impact_idle_threshold_type: data.type === 'equipment'
                ? equipment.impact_idle_threshold_type || 'acceleration'
                : installationPoint.impact_idle_threshold_type || 'acceleration' }));
    }, [data.type]);
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["SET IDLE THRESHOLD", smallLoader ? _jsx(FaSpinner, { className: "ms-2 spinner-anim" }) : _jsx(_Fragment, {})] }), _jsxs(ModalBody, { children: [_jsxs(Row, { children: [_jsx(Col, { md: 6, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Asset Code:" }) }), _jsx("p", { children: equipment.asset_code })] }) }), _jsx(Col, { md: 6, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Equipment:" }) }), _jsx("p", { children: equipment.name })] }) })] }), _jsx("hr", {}), _jsxs(Row, { children: [data.type === 'point' ? (_jsx(Col, { md: 4, children: _jsx(FormGroup, { children: _jsx(SensorsByEquipment, { className: "select-xl", equipment: equipment, selectedInstallationPointId: data.installation_point_id, setSelectedInstallationPointId: function (id) {
                                            return setData(__assign(__assign({}, data), { installation_point_id: +id }));
                                        } }) }) })) : (_jsx(_Fragment, {})), _jsx(Col, { md: data.type === 'equipment' ? 6 : 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsxs("span", { children: [_jsx("span", { id: "idle_threshold_notification", className: "me-1 cursor-pointer", children: _jsx(FaInfoCircle, {}) }), _jsx(UncontrolledTooltip, { target: "idle_threshold_notification", children: "RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until the RMS readings exceed the idle threshold. The minimum idle threshold value is 0.03." })] }) }), _jsx("b", { children: "Idle threshold:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "idle_threshold", name: "idle_threshold", type: "number", step: 0.1, min: 0, max: 1, placeholder: "Idle threshold", value: data.idle_threshold, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { idle_threshold: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsxs(InputGroupText, { children: [getUoms(data.idle_threshold_type === 'acceleration'
                                                                ? READING_TYPE_ACCELERATION_X
                                                                : READING_TYPE_FFT_VELOCITY_X, measure, personalSettingMeasure), data.idle_threshold_type === 'acceleration' ? '(RMS)' : '(PK)'] }) })] })] }) }), _jsx(Col, { md: data.type === 'equipment' ? 6 : 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Threshold type:" }) }), _jsxs(Input, { type: "select", name: "idle_threshold_type", className: "d-inline-block select-xl", value: data.idle_threshold_type || '', onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { idle_threshold_type: ev.target.value }));
                                            }, children: [_jsx("option", { value: "acceleration", children: "Acceleration" }), _jsx("option", { value: "velocity", children: "Velocity" })] })] }) })] }), isSupportHfdvue ? (_jsxs(Row, { children: [data.type === 'point' ? _jsx(Col, { md: 4 }) : _jsx(_Fragment, {}), _jsx(Col, { md: data.type === 'equipment' ? 6 : 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsxs("span", { children: [_jsx("span", { id: "idle_threshold_impactVue_notification", className: "me-1 cursor-pointer", children: _jsx(FaInfoCircle, {}) }), _jsx(UncontrolledTooltip, { target: "idle_threshold_impactVue_notification", children: "RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until the RMS readings exceed the idle threshold. The minimum idle threshold value is 0.03." })] }) }), _jsx("b", { children: "ImpactVue Idle threshold:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "impact_idle_threshold", name: "impact_idle_threshold", type: "number", step: 0.1, min: 0, placeholder: "Idle threshold", value: data.impact_idle_threshold, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { impact_idle_threshold: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsxs(InputGroupText, { children: [getUoms(data.impact_idle_threshold_type === 'acceleration'
                                                                ? READING_TYPE_ACCELERATION_X
                                                                : READING_TYPE_FFT_VELOCITY_X, measure, personalSettingMeasure), data.impact_idle_threshold_type === 'acceleration' ? '(RMS)' : '(PK)'] }) })] })] }) }), _jsx(Col, { md: data.type === 'equipment' ? 6 : 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "ImpactVue Threshold type:" }) }), _jsxs(Input, { type: "select", name: "impact_idle_threshold_type", className: "d-inline-block select-xl", value: data.impact_idle_threshold_type, disabled: true, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { impact_idle_threshold_type: ev.target.value }));
                                            }, children: [_jsx("option", { value: "acceleration", children: "Acceleration" }), _jsx("option", { value: "velocity", children: "Velocity" })] })] }) })] })) : (_jsx(_Fragment, {})), _jsx(SetFor, { data: data, setData: setData }), _jsx("span", { className: "fw-bold fz-18 mt-4 d-block align-middle", children: "Idle Threshold Log:" }), _jsx(DataTable, { config: config })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, className: "me-2", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, children: "Save" })] }) })] }));
};
export default SetIdleThresholdModal;
