import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import PreActionItems from '../../../../shared/components/dashboard/cards/PreActionItems';
import { onToggleHandle } from '../../../../shared/features/dashboard';
var PreActionItemsCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'PreActionItemsAmazon';
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'PreActionItems', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(PreActionItems, { deps: [selectedFacility, refreshFlag], tableName: tableName, endpoint: function (onlyNew, limit, offset) {
                    return Endpoints.amazon.getDashboardPreActionList({
                        query: {
                            selected_facility: selectedFacility,
                            onlyNew: onlyNew,
                            limit: limit,
                            offset: offset,
                        },
                    });
                } })] }));
};
export default PreActionItemsCardWrapper;
