import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var alertLevelsLogKey = function (installationPointId, readingTypes) { return [
    'alertLevelsLog',
    installationPointId,
    readingTypes,
]; };
var alertLevelsLogFn = function (coreLink, queryKey) {
    return Api.get("".concat(coreLink, "/alert-log/sensor-alerts"), {
        query: { installationPointId: queryKey[1], readingTypes: queryKey[2] },
    });
};
export var useAlertLevelsLogQuery = function (installationPointId, readingTypes) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: alertLevelsLogKey(installationPointId, readingTypes),
        queryFn: function (_a) {
            var queryKey = _a.queryKey;
            return alertLevelsLogFn(getCoreUrl(selectedSystemType), queryKey);
        },
        select: function (_a) {
            var data = _a.data;
            return {
                data: data,
            };
        },
    });
};
