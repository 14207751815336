import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
export var removeAllByClassName = function (className, parentClassName) {
    if (parentClassName === void 0) { parentClassName = ''; }
    var rootElement = parentClassName ? document.querySelector(parentClassName) : document;
    if (!rootElement) {
        return;
    }
    rootElement.querySelectorAll(className).forEach(function (a) {
        a.remove();
    });
};
export var copyTextToClipboard = function (text, successMessage) {
    if (successMessage === void 0) { successMessage = 'Successfully copied to clipboard'; }
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    var successful = document.execCommand('copy');
    if (successful) {
        toast.success(successMessage, {
            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
            autoClose: 1000,
            hideProgressBar: true,
        });
    }
    document.body.removeChild(textArea);
};
export var stripHtml = function (html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
};
