var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var axisLabelKey = function (axisLabelExtraField, equipmentId) { return [
    'axisLabel',
    { axisLabelExtraField: axisLabelExtraField, equipmentId: equipmentId },
]; };
var axisLabelFn = function (coreLink, axisLabelExtraField, equipmentId) {
    return Api.get("".concat(coreLink, "/axis-label"), {
        query: __assign(__assign({}, axisLabelExtraField), { equipment_id: equipmentId }),
    });
};
export var useAxisLabelQuery = function (_a) {
    var axisLabelExtraField = _a.axisLabelExtraField, equipmentId = _a.equipmentId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: axisLabelKey(axisLabelExtraField, equipmentId),
        queryFn: function () { return axisLabelFn(getCoreUrl(selectedSystemType), axisLabelExtraField, equipmentId); },
        select: function (_a) {
            var data = _a.data;
            return data;
        },
        enabled: !!axisLabelExtraField && !!equipmentId,
    });
};
