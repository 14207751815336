import { createStore } from '../createStore';
var useChartStore = createStore(function (set) { return ({
    isLockRmsTooltips: false,
    lockTooltipsPosition: 'top-right',
    actions: {
        setLockRmsTooltips: function (isLockRmsTooltips) { return set({ isLockRmsTooltips: isLockRmsTooltips }); },
        setLockTooltipPosition: function (lockTooltipsPosition) { return set({ lockTooltipsPosition: lockTooltipsPosition }); },
    },
}); }, 'ChartStore', true);
export var useChartActions = function () {
    return useChartStore(function (state) { return state.actions; });
};
export var selectIsLockRmsTooltips = function () {
    return useChartStore(function (state) { return state.isLockRmsTooltips; });
};
export var selectLockTooltipsPosition = function () {
    return useChartStore(function (state) { return state.lockTooltipsPosition; });
};
