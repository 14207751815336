import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
var Count = function (_a) {
    var counts = _a.counts;
    var userCount = +_get(counts, 'user', 0);
    var allCount = +_get(counts, 'all', 0);
    if (userCount === 0 || allCount === 0) {
        return _jsx(_Fragment, { children: "0" });
    }
    return (_jsxs("div", { children: [_jsxs("span", { className: "fw-bold", children: [userCount, " "] }), "-", _jsxs("span", { className: "fw-bold", children: [" ", Math.round((userCount / allCount) * 100)] }), "% from all users"] }));
};
export default Count;
