import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import LinkWithPrefix from '../../LinkWithPrefix';
import './Tabs.scss';
var AlertTabsMenu = function (_a) {
    var type = _a.type;
    return (_jsx(React.Fragment, { children: _jsx(Row, { className: "mt-3", children: _jsx(Col, { children: _jsxs(Nav, { tabs: true, className: "mb-2 tabs-navbar alert-log-tabs", children: [_jsx(NavItem, { className: "cursor-pointer", children: _jsx(NavLink, { active: type === 'alert', children: _jsx(LinkWithPrefix, { to: "/alert-log/alerts", children: "Alerts" }) }) }), _jsx(NavItem, { className: "cursor-pointer", children: _jsx(NavLink, { active: type === 'batch', children: _jsx(LinkWithPrefix, { to: "/alert-log/batch-alert-level", children: "Batch Alert Level" }) }) })] }) }) }) }));
};
export default AlertTabsMenu;
