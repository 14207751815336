import { each as _each, get as _get, size as _size } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
export var getExistingDataInList = function (installationPoint, inList) {
    var existInList = [];
    _each(inList, function (value, list) {
        if (_size(_get(value, installationPoint.id)) || _get(value, installationPoint.id)) {
            existInList.push({ name: list, value: _get(value, installationPoint.id) });
        }
    });
    return existInList;
};
export var isVisibleStartupMode = function (startupMode) {
    if (_size(startupMode) === 0 || !startupMode.active) {
        if (_size(startupMode) > 0 &&
            startupMode.settings &&
            moment().diff(moment(startupMode.settings.end_date), 'day') > 0 &&
            moment().diff(moment(startupMode.settings.end_date), 'day') <= 60) {
            return true;
        }
        if (_size(startupMode) > 0 &&
            !startupMode.settings &&
            moment().diff(moment(startupMode.facility.timestamp), 'month') <= 6) {
            var dateFrom = startupMode.facility.first_reading_timestamp ||
                startupMode.minPoweredUp ||
                startupMode.facility.timestamp, minPoweredUpDays = moment().diff(moment(dateFrom), 'day');
            return minPoweredUpDays <= 60;
        }
        return false;
    }
    return true;
};
export var isSeverityIncreasedPreAi = function (installationPoint, inList) {
    return +_get(inList, ['preActionList', installationPoint.id, 'is_severity_increase']) === 1;
};
export var getLabelData = function (existInList, key) {
    return useMemo(function () {
        var filteredList = existInList.filter(function (el) { return el.name === key; });
        var isHasData = filteredList.length > 0;
        return isHasData ? filteredList[0] : null;
    }, [existInList]);
};
