import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { fetchArticles, fetchReleaseList, setRelease } from '../../features';
var WhatsNewReleaseList = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var navigate = useNavigate();
    var releaseDate = useParams().releaseDate;
    var releases = state.releases, activeRelease = state.activeRelease, loaderRelease = state.loaderRelease;
    useEffect(function () {
        fetchReleaseList(state, dispatch, selectedSystemType, releaseDate === null || releaseDate === void 0 ? void 0 : releaseDate.replaceAll('-', '/'));
    }, [selectedSystemType]);
    useEffect(function () {
        if (!loaderRelease && activeRelease) {
            fetchArticles(state, dispatch, selectedSystemType, activeRelease);
        }
    }, [activeRelease]);
    return (_jsx("div", { className: "releaseList-wrap", children: _jsxs("div", { className: "releaseList", children: [_jsx("h4", { children: "Releases" }), _jsx("div", { className: "block" }), loaderRelease ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, { children: releases.length ? (releases.map(function (release, index) { return (_jsxs("span", { className: "".concat(activeRelease && activeRelease.date === release.date ? 'active' : ''), onClick: function (ev) {
                            if (ev.target.classList[0] !== 'markAsRead') {
                                setRelease(state, dispatch, release);
                                navigate('/whats-new/' + release.date.replaceAll('/', '-'));
                            }
                        }, children: [release.date, _jsxs("div", { className: "d-flex align-items-center", children: [release.countNotViewed ? (_jsxs("div", { className: "numberOfUnread", children: [_jsx("div", { className: "markAsRead", children: "Mark as read" }), release.countNotViewed] })) : (_jsx(_Fragment, {})), !index ? _jsx("div", { className: "latest", children: "Latest" }) : _jsx(_Fragment, {})] })] }, index)); })) : (_jsx(_Fragment, {})) }))] }) }));
};
export default memo(WhatsNewReleaseList);
