import Api from '../../api';
export default {
    getDashboardWaitingActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/waiting', options);
    },
    getDashboardCustomerCreatedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/customer-created', options);
    },
    getDashboardSubmittedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/submitted-report', options);
    },
    getDashboardTroubleShootingWaitingActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/troubleshooting-waiting', options);
    },
    getDashboardDeclinedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/with-declined-reports', options);
    },
    getDashboardIncreasedSeverityActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/increased-severity', options);
    },
    getDashboardIncreasedSeverityPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/pre-action-list/increased-severity', options);
    },
    getDashboardWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/watchlist/dashboard', options);
    },
    getDashboardOldPointsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/installation-point/old-points', options);
    },
    getDashboardPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/pre-action-list/dashboard', options);
    },
};
