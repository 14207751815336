import { get as _get } from 'lodash';
import { useContext, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { updateSeries } from '../../../components/charts/chartsWrapper/features/rms';
import { hideTachDataSeries, showTachDataSeries, } from '../../../components/charts/chartsWrapper/features/rms/tachData';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useTachData = function (state, dispatch, tachDataIsVisible) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = ChartsStore(function (state) { return ({
        countDays: state.countDays,
        storedTachDataReadings: state.storedTachDataReadings,
        setStatesChartStore: state.setStates,
    }); }, shallow), countDays = _a.countDays, storedTachDataReadings = _a.storedTachDataReadings, setStatesChartStore = _a.setStatesChartStore;
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }, shallow), equipment = _get(installationPoint, 'equipment') || {};
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        if (tachDataIsVisible) {
            dispatch({ type: 'setState', state: { overlayInstallationPointsLoader: 1 } });
            return showTachDataSeries(equipment, countDays, options.series, function (series) { return updateSeries(state, dispatch, series); }, selectedSystemType, storedTachDataReadings, setStatesChartStore);
        }
        return hideTachDataSeries(options.series, function (series) {
            return updateSeries(state, dispatch, series);
        });
    }, [tachDataIsVisible]);
};
