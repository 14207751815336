import { createStore } from '../createStore';
var useSuggestedLevelModalStore = createStore(function (set) { return ({
    isVisibleAnalystNotes: false,
    actions: {
        setIsVisibleAnalystNotes: function (isVisibleAnalystNotes) { return set({ isVisibleAnalystNotes: isVisibleAnalystNotes }); },
    },
}); });
export var useSuggestedLevelModalActions = function () {
    return useSuggestedLevelModalStore(function (state) { return state.actions; });
};
export var selectIsVisibleAnalystNotes = function () {
    return useSuggestedLevelModalStore(function (state) { return state.isVisibleAnalystNotes; });
};
