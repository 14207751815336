import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { READING_TYPES } from '../../../../../../../constants';
import IncreasedSeverityBadge from '../../../../../AIPreAi/IncreasedSeverityBadge';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
import { getLabelData } from '../features';
var SeverityIncreasedLabel = function (_a) {
    var existInList = _a.existInList, approveSeverityIncreaseMutate = _a.approveSeverityIncreaseMutate, declineSeverityIncreaseMutate = _a.declineSeverityIncreaseMutate;
    var data = getLabelData(existInList, 'severityIncrease');
    return (_jsx(_Fragment, { children: data ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(IncreasedSeverityBadge, { approveSeverityIncreaseMutate: approveSeverityIncreaseMutate, declineSeverityIncreaseMutate: declineSeverityIncreaseMutate, id: data.value[0].action_list_id
                        ? data.value[0].action_list_id
                        : data.value[0].pre_action_list_id, isPreAi: !!data.value[0].pre_action_list_id }), children: data.value.map(function (value) { return (_jsxs("div", { children: [_jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Reading:" }), _get(READING_TYPES, [value.reading_type_id, 'title'], ''), ' ', _get(READING_TYPES, [value.reading_type_id, 'axis'], '')] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Time created:" }), "UTC: ", moment(value.created_at).format('MM/DD/YYYY, h:mm A') || '---'] })] }, value.reading_type_id + value.created_at)); }) }) })) : (_jsx(_Fragment, {})) }));
};
export default memo(SeverityIncreasedLabel);
