var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { get as _get } from 'lodash';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCountAiByField, getFormattedDateFromOrDefault, getFormattedDateToOrDefault, getGroupedData, getStatisticByAnalysts, } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticCountAiKey = function (dateRange) {
    var _a, _b;
    return [
        'statisticCountAi',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
    ];
};
var statisticCountAiFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/count-ai"), {
        query: {
            'separate-by-team': 1,
            date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'),
            date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'),
        },
    });
};
var baseChartConfig = {
    title: {
        text: 'Statistic By Analyst',
    },
    series: [
        {
            type: 'pie',
            colorByPoint: true,
            data: [],
        },
    ],
    tooltip: {
        pointFormat: 'AI Count: <b>{point.y}</b>',
    },
};
export var useStatisticCountAiQuery = function () {
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticCountAiKey(dateRange),
        queryFn: function (data) { return statisticCountAiFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var _b, _c;
            var data = _a.data;
            var totalByTeam = {
                ai: 0,
                pre_ai: 0,
                processed_pre_ais: 0,
                hardware_ai: 0,
                ai_comments: 0,
                cr_processed: 0,
            };
            (Object.keys(_get(data, 'analysts', {})) || []).map(function (key) {
                var arr = _get(data, ['analysts', key], []);
                arr.filter(function (el) {
                    totalByTeam = {
                        ai: totalByTeam.ai + +el.created_ai,
                        pre_ai: totalByTeam.pre_ai + (+el.ai_from_own_pre_ai || 0) + (+el.created_pre_ai || 0),
                        processed_pre_ais: totalByTeam.processed_pre_ais +
                            (el.ai_from_own_pre_ai || 0) +
                            (+el.accepted_pre_ai || 0) +
                            (+el.declined_pre_ai || 0),
                        hardware_ai: totalByTeam.hardware_ai + (+el.hardware || 0),
                        ai_comments: totalByTeam.ai_comments + (+el.ai_comments || 0),
                        cr_processed: totalByTeam.cr_processed + (+el.closed_ai || 0) + (+el.declined_reports || 0),
                    };
                });
            });
            return {
                chartsData: {
                    chartAnalystsConfig: __assign(__assign({}, baseChartConfig), { series: [
                            {
                                colorByPoint: false,
                                data: getStatisticByAnalysts(getGroupedData(__assign({}, data)), 'created_ai'),
                            },
                        ] }),
                    teamsAiConfig: __assign(__assign({}, baseChartConfig), { title: {
                            text: 'AI Created By Teams',
                        }, series: [
                            {
                                colorByPoint: false,
                                data: [
                                    {
                                        name: 'UA Team',
                                        y: _get(data, 'total.total_by_team_ua', 0),
                                    },
                                    {
                                        name: 'USA Team',
                                        y: _get(data, 'total.total_by_team_usa', 0),
                                    },
                                    // {
                                    //     name: "PHL Team",
                                    //     y: _get(data, "total.total_by_team_phl", 0)
                                    // },
                                    {
                                        name: 'ML-bot',
                                        y: _get(data, 'total.total_by_team_ml_bot', 0),
                                    },
                                ],
                            },
                        ] }),
                    totalCreatedAiConfig: __assign(__assign({}, baseChartConfig), { title: {
                            text: 'Total AI created/No Action Required',
                        }, series: [
                            {
                                colorByPoint: false,
                                data: [
                                    {
                                        name: 'No Action Required UA Team',
                                        y: getCountAiByField(_get(data, 'analysts.UA_team', []), 'no_action_required'),
                                    },
                                    {
                                        name: 'No Action Required USA Team',
                                        y: getCountAiByField(_get(data, 'analysts.USA_team', []), 'no_action_required'),
                                    },
                                    // {
                                    //     name: "No Action Required PHL Team",
                                    //     y: getCountAiByField(_get(data, "analysts.PHL_team", []), "no_action_required")
                                    // },
                                    {
                                        name: 'Total AI',
                                        y: _get(data, 'total.ai', 0),
                                    },
                                ],
                            },
                        ] }),
                    totalAiConfig: __assign(__assign({}, baseChartConfig), { title: {
                            text: 'Total AI/Pre-Ai',
                        }, series: [
                            {
                                colorByPoint: false,
                                data: [
                                    {
                                        name: 'Pre AI',
                                        y: _get(data, 'total.pre_ai', 0),
                                    },
                                    {
                                        name: 'Total AI',
                                        y: _get(data, 'total.ai', 0),
                                    },
                                ],
                            },
                        ] }),
                },
                tablesData: {
                    totalByTeam: totalByTeam,
                    total: (_b = data === null || data === void 0 ? void 0 : data.total) !== null && _b !== void 0 ? _b : {},
                    analysts: (_c = data === null || data === void 0 ? void 0 : data.analysts) !== null && _c !== void 0 ? _c : {},
                },
            };
        },
    });
};
