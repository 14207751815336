var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ACTION_LIST } from '../../../constants';
import getStatusSelectOptionsShared from '../../../shared/components/actionItem/StatusSelect/config/index';
export var getStatusSelectOptions = function (isTroubleshoot, isTroubleshootingWaiting, isShowAwaitingUpdate, isShowChangeToHardwareIssue, isShowWaiting, isShowWaitesNew, stateWithoutTroubleshooting) {
    var data = getStatusSelectOptionsShared(isTroubleshoot, isTroubleshootingWaiting, isShowAwaitingUpdate, ACTION_LIST.STATUS_NAMES[stateWithoutTroubleshooting]);
    data = __assign(__assign({}, data), { cancel_and_close: {
            label: 'Cancel and Close',
            to: 'Close',
        } });
    if (isShowWaiting) {
        data = __assign(__assign({}, data), { waiting: {
                label: 'Waiting',
                to: 'Waiting',
            } });
    }
    if (isShowWaitesNew) {
        data = __assign(__assign({}, data), { waites_new: {
                label: 'Waites new',
                to: 'Waites new',
            } });
    }
    if (isShowWaiting) {
        data = __assign(__assign({}, data), { waiting: {
                label: 'Waiting',
                to: 'Waiting',
            } });
    }
    if (isShowChangeToHardwareIssue) {
        data = __assign(__assign({}, data), { change_to_hardware_issue: {
                label: 'Change to Network issue',
                to: 'Waiting',
            } });
    }
    if (!isTroubleshoot) {
        data = __assign(__assign({}, data), { cancel_snooze: {
                label: 'Cancel Snooze',
                to: 'Need Response',
            } });
    }
    return data;
};
