import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var bearingUpdatePlotLinesFn = function (coreLink, installationPointId, bearing) {
    return Api.post("".concat(coreLink, "/bearing/").concat(installationPointId, "/update-plot-lines/").concat(bearing.id), bearing);
};
export var useBearingUpdatePlotLinesMutation = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var bearing = _a.bearing;
            return bearingUpdatePlotLinesFn(getCoreUrl(selectedSystemType), installationPointId, bearing);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['bearings', installationPointId] });
        },
    });
};
