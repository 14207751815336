import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { MdRebaseEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useChartSettingsSaveMutation, } from '../../../../services/charts/mutations/useChartSettingsSaveMutation';
import { selectChartDefaultZoomDays, selectChartSorting, } from '../../../../store/charts/useChartSettingsStore';
import { selectIsVisibleFooterBar, useFooterBarActions } from '../../../../store/charts/useFooterBarStore';
import DefaultTooltip from '../../../DefaultTooltip';
import './index.scss';
var ToggleFooterBar = function () {
    var isVisibleFooterBar = selectIsVisibleFooterBar();
    var chartSorting = selectChartSorting();
    var chartDefaultZoomDays = selectChartDefaultZoomDays();
    var setIsVisibleFooterBar = useFooterBarActions().setIsVisibleFooterBar;
    var mutate = useChartSettingsSaveMutation().mutate;
    var onToggle = function () {
        if (isVisibleFooterBar) {
            mutate({
                chart_sorting: JSON.stringify(chartSorting),
                chart_default_zoom_days: chartDefaultZoomDays,
            }, {
                onSuccess: function () {
                    toast.success('Successfully saved', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                },
            });
        }
        setIsVisibleFooterBar(!isVisibleFooterBar);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { "data-tooltip-id": "toggle", className: "toggle-footer-bar ".concat(isVisibleFooterBar ? 'active' : ''), onClick: onToggle, children: isVisibleFooterBar ? _jsx(FaCheck, {}) : _jsx(MdRebaseEdit, {}) }), isVisibleFooterBar ? (_jsx(_Fragment, {})) : (_jsx(DefaultTooltip, { style: { zIndex: 1000 }, openEvents: { mouseenter: true }, place: "left", id: "toggle", children: "Chart sorting" }))] }));
};
export default memo(ToggleFooterBar);
