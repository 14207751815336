var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import DataTable from '../../../components/shared/Table/Table';
import { fetch } from '../../../features/clients';
import { onChangeFilter } from '../../../features/withoutAlarmLevels';
import { reducer } from '../../../helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../helper/table';
import { useResetDataTableEffect } from '../../../hooks/useResetDataTableEffect';
import HomePhotosModal from '../Photos/Photos';
import { config as baseConfig } from './config/dataTableConfig';
var initialState = function (onViewActionCallback, setOpenPhotosModal) {
    var config = baseConfig(function (item) { return onViewActionCallback(item); }, function (sensorId) { return setOpenPhotosModal(sensorId); });
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([], config.columns, true) }),
    };
};
var SensorsWithoutAlarmLevelsModal = function (_a) {
    var client = _a.client, isOpen = _a.isOpen, toggle = _a.toggle;
    if (!client || !client.customer_id) {
        return _jsx(_Fragment, {});
    }
    var _b = useState(''), selectedSensorId = _b[0], setSelectedSensorId = _b[1];
    var _c = useState(false), isOpenPhotosModal = _c[0], setOpenPhotosModal = _c[1];
    var _d = useReducer(reducer, initialState(function (item) { return window.open("/dashboard?installation_point=".concat(item.installation_point_id), '_blank'); }, function (sensorId) {
        setSelectedSensorId(sensorId);
        setOpenPhotosModal(true);
    })), state = _d[0], dispatch = _d[1];
    var config = state.config;
    if (!config.filtersData) {
        return _jsx(_Fragment, {});
    }
    var _e = config.filtersData, hide_bad_sensors = _e.hide_bad_sensors, new_data_readings = _e.new_data_readings, has_data_readings = _e.has_data_readings;
    var endpoint = function (customer_id) {
        return Endpoints.remastered.getSensorsWithoutAlarmLevels({
            errorSkip: true,
            query: {
                selected_customer_ids: customer_id ? [customer_id] : [],
                hide_bad_sensors: +hide_bad_sensors,
                new_data_readings: +new_data_readings,
                has_data_readings: +has_data_readings,
                pagination: config.pagination,
                sort: config.sort,
                search: config.search,
            },
        });
    };
    var callbackFetch = function () { return fetch(state, dispatch, function () { return endpoint(client.customer_id); }); };
    useResetDataTableEffect([hide_bad_sensors, new_data_readings, has_data_readings, isOpen], state, dispatch);
    useEffect(function () {
        callbackFetch();
    }, [hide_bad_sensors, new_data_readings, has_data_readings, isOpen]);
    useEffect(function () {
        if (isOpen) {
            callbackFetch();
        }
    }, [isOpen]);
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { size: "xl", isOpen: isOpen, toggle: toggle, children: [_jsx(ModalHeader, { toggle: toggle, children: "Sensors without alarm levels" }), _jsx(ModalBody, { style: { minHeight: 'calc(100vh - 200px)' }, children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                            }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); }, callbacks: {
                                onChangeFilter: function (name, value) { return onChangeFilter(state, dispatch, name, value); },
                            } }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: toggle, children: "Cancel" }) }) })] }), _jsx(HomePhotosModal, { endpoint: function () {
                    return Endpoints.remastered.getSensorPhotos(+selectedSensorId, {
                        errorSkip: true,
                    });
                }, isOpen: isOpenPhotosModal, toggle: function () { return setOpenPhotosModal(!isOpenPhotosModal); } })] }));
};
export default SensorsWithoutAlarmLevelsModal;
