import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useContext, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Endpoints from '../../api/endpoints/endpoints';
import { ACTION_LIST } from '../../constants';
import Loader from '../../shared/components/Loader';
import { SystemTypeContext } from '../../shared/context/SystemTypeContext';
import { replaceHandlerSaveData } from '../../shared/helper/chart';
import { getHost } from '../../shared/helper/customer';
import User from '../../shared/helper/user';
import { isMote } from '../../widgets/sensor';
var ReplaceBattery = function (_a) {
    var visible = _a.visible, type = _a.type, onCancel = _a.onCancel, node = _a.node, sensor = _a.sensor, installationPointId = _a.installationPointId, callback = _a.callback, _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b, storage = _a.storage;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useState(false), showLoader = _c[0], setShowLoader = _c[1];
    var onConfirm = function () {
        if (!_get(node, 'serial')) {
            toast.error('Node not found', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        var data = isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))
            ? {
                inst_point_id: installationPointId,
                // TODO: serial: _get(node, "serial") "node" is right??? in version_type = mote
                serial: _get(node, 'serial'),
                equipment_type: ACTION_LIST.EQUIPMENT_TYPE_MOTE,
            }
            : {
                inst_point_id: installationPointId,
                serial: _get(node, 'serial'),
                user_id: User.getId(),
                equipment_type: ACTION_LIST.EQUIPMENT_TYPE_NODE,
            };
        var moteEndpoint = isPreAi
            ? function (payload) { return Endpoints[selectedSystemType].replacePreAiMoteBattery(payload); }
            : function (payload) {
                return Endpoints[selectedSystemType].replaceMoteBattery(payload, { host: getHost(storage) });
            };
        var nodeEndpoint = isPreAi
            ? function (payload) { return Endpoints[selectedSystemType].replacePreAiNodeBatteries(payload); }
            : function (payload) {
                return Endpoints[selectedSystemType].replaceNodeBatteries(payload, { host: getHost(storage) });
            };
        var endPoint = isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))
            ? moteEndpoint
            : nodeEndpoint;
        setShowLoader(true);
        return replaceHandlerSaveData(endPoint, data, setShowLoader, onCancel, callback, isPreAi);
    };
    return (_jsxs(Modal, { size: "xs", className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Are You Sure?" }), showLoader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsxs(ModalBody, { children: ["This will create and send an automated ", isPreAi ? 'Pre-' : '', "Action Item to the facility, to replace ", type, " battery. You will not be able to edit this ", isPreAi ? 'Pre-' : '', "Action Item before sending it. If you\u2019d like to customize the ", isPreAi && 'Pre-', "Action Item, please select \u201Ccancel\u201D and create the ", isPreAi ? 'Pre-' : '', "Action Item manually."] })), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, disabled: showLoader, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onConfirm, disabled: showLoader, children: "Confirm" })] }) })] }));
};
export default ReplaceBattery;
