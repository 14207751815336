var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getStatusSelectOptions = function (isTroubleshoot, isTroubleshootingWaiting, isShowAwaitingUpdate, prevStatusName) {
    var data = {};
    if (isShowAwaitingUpdate) {
        data = __assign(__assign({}, data), { awaiting_update: {
                label: 'Awaiting Update',
                to: 'Awaiting Update',
            } });
    }
    if (isTroubleshoot || isTroubleshootingWaiting) {
        data = __assign(__assign({}, data), { cancel_hardware_assistance: {
                label: 'Cancel Network Assistance',
                to: isTroubleshootingWaiting ? null : prevStatusName,
            } });
    }
    if (!isTroubleshoot) {
        data = __assign(__assign({}, data), { snooze: {
                label: 'Snooze',
                to: 'Snooze',
            }, get_hardware_assistance: {
                label: 'Get Network Assistance',
                to: 'Troubleshooting On Hold',
            } });
    }
    return data;
};
export default getStatusSelectOptions;
