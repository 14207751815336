import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from "lodash";
import React from "react";
import { FaFile } from "react-icons/fa";
import { FILE_ICONS } from "../../constants";
export var isImage = function (file) {
    return file.type.indexOf('image') !== -1;
};
export var getIcon = function (file) {
    return _get(FILE_ICONS, file.type, _jsx(FaFile, {}));
};
