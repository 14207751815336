var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { TbBoxAlignBottomLeftFilled, TbBoxAlignBottomRightFilled, TbBoxAlignTopLeftFilled, TbBoxAlignTopRightFilled, } from 'react-icons/tb';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { selectLockTooltipsPosition, useChartActions, } from '../../../../../store/charts/useChartStore';
var LockRmsTooltipsPosition = function () {
    var _a;
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '58px', fontSize: '14px' })); },
    }); }, []);
    var POSITION_OPTIONS = [
        {
            value: 'top-left',
            label: (_jsx(_Fragment, { children: _jsx(TbBoxAlignTopLeftFilled, { style: { marginTop: '-4px', width: '100%' } }) })),
        },
        {
            value: 'top-right',
            label: (_jsx(_Fragment, { children: _jsx(TbBoxAlignTopRightFilled, { style: { marginTop: '-4px', width: '100%' } }) })),
        },
        {
            value: 'bottom-left',
            label: (_jsx(_Fragment, { children: _jsx(TbBoxAlignBottomLeftFilled, { style: { marginTop: '-4px', width: '100%' } }) })),
        },
        {
            value: 'bottom-right',
            label: (_jsx(_Fragment, { children: _jsx(TbBoxAlignBottomRightFilled, { style: { marginTop: '-4px', width: '100%' } }) })),
        },
    ];
    var lockTooltipPosition = selectLockTooltipsPosition();
    var setLockTooltipPosition = useChartActions().setLockTooltipPosition;
    var handler = function (value) {
        setLockTooltipPosition(value);
        toast.success('Locked tooltip position successfully saved by default', {
            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Select, { className: "select-custom-default", styles: customStyles, isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: POSITION_OPTIONS, onChange: function (ev) { return handler(ev.value); }, defaultValue: (_a = POSITION_OPTIONS.find(function (_a) {
                var value = _a.value;
                return value === lockTooltipPosition;
            })) !== null && _a !== void 0 ? _a : POSITION_OPTIONS[1] }) }));
};
export default memo(LockRmsTooltipsPosition);
