var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { resetHighlight } from '../helper/table';
export var useResetDataTableEffect = function (deps, state, dispatch, options) {
    useEffect(function () {
        var _a, _b;
        var sort = (options === null || options === void 0 ? void 0 : options.isResetSorting) ? { sort: [(_a = options === null || options === void 0 ? void 0 : options.defaultSort) !== null && _a !== void 0 ? _a : {}] } : {};
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign(__assign({}, state.config), { pagination: __assign(__assign({}, state.config.pagination), { pageSize: 20, page: 1 }) }), sort),
            },
        });
        if (state.config.pagination) {
            state.config.pagination.pageSize = 20;
            state.config.pagination.page = 1;
        }
        if (state.config.sort && (options === null || options === void 0 ? void 0 : options.isResetSorting)) {
            state.config.sort = [(_b = options === null || options === void 0 ? void 0 : options.defaultSort) !== null && _b !== void 0 ? _b : {}];
        }
        resetHighlight();
    }, __spreadArray([], deps, true));
};
