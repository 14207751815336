var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var onChangeFilter = function (state, dispatch, name, value) {
    var _a;
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), (_a = {}, _a[name] = value, _a)) }),
        },
    });
};
