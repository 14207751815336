export var setSortState = function (field, set, get) {
    var _a;
    if (field === null) {
        return set({ sort: [] });
    }
    var currentSort = get().sort;
    if (!currentSort || currentSort.length === 0) {
        return set({
            sort: [
                {
                    activeFieldSort: field,
                    field: field,
                    direction: 'asc',
                },
            ],
        });
    }
    return set({
        sort: [
            {
                activeFieldSort: field,
                field: field,
                direction: ((_a = currentSort[0]) === null || _a === void 0 ? void 0 : _a.direction) === 'asc' ? 'desc' : 'asc',
            },
        ],
    });
};
