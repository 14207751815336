export var initialStateConfig = {
    data: [],
    units: {
        temp: '',
        cf: '',
        acc: '',
        vel: '',
        pkpk: '',
        range: '',
    },
    loader: false,
    settingsMode: false,
    pagination: {
        page: 1,
        total: 0,
        pageSize: 20,
    },
    search: '',
    onlyIntralox: 0,
    filters: {
        greater: '',
        less: '',
        isActions: 0,
        notSet: 0,
    },
    selectedInstallationPoints: [],
};
export var initialModalStateConfig = function (fetchCallback) {
    return {
        visible: false,
        onCancel: function () { },
        callback: function (visibleEquipmentId) { return fetchCallback(visibleEquipmentId); },
        installationPoint: null,
        equipment: null,
        units: null,
        serial: null,
        readingTypes: [],
        comments: [],
        customersData: null,
        userComments: {},
        data: {},
        certain: null,
    };
};
