import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Card, CardBody, CardHeader } from 'reactstrap';
import User from '../../../helper/user';
import LinkWithPrefix from '../../LinkWithPrefix';
import UserActivityTable from '../../userActivity/UserActivityTable/UserActivityTable';
var UserActivity = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, endpoint = _a.endpoint;
    var user = User.get();
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsx(CardHeader, { style: { padding: 10 }, children: _jsx(LinkWithPrefix, { to: "/user-activity", children: _jsxs("div", { className: "float-start", children: ["User activity for ", user.full_name || ''] }) }) }), _jsx(CardBody, { style: { padding: 0 }, children: _jsx(Scrollbars, { style: { height: '100%' }, children: _jsx(UserActivityTable, { endpoint: function () { return endpoint(); }, deps: deps }) }) })] }));
};
export default UserActivity;
