export var SHIFT_KEY_CODE = 16;
export var CONTROL_KEY_CODE = 17;
export var ALT_KEY_CODE = 18;
export var META_KEY_CODE = 91;
export var S_KEY_CODE = 83;
export var H_KEY_CODE = 72;
export var A_KEY_CODE = 65;
export var D_KEY_CODE = 68;
export var C_KEY_CODE = 67;
export var R_KEY_CODE = 82;
export var N_KEY_CODE = 78;
export var ARROW_RIGHT_KEY_CODE = 39;
export var ARROW_LEFT_KEY_CODE = 37;
export var ARROW_RIGHT_KEY_NAME = 'ArrowRight';
export var ARROW_LEFT_KEY_NAME = 'ArrowLeft';
export var SHIFT_KEY_NAME = 'Shift';
export var ALT_KEY_NAME = 'Alt';
export var CONTROL_KEY_NAME = 'Control';
export var META_KEY_NAME = 'Meta';
