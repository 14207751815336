import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
var ChartHidePeaksToggle = function () {
    var _a = ChartsStore(function (state) { return ({
        hidePeaks: state.hidePeaks,
        setStatesChartsStore: state.setStates,
    }); }, shallow), hidePeaks = _a.hidePeaks, setStatesChartsStore = _a.setStatesChartsStore;
    var onHidePeaks = function () {
        setStatesChartsStore({ hidePeaks: !hidePeaks });
    };
    return (_jsx(Button, { outline: !hidePeaks, color: "customChartTopBarSelect", className: "btn-chart-tools btn-chart-tools-menubtn", size: "sm", onClick: onHidePeaks, children: "Hide Peaks" }));
};
export default memo(ChartHidePeaksToggle);
