import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo } from 'react';
import { Col } from 'reactstrap';
import { selectDateRange } from '../../../../store/watchersStatistic/useDateRangeStore';
var TotalTable = function (_a) {
    var tablesData = _a.tablesData;
    var dateRange = selectDateRange();
    var total = tablesData.total;
    return (_jsxs(Col, { md: 12, className: "my-2", children: [_jsxs("h4", { children: ["Total statistic by analysts from ", moment(dateRange[0]).format('YYYY-MM-DD'), " to", ' ', moment(dateRange[1]).format('YYYY-MM-DD')] }), _jsxs("table", { className: "table table-simply", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Created AIs" }), _jsx("th", { children: "No action AI" }), _jsx("th", { children: "Approved Pre-AIs" }), _jsx("th", { children: "Total WWAI" }), _jsx("th", { children: "Pre-AI Accepted Action" }), _jsx("th", { children: "Pre-AI Declined Action" })] }) }), _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { "data-label": "Total AI", children: total.ai || 0 }), _jsx("td", { "data-label": "No action AI", children: total.no_action_required || 0 }), _jsx("td", { "data-label": "Total Pre-AI", children: total.pre_ai || 0 }), _jsx("td", { "data-label": "Total WWAI", children: total.hardware_ai || 0 }), _jsx("td", { "data-label": "Pre-AI accepted", children: total.pre_ai_accepted || 0 }), _jsx("td", { "data-label": "Pre-AI declined", children: total.pre_ai_declined || 0 })] }) })] })] }));
};
export default memo(TotalTable);
