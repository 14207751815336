// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-error {
  text-align: center;
  vertical-align: middle;
  margin-top: calc((100vh - 76px) / 2 - 128px);
}
.page-error span {
  font-size: 128px;
  color: #444;
}
.dark-layout .page-error span {
  color: #f1f1f1;
}
.page-error .error-details {
  display: block;
  font-size: 32px;
}`, "",{"version":3,"sources":["webpack://./../src/page/error/Error.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,4CAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;AAEJ;AADI;EACE,cAAA;AAGN;AAAE;EACE,cAAA;EACA,eAAA;AAEJ","sourcesContent":[".page-error {\n  text-align: center;\n  vertical-align: middle;\n  margin-top: calc((100vh - 76px) / 2 - 128px);\n  & span {\n    font-size: 128px;\n    color: #444;\n    .dark-layout & {\n      color: #f1f1f1;\n    }\n  }\n  & .error-details {\n    display: block;\n    font-size: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
