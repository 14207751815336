import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaRegCommentDots } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { useActionItemModalsActions } from '../../../store/AIPreAi/useActionItemModalsStore';
import DefaultTooltip from '../../DefaultTooltip';
var CommentToMLBtn = function () {
    var setIsVisibleCommentForMlTeamModal = useActionItemModalsActions().setIsVisibleCommentForMlTeamModal;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: true, color: "primary", "data-tooltip-id": "comment-to-ml-btn", className: "top-actions-fill", onClick: function () { return setIsVisibleCommentForMlTeamModal(true); }, children: _jsx(FaRegCommentDots, {}) }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "comment-to-ml-btn", children: "Comment to ML" })] }));
};
export default memo(CommentToMLBtn);
