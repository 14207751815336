import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import ActionItemsTable from './ActionItemsTable';
var PointActionItems = function (_a) {
    var _b;
    var activeActionItems = _a.activeActionItems, previousActionItems = _a.previousActionItems, installationPoint = _a.installationPoint, collapseExpandState = _a.collapseExpandState, setCompletionReportModalState = _a.setCompletionReportModalState;
    var initialPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _c = useState(initialPoint.id === installationPoint.id), isVisible = _c[0], setIsVisible = _c[1];
    useEffect(function () {
        setIsVisible(collapseExpandState === 'expand');
    }, [collapseExpandState]);
    return (_jsxs(Row, { className: "mx-1", children: [_jsxs("div", { className: "machine-info-action-item-point-row d-flex justify-content-between h6 py-2", onClick: function () { return setIsVisible(function (isVisible) { return !isVisible; }); }, children: [(_b = installationPoint.point_name) !== null && _b !== void 0 ? _b : installationPoint.name, isVisible ? _jsx(FaAngleUp, {}) : _jsx(FaAngleDown, {})] }), isVisible ? (_jsx("div", { className: "machine-info-action-item-tables", children: _jsxs(_Fragment, { children: [_jsx(Col, { className: "fw-semibold", children: "Active Action Items" }), _jsx(ActionItemsTable, { actionItems: activeActionItems, setCompletionReportModalState: setCompletionReportModalState }), _jsx(Col, { className: "fw-semibold", children: "Previous Action Items" }), _jsx(ActionItemsTable, { actionItems: previousActionItems, setCompletionReportModalState: setCompletionReportModalState })] }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(PointActionItems);
