import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var config = {
    getIsShowAdditionContent: function (row) { return !!row.description; },
    additionContent: function (row) {
        return (_jsx("div", { className: "table-additional-content fz-13", children: _jsx("div", { dangerouslySetInnerHTML: { __html: row.description } }) }));
    },
    columns: [
        {
            name: 'type',
            title: 'Type',
            sortable: true,
        },
        {
            name: 'user_name',
            title: 'Username',
            sortable: true,
        },
        {
            name: 'short_description',
            title: 'Short description',
            sortable: true,
            component: function (row) {
                return _jsx("div", { dangerouslySetInnerHTML: { __html: row.short_description } });
            },
        },
        {
            name: 'version',
            title: 'Version',
            sortable: true,
        },
        {
            name: 'created_at',
            title: 'Created At',
            sortable: true,
        },
    ],
    data: [],
    loader: true,
    search: '',
};
