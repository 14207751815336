// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labels-chart-actions {
  display: flex;
  position: absolute;
  width: auto;
  top: 5px;
  right: 40px;
  z-index: 1;
}
.labels-chart-actions .default-chart-label {
  display: flex;
  align-items: center;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  background: #f1f1f1;
  border: 1px solid #efefef;
  margin-left: 5px;
  cursor: default;
}
.dark-layout .labels-chart-actions .default-chart-label {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}
.labels-chart-actions .label-active {
  background: #0a66b7 !important;
  color: #f1f1f1;
}
.labels-chart-actions .label-warning {
  background: #d9d601 !important;
  color: #212529;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/LabelsChartActions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AADI;EACE,mBAAA;EACA,yBAAA;AAGN;AAAE;EACE,8BAAA;EACA,cAAA;AAEJ;AAAE;EACE,8BAAA;EACA,cAAA;AAEJ","sourcesContent":[".labels-chart-actions {\n  display: flex;\n  position: absolute;\n  width: auto;\n  top: 5px;\n  right: 40px;\n  z-index: 1;\n  & .default-chart-label {\n    display: flex;\n    align-items: center;\n    font-size: 11px;\n    padding: 2px 4px;\n    border-radius: 4px;\n    background: #f1f1f1;\n    border: 1px solid #efefef;\n    margin-left: 5px;\n    cursor: default;\n    .dark-layout & {\n      background: #1e222d;\n      border: 1px solid #3e3f4f;\n    }\n  }\n  & .label-active {\n    background: #0a66b7 !important;\n    color: #f1f1f1;\n  }\n  & .label-warning {\n    background: #d9d601 !important;\n    color: #212529;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
