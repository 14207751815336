import { createStore } from '../createStore';
var useChartSettingsStore = createStore(function (set) { return ({
    id: null,
    user_id: null,
    updated_at: null,
    created_at: null,
    chartSorting: [],
    chartDefaultZoomDays: 14,
    chartZoomDays: 14,
    actions: {
        setChartSorting: function (chartSorting) { return set({ chartSorting: chartSorting }); },
        setChartDefaultZoomDays: function (chartDefaultZoomDays) { return set({ chartDefaultZoomDays: chartDefaultZoomDays }); },
        setChartZoomDays: function (chartZoomDays) { return set({ chartZoomDays: chartZoomDays }); },
    },
}); });
export var useChartSettingsActions = function () {
    return useChartSettingsStore(function (state) { return state.actions; });
};
export var selectChartSorting = function () {
    return useChartSettingsStore(function (state) { return state.chartSorting; });
};
export var selectChartDefaultZoomDays = function () {
    return useChartSettingsStore(function (state) { return state.chartDefaultZoomDays; });
};
export var selectChartZoomDays = function () {
    return useChartSettingsStore(function (state) { return state.chartZoomDays; });
};
