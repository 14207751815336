import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { useTitle } from '../../../../customHooks';
import LinkWithPrefix from '../../../../shared/components/LinkWithPrefix';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import { useFftAlertLevelsListQuery, } from '../../../services/alerts/useFftAlertLevelsListQuery';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
import { selectPage, selectPageSize, selectSearch, selectSort, useTableActions, } from '../../../store/useFftAlertLevelsTableStore';
var FftAlertLevelsPage = function () {
    useTitle('Fft Alert Levels');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _a = useFftAlertLevelsListQuery({
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
    }), data = _a.data, isPending = _a.isPending;
    var _b = data || {}, list = _b.data, total = _b.total;
    var _c = useTableActions(), setPage = _c.setPage, setSearch = _c.setSearch, setPageSize = _c.setPageSize, setSort = _c.setSort;
    var search = selectSearch();
    var page = selectPage();
    var pageSize = selectPageSize();
    var sort = selectSort();
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx("h3", { className: "page-title", children: _jsxs(Nav, { tabs: true, className: "mb-2 rejected-tabs fz-18", children: [_jsx(NavItem, { className: "cursor-pointer", children: _jsx(NavLink, { children: _jsx(LinkWithPrefix, { to: "/alerts/fft", children: "Triggered alerts FFT" }) }) }), _jsx(NavItem, { className: "cursor-pointer", children: _jsx(NavLink, { active: true, children: _jsx(LinkWithPrefix, { to: "/alert-levels/fft", children: "FFT alert levels" }) }) })] }) }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsx(DataTable, { config: {
                            columns: [
                                {
                                    name: 'customer_name',
                                    title: 'Customer',
                                    sortable: true,
                                },
                                {
                                    name: 'facility_name',
                                    title: 'Facility',
                                    sortable: true,
                                },
                                {
                                    name: 'location_name',
                                    title: 'Location',
                                    sortable: true,
                                },
                                {
                                    name: 'equipment_name',
                                    title: 'Equipment',
                                    sortable: true,
                                },
                                {
                                    name: 'point_name',
                                    title: 'Point name',
                                    sortable: true,
                                },
                                {
                                    name: 'sensor_id',
                                    title: 'Sensor ID',
                                    sortable: true,
                                    component: function (row) {
                                        return _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id });
                                    },
                                },
                                {
                                    name: 'level_counts',
                                    title: 'Fft Alert Levels',
                                    sortable: true,
                                    component: function (row) {
                                        return (_jsxs(_Fragment, { children: [row.band_counts > 0 ? _jsxs("div", { children: ["Band levels - ", row.band_counts] }) : _jsx(_Fragment, {}), row.envelope_counts > 0 ? _jsxs("div", { children: ["Envelope levels - ", row.envelope_counts] }) : _jsx(_Fragment, {})] }));
                                    },
                                },
                                {
                                    name: 'created_at',
                                    title: 'Date',
                                    sortable: true,
                                    component: function (row) {
                                        return (_jsx(DateTimeLabel, { timeBreak: true, dateTime: row.created_at, id: "bad_sensors_created_at".concat(row.id) }));
                                    },
                                },
                                {
                                    name: 'actions',
                                    sortable: false,
                                    component: function (row) {
                                        return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(row.id), target: "_blank", children: _jsx(Button, { color: "primary", size: "sm", children: "View" }) }));
                                    },
                                },
                            ],
                            pagination: {
                                page: page,
                                pageSize: pageSize,
                                total: total || 0,
                            },
                            sort: sort,
                            search: search,
                            data: list || [],
                            loader: isPending,
                        }, onPageChange: function (page) { return setPage(page); }, onPageSizeChange: function (pageSize) { return setPageSize(pageSize); }, onSearch: function (search) { return setSearch(search); }, onSort: function (column_name) { return setSort(column_name); } }) }) })] }));
};
export default FftAlertLevelsPage;
