import { get as _get, has as _has } from 'lodash';
import { CHART_GROUPS, READING_TYPES, SENSOR } from '../../../../constants';
export var scrollToChart = function (chartIdentifier) {
    if (!chartIdentifier) {
        return;
    }
    var element = document.getElementById("chart-".concat(chartIdentifier));
    if (!element) {
        return;
    }
    element.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
    });
};
export var getHiddenBtns = function (chartType, chartWidth) {
    var hiddenBtns = [];
    if (!chartType || !chartWidth) {
        return hiddenBtns;
    }
    if (chartType === 'fft') {
        if (chartWidth < 1290) {
            hiddenBtns.push('interpolation');
        }
        if (chartWidth < 1170) {
            hiddenBtns.push('highpass');
        }
        if (chartWidth < 1020) {
            hiddenBtns.push('waterfall');
            hiddenBtns.push('peaks');
        }
        if (chartWidth < 760) {
            hiddenBtns.push('faultFrequency');
        }
        if (chartWidth < 670) {
            hiddenBtns.push('bearings');
        }
        if (chartWidth < 560) {
            hiddenBtns.push('band');
        }
    }
    if (chartType === 'twf') {
        if (chartWidth < 1020) {
            hiddenBtns.push('faultFrequency');
        }
        if (chartWidth < 650) {
            hiddenBtns.push('highpass');
        }
    }
    return hiddenBtns;
};
export var isDistancePinSensor = function (installationPoint) {
    return _get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_PIN ||
        _get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_DISTANCE;
};
export var isDistanceSensor = function (installationPoint) {
    return _get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_DISTANCE;
};
export var isPinSensor = function (installationPoint) {
    return _get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_PIN;
};
export var isSortablePoint = function (installationPoint) {
    return !(installationPoint.isUniversalAdapter ||
        installationPoint.isTachometer ||
        installationPoint.isTemperatureSensor ||
        isDistancePinSensor(installationPoint));
};
export var getRmsAccVelExtraType = function (chartIdentifier) {
    var _a, _b;
    return [CHART_GROUPS.ACC, CHART_GROUPS.VEL].includes((_b = (_a = READING_TYPES[chartIdentifier]) === null || _a === void 0 ? void 0 : _a.groupId) !== null && _b !== void 0 ? _b : 0)
        ? READING_TYPES[chartIdentifier].extraType
        : '';
};
