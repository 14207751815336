import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var reportEditorOptionsKey = function () { return ['reportEditorOptions']; };
var reportEditorOptionsFn = function (coreLink) { return Api.get("".concat(coreLink, "/report-editor/select")); };
export var useReportEditorOptionsQuery = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: reportEditorOptionsKey(),
        queryFn: function () { return reportEditorOptionsFn(getCoreUrl(selectedSystemType)); },
        select: function (resp) {
            return resp;
        },
        enabled: selectedSystemType === SYSTEM_TYPE_REMASTERED,
    });
};
