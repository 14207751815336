import { createStore } from '../createStore';
var usePreActionItemCreateStore = createStore(function (set) { return ({
    sectionPosition: [
        { title: 'Technical info' },
        { title: "Action Item's charts" },
        { title: 'Action Items Already Exists On This Equipment' },
        { title: 'Pre-Action Item details' },
        { title: 'Previous Action Items' },
    ],
    isEditSections: false,
    actions: {
        setSectionPosition: function (sectionPosition) { return set({ sectionPosition: sectionPosition }); },
        setIsEditSections: function (isEditSections) { return set({ isEditSections: isEditSections }); },
    },
}); }, 'preActionItemCreateStore', ['sectionPosition']);
export var usePreActionItemCreateActions = function () {
    return usePreActionItemCreateStore(function (state) { return state.actions; });
};
export var selectSectionPosition = function () {
    return usePreActionItemCreateStore(function (state) { return state.sectionPosition; });
};
export var selectIsEditSections = function () {
    return usePreActionItemCreateStore(function (state) { return state.isEditSections; });
};
