var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, head as _head } from 'lodash';
import React, { memo, useContext, useEffect, useMemo, useState, } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { ACTION_LIST } from '../../../constants';
import { useTitle } from '../../../customHooks';
import { selectSelectedCustomers } from '../../../remastered/store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../remastered/store/SelectedLocationsStore';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import UpdateSlaSettingsModal from '../../modals/slaSettings/UpdateSlaSettingsModal';
import '../../styles/slaSettings/index.scss';
import Loader from '../Loader';
import SlaValues from './SlaValues';
var fetch = function (selectedSystemType, facilities, setFacilities, setPagination, setLoader, setOnScroll, pagination, selectedCustomers, selectedLocations, selectedFacility, triggeredByScroll) {
    if (triggeredByScroll === void 0) { triggeredByScroll = false; }
    setLoader(!triggeredByScroll);
    Endpoints[selectedSystemType]
        .getSlaSettingsList({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_id: _head(selectedLocations),
            selected_facility: selectedFacility,
            pagination: __assign({}, pagination),
        },
    })
        .then(function (resp) {
        if (resp) {
            setFacilities(triggeredByScroll ? __spreadArray(__spreadArray([], facilities, true), resp.data, true) : resp.data);
            setLoader(false);
            setOnScroll(false);
            setPagination(__assign(__assign({}, pagination), { total: resp.total, lastDataLength: resp.data.length }));
        }
    })
        .catch(function () {
        setLoader(false);
        setOnScroll(false);
        toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
var SlaSettingsTable = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, selectedFacility = _a.selectedFacility, actionListStatusNames = _a.actionListStatusNames;
    useTitle('SLA Settings');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var _d = useState(false), onScroll = _d[0], setOnScroll = _d[1];
    var _e = useState({
        page: 1,
        pageSize: 20,
        total: 0,
        lastDataLength: 0,
    }), pagination = _e[0], setPagination = _e[1];
    var _f = useState([]), facilities = _f[0], setFacilities = _f[1];
    var _g = useState({
        customer: {},
        facility: {},
        slaSettings: {},
        visible: false,
        onCancel: function () {
            setUpdateSlaSettingsModalState(__assign(__assign({}, updateSlaSettingsModalState), { customer: {}, facility: {}, slaSettings: {}, visible: false }));
        },
    }), updateSlaSettingsModalState = _g[0], setUpdateSlaSettingsModalState = _g[1];
    var facilitiesByCustomer = useMemo(function () {
        if (selectedSystemType === 'amazon') {
            return { Amazon: facilities };
        }
        var mappedFacilities = {};
        facilities.forEach(function (facility) {
            if (!mappedFacilities[facility.customer_name]) {
                mappedFacilities[facility.customer_name] = [];
            }
            mappedFacilities[facility.customer_name].push(facility);
        });
        return mappedFacilities;
    }, [facilities]);
    useEffect(function () {
        setPagination(__assign(__assign({}, pagination), { page: 1 }));
    }, [selectedCustomers, selectedFacility, refreshFlag, selectedLocations]);
    useEffect(function () {
        fetch(selectedSystemType, facilities, setFacilities, setPagination, setLoader, setOnScroll, pagination, selectedCustomers, selectedLocations, selectedFacility, onScroll);
    }, [
        refreshFlag,
        selectedCustomers,
        selectedLocations,
        selectedFacility,
        pagination.page,
        pagination.pageSize,
    ]);
    var onScrollFetch = function (values) {
        if (onScroll) {
            return;
        }
        var scrollTop = values.scrollTop, scrollHeight = values.scrollHeight, clientHeight = values.clientHeight;
        var pad = 30; // 100px of the bottom
        var scrollIndex = (scrollTop + pad) / (scrollHeight - clientHeight);
        if (scrollIndex > 1 && !loader && pagination.lastDataLength >= pagination.pageSize) {
            setOnScroll(true);
            setPagination(__assign(__assign({}, pagination), { page: pagination.page + 1 }));
        }
    };
    var onUpdateSettings = function (customer, facility, slaSettings) {
        setUpdateSlaSettingsModalState(__assign(__assign({}, updateSlaSettingsModalState), { customer: customer, facility: facility, slaSettings: slaSettings, visible: true }));
    };
    return (_jsxs("div", { className: "with-loader", children: [_jsx("h3", { className: "page-title mt-4 d-flex justify-content-between", children: "SLA Settings (sla/priority)" }), _jsx(Row, { className: "fz-12", children: _jsxs(Col, { children: [loader ? (_jsx("div", { className: "pb-3", children: _jsx(Loader, { variant: "loader-sm" }) })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "header-block d-flex fw-bold justify-content-between p-2", style: { zIndex: 1 }, children: [_jsx("span", { style: { width: 150 }, children: "Facility" }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_WW_NEW] }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_NEW] }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_WAITING] }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_AWAITING_UPDATE] }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_NEEDS_RESPONSE] }), _jsx("span", { children: actionListStatusNames[ACTION_LIST.STATE_VERIFYING] }), _jsx("span", { children: "Severity" }), _jsxs("span", { className: "text-center", children: ["Troubleshooting ", _jsx("br", {}), " ", selectedSystemType === 'amazon' ? 'Question' : 'Waiting'] }), _jsx("span", { children: "Pre-Ai" }), _jsx("span", { style: { width: 30 } })] }), facilities.length !== 0 ? (_jsxs(Scrollbars, { onUpdate: onScrollFetch, className: "mb-2", style: { width: '100%', height: 'calc(100vh - 185px)' }, children: [Object.values(facilitiesByCustomer).map(function (facilities, index) { return (_jsxs("div", { children: [_jsxs("div", { className: "d-flex header-block p-2 fw-bold mt-1", children: [_jsx(Col, { md: 10, children: Object.keys(facilitiesByCustomer)[index] }), selectedSystemType !== 'amazon' ? (_jsx(Col, { md: 2, children: _jsx(Button, { title: "Edit sla settings for ".concat(Object.keys(facilitiesByCustomer)[index], " customer"), color: "primary", size: "sm", onClick: function () {
                                                            return onUpdateSettings({
                                                                customer_id: _get(facilities, '0.customer_id'),
                                                                name: _get(facilities, '0.customer_name'),
                                                            }, {}, {});
                                                        }, className: "float-end", children: _jsx(FaPencilAlt, {}) }) })) : (_jsx(_Fragment, {}))] }, "sla_settings_customer_".concat(index)), facilities.map(function (facility) { return (_jsx(SlaSettingsFacilityRow, { facility: facility, onUpdateSettings: onUpdateSettings }, "sla_settings_customer_".concat(index, "_").concat(facility.facility_id))); })] }, "sla_settings_customer_".concat(index))); }), pagination.lastDataLength >= pagination.pageSize ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {}))] })) : (_jsx(React.Fragment, { children: !loader ? _jsx("p", { children: "There are no facilities" }) : _jsx(_Fragment, {}) }))] }) }), _jsx(UpdateSlaSettingsModal, __assign({}, updateSlaSettingsModalState, { callback: function () {
                    return fetch(selectedSystemType, facilities, setFacilities, setPagination, setLoader, setOnScroll, pagination, selectedCustomers, selectedLocations, selectedFacility, onScroll);
                }, actionListStatusNames: actionListStatusNames }))] }));
};
var SlaSettingsFacilityRow = function (_a) {
    var facility = _a.facility, onUpdateSettings = _a.onUpdateSettings;
    return (_jsxs("div", { className: "w-100 mx-0 my-2 border-bottom p-2 d-flex justify-content-between", children: [_jsx("span", { style: { width: 150 }, children: facility.name }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.CUSTOM_SLA_WAITES_NEW_AI }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.STATE_NEW }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.STATE_WAITING }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.STATE_AWAITING_UPDATE }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.STATE_NEEDS_RESPONSE }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.STATE_VERIFYING }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.CUSTOM_SLA_STATE_SEVERITY_AI }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.CUSTOM_SLA_STATE_TROUBLESHOOT_WAITING_AI }) }), _jsx("span", { children: _jsx(SlaValues, { facility: facility, state: ACTION_LIST.CUSTOM_SLA_STATE_PRE_AI }) }), _jsx("span", { children: _jsx(Button, { title: "Edit sla settings for ".concat(facility.name, " facility"), color: "primary", size: "sm", onClick: function () {
                        return onUpdateSettings({
                            customer_id: _get(facility, 'customer_id'),
                            name: _get(facility, 'customer_name'),
                        }, facility, facility.slaSettingsFormatted);
                    }, className: "float-end", children: _jsx(FaPencilAlt, {}) }) })] }));
};
export default memo(SlaSettingsTable);
