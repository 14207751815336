import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { FaEye } from 'react-icons/fa';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { READING_TYPE_TWF_ACCELERATION_X } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { onViewInstallationPoint } from '../../features/shared';
import { usePreActionItemModalsActions } from '../../store/AIPreAi/usePreActionItemModalsStore';
var MlReportModal = function (_a) {
    var activeMlReport = _a.activeMlReport, installationPoint = _a.installationPoint;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setMlReportModal = usePreActionItemModalsActions().setMlReportModal;
    var onCancel = function () {
        setMlReportModal({
            visible: false,
            activeMlReport: null,
            callback: function () { },
        });
    };
    var content = (activeMlReport === null || activeMlReport === void 0 ? void 0 : activeMlReport.text)
        ? activeMlReport === null || activeMlReport === void 0 ? void 0 : activeMlReport.text.replace(/^\s+|\s+$/g, function (match) { return '<span style="display:inline-block;">' + '&nbsp;'.repeat(match.length) + '</span>'; }).replaceAll('\n', '</br>')
        : '';
    return (_jsxs(Modal, { size: "xl", className: "modal-response", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: [activeMlReport.twfDataTimestamp ? (_jsx(Button, { onClick: function () {
                            return onViewInstallationPoint(installationPoint.id, selectedSystemType, "&fft_date=".concat(activeMlReport.twfDataTimestamp, "&scroll_to=").concat(READING_TYPE_TWF_ACCELERATION_X));
                        }, size: "sm", className: "me-2", color: "primary", children: _jsx(FaEye, {}) })) : (_jsx(_Fragment, {})), "Rate ML diagnosis"] }), _jsxs(ModalBody, { children: [_jsx("p", { className: "h-5 fw-bold", children: "This is an instant diagnosis corresponding to the point closest to the date of creation of the Pre-AI and the point of taking the TWF of a specific sensor:" }), _jsx("div", { className: "h-4 mt-2 mb-2 font-monospace", dangerouslySetInnerHTML: { __html: content } })] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default memo(MlReportModal);
