import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Highcharts from "highcharts";
import { get as _get } from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";
import { shallow } from "zustand/shallow";
import { isTwfChart } from "../../../../../helper/chart";
import { ChartsStore } from "../../../../../store/charts/chartsContent/ChartsStore";
import "../../../../../styles/charts/chartsWrapper/fftXAxisDropdown.scss";
import { FrequencyConverter } from "../../features/fftTwf/frequencyConverter";
export var formatXAxisFFT = function (chart, value, frequencyType, currentSpeed, precision) {
    if (frequencyType === void 0) { frequencyType = 'Hz'; }
    var chartIdentifier = _get(chart, 'userOptions.chartIdentifier');
    if (!chartIdentifier) {
        return value;
    }
    if (isTwfChart(chartIdentifier)) {
        return value;
    }
    if (frequencyType === 'CPM') {
        return FrequencyConverter.fromHz(value, currentSpeed).toCpm().numberFormat(precision);
    }
    if (frequencyType === 'Orders') {
        // @ts-ignore
        return currentSpeed > 0
            ? Math.round(FrequencyConverter.fromHz(value, currentSpeed).toOrders().value) + 'x'
            : '';
    }
    return value;
};
export var formatYAxis3dTrend = function (value) {
    return Highcharts.numberFormat(value, 4, '.', '');
};
export var fftTicksPositioner = function (chart, dataMax, units, currentSpeed) {
    if (units === void 0) { units = 'Hz'; }
    var chartIdentifier = _get(chart, 'userOptions.chartIdentifier');
    if (!chartIdentifier || isTwfChart(chartIdentifier)) {
        return;
    }
    if (units !== 'Orders') {
        return;
    }
    if (currentSpeed > 0 && dataMax > 1) {
        var maxTicks = 15, hzDataMax = dataMax;
        var result = [];
        var step = FrequencyConverter.fromOrders(1, currentSpeed).toHz().value;
        // @ts-ignore
        if (chart.isZoomed()) {
            var zoomedHzDataMax = chart.xAxis[0].getExtremes().max, zoomedStep = FrequencyConverter.fromOrders(1, currentSpeed).toHz().value + 0.01;
            for (var i = zoomedStep; i < zoomedHzDataMax; i += zoomedStep) {
                result.push(i);
            }
            return result;
        }
        for (var i = step; i < hzDataMax; i += step) {
            result.push(i);
        }
        var calculatedResult = [];
        for (var i = 0; i < maxTicks; i++) {
            var index = Math.floor((i * (result.length - 1)) / (maxTicks - 1));
            calculatedResult.push(result[index]);
        }
        return calculatedResult;
    }
};
var FftXAxisDropdown = function (_a) {
    var chartIdentifier = _a.chartIdentifier, units = _a.units, dispatch = _a.dispatch, currentSpeed = _a.currentSpeed, isVisibleFullscreenChart = _a.isVisibleFullscreenChart, customCursorPointsCount = _a.customCursorPointsCount, isCircleTwfType = _a.isCircleTwfType;
    var chartCursorType = ChartsStore(function (state) { return ({
        chartCursorType: state.chartCursorType,
    }); }, shallow).chartCursorType;
    var setUnits = function (units) {
        dispatch({
            type: 'setState',
            state: {
                units: units,
            },
        });
    };
    useEffect(function () {
        setUnits('Hz');
    }, [isVisibleFullscreenChart]);
    var _b = useState(false), isVisibleSpeedDropdown = _b[0], setIsVisibleSpeedDropdown = _b[1];
    return (_jsx("div", { children: _jsxs("div", { className: "fft-x-axis-labels d-flex justify-content-center w-100 align-items-center", children: [isTwfChart(chartIdentifier) ? (_jsx("span", { className: "fft-units-label fft-units-dropdown-toggle-link", children: "Time" })) : (_jsxs(Dropdown, { direction: "up", isOpen: isVisibleSpeedDropdown, toggle: function () { return setIsVisibleSpeedDropdown(!isVisibleSpeedDropdown); }, children: [_jsx(DropdownToggle, { tag: "span", size: "sm", caret: true, children: _jsxs("span", { className: "fft-units-dropdown-toggle-link", children: ["Frequency (", units, ")"] }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { active: units === 'Hz', className: "btn-sm", onClick: function () { return setUnits('Hz'); }, children: "Hz" }), _jsx(DropdownItem, { active: units === 'CPM', className: "btn-sm", onClick: function () { return setUnits('CPM'); }, children: "CPM" }), _jsx(DropdownItem, { active: units === 'Orders', disabled: +currentSpeed === 0, className: "btn-sm", onClick: function () { return setUnits('Orders'); }, children: "Orders" })] })] })), (chartCursorType === 'harmonic' || chartCursorType === 'sideband') &&
                    customCursorPointsCount &&
                    !isCircleTwfType ? (_jsxs("div", { className: "fft-harmonics-count", children: [_jsx(Label, { className: "d-inline-block", children: _jsxs("span", { className: "tdu", children: ["Max ", chartCursorType === 'harmonic' ? 'Harmonics' : 'Sideband', ":"] }) }), _jsx(Input, { min: 1, step: 1, type: "number", className: "d-inline-block", onChange: function (ev) {
                                return dispatch({
                                    type: 'setState',
                                    state: {
                                        customCursorPointsCount: +ev.target.value > 0 ? +ev.target.value : 1,
                                    },
                                });
                            }, value: customCursorPointsCount })] })) : (_jsx(_Fragment, {}))] }) }));
};
export default FftXAxisDropdown;
