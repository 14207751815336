var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer } from 'react';
import { FaEye } from 'react-icons/fa';
import { Alert, Button, ButtonGroup, Col, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import Loader from '../../../shared/components/Loader';
import DataTable from '../../../shared/components/shared/Table/Table';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { onViewInstallationPoint } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { highlightRow, setLoader } from '../../../shared/helper/table';
import '../../../shared/styles/structure/structure.scss';
import { buildLocationsTree, onFacilityChange } from '../../features/structure';
import { useResetStructure } from '../../hooks/structure/useResetStructure';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { useSelectedLocationsActions } from '../../store/SelectedLocationsStore';
var initialState = function (onViewInstallationPoint, setSelectedLocations) {
    return {
        structure: [],
        selectedLocations: [],
        selectedLocation: null,
        config: {
            columns: [
                {
                    name: 'name',
                    title: 'Equipment',
                    sortable: false,
                },
                {
                    name: 'count',
                    title: 'Count sensors',
                    sortable: false,
                    component: function (row) {
                        return row.installationPoints ? row.installationPoints.length : 0;
                    },
                },
                {
                    name: 'action',
                    title: '',
                    sortable: false,
                    component: function (row) {
                        return (_jsxs("div", { className: "response-ignore", children: [_jsx(ButtonGroup, { children: _jsxs(Button, { color: "success", size: "sm", onClick: function (ev) {
                                            var createParams = "&customer_id=".concat(row.customer_id, "&location_id=").concat(row.location_id);
                                            setSelectedLocations([row.location_id]);
                                            onViewInstallationPoint(0, encodeURIComponent(createParams));
                                            highlightRow(ev);
                                        }, children: [_jsx(FaEye, {}), " View Location"] }) }), _jsx(ButtonGroup, { className: "ms-2", children: _jsxs(Button, { color: "primary", size: "sm", onClick: function (ev) {
                                            var createParams = "&equipment=".concat(row.name, "&location_id=").concat(row.location_id, "&customer_id=").concat(row.customer_id);
                                            setSelectedLocations([row.location_id]);
                                            onViewInstallationPoint(0, encodeURIComponent(createParams));
                                            highlightRow(ev);
                                        }, children: [_jsx(FaEye, {}), " View Equipment"] }) })] }));
                    },
                },
            ],
            pagination: {
                disabled: true,
            },
            hideTotalLabel: true,
            search: '',
            disabledSearch: true,
            data: [],
            loader: true,
        },
    };
};
var fetch = function (state, dispatch, selectedCustomers) {
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getCustomerStructure({
        query: {
            selected_customer_ids: selectedCustomers,
        },
    })
        .then(function (resp) {
        if (resp) {
            var locationsTree = [];
            dispatch({
                type: 'setState',
                state: {
                    structure: buildLocationsTree(resp.structure, locationsTree),
                },
            });
        }
        setLoader(state, dispatch, false);
    })
        .catch(function () {
        setLoader(state, dispatch, false);
    });
};
var fetchEquipments = function (state, dispatch) {
    var config = state.config, selectedLocation = state.selectedLocation;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getCustomerStructureEquipments({
        query: {
            selected_customer_ids: selectedLocation ? [selectedLocation.customer_id] : null,
            location_id: selectedLocation ? selectedLocation.id : null,
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data.equipments.map(function (equipment) {
                            equipment.customer_id = selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.customer_id;
                            return equipment;
                        }), loader: false }),
                },
            });
        }
    })
        .catch(function () {
        setLoader(state, dispatch, false);
    });
};
var StructurePage = function () {
    var selectedCustomers = selectSelectedCustomers();
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = useReducer(reducer, initialState(function (installationPointId, params) {
        return onViewInstallationPoint(installationPointId, selectedSystemType, params);
    }, setSelectedLocations)), state = _a[0], dispatch = _a[1];
    var config = state.config, structure = state.structure, selectedLocations = state.selectedLocations, selectedLocation = state.selectedLocation;
    var loader = config.loader;
    useTitle('Structure');
    useResetStructure([selectedCustomers], state, dispatch);
    useEffect(function () {
        if (!selectedCustomers.length) {
            return;
        }
        fetch(state, dispatch, selectedCustomers);
    }, [selectedCustomers]);
    useEffect(function () {
        if (selectedLocation) {
            fetchEquipments(state, dispatch);
        }
    }, [selectedLocation]);
    if (!selectedCustomers.length) {
        return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { className: "page-title", children: "Structure" }), _jsx(Row, { children: _jsx(Col, { md: 3, children: _jsx(Alert, { color: "danger", children: "Choose customer first" }) }) })] }) }) }) }));
    }
    return (_jsx("div", { className: "page-Structure", children: _jsx(Row, { children: _jsxs(Col, { style: { minHeight: '90vh' }, children: [_jsx("h3", { className: "page-title", children: "Structure" }), loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "structure-firstLayer-container", children: structure.map(function (facility) { return (_jsxs(Button, { className: "me-3 position-relative", onClick: function () { return onFacilityChange(state, dispatch, facility); }, color: "info", size: "sm", children: [facility.name, facility.children.length > 0 && (_jsx("span", { className: "structure-firstLayer-badge", children: facility.children.length }))] }, "structure-item-firstLayer-".concat(facility.facility_id))); }) }), selectedLocations.length > 0 && (_jsx("div", { className: "structure-secondLayer-container mt-2", children: selectedLocations.map(function (location) { return (_jsx(Button, { className: "me-2 mb-2 ".concat(selectedLocation && +location.id === +selectedLocation.id ? 'active' : ''), color: "primary", onClick: function () {
                                        dispatch({
                                            type: 'setState',
                                            state: {
                                                selectedLocation: location,
                                            },
                                        });
                                    }, size: "sm", children: location.name }, "structure-item-secondLayer-".concat(location.id))); }) })), selectedLocation && (_jsx("div", { className: "table_wrap", children: _jsx(DataTable, { config: config }) }))] }))] }) }) }));
};
export default StructurePage;
