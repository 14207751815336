var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ActionItemCreatePage from "../page/actionItems/Create";
import ActiveActionListPage from "../page/actionItems/List/Active";
import ArchiveActionListPage from "../page/actionItems/List/Archive";
import ByInstallationPointActionListPage from "../page/actionItems/List/ByInstallationPoint";
import HardwareActionListPage from "../page/actionItems/List/Hardware";
import ActionItemViewPage from "../page/actionItems/View";
import CommentsActionListPage from "../page/actionItemsComments";
export var actionList = function (props) {
    return [
        {
            path: '/amazon/action-list',
            element: _jsx(ActiveActionListPage, __assign({}, props)),
        },
        {
            path: '/amazon/action-item/:id',
            element: _jsx(ActionItemViewPage, __assign({}, props)),
        },
        {
            path: '/amazon/action-item/create/:id',
            element: _jsx(ActionItemCreatePage, __assign({}, props)),
        },
        {
            path: '/amazon/action-list/archive',
            element: _jsx(ArchiveActionListPage, __assign({}, props)),
        },
        {
            path: '/amazon/action-list/network',
            element: _jsx(HardwareActionListPage, __assign({}, props)),
        },
        {
            path: '/amazon/action-list-comments',
            element: _jsx(CommentsActionListPage, __assign({}, props)),
        },
        {
            path: '/amazon/action-list/by-installation-point/:installation_point_id',
            element: _jsx(ByInstallationPointActionListPage, __assign({}, props)),
        },
        // {
        //     path: "/amazon/suggested-close",
        //     element: <SuggestedClosePage {...props}/>,
        // },
    ];
};
