import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from "lodash";
import React from "react";
import { FiRefreshCcw } from "react-icons/fi";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";
export var Filters = function (_a) {
    var onFilterChange = _a.onFilterChange, onFilterLevelChange = _a.onFilterLevelChange, activeFilters = _a.activeFilters, isRefreshInProgress = _a.isRefreshInProgress, onRefreshClick = _a.onRefreshClick, lastRefreshRequest = _a.lastRefreshRequest;
    if (!activeFilters) {
        return _jsx(_Fragment, {});
    }
    var lastRefreshRequestText = '';
    if (lastRefreshRequest) {
        var userName = _size(lastRefreshRequest.user)
            ? "by ".concat(lastRefreshRequest.user.name_first, " ").concat(lastRefreshRequest.user.name_last)
            : '';
        lastRefreshRequestText = "Request for refresh alert counts sent ".concat(userName, " at ").concat(lastRefreshRequest.created_at);
        if (+lastRefreshRequest.is_processing === 1) {
            lastRefreshRequestText = "Request for refresh alert counts still processing. Sent ".concat(userName, " at ").concat(lastRefreshRequest.created_at);
        }
        if (+lastRefreshRequest.is_processing === 0 && lastRefreshRequest.processed_at) {
            lastRefreshRequestText = "Request for refresh alert counts processed at ".concat(lastRefreshRequest.processed_at, ". Sent ").concat(userName);
        }
    }
    return (_jsx(React.Fragment, { children: _jsxs("div", { className: "mb-2 response-pad", children: [_jsx("div", { className: "float-start", children: _jsxs(ButtonGroup, { className: "response_group d-inline-block", children: [_jsx(Button, { color: activeFilters['in_alarm'] ? 'primary' : 'secondary', size: "sm", onClick: function () { return onFilterChange('in_alarm'); }, children: "In alarm" }), _jsx(Button, { color: activeFilters['hide_on_bed_ai'] ? 'primary' : 'secondary', size: "sm", onClick: function () { return onFilterChange('hide_on_bed_ai'); }, children: "Hide if AI exists on Bed" }), _jsx(Button, { color: activeFilters['hide_ai'] ? 'primary' : 'secondary', size: "sm", onClick: function () { return onFilterChange('hide_ai'); }, children: "Hide existing AI" }), _jsx(Button, { color: activeFilters['hide_bs'] ? 'primary' : 'secondary', size: "sm", onClick: function () { return onFilterChange('hide_bs'); }, children: "Hide Bad Sensors" }), _jsx(Button, { color: activeFilters['level'] === 'caution' || activeFilters['level'] === ''
                                    ? 'warning'
                                    : 'secondary', size: "sm", onClick: function () { return onFilterLevelChange('caution'); }, children: "Caution" }), _jsx(Button, { color: activeFilters['level'] === 'warning' || activeFilters['level'] === '' ? 'danger' : 'secondary', size: "sm", onClick: function () { return onFilterLevelChange('warning'); }, children: "Warning" })] }) }), _jsxs("div", { className: "float-end refresh-btn-wrapper", children: [_jsx("span", { id: "refresh-alert-count-button", className: "d-inline-block", children: _jsxs(Button, { color: "success", size: "sm", onClick: onRefreshClick, disabled: isRefreshInProgress, children: [_jsx(FiRefreshCcw, {}), " Refresh"] }) }), lastRefreshRequestText ? (_jsx(UncontrolledTooltip, { target: "refresh-alert-count-button", children: lastRefreshRequestText })) : (_jsx(_Fragment, {}))] })] }) }));
};
