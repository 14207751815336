// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miss-ai-modal {
  --svg-size: 18px;
  --checkbox-size: 18px;
  --margin: 10px;
}
.miss-ai-modal .miss-ai-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d02d05;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-left: var(--margin);
}
.miss-ai-modal .miss-ai-icon svg {
  width: var(--svg-size);
  height: var(--svg-size);
  fill: #fff;
}
.miss-ai-modal label {
  font-weight: 500;
  font-size: var(--checkbox-size);
}
.miss-ai-modal label input[type=checkbox] {
  margin-left: var(--margin);
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/actionItem/AiMissModal/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qBAAA;EACA,cAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;AAEJ;AAAI;EACE,sBAAA;EACA,uBAAA;EACA,UAAA;AAEN;AAEE;EACE,gBAAA;EACA,+BAAA;AAAJ;AACI;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AACN","sourcesContent":[".miss-ai-modal {\n  --svg-size: 18px;\n  --checkbox-size: 18px;\n  --margin: 10px;\n  & .miss-ai-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #d02d05;\n    width: 26px;\n    height: 26px;\n    border-radius: 50%;\n    margin-left: var(--margin);\n\n    & svg {\n      width: var(--svg-size);\n      height: var(--svg-size);\n      fill: #fff;\n    }\n  }\n\n  & label {\n    font-weight: 500;\n    font-size: var(--checkbox-size);\n    & input[type='checkbox'] {\n      margin-left: var(--margin);\n      width: var(--checkbox-size);\n      height: var(--checkbox-size);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
