import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { reportEditorOptionsKey } from './useReportEditorOptionsQuery';
var reportEditorOptionUpdateFn = function (coreLink, id, name, entityName) {
    return Api.post("".concat(coreLink, "/report-editor/update-item/").concat(id), { entityName: entityName, name: name });
};
export var useReportEditorOptionUpdateMutation = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var id = _a.id, name = _a.name, entityName = _a.entityName;
            return reportEditorOptionUpdateFn(getCoreUrl(selectedSystemType), id, name, entityName);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: reportEditorOptionsKey() });
        },
    });
};
