import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';
import AlertLogContent from '../../../../widgets/alertLogContent';
import { DateTimeLabel } from '../../../../widgets/common';
import LinkWithPrefix from '../../../components/LinkWithPrefix';
export var columns = [
    {
        name: 'val2',
        title: 'Related Name',
        sortable: true,
    },
    {
        name: 'val4',
        title: 'Sensor',
        sortable: true,
    },
    {
        name: 'val3',
        title: 'Reading Type',
        sortable: true,
    },
    {
        name: 'new_data',
        title: 'New data',
        sortable: false,
        component: function (row) {
            return (_jsx(React.Fragment, { children: Object.values(row.data).map(function (item) { return (_jsx(AlertLogContent, { readingTypeId: +item.reading_type_id, isNewData: true, showAxis: true, cautionValue: +item.caution, warningValue: +item.warning }, item.reading_type_id)); }) }));
        },
    },
    {
        name: 'date_create',
        title: 'Time',
        sortable: true,
        component: function (row) {
            return (_jsx(DateTimeLabel, { id: "default-alert-log-".concat(row.id), dateTime: row.date_create || row.created_at }));
        },
    },
    {
        name: 'user_name',
        title: 'User',
        sortable: true,
    },
    {
        name: 'actions',
        title: '',
        sortable: false,
        component: function (row) {
            if (_get(row, 'installationPoint_id')) {
                return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(+_get(row, 'installationPoint_id')), target: '_blank', children: _jsxs(Button, { size: "sm", color: "success", children: [_jsx(FaEye, {}), " View component"] }) }));
            }
            return _jsx(_Fragment, {});
        },
    },
];
