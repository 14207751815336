var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { IS_SHOW_LAST_VIEWED_INACTIVE, IS_SHOW_LAST_VIEWED_WAITING_REQUEST } from '../../../../constants';
import { onRequestChildren } from '../../../features/alerts/triggered';
export var useShowTreeLastViewedEffect = function (deps, state, dispatch, endpoint) {
    var config = state.config, isShowLastViewed = state.isShowLastViewed, lastViewedItem = state.lastViewedItem;
    var filtersData = config.filtersData, data = config.data;
    var equipmentId = lastViewedItem.equipmentId, alertId = lastViewedItem.alertId, exists = lastViewedItem.exists;
    useEffect(function () {
        if (!alertId ||
            !exists ||
            !equipmentId ||
            [IS_SHOW_LAST_VIEWED_INACTIVE, IS_SHOW_LAST_VIEWED_WAITING_REQUEST].includes(isShowLastViewed)) {
            return;
        }
        var itemFirstLevel = data.filter(function (el) { return +el.equipment_id === +equipmentId && el.isFirstLevel; })[0];
        if (!filtersData || !itemFirstLevel) {
            return;
        }
        onRequestChildren(state, dispatch, itemFirstLevel, function () { return endpoint(itemFirstLevel.equipment_id); });
        dispatch({
            type: 'setState',
            state: {
                isShowLastViewed: IS_SHOW_LAST_VIEWED_INACTIVE,
            },
        });
    }, __spreadArray([], deps, true));
};
