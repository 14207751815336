import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../customHooks';
import BaseActionItemsPage from '../../../components/actionItem/list/BasePage';
var ByInstallationPointActionListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    useTitle('Action List');
    var installation_point_id = useParams().installation_point_id;
    return (_jsx(BaseActionItemsPage, { refreshFlag: refreshFlag, installationPointId: installation_point_id, selectedFacility: selectedFacility, title: "Action items", tableName: 'amazonActionListByInstallationPointTable', isActive: false }));
};
export default ByInstallationPointActionListPage;
