import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Row } from 'reactstrap';
import { onChangeDateFilter, onChangeUserFilter, onResetFilters } from '../../../features/alertLog';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../helper/table';
import FilterSensorSearch from '../../FilterSensorSearch';
import DataTable from '../../shared/Table/Table';
var AlertLogContent = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, callbackFetch = _a.callbackFetch, _b = _a.disabledFilterSensorSearch, disabledFilterSensorSearch = _b === void 0 ? false : _b;
    var config = state.config, sensorIds = state.sensorIds;
    return (_jsx(Row, { className: "my-3", children: _jsxs(Col, { children: [!disabledFilterSensorSearch ? _jsx(FilterSensorSearch, { state: state, dispatch: dispatch }) : _jsx(_Fragment, {}), sensorIds || disabledFilterSensorSearch ? (_jsx("div", { className: "table_wrap align-pagination-section", children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, onPageSizeChange: function (pageSize) {
                            return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                        }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); }, callbacks: {
                            onChangeUserFilter: function (value) { return onChangeUserFilter(state, dispatch, value); },
                            onChangeDateFilter: function (startDate, endDate) {
                                return onChangeDateFilter(state, dispatch, startDate, endDate);
                            },
                            onResetFilters: function () { return onResetFilters(state, dispatch); },
                        } }) })) : (_jsx(_Fragment, {}))] }) }));
};
export default AlertLogContent;
