import { jsx as _jsx } from "react/jsx-runtime";
import { round as _round } from 'lodash';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { SensorId } from '../../../../widgets/sensor';
import { Filters } from '../../../components/alerts/counts/Filter';
import { highlightRow } from '../../../helper/table';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { hexBreak: true, notShowEmptyHex: true, sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'percentage_temperature_alerts_1d',
                title: 'Temp (24h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_temperature_alerts_1d || 0, 1);
                },
            },
            {
                name: 'percentage_temperature_alerts_2d',
                title: 'Temp (48h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_temperature_alerts_2d || 0, 1);
                },
            },
            {
                name: 'percentage_temperature_alerts_7d',
                title: 'Temp (7d)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_temperature_alerts_7d || 0, 1);
                },
            },
            {
                name: 'percentage_acceleration_alerts_1d',
                title: 'Acc (24h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_acceleration_alerts_1d || 0, 1);
                },
            },
            {
                name: 'percentage_acceleration_alerts_2d',
                title: 'Acc (48h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_acceleration_alerts_2d || 0, 1);
                },
            },
            {
                name: 'percentage_acceleration_alerts_7d',
                title: 'Acc (7d)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_acceleration_alerts_7d || 0, 1);
                },
            },
            {
                name: 'percentage_velocity_alerts_1d',
                title: 'Vel (24h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_velocity_alerts_1d || 0, 1);
                },
            },
            {
                name: 'percentage_velocity_alerts_2d',
                title: 'Vel (48h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_velocity_alerts_2d || 0, 1);
                },
            },
            {
                name: 'percentage_velocity_alerts_7d',
                title: 'Vel (7d)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_velocity_alerts_7d || 0, 1);
                },
            },
            {
                name: 'percentage_pk_pk_alerts_1d',
                title: 'PK-PK (24h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_pk_pk_alerts_1d || 0, 1);
                },
            },
            {
                name: 'percentage_pk_pk_alerts_2d',
                title: 'PK-PK (48h)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_pk_pk_alerts_2d || 0, 1);
                },
            },
            {
                name: 'percentage_pk_pk_alerts_7d',
                title: 'PK-PK (7d)',
                sortable: true,
                component: function (row) {
                    return _round(row.percentage_pk_pk_alerts_7d || 0, 1);
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(Button, { onClick: function (e) {
                            onViewClick(row.installation_point_id);
                            highlightRow(e);
                        }, color: "primary", size: "sm", children: "View" }));
                },
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        isSmallTable: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Row, { className: "response-flex", children: _jsx(Col, { className: "mb-2", children: _jsx(Filters, { onFilterChange: callbacks.onFilterChange, onFilterLevelChange: callbacks.onFilterLevelChange, activeFilters: filtersData.activeFilters, lastRefreshRequest: filtersData.lastRefreshRequest, isRefreshInProgress: filtersData.isRefreshInProgress, onRefreshClick: callbacks.onRefreshClick }) }) }));
        },
        filtersData: {
            activeFilters: null,
            lastRefreshRequest: null,
            isRefreshInProgress: null,
        },
    };
};
