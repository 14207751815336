import { useEffect } from 'react';
import { selectLockTooltipsPosition } from '../../../store/charts/useChartStore';
export var useLockRmsTooltipPosition = function (dispatch, chartRef) {
    var lockTooltipsPosition = selectLockTooltipsPosition();
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                lockTooltipsPosition: lockTooltipsPosition,
            },
        });
        chartRef.current.chart.userOptions.lockTooltipPosition = lockTooltipsPosition;
    }, [lockTooltipsPosition]);
};
