var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheck, FaTrash } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { formatOptionLabel } from '../../features/reportEditor';
import { useReportEditorOptionCreateMutation } from '../../services/reportEditor/useReportEditorOptionCreateMutation';
import { useReportEditorOptionDeleteMutation } from '../../services/reportEditor/useReportEditorOptionDeleteMutation';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
import ReportEditorEntityEditModal from './EntityEditModal';
var TITLE_MAPPING = {
    componentType: 'Component Type',
    subType: 'Sub Type',
    fault: 'Fault',
    recommendations: 'Recommendations',
};
var ReportEditorFormModal = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var reportEditorModalState = _a.reportEditorModalState, onCancel = _a.onCancel, reportEditorData = _a.reportEditorData;
    var queryClient = useQueryClient();
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var reportEditorOptionDeleteMutation = useReportEditorOptionDeleteMutation().mutate;
    var reportEditorOptionCreateMutation = useReportEditorOptionCreateMutation().mutate;
    var _k = useState(reportEditorModalState.isNew), isNew = _k[0], setIsNew = _k[1];
    var _l = useState({
        visible: false,
        entityName: 'componentType',
        id: 0,
        name: '',
    }), reportEditorEntityEditState = _l[0], setReportEditorEntityEditState = _l[1];
    var _m = reportEditorData || {
        componentTypes: [],
        subTypes: [],
        faults: [],
        recommendations: [],
    }, subTypes = _m.subTypes, faults = _m.faults, recommendations = _m.recommendations;
    var _o = useState(isNew
        ? []
        : [
            {
                label: ((_b = reportEditorModalState.componentType) === null || _b === void 0 ? void 0 : _b.name) || 'Generic Recommendations',
                value: ((_c = reportEditorModalState.componentType) === null || _c === void 0 ? void 0 : _c.id) || null,
            },
        ]), componentTypeOptions = _o[0], setComponentTypeOptions = _o[1];
    var _p = useState([]), subTypeOptions = _p[0], setSubTypeOptions = _p[1];
    var _q = useState([]), faultsOptions = _q[0], setFaultsOptions = _q[1];
    var _r = useState([]), recommendationsOptions = _r[0], setRecommendationsOptions = _r[1];
    var _s = useState({
        label: ((_d = reportEditorModalState.subType) === null || _d === void 0 ? void 0 : _d.name) || 'All',
        value: ((_e = reportEditorModalState.subType) === null || _e === void 0 ? void 0 : _e.id) || null,
    }), selectedSubTypeOption = _s[0], setSelectedSubTypeOption = _s[1];
    useEffect(function () {
        setSubTypeOptions(__spreadArray([
            { label: 'All', value: null }
        ], subTypes
            .filter(function (subType) { var _a; return subType.component_type_id === (((_a = componentTypeOptions[0]) === null || _a === void 0 ? void 0 : _a.value) || null); })
            .map(function (subType) { return ({
            label: subType.name,
            value: subType.id,
        }); }), true));
    }, [subTypes, (_f = componentTypeOptions[0]) === null || _f === void 0 ? void 0 : _f.value]);
    useEffect(function () {
        setFaultsOptions(faults
            .filter(function (fault) { var _a; return fault.component_type_id === (((_a = componentTypeOptions[0]) === null || _a === void 0 ? void 0 : _a.value) || null); })
            .filter(function (fault) { return fault.sub_type_id === ((selectedSubTypeOption === null || selectedSubTypeOption === void 0 ? void 0 : selectedSubTypeOption.value) || null); })
            .map(function (fault) { return ({
            label: fault.name,
            value: fault.id,
        }); }));
    }, [faults, (_g = componentTypeOptions[0]) === null || _g === void 0 ? void 0 : _g.value, selectedSubTypeOption]);
    useEffect(function () {
        if (isNew) {
            return setRecommendationsOptions([]);
        }
        setRecommendationsOptions(recommendations
            .filter(function (recommendation) { var _a; return recommendation.component_type_id === (((_a = componentTypeOptions[0]) === null || _a === void 0 ? void 0 : _a.value) || null); })
            .filter(function (recommendation) { return recommendation.sub_type_id === ((selectedSubTypeOption === null || selectedSubTypeOption === void 0 ? void 0 : selectedSubTypeOption.value) || null); })
            .map(function (recommendation) { return ({
            label: recommendation.name,
            value: recommendation.id,
        }); }));
    }, [recommendations, (_h = componentTypeOptions[0]) === null || _h === void 0 ? void 0 : _h.value, selectedSubTypeOption]);
    var onEdit = function (id, name, entityName) {
        setReportEditorEntityEditState({ visible: true, id: id, name: name, entityName: entityName });
    };
    var onDelete = function (id, entityName) {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: "Are you sure you want to delete this ".concat(TITLE_MAPPING[entityName], "?"),
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                reportEditorOptionDeleteMutation({ id: id, entityName: entityName }, {
                    onSuccess: function (resp) {
                        if (resp.success) {
                            toast.success('Successfully removed', { icon: _jsx(FaTrash, { style: { fill: '#198754' } }) });
                            queryClient.invalidateQueries({ queryKey: ['reportEditorList'] });
                            if (entityName === 'componentType' || entityName === 'subType') {
                                onCancel();
                                return;
                            }
                            if (entityName === 'recommendations') {
                                setRecommendationsOptions(function (prevState) { return prevState.filter(function (item) { return item.value !== id; }); });
                                return;
                            }
                            if (entityName === 'fault') {
                                setFaultsOptions(function (prevState) { return prevState.filter(function (item) { return item.value !== id; }); });
                                return;
                            }
                        }
                    },
                });
            },
        });
    };
    var onChange = function (item, meta, entityName) {
        var _a;
        if (meta.action === 'create-option') {
            reportEditorOptionCreateMutation({
                componentType: (_a = componentTypeOptions[0]) === null || _a === void 0 ? void 0 : _a.value,
                subType: selectedSubTypeOption === null || selectedSubTypeOption === void 0 ? void 0 : selectedSubTypeOption.value,
                name: item === null || item === void 0 ? void 0 : item.label,
                entityName: entityName,
            }, {
                onSuccess: function (resp) {
                    if (resp.success) {
                        setIsNew(false);
                        queryClient.invalidateQueries({ queryKey: ['reportEditorList'] });
                        toast.success('Successfully created', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                        var option_1 = { label: resp.entity.name, value: resp.entity.id };
                        if (entityName === 'componentType') {
                            setComponentTypeOptions(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [option_1], false); });
                        }
                        if (entityName === 'subType') {
                            setSubTypeOptions(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [option_1], false); });
                            setSelectedSubTypeOption(option_1);
                        }
                        if (entityName === 'fault') {
                            setFaultsOptions(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [option_1], false); });
                        }
                        if (entityName === 'recommendations') {
                            setRecommendationsOptions(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [option_1], false); });
                        }
                    }
                },
            });
            return;
        }
        if (entityName === 'subType') {
            setSelectedSubTypeOption(item);
        }
    };
    var editCallback = function (entityName, name, id) {
        queryClient.invalidateQueries({ queryKey: ['reportEditorList'] });
        if (entityName === 'componentType') {
            var updatedOptions = componentTypeOptions.map(function (option) {
                if (option.value === id) {
                    option.label = name;
                }
                return option;
            });
            setComponentTypeOptions(updatedOptions);
            return;
        }
        if (entityName === 'subType') {
            var updatedOptions = subTypeOptions.map(function (option) {
                if (option.value === id) {
                    option.label = name;
                }
                return option;
            });
            setSubTypeOptions(updatedOptions);
        }
        if (entityName === 'fault') {
            var updatedOptions = faultsOptions.map(function (option) {
                if (option.value === id) {
                    option.label = name;
                }
                return option;
            });
            setFaultsOptions(updatedOptions);
        }
        if (entityName === 'recommendations') {
            var updatedOptions = recommendationsOptions.map(function (option) {
                if (option.value === id) {
                    option.label = name;
                }
                return option;
            });
            setRecommendationsOptions(updatedOptions);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { size: "xxl", className: "modal-response", toggle: onCancel, isOpen: reportEditorModalState.visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Editing The Fault Names for the", ' ', ((_j = reportEditorModalState.componentType) === null || _j === void 0 ? void 0 : _j.name) || 'Generic Recommendations'] }), _jsxs(ModalBody, { style: { minHeight: '85vh' }, children: [_jsxs(Row, { className: "mt-2", children: [_jsx(Col, { md: 2, children: _jsx(Label, { children: "Component Type" }) }), _jsx(Col, { md: 10, children: _jsx(CreatableSelect, { isSearchable: isNew ? undefined : false, menuIsOpen: isNew ? undefined : false, styles: {
                                                dropdownIndicator: function (styles) { return (__assign(__assign({}, styles), { display: 'none' })); },
                                                menuList: function (styles) { return (__assign(__assign({}, styles), { maxHeight: '200px' })); },
                                                option: function (styles) { return (__assign(__assign({}, styles), { color: '#000' })); },
                                            }, placeholder: "Edit Component Type", classNamePrefix: "customSelectStyleDefault", formatOptionLabel: function (item) {
                                                return formatOptionLabel(item, { context: 'menu' }, function () { return onEdit(item.value, item.label, 'componentType'); }, function () { return onDelete(item.value, 'componentType'); });
                                            }, value: componentTypeOptions[0], onChange: function (item, meta) { return onChange(item, meta, 'componentType'); }, options: componentTypeOptions }) })] }), _jsxs(Row, { className: "mt-2", children: [_jsx(Col, { md: 2, children: _jsx(Label, { children: "Sub Type" }) }), _jsx(Col, { md: 10, children: _jsx(CreatableSelect, { styles: {
                                                menuList: function (styles) { return (__assign(__assign({}, styles), { maxHeight: '200px' })); },
                                                option: function (styles) { return (__assign(__assign({}, styles), { color: '#000' })); },
                                            }, placeholder: "Edit Sub Type", classNamePrefix: "customSelectStyleDefault", formatOptionLabel: function (item, options) {
                                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'subType'); }, function () { return onDelete(item.value, 'subType'); });
                                            }, 
                                            //@ts-ignore
                                            value: selectedSubTypeOption, onChange: function (item, meta) { return onChange(item, meta, 'subType'); }, options: subTypeOptions }) })] }), _jsxs(Row, { style: { marginTop: 220 }, children: [_jsx(Col, { md: 2, children: _jsx(Label, { children: "Fault Names" }) }), _jsx(Col, { md: 10, children: _jsx(CreatableSelect, { menuIsOpen: true, styles: {
                                                dropdownIndicator: function (styles) { return (__assign(__assign({}, styles), { display: 'none' })); },
                                                menuList: function (styles) { return (__assign(__assign({}, styles), { maxHeight: '200px' })); },
                                                option: function (styles) { return (__assign(__assign({}, styles), { color: '#000' })); },
                                            }, placeholder: "Edit Fault Names", classNamePrefix: "customSelectStyleDefault", formatOptionLabel: function (item, options) {
                                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'fault'); }, function () { return onDelete(item.value, 'fault'); });
                                            }, 
                                            //@ts-ignore
                                            isOptionDisabled: function (item) { return !item.__isNew__; }, value: null, onChange: function (item, meta) { return onChange(item, meta, 'fault'); }, options: faultsOptions }) })] }), _jsxs(Row, { style: { marginTop: 220 }, children: [_jsx(Col, { md: 2, children: _jsx(Label, { children: "Recommendations" }) }), _jsx(Col, { md: 10, children: _jsx(CreatableSelect, { menuIsOpen: true, styles: {
                                                dropdownIndicator: function (styles) { return (__assign(__assign({}, styles), { display: 'none' })); },
                                                menuList: function (styles) { return (__assign(__assign({}, styles), { maxHeight: '200px' })); },
                                                option: function (styles) { return (__assign(__assign({}, styles), { color: '#000' })); },
                                            }, placeholder: "Edit Recommendations", classNamePrefix: "customSelectStyleDefault", formatOptionLabel: function (item, options) {
                                                return formatOptionLabel(item, options, function () { return onEdit(item.value, item.label, 'recommendations'); }, function () { return onDelete(item.value, 'recommendations'); });
                                            }, 
                                            //@ts-ignore
                                            isOptionDisabled: function (item) { return !item.__isNew__; }, value: null, onChange: function (item, meta) { return onChange(item, meta, 'recommendations'); }, options: recommendationsOptions }) })] })] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Close" }) }) })] }), reportEditorEntityEditState.visible ? (_jsx(ReportEditorEntityEditModal, { reportEditorEntityEditState: reportEditorEntityEditState, callback: editCallback, onCancel: function () { return setReportEditorEntityEditState(function (prev) { return (__assign(__assign({}, prev), { visible: false })); }); } })) : (_jsx(_Fragment, {}))] }));
};
export default memo(ReportEditorFormModal);
