import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../customHooks';
import BaseActionItemsPage from '../../../components/actionItem/list/BasePage';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
var ByInstallationPointActionListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Action List');
    var selectedCustomers = selectSelectedCustomers();
    var installation_point_id = useParams().installation_point_id;
    return (_jsx(BaseActionItemsPage, { refreshFlag: refreshFlag, installationPointId: installation_point_id, selectedCustomers: selectedCustomers, selectedLocations: [], title: "Action items", tableName: 'actionListByInstallationPointTable', isActive: false }));
};
export default ByInstallationPointActionListPage;
