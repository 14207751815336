import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Button } from "reactstrap";
import { isFftChart } from "../../../../../../helper/chart";
export var AutoCorrelation = function (_a) {
    var chartIdentifier = _a.chartIdentifier, chart = _a.chart, dispatch = _a.dispatch, autoCorrelationIsVisible = _a.autoCorrelationIsVisible, width = _a.width, isDisabled = _a.isDisabled;
    if (isFftChart(chartIdentifier)) {
        return _jsx(React.Fragment, {});
    }
    var onClick = useCallback(function () {
        chart.zoomOut();
        dispatch({
            type: 'setState',
            state: {
                autoCorrelationIsVisible: !autoCorrelationIsVisible,
            },
        });
    }, [autoCorrelationIsVisible]);
    return (_jsx("div", { className: "chart-btn", children: _jsxs(Button, { className: "auto-correlation chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", size: "sm", disabled: isDisabled, onClick: onClick, style: { width: width }, children: [autoCorrelationIsVisible ? 'Hide' : 'Show', " ", _jsx("b", { children: "Auto-correlation" })] }) }));
};
