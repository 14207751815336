var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
export var usePeakUpdatePlotLinesZone = function (deps, hidePeaksState, hidePeaksDispatch) {
    var peak = hidePeaksState.peak, options = hidePeaksState.options;
    var yAxis = options.yAxis, series = options.series;
    useEffect(function () {
        hidePeaksDispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { yAxis: [
                        __assign(__assign({}, yAxis[0]), { plotLines: peak
                                ? [
                                    {
                                        className: 'alarm-plot-line-hide-peak',
                                        value: peak,
                                        zIndex: 4,
                                    },
                                ]
                                : [] }),
                    ], series: series
                        .filter(function (el) { return el.isMainSeries; })
                        .map(function (el, index) {
                        if (index) {
                            return el;
                        }
                        return __assign(__assign({}, el), { zones: peak
                                ? [
                                    {
                                        value: +peak,
                                        color: '#7cb5ec',
                                    },
                                    {
                                        color: '#ff0000',
                                    },
                                ]
                                : [] });
                    }) }),
            },
        });
    }, __spreadArray([], deps, true));
};
