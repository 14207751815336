import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Badge } from 'reactstrap';
var RecalculationStatus = function (_a) {
    var status = _a.status;
    if (status === 0) {
        return (_jsx(Badge, { color: "info", size: "sm", children: "New" }));
    }
    if (status === 1) {
        return (_jsx(Badge, { color: "warning", size: "sm", children: "Processing" }));
    }
    if (status === 2) {
        return (_jsx(Badge, { color: "success", size: "sm", children: "Finished" }));
    }
    if (status === 3) {
        return (_jsx(Badge, { color: "danger", size: "sm", children: "Error" }));
    }
    return _jsx(_Fragment, {});
};
export default memo(RecalculationStatus);
