import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { get as _get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import 'react-contexify/ReactContexify.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Button } from 'reactstrap';

import { METRIC_KEY, METRIC_KEY_NAME } from '../constants';
import Loader from '../shared/components/Loader';
import { SystemTypeProvider } from '../shared/context/SystemTypeContext';
import { getLastActivityTime, setLogin, setLogout } from '../shared/features/shared/analystLoginTrackingTime';
import AuthService from '../shared/features/shared/auth';
import { clearUnusedLocalStore } from '../shared/helper/commonHelper';
import User from '../shared/helper/user';
import {
  selectIsFullSizeSidebar,
  selectTheme,
  useGlobalActions,
} from '../shared/store/global/useGlobalStore';
import areaStorage from '../storage/area';
import facilityStorage from '../storage/facility';
import Permissions from '../storage/permissions';
import zoneStorage from '../storage/zone';
import MainContent from './components/Content/Content';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const auth = new AuthService();
const params = new URLSearchParams(window.location.search);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 60 * 1000,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

const matomoInstance = createInstance({
  urlBase: APP_MATOMO_URL,
  siteId: APP_MATOMO_SITE_ID,
  linkTracking: false,
  disabled: IS_DEV_SERVER,
});

const initialState = {
  isAvailable: false,
  selectedFacility: params.get('facility_name') || facilityStorage.get() || null,
  selectedZone: params.get('zone') || zoneStorage.get() || null,
  selectedArea: params.get('area') || areaStorage.get() || null,
  refreshInterval: 60000 * 5,
  refreshFlag: false,
  user: null,
  isVisibleMetricsModal: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setFacility':
      return { ...state, selectedFacility: action.selectedFacility };
    case 'setZone':
      return { ...state, selectedZone: action.selectedZone };
    case 'setArea':
      return { ...state, selectedArea: action.selectedArea };
    case 'setIsAvailable':
      return { ...state, isAvailable: action.isAvailable || initialState.isAvailable };
    case 'refreshInterval':
      return { ...state, refreshInterval: action.refreshInterval || initialState.refreshInterval };
    case 'setRefreshFlag':
      return { ...state, refreshFlag: !state.refreshFlag };
    case 'setUser': {
      const user =
        action.user && action.user.user
          ? {
              id: action.user.user.id,
              userpic: action.user.user.userpic,
              full_name: action.user.user.full_name,
              timezone: action.user.user.timezone,
              team_role: action.user.user.team_role,
              is_super_admin: action.user.permissions.superAdmin && action.user.permissions.superAdmin.id,
              email: action.user.user.email,
            }
          : {};
      User.set(user);
      Permissions.set(_get(action.user, 'permissions', {}));
      return { ...state, user: action.user || initialState.user };
    }
    case 'setMetricsModal':
      return {
        ...state,
        isVisibleMetricsModal: action.isVisibleMetricsModal || initialState.isVisibleMetricsModal,
      };
    default:
      state;
  }
};

export default function MainLayout(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedFacility, selectedZone, selectedArea, refreshInterval, user, isAvailable } = state;

  const { setFullSizeSidebar, setMeasure, setPersonalSettingMeasure } = useGlobalActions();

  const isFullSizeSidebar = selectIsFullSizeSidebar();
  const theme = selectTheme();

  useEffect(() => {
    auth.getProfile().then((user) => {
      dispatch({ type: 'setUser', user });
      setMeasure(METRIC_KEY_NAME[user?.user?.use_metric ?? METRIC_KEY['us']]);
      if (user?.user?.personal_settings) {
        setPersonalSettingMeasure(user.user.personal_settings);
      }
      dispatch({
        type: 'setIsAvailable',
        isAvailable:
          (user.permissions.superAdmin && user.permissions.superAdmin.id) ||
          (user.permissions.analyticAccess && user.permissions.analyticAccess.id),
      });
      setLogin();
    });
    dispatch({ type: 'setRefreshFlag' });
  }, []);

  useEffect(() => {
    document.body.classList.remove(`${theme === 'light' ? 'dark' : 'light'}-layout`);
    document.body.classList.add(`${theme ? theme : 'light'}-layout`);
  }, [theme]);

  addEventListener('popstate', () => {
    if (
      document.getElementsByClassName('medium-zoom-overlay')[0] &&
      document.getElementsByClassName('medium-zoom-image--opened')[0]
    ) {
      document.getElementsByClassName('medium-zoom-overlay')[0].remove();
      document.getElementsByClassName('medium-zoom-image--opened')[0].remove();
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        window.location.pathname.includes('/action-item')
          ? moment().diff(moment(getLastActivityTime()), 'seconds') >= 120
          : true
      ) {
        dispatch({ type: 'setRefreshFlag' });
      }
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval]);

  useEffect(() => {
    if (+localStorage.getItem('isLocalStorageCleared') === 1) {
      return;
    }
    clearUnusedLocalStore();
    localStorage.setItem('isLocalStorageCleared', '1');
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', () => setLogout());
    return () => {
      window.removeEventListener('beforeunload', () => setLogout());
    };
  }, []);

  if (!auth.loggedIn()) return <></>;

  if (!user)
    return (
      <div className="position-absolute d-flex justify-content-center align-items-center h-100 w-100">
        <Loader variant="loader-xl" />
      </div>
    );

  if (!isAvailable) {
    return (
      <div className="page-401">
        <div className="page-401-title">401</div>
        <div className="page-401-text">You {"haven't"} access to this page</div>
        <Button onClick={() => auth.logout()} outline size="lg" className="text-uppercase">
          Logout
        </Button>
      </div>
    );
  }

  const handleScrollStart = () => {
    if (document.getElementById('right-bar')) {
      document.getElementById('right-bar').style.pointerEvents = 'none';
    }
  };

  const handleScrollStop = () => {
    if (document.getElementById('right-bar')) {
      document.getElementById('right-bar').style.pointerEvents = 'auto';
    }
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MatomoProvider value={matomoInstance}>
          <ToastContainer />
          <div>
            <SystemTypeProvider>
              <Header
                auth={auth}
                layoutDispatch={dispatch}
                selectedFacility={selectedFacility}
                selectedZone={selectedZone}
                selectedArea={selectedArea}
              />
              <div style={{ display: 'flex' }}>
                <Sidebar
                  setFullSizeSidebar={setFullSizeSidebar}
                  isFullSizeSidebar={isFullSizeSidebar}
                  selectedFacility={selectedFacility}
                  layoutDispatch={dispatch}
                />
                <MainContent
                  props={props}
                  state={state}
                  dispatch={dispatch}
                  isFullSizeSidebar={isFullSizeSidebar}
                  handleScrollStart={handleScrollStart}
                  handleScrollStop={handleScrollStop}
                />
              </div>
            </SystemTypeProvider>
          </div>
        </MatomoProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
