var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
import Filters from '../../../shared/modals/machineInfo/tabs/Firmware/components/Filters';
import { config } from '../../../shared/modals/machineInfo/tabs/Firmware/config/dataTableConfig';
import { selectPageDataTable, selectPageSizeDataTable, selectSearchDataTable, selectSortDataTable, selectTypeDataTable, } from '../../../shared/store/firmware/useFirmwareStore';
export var firmwareListKey = function (installationPointId, sort, search, page, pageSize, type) { return ['firmware', { installationPointId: installationPointId, sort: sort, search: search, page: page, pageSize: pageSize, type: type }]; };
var firmwareListFn = function (_a) {
    var queryKey = _a.queryKey;
    var _b = queryKey[1], installationPointId = _b.installationPointId, sort = _b.sort, search = _b.search, page = _b.page, pageSize = _b.pageSize, type = _b.type;
    return Api.get("all-analytics/firmware/".concat(installationPointId), {
        query: {
            sort: {
                sort: sort[0].direction,
                field: sort[0].field,
            },
            pagination: {
                page: page,
                perpage: pageSize,
            },
            filter: {
                query: search,
                type: type,
            },
        },
    });
};
export var useFirmwareListQuery = function (_a) {
    var installationPointId = _a.installationPointId;
    var search = selectSearchDataTable();
    var page = selectPageDataTable();
    var pageSize = selectPageSizeDataTable();
    var sort = selectSortDataTable();
    var type = selectTypeDataTable();
    return useQuery({
        queryKey: firmwareListKey(installationPointId, sort, search, page, pageSize, type),
        queryFn: firmwareListFn,
        select: function (_a) {
            var list = _a.list, meta = _a.meta;
            return {
                config: __assign(__assign({}, config), { data: list, pagination: __assign(__assign({}, config.pagination), { total: meta.total, page: meta.page, pageSize: meta.perpage }), search: search, sort: sort, filtersData: {
                        type: type,
                    }, filters: function () {
                        return _jsx(Filters, {});
                    } }),
            };
        },
        placeholderData: {
            list: [],
            meta: {
                total: 0,
                page: page,
                perpage: pageSize,
            },
        },
        enabled: !!installationPointId,
    });
};
