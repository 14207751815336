// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotkey-btns {
  display: block;
  margin-top: 20px;
}
.hotkey-btns span {
  border: 1px solid #b01919;
  border-radius: 5px;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.3s color, 0.3s background;
}
.hotkey-btns span:hover {
  color: #fff;
  background: #b01919;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/Hotkeys/index.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,uCACE;AACN;AACI;EACE,WAAA;EACA,mBAAA;AACN","sourcesContent":[".hotkey-btns {\n  display: block;\n  margin-top: 20px;\n  & span {\n    border: 1px solid #b01919;\n    border-radius: 5px;\n    padding: 5px 8px;\n    cursor: pointer;\n    font-size: 15px;\n    transition:\n      0.3s color,\n      0.3s background;\n    &:hover {\n      color: #fff;\n      background: #b01919;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
