var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, find as _find, get as _get, has as _has, size as _size } from 'lodash';
import React, { Fragment, memo, useEffect, useReducer, useState, } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FaTimes } from 'react-icons/fa';
import { IoReloadOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Table } from 'reactstrap';
import { get, set } from '../../../../storage/tableColumn';
import { reducer } from '../../../helper/reducer';
import Loader from '../../Loader';
import PaginationComponent from '../Pagination/Pagination';
import Row from './components/Row';
var DataTable = function (_a) {
    var config = _a.config, isLoading = _a.isLoading, onPageChange = _a.onPageChange, onPageSizeChange = _a.onPageSizeChange, onSort = _a.onSort, onSearch = _a.onSearch, _b = _a.callbacks, callbacks = _b === void 0 ? {} : _b, _c = _a.selectedFilters, selectedFilters = _c === void 0 ? {} : _c, dispatchTable = _a.dispatchTable, tableName = _a.tableName, _d = _a.searchPlaceholder, searchPlaceholder = _d === void 0 ? 'Search' : _d;
    var data = config.data, columns = config.columns, disabledSearch = config.disabledSearch;
    if (isLoading !== undefined) {
        config.loader = isLoading;
    }
    var dataColumns = columns.filter(function (el) {
        return el.title && (el.permissions === void 0 || _get(el, 'permissions', true));
    });
    var newDataColumns = {};
    _cloneDeep(dataColumns).filter(function (el) {
        var _a;
        var _b;
        Object.assign(newDataColumns, (_a = {},
            _a[el.name] = (_b = el.visible) !== null && _b !== void 0 ? _b : false,
            _a));
    });
    var _e = useReducer(reducer, tableName ? (get(tableName) ? get(tableName) : newDataColumns) : newDataColumns), state = _e[0], dispatch = _e[1];
    useEffect(function () {
        updateColumnTable();
    }, [state]);
    var updateColumnTable = function () {
        if (!tableName || !dispatchTable) {
            return;
        }
        set(tableName, state);
        dispatchTable({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { columns: config.columns.map(function (el) {
                        if (state[el.name] !== void 0) {
                            return __assign(__assign({}, el), { visible: state[el.name] });
                        }
                        else {
                            return __assign({}, el);
                        }
                    }) }),
            },
        });
    };
    var checkSearch = function (searchValue) {
        var cyrillicPattern = /[\u0400-\u04FF]+/;
        if (cyrillicPattern.test(searchValue)) {
            toast.error("You can't use cyrillic characters in search", {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
            return;
        }
        if (onSearch) {
            onSearch(searchValue);
        }
    };
    return (_jsxs(Fragment, { children: [config.filters && config.filters(callbacks, config.filtersData, selectedFilters), (data.length > 0 || (!disabledSearch && config.search.length > 0) || config.loader) && (_jsx(Fragment, { children: _jsxs("div", { className: "d-flex justify-content-between align-items-center table-pagination", children: [!config.loader && !config.hideTotalLabel ? (_jsxs("span", { className: "font-italic", children: ["Total: ", config.pagination.total] })) : (_jsx("span", {})), _jsxs("div", { className: "d-flex", children: [_jsx(PaginationComponent, { config: config, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange }), !disabledSearch ? (_jsx(Input, { onChange: function (ev) { return checkSearch(ev.target.value); }, className: "table-search ms-3", value: config.search, placeholder: searchPlaceholder })) : (_jsx(_Fragment, {}))] })] }) })), config.withScroll ? (_jsx(Scrollbars, { className: "table-scrollbar", children: _jsx(TableComponent, { config: config, dataColumns: dataColumns, state: state, onSort: onSort, dispatch: dispatch, callbacks: callbacks, tableName: tableName }) })) : (_jsx(TableComponent, { config: config, dataColumns: dataColumns, state: state, onSort: onSort, dispatch: dispatch, callbacks: callbacks, tableName: tableName }))] }));
};
var TableComponent = function (_a) {
    var config = _a.config, onSort = _a.onSort, callbacks = _a.callbacks, dataColumns = _a.dataColumns, state = _a.state, dispatch = _a.dispatch, tableName = _a.tableName;
    var _b = useState(false), isVisibleTableMenu = _b[0], setIsVisibleTableMenu = _b[1];
    var data = config.data, columns = config.columns, _c = config.textAlignment, textAlignment = _c === void 0 ? 'text-center' : _c, hideHeader = config.hideHeader, additionContent = config.additionContent, _d = config.getIsShowAdditionContent, getIsShowAdditionContent = _d === void 0 ? function () { return false; } : _d;
    var columnIsSorted = function (columnName) {
        return (_size(_find(config.sort, { field: columnName })) > 0 ||
            _size(_find(config.sort, { activeFieldSort: columnName })));
    };
    var getColumnSortedDirection = function (columnName) {
        if (!columnIsSorted(columnName)) {
            return 'asc';
        }
        return _get(_find(config.sort, { field: columnName }), 'direction', 'asc');
    };
    return (_jsxs(Table, { striped: true, className: "position-relative responsive_table ".concat(config.isSmallTable && 'table-sm'), children: [!hideHeader && (_jsx("thead", { className: "table-head noselect", children: _jsxs("tr", { children: [data.some(function (item) { return getIsShowAdditionContent(item); }) ? _jsx("th", {}) : _jsx(_Fragment, {}), columns.map(function (column, index) { return (_jsxs("th", { className: "text-nowrap ".concat(column.sortable ? 'sorting-table-name' : '', " ").concat(textAlignment, " card-row_title ").concat(_has(column, 'visible') && !column.visible ? 'hidden' : ''), style: column.style || {}, onClick: column.sortable ? function (ev) { return onSort && onSort(column.name, ev); } : function () { }, children: [_jsxs(Fragment, { children: [_has(column, 'titleComponent')
                                            ? column.titleComponent(callbacks, config.filtersData, config)
                                            : column.title, column.sortable && (_jsx(Fragment, { children: columnIsSorted(column.name) ? (_jsx(Fragment, { children: getColumnSortedDirection(column.name) === 'asc' ? (_jsx("i", { className: "sorting-icon fa fa-sort-asc" })) : (_jsx("i", { className: "sorting-icon fa fa-sort-desc" })) })) : (_jsx("i", { className: "sorting-icon fa fa-sort" })) }))] }), index === columns.length - 1 &&
                                    _get(config, 'sort', []).length > 0 &&
                                    _get(config, 'sort.0.activeFieldSort') && (_jsx(Button, { onClick: function (ev) {
                                        ev.stopPropagation();
                                        onSort && onSort(null, ev);
                                    }, size: "sm", color: "danger", className: "float-end", children: _jsx(IoReloadOutline, {}) })), column.name === 'table_menu' ? (_jsxs(Dropdown, { className: "table-menu-dropdown", direction: "down", isOpen: isVisibleTableMenu, toggle: function () { return setIsVisibleTableMenu(!isVisibleTableMenu); }, children: [_jsx(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: column.component() }), _jsx(DropdownMenu, { children: dataColumns.map(function (el) { return (_jsxs(DropdownItem, { tag: "label", className: "table-menu-dropdown-item", toggle: false, htmlFor: tableName + el.name, children: [_jsx("input", { id: tableName + el.name, value: el.name, checked: state ? state[el.name] : el.visible, onChange: function (event) {
                                                            var _a;
                                                            return dispatch
                                                                ? dispatch({
                                                                    type: 'setState',
                                                                    state: (_a = {},
                                                                        _a[event.target.value] = event.target.checked,
                                                                        _a),
                                                                })
                                                                : {};
                                                        }, type: "checkbox" }), el.title] }, "".concat(tableName !== null && tableName !== void 0 ? tableName : '', "-item-").concat(el.name))); }) })] })) : (_jsx(_Fragment, {}))] }, "column-".concat(index))); })] }) })), _jsx("tbody", { children: config.loader ? (_jsx("tr", { children: _jsx("td", { className: "".concat(textAlignment, " card-row_title response-text"), colSpan: 100, children: _jsx("div", { className: "py-3", children: _jsx(Loader, { variant: "loader-md" }) }) }) })) : (_jsx(_Fragment, { children: data.length === 0 ? (_jsx("tr", { children: _jsx("td", { className: "".concat(textAlignment, " card-row_title response-text"), colSpan: 100, children: !config.loader && (config.noDataLabel || 'There are no data') }) })) : (_jsx(_Fragment, { children: data.map(function (item, index) {
                            return !_get(item, 'isHiddenRow') ? (_jsx(Fragment, { children: _jsx(Row, { index: index, columns: columns, item: item, textAlignment: textAlignment, callbacks: callbacks, config: config, tableName: tableName, additionContent: additionContent, getIsShowAdditionContent: getIsShowAdditionContent }) }, index)) : (_jsx(_Fragment, {}));
                        }) })) })) })] }));
};
export default memo(DataTable);
