import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'reactstrap';
var FooterToolsToggleBtn = function (_a) {
    var onClick = _a.onClick, isSelected = _a.isSelected, setIsSelected = _a.setIsSelected, children = _a.children;
    return (_jsx(Button, { className: "footer-tools-toggleBtn", color: "primary", outline: true, onClick: function () {
            if (setIsSelected) {
                setIsSelected(function (data) { return !data; });
            }
            if (onClick) {
                onClick();
            }
        }, active: isSelected, children: children }));
};
export default FooterToolsToggleBtn;
