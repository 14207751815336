import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import WatchersStatisticTooltip from '../../../components/watchersStatistic/Tooltip';
export var initialState = {
    data: [],
    loader: true,
    statisticByAnalysts: {
        title: {
            text: 'Statistic By Analyst',
        },
        series: [
            {
                type: 'pie',
                colorByPoint: true,
                data: [],
            },
        ],
        tooltip: {
            pointFormat: 'AI Count: <b>{point.y}</b>',
        },
    },
    statisticByState: {
        title: {
            text: 'Statistic By State',
        },
        series: [
            {
                colorByPoint: true,
                data: [],
            },
        ],
        xAxis: {
            type: 'category',
        },
        yAxis: {
            title: {
                text: 'Count Action Items',
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: 'AI Counts: <b>{point.y}</b>',
        },
    },
    statisticByDays: {
        title: {
            text: 'Statistic By Days',
        },
        plotOptions: {
            series: {
                showInNavigator: true,
            },
        },
        tooltip: {
            shared: true,
            split: false,
            useHTML: true,
            formatter: function () {
                return ReactDOMServer.renderToStaticMarkup((_jsx(WatchersStatisticTooltip, { points: this.points })));
            },
        },
        yAxis: {
            opposite: false,
        },
        series: [],
        disabledNearestPoint: true,
    },
};
