import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var analystNotesDeleteAttachmentFn = function (coreLink, id) {
    return Api.post("".concat(coreLink, "/analyst-notes/delete-file/").concat(id), {});
};
export var useAnalystNotesDeleteAttachmentMutation = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (id) { return analystNotesDeleteAttachmentFn(getCoreUrl(selectedSystemType), id); },
    });
};
