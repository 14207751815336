import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { ACTION_LIST, TFW_ML_FAULT_TYPES, TWF_ML_DEFECT_CATEGORIES } from '../../../constants';
import Loader from '../../components/Loader';
import { getSvgSource } from '../../components/charts/chartsWrapper/components/shared/ChartSelect';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { isFftTwfChart } from '../../helper/chart';
import User from '../../helper/user';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
var TwfDetectionsReport = function (_a) {
    var visible = _a.visible, handleDisableSelectCharts = _a.handleDisableSelectCharts, onCancel = _a.onCancel, _b = _a.report, report = _b === void 0 ? {} : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var _c = ChartsStore(function (state) { return ({
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedRanges: state.chartsSelectedRanges,
        twfMlDataItemId: state.twfMlDataItemId,
        setStates: state.setStates,
    }); }, shallow), selectedChartsAvailable = _c.selectedChartsAvailable, chartsSelectedRanges = _c.chartsSelectedRanges, twfMlDataItemId = _c.twfMlDataItemId;
    var _d = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _d.data, fftTimestampDates = _d.fftTimestampDates;
    var readingIds = data.readingIds;
    var _e = useState(report.urgency || ACTION_LIST.URGENCY_STANDARD), urgency = _e[0], setUrgency = _e[1];
    var _f = useState(report.comment || ''), mlComment = _f[0], setMlComment = _f[1];
    var _g = useState(report.is_relevant || 0), isRelevant = _g[0], setIsRelevant = _g[1];
    var _h = useState(report.defect_category || ''), defectCategories = _h[0], setDefectCategories = _h[1];
    var _j = useState(report.defect_category || ''), faultTypes = _j[0], setFaultTypes = _j[1];
    var _k = useState(false), loader = _k[0], setModalLoader = _k[1];
    var onSubmitMlReportModal = function () {
        setModalLoader(true);
        if (report.id) {
            Endpoints[selectedSystemType]
                .twfDataActionListEditReport({
                twf_ml_data_item_report_id: report.id,
                user_id: User.getId(),
                is_relevant: isRelevant,
                defect_categories: defectCategories,
                fault_types: faultTypes,
                urgency: urgency,
                comment: mlComment,
            })
                .then(function (resp) {
                if (resp.success) {
                    toast.success(resp.message || 'Successfully updated', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    handleDisableSelectCharts();
                    onCancel();
                    return;
                }
                toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            });
            return;
        }
        var readingFftIds = {};
        var readingFftTimestamps = {};
        Object.keys(selectedChartsAvailable).map(function (chartIdentifier) {
            if (isFftTwfChart(chartIdentifier)) {
                readingFftTimestamps[+chartIdentifier] =
                    fftTimestampDates[chartIdentifier];
                readingFftIds[+chartIdentifier] = readingIds[chartIdentifier];
            }
        });
        var svgSources = getSvgSource(selectedChartsAvailable);
        Endpoints[selectedSystemType]
            .twfDataActionListCreateReport({
            twf_ml_data_item_id: twfMlDataItemId,
            user_id: User.getId(),
            is_relevant: isRelevant,
            defect_categories: defectCategories,
            fault_types: faultTypes,
            urgency: urgency,
            comment: mlComment,
            ranges: chartsSelectedRanges,
            svg_sources: svgSources,
            readingFft_ids: readingFftIds,
            readingFft_timestamps: readingFftTimestamps,
        })
            .then(function (resp) {
            setModalLoader(false);
            if (resp.success) {
                handleDisableSelectCharts();
                onCancel();
                toast.success(resp.message || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                return;
            }
            toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "TWF Detections Report" }), _jsx(ModalBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(_Fragment, { children: _jsxs(Form, { children: [_jsxs(FormGroup, { className: "mt-1", inline: true, children: [_jsx(Label, { for: "textarea", children: "Relevant?" }), _jsxs(Label, { check: true, className: "ms-4", children: [_jsx(Input, { type: "radio", name: "is_relevant", defaultChecked: +isRelevant === 1, onChange: function () { return setIsRelevant(1); } }), "Yes"] }), _jsxs(Label, { check: true, className: "ms-4", children: [_jsx(Input, { type: "radio", name: "is_relevant", defaultChecked: +isRelevant === 0, onChange: function () { return setIsRelevant(0); } }), "No"] })] }), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "Fault equipment component:" }), _jsx(Select, { placeholder: 'Defect category', defaultValue: defectCategories
                                                        .split(';')
                                                        .map(function (label) { return ({ label: label, value: label }); })
                                                        .filter(function (item) { return item.value.length > 0; }), classNamePrefix: "customSelectStyleDefault", onChange: function (values) {
                                                        return setDefectCategories(values ? values.map(function (value) { return value.value; }).join(';') : '');
                                                    }, isMulti: true, options: TWF_ML_DEFECT_CATEGORIES.map(function (label) { return ({ label: label, value: label }); }) })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "Fault type:" }), _jsx(Select, { placeholder: 'Fault type', defaultValue: faultTypes
                                                        .split(';')
                                                        .map(function (label) { return ({ label: label, value: label }); })
                                                        .filter(function (item) { return item.value.length > 0; }), classNamePrefix: "customSelectStyleDefault", onChange: function (values) {
                                                        return setFaultTypes(values ? values.map(function (value) { return value.value; }).join(';') : '');
                                                    }, isMulti: true, options: TFW_ML_FAULT_TYPES.map(function (label) { return ({ label: label, value: label }); }) })] }) })] }), _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "Urgency:" }), _jsxs(Input, { type: "select", name: "select", value: urgency, onChange: function (ev) { return setUrgency(+ev.target.value); }, children: [_jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_HIGH, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_HIGH] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL] })] })] }), _jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "Comment for ML-system: " }), _jsx(Input, { className: "input-cancel", id: "textarea", bsSize: 'lg', label: "description", type: "textarea", required: true, value: mlComment, onChange: function (ev) { return setMlComment(ev.currentTarget.value); } })] })] }) })) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Close" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSubmitMlReportModal, children: "Save" })] }) })] }));
};
export default TwfDetectionsReport;
