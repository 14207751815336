import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { selectActivityLogSize, selectEditComment, useActionItemViewActions, } from '../../../../store/AIPreAi/useActionItemViewStore';
import '../../../../styles/AIPreAi/activityLog.scss';
import ActivityLogToggleSize from '../../../AIPreAi/activityLogHeader/components/ActivityLogToggleSize';
import SaveAsDefaultSize from '../../../AIPreAi/activityLogHeader/components/SaveAsDefaultSize';
import DefaultTooltip from '../../../DefaultTooltip';
var Header = function (_a) {
    var id = _a.id, isViewMode = _a.isViewMode, isReplaceEquipment = _a.isReplaceEquipment, commentSaveMutate = _a.commentSaveMutate, commentSaveIsLoading = _a.commentSaveIsLoading, control = _a.control, handleSubmit = _a.handleSubmit, comments = _a.comments;
    var activityLogSize = selectActivityLogSize();
    var editComment = selectEditComment();
    var setEditComment = useActionItemViewActions().setEditComment;
    var comment = useWatch({
        control: control,
        name: ['comment'],
    })[0];
    var field = useController({
        control: control,
        name: 'comment',
    }).field;
    var onSubmit = handleSubmit(function () {
        if (editComment) {
            commentSaveMutate({
                pre_action_item_id: id,
                comment: comment,
                id: editComment.id,
                is_replace_equipment: +isReplaceEquipment,
            }, {
                onSuccess: function () {
                    toast.success('The comment has been successfully updated.', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    field.onChange('');
                    setEditComment(null);
                },
            });
            return;
        }
        commentSaveMutate({
            pre_action_item_id: id,
            comment: comment,
            is_replace_equipment: +isReplaceEquipment,
        }, {
            onSuccess: function () {
                toast.success('The comment has been successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                field.onChange('');
            },
        });
    });
    return (_jsxs("div", { className: "activity-log-header", children: [_jsx("div", { className: "d-flex align-items-center", children: !activityLogSize ? (_jsxs("div", { className: "d-flex align-items-center flex-wrap justify-content-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "preAiDefaultActivityLogSize" })] })) : (_jsxs("div", { className: "d-flex align-items-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "preAiDefaultActivityLogSize" }), _jsx("div", { className: "activity-log-header-title", children: "Activity Log" }), comments.length ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-comments-count", "data-tooltip-id": "comments_count", children: comments.length || _jsx(_Fragment, {}) }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "comments_count", children: "Comments count" })] })) : (_jsx(_Fragment, {}))] })) }), activityLogSize ? (_jsx("div", { className: "d-flex align-items-center", children: isViewMode ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-send", id: "send", onClick: function () { return (commentSaveIsLoading ? {} : onSubmit()); }, children: _jsx(RiSendPlane2Fill, {}) }), _jsx(UncontrolledTooltip, { placement: "left", target: "send", children: editComment ? 'Save changes' : 'Save comment' })] })) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Header);
