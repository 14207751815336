import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheckCircle, FaLock, FaLockOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { selectIsLockRmsTooltips, useChartActions } from '../../../../../store/charts/useChartStore';
var LockRmsTooltips = function () {
    var isLockRmsTooltips = selectIsLockRmsTooltips();
    var setLockRmsTooltips = useChartActions().setLockRmsTooltips;
    var handler = function () {
        setLockRmsTooltips(!isLockRmsTooltips);
        toast.success("".concat(isLockRmsTooltips ? 'Unlocked' : 'Locked', " tooltip successfully saved by default"), {
            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
        });
    };
    return (_jsx("div", { children: _jsx(Button, { outline: true, color: "customChartTopBarSelect", className: "btn-chart-tools", size: "sm", onClick: handler, children: isLockRmsTooltips ? _jsx(FaLock, {}) : _jsx(FaLockOpen, {}) }) }));
};
export default memo(LockRmsTooltips);
