import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, } from 'reactstrap';
import { ACTION_LIST } from '../../../../constants';
import { UrgencyLabel } from '../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateInput, DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import User from '../../../helper/user';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    return (_jsx("div", { className: "no-wrap", children: _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id }) }));
                },
            },
            {
                name: '',
                titleComponent: function (callbacks, filtersData) {
                    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                    var labelsMapping = {
                        '1': 'Standard',
                        '2': 'High',
                        '3': 'Critical',
                    };
                    var field = filtersData.urgency;
                    return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'Urgency' }), _jsx(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: _jsx("div", { children: Object.values(labelsMapping).map(function (label, index) { return (_jsx(DropdownItem, { onClick: function () { return callbacks.onChangeUrgencyFilter(Object.keys(labelsMapping)[index]); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.urgency === Object.keys(labelsMapping)[index] }), label] }) }, "urgency_title_filter_".concat(label))); }) }) })] }));
                },
                sortable: false,
                component: function (row) {
                    return _jsx(UrgencyLabel, { urgency: +row.urgency || ACTION_LIST.URGENCY_STANDARD });
                },
            },
            {
                name: 'user_name',
                title: 'Created by',
                sortable: true,
            },
            {
                name: 'created_at',
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "ai_list_time_created_".concat(row.id), dateTime: row.created_at, timeBreak: true }));
                },
            },
            {
                title: 'Analyst comment',
                name: 'comment',
                sortable: false,
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { color: "info", size: "sm", onClick: function () { return callbacks.onViewImages(row.charts.map(function (chart) { return chart.image; })); }, children: "Images" }) }), _jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { onClick: function () {
                                        onViewClick(row.installation_point_id);
                                    }, className: "btn btn-sm btn-success", children: "View" }) }), +User.getId() === +row.user_id && (_jsxs(_Fragment, { children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { size: "sm", color: "primary", onClick: function () { return callbacks.setMlMissedAi(row); }, children: "Edit" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "danger", onClick: function () { return callbacks.deleteMissedAiItem(row.id); }, children: "Delete" }) })] }))] }));
                },
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filtersData: {
            urgency: '',
            dateRange: [],
        },
        filters: function (callbacks, filtersData) {
            return (_jsx("div", { className: "response-action d-flex align-items-center mb-3", children: _jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: callbacks.onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }) }));
        },
    };
};
