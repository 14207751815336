// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fft-labels-shape {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 2px solid #dc3545;
  display: inline-block;
  position: relative;
}
.fft-labels-shape .selected-shape {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #dc3545;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 0;
  margin: 0;
  z-index: 1;
}
.fft-labels-shape.on-demand {
  border-color: #147900;
}
.fft-labels-shape.on-demand .selected-shape {
  background-color: #147900;
}

.fft-labels {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/fftShapes.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,qBAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gCAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;AACJ;AAEE;EACE,qBAAA;AAAJ;AACI;EACE,yBAAA;AACN;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".fft-labels-shape {\n  width: 12px;\n  height: 12px;\n  background-color: #fff;\n  border: 2px solid #dc3545;\n  display: inline-block;\n  position: relative;\n\n  .selected-shape {\n    width: 6px;\n    height: 6px;\n    display: inline-block;\n    background-color: #dc3545;\n    position: absolute;\n    transform: translate(-50%, -50%);\n    top: 50%;\n    left: 50%;\n    padding: 0;\n    margin: 0;\n    z-index: 1;\n  }\n\n  &.on-demand {\n    border-color: #147900;\n    .selected-shape {\n      background-color: #147900;\n    }\n  }\n}\n.fft-labels {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
