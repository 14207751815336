var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BsChatFill, BsFillGearFill } from 'react-icons/bs';
import { FaAlignLeft, FaBell, FaBug, FaCheckDouble, FaCog, FaCubes, FaExclamationTriangle, FaEye, FaHome, FaLightbulb, FaRegBellSlash, FaSitemap, } from 'react-icons/fa';
import { FaGears } from 'react-icons/fa6';
import { GiSettingsKnobs } from 'react-icons/gi';
import { RiFileHistoryFill } from 'react-icons/ri';
import { Badge } from 'reactstrap';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import User from '../../../../shared/helper/user';
export var configMenu = function (state, selectedSystemType, setIsVisibleModal, resetNote) {
    var notViewedNews = state.notViewedNews;
    return [
        {
            title: 'Main',
            link: '/',
            icon: function () {
                return _jsx(FaHome, {});
            },
        },
        {
            title: "What's new",
            link: '/whats-new',
            getTitle: function () {
                return (_jsxs(React.Fragment, { children: ["What`s new", ' ', notViewedNews > 0 ? (_jsx(Badge, { className: "ms-2", color: "warning", children: notViewedNews })) : ('')] }));
            },
            icon: function () {
                return _jsx(FaBell, {});
            },
        },
        {
            title: 'Watch List',
            link: 'watch-list',
            icon: function () {
                return _jsx(FaEye, {});
            },
            children: [
                {
                    title: 'Watchlist',
                    link: '/watch-list',
                },
                {
                    title: 'Users Watchlist',
                    link: '/watch-list/users',
                    visible: User.can('viewWatchList'),
                },
            ],
        },
        {
            title: 'Bad data items',
            link: '/bad-data',
            icon: function () {
                return _jsx(FaBug, {});
            },
        },
        {
            title: 'Structure',
            link: '/structure',
            icon: function () {
                return _jsx(FaSitemap, {});
            },
        },
        {
            title: 'Alerts',
            link: 'alert',
            icon: function () {
                return _jsx(FaExclamationTriangle, {});
            },
            children: __spreadArray(__spreadArray([], (selectedSystemType === SYSTEM_TYPE_REMASTERED
                ? [
                    {
                        title: 'Trigger Alerts RMS',
                        link: '/alerts/rms',
                    },
                    {
                        title: 'Trigger Alerts FFT',
                        link: '/alerts/fft',
                    },
                ]
                : [
                    {
                        title: 'Trigger Alerts RMS',
                        link: '/alerts/rms',
                    },
                ]), true), [
                {
                    title: 'Alarm counts',
                    link: '/alert-counts',
                },
            ], false),
        },
        {
            title: 'AVG reading values',
            link: '/avg',
            icon: function () {
                return _jsx(FaCubes, {});
            },
        },
        {
            title: 'Action list',
            link: 'action-list',
            icon: function () {
                return _jsx(FaLightbulb, {});
            },
            children: [
                {
                    title: 'Pre-Action List',
                    link: '/pre-action-list',
                },
                {
                    title: 'Action list',
                    link: '/action-list',
                },
                {
                    title: 'Network Issues',
                    link: '/action-list/network',
                },
                {
                    title: 'Archive list',
                    link: '/action-list/archive',
                },
            ],
        },
        {
            title: 'ML-statistics',
            link: 'ml-statistic',
            icon: function () {
                return _jsx(FaCog, {});
            },
            children: [
                {
                    title: 'Rejected AI',
                    link: '/ml-statistic/rejected-ai',
                },
                {
                    title: 'Missed AI',
                    link: '/ml-statistic/missed-ai',
                },
                {
                    title: 'TWF Detections',
                    link: '/twf-detections',
                    visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
                },
                {
                    title: 'Adjust Alarm Levels',
                    link: '/ml-suggested-alarm-adjustment',
                },
            ],
        },
        {
            title: 'Without alarm levels',
            link: '/without-alarm-levels',
            icon: function () {
                return _jsx(FaRegBellSlash, {});
            },
        },
        {
            title: 'Suggested levels',
            link: '/suggested-levels',
            icon: function () {
                return _jsx(FaAlignLeft, {});
            },
        },
        {
            title: 'Alarm levels verification',
            link: '/alarm-levels-verification',
            icon: function () {
                return _jsx(FaCheckDouble, {});
            },
        },
        {
            title: 'Sensor intervals',
            link: '/sensor-intervals',
            visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
            icon: function () {
                return _jsx(GiSettingsKnobs, {});
            },
        },
        {
            title: 'Customer Dashboard',
            link: APP_CUSTOMER_DASHBOARD_DOMAIN || '',
            externalLink: true,
            visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
            icon: function () {
                return _jsx(FaSitemap, {});
            },
        },
        {
            title: 'V2 website',
            link: APP_MY_WAITES_DOMAIN || '',
            externalLink: true,
            visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
            icon: function () {
                return _jsx(FaSitemap, {});
            },
        },
        {
            title: 'Management',
            link: 'management',
            icon: function () {
                return _jsx(BsFillGearFill, {});
            },
            children: [
                {
                    title: 'Watchers Statistic',
                    link: '/watchers-statistic',
                },
                {
                    title: 'User activity',
                    link: '/user-activity',
                },
                {
                    title: 'Customers',
                    link: '/customers',
                    visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
                },
                {
                    title: 'SLA Settings',
                    link: '/sla-settings',
                    visible: User.isSuperAdmin() || User.can('manageWatchList'),
                },
            ],
        },
        {
            title: 'Logged actions',
            link: 'logged',
            icon: function () {
                return _jsx(RiFileHistoryFill, {});
            },
            children: [
                {
                    title: "On Demand FFT's",
                    link: '/on-demand-fft',
                },
                {
                    title: 'Sensor log',
                    link: '/sensor-log',
                    visible: User.can('viewSensorLog'),
                },
                {
                    title: 'Alert log',
                    link: '/alert-log/alerts',
                },
                {
                    title: 'Action List Comments',
                    link: '/action-list-comments',
                },
                {
                    title: 'Running speed log',
                    link: '/running-speed-log',
                    visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
                },
                {
                    title: 'Idle Threshold log',
                    link: '/idle-threshold-log',
                    visible: selectedSystemType === SYSTEM_TYPE_REMASTERED,
                },
            ],
        },
        {
            title: 'Analyst Notes',
            link: 'analyst-notes',
            isOpen: true,
            icon: function () {
                return _jsx(BsChatFill, {});
            },
            children: [
                {
                    title: 'Analyst Notes List',
                    link: '/analyst-notes',
                },
                {
                    title: 'Add Note',
                    link: '/analyst-notes/create',
                    visible: !['/amazon/analyst-notes', '/analyst-notes'].includes(location.pathname),
                    onClick: function (ev) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        resetNote();
                        setIsVisibleModal(true);
                    },
                },
            ],
        },
        {
            title: 'Report Editor',
            link: '/report-editor',
            visible: selectedSystemType === SYSTEM_TYPE_REMASTERED && User.can('manageReportEditor'),
            icon: function () {
                return _jsx(FaGears, {});
            },
        },
    ];
};
