// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-design .pagination-wrap .pagination,
.modal .pagination-wrap .pagination {
  margin-bottom: 0;
}
.new-design .pagination-wrap .pagination .active .pagination-item,
.modal .pagination-wrap .pagination .active .pagination-item {
  border-color: #d9d9d9;
  background: #2962ff;
}
.dark-layout .new-design .pagination-wrap .pagination .active .pagination-item,
.dark-layout .modal .pagination-wrap .pagination .active .pagination-item {
  background: #2962ff;
}
.new-design .pagination-wrap .pagination .active .pagination-item:hover,
.modal .pagination-wrap .pagination .active .pagination-item:hover {
  border-color: #d9d9d9;
  background: #2962ff;
}
.dark-layout .new-design .pagination-wrap .pagination .active .pagination-item:hover,
.dark-layout .modal .pagination-wrap .pagination .active .pagination-item:hover {
  background: #2962ff;
}
.new-design .pagination-wrap .pagination .pagination-item,
.modal .pagination-wrap .pagination .pagination-item {
  height: 32px;
  display: flex;
  align-items: center;
  border-color: #d9d9d9;
  background: #ffffff;
}
.dark-layout .new-design .pagination-wrap .pagination .pagination-item,
.dark-layout .modal .pagination-wrap .pagination .pagination-item {
  border-color: #3e3f4f;
  background: #252a38;
}
.new-design .pagination-wrap .pagination .pagination-item span,
.modal .pagination-wrap .pagination .pagination-item span {
  margin-top: -3px;
}
.new-design .pagination-wrap .pagination .pagination-item:hover,
.modal .pagination-wrap .pagination .pagination-item:hover {
  border-color: rgba(41, 98, 255, 0.2078431373);
  background: rgba(41, 98, 255, 0.2078431373);
}
.dark-layout .new-design .pagination-wrap .pagination .pagination-item:hover,
.dark-layout .modal .pagination-wrap .pagination .pagination-item:hover {
  border-color: #3e3f4f;
  background: #2c2d3b;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/shared/Pagination/Pagination.scss"],"names":[],"mappings":"AAGI;;EACE,gBAAA;AADN;AAGQ;;EACE,qBAAA;EACA,mBAAA;AAAV;AACU;;EACE,mBAAA;AAEZ;AAAU;;EACE,qBAAA;EACA,mBAAA;AAGZ;AAFY;;EACE,mBAAA;AAKd;AAAM;;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;AAGR;AAFQ;;EACE,qBAAA;EACA,mBAAA;AAKV;AAHQ;;EACE,gBAAA;AAMV;AAJQ;;EACE,6CAAA;EACA,2CAAA;AAOV;AANU;;EACE,qBAAA;EACA,mBAAA;AASZ","sourcesContent":[".new-design,\n.modal {\n  & .pagination-wrap {\n    & .pagination {\n      margin-bottom: 0;\n      & .active {\n        & .pagination-item {\n          border-color: #d9d9d9;\n          background: #2962ff;\n          .dark-layout & {\n            background: #2962ff;\n          }\n          &:hover {\n            border-color: #d9d9d9;\n            background: #2962ff;\n            .dark-layout & {\n              background: #2962ff;\n            }\n          }\n        }\n      }\n      & .pagination-item {\n        height: 32px;\n        display: flex;\n        align-items: center;\n        border-color: #d9d9d9;\n        background: #ffffff;\n        .dark-layout & {\n          border-color: #3e3f4f;\n          background: #252a38;\n        }\n        & span {\n          margin-top: -3px;\n        }\n        &:hover {\n          border-color: #2962ff35;\n          background: #2962ff35;\n          .dark-layout & {\n            border-color: #3e3f4f;\n            background: #2c2d3b;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
