import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { renderToString } from 'react-dom/server';
var HighlighterWord = function (_a) {
    var textToHighlight = _a.textToHighlight, _b = _a.searchWords, searchWords = _b === void 0 ? '' : _b, _c = _a.highlightClassName, highlightClassName = _c === void 0 ? '' : _c, _d = _a.block, block = _d === void 0 ? true : _d;
    if (!textToHighlight) {
        return _jsx(React.Fragment, {});
    }
    if (searchWords) {
        searchWords = searchWords.toString();
        var parts = textToHighlight.split(new RegExp("(".concat(searchWords.replace(/[()]/g, ''), ")"), 'gi'));
        var text = (_jsxs("span", { children: [' ', parts.map(function (part, i) { return (_jsx("span", { className: part.toLowerCase() === searchWords.toLowerCase() ? 'highlighted-text' : '', style: part.toLowerCase() === searchWords.toLowerCase()
                        ? { backgroundColor: '#ffff00', color: '#000' }
                        : {}, children: _jsx("span", { dangerouslySetInnerHTML: { __html: part } }) }, i)); }), ' '] }));
        textToHighlight = renderToString(text);
    }
    if (block) {
        return _jsx("div", { className: highlightClassName, dangerouslySetInnerHTML: { __html: textToHighlight } });
    }
    return _jsx("span", { className: highlightClassName, dangerouslySetInnerHTML: { __html: textToHighlight } });
};
export default memo(HighlighterWord);
