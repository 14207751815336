import Api from '../../api';
export default {
    getRunningSpeedLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/running-speed-log', options);
    },
    getRunningSpeedLogCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/running-speed-log/csv', options);
    },
};
