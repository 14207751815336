import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import UserActivityTable from '../../../shared/components/userActivity/UserActivityTable/UserActivityTable';
import User from '../../../shared/helper/user';
import '../../../shared/styles/userActivity/userActivity.scss';
var UserActivityPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('User Activity');
    var user = User.get();
    return (_jsxs("div", { className: "page-userActivity", children: [_jsxs("h3", { className: "page-title", children: ["User activity for ", user.full_name || ''] }), _jsx(UserActivityTable, { deps: [refreshFlag], endpoint: Endpoints.amazon.getUserActivity })] }));
};
export default UserActivityPage;
