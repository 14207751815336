var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useAutoCorrelation = function (state, dispatch, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate) {
    var setStates = ChartsStore(function (state) { return ({
        setStates: state.setStates,
    }); }, shallow).setStates;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var options = state.options, mainSeriesLoaded = state.mainSeriesLoaded, autoCorrelationIsVisible = state.autoCorrelationIsVisible;
    useEffect(function () {
        if (loader) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                autoCorrelationIsVisible: false,
            },
        });
    }, [installationPoint.settings, fftSelectedDate]);
    useEffect(function () {
        if (loader) {
            return;
        }
        if (isChangedCalendar) {
            dispatch({
                type: 'setState',
                state: {
                    autoCorrelationIsVisible: false,
                },
            });
        }
    }, [fftTimestampDates[chartIdentifier]]);
    useEffect(function () {
        var _a, _b;
        if (!mainSeriesLoaded) {
            return;
        }
        if ((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length) {
            var autoCorrelationSeriesId_1 = autoCorrelationIsVisible
                ? "fft_auto_correlation_".concat(_get(options, 'chartIdentifier'), "_").concat(_get(options, 'installationPointId'))
                : "fft_".concat(_get(options, 'chartIdentifier'), "_").concat(_get(options, 'installationPointId'));
            options.series.map(function (seriesItem) {
                seriesItem.visible = _get(seriesItem, 'id') === autoCorrelationSeriesId_1;
                seriesItem.showInLegend = _get(seriesItem, 'id') === autoCorrelationSeriesId_1;
            });
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { exporting: __assign(__assign({}, options.exporting), { buttons: __assign(__assign({}, (_b = options === null || options === void 0 ? void 0 : options.exporting) === null || _b === void 0 ? void 0 : _b.buttons), { showAutoCorrelation: {
                                enabled: !autoCorrelationIsVisible,
                            }, hideAutoCorrelation: {
                                enabled: autoCorrelationIsVisible,
                            } }) }) }),
            },
        });
        setStates({ chartCursorType: 'normal' });
    }, [autoCorrelationIsVisible]);
};
