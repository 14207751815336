import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";
import { Button, Input } from "reactstrap";
import { DateInput } from "../../../../widgets/common";
import "./Filter.scss";
var Filters = function (_a) {
    var filtersData = _a.filtersData, callbacks = _a.callbacks;
    var _b = useState([null, null]), dateRange = _b[0], setDateRange = _b[1];
    var startDate = dateRange[0], endDate = dateRange[1];
    var user_name = filtersData.user_name, _c = filtersData.userNames, userNames = _c === void 0 ? [] : _c;
    var onChangeUserFilter = callbacks.onChangeUserFilter, onChangeDateFilter = callbacks.onChangeDateFilter, onResetFilters = callbacks.onResetFilters;
    var setDateRangeNew = function (update) {
        setDateRange(update);
        var startDate = update[0], endDate = update[1];
        if (startDate && endDate) {
            var formattedStartDate = moment(startDate).format('YYYY/MM/DD');
            var formattedEndDate = moment(endDate).format('YYYY/MM/DD');
            onChangeDateFilter(formattedStartDate, formattedEndDate);
        }
    };
    var handleOnResetFilters = function () {
        setDateRange([null, null]);
        onResetFilters();
    };
    return (_jsxs("div", { className: "alert-log-filter mb-3", children: [_jsx(DatePicker, { selectsRange: true, startDate: startDate, endDate: endDate, maxDate: new Date(), shouldCloseOnSelect: true, value: startDate && endDate
                    ? "".concat(moment(startDate).format('YYYY/MM/DD'), " - ").concat(moment(endDate).format('YYYY/MM/DD'))
                    : '', onChange: function (update) { return setDateRangeNew(update); }, customInput: _jsx(DateInput, {}) }), _jsxs(Input, { id: "user_name", value: user_name, className: "w-auto", type: "select", onChange: function (ev) { return onChangeUserFilter(ev.target.value); }, name: "user_name", children: [_jsx("option", { value: "", children: "All users" }), userNames.map(function (userName) { return (_jsx("option", { value: userName, children: userName }, "user-filter-item-".concat(userName))); })] }), _jsx(Button, { className: "filter-reset-button", color: "danger", size: "sm", onClick: handleOnResetFilters, children: _jsx(FaTimes, {}) })] }));
};
export default Filters;
