import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { AlarmLevel } from '../../../widgets/AlarmLevel';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import LinkWithPrefix from '../../components/LinkWithPrefix';
import Filters from '../../components/alarmLevelsVerification/Filter/Filter';
export var config = function (isAmazon) {
    if (isAmazon === void 0) { isAmazon = false; }
    var defaultHighValues = {
        temperature: {
            isEnabled: true,
            fromValue: 180,
            toValue: '',
        },
        acceleration: {
            isEnabled: true,
            fromValue: 4,
            toValue: '',
        },
        velocity: {
            isEnabled: true,
            fromValue: 0.5,
            toValue: '',
        },
        'pk-pk': {
            isEnabled: true,
            fromValue: 40,
            toValue: '',
        },
    };
    return {
        columns: [
            {
                name: 'level_name',
                title: 'Level',
                sortable: false,
                component: function (row) {
                    return _jsx(AlarmLevel, { levelName: row.level_name });
                },
            },
            {
                name: 'customer_name',
                title: 'Customer',
                sortable: false,
                visible: !isAmazon,
            },
            {
                name: 'facility_name',
                title: 'Facility',
                sortable: false,
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: true,
            },
            {
                name: 'value',
                title: 'Value',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: false,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true, notShowEmptyHex: true });
                },
            },
            {
                name: 'created_at',
                title: 'Created At',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "high_alert_condition_values_verification_widget_".concat(row.id), dateTime: row.created_at, timeBreak: true }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(row.installation_point_id, "&fromAlerts=1&alert=").concat(row.id, "&scroll_to=").concat(row.reading_type_id), target: "_blank", className: "btn btn-sm btn-primary", children: "View" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "success", onClick: function () { return callbacks.onConfirm(row.installation_point_id, row.reading_type_id); }, children: "Confirm" }) })] }));
                },
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Filters, { defaultHighValues: defaultHighValues, onChangeHighValues: callbacks.onChangeHighValues, onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange }));
        },
        filtersData: {
            dateRange: [],
            highValues: defaultHighValues,
        },
    };
};
