import { valibotResolver } from '@hookform/resolvers/valibot';
import { custom, forward, minLength, number, object, string, } from 'valibot';
export var preAiFormDescriptionSchema = valibotResolver(object({
    description: string('Description should be string', [minLength(1, 'Description cannot be empty')]),
    title: string('Title should be string', [minLength(1, 'Title cannot be empty')]),
    urgency: number(),
}, [
    forward(custom(function (_a) {
        var description = _a.description;
        return description !== '<p><br></p>' && description !== '<p></p>';
    }, 'Description cannot be empty'), ['description']),
    forward(custom(function (_a) {
        var description = _a.description;
        return /^[^\u0400-\u04FF]+$/.test(description);
    }, 'You cannot use Cyrillic characters in description'), ['description']),
    forward(custom(function (_a) {
        var title = _a.title;
        return /^[^\u0400-\u04FF]+$/.test(title);
    }, 'You cannot use Cyrillic characters in title'), ['title']),
]));
