import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { preActionItemKey as preActionItemKeyAmazon } from '../../../amazon/services/preActionItems/usePreActionItemQuery';
import Api from '../../../api/api';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { preActionItemKey as preActionItemKeyRemastered } from '../../../remastered/services/preActionItems/usePreActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var declinePreActionItemFn = function (data, coreLink, selectedSystemType, storage) {
    return Api.post("".concat(coreLink, "/pre-action-list/").concat(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'decline/' + data.id : data.id + '/decline'), {
        decline_reason: data.decline_reason,
        blocking_action_item_id: data.blocking_action_item_id,
        blocked_pre_action_item_type: data.blocked_pre_action_item_type,
    }, { host: getHost(storage) });
};
export var useDeclinePreActionItemMutation = function (_a) {
    var preActionItemId = _a.preActionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var trackEvent = useMatomo().trackEvent;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) {
            return declinePreActionItemFn(data, getCoreUrl(selectedSystemType), selectedSystemType, storage);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: selectedSystemType
                    ? preActionItemKeyRemastered(preActionItemId)
                    : preActionItemKeyAmazon(preActionItemId),
            });
            trackEvent({
                category: 'Pre-Action item',
                action: 'Decline',
                name: "ID: ".concat(preActionItemId),
                href: window.location.href,
            });
        },
    });
};
