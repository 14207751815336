var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticSystemMetricsKey = function (dateRange, queryAdditionProperties) {
    var _a, _b;
    return [
        'statisticSystemMetrics',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
        queryAdditionProperties,
    ];
};
var statisticSystemMetricsFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/system-metrics"), {
        query: __assign({ date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'), date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00') }, queryKey[3]),
    });
};
export var useStatisticSystemMetricsQuery = function (_a) {
    var queryAdditionProperties = _a.queryAdditionProperties;
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticSystemMetricsKey(dateRange, queryAdditionProperties),
        queryFn: function (data) { return statisticSystemMetricsFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var data = _a.data;
            return __assign(__assign({}, data), { sumPreAisBacklogByUrgency: Object.values(data.pre_ais_backlog_by_urgency).reduce(function (acc, val) { return acc + val; }, 0), sumPreAisBacklogMaxDaysOld: Object.values(data.pre_ais_backlog_max_days_old).reduce(function (acc, val) { return acc + val; }, 0), sumOpenedAisByStatus: Object.values(data.opened_ais_by_status).reduce(function (acc, val) { return acc + val; }, 0) });
        },
    });
};
