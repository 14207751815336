import { createStore } from '../createStore';
var useAlarmLevelCountModalStore = createStore(function (set) { return ({
    activeDayData: null,
    actions: {
        setActiveDayData: function (activeDayData) { return set({ activeDayData: activeDayData }); },
    },
}); });
export var useAlarmLevelCountModalActions = function () {
    return useAlarmLevelCountModalStore(function (state) { return state.actions; });
};
export var selectActiveDayData = function () {
    return useAlarmLevelCountModalStore(function (state) { return state.activeDayData; });
};
