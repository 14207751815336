export var DEFAULT_CHARTS_CONFIG = {
    style: {
        fontFamily: "'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, Helvetica, sans-serif",
        fontSize: '12px',
    },
    resetZoomButton: {
        theme: {
            style: {
                'font-size': '10px',
            },
        },
        position: {
            align: 'left',
            verticalAlign: 'top',
            x: -6,
            y: -38,
        },
    },
    borderColor: '#ffffff',
    borderWidth: 2,
    boost: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    navigator: {
        enabled: false,
    },
    scrollbar: {
        enabled: false,
    },
    colors: [
        '#7cb5ec',
        '#1dc9b7',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1',
        '#7cb5ec',
        '#742eb0',
        '#8bbc21',
        '#910000',
        '#1aadce',
        '#492970',
        '#f28f43',
        '#77a1e5',
        '#c42525',
        '#a6c96a',
    ],
    rangeSelector: {
        enabled: false,
    },
    lang: {
        noData: '',
        showAmbientTemp: 'Show Ambient Temperature',
        showNormalizeReadings: 'Show Normalize Readings',
        showAutoCorrelation: 'Show Auto-correlation',
        hideAutoCorrelation: 'Hide Auto-correlation',
        hideNormalizeReadings: 'Hide Normalize Readings',
        hideAmbientTemp: 'Hide Ambient Temperature',
        showWaterfallPlot: 'Show Waterfall Plot',
        hideWaterfallPlot: 'Hide Waterfall Plot',
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },
    title: {
        text: '',
        style: {
            fontSize: '12px',
        },
    },
    tooltip: {
        animation: false,
        backgroundColor: 'rgba(255, 255, 255, .0)',
        shared: true,
        shadow: false,
        border: 0,
        style: {
            color: '#333333',
            cursor: 'default',
            fontSize: '12px',
            pointerEvents: 'all',
            whiteSpace: 'nowrap',
            opacity: 0,
        },
        useHTML: true,
    },
    series: [
        {
            data: [],
        },
    ],
    plotOptions: {
        series: {
            animation: false,
            connectNulls: false,
            lineWidth: 1,
            marker: {
                enabled: false,
            },
            states: {
                hover: {
                    lineWidth: 1,
                },
                inactive: {
                    opacity: 1,
                },
            },
            point: {
                events: {},
            },
        },
    },
    xAxis: [
        {
            crosshair: true,
            ordinal: false,
            type: 'datetime',
            plotLines: [],
        },
    ],
    yAxis: [
        {
            tickWidth: 1,
            tickLength: 20,
            startOnTick: false,
            endOnTick: false,
            opposite: false,
            softMax: null,
            softMin: null,
            title: {
                align: 'middle',
                text: '',
            },
        },
    ],
    legend: {
        align: 'center',
        verticalAlign: 'top',
        y: 10,
        floating: true,
        enabled: true,
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: ['downloadCSV'],
            },
        },
    },
    isVisible: false,
};
