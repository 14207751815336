var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get as _get, includes as _includes, size as _size } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { getAlertConditionsConfig } from '../../components/charts/chartsWrapper/features/rms/alertConditions';
import { getZones } from '../../components/charts/chartsWrapper/features/rms/zones';
import { calculateMaxYAxisValue, calculateMinYAxisValue, } from '../../components/charts/chartsWrapper/features/shared/extremes';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../store/global/useGlobalStore';
export var useChartAlertLevelsVisibleToggle = function (state, dispatch) {
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return ({
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
    }); }, shallow).chartAlertLevelsVisibleToggle;
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { plotLines: chartAlertLevelsVisibleToggle ? [] : options.yAxis[0].initialPlotLines }),
                    ], series: options.series.map(function (el) {
                        if (!el.isOverlay) {
                            return __assign(__assign({}, el), { zones: chartAlertLevelsVisibleToggle ? [] : el.initialZones });
                        }
                        return el;
                    }) }),
            },
        });
    }, [chartAlertLevelsVisibleToggle]);
};
export var useChangeAlertConditions = function (state, dispatch, chartIdentifier) {
    var mainSeriesLoaded = state.mainSeriesLoaded, readings = state.readings, options = state.options, seriesIsRendered = state.seriesIsRendered;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return ({
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
    }); }, shallow).chartAlertLevelsVisibleToggle;
    var readingTypes = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
    }); }, shallow).readingTypes;
    var _a = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), changeAlertConditions = _a.changeAlertConditions, setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore;
    useEffect(function () {
        if (!mainSeriesLoaded && !_size(readings)) {
            return;
        }
        var isMatchedReadingTypeId = changeAlertConditions.selectedReadingTypeIds
            ? _includes(changeAlertConditions.selectedReadingTypeIds, +chartIdentifier)
            : +_get(changeAlertConditions, 'readingTypeId') === +chartIdentifier;
        if (isMatchedReadingTypeId && _get(changeAlertConditions, 'shouldUpdate')) {
            if (seriesIsRendered) {
                var updatedPlotLines = (options.yAxis[0].plotLines || []).filter(function (plotLine) { return plotLine.isSystem; });
                updatedPlotLines = __spreadArray(__spreadArray([], updatedPlotLines, true), getAlertConditionsConfig(changeAlertConditions, chartIdentifier, setStatesChartFeaturesStore, readingTypes, measure, personalSettingMeasure), true);
                dispatch({
                    type: 'setState',
                    state: {
                        options: __assign(__assign({}, options), { yAxis: [
                                __assign(__assign({}, options.yAxis[0]), { plotLines: chartAlertLevelsVisibleToggle
                                        ? []
                                        : updatedPlotLines
                                            ? __spreadArray([], updatedPlotLines, true) : [], initialPlotLines: updatedPlotLines, softMax: changeAlertConditions
                                        ? calculateMaxYAxisValue(changeAlertConditions, chartIdentifier, readings, readingTypes, measure, personalSettingMeasure)
                                        : options.yAxis[0].softMax, softMin: changeAlertConditions
                                        ? calculateMinYAxisValue(changeAlertConditions, chartIdentifier, readingTypes, measure, personalSettingMeasure)
                                        : options.yAxis[0].softMin }),
                            ], series: options.series.map(function (el) {
                                if (!el.isOverlay) {
                                    return __assign(__assign({}, el), { zones: getZones(chartIdentifier, changeAlertConditions, readingTypes, measure, personalSettingMeasure), initialZones: getZones(chartIdentifier, changeAlertConditions, readingTypes, measure, personalSettingMeasure) });
                                }
                                return el;
                            }) }),
                    },
                });
            }
        }
    }, [changeAlertConditions]);
};
export var useResetAlertConditions = function (state, dispatch, chartIdentifier) {
    var mainSeriesLoaded = state.mainSeriesLoaded, readings = state.readings, options = state.options;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return ({
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
    }); }, shallow).chartAlertLevelsVisibleToggle;
    var readingTypes = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
    }); }, shallow).readingTypes;
    var _a = ChartFeaturesStore(function (state) { return ({
        resetAlertConditions: state.resetAlertConditions,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), resetAlertConditions = _a.resetAlertConditions, setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore;
    useEffect(function () {
        if ((!mainSeriesLoaded && !_size(readings)) ||
            +_get(resetAlertConditions, 'readingTypeId') !== +chartIdentifier) {
            return;
        }
        var updatedPlotLines = (options.yAxis[0].plotLines || []).filter(function (plotLine) { return plotLine.isSystem; });
        updatedPlotLines = __spreadArray(__spreadArray([], updatedPlotLines, true), getAlertConditionsConfig(resetAlertConditions, chartIdentifier, setStatesChartFeaturesStore, readingTypes, measure, personalSettingMeasure), true);
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { yAxis: [
                        __assign(__assign({}, options.yAxis[0]), { plotLines: chartAlertLevelsVisibleToggle ? [] : updatedPlotLines ? __spreadArray([], updatedPlotLines, true) : [], initialPlotLines: updatedPlotLines, softMax: resetAlertConditions
                                ? calculateMaxYAxisValue(resetAlertConditions, chartIdentifier, readings, readingTypes, measure, personalSettingMeasure)
                                : options.yAxis[0].softMax, softMin: resetAlertConditions
                                ? calculateMinYAxisValue(resetAlertConditions, chartIdentifier, readingTypes, measure, personalSettingMeasure)
                                : options.yAxis[0].softMin }),
                    ], series: options.series.map(function (el) {
                        if (!el.isOverlay) {
                            return __assign(__assign({}, el), { zones: ['ambientData', 'ambientNormalizeData'].includes(el.id)
                                    ? []
                                    : getZones(chartIdentifier, resetAlertConditions, readingTypes, measure, personalSettingMeasure) });
                        }
                        return el;
                    }) }),
            },
        });
    }, [resetAlertConditions]);
};
