import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import './TooltipBlockData.scss';
var TooltipBlockData = function (_a) {
    var children = _a.children, icon = _a.icon, _b = _a.classNameChildren, classNameChildren = _b === void 0 ? '' : _b;
    var _c = useState(false), tooltipVisible = _c[0], setTooltipVisible = _c[1];
    var onShow = function () {
        setTooltipVisible(true);
    };
    var onHide = function () {
        setTooltipVisible(false);
    };
    return (_jsxs("div", { children: [_jsx("div", { onMouseEnter: function () { return onShow(); }, onMouseLeave: function () { return onHide(); }, className: "".concat(tooltipVisible && children ? 'tooltip-trigger-wrap' : ''), children: icon }), children ? (_jsx("div", { onMouseEnter: function () { return onShow(); }, onMouseLeave: function () { return onHide(); }, className: "tooltip-container ".concat(tooltipVisible ? 'd-flex' : 'd-none'), children: _jsx("div", { className: 'tooltip-content ' + classNameChildren, children: children }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(TooltipBlockData);
