var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getStatusSelectOptionsShared from '../../../shared/components/actionItem/StatusSelect/config/index';
export var getStatusSelectOptions = function (isTroubleshoot, isTroubleshootingWaiting, isShowAwaitingUpdate, isShowChangeToHardwareIssue, isShowQuestion, isShowInProgress) {
    var data = getStatusSelectOptionsShared(isTroubleshoot, isTroubleshootingWaiting, isShowAwaitingUpdate, 'Previous active state');
    if (isShowQuestion) {
        data = __assign(__assign({}, data), { question: {
                label: 'Question',
                to: 'Question',
            } });
    }
    if (isShowChangeToHardwareIssue) {
        data = __assign(__assign({}, data), { change_to_hardware_issue: {
                label: 'Change to Network issue',
                to: 'New',
            } });
    }
    if (isShowInProgress) {
        data = __assign(__assign({}, data), { in_progress: {
                label: 'In progress',
                to: 'In progress',
            } });
    }
    if (!isTroubleshoot) {
        data = __assign(__assign({}, data), { cancel_snooze: {
                label: 'Cancel Snooze',
                to: 'Awaiting Update',
            } });
    }
    return __assign(__assign({}, data), { cancel_and_close: {
            label: 'Cancel and Close',
            to: 'Close',
        } });
};
