var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Alert, Col, Input, InputGroup, InputGroupText, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { amplitudesArray, frequenciesArray } from '../features/wosFeatures';
var WakeOnShakeSettings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var wosTriggerData = _a.wosTriggerData, installationPoint = _a.installationPoint, isEnabledByDefault = _a.isEnabledByDefault, data = _a.data, setData = _a.setData, isImpactvueEnabled = _a.isImpactvueEnabled;
    var _l = useState(isEnabledByDefault), isEnabled = _l[0], setIsEnabled = _l[1];
    var onFlagChange = function (ev) {
        var _a, _b, _c, _d, _e, _f;
        if (!data.triggers) {
            return;
        }
        setData(__assign(__assign({}, data), { triggers: __assign(__assign({}, data.triggers), { wos: ev.target.checked
                    ? {
                        client_type: (_a = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.client_type) !== null && _a !== void 0 ? _a : 0,
                        data_rate: (_b = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.data_rate) !== null && _b !== void 0 ? _b : null,
                        delay: (_c = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.delay) !== null && _c !== void 0 ? _c : null,
                        installation_point_id: (_d = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.installation_point_id) !== null && _d !== void 0 ? _d : installationPoint.id,
                        samples: (_e = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.samples) !== null && _e !== void 0 ? _e : 2,
                        threshold: (_f = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.threshold) !== null && _f !== void 0 ? _f : null,
                    }
                    : undefined }) }));
        setIsEnabled(ev.target.checked);
    };
    var onInputChange = function (value, field) {
        var _a;
        var _b;
        if (!((_b = data.triggers) === null || _b === void 0 ? void 0 : _b.wos)) {
            return;
        }
        setData(__assign(__assign({}, data), { triggers: __assign(__assign({}, data.triggers), { wos: __assign(__assign({}, data.triggers.wos), (_a = {}, _a[field] = value, _a)) }) }));
    };
    return (_jsxs(Row, { children: [_jsxs(Col, { md: 12, className: "my-2", children: [isImpactvueEnabled ? (_jsx(Alert, { color: "warning", children: "This sensor doesn\u2019t currently support having both Wake-On-Shake and ImpactVue enabled simultaneously. We are actively working on a firmware update to support this in the future." })) : (_jsx(_Fragment, {})), _jsxs(Label, { check: true, className: "mx-4", children: [_jsx(Input, { type: "checkbox", defaultChecked: isEnabled, onChange: onFlagChange, disabled: isImpactvueEnabled }), _jsx("h5", { className: "fz-15", children: _jsx("b", { children: "Wake On Shake Settings" }) })] })] }), _jsxs(Col, { md: 4, className: "border-end", children: [_jsxs(Label, { children: [_jsx("span", { className: "mx-2", id: "setting-td-info", children: _jsx("i", { className: "fa fa-info-circle" }) }), _jsx(UncontrolledTooltip, { placement: "top", target: "setting-td-info", children: _jsx("p", { className: "tooltip-text", children: "This setting determines the time between the detection of the trigger and when the data acquisition begins." }) }), _jsx("b", { children: "Trigger Delay" })] }), _jsx(Row, { children: _jsx(Col, { md: 12, children: _jsxs(InputGroup, { children: [_jsx(Input, { name: "wos-delay", id: "wos-delay", type: "number", className: "select-xl", disabled: !isEnabled || isImpactvueEnabled, min: 0, max: 1000, value: (_d = (_c = (_b = data.triggers) === null || _b === void 0 ? void 0 : _b.wos) === null || _c === void 0 ? void 0 : _c.delay) !== null && _d !== void 0 ? _d : 0, onChange: function (ev) {
                                            return onInputChange(+ev.target.value, 'delay');
                                        } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "ms" }) })] }) }) })] }), _jsxs(Col, { md: 4, className: "border-end", children: [_jsxs(Label, { children: [_jsx("span", { className: "mx-2", id: "setting-ta-info", children: _jsx("i", { className: "fa fa-info-circle" }) }), _jsx(UncontrolledTooltip, { placement: "top", target: "setting-ta-info", children: _jsx("p", { className: "tooltip-text", children: "This setting sets the threshold at which the relative change in acceleration between two consecutive readings constitutes a trigger event." }) }), _jsx("b", { children: "Threshold Amplitude (pk-pk)" })] }), _jsx(Row, { children: _jsx(Col, { md: 12, children: _jsx(Input, { id: "wos-threshold", name: "wos-threshold", type: "select", className: "select-xl", disabled: !isEnabled || isImpactvueEnabled, value: (_g = (_f = (_e = data.triggers) === null || _e === void 0 ? void 0 : _e.wos) === null || _f === void 0 ? void 0 : _f.threshold) !== null && _g !== void 0 ? _g : '', onChange: function (ev) {
                                    return onInputChange(+ev.target.value, 'threshold');
                                }, children: amplitudesArray.map(function (amplitude) { return (_jsx("option", { value: amplitude.key, children: amplitude.value < 1 ? (amplitude.value * 1000).toFixed(2) + ' mG' : amplitude.value + ' G' }, amplitude.key)); }) }) }) })] }), _jsxs(Col, { md: 4, className: "border-end", children: [_jsxs(Label, { children: [_jsx("span", { className: "mx-2", id: "setting-sf-info", children: _jsx("i", { className: "fa fa-info-circle" }) }), _jsx(UncontrolledTooltip, { placement: "top", target: "setting-sf-info", children: _jsx("p", { className: "tooltip-text", children: "This setting determines the frequency at which the vibration data is evaluated against the threshold amplitude. If the scan frequency is set too low, the device may not detect the vibration event if it is short. If it is set too high, the device will consume more power than necessary." }) }), _jsx("b", { children: "Scan Frequency" })] }), _jsx(Row, { children: _jsx(Col, { md: 7, children: _jsx(Input, { id: "wos-data_rate", name: "wos-data_rate", type: "select", className: "select-xl", disabled: !isEnabled || isImpactvueEnabled, value: (_k = (_j = (_h = data.triggers) === null || _h === void 0 ? void 0 : _h.wos) === null || _j === void 0 ? void 0 : _j.data_rate) !== null && _k !== void 0 ? _k : '', onChange: function (ev) {
                                    return onInputChange(+ev.target.value, 'data_rate');
                                }, children: frequenciesArray.map(function (rate) { return (_jsx("option", { value: rate.key, children: rate.value + ' ' + 'Hz' }, rate.key)); }) }) }) })] })] }));
};
export default memo(WakeOnShakeSettings);
