import { setSortState } from '../../shared/helper/store';
import { createStore } from '../../shared/store/createStore';
var useFftAlertLevelsTableStore = createStore(function (set, get) { return ({
    search: '',
    page: 1,
    pageSize: 20,
    sort: [
        {
            field: 'created_at',
            direction: 'desc',
        },
    ],
    actions: {
        setSearch: function (search) { return set({ search: search }); },
        setPage: function (page) { return set({ page: page }); },
        setPageSize: function (pageSize) { return set({ pageSize: pageSize }); },
        setSort: function (field) {
            setSortState(field, set, get);
        },
    },
}); });
export var useTableActions = function () {
    return useFftAlertLevelsTableStore(function (state) { return state.actions; });
};
export var selectPage = function () { return useFftAlertLevelsTableStore(function (state) { return state.page; }); };
export var selectSearch = function () {
    return useFftAlertLevelsTableStore(function (state) { return state.search; });
};
export var selectPageSize = function () {
    return useFftAlertLevelsTableStore(function (state) { return state.pageSize; });
};
export var selectSort = function () {
    return useFftAlertLevelsTableStore(function (state) { return state.sort; });
};
