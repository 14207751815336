var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { clearAllTooltips } from '../../../../../helper/commonHelper';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import Loader from '../../../../Loader';
var ImpactVueToggle = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = InstallationPointStore(function (state) { return ({
        wosTrigger: state.wosTrigger,
        installationPoint: state.installationPoint,
        isSupportHfdvue: state.isSupportHfdvue,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
    }); }, shallow), wosTrigger = _a.wosTrigger, installationPoint = _a.installationPoint, isSupportHfdvue = _a.isSupportHfdvue, setActiveInstallationPoint = _a.setActiveInstallationPoint;
    var _b = useState(false), disabledImpactVueState = _b[0], disabledImpactVueDispatch = _b[1];
    var sensor = _get(installationPoint, 'sensor') || {};
    var isHfdvue = +sensor.is_hfdvue === 1;
    var onImpactVueToggle = useCallback(function () {
        if (wosTrigger) {
            return;
        }
        disabledImpactVueDispatch(true);
        // @ts-ignore
        Endpoints[selectedSystemType]
            //@ts-ignore
            .updateImpactVue({
            sensor_id: sensor.sensor_id,
            is_hfdvue: !isHfdvue ? 1 : 0,
        })
            .then(function (resp) {
            disabledImpactVueDispatch(false);
            if (resp) {
                if (!resp.success) {
                    return toast.error(resp.message || 'Server error', {
                        icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                    });
                }
                setActiveInstallationPoint(__assign(__assign({}, installationPoint), { 
                    // @ts-ignore
                    sensor: __assign(__assign({}, sensor), { is_hfdvue: !isHfdvue ? 1 : 0 }) }));
                toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            }
        })
            .catch(function () {
            disabledImpactVueDispatch(false);
        });
        clearAllTooltips();
    }, [selectedSystemType, installationPoint]);
    return (_jsx("div", { children: isSupportHfdvue ? (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: function () { return onImpactVueToggle(); }, outline: Boolean(!isHfdvue), color: "customChartTopBarSelect", className: "btn-chart-tools position-relative impact_vue", size: "sm", disabled: disabledImpactVueState, style: { height: '30px' }, id: "impact_vue", children: ["Impact Vue", disabledImpactVueState ? (_jsx("div", { className: "position-absolute", children: _jsx(Loader, { variant: "loader-exs" }) })) : (_jsx(_Fragment, {}))] }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "impact_vue", children: wosTrigger
                        ? 'This sensor doesn’t currently support having both Wake-On-Shake and ImpactVue enabled simultaneously. We are actively working on a firmware update to support this in the future.'
                        : isHfdvue
                            ? 'Off Impact Vue'
                            : 'On Impact Vue' })] })) : (_jsx(_Fragment, {})) }));
};
export default React.memo(ImpactVueToggle);
