import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Row } from 'reactstrap';
import AnalystsTable from './AnalystsTable';
import TotalTable from './TotalTable';
var Tables = function (_a) {
    var tablesData = _a.tablesData;
    return (_jsxs(Row, { children: [_jsx(AnalystsTable, { tablesData: tablesData }), _jsx(TotalTable, { tablesData: tablesData })] }));
};
export default memo(Tables);
