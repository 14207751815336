import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Button, ButtonGroup } from "reactstrap";
import { BAD_DATA_READING_TYPES_LABELS, BAD_DATA_TAGS } from "../../../../constants";
import { DateInput } from "../../../../widgets/common";
import "./Filter.scss";
var Filters = function (_a) {
    var selectedTags = _a.selectedTags, onClickFilterTag = _a.onClickFilterTag, setSelectedReadingTypes = _a.setSelectedReadingTypes, onChangeDateRange = _a.onChangeDateRange, dateRange = _a.dateRange;
    return (_jsxs("div", { className: "badData-filter mb-2", children: [_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }), _jsx(ButtonGroup, { className: "badData-filter-btns", children: BAD_DATA_TAGS.map(function (tag) { return (_jsx(Button, { color: selectedTags.includes(tag) ? 'primary' : 'secondary', onClick: function () { return onClickFilterTag(tag); }, size: "sm", children: tag }, "filter_tag_".concat(tag))); }) }), selectedTags.length > 0 && (_jsx("div", { className: "badData-filter-select", children: _jsx(Select, { placeholder: 'Filter By Reading Type', classNamePrefix: "customSelectStyleDefault", onChange: function (values) { return setSelectedReadingTypes(values ? values.map(function (value) { return value.value; }) : []); }, isMulti: true, options: BAD_DATA_READING_TYPES_LABELS.map(function (label) { return ({ label: label, value: label }); }) }) }))] }));
};
export default memo(Filters);
