var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import { get as _get, omit as _omit } from 'lodash';
import React from 'react';
import { BsSquare } from 'react-icons/bs';
import { FiCheckSquare } from 'react-icons/fi';
import { shallow } from 'zustand/shallow';
import { isFftTwfChart, isRmsChart } from '../../../../../helper/chart';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import '../../../../../styles/charts/chartsWrapper/chartSelect.css';
import { clearPaintingFigures } from '../../features/shared/painting';
export var ChartSelect = function (_a) {
    var isChartSelectionChecked = _a.isChartSelectionChecked, isChartPainting = _a.isChartPainting, chartIdentifier = _a.chartIdentifier, dispatch = _a.dispatch, chartRef = _a.chartRef;
    var _b = ChartsStore(function (state) { return ({
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedPainting: state.chartsSelectedPainting,
        setStates: state.setStates,
    }); }, shallow), selectedChartsAvailable = _b.selectedChartsAvailable, chartsSelectedPainting = _b.chartsSelectedPainting, setStates = _b.setStates;
    var onToggleAvailable = function (val) {
        var _a, _b;
        if (!val) {
            clearPaintingFigures(chartRef);
        }
        setStates({
            selectedChartsAvailable: __assign(__assign({}, selectedChartsAvailable), (_a = {}, _a[chartIdentifier] = val, _a)),
            chartsSelectedPainting: __assign(__assign({}, chartsSelectedPainting), (_b = {}, _b[chartIdentifier] = false, _b)),
        });
        dispatch({
            type: 'setState',
            state: {
                isChartSelectionChecked: val,
                isChartPainting: false,
            },
        });
        chartRef.current.chart.userOptions.paintingData.isPaintingEnabled = false;
    };
    var onTogglePainting = function (val) {
        var _a;
        setStates({
            chartsSelectedPainting: __assign(__assign({}, chartsSelectedPainting), (_a = {}, _a[chartIdentifier] = val, _a)),
        });
        dispatch({
            type: 'setState',
            state: {
                isChartPainting: val,
            },
        });
        chartRef.current.chart.userOptions.paintingData.isPaintingEnabled = val;
    };
    return (_jsxs("div", { className: "chart-select", children: [!isChartSelectionChecked ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "me-3", children: "Select if chart has problems!" }), _jsx(BsSquare, { onClick: function () { return onToggleAvailable(true); }, className: "color-svg" })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: "me-3", children: "Select range where problems!" }), _jsx(FiCheckSquare, { onClick: function () { return onToggleAvailable(false); }, className: "color-svg" })] })), isChartSelectionChecked ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "mx-3", children: "Painting mode" }), !isChartPainting ? (_jsx(BsSquare, { onClick: function () { return onTogglePainting(true); }, className: "color-svg" })) : (_jsx(FiCheckSquare, { onClick: function () { return onTogglePainting(false); }, className: "color-svg" }))] })) : (_jsx(_Fragment, {}))] }));
};
export var setPlotBands = function (dispatch, options, selection, chartIdentifier, chartsSelectedRanges, setStatesChartsStore) {
    var _a;
    setStatesChartsStore({
        chartsSelectedRanges: __assign(__assign({}, chartsSelectedRanges), (_a = {}, _a[chartIdentifier] = selection, _a)),
    });
    dispatch({
        type: 'setState',
        state: {
            options: __assign(__assign({}, options), { chart: __assign(__assign({}, options.chart), { selectionMarkerFill: 'rgba(255, 217, 56, 0.15)', events: __assign(__assign({}, options.chart.events), { selection: function (event) {
                            setPlotBands(dispatch, options, {
                                min: event.xAxis[0].min,
                                max: event.xAxis[0].max,
                            }, chartIdentifier, chartsSelectedRanges, setStatesChartsStore);
                            return false;
                        } }) }), xAxis: [
                    __assign(__assign({}, options.xAxis[0]), { zoomEnabled: true, plotBands: [
                            {
                                color: 'rgba(255, 217, 56, 0.15)',
                                dashStyle: 'Dot',
                                from: selection.min,
                                to: selection.max,
                                id: 'window-band',
                                events: {},
                            },
                        ] }),
                ] }),
        },
    });
};
export var deletePlotBand = function (dispatch, options, chartIdentifier, chartsSelectedRanges, setStatesChartsStore, selectedChartsAvailable) {
    setStatesChartsStore({
        chartsSelectedRanges: _omit(chartsSelectedRanges, chartIdentifier),
    });
    dispatch({
        type: 'setState',
        state: {
            options: __assign(__assign({}, options), { chart: __assign(__assign({}, options.chart), { zoomType: 'x', selectionMarkerFill: isFftTwfChart(chartIdentifier) ? 'rgba(51,92,173,0.25)' : 'rgba(51,92,173,0)', events: __assign(__assign({}, options.chart.events), { selection: function () {
                            return true;
                        } }) }), xAxis: [
                    __assign(__assign({}, options.xAxis[0]), { zoomEnabled: selectedChartsAvailable[chartIdentifier] ||
                            isFftTwfChart(chartIdentifier) ||
                            !isRmsChart(chartIdentifier), plotBands: [] }),
                ] }),
        },
    });
};
export var getSvgSource = function (selectedChartsAvailable) {
    var svgSources = {};
    Highcharts.charts.forEach(function (item) {
        var chartIdentifier = +_get(item, 'userOptions.chartIdentifier');
        if (chartIdentifier &&
            item &&
            selectedChartsAvailable[chartIdentifier]) {
            svgSources[chartIdentifier] = item.getSVG({
                chart: { width: item.chartWidth, height: item.chartHeight },
            });
        }
    });
    return svgSources;
};
