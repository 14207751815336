import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import AlertLogContent from '../../../../widgets/alertLogContent';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
export var columns = [
    {
        name: 'sensor_hex',
        title: 'Sensor',
        sortable: true,
        component: function (item) {
            return _jsx(SensorId, { sensor_id: +item.val1, hexBreak: true, notShowEmptyHex: true });
        },
    },
    {
        name: 'reading_type',
        title: 'Reading Type',
        sortable: true,
    },
    {
        name: 'old_data',
        title: 'Old data',
        sortable: false,
        component: function (row) {
            var _a, _b, _c, _d;
            return (_jsx(AlertLogContent, { readingTypeId: +row.val2 || null, cautionValue: +((_b = (_a = row.old_data) === null || _a === void 0 ? void 0 : _a.levels) === null || _b === void 0 ? void 0 : _b.caution), warningValue: +((_d = (_c = row.old_data) === null || _c === void 0 ? void 0 : _c.levels) === null || _d === void 0 ? void 0 : _d.warning) }));
        },
    },
    {
        name: 'new_data',
        title: 'New data',
        sortable: false,
        component: function (row) {
            var _a, _b, _c, _d;
            return (_jsx(AlertLogContent, { readingTypeId: +row.val2 || null, isNewData: true, cautionValue: +((_b = (_a = row.new_data) === null || _a === void 0 ? void 0 : _a.levels) === null || _b === void 0 ? void 0 : _b.caution), warningValue: +((_d = (_c = row.new_data) === null || _c === void 0 ? void 0 : _c.levels) === null || _d === void 0 ? void 0 : _d.warning) }));
        },
    },
    {
        name: 'date_create',
        title: 'Time',
        sortable: true,
        component: function (row) {
            return (_jsx(DateTimeLabel, { id: "default-alert-log-".concat(row.id), dateTime: row.date_create || row.created_at }));
        },
    },
    {
        name: 'user_name',
        title: 'User',
        sortable: true,
    },
];
