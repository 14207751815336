import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { InList } from '../../../widgets/InList';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import Filters from '../../components/watchList/Filter';
import StateLabel from '../../components/watchList/StateLabel';
import { highlightRow } from '../../helper/table';
export var config = function (onViewClick, removeButtonText, showRemoveButton, isShowEdit) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true });
                },
            },
            {
                name: 'time_create',
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "watch_list_created_at_".concat(row.id), timeBreak: true, dateTime: row.time_create }));
                },
            },
            {
                name: 'description',
                title: 'Description',
                sortable: true,
                style: {
                    minWidth: '500px',
                },
                component: function (row) {
                    return row.description || '';
                },
            },
            {
                name: 'status',
                title: 'Status',
                component: function (row) {
                    return _jsx(InList, { entity: row });
                },
            },
            {
                name: 'state',
                title: 'State',
                sortable: true,
                component: function (row) {
                    return _jsx(StateLabel, { isAiExisting: +_size(row.actionItem) > 0 });
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsx(_Fragment, { children: _jsxs(ButtonGroup, { className: "btn-group_space", children: [_jsx(Button, { onClick: function (ev) {
                                        onViewClick(row.installation_point_id);
                                        highlightRow(ev);
                                    }, color: "primary", className: "button-ml", size: "sm", children: "View" }), isShowEdit ? (_jsx(Button, { onClick: function () { return callbacks.editWatchListItem(row.id, row.description); }, color: "success", className: "button-ml", size: "sm", children: "Edit" })) : (_jsx(_Fragment, {})), showRemoveButton && showRemoveButton(row) ? (_jsx(Button, { color: "danger", onClick: function () { return callbacks.deleteWatchListItem(row.id); }, size: "sm", children: removeButtonText })) : (_jsx(_Fragment, {}))] }) }));
                },
            },
        ],
        sort: [
            {
                field: 'time_create',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return (_jsx(Filters, { onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, onChangeState: callbacks.onChangeState, isFilterClick: filtersData.isFilterClick }));
        },
        filtersData: {
            dateRange: [],
            isFilterClick: false,
        },
    };
};
