var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Button, ButtonGroup, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CAUSE_CATEGORY_SEVERITY_MINOR } from '../../../constants';
import ApproveReassignRecommendations from '../../components/actionItem/selectedRecommendations/ApproveReassignRecommendations';
import { selectCloseReportModalState, useActionItemModalsActions, } from '../../store/AIPreAi/useActionItemModalsStore';
var CloseReportModal = function (_a) {
    var recommendations = _a.recommendations;
    var closeReportModalState = selectCloseReportModalState();
    var setCloseReportModalState = useActionItemModalsActions().setCloseReportModalState;
    var _b = useState(recommendations.length === 0), isRecommendationsApproved = _b[0], setIsRecommendationsApproved = _b[1];
    var _c = useState({}), reassignedRecommendations = _c[0], setReassignedRecommendations = _c[1];
    var _d = useState(''), internalComment = _d[0], setInternalComment = _d[1];
    var onCancel = function () {
        setCloseReportModalState({
            visible: false,
            onSave: function () { },
            reportProcessingType: 'accept',
        });
    };
    var onConfirm = function () {
        var _a;
        closeReportModalState.onSave({
            reassignedRecommendations: ((_a = reassignedRecommendations.faultRecommendations) === null || _a === void 0 ? void 0 : _a.filter(function (faultRecommendation) { return faultRecommendation; }).map(function (faultRecommendation) { return (__assign(__assign({}, faultRecommendation), { severity: CAUSE_CATEGORY_SEVERITY_MINOR })); })) || [],
            internalComment: internalComment,
            isRecommendationsApproved: isRecommendationsApproved && Object.values(reassignedRecommendations).length === 0 ? 1 : 0,
        });
        onCancel();
    };
    return (_jsxs(Modal, { size: "xl", className: "modal-response", toggle: onCancel, isOpen: closeReportModalState.visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Confirmation" }), _jsx(ModalBody, { children: _jsxs(Form, { children: [_jsx("div", { className: "text-center h-4", children: recommendations.length > 0 ? (_jsxs("b", { children: ["Are you sure you want to ", closeReportModalState.reportProcessingType, " the report with specified reason and the corresponding Equipment Fault Reason?"] })) : (_jsxs("b", { children: ["Are you sure you want to ", closeReportModalState.reportProcessingType, " the report?"] })) }), _jsx(ApproveReassignRecommendations, { recommendations: recommendations, setReassignedRecommendations: setReassignedRecommendations, setIsRecommendationsApproved: setIsRecommendationsApproved, setInternalComment: setInternalComment, isRecommendationsApproved: isRecommendationsApproved })] }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { disabled: !isRecommendationsApproved, size: "sm", color: "primary", onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(CloseReportModal);
