// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-action-report-body-title {
  font-size: 14px;
  color: #777;
}
.dark-layout .no-action-report-body-title {
  color: #ccc;
}
.no-action-report-body img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/reports/components/NoActionReport/index.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,WAAA;AAAJ;AACI;EACE,WAAA;AACN;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".no-action-report-body {\n  &-title {\n    font-size: 14px;\n    color: #777;\n    .dark-layout & {\n      color: #ccc;\n    }\n  }\n  & img {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
