import { useEffect } from 'react';
import { usePrevious } from '../../../../customHooks';
import { onBlockedFftTwfMouseMove } from '../../../helper/chart';
export var useLockedCursor = function (state, chartRef) {
    var isVisibleFullscreenChart = state.isVisibleFullscreenChart;
    var prevIsVisibleFullscreenChart = usePrevious(isVisibleFullscreenChart);
    useEffect(function () {
        if (!prevIsVisibleFullscreenChart || prevIsVisibleFullscreenChart === isVisibleFullscreenChart) {
            return;
        }
        chartRef.current.chart.userOptions.isLockedCursor = false;
        onBlockedFftTwfMouseMove(true, chartRef.current.chart);
    }, [isVisibleFullscreenChart]);
};
