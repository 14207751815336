var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { FaCheck, FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { useBearingCreateMutation } from '../../services/bearings/useBearingCreateMutation';
import { useBearingSpecificationsQuery, } from '../../services/bearings/useBearingSpecificationsQuery';
import { useBearingUpdateMutation } from '../../services/bearings/useBearingUpdateMutation';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
var calculateBearingsByFormula = function (formulaData, ballsRollers) {
    var ftf = (1 -
        (formulaData.balls_rollers_diameter /
            ((formulaData.outside_diameter + formulaData.bore_diameter) / 2)) *
            Math.cos(formulaData.contact_andle)) /
        2;
    var bsf = ((formulaData.outside_diameter + formulaData.bore_diameter) /
        2 /
        (formulaData.balls_rollers_diameter * 2)) *
        (1 -
            Math.pow(((formulaData.balls_rollers_diameter /
                ((formulaData.outside_diameter + formulaData.bore_diameter) / 2)) *
                Math.cos(formulaData.contact_andle)), 2));
    var bpfo = ftf * ballsRollers;
    var bpfi = (1 - ftf) * ballsRollers;
    return { ftf: ftf, bsf: bsf, bpfo: bpfo, bpfi: bpfi };
};
var CustomBearingModal = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        customBearingModal: state.customBearingModal,
    }); }, shallow), setStatesChartFeaturesStore = _j.setStatesChartFeaturesStore, customBearingModal = _j.customBearingModal;
    var _k = useBearingSpecificationsQuery(), bearingsSpecificationsData = _k.data, isLoading = _k.isLoading;
    var _l = useBearingCreateMutation(), bearingCreateMutation = _l.mutate, bearingCreateMutationPending = _l.isPending;
    var _m = useBearingUpdateMutation(), bearingUpdateMutation = _m.mutate, bearingUpdateMutationPending = _m.isPending;
    var _o = useState(__assign({ id: ((_a = customBearingModal.bearing) === null || _a === void 0 ? void 0 : _a.id) || null, mfr: ((_b = customBearingModal.bearing) === null || _b === void 0 ? void 0 : _b.mfr) || null, name: ((_c = customBearingModal.bearing) === null || _c === void 0 ? void 0 : _c.name) || null, balls_rollers: ((_d = customBearingModal.bearing) === null || _d === void 0 ? void 0 : _d.balls_rollers) || null, ftf: ((_e = customBearingModal.bearing) === null || _e === void 0 ? void 0 : _e.ftf) || null, bsf: ((_f = customBearingModal.bearing) === null || _f === void 0 ? void 0 : _f.bsf) || null, bpfo: ((_g = customBearingModal.bearing) === null || _g === void 0 ? void 0 : _g.bpfo) || null, bpfi: ((_h = customBearingModal.bearing) === null || _h === void 0 ? void 0 : _h.bpfi) || null }, customBearingModal.extraData)), data = _o[0], setData = _o[1];
    var _p = useState({
        balls_rollers_diameter: 0,
        bore_diameter: 0,
        outside_diameter: 0,
        contact_andle: 0,
    }), formulaData = _p[0], setFormulaData = _p[1];
    var _q = useState('manually'), selectedCalculationType = _q[0], setSelectedCalculationType = _q[1];
    var _r = useState('US'), measurement = _r[0], setMeasurement = _r[1];
    useEffect(function () {
        if (selectedCalculationType === 'manually') {
            return;
        }
        if (selectedCalculationType === 'select' && (bearingsSpecificationsData === null || bearingsSpecificationsData === void 0 ? void 0 : bearingsSpecificationsData.list) && data.balls_rollers > 0) {
            var bearingSpecification = bearingsSpecificationsData.list.find(function (specification) { return specification.number_of_balls === data.balls_rollers; });
            if (bearingSpecification) {
                setData(__assign(__assign({}, data), { ftf: bearingSpecification.ftf, bsf: bearingSpecification.bsf, bpfo: bearingSpecification.bpfo, bpfi: bearingSpecification.bpfi }));
            }
            return;
        }
        if (selectedCalculationType === 'formula' &&
            data.balls_rollers > 0 &&
            formulaData.balls_rollers_diameter > 0 &&
            formulaData.bore_diameter > 0 &&
            formulaData.outside_diameter > 0) {
            setData(__assign(__assign({}, data), calculateBearingsByFormula(formulaData, data.balls_rollers)));
        }
    }, [selectedCalculationType, data.balls_rollers, formulaData]);
    var onCancel = function () {
        setStatesChartFeaturesStore({
            customBearingModal: {
                visible: false,
                bearing: {},
            },
        });
    };
    var onSave = function () {
        if (selectedCalculationType === 'formula') {
            // Request from QA
            if (+data.balls_rollers === 0) {
                toast.error('Balls/Rollers field is required', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
                return;
            }
            if (+formulaData.balls_rollers_diameter === 0) {
                toast.error('Ball/roller diameter field is required', {
                    icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                });
                return;
            }
            if (+formulaData.bore_diameter === 0) {
                toast.error('Bore diameter (inner ring) field is required', {
                    icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                });
                return;
            }
            if (+formulaData.outside_diameter === 0) {
                toast.error('Outside diameter (outer ring) field is required', {
                    icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                });
                return;
            }
            if (+formulaData.contact_andle === 0) {
                toast.error('Contact angle field is required', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
                return;
            }
        }
        var mutation = data.id ? bearingUpdateMutation : bearingCreateMutation;
        mutation(data, {
            onSuccess: function (resp) {
                toast.success(resp.message || ('Successfully ' + data.id ? 'updated' : 'created'), {
                    icon: _jsx(FaCheck, { style: { fill: '#198754' } }),
                });
                onCancel();
            },
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Add Custom Bearing", isLoading || bearingCreateMutationPending || bearingUpdateMutationPending ? (_jsx(FaSpinner, { className: "ms-2 spinner-anim" })) : (_jsx(_Fragment, {}))] }), _jsxs(ModalBody, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "name", children: "Name:" }), _jsx(Input, { id: "name", value: data.name, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { name: ev.target.value }));
                                            } })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "mfr", children: "Manufacturer:" }), _jsx(Input, { id: "mfr", value: data.mfr, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { mfr: ev.target.value }));
                                            } })] }) })] }), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "calculation_type", children: "Calculation Type:" }), _jsxs(Input, { type: "select", name: "calculation_type", onChange: function (ev) {
                                                return setSelectedCalculationType(ev.target.value);
                                            }, children: [_jsx("option", { value: "manually", children: "By default" }), _jsx("option", { value: "formula", children: "Calculation" }), _jsx("option", { value: "select", children: "Approximate Calculation" })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "balls_rollers", children: "Balls/Rollers:" }), _jsx(Input, { id: "balls_rollers", type: "number", min: 0, step: 1, value: data.balls_rollers, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { balls_rollers: +ev.target.value }));
                                            } })] }) })] }), selectedCalculationType === 'formula' ? (_jsxs(_Fragment, { children: [_jsxs(Row, { className: "mb-2", children: [_jsx(Col, { md: 2, children: _jsx(Label, { children: "Measurement:" }) }), _jsxs(Col, { className: "float-start", children: [_jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "bearing_measurement_us", checked: measurement === 'US', onChange: function () { return setMeasurement('US'); } }), "US"] }) }), _jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "bearing_measurement_metric", checked: measurement === 'metric', onChange: function () { return setMeasurement('metric'); } }), "Metric"] }) })] })] }), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "balls_rollers_diameter", children: "Ball/roller diameter:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "balls_rollers_diameter", type: "number", min: 0, step: 1, value: formulaData.balls_rollers_diameter, onChange: function (ev) {
                                                                return setFormulaData(__assign(__assign({}, formulaData), { balls_rollers_diameter: +ev.target.value }));
                                                            } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: measurement === 'US' ? 'in' : 'mm' }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "bore_diameter", children: "Bore diameter (inner ring):" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "bore_diameter", type: "number", min: 0, step: 1, value: formulaData.bore_diameter, onChange: function (ev) {
                                                                return setFormulaData(__assign(__assign({}, formulaData), { bore_diameter: +ev.target.value }));
                                                            } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: measurement === 'US' ? 'in' : 'mm' }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "outside_diameter", children: "Outside diameter (outer ring):" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "outside_diameter", type: "number", min: 0, step: 1, value: formulaData.outside_diameter, onChange: function (ev) {
                                                                return setFormulaData(__assign(__assign({}, formulaData), { outside_diameter: +ev.target.value }));
                                                            } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: measurement === 'US' ? 'in' : 'mm' }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "contact_andle", children: "Contact angle:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "contact_andle", type: "number", min: 0, step: 1, value: formulaData.contact_andle, onChange: function (ev) {
                                                                return setFormulaData(__assign(__assign({}, formulaData), { contact_andle: +ev.target.value }));
                                                            } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "deg" }) })] })] }) })] })] })) : (_jsx(_Fragment, {})), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "ftf", children: "FTF:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "ftf", type: "number", min: 0, step: 1, disabled: selectedCalculationType !== 'manually', value: data.ftf, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { ftf: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "Orders" }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "bsf", children: "BSF:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "bsf", type: "number", min: 0, step: 1, disabled: selectedCalculationType !== 'manually', value: data.bsf, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { bsf: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "Orders" }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "bpfo", children: "BPFO:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "bpfo", type: "number", min: 0, step: 1, disabled: selectedCalculationType !== 'manually', value: data.bpfo, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { bpfo: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "Orders" }) })] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "bpfi", children: "BPFI:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "bpfi", type: "number", min: 0, step: 1, disabled: selectedCalculationType !== 'manually', value: data.bpfi, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { bpfi: +ev.target.value }));
                                                    } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: "Orders" }) })] })] }) })] })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", outline: true, color: "primary", onClick: onSave, children: "Save" })] }) })] }));
};
export default memo(CustomBearingModal);
