var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { get as _get } from 'lodash';
import moment from 'moment/moment';
import React, { memo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import ReactDOMServer from 'react-dom/server';
import { Badge, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Loader from '../../components/Loader';
import { fftTicksPositioner, formatXAxisFFT, } from '../../components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import ResetFftTwfZoomButton from '../../components/charts/chartsWrapper/components/shared/chartToolsButtons/ResetFftTwfZoomButton';
import { FftTooltipFormatter, fftPositioner, } from '../../components/charts/chartsWrapper/components/shared/tooltip';
import { DEFAULT_CHARTS_CONFIG } from '../../config/charts/defaultChartsConfig';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
export var ChartBlock = function (_a) {
    var _b, _c;
    var chartIdentifier = _a.chartIdentifier, isPending = _a.isPending, series = _a.series, additionalInfoForTooltip = _a.additionalInfoForTooltip, alertLevel = _a.alertLevel, onChange = _a.onChange, activeAlertsLevel = _a.activeAlertsLevel;
    var chartRef = useRef(null);
    var fftData = FftChartsStore(function (state) { return state.data; });
    var _d = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        currentSpeed: state.currentSpeed,
    }); }, shallow), readingTypes = _d.readingTypes, currentSpeed = _d.currentSpeed;
    var isOutlier = +_get(fftData, ['meta', chartIdentifier, 'is_outlier'], 0) === 1;
    return (_jsxs(_Fragment, { children: [_jsxs(Nav, { className: "d-flex justify-content-end", tabs: true, children: [_jsx(NavItem, { children: _jsx(NavLink, { active: alertLevel.following_type === 1, onClick: function () { return onChange(activeAlertsLevel, 'following_type', 1); }, children: "The currently visible reading" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: alertLevel.following_type === 2, onClick: function () { return onChange(activeAlertsLevel, 'following_type', 2); }, children: "Recent readings" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: alertLevel.following_type === 3, onClick: function () { return onChange(activeAlertsLevel, 'following_type', 3); }, children: "Recent days" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: alertLevel.following_type === 4, onClick: function () { return onChange(activeAlertsLevel, 'following_type', 4); }, children: "Date range" }) })] }), _jsxs("div", { className: "position-relative", children: [isPending ? (_jsx("div", { style: {
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            backdropFilter: 'blur(2px)',
                            width: '100%',
                            height: '100%',
                        }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "chart-actions-btn-wrapper", style: { height: 45 }, children: [_jsx(ResetFftTwfZoomButton, { chart: (_b = chartRef === null || chartRef === void 0 ? void 0 : chartRef.current) === null || _b === void 0 ? void 0 : _b.chart, width: 30, isDisabled: false }), _jsx(TabContent, { children: _jsx(TabPane, { className: "position-relative", children: _jsxs(Row, { className: "", children: [_jsx(Col, { md: alertLevel.following_type === 4 ? 5 : 7, children: alertLevel.following_type && alertLevel.following_type !== 1 ? (_jsxs("div", { className: "float-end mt-1", children: [_jsx(FormGroup, { check: true, className: "d-inline-block", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "series-type", checked: alertLevel.fft_envelope_calc_type === 1, onChange: function () { return onChange(activeAlertsLevel, 'fft_envelope_calc_type', 1); } }), "Average"] }) }), _jsx(FormGroup, { check: true, className: "d-inline-block ms-2", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "series-type", checked: alertLevel.fft_envelope_calc_type === 2, onChange: function () { return onChange(activeAlertsLevel, 'fft_envelope_calc_type', 2); } }), "Peak"] }) })] })) : (_jsx(_Fragment, {})) }), _jsxs(Col, { md: alertLevel.following_type === 4 ? 7 : 5, children: [alertLevel.following_type === 2 ? (_jsxs(FormGroup, { inline: true, children: [_jsx(Input, { type: "number", step: "1", bsSize: "sm", max: 20, id: "avg-last-readings-count", name: "avg-last-readings-count", className: "d-inline-block w-40 me-2", onChange: function (ev) {
                                                                    return onChange(activeAlertsLevel, 'avg_last_r_count', ev.target.value > 20 ? 20 : ev.target.value);
                                                                }, value: alertLevel.avg_last_r_count }), _jsx(Label, { for: "avg-last-readings-count", children: "readings" })] })) : (_jsx(_Fragment, {})), alertLevel.following_type === 3 ? (_jsxs(FormGroup, { inline: true, children: [_jsx(Input, { type: "number", step: "1", bsSize: "sm", id: "avg-last-days-count", name: "avg-last-days-count", className: "d-inline-block w-40 me-2", onChange: function (ev) {
                                                                    return onChange(activeAlertsLevel, 'avg_last_d_count', Math.abs(parseInt(ev.target.value)));
                                                                }, value: alertLevel.avg_last_d_count }), _jsx(Label, { for: "avg-last-days-count", children: "day(s)" })] })) : (_jsx(_Fragment, {})), alertLevel.following_type === 4 ? (_jsxs(FormGroup, { inline: true, children: [_jsx("div", { className: "d-inline-block w-40", children: _jsx(DatePicker, { id: "datepicker", className: "form-control", maxDate: moment().toDate(), showTimeSelect: true, timeFormat: "HH:mm", selected: alertLevel.avg_date_from, dateFormat: "yyyy/MM/dd HH:mm", onChange: function (date) { return onChange(activeAlertsLevel, 'avg_date_from', date); } }) }), _jsx("span", { className: "ms-2 me-2 d-inline-block", children: "to" }), _jsx("div", { className: "d-inline-block w-40", children: _jsx(DatePicker, { id: "datepicker", className: "form-control", maxDate: moment().toDate(), showTimeSelect: true, timeFormat: "HH:mm", selected: alertLevel.avg_date_to, dateFormat: "yyyy/MM/dd HH:mm", onChange: function (date) { return onChange(activeAlertsLevel, 'avg_date_to', date); } }) })] })) : (_jsx(_Fragment, {}))] })] }) }) })] }), _jsxs("div", { className: "position-relative", children: [isOutlier ? (_jsx(Badge, { className: "outlier-chart-label", color: "warning", children: "THIS READING WAS MARKED AS OUTLIER" })) : (_jsx(_Fragment, {})), _jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: getChartOptions(series, (_c = alertLevel === null || alertLevel === void 0 ? void 0 : alertLevel.fft_band_frequency_type) !== null && _c !== void 0 ? _c : 'Hz', chartIdentifier, currentSpeed, readingTypes, additionalInfoForTooltip) })] })] })] }));
};
var getChartOptions = function (series, units, chartIdentifier, currentSpeed, readingTypes, additionalInfoForTooltip) {
    return __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
            style: {
                fontFamily: "'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, Helvetica, sans-serif",
                fontSize: '12px',
            },
            resetZoomButton: {
                theme: { style: { display: 'none' } },
            },
            type: 'line',
            zoomType: 'x',
            borderColor: 'rgb(241, 238, 233)',
            backgroundColor: 'rgb(241, 238, 233)',
            borderWidth: 2,
            animation: false,
        }, boost: {
            enabled: true,
        }, series: series, xAxis: [
            {
                crosshair: true,
                ordinal: false,
                labels: {
                    formatter: function () {
                        return formatXAxisFFT(this.chart, this.value, units, currentSpeed);
                    },
                },
                tickPositioner: function () {
                    return fftTicksPositioner(this.chart, this.max, units, currentSpeed);
                },
            },
        ], chartIdentifier: chartIdentifier, tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { positioner: function (labelWidth) {
                return fftPositioner(labelWidth, this.chart);
            }, formatter: function () {
                return ReactDOMServer.renderToStaticMarkup((_jsx("div", { children: _jsx(FftTooltipFormatter, { readingTypes: readingTypes, points: this.points, currentSpeed: currentSpeed, showPeaks: false, xValue: this.x, children: additionalInfoForTooltip }) })));
            }, shape: 'square', backgroundColor: 'rgba(255, 255, 255, .85)' }), exporting: {
            enabled: false,
        } });
};
export default memo(ChartBlock);
