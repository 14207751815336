import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import SlaSettingsTable from '../../../shared/components/slaSettings/SlaSettingsTable';
import User from '../../../shared/helper/user';
import { ACTION_LIST } from '../../constants';
var SlaSettings = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    if (!User.isSuperAdmin() && !User.can('manageWatchList')) {
        return _jsxs("p", { className: "mt-2 text-center fz-18", children: ["You ", "haven't", " access to this page"] });
    }
    return (_jsx(SlaSettingsTable, { selectedFacility: selectedFacility, actionListStatusNames: ACTION_LIST.STATUS_NAMES, refreshFlag: refreshFlag }));
};
export default memo(SlaSettings);
