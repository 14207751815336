var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from "lodash";
import React, { useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";
import { shallow } from "zustand/shallow";
import { InstallationPointStore } from "../../../../../../store/charts/chartsContent/InstallationPointStore";
var HighPassFilter = function (_a) {
    var width = _a.width, isDisabled = _a.isDisabled;
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
    }); }, shallow), installationPoint = _c.installationPoint, setActiveInstallationPoint = _c.setActiveInstallationPoint;
    var installationPointSettings = installationPoint.settings;
    var maxHighPass = +_get(installationPointSettings, 'fft_settings.0.fmax', 1200);
    var _d = useState(_get(installationPointSettings, 'high_pass', 0)), highPass = _d[0], setHighPass = _d[1];
    var setHighPassValue = function (highPassValue) {
        setActiveInstallationPoint(__assign(__assign({}, installationPoint), { 
            // @ts-ignore
            settings: __assign(__assign({}, installationPoint.settings), { high_pass: highPassValue }) }));
        setIsVisibleDropdown(false);
    };
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "chart-action-btn high-pass-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsxs(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: ["High-pass filter - ", _get(installationPointSettings, 'high_pass', 0), " Hz"] }), _jsxs(DropdownMenu, { children: [_jsx("div", { className: "title", children: _jsxs("b", { children: ["Input High-Pass filter from 1 Hz to ", maxHighPass, " Hz"] }) }), _jsxs("div", { className: "my-2 d-flex align-items-center justify-content-between", children: [_jsx(Label, { for: "high_pass", children: "RMS High-Pass Filter:" }), _jsx(Input, { bsSize: "sm", type: "number", name: "high_pass", id: "high_pass", value: highPass, step: 1, min: 0, max: maxHighPass, onChange: function (ev) {
                                            var value = +ev.target.value;
                                            if (value <= maxHighPass) {
                                                setHighPass(value);
                                            }
                                        } })] }), _jsx(DropdownItem, { divider: true }), _jsxs("div", { className: "d-flex justify-content-between", children: [_jsx(Button, { className: "clear-btn", size: "sm", color: "secondary", onClick: function () { return setHighPassValue(0); }, children: "Clear" }), _jsx(Button, { size: "sm", color: "primary", onClick: function () { return setHighPassValue(highPass); }, children: "Save" })] })] })] }) }) }));
};
export default React.memo(HighPassFilter);
