import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'reactstrap';
import { DateInput } from '../../../../widgets/common';
import { onSort } from '../../../features/table/tableQuery';
import { useStatisticCountAlertLevelQuery, } from '../../../services/watchersStatistic/useStatisticCountAlarmLevelQuery';
import { selectSortDataTable, useCountOfAlarmLevelsDataTableActions, } from '../../../store/watchersStatistic/useCountOfAlarmLevelsDataTableStore';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
import AlarmLevelCountModal from './components/AlarmLevelCountModal';
var CountAlarmLevelTab = function () {
    var _a = useState(new Date()), selectedMonth = _a[0], setSelectedMonth = _a[1];
    var _b = useStatisticCountAlertLevelQuery({ selectedMonth: selectedMonth }), statisticCountAlertLevelData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    var setSort = useCountOfAlarmLevelsDataTableActions().setSort;
    var sort = selectSortDataTable();
    if (!statisticCountAlertLevelData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    var config = statisticCountAlertLevelData.config;
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx("h4", { children: "Count of alarm levels" }), _jsx(Row, { children: _jsx(Col, { md: 3, className: "mb-2", children: _jsxs("span", { className: "d-flex align-items-center", children: [_jsx("div", { className: "me-1", children: "Date:" }), _jsx(DatePicker, { onChange: function (selectedDateValue) { return setSelectedMonth(selectedDateValue); }, selected: selectedMonth, showMonthYearPicker: true, maxDate: Date.now(), dateFormat: "MMMM yyyy", customInput: _jsx(DateInput, {}) })] }) }) }), _jsx(Col, { md: 12, children: _jsx(DataTable, { config: config, isLoading: isLoading || isFetching, onSort: function (column_name, ev) { return onSort(column_name, sort, setSort, ev); } }) })] }), _jsx(AlarmLevelCountModal, { selectedMonth: selectedMonth })] }));
};
export default memo(CountAlarmLevelTab);
