// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-suggestedLevels .action-icon {
  margin-left: 10px;
}
.modal-suggestedLevels .action-icon svg {
  width: 20px;
  height: 20px;
}

.installation-points-list {
  max-height: 250px;
  overflow-y: auto;
}

.suggested-settings-tabs {
  border-bottom: none !important;
}
.suggested-settings-tabs .nav-item .nav-link {
  border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/suggestedLevel/index.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;;AAKA;EACE,iBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,8BAAA;AAFF;AAIE;EACE,2BAAA;AAFJ","sourcesContent":[".modal-suggestedLevels {\n  & .action-icon {\n    margin-left: 10px;\n\n    & svg {\n      width: 20px;\n      height: 20px;\n    }\n  }\n}\n\n.installation-points-list {\n  max-height: 250px;\n  overflow-y: auto;\n}\n\n.suggested-settings-tabs {\n  border-bottom: none !important;\n\n  & .nav-item .nav-link {\n    border-radius: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
