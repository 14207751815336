export var hideAmbientReadings = function (series, updateSeries) {
    var existingAmbientSeriesIndex = findExistingAmbientSeriesIndex(series);
    if (existingAmbientSeriesIndex !== -1) {
        // @ts-ignore
        series[existingAmbientSeriesIndex].visible = false;
        return updateSeries(series);
    }
};
export var showAmbientReadings = function (installationPointId, countDays, series, updateSeries) {
    var existingAmbientSeriesIndex = findExistingAmbientSeriesIndex(series);
    if (existingAmbientSeriesIndex !== -1) {
        // @ts-ignore
        series[existingAmbientSeriesIndex].visible = true;
        return updateSeries(series);
    }
};
export var findExistingAmbientSeriesIndex = function (series) {
    var existingAmbientSeriesIndex = -1;
    series.map(function (seriesItem, index) {
        if (seriesItem.id === 'ambientData') {
            existingAmbientSeriesIndex = index;
        }
    });
    return existingAmbientSeriesIndex;
};
