var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
var SetFor = function (_a) {
    var data = _a.data, setData = _a.setData;
    return (_jsxs(Row, { className: "mt-2", children: [_jsx(Col, { md: 2, children: _jsx("span", { className: "fw-bold mt-2 d-block align-middle", children: "Set For:" }) }), _jsx(Col, { md: 10, children: _jsxs(Nav, { tabs: true, children: [_jsx(NavItem, { children: _jsx(NavLink, { active: data.type === 'single', onClick: function () { return setData(__assign(__assign({}, data), { type: 'single' })); }, children: "Installation Point Only" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: data.type === 'all', onClick: function () { return setData(__assign(__assign({}, data), { type: 'all' })); }, children: "All Installation Points On Equipment" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: data.type === 'reading', onClick: function () { return setData(__assign(__assign({}, data), { type: 'reading' })); }, children: "Current Reading Only" }) })] }) })] }));
};
export default SetFor;
