import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FaultFrequenciesTable from '../../components/faultFrequency/FaultFrequenciesTable';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
var ManageFaultFrequenciesModal = function (_a) {
    var installationPoint = _a.installationPoint;
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var onCancel = function () {
        setStatesChartFeaturesStore({
            isVisibleManageFaultFrequencies: false,
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Manage fault/forcing frequency" }), _jsx(ModalBody, { className: "bearing-modal-content", children: _jsx(FaultFrequenciesTable, { installationPoint: installationPoint }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default ManageFaultFrequenciesModal;
