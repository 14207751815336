import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var equipmentPhotoSaveFn = function (coreLink, data, equipmentId, installationPointId) { return Api.post("".concat(coreLink, "/equipment-photos/update"), data, { query: { equipmentId: equipmentId, installationPointId: installationPointId } }); };
export var useEquipmentPhotoSaveMutation = function (equipmentId, installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) {
            return equipmentPhotoSaveFn(getCoreUrl(selectedSystemType), data, equipmentId, installationPointId);
        },
    });
};
