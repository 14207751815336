import Api from '../../api';
export default {
    getSensorInfoRequestOnDemand: function (sensor_id, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/request-on-demand-group/sensor-info/".concat(sensor_id), options);
    },
    getEstimatedRequest: function (installationPointId, requestId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/request-on-demand-group/get-estimate-request/".concat(installationPointId, "/").concat(requestId), options);
    },
    requestOnDemand: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/request-on-demand-group', body, options);
    },
    getOnDemandUsersFftRequests: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/on-demand-users-fft-requests', options);
    },
    getOnDemandUsersFftRequestsCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/on-demand-users-fft-requests/csv', options);
    },
};
