import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { filter as _filter, get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { READING_TYPES } from '../../../../../../constants';
import { getAxisName } from '../../../../../../widgets/sensor';
import { hasAlertConditionsAction } from '../../../../../features/suggestedLevels';
import { getUoms } from '../../../../../helper/chart';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../../../store/global/useGlobalStore';
import { ExistingAlertCondition } from '../ExistingAlertCondition/ExistingAlertCondition';
var SuggestedLevelsUnitData = function (_a) {
    var readingTypes = _a.readingTypes, suggestedLevels = _a.suggestedLevels, alertLevelsSettings = _a.alertLevelsSettings, installationPoint = _a.installationPoint, precision = _a.precision, units = _a.units, type = _a.type;
    return (_jsx(_Fragment, { children: readingTypes.map(function (readingTypeId, index) {
            var _a;
            var typeAlertLevel = type === 'caution' ? 'Caution' : 'Warning';
            var alertConditions = _filter(installationPoint.alertCondition, function (alertCondition) {
                return +readingTypeId === +alertCondition.reading_type_id && alertCondition.equipment_type === 'sensor';
            });
            var alertCondition = _filter(alertConditions, function (alertCondition) {
                return _get(alertCondition, 'alertLevel.name', '').toLowerCase() === type;
            });
            var suggestedLevel = _get(suggestedLevels.filter(function (suggestedLevel) { return +suggestedLevel.reading_type_id === +readingTypeId; }), 0);
            var username = alertLevelsSettings[readingTypeId][type + 'Settings'].username;
            var value = alertLevelsSettings[readingTypeId][type + 'Settings'].value;
            var triggerTime = alertLevelsSettings[readingTypeId][type + 'Settings'].trigger_time;
            var analystNotesData = alertCondition.find(function (el) { return el.comments.length; });
            return (_jsxs("span", { className: "d-block text-nowrap", children: [_jsx(Alert, { installationPoint: installationPoint, alertLevelsSettings: alertLevelsSettings, analystNotesData: (_a = analystNotesData === null || analystNotesData === void 0 ? void 0 : analystNotesData.comments) !== null && _a !== void 0 ? _a : [], readingTypeId: readingTypeId, type: type, index: index, username: username, typeAlertLevel: typeAlertLevel, value: value, triggerTime: triggerTime }), suggestedLevels.length > 1 ? _jsx("b", { className: "me-2", children: getAxisName(readingTypeId) }) : _jsx(_Fragment, {}), suggestedLevel ? parseFloat(suggestedLevel.value).toFixed(precision) : _jsx(_Fragment, { children: "No set" }), "\u00A0/\u00A0", alertCondition.length ? (_jsx(ExistingAlertCondition, { alertCondition: alertCondition[0], suggestedLevel: suggestedLevel, precision: precision })) : (_jsx(_Fragment, { children: "No set" })), _jsx("span", { className: "ms-2", children: _jsx("span", { className: "units-sub", children: units }) })] }, 'existing_levels_' +
                installationPoint.id +
                '_' +
                readingTypeId +
                '_' +
                _get(suggestedLevel, 'alert_level_id')));
        }) }));
};
export default memo(SuggestedLevelsUnitData);
var Alert = function (_a) {
    var installationPoint = _a.installationPoint, alertLevelsSettings = _a.alertLevelsSettings, analystNotesData = _a.analystNotesData, readingTypeId = _a.readingTypeId, type = _a.type, index = _a.index, username = _a.username, typeAlertLevel = _a.typeAlertLevel, value = _a.value, triggerTime = _a.triggerTime;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    if (!hasAlertConditionsAction(alertLevelsSettings, readingTypeId, type) && !analystNotesData.length) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { id: "alert-conditions-settings-label-".concat(readingTypeId, "-").concat(installationPoint.id, "-").concat(type, "-").concat(index), className: "alert-conditions-settings", color: "warning", children: _jsx(FaRegLightbulb, { size: 15 }) }), _jsxs(UncontrolledTooltip, { className: "alert-conditions-settings-label-tooltip", placement: "bottom", target: "alert-conditions-settings-label-".concat(readingTypeId, "-").concat(installationPoint.id, "-").concat(type, "-").concat(index), children: [hasAlertConditionsAction(alertLevelsSettings, readingTypeId, type) ? (_jsxs(React.Fragment, { children: [Object.values(alertLevelsSettings).length > 1 ? (_jsxs("div", { children: [_get(READING_TYPES, [readingTypeId, 'title'], ''), ' ', _get(READING_TYPES, [readingTypeId, 'axis'], '')] })) : (_jsx(_Fragment, {})), _jsxs("div", { children: [username || '', ": ", typeAlertLevel, " ", value || '', getUoms(readingTypeId, measure, personalSettingMeasure), ' ', triggerTime && triggerTime > 0 ? "".concat(triggerTime, " minutes") : ''] })] })) : (_jsx(_Fragment, {})), analystNotesData.map(function (comment) {
                        var _a, _b, _c;
                        return (_jsxs("div", { children: ["Has analyst notes by ", (_c = (_b = (_a = comment === null || comment === void 0 ? void 0 : comment.user) === null || _a === void 0 ? void 0 : _a.username) !== null && _b !== void 0 ? _b : comment === null || comment === void 0 ? void 0 : comment.user_name) !== null && _c !== void 0 ? _c : ''] }, comment.id));
                    })] })] }));
};
