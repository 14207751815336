var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { memo } from 'react';
export var SplineChart = function (_a) {
    var _b = _a.options, options = _b === void 0 ? {} : _b;
    var chartOptions = {
        chart: {
            type: 'spline',
        },
        tooltip: {
            pointFormat: '<b>$ {point.y}</b>',
        },
        colors: [
            '#7cb5ec',
            '#c42525',
            '#742eb0',
            '#8bbc21',
            '#77a1e5',
            '#1dc9b7',
            '#2b908f',
            '#f45b5b',
            '#91e8e1',
            '#7cb5ec',
            '#90ed7d',
            '#910000',
            '#492970',
            '#f28f43',
            '#a6c96a',
            '#f7a35c',
            '#8085e9',
            '#f15c80',
            '#e4d354',
        ],
        lang: {
            noData: 'No data to display',
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            ordinal: false,
        },
        credits: {
            enabled: false,
        },
    };
    return (_jsx(HighchartsReact, { highcharts: Highcharts, options: __assign(__assign({}, chartOptions), options) }));
};
export default memo(SplineChart);
