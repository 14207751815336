import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Input, Label } from 'reactstrap';
import { selectIsGatheringData, useActionItemViewActions, } from '../../../../../store/AIPreAi/useActionItemViewStore';
import { useConfirmModalActions } from '../../../../../store/global/useConfirmModalStore';
import DefaultTooltip from '../../../../DefaultTooltip';
import './index.scss';
var GatheringDataToggle = function () {
    var setIsGatheringData = useActionItemViewActions().setIsGatheringData;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var isGatheringData = selectIsGatheringData();
    var onChange = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to update an AI without updating the AI’s state? (This action will inform the customer that we currently working on the AI and collecting more data for respond)',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                setIsGatheringData(true);
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Label, { className: "gathering-toggle", for: "gatheringData", "data-tooltip-id": "gathering-label", children: [_jsx(Input, { checked: isGatheringData, id: "gatheringData", type: "checkbox", onChange: function () { return (!isGatheringData ? onChange() : setIsGatheringData(false)); } }), _jsx("span", { children: "GD" })] }), _jsx(DefaultTooltip, { id: "gathering-label", place: "left", children: "Gathering Data" })] }));
};
export default memo(GatheringDataToggle);
