import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
import './index.scss';
var DEFAULT_DIRECTION = 0;
var ChartsImages = function (_a) {
    var _b = _a.charts, charts = _b === void 0 ? [] : _b, localStorageKey = _a.localStorageKey;
    if (!charts.length) {
        return _jsx(_Fragment, {});
    }
    var _c = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _c.isContentVisible, storageStateMemory = _c.storageStateMemory, setIsContentVisible = _c.setIsContentVisible, onSaveAsDefaultSize = _c.onSaveAsDefaultSize;
    return (_jsxs("div", { className: "chart-images-block default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsx("div", { className: "default-ai-block-header-title", children: "Action Item's charts" }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), _jsx(_Fragment, { children: isContentVisible ? (_jsx("div", { className: "default-ai-block-content", children: charts.map(function (chart) { return (_jsx("div", { className: "mb-2 text-center", children: _jsx("img", { style: { maxWidth: '100%' }, src: chart.image, alt: chart.image }) }, "saved_chart_".concat(chart.image))); }) })) : (_jsx(_Fragment, {})) })] }));
};
export default memo(ChartsImages);
