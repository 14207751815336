import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import TotalWatcherStatistic from '../../../../shared/components/dashboard/cards/TotalWatcherStatistic';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault, } from '../../../../shared/features/watchersStatistic';
var TotalWatcherStatisticCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'TotalWatcherStatisticAmazon';
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'TotalWatcherStatistic', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(TotalWatcherStatistic, { deps: [selectedFacility, refreshFlag], tableName: tableName, tableColumns: [], endpoint: function () {
                    return Endpoints.amazon.getDashboardCountAiStats({
                        query: {
                            duplicated: true,
                            'separate-by-team': 1,
                            date_from: getFormattedDateFromOrDefault(null, 'YYYY-MM-DD 00:00:00'),
                            date_to: getFormattedDateToOrDefault(null, 'YYYY-MM-DD 00:00:00'),
                        },
                    });
                } })] }));
};
export default TotalWatcherStatisticCardWrapper;
