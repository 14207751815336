var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var onUserClick = function (activeUsers, setActiveUsers, userName) {
    if (activeUsers.includes(userName)) {
        setActiveUsers(activeUsers.filter(function (item) { return item !== userName; }));
        return;
    }
    setActiveUsers(__spreadArray(__spreadArray([], activeUsers, true), [userName], false));
};
