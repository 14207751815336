var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { AXIS_X } from '../../../constants';
import { LossPacketsAlert } from '../../../widgets/common';
import Loader from '../../components/Loader';
import AxisSelector from '../../components/requestOnDemand/AxisChecker';
import FmaxSelect from '../../components/requestOnDemand/FmaxSelect';
import LineOfResolutionSelect from '../../components/requestOnDemand/LineOfResolutionSelect';
import ResponseBlock from '../../components/requestOnDemand/ResponseBlock';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { onSubmitModal } from '../../features/requestOnDemand';
import useToastErrorAlert from '../../hooks/useToastErrorAlert';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import OnDemandLogTable from './components/LogTable';
var RequestOnDemand = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        isSupportHfdvue: state.isSupportHfdvue,
        packetLossPercent: state.packetLossPercent,
    }); }, shallow), installationPoint = _b.installationPoint, packetLossPercent = _b.packetLossPercent, equipment = _get(installationPoint, 'equipment') || {};
    var _c = useState({}), data = _c[0], setData = _c[1];
    var activeInstallationPointId = useMemo(function () { return installationPoint.id; }, [installationPoint]);
    var _d = useState(false), refreshFlag = _d[0], setRefreshFlag = _d[1];
    var _e = useState(false), loader = _e[0], setModalLoader = _e[1];
    var _f = useState([]), response = _f[0], setResponse = _f[1];
    var _g = useState(false), isVisibleResponseBlock = _g[0], setIsVisibleResponseBlock = _g[1];
    var onSelectOnDemandForPoint = function (installationPointId, onDemandTypeData, isHFDvue) {
        var _a;
        setData(__assign(__assign({}, data), (_a = {}, _a[installationPointId] = __assign(__assign({}, (data[installationPointId] || {})), buildDefaultDataForPoint(installationPointId, onDemandTypeData, isHFDvue)), _a)));
    };
    var buildDefaultDataForPoint = function (installationPointId, onDemandTypeData, isHFDvue) {
        var _a, _b, _c, _d;
        var fftDefaultData = {};
        if ((onDemandTypeData.is_fft === 1 && +((_a = data[installationPointId]) === null || _a === void 0 ? void 0 : _a.is_fft) === 0) ||
            +isHFDvue !== ((_b = data[installationPointId]) === null || _b === void 0 ? void 0 : _b.isHFDvue)) {
            var installationPoint_1 = equipment.installationPoints.find(function (installationPoint) { return installationPoint.id === installationPointId; });
            var defaultFmax = _get((_c = installationPoint_1 === null || installationPoint_1 === void 0 ? void 0 : installationPoint_1.settings) === null || _c === void 0 ? void 0 : _c.fft_settings, [AXIS_X, isHFDvue ? 'hfdvue_fmax' : 'fmax']) ||
                _get(installationPoint_1.sensorSpecs, isHFDvue ? 'default_impactvue_fmax' : 'defaultFMax');
            var defaultLor = _get((_d = installationPoint_1 === null || installationPoint_1 === void 0 ? void 0 : installationPoint_1.settings) === null || _d === void 0 ? void 0 : _d.fft_settings, [
                AXIS_X,
                isHFDvue ? 'hfdvue_lines_of_resolution' : 'lines_of_resolution',
            ]) || _get(installationPoint_1.sensorSpecs, isHFDvue ? 'default_impactvue_lor' : 'defaultLorOnDemand');
            fftDefaultData = {
                id: installationPointId,
                is_fft: onDemandTypeData.is_fft,
                f_max: defaultFmax,
                withAxis: isHFDvue ? 0 : 1,
                axisIds: [AXIS_X],
                isHFDvue: +isHFDvue,
                lines_of_resolution: defaultLor,
            };
        }
        return __assign({ is_fft: +(onDemandTypeData === null || onDemandTypeData === void 0 ? void 0 : onDemandTypeData.is_fft), is_rms: +(onDemandTypeData === null || onDemandTypeData === void 0 ? void 0 : onDemandTypeData.is_rms) }, fftDefaultData);
    };
    var onAxisChange = function (installationPointId, axisId) {
        var _a, _b;
        var _c, _d, _e;
        if ((_c = data[installationPointId]) === null || _c === void 0 ? void 0 : _c.axisIds.includes(axisId)) {
            setData(__assign(__assign({}, data), (_a = {}, _a[installationPointId] = __assign(__assign({}, (data[installationPointId] || {})), { axisIds: (_d = data[installationPointId]) === null || _d === void 0 ? void 0 : _d.axisIds.filter(function (axisIdItem) { return axisIdItem !== axisId; }) }), _a)));
            return;
        }
        setData(__assign(__assign({}, data), (_b = {}, _b[installationPointId] = __assign(__assign({}, (data[installationPointId] || {})), { axisIds: __spreadArray(__spreadArray([], (((_e = data[installationPointId]) === null || _e === void 0 ? void 0 : _e.axisIds) || []), true), [axisId], false) }), _b)));
    };
    var collectData = function () {
        var payload = {};
        setModalLoader(true);
        setResponse([]);
        setIsVisibleResponseBlock(false);
        Object.values(data).forEach(function (item) {
            if (item.visible && (item.is_rms === 1 || item.is_fft === 1)) {
                payload[item.id] = item;
            }
        });
        if (Object.values(payload).length === 0) {
            useToastErrorAlert([
                {
                    message: 'Please select the desired installation points and readings for the on-demand reading (RMS or FFT).',
                },
            ]);
            return;
        }
        var item = payload[activeInstallationPointId] || Object.values(payload)[0];
        onSubmitModal(payload, function (sensorId) {
            return equipment.installationPoints.find(function (installationPoint) { return installationPoint.sensor_id === sensorId; });
        }, selectedSystemType === 'remastered' ? { customer_id: installationPoint.customer_id } : {}, showResponseBlock, selectedSystemType, +item.isHFDvue);
    };
    var showResponseBlock = function (response) {
        setModalLoader(false);
        setRefreshFlag(!refreshFlag);
        setIsVisibleResponseBlock(true);
        setData({});
        setResponse(response);
    };
    var onClose = function () {
        setData({});
        setResponse([]);
        setIsVisibleResponseBlock(false);
        onCancel();
    };
    var checkedInstallationPoints = useMemo(function () {
        var ids = Object.values(data)
            .map(function (item, index) {
            if (!item.visible) {
                return;
            }
            return +Object.keys(data)[index];
        })
            .filter(function (item) { return item; });
        return ids.length > 0
            ? ids
            : ((equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) || []).map(function (installationPoint) { return installationPoint.id; });
    }, [Object.keys(data), equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints]);
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onClose, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onClose, children: "Request On-Demand" }), _jsx(ModalBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsxs(_Fragment, { children: [_jsx(LossPacketsAlert, { lossPacketsPercent: +packetLossPercent }), isVisibleResponseBlock && _jsx(ResponseBlock, { response: response }), ((equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) || []).map(function (installationPoint) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                            return (_jsxs(Row, { className: "p-2 border-bottom", children: [_jsx(Col, { md: 3, children: _jsxs(FormGroup, { inline: true, children: [_jsxs(Label, { check: true, id: "on-demand-point-setting-".concat(installationPoint.id), children: [_jsx(Input, { disabled: Object.values((installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorSpecs) || {}).length === 0, type: "checkbox", checked: (_a = data[installationPoint.id]) === null || _a === void 0 ? void 0 : _a.visible, onChange: function () {
                                                                var _a;
                                                                var _b;
                                                                return setData(__assign(__assign({}, data), (_a = {}, _a[installationPoint.id] = __assign(__assign({}, (data[installationPoint.id] || {})), { is_fft: 0, is_rms: 0, visible: !((_b = data[installationPoint.id]) === null || _b === void 0 ? void 0 : _b.visible) }), _a)));
                                                            } }), installationPoint.name, installationPoint.isSupportHfdvue && ((_b = data[installationPoint.id]) === null || _b === void 0 ? void 0 : _b.visible) ? (_jsx(FormGroup, { inline: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: !!((_c = data[installationPoint.id]) === null || _c === void 0 ? void 0 : _c.isHFDvue), onChange: function () {
                                                                            var _a, _b, _c;
                                                                            return onSelectOnDemandForPoint(installationPoint.id, {
                                                                                is_fft: ((_a = data[installationPoint.id]) === null || _a === void 0 ? void 0 : _a.is_fft) || 0,
                                                                                is_rms: ((_b = data[installationPoint.id]) === null || _b === void 0 ? void 0 : _b.is_rms) || 0,
                                                                            }, !((_c = data[installationPoint.id]) === null || _c === void 0 ? void 0 : _c.isHFDvue));
                                                                        } }), "Impact Vue"] }) })) : (_jsx(_Fragment, {}))] }), Object.values((installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorSpecs) || {}).length === 0 ? (_jsx(UncontrolledTooltip, { target: "on-demand-point-setting-".concat(installationPoint.id), children: "There are no sensors settings for this point" })) : (_jsx(_Fragment, {}))] }) }), ((_d = data[installationPoint.id]) === null || _d === void 0 ? void 0 : _d.visible) ? (_jsxs(Col, { className: "d-flex justify-content-around", md: 3, children: [_jsx(FormGroup, { inline: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", onChange: function () {
                                                                var _a, _b, _c;
                                                                return onSelectOnDemandForPoint(installationPoint.id, {
                                                                    is_rms: +((_a = data[installationPoint.id]) === null || _a === void 0 ? void 0 : _a.is_rms) === 1 ? 0 : 1,
                                                                    is_fft: ((_b = data[installationPoint.id]) === null || _b === void 0 ? void 0 : _b.is_fft) || 0,
                                                                }, !!((_c = data[installationPoint.id]) === null || _c === void 0 ? void 0 : _c.isHFDvue));
                                                            } }), "RMS"] }) }), _jsx(FormGroup, { inline: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", onChange: function () {
                                                                var _a, _b, _c;
                                                                return onSelectOnDemandForPoint(installationPoint.id, {
                                                                    is_fft: +((_a = data[installationPoint.id]) === null || _a === void 0 ? void 0 : _a.is_fft) === 1 ? 0 : 1,
                                                                    is_rms: ((_b = data[installationPoint.id]) === null || _b === void 0 ? void 0 : _b.is_rms) || 0,
                                                                }, !!((_c = data[installationPoint.id]) === null || _c === void 0 ? void 0 : _c.isHFDvue));
                                                            } }), "FFT"] }) })] })) : (_jsx(_Fragment, {})), ((_e = data[installationPoint.id]) === null || _e === void 0 ? void 0 : _e.is_fft) === 1 ? (_jsx(Col, { md: 6, children: _jsxs(Form, { children: [!((_f = data[installationPoint.id]) === null || _f === void 0 ? void 0 : _f.isHFDvue) ? (_jsx(AxisSelector, { modelSpec: (_g = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.settings) === null || _g === void 0 ? void 0 : _g.fft_settings, onCheckBoxChange: function (axisId) { return onAxisChange(installationPoint.id, axisId); }, axisIds: (_h = data[installationPoint.id]) === null || _h === void 0 ? void 0 : _h.axisIds })) : (_jsx(_Fragment, {})), _jsx(FmaxSelect, { value: (_j = data[installationPoint.id]) === null || _j === void 0 ? void 0 : _j.f_max, setFmax: function (f_max) {
                                                        var _a;
                                                        return setData(__assign(__assign({}, data), (_a = {}, _a[installationPoint.id] = __assign(__assign({}, (data[installationPoint.id] || {})), { f_max: f_max }), _a)));
                                                    }, loader: loader, fmaxValues: ((_k = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorSpecs) === null || _k === void 0 ? void 0 : _k.fmax) || [], isHFDvue: !!((_l = data[installationPoint.id]) === null || _l === void 0 ? void 0 : _l.isHFDvue), isDisabled: (((_m = data[installationPoint.id]) === null || _m === void 0 ? void 0 : _m.axisIds) || []).length === 0, sensorParameters: installationPoint.sensorSpecs }), _jsx(LineOfResolutionSelect, { values: ((_o = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorSpecs) === null || _o === void 0 ? void 0 : _o.lines_of_resolution_on_demand) || [], setLor: function (lines_of_resolution) {
                                                        var _a;
                                                        return setData(__assign(__assign({}, data), (_a = {}, _a[installationPoint.id] = __assign(__assign({}, (data[installationPoint.id] || {})), { lines_of_resolution: lines_of_resolution }), _a)));
                                                    }, isDisabled: (((_p = data[installationPoint.id]) === null || _p === void 0 ? void 0 : _p.axisIds) || []).length === 0, lor: (_q = data[installationPoint.id]) === null || _q === void 0 ? void 0 : _q.lines_of_resolution })] }) })) : (_jsx(_Fragment, {}))] }, "on-demand-point-setting-".concat(installationPoint.id)));
                        }), _jsx(Row, { className: "mt-2", children: _jsx(Col, { children: _jsx(OnDemandLogTable, { refreshFlag: refreshFlag, installationPointIds: checkedInstallationPoints }) }) })] })) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onClose, children: "Close" }), _jsx(Button, { size: "sm", color: "primary", onClick: collectData, disabled: Object.values(data).length === 0 ||
                                Object.values(data).every(function (item) { return (item === null || item === void 0 ? void 0 : item.is_rms) === 0 && (item === null || item === void 0 ? void 0 : item.is_fft) === 0; }) ||
                                Object.values(data).every(function (item) { return ((item === null || item === void 0 ? void 0 : item.axisIds) || []).length === 0; }), children: "Send Request On-Demand" })] }) })] }));
};
export default RequestOnDemand;
