// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-layout .assign-photo-main-wrapper .yarl__container {
  background-color: #1e222d !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/equipmentPhotos/ImageDropLayout/index.scss"],"names":[],"mappings":"AAEI;EACE,oCAAA;AADN","sourcesContent":[".assign-photo-main-wrapper {\n  & .yarl__container {\n    .dark-layout & {\n      background-color: #1e222d !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
