import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../constants';
export var isJsonString = function (str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
export var clearUnusedLocalStore = function () {
    Object.keys(localStorage).forEach(function (name) {
        if (name.includes('/')) {
            localStorage.removeItem(name);
        }
    });
};
export var clearAllTooltips = function () {
    Array.from(document.getElementsByClassName('bs-tooltip-auto')).forEach(function (el) {
        return el ? el.remove() : {};
    });
};
export var getReverseTheme = function (theme) {
    return theme === 'light' ? 'dark' : theme === 'dark' ? 'light' : void 0;
};
export var getIsBlockedActionOnAIPreAIPage = function (pathname) {
    return pathname.includes('/pre-action-list/') || pathname.includes('/action-item/');
};
export var getCoreUrl = function (selectedSystemType) {
    return selectedSystemType === SYSTEM_TYPE_REMASTERED
        ? 'all-analytics'
        : selectedSystemType === SYSTEM_TYPE_AMAZON
            ? 'amazon-analytic'
            : '';
};
