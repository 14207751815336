var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import React, { memo, useContext, useEffect, useState, } from 'react';
import { FaCheckCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { ACTION_LIST } from '../../../constants';
import { DateTimeLabel } from '../../../widgets/common';
import DataTable from '../../components/shared/Table/Table';
import { SystemTypeContext } from '../../context/SystemTypeContext';
var fetchLog = function (selectedSystemType, facilityId, setLoaderLog, setLog) {
    setLoaderLog(true);
    Endpoints[selectedSystemType]
        .getSlaSettingsLog({
        query: {
            facility_id: facilityId,
        },
    })
        .then(function (resp) {
        setLoaderLog(false);
        if (resp) {
            setLog(resp.data);
        }
    })
        .catch(function () {
        setLoaderLog(false);
        toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
var UpdateSlaSettingsModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, customer = _a.customer, facility = _a.facility, slaSettings = _a.slaSettings, actionListStatusNames = _a.actionListStatusNames, callback = _a.callback;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState({}), data = _b[0], setData = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var _d = useState([]), log = _d[0], setLog = _d[1];
    var _e = useState(false), loaderLog = _e[0], setLoaderLog = _e[1];
    useEffect(function () {
        setData(_cloneDeep(slaSettings));
    }, [slaSettings]);
    useEffect(function () {
        if (visible && facility.facility_id) {
            fetchLog(selectedSystemType, facility.facility_id, setLoaderLog, setLog);
        }
    }, [visible]);
    if (!visible) {
        return _jsx(_Fragment, {});
    }
    var states = [
        ACTION_LIST.CUSTOM_SLA_WAITES_NEW_AI,
        ACTION_LIST.STATE_NEW,
        ACTION_LIST.STATE_WAITING,
        ACTION_LIST.STATE_AWAITING_UPDATE,
        ACTION_LIST.STATE_NEEDS_RESPONSE,
        ACTION_LIST.STATE_VERIFYING,
        ACTION_LIST.CUSTOM_SLA_STATE_SEVERITY_AI,
        ACTION_LIST.CUSTOM_SLA_STATE_TROUBLESHOOT_WAITING_AI,
        ACTION_LIST.CUSTOM_SLA_STATE_PRE_AI,
    ];
    var onChange = function (state, urgency, sla, priority) {
        var _a, _b;
        setData(__assign(__assign({}, data), (_a = {}, _a[state] = __assign(__assign({}, _get(data, state, {})), (_b = {}, _b[urgency] = __assign(__assign({}, _get(data, [state, urgency], {})), { sla: sla < 0 ? 0 : sla, priority: priority < 0 ? 0 : priority }), _b)), _a)));
    };
    var onSave = function () {
        setLoader(true);
        var formattedData = {};
        Object.values(data).forEach(function (urgencies, indexState) {
            var state = Object.keys(data)[indexState];
            // @ts-ignore
            formattedData[state] = {};
            Object.values(urgencies).forEach(function (item, indexUrgency) {
                var urgency = Object.keys(urgencies)[indexUrgency];
                // @ts-ignore
                formattedData[state][urgency] = { sla: item.sla, priority: item.priority };
            });
        });
        Endpoints[selectedSystemType]
            .saveSlaSettings({
            customer_id: customer.customer_id,
            facility_id: facility.facility_id,
            data: formattedData,
        })
            .then(function (resp) {
            if (resp) {
                setLoader(false);
                if (resp.success) {
                    toast.success('Successfully updated', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    fetchLog(selectedSystemType, facility.facility_id, setLoaderLog, setLog);
                    return callback();
                }
                toast.error(resp.errors, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        })
            .catch(function () {
            setLoader(false);
            toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(Modal, { size: "xxl", className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["SLA Settings ", customer.name ? "".concat(customer.name, " ") : '', " ", facility.name ? "- ".concat(facility.name) : '', loader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { children: [_jsxs(Row, { children: [_jsx(Col, { md: 2 }), _jsx(Col, { md: 3, className: "text-center", children: _jsx(Label, { children: _jsx("b", { children: "Standard" }) }) }), _jsx(Col, { md: 3, className: "text-center", children: _jsx(Label, { children: _jsx("b", { children: "High" }) }) }), _jsx(Col, { md: 3, className: "text-center", children: _jsx(Label, { children: _jsx("b", { children: "Critical" }) }) })] }), states.map(function (state) { return (_jsxs(Row, { children: [_jsx(Col, { md: 2, className: "mt-1", children: _jsxs("b", { children: [_get(actionListStatusNames, state, ''), state === ACTION_LIST.CUSTOM_SLA_WAITES_NEW_AI ? 'Waites NEW' : '', state === ACTION_LIST.CUSTOM_SLA_STATE_SEVERITY_AI ? 'Severity' : '', state === ACTION_LIST.CUSTOM_SLA_STATE_TROUBLESHOOT_WAITING_AI
                                            ? selectedSystemType === 'amazon'
                                                ? 'Troubleshooting Question'
                                                : 'Troubleshooting Waiting'
                                            : '', state === ACTION_LIST.CUSTOM_SLA_STATE_PRE_AI ? 'Pre-ai' : ''] }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { className: "text-center", children: _jsxs("div", { className: "d-flex justify-content-between", children: [_jsxs("div", { children: [_jsx(Label, { for: "standard-sla", className: "d-inline-block", children: _jsx("b", { children: "SLA" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "SLA", name: "standard-sla", id: "standard-sla", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_STANDARD, 'sla']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_STANDARD, +ev.target.value, _get(data, [state, ACTION_LIST.URGENCY_STANDARD, 'priority']));
                                                        }, bsSize: "sm" })] }), _jsxs("div", { children: [_jsx(Label, { for: "standard-priority", className: "d-inline-block", children: _jsx("b", { children: "Priority" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "Priority", name: "standard-priority", id: "standard-priority", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_STANDARD, 'priority']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_STANDARD, _get(data, [state, ACTION_LIST.URGENCY_STANDARD, 'sla']), +ev.target.value);
                                                        }, bsSize: "sm" })] })] }) }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { className: "text-center", children: _jsxs("div", { className: "d-flex justify-content-between", children: [_jsxs("div", { children: [_jsx(Label, { for: "high-sla", className: "d-inline-block", children: _jsx("b", { children: "SLA" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "SLA", name: "high-sla", id: "high-sla", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_HIGH, 'sla']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_HIGH, +ev.target.value, _get(data, [state, ACTION_LIST.URGENCY_HIGH, 'priority']));
                                                        }, bsSize: "sm" })] }), _jsxs("div", { children: [_jsx(Label, { for: "high-priority", className: "d-inline-block", children: _jsx("b", { children: "Priority" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "Priority", name: "high-priority", id: "high-priority", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_HIGH, 'priority']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_HIGH, _get(data, [state, ACTION_LIST.URGENCY_HIGH, 'sla']), +ev.target.value);
                                                        }, bsSize: "sm" })] })] }) }) }), _jsx(Col, { md: 3, children: _jsx(FormGroup, { className: "text-center", children: _jsxs("div", { className: "d-flex justify-content-between", children: [_jsxs("div", { children: [_jsx(Label, { for: "critical-sla", className: "d-inline-block", children: _jsx("b", { children: "SLA" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "SLA", name: "critical-sla", id: "critical-sla", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_CRITICAL, 'sla']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_CRITICAL, +ev.target.value, _get(data, [state, ACTION_LIST.URGENCY_CRITICAL, 'priority']));
                                                        }, bsSize: "sm" })] }), _jsxs("div", { children: [_jsx(Label, { for: "critical-priority", className: "d-inline-block", children: _jsx("b", { children: "Priority" }) }), _jsx(Input, { type: "number", min: 0, placeholder: "Priority", name: "critical-priority", id: "critical-priority", className: "d-inline-block w-50 ms-2", value: _get(data, [state, ACTION_LIST.URGENCY_CRITICAL, 'priority']), onChange: function (ev) {
                                                            return onChange(state, ACTION_LIST.URGENCY_CRITICAL, _get(data, [state, ACTION_LIST.URGENCY_CRITICAL, 'sla']), +ev.target.value);
                                                        }, bsSize: "sm" })] })] }) }) }), _jsx(Col, { md: 1 }), _jsx("hr", {})] }, "sla_settings_row_".concat(customer.customer_id, "_").concat(state))); }), log.length > 0 ? (_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("h4", { children: ["SLA Log", loaderLog && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsx(DataTable, { config: {
                                        columns: [
                                            {
                                                name: 'username',
                                                title: 'Username',
                                                sortable: false,
                                            },
                                            {
                                                name: 'val4',
                                                title: 'Changes',
                                                sortable: false,
                                                component: function (row) {
                                                    return _jsx(LogChanges, { row: row, actionListStatusNames: actionListStatusNames });
                                                },
                                            },
                                            {
                                                name: 'updated_at',
                                                title: 'Updated At',
                                                sortable: false,
                                                component: function (row) {
                                                    return (_jsx(DateTimeLabel, { id: "sla_log_updated_at".concat(row.id), dateTime: row.updated_at || row.date_create, fromNow: true }));
                                                },
                                            },
                                        ],
                                        pagination: {
                                            disabled: true,
                                        },
                                        hideTotalLabel: true,
                                        search: '',
                                        disabledSearch: true,
                                        data: log,
                                        loader: false,
                                    } })] }) })) : (_jsx(_Fragment, {}))] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { disabled: loader, size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Close" }), _jsx(Button, { disabled: loader, size: "sm", color: "primary", onClick: onSave, children: "Save" })] }) })] }));
};
var LogChanges = function (_a) {
    var row = _a.row, actionListStatusNames = _a.actionListStatusNames;
    var data = JSON.parse(row.val4);
    return (_jsx(_Fragment, { children: Object.values(data).map(function (urgencies, index) { return (_jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs("b", { children: [_get(actionListStatusNames, +Object.keys(data)[index], ''), +Object.keys(data)[index] === ACTION_LIST.CUSTOM_SLA_WAITES_NEW_AI ? 'Waites NEW' : '', +Object.keys(data)[index] === ACTION_LIST.CUSTOM_SLA_STATE_SEVERITY_AI ? 'Severity' : '', +Object.keys(data)[index] === ACTION_LIST.CUSTOM_SLA_STATE_TROUBLESHOOT_WAITING_AI
                                ? 'Troubleshooting Waiting'
                                : '', +Object.keys(data)[index] === ACTION_LIST.CUSTOM_SLA_STATE_PRE_AI ? 'Pre-ai' : ''] }) }), _jsx(Col, { children: Object.values(urgencies).map(function (urgency, urgencyIndex) { return (_jsxs("div", { className: "d-inline-block me-2", children: [_jsx("b", { children: ACTION_LIST.URGENCY_NAMES[+Object.keys(urgencies)[urgencyIndex]] }), urgency.sla ? " SLA - ".concat(urgency.sla) : '', urgency.priority ? " Priority - ".concat(urgency.priority) : ''] }, "sla_log_".concat(row.id, "_").concat(index, "_").concat(urgencyIndex))); }) })] }, "sla_log_".concat(row.id, "_").concat(index))); }) }));
};
export default memo(UpdateSlaSettingsModal);
