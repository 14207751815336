import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { PiWarningDiamondFill } from 'react-icons/pi';
import { selectMeasure } from '../../../store/global/useGlobalStore';
var CommentStatusBar = function (_a) {
    var facilityMeasure = _a.facilityMeasure;
    var measure = selectMeasure();
    if (!facilityMeasure || measure === facilityMeasure) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "comment-status-bar", children: [_jsx(PiWarningDiamondFill, {}), " ", _jsx("small", { children: "Your measurement settings are different from the facility" })] }));
};
export default memo(CommentStatusBar);
