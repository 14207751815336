import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import DefaultTooltip from '../../DefaultTooltip';
var RefreshBtn = function (_a) {
    var onInvalidateData = _a.onInvalidateData;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: true, color: "primary", "data-tooltip-id": "refresh-ai-btn", className: "top-actions-stroke", onClick: function () {
                    toast.success('Successfully refresh', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    onInvalidateData();
                }, children: _jsx(FiRefreshCcw, {}) }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "refresh-ai-btn", children: "Refresh Data" })] }));
};
export default memo(RefreshBtn);
