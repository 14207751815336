var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import User from '../../../shared/helper/user';
export var getNewUpdatedData = function (data, visibleEquipmentId) {
    if (!data || !data.length) {
        return [];
    }
    return data.map(function (el) {
        return __assign(__assign({}, el), { is_visible: (visibleEquipmentId && +visibleEquipmentId === +el.id) || false, certain: {
                temp: {
                    type: 'temp',
                    is_visible: false,
                    installPointIds: [],
                },
                acc: {
                    type: 'acc',
                    is_visible: false,
                    installPointIds: [],
                },
                vel: {
                    type: 'vel',
                    is_visible: false,
                    installPointIds: [],
                },
                pkpk: {
                    type: 'pkpk',
                    is_visible: false,
                    installPointIds: [],
                },
                range: {
                    type: 'range',
                    is_visible: false,
                    installPointIds: [],
                },
            }, entity_id: el.customer_id, name: el.customer_name, equipment_name: el.name });
    });
};
export var getSuggestedAlertConditionHandler = function (resp, dispatchSuggestedLevelModal) {
    if (!resp) {
        return;
    }
    var userComments = {};
    resp.comments.map(function (comment) {
        if (+comment.user_id === +User.getId()) {
            userComments[comment.reading_type_id] = comment;
        }
    });
    dispatchSuggestedLevelModal({
        type: 'setState',
        state: {
            comments: resp.comments,
            customersData: resp.customersData,
            userComments: userComments,
        },
    });
};
