import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import AnalystNotesDataTable from '../../../../components/analystNotes/AnalystNotesDataTable';
import { useAnalystNotesDeleteMutation } from '../../../../services/analystNotes/useAnalystNotesDeleteMutation';
import { useAnalystNotesDataTableActions } from '../../../../store/analystNotes/useAnalystNotesDataTableStore';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import SelectInstallationPointOnEquipment from '../../components/SelectInstallationPointOnEquipment';
var AnalystNotesTab = function (_a) {
    var _b, _c;
    var options = _a.options, additionalNoteParam = _a.additionalNoteParam;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var pointsOnEquipment = (_c = (_b = installationPoint.equipment) === null || _b === void 0 ? void 0 : _b.installationPoints) !== null && _c !== void 0 ? _c : [];
    var setSelectedInstallationPointId = useAnalystNotesDataTableActions().setSelectedInstallationPointId;
    var _d = useAnalystNotesDeleteMutation(), deleteMutate = _d.mutate, deleteIsLoading = _d.isPending;
    return (_jsxs("div", { className: "mt-3", children: [_jsx(Row, { className: "my-3", children: _jsx(Col, { md: 4, children: _jsx(SelectInstallationPointOnEquipment, { installationPoint: installationPoint, pointsOnEquipment: pointsOnEquipment, setSelectedInstallationPointId: setSelectedInstallationPointId }) }) }), _jsx(AnalystNotesDataTable, { additionalNoteParam: additionalNoteParam, options: options, deleteMutate: deleteMutate, deleteIsLoading: deleteIsLoading, isStaticTable: true })] }));
};
export default memo(AnalystNotesTab);
