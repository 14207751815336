import Plotly from 'plotly.js/lib/core';
import Scatter3d from 'plotly.js/lib/scatter3d';
import Surface from 'plotly.js/lib/surface';
import createPlotlyComponent from 'react-plotly.js/factory';
Plotly.register([
    Scatter3d,
    Surface,
    {
        moduleType: 'locale',
        name: 'en-US',
        dictionary: {
            'Toggle show closest data on hover': 'Toggle display of closest data on hover',
        },
    },
]);
var Plot = createPlotlyComponent(Plotly);
export default Plot;
