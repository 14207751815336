import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import ActiveActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/ActiveActionItemsOnEquipment';
import PreviousActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/PreviousActionItemsOnEquipment';
import SectionPosition from '../../../../shared/components/AIPreAi/SectionPosition';
import SensorInfoBlock, { DEFAULT_DIRECTION, } from '../../../../shared/components/AIPreAi/SensorInfoBlock/SensorInfoBlock';
import Description from '../../../../shared/components/preActionItem/Description';
import { useWidthContent } from '../../../../shared/hooks/AIPreAi/useWidthContent';
import PreviousAiReportModal from '../../../../shared/modals/actionItem/PreviousAiReport/PreviousAiReport';
import { useApprovePreActionItemMutation } from '../../../../shared/services/preActionItems/useApprovePreActionItemMutation';
import { useCreateMutation } from '../../../../shared/services/preActionItems/useCreateMutation';
import { useDeclinePreActionItemMutation } from '../../../../shared/services/preActionItems/useDeclinePreActionItemMutation';
import { useEditMutation } from '../../../../shared/services/preActionItems/useEditMutation';
import { useReplaceMoteBatteryMutation } from '../../../../shared/services/preActionItems/useReplaceMoteBatteryMutation';
import { useReplaceNodeBatteriesMutation } from '../../../../shared/services/preActionItems/useReplaceNodeBatteriesMutation';
import { useReplaceSensorMoteMutation } from '../../../../shared/services/preActionItems/useReplaceSensorMoteMutation';
import { useReplaceSensorMutation } from '../../../../shared/services/preActionItems/useReplaceSensorMutation';
import { useActionItemModalsActions } from '../../../../shared/store/AIPreAi/useActionItemModalsStore';
import { selectIsEditSections, selectSectionPosition, usePreActionItemViewActions, } from '../../../../shared/store/preActionItem/usePreActionItemViewStore';
import { sensorInfoConfig } from '../../../config/preActionItems/sensorInfo';
import { INACTIVE_STATES } from '../../../services/actionItems/useActionItemQuery';
import { usePreActionItemQuery } from '../../../services/preActionItems/usePreActionItemQuery';
import StatusLabelAmazon from '../../AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../AIPreAi/UrgencyLabel';
var Content = function (_a) {
    var defaultDeclineReasons = _a.defaultDeclineReasons;
    var preActionItemId = useParams().id;
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    var preActionItemData = usePreActionItemQuery({
        preActionItemId: preActionItemId,
    }).data;
    var _b = useEditMutation({
        preActionItemId: preActionItemId,
        storage: null,
    }), editMutate = _b.mutate, editIsLoading = _b.isPending;
    var _c = useCreateMutation({
        preActionItemId: preActionItemId,
        storage: null,
    }), createMutate = _c.mutate, createIsLoading = _c.isPending;
    var _d = useApprovePreActionItemMutation({
        preActionItemId: preActionItemId,
        storage: null,
    }), approveMutate = _d.mutate, approveIsLoading = _d.isPending;
    var _e = useReplaceMoteBatteryMutation({ preActionItemId: preActionItemId, storage: null }), replaceMoteBatteryMutate = _e.mutate, replaceMoteBatteryIsLoading = _e.isPending;
    var _f = useReplaceNodeBatteriesMutation({ preActionItemId: preActionItemId, storage: null }), replaceNodeBatteriesMutate = _f.mutate, replaceNodeBatteriesIsLoading = _f.isPending;
    var _g = useReplaceSensorMoteMutation({ preActionItemId: preActionItemId, storage: null }), replaceSensorMoteMutate = _g.mutate, replaceSensorMoteIsLoading = _g.isPending;
    var _h = useReplaceSensorMutation({
        preActionItemId: preActionItemId,
        storage: null,
    }), replaceSensorMutate = _h.mutate, replaceSensorIsLoading = _h.isPending;
    var declinePreActionItemMutate = useDeclinePreActionItemMutation({
        preActionItemId: preActionItemId,
        storage: null,
    }).mutate;
    var existingActionItems = preActionItemData.existingActionItems, description = preActionItemData.description, id = preActionItemData.id, title = preActionItemData.title, installationPoint = preActionItemData.installationPoint, urgency = preActionItemData.urgency, isShowEdit = preActionItemData.isShowEdit, photos = preActionItemData.photos, isReplaceEquipment = preActionItemData.isReplaceEquipment, replacementType = preActionItemData.replacementType, installationPointId = preActionItemData.installationPointId, equipmentType = preActionItemData.equipmentType, serial = preActionItemData.serial, activeActionList = preActionItemData.activeActionList, declineReason = preActionItemData.declineReason, isNewPreAi = preActionItemData.isNewPreAi, preActionListTags = preActionItemData.preActionListTags, tags = preActionItemData.tags;
    var setIdPreviousAiReportForModal = useActionItemModalsActions().setIdPreviousAiReportForModal;
    var setSectionPosition = usePreActionItemViewActions().setSectionPosition;
    var isEditSections = selectIsEditSections();
    var sectionPosition = selectSectionPosition();
    var localStorageKey = 'preAiSensorInfoBlockSaveAsDefault';
    var storageState = localStorage.getItem(localStorageKey)
        ? Number(localStorage.getItem(localStorageKey))
        : DEFAULT_DIRECTION;
    var _j = useState(storageState), isContentVisible = _j[0], setIsContentVisible = _j[1];
    var config = useMemo(function () {
        return sensorInfoConfig(highlightSearch || '', preActionItemData, isNewPreAi ? [] : ['sla'], isContentVisible);
    }, [preActionItemData, isContentVisible]);
    var width = useWidthContent();
    var sections = {
        'Technical info': (_jsx(SensorInfoBlock, { blocks: config, photos: photos, isContentVisible: isContentVisible, setIsContentVisible: setIsContentVisible, localStorageKey: localStorageKey })),
        'Action Items Already Exists On This Equipment': (_jsx(ActiveActionItemsOnEquipment, { localStorageKey: "preAiOnEquipmentSaveAsDefault", existingActionItems: existingActionItems, archiveStates: INACTIVE_STATES, statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelAmazon, { commentsCount: 0, status: +existingActionItem.state, isTroubleshootingHoldWaiting: +existingActionItem.is_troubleshooting_hold }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency, type: existingActionItem.type }) })] })); } })),
        'Pre-Action Item description': (_jsx(Description, { description: description, isShowEdit: isShowEdit, id: id, title: title, installationPoint: installationPoint, urgency: urgency, isReplaceEquipment: isReplaceEquipment, replacementType: replacementType, defaultDeclineReasons: defaultDeclineReasons, editIsLoading: editIsLoading, createIsLoading: createIsLoading, editMutate: editMutate, createMutate: createMutate, approveMutate: approveMutate, approveIsLoading: approveIsLoading, replaceMoteBatteryMutate: replaceMoteBatteryMutate, replaceMoteBatteryIsLoading: replaceMoteBatteryIsLoading, replaceNodeBatteriesMutate: replaceNodeBatteriesMutate, replaceNodeBatteriesIsLoading: replaceNodeBatteriesIsLoading, replaceSensorMoteMutate: replaceSensorMoteMutate, replaceSensorMoteIsLoading: replaceSensorMoteIsLoading, replaceSensorMutate: replaceSensorMutate, replaceSensorIsLoading: replaceSensorIsLoading, localStorageKey: "preAiDescriptionSaveAsDefault", installationPointId: installationPointId, equipmentType: equipmentType, serial: serial, activeActionList: activeActionList, declineReason: declineReason, preActionListTags: preActionListTags, tags: tags, existingActionItems: existingActionItems, declinePreActionItemMutate: declinePreActionItemMutate })),
        'Previous Action Items': (_jsx(PreviousActionItemsOnEquipment, { localStorageKey: "preAiPreviousActionItemsOnEquipmentSaveAsDefault", installationPointCurrent: installationPoint, existingActionItems: existingActionItems, archiveStates: INACTIVE_STATES, onViewCommentsClickAction: function (id) { return setIdPreviousAiReportForModal(id); }, statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelAmazon, { commentsCount: 0, status: +existingActionItem.state, isTroubleshootingHoldWaiting: +existingActionItem.is_troubleshooting_hold }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency, type: existingActionItem.type }) })] })); } })),
    };
    return (_jsxs(ReactSortable, { animation: 250, ghostClass: "active", list: sectionPosition, setList: setSectionPosition, disabled: !isEditSections, style: {
            width: width,
            marginTop: '20px',
            transition: '.2s',
        }, children: [_jsx(SectionPosition, { sectionPosition: sectionPosition, isEditSections: isEditSections, sections: sections }), _jsx(PreviousAiReportModal, { existingActionItems: existingActionItems, onCancel: function () { return setIdPreviousAiReportForModal(null); } })] }));
};
export default memo(Content);
