var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticKPICSVKey = function (dateRange, username, userId, queryAdditionProperties) {
    var _a, _b;
    return [
        'statisticKPICSV',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
        username,
        userId,
        queryAdditionProperties,
    ];
};
var statisticKPICSVFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/analysts-kpi-csv"), {
        query: __assign({ date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'), date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 23:23:59'), selected_user: {
                user_name: queryKey[3],
                id: queryKey[4],
            } }, queryKey[5]),
        isBlob: true,
    });
};
export var useStatisticKPICSVQuery = function (_a) {
    var username = _a.username, userId = _a.userId, queryAdditionProperties = _a.queryAdditionProperties;
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticKPICSVKey(dateRange, username, userId, queryAdditionProperties),
        queryFn: function (data) { return statisticKPICSVFn(data, getCoreUrl(selectedSystemType)); },
        select: function (resp) {
            return resp;
        },
    });
};
