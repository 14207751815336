var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { ZOOM_OPTIONS } from '../../../../../../constants';
import { clearAllTooltips } from '../../../../../helper/commonHelper';
import { useChartSettingsSaveMutation, } from '../../../../../services/charts/mutations/useChartSettingsSaveMutation';
import { selectChartDefaultZoomDays, selectChartSorting, useChartSettingsActions, } from '../../../../../store/charts/useChartSettingsStore';
var ChartDefaultZoomSelect = function () {
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '80px', fontSize: '14px' })); },
    }); }, []);
    var chartDefaultZoomDays = selectChartDefaultZoomDays();
    var chartSorting = selectChartSorting();
    var setChartDefaultZoomDays = useChartSettingsActions().setChartDefaultZoomDays;
    var mutate = useChartSettingsSaveMutation().mutate;
    var onChangeDefaultChartScale = function (value, _a) {
        var intervalLabel = _a.intervalLabel;
        clearAllTooltips();
        mutate({
            chart_sorting: JSON.stringify(chartSorting),
            chart_default_zoom_days: value,
        }, {
            onSuccess: function () {
                toast.success("Default time scale preference has been set ".concat(intervalLabel), {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                setChartDefaultZoomDays(+value);
            },
        });
    };
    var calcDefaultValueForSelectDefaultZoom = useCallback(function () {
        if (!chartDefaultZoomDays) {
            return ZOOM_OPTIONS[3];
        }
        if (!ZOOM_OPTIONS.filter(function (el) { return +el.value === chartDefaultZoomDays && el; })) {
            return ZOOM_OPTIONS[3];
        }
        return ZOOM_OPTIONS.filter(function (el) { return +el.value === chartDefaultZoomDays && el; });
    }, [chartDefaultZoomDays]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "select-custom-default", id: "chart-default-zoom", styles: customStyles, isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: ZOOM_OPTIONS, onChange: function (ev) {
                    return onChangeDefaultChartScale(ev.value, { intervalLabel: ev.label });
                }, defaultValue: calcDefaultValueForSelectDefaultZoom() }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "chart-default-zoom", children: "Default zoom" })] }));
};
export default memo(ChartDefaultZoomSelect);
