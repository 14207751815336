// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-conditions-settings-label {
  position: absolute;
  top: 45px;
  right: calc(100% - 80px);
  z-index: 1;
  padding: 5px 12px !important;
  min-width: auto !important;
  cursor: pointer;
}
.alert-conditions-settings-label.label-circle {
  border-radius: 15px;
  padding: 7px 8px !important;
}

.alert-conditions-settings-label-tooltip .tooltip-inner {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/widgets/alertConditionsSettingsLabel/AlertConditionsSettingsLabel.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,wBAAA;EACA,UAAA;EACA,4BAAA;EACA,0BAAA;EACA,eAAA;AACF;AAAE;EACE,mBAAA;EACA,2BAAA;AAEJ;;AAEA;EACE,eAAA;AACF","sourcesContent":[".alert-conditions-settings-label {\n  position: absolute;\n  top: 45px;\n  right: calc(100% - 80px);\n  z-index: 1;\n  padding: 5px 12px !important;\n  min-width: auto !important;\n  cursor: pointer;\n  &.label-circle {\n    border-radius: 15px;\n    padding: 7px 8px !important;\n  }\n}\n\n.alert-conditions-settings-label-tooltip .tooltip-inner {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
