import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Badge } from 'reactstrap';
import { RUNNING_SPEED_TYPE_EQUIPMENT, RUNNING_SPEED_TYPE_INSTALLATION_POINT, RUNNING_SPEED_TYPE_READING, } from '../../../constants';
import { DateInput, DateTimeLabel } from '../../../widgets/common';
import NewOldData from '../../components/runningSpeedLog/NewOldData';
export var config = {
    columns: [
        {
            name: 'type',
            title: 'Type',
            sortable: true,
            component: function (row) {
                var _a;
                var badgeColors = (_a = {},
                    _a[RUNNING_SPEED_TYPE_READING] = 'info',
                    _a[RUNNING_SPEED_TYPE_INSTALLATION_POINT] = 'primary',
                    _a[RUNNING_SPEED_TYPE_EQUIPMENT] = 'success',
                    _a);
                return _jsx(Badge, { color: _get(badgeColors, row.type), children: row.type.replace('_', ' ') });
            },
        },
        {
            name: 'installation_point_name',
            title: 'Point Name',
            sortable: true,
        },
        {
            name: 'equipment_name',
            title: 'Equipment Name',
            sortable: true,
        },
        {
            name: 'old_data',
            title: 'Old Data',
            sortable: false,
            component: function (row) {
                return _jsx(NewOldData, { id: row.id, data: JSON.parse(row.old_data) }, "old");
            },
        },
        {
            name: 'new_data',
            title: 'New Data',
            sortable: false,
            component: function (row) {
                return _jsx(NewOldData, { id: row.id, data: JSON.parse(row.new_data) }, "new");
            },
        },
        {
            name: 'created_at',
            title: 'Time',
            sortable: true,
            component: function (row) {
                return _jsx(DateTimeLabel, { id: "running_speed_log_".concat(row.id), dateTime: row.created_at });
            },
        },
    ],
    sort: [
        {
            field: 'created_at',
            direction: 'desc',
        },
    ],
    filtersData: {
        dateRange: [],
    },
    filters: function (callbacks, filtersData) {
        return (_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: callbacks.onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }));
    },
    pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
    },
    search: '',
    data: [],
    loader: true,
};
