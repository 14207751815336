import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from "highcharts";
import { find as _find, get as _get, size as _size } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { shallow } from "zustand/shallow";
import { FFT_ALERT_TYPES, SERVICE_SELF } from "../../../../../../../constants";
import { getUoms, isTwfChart } from "../../../../../../helper/chart";
import { ChartFeaturesStore } from "../../../../../../store/charts/chartsContent/ChartFeaturesStore";
import { FftChartsStore } from "../../../../../../store/charts/chartsContent/FftChartsStore";
export var getEnvelopeSeries = function (chartIdentifier, fftAlerts, readingTypes, measure, personalSettingMeasure) {
    var data = [];
    var chartAlerts = fftAlerts.filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.ENVELOPE; });
    if (chartAlerts.length === 0) {
        return [];
    }
    chartAlerts.map(function (chartAlert) {
        if (_size(chartAlert.envelopeData)) {
            data.push({
                id: 'envelope_alert_' + chartIdentifier + '_' + chartAlert.id,
                name: chartAlert.alertLevel.name,
                // @ts-ignore
                data: chartAlert.envelopeData.data,
                units: getUoms(chartIdentifier, measure, personalSettingMeasure),
                chartType: chartIdentifier,
                precision: readingTypes[chartIdentifier].precision,
                seriesType: 'envelope',
                visible: true,
                showInLegend: false,
                enableMouseTracking: false,
                color: new Highcharts.Color(chartAlert.alertLevel.color)
                    .setOpacity(chartAlert.service === SERVICE_SELF ? 0.5 : 0.8)
                    .get(),
                dashStyle: chartAlert.service === SERVICE_SELF ? 'LongDash' : 'Solid',
                zones: [],
            });
        }
    });
    return data;
};
var onFftEnvelopeAlertLabelClick = function (ev, setStatesChartFeaturesStore, fftAlerts, chartIdentifier) {
    // @ts-ignore
    if (!ev.target.matches('.js-alert-envelope-badge')) {
        return;
    }
    // @ts-ignore
    var id = +ev.target.id.split('envelope-alert-badge-')[1];
    var envelope = null;
    Object.values(fftAlerts).map(function (fftAlertsItems) {
        envelope = envelope || _find(fftAlertsItems, { id: id });
    });
    if (!envelope) {
        return;
    }
    setStatesChartFeaturesStore({
        envelopeAlarmsFormModal: {
            visible: true,
            envelope: envelope,
            chartIdentifier: chartIdentifier,
        },
    });
};
export var getEnvelopeAnnotations = function (chartIdentifier, fftAlerts, setStatesChartFeaturesStore) {
    var chartAlerts = fftAlerts.filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.ENVELOPE; });
    if (chartAlerts.length === 0) {
        return [];
    }
    var labels = [], shapes = [];
    chartAlerts.map(function (chartAlert) {
        if (_size(chartAlert.envelopeData) && chartAlert.service !== SERVICE_SELF) {
            var dataMax = chartAlert.envelopeData.data.reduce(function (result, current) { return (current[1] > result[1] ? current : result); }, [0, 0]);
            shapes.push({
                type: 'path',
                dashStyle: 'longdashdot',
                stroke: new Highcharts.Color(chartAlert.alertLevel.color).setOpacity(0.6).get(),
                alertGroup: 'envelope',
                points: [
                    { x: dataMax[0], y: dataMax[1], xAxis: 0, yAxis: 0 },
                    { x: 0, y: dataMax[1], xAxis: 0, yAxis: 0 },
                ],
            });
            var style = "background: ".concat(chartAlert.alertLevel.color, "; height: 10px; width: 10px;"), text = "<span class=\"fa fa-square-full cursor-pointer js-alert-badge js-alert-envelope-badge\" id=\"envelope-alert-badge-".concat(chartAlert.id, "\" style=\"").concat(style, "\"></span>");
            labels.push({
                allowOverlap: true,
                backgroundColor: 'none',
                borderWidth: 0,
                distance: -10,
                padding: 0,
                useHTML: true,
                text: text,
                point: { x: 0, y: dataMax[1], xAxis: 0, yAxis: 0 },
                alertGroup: 'envelope',
                y: 0,
            });
        }
    });
    document.removeEventListener('click', function (ev) { return onFftEnvelopeAlertLabelClick(ev, setStatesChartFeaturesStore, fftAlerts, chartIdentifier); }, false);
    document.addEventListener('click', function (ev) { return onFftEnvelopeAlertLabelClick(ev, setStatesChartFeaturesStore, fftAlerts, chartIdentifier); }, false);
    return [
        {
            shapes: shapes,
            labels: labels,
            type: 'envelope',
            draggable: '',
            visible: true,
        },
    ];
};
export var EnvelopeAlarmsDropdown = function (_a) {
    var chartIdentifier = _a.chartIdentifier, dispatch = _a.dispatch, isVisibleEnvelope = _a.isVisibleEnvelope, width = _a.width, isDisabled = _a.isDisabled;
    if (isTwfChart(chartIdentifier)) {
        return _jsx(React.Fragment, {});
    }
    var onToggleIsVisibleEnvelope = function () {
        dispatch({
            type: 'setState',
            state: {
                isVisibleEnvelope: !isVisibleEnvelope,
            },
        });
    };
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var fftAlerts = FftChartsStore(function (state) { return ({
        fftAlerts: state.fftAlerts,
    }); }, shallow).fftAlerts;
    var chartAlerts = _get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.ENVELOPE; });
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var onManage = useCallback(function () {
        setStatesChartFeaturesStore({
            envelopeAlarmsFormModal: {
                visible: true,
                envelope: {},
                chartIdentifier: chartIdentifier,
            },
        });
    }, [chartIdentifier]);
    var indicatorColor = useMemo(function () {
        var color = 'green';
        chartAlerts.forEach(function (chartAlert) {
            var _a;
            if (color !== 'red' && chartAlert.hasTriggeredAlerts) {
                color = ((_a = chartAlert.alertLevel) === null || _a === void 0 ? void 0 : _a.name) === 'Caution' ? 'yellow' : 'red';
            }
        });
        return color;
    }, [chartAlerts]);
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "envelope-alarms-dropdown chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsx(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: _jsxs("span", { className: "envelope-alarms-dropdown-toggle-link", children: ["Envelope Alarms", chartAlerts.length > 0 ? (_jsx("span", { className: "indicator indicator-full-size indicator-".concat(indicatorColor) })) : (_jsx(_Fragment, {}))] }) }), _jsxs(DropdownMenu, { children: [chartAlerts.length > 0 && (_jsxs(React.Fragment, { children: [_jsx(DropdownItem, { className: "btn-sm", onClick: onToggleIsVisibleEnvelope, children: isVisibleEnvelope ? 'Hide' : 'Show' }), _jsx(DropdownItem, { divider: true })] })), _jsx(DropdownItem, { className: "btn-sm", onClick: onManage, children: "Manage Envelope Alarms..." })] })] }) }) }));
};
export var calculateEnvelope = function (readings, data, std) {
    var result = readings.map(function (val, key) {
        var x = val[0];
        var y = val[1];
        if (+data.fft_envelope_type === 1 && data.fft_envelope_percent) {
            y = y * (1 + data.fft_envelope_percent / 100);
        }
        else if (+data.fft_envelope_type === 2 && std[key]) {
            y = y + data.fft_envelope_std * std[key][1];
        }
        return [x, y];
    });
    if (+data.fft_envelope_spectral_window_width > 0) {
        var peaks = findAllPeaks(result);
        result = filterEnvelopePeaks(peaks, +data.fft_envelope_spectral_window_width || 0);
    }
    if (+data.fft_envelope_minimum_value > 0) {
        result = result.map(function (val) { return [val[0], Math.max(val[1], +data.fft_envelope_minimum_value || 0)]; });
    }
    return result;
};
var findAllPeaks = function (data) {
    var peaks = [];
    data.forEach(function (val, key) {
        if (key === 0 || key === data.length - 1) {
            return true;
        }
        var cur_y_val = val[1], prev_y_val = data[key - 1][1], next_y_val = data[key + 1][1];
        if (cur_y_val > prev_y_val && cur_y_val > next_y_val) {
            peaks.push(val);
        }
    });
    return peaks;
};
var filterEnvelopePeaks = function (peaks, hz) {
    hz = +hz;
    // sort descending by value
    var sortedArr = peaks.sort(function (a, b) { return b[1] - a[1]; }), xx = sortedArr.map(function (val) { return val[0]; });
    xx.forEach(function (x) {
        if (!x) {
            return true;
        }
        var max_range = x + hz, min_range = x - hz;
        sortedArr.forEach(function (vv, kk) {
            if (vv) {
                var cur_x_val = vv[0];
                // if value is between current range (x_val - hz to x_val + hz) - remove it.
                // also removing the value from xx array to skip it when it comes to iteration
                if (cur_x_val !== x && cur_x_val >= min_range && cur_x_val <= max_range) {
                    xx[kk] = undefined;
                    sortedArr[kk] = undefined;
                }
            }
        });
    });
    var coords = sortedArr.filter(function (val) { return !!val; }).sort(function (a, b) { return a[0] - b[0]; }), res = [];
    coords.forEach(function (v) {
        res.push([v[0] - hz / 2, v[1]]);
        res.push([v[0] + hz / 2, v[1]]);
    });
    if (res.length) {
        res[0][0] = 0;
    }
    return res;
};
