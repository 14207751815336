import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { selectAlertLevelsHotkey } from '../../../../../store/hotkeys/useChartsHotkeysStore';
import DefaultTooltip from '../../../../DefaultTooltip';
import HotkeyInfo from '../../../../HotkeyInfo';
var ChartAlertLevelsVisibleToggle = function () {
    var _a = ChartsStore(function (state) { return ({
        setStatesChartsStore: state.setStates,
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
    }); }, shallow), setStatesChartsStore = _a.setStatesChartsStore, chartAlertLevelsVisibleToggle = _a.chartAlertLevelsVisibleToggle;
    var onCursorToggleTooltip = function (chartAlertLevelsVisibleToggle) {
        setStatesChartsStore({
            chartAlertLevelsVisibleToggle: chartAlertLevelsVisibleToggle,
        });
    };
    var alertLevelsHotkey = selectAlertLevelsHotkey();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return onCursorToggleTooltip(!chartAlertLevelsVisibleToggle); }, className: "btn-chart-tools btn-chart-tools-menubtn", color: "customChartTopBarSelect", outline: !chartAlertLevelsVisibleToggle, size: "sm", "data-tooltip-id": "chartAlertLevelsVisible", children: "Hide Alert levels" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "chartAlertLevelsVisible", clickable: true, children: _jsx(HotkeyInfo, { hotkey: alertLevelsHotkey }) })] }));
};
export default memo(ChartAlertLevelsVisibleToggle);
