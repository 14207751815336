import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { getHexId } from '../../widgets/sensor';
var SensorsByEquipment = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId, setSelectedInstallationPointId = _a.setSelectedInstallationPointId, equipment = _a.equipment, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
    return (_jsx(Row, { children: _jsxs(Col, { children: [_jsx(Label, { className: "ellipsis d-block fw-bold", children: "Sensors: " }), _jsx(Input, { value: selectedInstallationPointId, disabled: isDisabled, className: className, onChange: function (ev) { return setSelectedInstallationPointId(+ev.target.value); }, type: "select", children: equipment.installationPoints.map(function (installationPoint) { return (_jsx(_Fragment, { children: installationPoint.sensor_id ? (_jsxs("option", { value: installationPoint.id, children: [installationPoint.name ||
                                    "".concat(installationPoint.component, " - ").concat(installationPoint.point_name), ' ', "(", getHexId(+installationPoint.sensor_id), ")"] }, "alert-levels-sensors-select-".concat(installationPoint.id))) : (_jsx(_Fragment, {})) })); }) })] }) }));
};
export default SensorsByEquipment;
