var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onChangeStatus = function (state, dispatch, status) {
    var _a;
    var selectedStatuses = __assign(__assign({}, state.selectedStatuses), (_a = {}, _a[+status] = +!state.selectedStatuses[status], _a));
    dispatch({
        type: 'setState',
        state: {
            selectedStatuses: selectedStatuses,
            config: __assign(__assign({}, state.config), { loader: true }),
        },
    });
};
export var onRemove = function (state, dispatch, setConfirmData, removeCallback) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want to remove pre-action item?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, state.config), { loader: true }),
                },
            });
            removeCallback();
        },
    });
};
export var onChangeUser = function (state, dispatch, id) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: true }),
            selectedUserId: id,
        },
    });
};
export var onChangeWatchingFilter = function (state, dispatch, filter) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { watchingFilter: state.config.filtersData.watchingFilter === filter ? '' : filter }) }),
        },
    });
};
export var onChangeUrgencyFilter = function (state, dispatch, filter) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { urgency: state.config.filtersData.urgency === filter ? '' : filter }) }),
        },
    });
};
export var onChangeUserStatus = function (state, dispatch, userStatus) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: true }),
            selectedUserStatus: userStatus,
        },
    });
};
export var transformDefaultReasonsReplacement = function (reasons, replaceText) {
    return reasons.map(function (reason) { return reason.replace('%s', replaceText); });
};
export var onRemovePreAi = function (endpoint, callback) {
    endpoint().then(function (resp) {
        if (resp.success || resp.status === 'ok') {
            toast.success('Successfully removed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
        }
        callback();
    });
};
