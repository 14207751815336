// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-list {
  display: flex;
  margin-left: 10px;
}
.tags-list .tag-item {
  cursor: pointer;
  margin-top: 2px;
  margin-right: 5px;
}
.tags-list .tag-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  min-width: 45px;
}
.tags-list .tag-item span:hover {
  opacity: 0.9;
}
.tags-list .tag-startupMode {
  width: 28px !important;
  min-width: 28px !important;
  padding: 0;
}
.tags-list .tag-startupMode svg {
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/topBar/Labels.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AAAE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AAGN;AAFM;EACE,YAAA;AAIR;AAAE;EACE,sBAAA;EACA,0BAAA;EACA,UAAA;AAEJ;AADI;EACE,eAAA;AAGN","sourcesContent":[".tags-list {\n  display: flex;\n  margin-left: 10px;\n  & .tag-item {\n    cursor: pointer;\n    margin-top: 2px;\n    margin-right: 5px;\n    & span {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 28px;\n      min-width: 45px;\n      &:hover {\n        opacity: 0.9;\n      }\n    }\n  }\n  & .tag-startupMode {\n    width: 28px !important;\n    min-width: 28px !important;\n    padding: 0;\n    & svg {\n      font-size: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
