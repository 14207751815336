var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { difference as _difference, findIndex as _findIndex, split as _split, toLower as _toLower, uniq as _uniq, } from 'lodash';
import { toast } from 'react-toastify';
import { ALERTS_LIST, IS_SHOW_LAST_VIEWED_INACTIVE, IS_SHOW_LAST_VIEWED_SUCCESS, IS_SHOW_LAST_VIEWED_WAITING_REQUEST, STATUS_WATCHING, } from '../../../../constants';
import Cookie from '../../../helper/cookie';
import User from '../../../helper/user';
export var getNewDataFromResp = function (resp, showAcknowledge) {
    if (resp.data) {
        return resp.data.map(function (item) {
            return __assign(__assign({}, item), { children: [], isFirstLevel: true, isOpenTree: false, isShowCheckbox: showAcknowledge, isLoaderActive: false, isSelected: false, count_items: String(item.count_items) });
        });
    }
};
export var onSelectProcess = function (state, dispatch, processStatus) {
    var config = state.config;
    var filtersData = config.filtersData;
    dispatch({
        type: 'setState',
        state: {
            alertId: null,
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, filtersData), { processStatus: processStatus }) }),
        },
    });
};
export var onSelectReadingTypes = function (state, dispatch, readingTypes) {
    var config = state.config;
    var filtersData = config.filtersData;
    dispatch({
        type: 'setState',
        state: {
            alertId: null,
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, filtersData), { readingTypes: readingTypes }) }),
        },
    });
};
var onToggleTreeCalculateFunction = function (config, data, showAcknowledge, item, dispatch, isOpenTree, isShowLastViewed, lastViewedItem) {
    if (!item) {
        return;
    }
    var filtersData = config.filtersData;
    var index = _findIndex(data, item);
    if (isOpenTree) {
        data.splice.apply(data, __spreadArray([index + 1, 0], data[index].children, false));
    }
    else {
        data.splice(index + 1, (item === null || item === void 0 ? void 0 : item.isFirstLevel)
            ? data.filter(function (el) { return el.equipment_id === item.equipment_id; }).length - 1
            : data[index].children.length);
    }
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { data: data.map(function (el) {
                    if (JSON.stringify(el) === JSON.stringify(item)) {
                        return __assign(__assign({}, el), { isOpenTree: isOpenTree, isShowCheckbox: showAcknowledge });
                    }
                    return __assign(__assign({}, el), { isShowCheckbox: showAcknowledge, isLastChildren: (!item.isFirstLevel &&
                            item.children.length > 1 &&
                            item.children[item.children.length - 1].id === el.id) ||
                            el.isLastChildren ||
                            false, isSelected: item.children.filter(function (elem) { return elem.sensor_id === el.sensor_id && elem.reading_type_id === el.reading_type_id; }).length && !el.isFirstLevel
                            ? item.isSelected
                            : el.isSelected });
                }), filtersData: __assign(__assign({}, filtersData), { disabledBtns: false }) }),
        },
    });
    if (![IS_SHOW_LAST_VIEWED_INACTIVE, IS_SHOW_LAST_VIEWED_WAITING_REQUEST].includes(isShowLastViewed)) {
        var newItem_1 = data.filter(function (el) {
            return Number(el.installation_point_id) === Number(lastViewedItem.installationPoint) && !el.isFirstLevel;
        })[0];
        if (!newItem_1 || newItem_1.children.length <= 1) {
            return;
        }
        if (isOpenTree) {
            data.splice.apply(data, __spreadArray([_findIndex(data, newItem_1) + 1, 0], data[_findIndex(data, newItem_1)].children, false));
        }
        else {
            data.splice(_findIndex(data, newItem_1) + 1, newItem_1.isFirstLevel
                ? data.filter(function (el) { return el.equipment_id === newItem_1.equipment_id; }).length - 1
                : data[_findIndex(data, newItem_1)].children.length);
        }
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { data: data.map(function (el) {
                        var levelName = el.level
                            ? +el.level === 2
                                ? ALERTS_LIST.WARNING
                                : ALERTS_LIST.CAUTION
                            : el.level_name;
                        if (JSON.stringify(el) === JSON.stringify(newItem_1)) {
                            return __assign(__assign({}, el), { isOpenTree: isOpenTree, isShowCheckbox: showAcknowledge });
                        }
                        return __assign(__assign({}, el), { isShowCheckbox: showAcknowledge, isLastChildren: (!item.isFirstLevel &&
                                item.children.length > 1 &&
                                item.children[item.children.length - 1].id === el.id) ||
                                el.isLastChildren ||
                                false, isSelected: newItem_1.children.filter(function (elem) {
                                return elem.sensor_id === el.sensor_id && elem.reading_type_id === el.reading_type_id;
                            }).length && !el.isFirstLevel
                                ? newItem_1.isSelected
                                : ((filtersData === null || filtersData === void 0 ? void 0 : filtersData.batchSelectedLevelsType) === ALERTS_LIST.CAUTION &&
                                    _toLower(levelName) === ALERTS_LIST.CAUTION) ||
                                    ((filtersData === null || filtersData === void 0 ? void 0 : filtersData.batchSelectedLevelsType) === ALERTS_LIST.WARNING &&
                                        _toLower(levelName) === ALERTS_LIST.WARNING) });
                    }), filtersData: __assign(__assign({}, filtersData), { disabledBtns: false }) }),
            },
        });
    }
};
export var onToggleTree = function (state, dispatch, item, isOpenTree) {
    var config = state.config, isShowLastViewed = state.isShowLastViewed, lastViewedItem = state.lastViewedItem;
    var data = config.data, filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    var showAcknowledge = filtersData.showAcknowledge;
    onToggleTreeCalculateFunction(config, data, showAcknowledge, item, dispatch, isOpenTree, isShowLastViewed, lastViewedItem);
};
export var onRequestChildren = function (state, dispatch, item, endpoint) {
    var config = state.config, isShowLastViewed = state.isShowLastViewed, lastViewedItem = state.lastViewedItem;
    var data = config.data, filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    if (item.isFirstLevel) {
        dispatch({
            type: 'setState',
            state: {
                config: __assign(__assign({}, config), { data: data.map(function (el) {
                        if (el.equipment_id === item.equipment_id) {
                            return __assign(__assign({}, el), { isLoaderActive: true });
                        }
                        return el;
                    }), filtersData: __assign(__assign({}, filtersData), { disabledBtns: true }) }),
            },
        });
    }
    var showAcknowledge = filtersData.showAcknowledge;
    var newItem = item;
    endpoint().then(function (resp) {
        if (resp.data) {
            var installPointsArray_1 = Object.values(resp.data).map(function (el) {
                if (el.children.length > 1) {
                    return __assign(__assign({}, el), { isOpenTree: false, isShowCheckbox: showAcknowledge, isSelected: el.isSelected, children: el.children.map(function (elem) {
                            if (String(elem.id) === String(lastViewedItem.alertId)) {
                                return __assign(__assign({}, elem), { is_selected: isShowLastViewed === IS_SHOW_LAST_VIEWED_SUCCESS || el.is_selected });
                            }
                            return elem;
                        }), is_selected: (isShowLastViewed === IS_SHOW_LAST_VIEWED_SUCCESS &&
                            String(el.installation_point_id) === String(lastViewedItem.installationPoint)) ||
                            el.is_selected });
                }
                return __assign(__assign({}, el), { is_selected: (isShowLastViewed === IS_SHOW_LAST_VIEWED_SUCCESS &&
                        String(el.installation_point_id) === String(lastViewedItem.installationPoint)) ||
                        el.is_selected });
            });
            var newData = data.map(function (el) {
                if (el.equipment_id === item.equipment_id) {
                    newItem = __assign(__assign({}, el), { isOpenTree: true, isLoaderActive: false, isShowCheckbox: showAcknowledge, isSelected: el.isSelected, is_selected: isShowLastViewed === IS_SHOW_LAST_VIEWED_SUCCESS || el.is_selected, children: installPointsArray_1 });
                    return newItem;
                }
                return el;
            });
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: newData }),
                },
            });
            onToggleTreeCalculateFunction(config, newData, showAcknowledge, newItem, dispatch, true, isShowLastViewed, lastViewedItem);
        }
    });
};
export var onShowAcknowledge = function (state, dispatch) {
    var config = state.config;
    var data = config.data, filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    var showAcknowledge = filtersData.showAcknowledge;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { data: data.map(function (el) {
                    return __assign(__assign({}, el), { isSelected: false, isShowCheckbox: !showAcknowledge });
                }), filtersData: __assign(__assign({}, filtersData), { showAcknowledge: !showAcknowledge }) }),
        },
    });
};
export var onChangeLevel = function (state, dispatch, selectedLevel) {
    var config = state.config;
    var filtersData = config.filtersData;
    Cookie.set('showAllAlertsLevel', !!selectedLevel);
    dispatch({
        type: 'setState',
        state: {
            alertId: null,
            config: __assign(__assign({}, config), { loader: true, filtersData: __assign(__assign({}, filtersData), { selectedLevel: selectedLevel }) }),
        },
    });
};
export var onToggleIsSelectedAlert = function (state, dispatch, item) {
    var config = state.config, ids = state.ids;
    var data = config.data;
    var childrenCautionWarning = __spreadArray(__spreadArray([], _split(item.warning_alert_triggered_ids, ','), true), _split(item.caution_alert_triggered_ids, ','), true).filter(function (el) { return el !== ''; });
    var isSelected = !item.isSelected;
    var newIds = ids;
    if (isSelected) {
        newIds = __spreadArray(__spreadArray([], newIds, true), childrenCautionWarning, true);
    }
    else {
        newIds = _difference(newIds, childrenCautionWarning);
    }
    var intermediateData = data.map(function (el, index) {
        return __assign(__assign({}, el), { isSelected: item.isFirstLevel
                ? el.equipment_id === item.equipment_id
                    ? isSelected
                    : el.isSelected
                : item.children
                    ? item.children.length > 1
                        ? item.children.filter(function (elem) { return elem.sensor_id === el.sensor_id && elem.reading_type_id === el.reading_type_id; }).length && !el.isFirstLevel
                            ? isSelected
                            : el.isSelected
                        : _findIndex(data, item) === index
                            ? isSelected
                            : el.isSelected
                    : _findIndex(data, item) === index
                        ? isSelected
                        : el.isSelected });
    });
    var newData = intermediateData.map(function (el) {
        if (!item.isFirstLevel) {
            return __assign(__assign({}, el), { isSelected: item.equipment_id === el.equipment_id && el.isFirstLevel
                    ? intermediateData.filter(function (elem) { return elem.equipment_id === el.equipment_id && elem.isSelected && !elem.isFirstLevel; }).length ===
                        +intermediateData.filter(function (elem) { return elem.equipment_id === el.equipment_id && !elem.isFirstLevel; })
                            .length
                    : el.isSelected });
        }
        return el;
    });
    dispatch({
        type: 'setState',
        state: {
            ids: _uniq(newIds),
            config: __assign(__assign({}, config), { data: newData }),
        },
    });
};
export var onBatchSelectChange = function (state, dispatch, batchSelectedLevelsType) {
    var config = state.config;
    var data = config.data, filtersData = config.filtersData;
    var newData = data;
    var newIds = [];
    newData.map(function (el) {
        var levelName = el.level
            ? +el.level === 2
                ? ALERTS_LIST.WARNING
                : ALERTS_LIST.CAUTION
            : el.level_name;
        if (el.isFirstLevel) {
            if (batchSelectedLevelsType === 'allOnPage' || batchSelectedLevelsType === 'all') {
                __spreadArray(__spreadArray([], _split(el.warning_alert_triggered_ids, ','), true), _split(el.caution_alert_triggered_ids, ','), true).filter(function (elem) {
                    if (!elem) {
                        return;
                    }
                    newIds.push(elem);
                });
                return;
            }
            if (batchSelectedLevelsType === ALERTS_LIST.WARNING) {
                _split(el.warning_alert_triggered_ids, ',').filter(function (elem) {
                    if (!elem) {
                        return;
                    }
                    newIds.push(elem);
                });
                return;
            }
            if (batchSelectedLevelsType === ALERTS_LIST.CAUTION) {
                _split(el.caution_alert_triggered_ids, ',').filter(function (elem) {
                    if (!elem) {
                        return;
                    }
                    newIds.push(elem);
                });
                return;
            }
        }
        else {
            if (_toLower(levelName) === batchSelectedLevelsType) {
                newIds.push(el.alert_condition_id);
            }
        }
    });
    dispatch({
        type: 'setState',
        state: {
            ids: _uniq(newIds),
            config: __assign(__assign({}, config), { data: data.map(function (el) {
                    var levelName = el.level
                        ? +el.level === 2
                            ? ALERTS_LIST.WARNING
                            : ALERTS_LIST.CAUTION
                        : el.level_name;
                    if (!batchSelectedLevelsType) {
                        return __assign(__assign({}, el), { isSelected: false });
                    }
                    if (batchSelectedLevelsType === 'allOnPage' || batchSelectedLevelsType === 'all') {
                        return __assign(__assign({}, el), { isSelected: true });
                    }
                    return __assign(__assign({}, el), { isSelected: _toLower(levelName) === batchSelectedLevelsType ||
                            (el.isFirstLevel &&
                                el.caution_alert_triggered_ids &&
                                batchSelectedLevelsType === ALERTS_LIST.CAUTION) ||
                            (el.isFirstLevel &&
                                el.warning_alert_triggered_ids &&
                                batchSelectedLevelsType === ALERTS_LIST.WARNING) });
                }), filtersData: __assign(__assign({}, filtersData), { batchSelectedLevelsType: batchSelectedLevelsType }) }),
        },
    });
};
export var onLastViewed = function (state, dispatch) {
    var config = state.config, lastViewedItem = state.lastViewedItem;
    var filtersData = config.filtersData, pagination = config.pagination, data = config.data;
    var page = lastViewedItem.page, exists = lastViewedItem.exists, equipmentId = lastViewedItem.equipmentId;
    if (!filtersData) {
        return;
    }
    if (exists) {
        dispatch({
            type: 'setState',
            state: {
                isShowLastViewed: page !== (pagination === null || pagination === void 0 ? void 0 : pagination.page) ? IS_SHOW_LAST_VIEWED_WAITING_REQUEST : IS_SHOW_LAST_VIEWED_SUCCESS,
                config: __assign(__assign({}, config), { pagination: __assign(__assign({}, pagination), { page: page }), filtersData: __assign(__assign({}, filtersData), { processStatus: '', readingTypes: '' }) }),
            },
        });
        var itemFirstLevel = data.filter(function (el) { return String(el.equipment_id) === String(equipmentId) && el.isFirstLevel; })[0];
        if (!itemFirstLevel) {
            toast.info('Your last viewed alert was Acknowledge!');
            return;
        }
        if (page === (pagination === null || pagination === void 0 ? void 0 : pagination.page) && itemFirstLevel.isOpenTree) {
            onToggleTree(state, dispatch, itemFirstLevel, false);
        }
    }
    else {
        toast.info('Your last viewed alert was Acknowledge!');
    }
};
export var acknowledgeItem = function (state, dispatch, itemId) {
    var config = state.config, ids = state.ids;
    var filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    if (!ids.length) {
        toast.warning('No selected alerts! Please select at least one alert');
        return false;
    }
    dispatch({
        type: 'setState',
        state: {
            isVisibleAcknowledgeModal: true,
            itemId: itemId,
        },
    });
};
export var onClickViewBtn = function (state, dispatch, equipmentId, pointId, alertId) {
    var user = User.get();
    var config = state.config, lastViewedItem = state.lastViewedItem;
    var data = config.data, pagination = config.pagination, filtersData = config.filtersData;
    var tmpData = lastViewedItem.exists
        ? toggleTreeFillingActiveRow(data, lastViewedItem.equipmentId, String(lastViewedItem.installationPoint), Number(lastViewedItem.alertId), false)
        : data;
    var newData = toggleTreeFillingActiveRow(tmpData, String(equipmentId), pointId, alertId, true).map(function (el) {
        if (+el.installation_point_id === +pointId) {
            return __assign(__assign({}, el), { analyst_status: STATUS_WATCHING, analyst_username: user.full_name });
        }
        return el;
    });
    dispatch({
        type: 'setState',
        state: {
            lastViewedItem: {
                page: pagination === null || pagination === void 0 ? void 0 : pagination.page,
                alertId: alertId,
                equipmentId: equipmentId,
                exists: true,
                installationPoint: pointId,
            },
            config: __assign(__assign({}, config), { data: newData, loader: false, filtersData: __assign(__assign({}, filtersData), { hasViewedAlert: true }) }),
        },
    });
};
var toggleTreeFillingActiveRow = function (data, equipmentId, pointId, alertId, val) {
    var newData = data.map(function (el) {
        if (el.isFirstLevel && String(el.equipment_id) === String(equipmentId)) {
            return __assign(__assign({}, el), { is_selected: val, children: el.children.map(function (elem) {
                    if (elem.children &&
                        elem.children.length > 1 &&
                        String(elem.installation_point_id) === String(pointId)) {
                        return __assign(__assign({}, elem), { is_selected: val, children: elem.children.map(function (element) {
                                if (String(element.id) === String(alertId)) {
                                    return __assign(__assign({}, element), { is_selected: val });
                                }
                                return element;
                            }) });
                    }
                    return __assign(__assign({}, elem), { is_selected: val ? String(elem.installation_point_id) === String(pointId) : false });
                }) });
        }
        return el;
    });
    return newData.map(function (el) {
        if ((el.isFirstLevel && String(el.equipment_id) === String(equipmentId)) ||
            (el.children && el.children.length > 1 && String(el.installation_point_id) === String(pointId)) ||
            String(el.id) === String(alertId)) {
            return __assign(__assign({}, el), { is_selected: val });
        }
        return el;
    });
};
