import { get as _get, includes as _includes } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
export var useAlertConditions = function (state, dispatch, chartIdentifier) {
    var seriesIsRendered = state.seriesIsRendered;
    var _a = ChartFeaturesStore(function (state) { return ({
        resetAlertConditions: state.resetAlertConditions,
        changeAlertConditions: state.changeAlertConditions,
    }); }, shallow), resetAlertConditions = _a.resetAlertConditions, changeAlertConditions = _a.changeAlertConditions;
    useEffect(function () {
        var isMatchedReadingTypeId = changeAlertConditions.selectedReadingTypeIds
            ? _includes(changeAlertConditions.selectedReadingTypeIds, +chartIdentifier)
            : +_get(changeAlertConditions, 'readingTypeId') === +chartIdentifier;
        if (isMatchedReadingTypeId && _get(changeAlertConditions, 'shouldUpdate')) {
            if (seriesIsRendered) {
                dispatch({
                    type: 'setState',
                    state: {
                        isAutoAlertConditions: false,
                    },
                });
            }
            dispatch({
                type: 'setState',
                state: {
                    alertConditions: changeAlertConditions,
                },
            });
        }
    }, [changeAlertConditions]);
    useEffect(function () {
        if (+_get(resetAlertConditions, 'readingTypeId') !== +chartIdentifier) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                isAutoAlertConditions: false,
            },
        });
    }, [resetAlertConditions]);
};
