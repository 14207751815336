import { size as _size } from 'lodash';
import Endpoints from '../../../../../../api/endpoints/endpoints';
export var showTachDataSeries = function (equipment, countDays, series, updateSeries, selectedSystemType, storedTachDataReadings, setStatesChartStore) {
    var existingTachDataSeriesIndex = findExistingTachDataSeriesIndex(series);
    if (existingTachDataSeriesIndex !== -1) {
        series[existingTachDataSeriesIndex].visible = true;
        return updateSeries(series);
    }
    fetchTachDataSeries(equipment, countDays, series, updateSeries, selectedSystemType, storedTachDataReadings, setStatesChartStore);
};
export var hideTachDataSeries = function (series, updateSeries) {
    var existingTachDataSeriesIndex = findExistingTachDataSeriesIndex(series);
    if (existingTachDataSeriesIndex !== -1) {
        series[existingTachDataSeriesIndex].visible = false;
        return updateSeries(series);
    }
};
var fetchTachDataSeries = function (equipment, countDays, series, updateSeries, selectedSystemType, storedTachDataReadings, setStatesChartStore) {
    if (storedTachDataReadings.length > 0) {
        return pushSeries({ readings: storedTachDataReadings }, series, updateSeries);
    }
    Endpoints[selectedSystemType]
        .getTachReadings({
        query: {
            equipmentId: equipment.id,
            countDays: countDays,
        },
    })
        .then(function (resp) {
        if (resp) {
            pushSeries(resp, series, updateSeries);
            setStatesChartStore({ storedTachDataReadings: resp.readings.filter(function (el) { return el[1] !== null; }) });
        }
    });
};
var pushSeries = function (resp, series, updateSeries) {
    if (_size(resp.readings) === 0) {
        updateSeries(series);
        return;
    }
    series.push({
        id: 'tachData',
        visible: true,
        name: 'Tachometer RPM',
        data: resp.readings.filter(function (el) { return el[1] !== null; }),
        units: 'RPM',
        chartType: 1,
        isRpmData: true,
        showInLegend: false,
        precision: 2,
        dataGrouping: {
            approximation: 'high',
            forced: true,
        },
        color: 'rgba(255, 29, 89, 1)',
        dashStyle: 'Solid',
        zones: [],
    });
    updateSeries(series);
};
var findExistingTachDataSeriesIndex = function (series) {
    var existingAmbientSeriesIndex = -1;
    series.map(function (seriesItem, index) {
        if (seriesItem.id === 'tachData') {
            existingAmbientSeriesIndex = index;
        }
    });
    return existingAmbientSeriesIndex;
};
