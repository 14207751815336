import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useFirmwareListQuery, } from '../../../../../remastered/services/firmware/useFirmwareListQuery';
import DataTable from '../../../../components/shared/Table/Table';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../../features/table/tableQuery';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import { selectSortDataTable, useFirmwareActions } from '../../../../store/firmware/useFirmwareStore';
import SelectInstallationPointOnEquipment from '../../components/SelectInstallationPointOnEquipment';
var FirmwareTab = function () {
    var _a, _b;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _c = useState(installationPoint.id), selectedInstallationPointId = _c[0], setSelectedInstallationPointId = _c[1];
    var pointsOnEquipment = (_b = (_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.installationPoints) !== null && _b !== void 0 ? _b : [];
    var _d = useFirmwareListQuery({ installationPointId: selectedInstallationPointId }), data = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching;
    var config = data.config;
    var _e = useFirmwareActions(), setPage = _e.setPage, setPageSize = _e.setPageSize, setSort = _e.setSort, setSearch = _e.setSearch;
    var sort = selectSortDataTable();
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "mt-3", children: _jsx(Col, { md: 4, children: _jsx(SelectInstallationPointOnEquipment, { installationPoint: installationPoint, pointsOnEquipment: pointsOnEquipment, setSelectedInstallationPointId: setSelectedInstallationPointId }) }) }), _jsx(Row, { className: "mt-3", children: _jsx(Col, { children: _jsx(DataTable, { config: config, isLoading: isLoading || isFetching, onPageChange: function (page) { return onPageChange(page, setPage); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, setPageSize, setPage); }, onSort: function (column_name, ev) { return onSort(column_name, sort, setSort, ev); }, onSearch: function (search) { return onSearch(search, setSearch, setPage); } }) }) })] }));
};
export default memo(FirmwareTab);
