var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var onToggleWhoCreated = function (state, dispatch, source) {
    var config = state.config;
    var filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    var activeWhoCreated = filtersData.activeWhoCreated;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, filtersData), { activeWhoCreated: activeWhoCreated === ''
                        ? source === 'customer'
                            ? 'analytic'
                            : 'customer'
                        : activeWhoCreated === source
                            ? source
                            : '' }) }),
        },
    });
};
