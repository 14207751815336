import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { AiOutlineRadarChart } from 'react-icons/ai';
import { MdSsidChart } from 'react-icons/md';
var ChartTwfType = function (_a) {
    var isCircleTwfType = _a.isCircleTwfType, dispatch = _a.dispatch, chartRef = _a.chartRef;
    var onToggle = function () {
        chartRef.current.chart.userOptions.isCircleTwfType = !isCircleTwfType;
        dispatch({
            type: 'setState',
            state: {
                isCircleTwfType: !isCircleTwfType,
            },
        });
    };
    return (_jsx("div", { className: "chart-btn-toggle", onClick: onToggle, children: isCircleTwfType ? _jsx(MdSsidChart, {}) : _jsx(AiOutlineRadarChart, {}) }));
};
export default memo(ChartTwfType);
