import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { READING_TYPE_TEMPERATURE } from '../../../../constants';
import { hideNormalizedReadings, showNormalizedReadings, } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/Normalized/features';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useNormalized = function (state, dispatch, chartIdentifier, updateSeries) {
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var readingTypes = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
    }); }, shallow).readingTypes;
    var mainSeriesLoaded = state.mainSeriesLoaded, options = state.options, ambientDataIsVisible = state.ambientDataIsVisible, normalizedReadingsIsVisible = state.normalizedReadingsIsVisible;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        if (chartIdentifier !== READING_TYPE_TEMPERATURE) {
            return;
        }
        if (!ambientDataIsVisible) {
            return hideNormalizedReadings(options.series, updateSeries);
        }
        if (normalizedReadingsIsVisible) {
            return showNormalizedReadings(options.series, updateSeries, readingTypes, measure, personalSettingMeasure);
        }
        return hideNormalizedReadings(options.series, updateSeries);
    }, [normalizedReadingsIsVisible, ambientDataIsVisible]);
};
