import Api from "../../api";
export default {
    getBadDataList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/bad-data', options);
    },
    removeBadDataItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("amazon-analytic/bad-data/".concat(id), options);
    },
    softRemoveBadDataItem: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("amazon-analytic/bad-data/soft-delete/".concat(id), options);
    },
    getBadDataListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/bad-data/csv', options);
    },
};
