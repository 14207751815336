import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useLoader = function (state, dispatch) {
    var countDays = ChartsStore(function (state) { return ({
        countDays: state.countDays,
    }); }, shallow).countDays;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var mainSeriesLoaded = state.mainSeriesLoaded;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                loader: true,
            },
        });
    }, [installationPoint.id, countDays]);
};
