// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.painting-left-bar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  padding: 20px;
  margin: 20px;
  background: #fff;
  border-radius: 5px;
  width: 260px;
}
.dark-layout .painting-left-bar {
  background: #252a38;
}
.painting-left-bar .painting-colors,
.painting-left-bar .painting-tools {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 20px;
}
.painting-left-bar .painting-colors > div,
.painting-left-bar .painting-tools > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(17, 17, 17, 0.5019607843);
  opacity: 0.8;
  transition: 0.3s opacity, 0.3s background;
}
.painting-left-bar .painting-colors > div:hover,
.painting-left-bar .painting-tools > div:hover {
  opacity: 1;
}
.painting-left-bar .painting-colors > div > svg,
.painting-left-bar .painting-tools > div > svg {
  border-radius: 3px;
  padding: 3px;
  width: 26px;
  height: 26px;
  background-color: rgba(17, 17, 17, 0.5019607843);
  color: #fff;
}
.painting-left-bar .painting-tools > .active {
  background: #111;
}
.painting-left-bar .painting-tools input {
  margin-top: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/charts/components/PaintingLeftBar/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EAIA,kBAAA;EACA,YAAA;AAFF;AAFE;EACE,mBAAA;AAIJ;AAAE;;EAEE,aAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AAEJ;AADI;;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gDAAA;EACA,YAAA;EACA,yCACE;AAGR;AADM;;EACE,UAAA;AAIR;AAFM;;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,gDAAA;EACA,WAAA;AAKR;AAAI;EACE,gBAAA;AAEN;AAAI;EACE,aAAA;EACA,WAAA;AAEN","sourcesContent":[".painting-left-bar {\n  position: fixed;\n  left: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  z-index: 10000;\n  padding: 20px;\n  margin: 20px;\n  background: #fff;\n  .dark-layout & {\n    background: #252a38;\n  }\n  border-radius: 5px;\n  width: 260px;\n  & .painting-colors,\n  .painting-tools {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 15px;\n    column-gap: 20px;\n    & > div {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      width: 40px;\n      height: 40px;\n      border-radius: 5px;\n      cursor: pointer;\n      border: 1px solid #11111180;\n      opacity: 0.8;\n      transition:\n        0.3s opacity,\n        0.3s background;\n      &:hover {\n        opacity: 1;\n      }\n      & > svg {\n        border-radius: 3px;\n        padding: 3px;\n        width: 26px;\n        height: 26px;\n        background-color: #11111180;\n        color: #fff;\n      }\n    }\n  }\n  & .painting-tools {\n    & > .active {\n      background: #111;\n    }\n    & input {\n      margin-top: 0;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
