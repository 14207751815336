import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useEffect, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { getHotkey } from '../../features/hotkey';
import './index.scss';
var HotkeyInfo = function (_a) {
    var hotkey = _a.hotkey, onChangeHotkey = _a.onChangeHotkey;
    var _b = useState(false), isEditActive = _b[0], setIsEditActive = _b[1];
    var newHotkeyHandler = useCallback(function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (!onChangeHotkey) {
            return;
        }
        onChangeHotkey(getHotkey(event));
        setIsEditActive(false);
        document.removeEventListener('keyup', newHotkeyHandler);
    }, [onChangeHotkey]);
    var handler = useCallback(function () {
        if (isEditActive) {
            document.removeEventListener('keyup', newHotkeyHandler);
            return;
        }
        document.removeEventListener('keyup', newHotkeyHandler);
        document.addEventListener('keyup', newHotkeyHandler);
        setIsEditActive(true);
    }, []);
    useEffect(function () {
        return function () {
            document.removeEventListener('keyup', newHotkeyHandler);
        };
    }, []);
    return (_jsxs("div", { className: "hotkey-info", children: [hotkey.names.map(function (key, index) { return (_jsxs(_Fragment, { children: [_jsx("span", { children: key }, key), index === hotkey.names.length - 1 ? '' : ' + '] })); }), onChangeHotkey ? (_jsx(_Fragment, { children: _jsx(MdModeEdit, { className: "hotkey-edit ".concat(isEditActive ? 'active' : ''), onClick: handler }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(HotkeyInfo);
