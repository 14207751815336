var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { FaCheckCircle, FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label, Table } from 'reactstrap';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { READING_TYPES } from '../../../../../../constants';
import { SystemTypeContext } from '../../../../../../shared/context/SystemTypeContext';
import User from '../../../../../../shared/helper/user';
import { useConfirmModalActions } from '../../../../../../shared/store/global/useConfirmModalStore';
import RichTextEditor from '../../../../../../widgets/TextEditor/TextEditor';
import './CommentsBlock.scss';
var CommentsBlock = function (_a) {
    var comments = _a.comments, callback = _a.callback, isVisibleFormBlock = _a.isVisibleFormBlock, selectedComment = _a.selectedComment, setSelectedComment = _a.setSelectedComment, setIsVisibleFormBlock = _a.setIsVisibleFormBlock, customerId = _a.customerId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onDelete = function (commentId) {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to delete comment?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                Endpoints[selectedSystemType]
                    .deleteAlertConditionComment(commentId)
                    .then(function (resp) {
                    if (resp.success) {
                        toast.success(resp.message || 'Successfully Removed', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                        setIsVisibleFormBlock(false);
                        setSelectedComment({ user_name: '', comment: '', id: null });
                        return callback();
                    }
                    toast.error(resp.message || 'Server Error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
                });
            },
        });
    };
    var commentAlreadyExist = useMemo(function () {
        return comments.map(function (comment) { return (comment.user_id ? +comment.user_id : null); }).includes(+User.getId());
    }, [comments]);
    return (_jsxs("div", { className: "analyst-note-commentBlock", children: [comments.length > 0 && (_jsx(Table, { children: _jsx("tbody", { children: comments.map(function (comment) { return (_jsxs("tr", { className: "d-flex", children: [_jsxs("td", { className: "analyst-note-username", children: [comment.user_name, ":"] }), _jsx("td", { className: "analyst-note-comment", dangerouslySetInnerHTML: { __html: comment.comment } }), _jsxs("td", { className: "analyst-note-readingType", children: [_get(READING_TYPES, [comment.reading_type_id, 'title'], ''), ' ', _get(READING_TYPES, [comment.reading_type_id, 'axis'], '')] }), _jsxs("td", { className: "analyst-note-btns", children: [_jsx(Button, { size: "sm", color: "primary", title: "Update comment", onClick: function () {
                                            setSelectedComment(comment);
                                            setIsVisibleFormBlock(true);
                                        }, children: _jsx(FaEdit, {}) }), _jsx(Button, { size: "sm", color: "danger", title: "Delete comment", onClick: function () { return onDelete(comment.id); }, children: _jsx(FaTrash, {}) })] })] }, "alert_levels_comment_".concat(comment.id))); }) }) })), !commentAlreadyExist && (_jsx(Button, { color: "success", onClick: function () {
                    setSelectedComment({ user_name: '', comment: '' });
                    setIsVisibleFormBlock(!isVisibleFormBlock);
                }, children: "Add Alert New Comment" })), isVisibleFormBlock && (_jsxs(FormGroup, { className: "mt-2", children: [_jsx(Label, { for: "textarea", children: "Comment:" }), _jsx(RichTextEditor, { customerId: customerId, content: _get(selectedComment, 'comment', ''), onChange: function (content) { return setSelectedComment(__assign(__assign({}, selectedComment), { comment: content })); } })] }))] }));
};
export default CommentsBlock;
