// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-TwfDetectionsReports .nav-tabs,
.page-TwfDetectionsReportsStatistic .nav-tabs {
  margin: 10px 0;
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/twfDetections/twfDetections.scss"],"names":[],"mappings":"AAEE;;EACE,cAAA;EACA,mBAAA;AAAJ","sourcesContent":[".page-TwfDetectionsReports,\n.page-TwfDetectionsReportsStatistic {\n  & .nav-tabs {\n    margin: 10px 0;\n    border-bottom: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
