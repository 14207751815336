var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import { get as _get } from 'lodash';
import React, { useContext } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../api/endpoints/endpoints';
import { READING_TYPES } from '../../constants';
import { SystemTypeContext } from '../../shared/context/SystemTypeContext';
import { ChartFeaturesStore } from '../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
var RemovePointModal = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = ChartFeaturesStore(function (state) { return ({
        removePoint: state.removePoint,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), removePoint = _a.removePoint, setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }, shallow), sensor = _get(installationPoint, 'sensor') || {};
    var chartIdentifier = _get(removePoint, 'chartIdentifier');
    var onSave = function () {
        var _a;
        Endpoints[selectedSystemType]
            .removePoint({
            time: (_a = removePoint === null || removePoint === void 0 ? void 0 : removePoint.point) === null || _a === void 0 ? void 0 : _a.x,
            installation_point_id: installationPoint.id,
            reading_type_id: chartIdentifier,
        })
            .then(function (resp) {
            if (resp) {
                onCancel();
                if (!resp.success) {
                    return toast.error(resp.message || 'Server error', {
                        icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                    });
                }
                setStatesChartFeaturesStore({
                    removePoint: __assign(__assign({}, removePoint), { removedReading: removePoint.point.x }),
                });
                toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onCancel = function () {
        setStatesChartFeaturesStore({
            removePoint: {
                isVisibleModal: false,
                point: { x: 0, y: 0 },
                chartIdentifier: null,
            },
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "sm", toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Remove point?" }), _jsxs(ModalBody, { children: [_jsxs("p", { children: [_jsx("b", { children: "Sensor: " }), _jsx("span", { children: sensor.sensor_id })] }), _jsxs("p", { children: [_jsx("b", { children: "Reading Type: " }), _jsx("span", { children: _get(READING_TYPES, [chartIdentifier, 'title'], '') })] }), _jsxs("p", { children: [_jsx("b", { children: "Time: " }), _jsx("span", { children: Highcharts.dateFormat('%m/%d/%y %I:%M %p', removePoint.point.x) })] }), _jsxs("p", { children: [_jsx("b", { children: "Value: " }), _jsx("span", { children: removePoint.point.y })] })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "danger", onClick: onSave, children: "Remove" })] }) })] }));
};
export default RemovePointModal;
