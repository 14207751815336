import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { copyTextToClipboard } from '../../../../../../../helper/dom';
var CopyLinkButton = function (_a) {
    var onClickCopyLink = _a.onClickCopyLink, width = _a.width, isDisabled = _a.isDisabled;
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { className: "copy-link-btn chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", onClick: onClickCopyLink, style: { width: width }, disabled: isDisabled, children: _jsx(FaRegCopy, {}) }) }));
};
export default memo(CopyLinkButton);
export var onCopyLink = function (chartIdentifier) {
    copyTextToClipboard(window.location.href + '&scroll_to=' + chartIdentifier, 'Link to chart copied to clipboard');
};
