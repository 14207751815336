var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { get as _get } from 'lodash';
import React, { memo, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../../constants';
import Loader from '../../components/Loader';
import BatchTimestampsCalendar from '../../components/charts/chartsWrapper/components/fftTwf/BatchTimestampsCalendar';
import { fftTicksPositioner, formatXAxisFFT, } from '../../components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import ResetFftTwfZoomButton from '../../components/charts/chartsWrapper/components/shared/chartToolsButtons/ResetFftTwfZoomButton';
import { FftTooltipFormatter, fftPositioner, } from '../../components/charts/chartsWrapper/components/shared/tooltip';
import { DEFAULT_CHARTS_CONFIG } from '../../config/charts/defaultChartsConfig';
import { getFftReadingsIdsByTimestamps, getNearestPointIndex } from '../../helper/chart';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
export var ChartBlock = function (_a) {
    var _b;
    var chartIdentifier = _a.chartIdentifier, setReadingsList = _a.setReadingsList, isPending = _a.isPending, series = _a.series, annotations = _a.annotations, units = _a.units, plotLinesX = _a.plotLinesX, additionalInfoForTooltip = _a.additionalInfoForTooltip, yAxisMax = _a.yAxisMax;
    var chartRef = useRef(null);
    var _c = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        currentSpeed: state.currentSpeed,
    }); }, shallow), readingTypes = _c.readingTypes, currentSpeed = _c.currentSpeed;
    var _d = FftChartsStore(function (state) { return ({
        fftTimestampDates: state.fftTimestampDates,
        fftData: state.data,
    }); }, shallow), fftTimestampDates = _d.fftTimestampDates, fftData = _d.fftData;
    var initialTimestamps = useMemo(function () { return [fftTimestampDates[chartIdentifier]]; }, []);
    var axisId = _get(READING_TYPES, [chartIdentifier, 'axisId']);
    var onApplyCalendar = function (timestamps) {
        if (timestamps.length === 0) {
            toast.error('Choose at least one timestamp', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            return undefined;
        }
        setReadingsList(getFftReadingsIdsByTimestamps(Object.values(fftData.fftTimestamps[axisId]), timestamps).reverse());
    };
    return (_jsxs("div", { className: "position-relative", children: [isPending ? (_jsx("div", { style: {
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                    backdropFilter: 'blur(2px)',
                    width: '100%',
                    height: '100%',
                }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "chart-actions-btn-wrapper", children: [_jsx(ResetFftTwfZoomButton, { chart: (_b = chartRef === null || chartRef === void 0 ? void 0 : chartRef.current) === null || _b === void 0 ? void 0 : _b.chart, width: 30, isDisabled: false }), _jsx(BatchTimestampsCalendar, { chartIdentifier: chartIdentifier, inititalTimestamps: initialTimestamps, onApply: onApplyCalendar })] }), _jsx("div", { className: "position-relative", children: _jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: getChartOptions(series, annotations || [], units, chartIdentifier, currentSpeed, readingTypes, plotLinesX, additionalInfoForTooltip, yAxisMax) }) })] }));
};
var getChartOptions = function (series, annotations, units, chartIdentifier, currentSpeed, readingTypes, plotLinesX, additionalInfoForTooltip, yAxisMax) {
    return __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
            style: {
                fontFamily: "'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, Helvetica, sans-serif",
                fontSize: '12px',
            },
            type: 'line',
            zoomType: 'x',
            borderColor: 'rgb(241, 238, 233)',
            backgroundColor: 'rgb(241, 238, 233)',
            borderWidth: 2,
            animation: false,
            resetZoomButton: {
                theme: { style: { display: 'none' } },
            },
        }, boost: {
            enabled: true,
        }, series: series, yAxis: [
            {
                max: yAxisMax || null,
            },
        ], xAxis: [
            {
                plotLines: plotLinesX || [],
                crosshair: true,
                ordinal: false,
                labels: {
                    formatter: function () {
                        return formatXAxisFFT(this.chart, this.value, units, currentSpeed);
                    },
                },
                tickPositioner: function () {
                    return fftTicksPositioner(this.chart, this.max, units, currentSpeed);
                },
            },
        ], chartIdentifier: chartIdentifier, tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { positioner: function (labelWidth) {
                return __assign(__assign({}, fftPositioner(labelWidth, this.chart)), { y: 30 });
            }, formatter: function () {
                var _this = this;
                var _a;
                // Sync overlays
                var chart = {};
                var arrPoints = [];
                if (this.points.length) {
                    chart = this.points[0].series.chart;
                    if (((_a = chart === null || chart === void 0 ? void 0 : chart.series) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        chart.series.forEach(function (seriesItem) {
                            if (!seriesItem.data.length) {
                                return;
                            }
                            var nearestPoint = getNearestPointIndex(seriesItem, _this.point.x);
                            arrPoints.push(seriesItem.points[nearestPoint]);
                        });
                    }
                }
                return ReactDOMServer.renderToStaticMarkup((_jsx("div", { children: _jsx(FftTooltipFormatter, { showRms: false, readingTypes: readingTypes, points: arrPoints, currentSpeed: currentSpeed, showPeaks: false, xValue: this.x, children: additionalInfoForTooltip }) })));
            }, shape: 'square', backgroundColor: 'rgba(255, 255, 255, .85)' }), exporting: {
            enabled: false,
        }, annotations: annotations });
};
export default memo(ChartBlock);
