import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import TranslatesToggleBtn from '../../../AIPreAi/TranslatesToggleBtn';
import { getTranslates } from '../../../AIPreAi/TranslatesToggleBtn/features';
import HighlighterWord from '../../../shared/HighlighterWord';
var DefaultAITitle = function (_a) {
    var title = _a.title, translates = _a.translates;
    var _b = useState(false), isShowTranslates = _b[0], setIsShowTranslates = _b[1];
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    return (_jsxs("span", { className: "page-title-aiPreAiTitle", children: [_jsx(HighlighterWord, { block: false, searchWords: highlightSearch || '', textToHighlight: isShowTranslates ? getTranslates(translates || {}, 'title') : title }), translates ? (_jsx(TranslatesToggleBtn, { fieldName: 'title', fieldNameId: 'title', translates: translates, buttonStyle: { right: '-14px', top: '-7px' }, isShowTranslates: isShowTranslates, setIsShowTranslates: setIsShowTranslates })) : (_jsx(_Fragment, {}))] }));
};
export default memo(DefaultAITitle);
