import { useEffect } from 'react';
var useDeactivationAnalystLog = function (id, deactivationPreAiAnalystLogMutate) {
    useEffect(function () {
        window.addEventListener('beforeunload', function () { return deactivationPreAiAnalystLogMutate({ id: id }); });
        return function () {
            window.removeEventListener('beforeunload', function () { return deactivationPreAiAnalystLogMutate({ id: id }); });
        };
    }, []);
};
export default useDeactivationAnalystLog;
