import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get, has as _has } from 'lodash';
import React from 'react';
export var config = function (visibleState) {
    return {
        columns: [
            {
                name: 'ai',
                visible: _has(visibleState, 'ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.ai : true,
                title: 'Total AI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'ai', 0) || 0 });
                },
            },
            {
                name: 'no_action_required',
                visible: _has(visibleState, 'no_action_required') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.no_action_required : true,
                title: 'No action AI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'no_action_required', 0) || 0 });
                },
            },
            {
                name: 'pre_ai',
                visible: _has(visibleState, 'pre_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.pre_ai : true,
                title: 'Total Pre-AI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'pre_ai', 0) || 0 });
                },
            },
            {
                name: 'hardware_ai',
                visible: _has(visibleState, 'hardware_ai') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.hardware_ai : true,
                title: 'Total WWAI',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'hardware_ai', 0) || 0 });
                },
            },
            {
                name: 'pre_ai_accepted',
                visible: _has(visibleState, 'pre_ai_accepted') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.pre_ai_accepted : true,
                title: 'Pre-AI accepted',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'pre_ai_accepted', 0) || 0 });
                },
            },
            {
                name: 'pre_ai_declined',
                visible: _has(visibleState, 'pre_ai_declined') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.pre_ai_declined : true,
                title: 'Pre-AI declined',
                sortable: false,
                component: function (row) {
                    return _jsx("span", { children: _get(row, 'pre_ai_declined', 0) || 0 });
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        disabledSearch: true,
        data: [],
        loader: false,
        hideTotalLabel: true,
    };
};
