import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaEye } from 'react-icons/fa';
import { Button, Table } from 'reactstrap';
var ShowMoreTable = function (_a) {
    var actionItem = _a.actionItem, setCompletionReportModalState = _a.setCompletionReportModalState;
    return (_jsxs(Table, { striped: true, className: "position-relative responsive_table table-sm", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { className: "text-nowrap text-center card-row_title", children: "Title" }), _jsx("th", { className: "text-nowrap text-center card-row_title", children: "Description" }), _jsx("th", {})] }) }), _jsx("tbody", { style: { border: 0 }, children: _jsxs("tr", { children: [_jsx("td", { className: "card-row_title text-center", children: actionItem.title }), _jsx("td", { className: "card-row_title text-center", children: _jsx("div", { className: "machine-info-action-item-content", dangerouslySetInnerHTML: { __html: actionItem.description } }) }), _jsx("td", { className: "card-row_title text-center", children: actionItem.report ? (_jsx(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function () {
                                    return setCompletionReportModalState({
                                        isVisibleCompletionReportModal: true,
                                        report: actionItem.report,
                                    });
                                }, children: _jsx(FaEye, { className: "button-icon" }) })) : (_jsx(_Fragment, {})) })] }) })] }));
};
export default memo(ShowMoreTable);
