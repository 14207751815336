var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { find as _find, flatten as _flatten, get as _get, sortBy as _sortBy } from 'lodash';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
var initialState = {
    changeAlertConditions: {
        readingTypeId: null,
        currentLevel: {},
        storedLevels: {},
        conditions: '',
        settings: {},
        fftSettings: {},
        isRmsOd: {},
        isVisibleModal: false,
        selectedReadingTypeIds: [],
    },
    resetAlertConditions: {
        readingTypeId: null,
        levels: {},
        conditions: {},
        settings: {},
        fftSettings: {},
        isRmsOd: {},
    },
    removePoint: {
        isVisibleModal: false,
        point: { x: 0, y: 0 },
        removedReading: null,
        chartIdentifier: null,
    },
    closestFft: {
        isVisibleModal: false,
        point: { x: 0, y: 0 },
    },
    stateSuggestedLevelFormulaModal: {
        visible: false,
        onCancel: function () { },
        data: {
            installationPoint: {},
            fromChart: false,
            readingTypes: [],
        },
    },
    tachDataIsVisible: {},
    hidePeaksModal: {
        visible: false,
        state: {},
        dispatch: function () { },
        chartIdentifier: null,
    },
    bandAlarmsFormModal: {
        visible: false,
        band: {},
        chartIdentifier: null,
    },
    envelopeAlarmsFormModal: {
        visible: false,
        envelope: {},
        chartIdentifier: null,
    },
    setRunningSpeed: {
        visible: false,
        cpm: null,
        chartIdentifier: null,
    },
    setFaultFrequency: {
        visible: false,
        faultFrequency: {},
    },
    customBearingModal: {
        visible: false,
        bearing: {},
        extraData: {},
    },
    waterfallPlotModal: {
        visible: false,
        chartIdentifier: null,
        selectedUnits: 'Hz',
    },
    equipmentSpeedVisible: false,
    isVisibleManageBearings: false,
    isVisibleManageFaultFrequencies: false,
    isVisibleIdleThresholdModal: false,
    interpolationDisabled: localStorage.getItem('interpolationDisabled') === '1',
    harmonicByPoint: {
        peak: [],
        harmonics: [],
        chartIdentifier: null,
    },
};
export var ChartFeaturesStore = create()(devtools(function (set, get) { return (__assign(__assign({}, initialState), { setStates: function (states) {
        set(states, false, 'ChartFeaturesStoreSetStates');
    }, setInterpolationState: function (interpolationDisabled) {
        localStorage.setItem('interpolationDisabled', interpolationDisabled ? '1' : '0');
        set({ interpolationDisabled: interpolationDisabled }, false, 'ChartFeaturesStoreSetInterpolationState');
    }, setWaterfallPlotModalReadings: function (readingId, readingsListChartIdentifier, fftTimestamps) {
        var _a, _b;
        if (get().waterfallPlotModal.readingsListChartIdentifier !== readingsListChartIdentifier) {
            set({
                waterfallPlotModal: {
                    visible: false,
                    chartIdentifier: null,
                    readingsListChartIdentifier: readingsListChartIdentifier,
                    selectedUnits: 'Hz',
                    readingsList: [readingId],
                },
            });
            return true;
        }
        if ((_a = get().waterfallPlotModal.readingsList) === null || _a === void 0 ? void 0 : _a.includes(readingId)) {
            set({
                waterfallPlotModal: __assign(__assign({}, get().waterfallPlotModal), { readingsList: (_b = get().waterfallPlotModal.readingsList) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return id !== readingId; }) }),
            });
            return true;
        }
        if ((get().waterfallPlotModal.readingsList || []).length === 10) {
            toast.error("You can't select more than 10 readings", {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
            return false;
        }
        var updatedReadingList = _sortBy(__spreadArray(__spreadArray([], (get().waterfallPlotModal.readingsList || []), true), [readingId], false), function (readingId) {
            return _get(_find(_flatten(Object.values(fftTimestamps)), { readingFFT_id: +readingId }), 'timestamp');
        });
        set({
            waterfallPlotModal: __assign(__assign({}, get().waterfallPlotModal), { readingsListChartIdentifier: readingsListChartIdentifier, readingsList: updatedReadingList }),
        });
        return true;
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'ChartFeaturesStore' }));
