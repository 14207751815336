import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button } from "reactstrap";
import { shallow } from "zustand/shallow";
import { useExistingAiPreAiActions, } from "../../../../../modals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore";
import { InstallationPointStore } from "../../../../../store/charts/chartsContent/InstallationPointStore";
var AiBed = function () {
    var _a = InstallationPointStore(function (state) { return ({
        aisOnBed: state.aisOnBed,
        installationPoint: state.installationPoint,
    }); }, shallow), aisOnBed = _a.aisOnBed, installationPoint = _a.installationPoint;
    var _b = useExistingAiPreAiActions(), setIsVisible = _b.setIsVisible, setInstallationPointId = _b.setInstallationPointId, setEquipmentId = _b.setEquipmentId;
    return (_jsx("div", { children: aisOnBed.length > 0 ? (_jsx(Button, { outline: true, color: "customChartTopBarSelect", className: "btn-chart-tools", size: "sm", onClick: function () {
                var _a, _b;
                setIsVisible(true);
                setInstallationPointId(installationPoint.id);
                setEquipmentId((_b = (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null);
            }, children: "AiBed" })) : (_jsx(React.Fragment, {})) }));
};
export default React.memo(AiBed);
