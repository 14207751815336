import Api from '../../api';
export default {
    getCustomerStructure: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/structure', options);
    },
    getCustomerStructureEquipments: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/structure/equipments', options);
    },
};
