var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var leadAnalystKey = function () { return ['leadAnalyst']; };
var leadAnalystFn = function () {
    return Api.get('all-analytics/analyst-leads');
};
export var useLeadAnalystQuery = function (_a) {
    var customers = _a.customers;
    return useQuery({
        queryKey: leadAnalystKey(),
        queryFn: leadAnalystFn,
        select: function (_a) {
            var data = _a.data;
            return {
                data: data.map(function (analyst) {
                    return __assign(__assign({}, analyst), { customerAnalyticLeads: analyst.customerAnalyticLeads.filter(function (_a) {
                            var customer_id = _a.customer_id;
                            return customers.some(function (customer) { return customer.customer_id === customer_id; });
                        }) });
                }),
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!customers.length,
    });
};
