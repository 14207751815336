import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DateTimeLabel } from '../../../widgets/common';
export var config = function () {
    return {
        columns: [
            {
                name: 'created_by_user_name',
                title: 'User',
                sortable: true,
            },
            {
                name: 'time_created',
                title: 'Time',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { timeBreak: true, dateTime: row.time_created });
                },
            },
            {
                name: 'comment',
                title: 'Comment',
                sortable: true,
                visible: true,
                hiddenDefaultStyles: true,
                className: 'actionItemsComments-text',
                component: function (row) {
                    return _jsx("div", { dangerouslySetInnerHTML: { __html: row.comment } });
                },
            },
            {
                name: 'description',
                title: 'Description',
                sortable: true,
                visible: false,
                hiddenDefaultStyles: true,
                className: 'actionItemsComments-text',
                component: function (row) {
                    return _jsx("div", { dangerouslySetInnerHTML: { __html: row.description } });
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) { return (_jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { onClick: function () { return callbacks.onViewAi(row.action_list_id); }, color: "primary", size: "sm", children: "View\u00A0\u0410\u0406" }) }), _jsx(ButtonGroup, { children: _jsx(Button, { onClick: function () { return callbacks.onViewSensor(row.installation_point_id); }, color: "success", size: "sm", children: "View\u00A0Sensor" }) })] })); },
            },
        ],
        sort: [
            {
                field: 'time_created',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
    };
};
