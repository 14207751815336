import { useQuery } from '@tanstack/react-query';
import { head as _head } from 'lodash';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { selectPage, selectPageSize, selectSearch, selectSort, } from '../../store/useFftAlertLevelsTableStore';
export var fftAlertLevelsListKey = function (query) { return ['fftAlertLevelsList', query]; };
var fftAlertLevelsListFn = function (queryKey) {
    return Api.get('all-analytics/alert-conditions/fft-list', { query: queryKey[1] });
};
export var useFftAlertLevelsListQuery = function (_a) {
    var selectedCustomers = _a.selectedCustomers, selectedLocations = _a.selectedLocations;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var search = selectSearch();
    var page = selectPage();
    var pageSize = selectPageSize();
    var sort = selectSort();
    var query = {
        pagination: { page: page, pageSize: pageSize },
        sort: sort,
        search: search,
        selected_customer_ids: selectedCustomers,
        selected_location_id: _head(selectedLocations),
    };
    return useQuery({
        queryKey: fftAlertLevelsListKey(query),
        queryFn: function (_a) {
            var queryKey = _a.queryKey;
            return fftAlertLevelsListFn(queryKey);
        },
        select: function (resp) {
            return resp;
        },
        enabled: selectedSystemType === SYSTEM_TYPE_REMASTERED,
    });
};
