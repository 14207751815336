import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { DateTimeLabel } from '../../../../widgets/common';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import './index.scss';
var Timestamp = function (_a) {
    var updatedAt = _a.updatedAt, updatedBy = _a.updatedBy, timeCreated = _a.timeCreated, createdBy = _a.createdBy, remindDate = _a.remindDate, _b = _a.variant, variant = _b === void 0 ? '' : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    if (variant === 'column') {
        return (_jsxs(Row, { className: "ai-timestamp column", children: [updatedAt ? (_jsx(_Fragment, { children: _jsxs(Col, { col: 4, children: [_jsx("small", { children: "Updated:" }), _jsx("br", {}), _jsx(DateTimeLabel, { isShowRelativeTimeInTooltip: true, dateTime: updatedAt })] }) })) : (_jsx(_Fragment, {})), timeCreated ? (_jsxs(_Fragment, { children: [_jsxs(Col, { col: 4, children: [_jsx("small", { children: "Created:" }), _jsx("br", {}), _jsx(DateTimeLabel, { isShowRelativeTimeInTooltip: true, dateTime: timeCreated })] }), createdBy ? (_jsxs(Col, { col: 4, children: [_jsx("small", { children: "Created by:" }), _jsx("br", {}), createdBy] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
    }
    return (_jsxs("div", { className: "ai-timestamp", children: [updatedAt ? (_jsxs(_Fragment, { children: [_jsx("small", { children: "Updated:" }), " ", _jsx(DateTimeLabel, { isShowRelativeTimeInTooltip: true, dateTime: updatedAt }), updatedBy ? (_jsxs(_Fragment, { children: [_jsx("small", { children: "by" }), " ", updatedBy] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {})), timeCreated ? (_jsxs(_Fragment, { children: [_jsx("small", { children: "Created:" }), " ", _jsx(DateTimeLabel, { isShowRelativeTimeInTooltip: true, dateTime: timeCreated }), createdBy ? (_jsxs(_Fragment, { children: [_jsx("small", { children: "by" }), " ", createdBy] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {})), remindDate ? (_jsxs(_Fragment, { children: [_jsx("small", { children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Snoozed up to:' : 'Hold up to:' }), ' ', _jsx(DateTimeLabel, { isShowRelativeTimeInTooltip: true, dateTime: remindDate })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Timestamp);
