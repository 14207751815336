import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import PieChart from '../../AllStatisticTab/components/PieChart';
var Charts = function (_a) {
    var chartsData = _a.chartsData;
    var chartAnalystsConfig = chartsData.chartAnalystsConfig, teamsAiConfig = chartsData.teamsAiConfig, totalCreatedAiConfig = chartsData.totalCreatedAiConfig, totalAiConfig = chartsData.totalAiConfig;
    return (_jsxs(Row, { children: [_jsx(Col, { className: "mb-3", md: 6, children: _jsx(PieChart, { options: chartAnalystsConfig }) }), _jsx(Col, { className: "mb-3", md: 6, children: _jsx(PieChart, { options: teamsAiConfig }) }), _jsx(Col, { className: "mb-3", md: 6, children: _jsx(PieChart, { options: totalCreatedAiConfig }) }), _jsx(Col, { className: "mb-3", md: 6, children: _jsx(PieChart, { options: totalAiConfig }) })] }));
};
export default memo(Charts);
