import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
import DataTable from '../../../../components/shared/Table/Table';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { reducer } from '../../../../helper/reducer';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import '../../MachineInfo.scss';
import { initialState } from './config/dataTableConfig';
import { fetch } from './features';
var WwEquipmentInfoTab = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = InstallationPointStore(function (state) { return ({
        equipmentInfo: state.equipmentInfo,
        installationPoint: state.installationPoint,
    }); }, shallow), equipmentInfo = _a.equipmentInfo, installationPoint = _a.installationPoint;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var config = state.config;
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
            return;
        }
        fetch(state, dispatch, function () { return Endpoints.remastered.getLocationLogs(installationPoint.id); });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "mt-3", children: _jsx(Col, { children: _jsx("div", { className: "response-table", children: _jsx(DataTable, { config: {
                                columns: [
                                    {
                                        name: 'entity',
                                        title: '',
                                        sortable: false,
                                    },
                                    {
                                        name: 'data',
                                        title: '',
                                        sortable: false,
                                        component: function (row) {
                                            return row.data === null ? '---' : row.data;
                                        },
                                    },
                                ],
                                pagination: {
                                    disabled: true,
                                },
                                hideTotalLabel: true,
                                search: '',
                                disabledSearch: true,
                                data: equipmentInfo,
                                loader: false,
                            } }) }) }) }), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsxs(Row, { children: [_jsx("h4", { children: "Asset name/location change actions" }), _jsx("div", { className: "response-table", children: _jsx(DataTable, { config: config }) })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(WwEquipmentInfoTab);
