import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, reduce as _reduce } from "lodash";
import React from "react";
import { Badge, Col, Row, UncontrolledTooltip } from "reactstrap";
var AlertsRow = function (_a) {
    var client = _a.client, statistic = _a.statistic, isActiveAlertsStatistic = _a.isActiveAlertsStatistic, setIsActiveAlertsStatistic = _a.setIsActiveAlertsStatistic;
    var alertsCount = _reduce(_get(statistic, 'alerts.count') || [], function (sum, alert) { return sum + +alert.count; }, 0);
    if (!statistic) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Col, { md: 4, className: "card-statistic-title", children: _jsx("span", { children: "Alerts:" }) }), _jsxs(Col, { md: 5, className: "card-statistic-count", children: [_jsx(UncontrolledTooltip, { target: "alerts-total-".concat(client.customer_id), children: "Total" }), _jsx(Badge, { id: "alerts-total-".concat(client.customer_id), color: alertsCount > 0 ? 'info' : 'none', children: alertsCount })] }), alertsCount ? (_jsx(Col, { md: 3, className: "card-statistic-actions", children: _jsx(Badge, { onClick: function () { return setIsActiveAlertsStatistic(!isActiveAlertsStatistic); }, color: "primary", id: "show-alerts-statistic-".concat(client.customer_id), children: _jsx("i", { className: "fa ".concat(isActiveAlertsStatistic ? 'fa-arrow-up' : 'fa-arrow-down') }) }) })) : (_jsx(Col, { md: 3, className: "card-statistic-actions", children: _jsx(Badge, { children: _jsx("i", { className: "fa fa-arrow-down" }) }) }))] }), !!(_get(statistic, 'alerts.count') || []).length && isActiveAlertsStatistic ? (_jsx(Row, { children: _jsx(Col, { md: 12, className: "card-statistic-table", children: _jsx("table", { children: _jsx("tbody", { children: statistic.alerts.count.map(function (alert, index) { return (_jsx("tr", { children: _jsxs("td", { children: [_jsx("span", { children: alert.name }), _jsx("span", { children: "".concat(alert.count || 0) })] }) }, index)); }) }) }) }) })) : (_jsx(_Fragment, {}))] }));
};
export default AlertsRow;
