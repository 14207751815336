import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useId } from 'react';
import { ImBoxAdd } from 'react-icons/im';
import { Badge } from 'reactstrap';
import { PRE_ACTION_LIST } from '../constants';
import DefaultTooltip from '../shared/components/DefaultTooltip';
// @TODO: Need remove or rewrite component below
export var PreStatusLabel = function (_a) {
    var status = _a.status, _b = _a.isTech, isTech = _b === void 0 ? false : _b;
    var id = useId();
    return (_jsxs(Badge, { className: "position-relative", color: PRE_ACTION_LIST.STATUS_COLORS[status], children: [PRE_ACTION_LIST.STATUS_NAMES[status], isTech ? (_jsx("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '-7px',
                    right: '-10px',
                    backgroundColor: '#2962ff',
                    color: '#fff',
                    borderRadius: '50%',
                    width: '22px',
                    height: '22px',
                    fontSize: '14px',
                }, "data-tooltip-id": id, children: _jsx(ImBoxAdd, {}) })) : (_jsx(_Fragment, {})), _jsx(DefaultTooltip, { place: "left", openEvents: { mouseenter: true }, id: id, children: "Pre-AI created via InstallApp" })] }));
};
export var PreUrgencyLabel = function (_a) {
    var urgency = _a.urgency;
    if (!urgency) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Badge, { color: PRE_ACTION_LIST.URGENCY_COLORS[urgency], children: PRE_ACTION_LIST.URGENCY_NAMES[urgency] }));
};
