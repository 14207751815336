var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { deletePlotBand, setPlotBands, } from '../../../components/charts/chartsWrapper/components/shared/ChartSelect';
import { clearPaintingFigures } from '../../../components/charts/chartsWrapper/features/shared/painting';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useSelectProblemChart = function (state, dispatch, chartIdentifier, chartRef) {
    var _a = ChartsStore(function (state) { return ({
        chartsSelectedRanges: state.chartsSelectedRanges,
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedPainting: state.chartsSelectedPainting,
        setStatesChartsStore: state.setStates,
    }); }, shallow), chartsSelectedRanges = _a.chartsSelectedRanges, selectedChartsAvailable = _a.selectedChartsAvailable, chartsSelectedPainting = _a.chartsSelectedPainting, setStatesChartsStore = _a.setStatesChartsStore;
    var options = state.options;
    useEffect(function () {
        if (selectedChartsAvailable[chartIdentifier]) {
            dispatch({
                type: 'setState',
                state: {
                    options: __assign(__assign({}, options), { chart: __assign(__assign({}, options.chart), { zoomType: 'x', selectionMarkerFill: chartsSelectedPainting[chartIdentifier]
                                ? 'rgba(255, 217, 56, 0)'
                                : 'rgba(255, 217, 56, 0.15)', events: __assign(__assign({}, options.chart.events), { selection: function (event) {
                                    if (chartsSelectedPainting[chartIdentifier]) {
                                        return true;
                                    }
                                    setPlotBands(dispatch, options, {
                                        min: event.xAxis[0].min,
                                        max: event.xAxis[0].max,
                                    }, chartIdentifier, chartsSelectedRanges, setStatesChartsStore);
                                    return false;
                                } }) }), xAxis: [
                            __assign(__assign({}, options.xAxis[0]), { zoomEnabled: !chartsSelectedPainting[chartIdentifier] }),
                        ] }),
                },
            });
        }
        if (selectedChartsAvailable[chartIdentifier] === false &&
            chartsSelectedRanges[chartIdentifier]) {
            deletePlotBand(dispatch, options, chartIdentifier, chartsSelectedRanges, setStatesChartsStore, selectedChartsAvailable);
        }
        if (selectedChartsAvailable[chartIdentifier] === false) {
            clearPaintingFigures(chartRef);
            chartRef.current.chart.userOptions.paintingData.isPaintingEnabled = false;
        }
    }, [
        selectedChartsAvailable[chartIdentifier],
        chartsSelectedPainting[chartIdentifier],
        chartsSelectedRanges,
    ]);
};
