import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { nameplatesKey } from './useNameplatesQuery';
var nameplateAddFn = function (coreLink, id, nameplateId) {
    return Api.post("".concat(coreLink, "/").concat(id, "/nameplate/").concat(nameplateId), {});
};
export var useNameplateAddMutation = function (installationPointId) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var coreLink = _a.coreLink, id = _a.id, nameplateId = _a.nameplateId;
            return nameplateAddFn(coreLink, id, nameplateId);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: nameplatesKey(installationPointId) });
        },
    });
};
