import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import { ACTIVITY_LOG_SIZE_FULL, ACTIVITY_LOG_SIZE_HIDE, ACTIVITY_LOG_SIZE_LARGE, ACTIVITY_LOG_SIZE_MEDIUM, ACTIVITY_LOG_SIZE_SMALL, selectActivityLogSize, useActionItemViewActions, } from '../../../../../store/AIPreAi/useActionItemViewStore';
var ActivityLogToggleSize = function () {
    var activityLogSize = selectActivityLogSize();
    var setActivityLogSize = useActionItemViewActions().setActivityLogSize;
    return (_jsx("div", { className: "d-flex gap-2", children: activityLogSize === ACTIVITY_LOG_SIZE_HIDE ? (_jsx("div", { className: "activity-log-header-toggle", onClick: function () { return setActivityLogSize(ACTIVITY_LOG_SIZE_SMALL); }, children: _jsx(TbLayoutSidebarLeftCollapse, {}) })) : activityLogSize === ACTIVITY_LOG_SIZE_MEDIUM ||
            activityLogSize === ACTIVITY_LOG_SIZE_SMALL ||
            activityLogSize === ACTIVITY_LOG_SIZE_LARGE ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-toggle", onClick: function () { return setActivityLogSize(++activityLogSize); }, children: _jsx(TbLayoutSidebarLeftCollapse, {}) }), _jsx("div", { className: "activity-log-header-toggle", onClick: function () { return setActivityLogSize(--activityLogSize); }, children: _jsx(TbLayoutSidebarLeftExpand, {}) })] })) : activityLogSize === ACTIVITY_LOG_SIZE_FULL ? (_jsx("div", { className: "activity-log-header-toggle", onClick: function () { return setActivityLogSize(ACTIVITY_LOG_SIZE_LARGE); }, children: _jsx(TbLayoutSidebarLeftExpand, {}) })) : (_jsx(_Fragment, {})) }));
};
export default memo(ActivityLogToggleSize);
