import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var locationsKey = function (facilityId) { return ['locations', facilityId]; };
var locationsFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("all-analytics/facility/".concat(queryKey[1], "/locations"));
};
export var useLocationsQuery = function (_a) {
    var facilityId = _a.facilityId;
    return useQuery({
        queryKey: locationsKey(facilityId),
        queryFn: locationsFn,
        select: function (_a) {
            var data = _a.data;
            return {
                locations: data !== null && data !== void 0 ? data : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!facilityId,
    });
};
