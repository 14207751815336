import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Badge } from 'reactstrap';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
import { getLabelData } from '../features';
var DetachedLabel = function (_a) {
    var existInList = _a.existInList;
    var data = getLabelData(existInList, 'detachedList');
    return (_jsx(_Fragment, { children: data ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { color: "secondary", children: "Detached" }) }) })) : (_jsx(_Fragment, {})) }));
};
export default React.memo(DetachedLabel);
