var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep } from 'lodash';
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onShowChartImages = function (row, setImagesModalData) {
    setImagesModalData({
        visible: true,
        images: row.charts.map(function (chart) { return chart.image; }) || [],
    });
};
export var setSelectedReadingTypes = function (state, dispatch, selectedReadingTypes) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { selectedReadingTypes: selectedReadingTypes }) }),
        },
    });
};
export var onClickFilterTag = function (state, dispatch, tag) {
    var config = state.config;
    var updatedSelectedTags = _cloneDeep(config.filtersData ? config.filtersData.selectedTags : []) || [];
    if (updatedSelectedTags.includes(tag)) {
        updatedSelectedTags.splice(updatedSelectedTags.indexOf(tag), 1);
    }
    else {
        updatedSelectedTags.push(tag);
    }
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { selectedTags: updatedSelectedTags, selectedReadingTypes: updatedSelectedTags.length
                        ? config.filtersData
                            ? config.filtersData.selectedReadingTypes
                            : []
                        : [] }) }),
        },
    });
};
export var onDeleteBadDataItem = function (endpoint, id, setConfirmData, callback, setLoader) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want remove bad data item?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            setLoader();
            endpoint(id).then(function (resp) {
                if (resp.success) {
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    return callback();
                }
                toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            });
        },
    });
};
