import Api from '../../api';
export default {
    getIdleThresholdLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/idle-threshold-log', options);
    },
    getIdleThresholdLogCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/idle-threshold-log/csv', options);
    },
};
