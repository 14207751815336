var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { initialState } from '../../config/watchersStatistic/preAiStatistic';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault, getGroupedData, getStatisticByState, groupListByDays, } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticPreAiKey = function (dateRange) {
    var _a, _b;
    return [
        'statisticPreAi',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
    ];
};
var statisticPreAiFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/pre-ai"), {
        query: {
            'separate-by-team': 1,
            date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'),
            date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'),
        },
    });
};
var allStatisticChartsConfig = initialState.allStatisticChartsConfig, stateChartsConfig = initialState.stateChartsConfig, pieChartOptions = initialState.pieChartOptions, initialData = initialState.data;
export var useStatisticPreAiQuery = function () {
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticPreAiKey(dateRange),
        queryFn: function (data) { return statisticPreAiFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h;
            var data = _a.data;
            return {
                pieChartOptions: pieChartOptions,
                data: (_b = data === null || data === void 0 ? void 0 : data.analysts) !== null && _b !== void 0 ? _b : initialData,
                allStatisticChartsConfig: __assign(__assign({}, allStatisticChartsConfig), { series: [
                        {
                            name: 'All Pre-Ai Counts',
                            data: groupListByDays(getGroupedData(data), 'created_at'),
                        },
                        {
                            name: 'UA Pre-Ai Counts',
                            data: groupListByDays(__spreadArray([], ((_d = (_c = data === null || data === void 0 ? void 0 : data.analysts) === null || _c === void 0 ? void 0 : _c.UA_team) !== null && _d !== void 0 ? _d : []), true), 'created_at'),
                        },
                        {
                            name: 'USA Pre-Ai Counts',
                            data: groupListByDays(__spreadArray([], ((_f = (_e = data === null || data === void 0 ? void 0 : data.analysts) === null || _e === void 0 ? void 0 : _e.USA_team) !== null && _f !== void 0 ? _f : []), true), 'created_at'),
                        },
                        // {
                        //     name: "PHL Pre-Ai Counts",
                        //     data: groupListByDays([...(data?.analysts?.PHL_team ?? [])], "created_at")
                        // },
                        {
                            name: 'ML-bot Pre-Ai Counts',
                            data: groupListByDays(__spreadArray([], ((_h = (_g = data === null || data === void 0 ? void 0 : data.analysts) === null || _g === void 0 ? void 0 : _g.ML_bot_system) !== null && _h !== void 0 ? _h : []), true), 'created_at'),
                        },
                    ].filter(function (el) { return el.data.length; }) }),
                stateChartsConfig: __assign(__assign({}, stateChartsConfig), { series: [
                        __assign(__assign({}, stateChartsConfig.series[0]), { data: getStatisticByState(getGroupedData(data)) }),
                    ].filter(function (el) { return el.data.length; }) }),
            };
        },
    });
};
