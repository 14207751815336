var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, head as _head, uniq as _uniq } from 'lodash';
import React, { memo, useCallback, useEffect, useReducer, useState, } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FaCheckCircle, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Alert, Button, Col, Input, Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { ReactComponent as Radiator } from '../../../assets/svg/radiator.svg';
import { useTitle } from '../../../customHooks';
import DefaultTooltip from '../../../shared/components/DefaultTooltip';
import Loader from '../../../shared/components/Loader';
import { reducer } from '../../../shared/helper/reducer';
import { IntervalsContent } from '../../components/installationPoint/IntervalsContent';
import HighPassForm from '../../modals/installationPoint/HighPassForm';
import ImpactVueForm from '../../modals/installationPoint/ImpactVueForm';
import IntervalsForm from '../../modals/installationPoint/IntervalsForm';
import { useHeatsinkBatchUpdateMutation, } from '../../services/heatsink/useHeatsinkBatchUpdateMutation';
import { selectSelectedCustomers } from '../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../store/SelectedLocationsStore';
import './index.scss';
var initialState = {
    data: [],
    pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
    },
    lastDataLength: 0,
    onScroll: false,
    search: '',
    loader: true,
};
var fetch = function (state, dispatch, selectedCustomers, selectedLocations, triggeredByScroll) {
    if (triggeredByScroll === void 0) { triggeredByScroll = false; }
    var pagination = state.pagination, search = state.search, data = state.data;
    if (!selectedCustomers.length) {
        return;
    }
    dispatch({
        type: 'setState',
        state: {
            loader: !triggeredByScroll,
        },
    });
    Endpoints.remastered
        .getInstallationPointsSettings({
        query: {
            selected_customer_ids: selectedCustomers,
            selected_location_id: _head(selectedLocations),
            search: search,
            pagination: __assign({}, pagination),
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    data: triggeredByScroll ? __spreadArray(__spreadArray([], data, true), resp.data, true) : resp.data,
                    loader: false,
                    onScroll: false,
                    lastDataLength: resp.data.length,
                    pagination: __assign(__assign({}, pagination), { total: resp.total }),
                },
            });
        }
    })
        .catch(function () {
        dispatch({
            type: 'setState',
            state: {
                loader: false,
                onScroll: false,
            },
        });
        toast.error('Something went wrong', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    });
};
var SensorIntervalsPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Sensor Intervals');
    var _c = useHeatsinkBatchUpdateMutation(), heatsinkBatchUpdateMutate = _c.mutate, heatsinkBatchUpdateIsLoading = _c.isPending;
    var _d = useReducer(reducer, initialState), state = _d[0], dispatch = _d[1];
    var _e = useState({
        visible: false,
        installationPoint: undefined,
        equipment: undefined,
        serials: [],
        onCancel: function () {
            setIntervalsFormData(function (prevState) { return (__assign(__assign({}, prevState), { visible: false, installationPoint: undefined, equipment: undefined, serials: [] })); });
        },
    }), intervalsFormData = _e[0], setIntervalsFormData = _e[1];
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _f = useState({
        visible: false,
        equipment: undefined,
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        onCancel: function () {
            setHighPassFormData(function (prevState) { return (__assign(__assign({}, prevState), { visible: false, equipment: undefined })); });
        },
    }), highPassFormData = _f[0], setHighPassFormData = _f[1];
    var _g = useState({
        visible: false,
        equipment: undefined,
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        onCancel: function () {
            setImpactVueFormData(function (prevState) { return (__assign(__assign({}, prevState), { visible: false, equipment: undefined })); });
        },
    }), impactVueFormData = _g[0], setImpactVueFormData = _g[1];
    var search = state.search, pagination = state.pagination, loader = state.loader, data = state.data, lastDataLength = state.lastDataLength, onScroll = state.onScroll;
    var page = pagination.page, pageSize = pagination.pageSize;
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                pagination: __assign(__assign({}, pagination), { page: 1, pageSize: 20 }),
            },
        });
        setHighPassFormData(__assign(__assign({}, highPassFormData), { selectedCustomers: selectedCustomers, selectedLocations: selectedLocations }));
        setImpactVueFormData(__assign(__assign({}, impactVueFormData), { selectedCustomers: selectedCustomers, selectedLocations: selectedLocations }));
    }, [selectedCustomers, selectedLocations]);
    var getData = useCallback(function () {
        if (!selectedCustomers) {
            return;
        }
        fetch(state, dispatch, selectedCustomers, selectedLocations, onScroll);
    }, [refreshFlag, selectedCustomers, selectedLocations, page, pageSize, search]);
    useEffect(function () {
        getData();
    }, [getData]);
    if (!selectedCustomers.length) {
        return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "table_wrap", children: [_jsx("h3", { className: "page-title", children: "Sensor Intervals" }), _jsx(Row, { children: _jsx(Col, { md: 3, children: _jsx(Alert, { color: "danger", className: "mt-3", children: "Choose customer first" }) }) })] }) }) }) }));
    }
    var onScrollFetch = function (values) {
        if (onScroll) {
            return;
        }
        var scrollTop = values.scrollTop, scrollHeight = values.scrollHeight, clientHeight = values.clientHeight;
        var pad = 30; // 100px of the bottom
        var scrollIndex = (scrollTop + pad) / (scrollHeight - clientHeight);
        if (scrollIndex > 1 && !loader && lastDataLength >= pageSize) {
            dispatch({
                type: 'setState',
                state: {
                    pagination: __assign(__assign({}, pagination), { page: page + 1 }),
                    onScroll: true,
                },
            });
        }
    };
    var onSearch = function (search) {
        dispatch({
            type: 'setState',
            state: {
                pagination: __assign(__assign({}, pagination), { page: 1 }),
                search: search,
            },
        });
    };
    var heatsinkHandler = function (isHeatsink, equipmentId) {
        heatsinkBatchUpdateMutate({
            isHeatsink: !isHeatsink,
            selectedCustomers: selectedCustomers,
            selectedLocations: selectedLocations,
            equipmentId: equipmentId,
        }, {
            onSuccess: function () {
                toast.success('Successfully changed!', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                fetch(state, dispatch, selectedCustomers, selectedLocations, onScroll);
            },
            onError: function () {
                toast.error('Something went wrong!', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
            },
        });
    };
    return (_jsxs("div", { className: "page-sensorIntervals with-loader", children: [_jsxs("h3", { className: "page-title mt-4 d-flex justify-content-between", children: ["Sensor Intervals", _jsx("div", { className: "search-block ms-2", children: _jsx(Input, { onChange: function (ev) { return onSearch(ev.target.value); }, value: search, placeholder: "Search Sensor Intervals" }) })] }), _jsx(Row, { className: "fz-12 position-relative", children: _jsxs(Col, { children: [loader ? (_jsx("div", { style: {
                                left: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                zIndex: 1,
                                backdropFilter: 'blur(2px)',
                                width: '100%',
                                height: 'calc(100vh - 140px)',
                            }, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), _jsxs(Row, { className: "header-block fw-bold p-2", style: { zIndex: 1 }, children: [_jsx(Col, { md: 2, children: "ALL EQUIPMENT" }), _jsx(Col, { md: 1, children: "INTERVALS" }), _jsx(Col, { md: 1, children: "FFT" }), _jsx(Col, { md: 1, children: "FMAX" }), _jsx(Col, { md: 1, children: "LOR" }), _jsxs(Col, { className: "fz-11 d-flex flex-wrap align-items-center", md: 1, children: ["RMS HIGH-PASS", _jsx(Button, { title: "Edit rms high-pass for all selected equipments", color: "primary", size: "xs", className: "ms-1", onClick: function () {
                                                return setHighPassFormData(__assign(__assign({}, highPassFormData), { equipment: undefined, selectedLocations: selectedLocations, selectedCustomers: selectedCustomers, visible: true }));
                                            }, children: _jsx(FaPencilAlt, {}) })] }), _jsxs(Col, { md: 1, children: ["ImpactVue", _jsx(Button, { title: "Edit ImpactVue state for all selected equipments", color: "primary", size: "xs", className: "ms-2", onClick: function () {
                                                return setImpactVueFormData(__assign(__assign({}, impactVueFormData), { equipment: undefined, selectedLocations: selectedLocations, selectedCustomers: selectedCustomers, visible: true }));
                                            }, children: _jsx(FaPencilAlt, {}) })] }), _jsx(Col, { md: 1 })] }), data.length !== 0 ? (_jsxs(Scrollbars, { onUpdate: onScrollFetch, className: "mb-2", style: { width: '100%', height: 'calc(100vh - 185px)' }, children: [data.map(function (equipment) { return (_jsxs("div", { children: [_jsxs("div", { className: "d-flex header-block p-2 fw-bold mt-1", children: [_jsx(Col, { md: 6, children: equipment.name }), _jsx(Col, { md: 1, children: _jsx(Button, { title: "Edit rms high-pass for ".concat(equipment.name, " equipment"), color: "primary", size: "xs", className: "ms-4 float-start", onClick: function () {
                                                            return setHighPassFormData(__assign(__assign({}, highPassFormData), { equipment: equipment, selectedLocations: [], visible: true }));
                                                        }, children: _jsx(FaPencilAlt, {}) }) }), _jsx(Col, { md: 1, children: (equipment.installationPoints || []).some(function (installationPoint) { return !!_get(installationPoint, 'sensor.supportHfdvue'); }) ? (_jsx(_Fragment, { children: _jsx(Button, { title: "Edit ImpactVue state for ".concat(equipment.name, " equipment"), color: "primary", size: "xs", className: "ms-4 float-start", onClick: function () {
                                                                return setImpactVueFormData(__assign(__assign({}, impactVueFormData), { equipment: equipment, selectedLocations: selectedLocations, selectedCustomers: selectedCustomers, visible: true }));
                                                            }, children: _jsx(FaPencilAlt, {}) }) })) : (_jsx(_Fragment, {})) }), _jsx(Col, { md: 2, children: _jsx(Button, { title: "Edit settings for ".concat(equipment.name, " equipment"), color: "primary", size: "xs", className: "ms-2 float-end", onClick: function () {
                                                            return setIntervalsFormData(__assign(__assign({}, intervalsFormData), { visible: true, equipment: equipment, installationPoint: undefined, serials: _uniq(equipment.installationPoints
                                                                    .map(function (installationPoint) { return _get(installationPoint, 'sensor.node.serial'); })
                                                                    .filter(function (item) { return item; })) }));
                                                        }, children: _jsx(FaPencilAlt, {}) }) }), _jsxs(Col, { md: 1, children: [_jsx("span", { className: "float-end", children: _jsx(Radiator, { "data-tooltip-id": "radiators_".concat(equipment.id), onClick: function () {
                                                                    return heatsinkHandler(equipment.installationPoints.every(function (_a) {
                                                                        var is_heatsink = _a.is_heatsink;
                                                                        return !!is_heatsink;
                                                                    }), equipment.id);
                                                                }, style: {
                                                                    width: '18px',
                                                                    float: 'right',
                                                                    marginRight: '10px',
                                                                    cursor: heatsinkBatchUpdateIsLoading ? 'wait' : 'pointer',
                                                                    fill: equipment.installationPoints.every(function (_a) {
                                                                        var is_heatsink = _a.is_heatsink;
                                                                        return !!is_heatsink;
                                                                    })
                                                                        ? '#2962ff'
                                                                        : '#777',
                                                                    opacity: heatsinkBatchUpdateIsLoading ? 0.6 : 1,
                                                                } }) }), _jsx(DefaultTooltip, { id: "radiators_".concat(equipment.id), place: "top", children: "Insulator" })] }), _jsx(Col, { md: 1 })] }), equipment.installationPoints.map(function (installationPoint) {
                                            var _a;
                                            return (_jsx(IntervalsContent, { isSupportImpactVue: (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorParameters) === null || _a === void 0 ? void 0 : _a.supportHfdvue, speed: equipment.current_speed || installationPoint.speed, textToHighlight: search, installationPoint: installationPoint, onEdit: function () {
                                                    return setIntervalsFormData(__assign(__assign({}, intervalsFormData), { visible: true, installationPoint: installationPoint, equipment: undefined, serials: [_get(installationPoint, 'sensor.node.serial', '---')] }));
                                                }, onUpdateSensorImpactVue: function () {
                                                    getData();
                                                } }, "interval_row_".concat(installationPoint.id)));
                                        })] }, "equipments_settings_".concat(equipment.id))); }), lastDataLength >= pageSize ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {}))] })) : (_jsx(React.Fragment, { children: !loader ? _jsx("p", { className: "text-center", children: "There are no tracking components" }) : _jsx(_Fragment, {}) }))] }) }), _jsx(IntervalsForm, { intervalsFormData: intervalsFormData, callback: function () {
                    fetch(state, dispatch, selectedCustomers, selectedLocations, onScroll);
                } }), _jsx(HighPassForm, { highPassFormData: highPassFormData, callback: function () {
                    fetch(state, dispatch, selectedCustomers, selectedLocations, onScroll);
                } }), impactVueFormData.visible && (_jsx(ImpactVueForm, { impactVueFormData: impactVueFormData, onSuccess: function () {
                    fetch(state, dispatch, selectedCustomers, selectedLocations, onScroll);
                } }))] }));
};
export default memo(SensorIntervalsPage);
