export var updatePositionOnImageFlip = function (positions, current, flipTurn) {
    var currentPositionKeys = Object.keys(positions[current] || {});
    if (currentPositionKeys.length) {
        currentPositionKeys.forEach(function (key) {
            var _a, _b;
            var cTop = positions[current][key].top / positions[current][key].percent_top;
            var cLeft = positions[current][key].left / positions[current][key].percent_left;
            var percentLeft, percentTop;
            if (flipTurn === 0.25) {
                percentTop = ((_a = positions[current][key]) === null || _a === void 0 ? void 0 : _a.percent_left) || 0;
                percentLeft = 100 - positions[current][key].percent_top;
            }
            else {
                percentLeft = ((_b = positions[current][key]) === null || _b === void 0 ? void 0 : _b.percent_top) || 0;
                percentTop = 100 - positions[current][key].percent_left;
            }
            positions[current][key].percent_top = percentTop;
            positions[current][key].percent_left = percentLeft;
            positions[current][key].top = Math.round(cLeft * percentTop);
            positions[current][key].left = Math.round(cTop * percentLeft);
        });
    }
    return positions;
};
