import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, memo } from 'react';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { tabs } from '../../../config/watchersStatistic';
import { selectDateRange } from '../../../store/watchersStatistic/useDateRangeStore';
import TabNavLink from '../TabNavLink';
var Tabs = function (_a) {
    var activeTabTitle = _a.activeTabTitle, setActiveTabTitle = _a.setActiveTabTitle, queryAdditionProperties = _a.queryAdditionProperties;
    var dateRange = selectDateRange();
    if (!dateRange) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx(TabNavLink, { activeTabTitle: activeTabTitle, setActiveTabTitle: setActiveTabTitle }), _jsx(TabContent, { activeTab: activeTabTitle, children: tabs.map(function (el) { return (_jsx(Fragment, { children: activeTabTitle === el.title ? (_jsx(TabPane, { tabId: el.title, children: el.component(queryAdditionProperties) })) : (_jsx(_Fragment, {})) }, el.title)); }) })] }) }) }));
};
export default memo(Tabs);
