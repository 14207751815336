var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { shallow } from "zustand/shallow";
import Endpoints from "../../../../api/endpoints/endpoints";
import { METRIC_KEY, SYSTEM_TYPE_REMASTERED } from "../../../../constants";
import SensorsByEquipment from "../../../../shared/components/SensorsByEquipment";
import UserSourceBadge from "../../../../shared/components/UserSourceBadge";
import DataTable from "../../../../shared/components/shared/Table/Table";
import { SystemTypeContext } from "../../../../shared/context/SystemTypeContext";
import { ChartFeaturesStore } from "../../../../shared/store/charts/chartsContent/ChartFeaturesStore";
import { InstallationPointStore } from "../../../../shared/store/charts/chartsContent/InstallationPointStore";
import { selectMeasure } from "../../../../shared/store/global/useGlobalStore";
import AlertLogContent from "../../../../widgets/alertLogContent";
import { DateTimeLabel } from "../../../../widgets/common";
import { isSupportZAxis } from "../../../../widgets/sensor";
import AxisType from "../AxisType";
import { getAvailableChartIdentifiersByReadingType } from "../helpers";
var initialConfig = function (selectedSystemType) {
    return {
        columns: [
            {
                name: 'label',
                title: '',
                sortable: false,
                component: function (row) {
                    var permissions = _get(row, 'user.permissions') || [];
                    return (_jsx("span", { className: "mt-3", children: _jsx(UserSourceBadge, { permissions: permissions }) }));
                },
            },
            {
                name: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'customer_name' : 'facility',
                title: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Customer' : 'Facility',
                sortable: false,
            },
            {
                name: 'sensor_hex',
                title: 'Sensor',
                sortable: false,
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
            },
            {
                name: 'old_data',
                title: 'Old data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: row.val2, cautionValue: +_get(row.old_data, 'levels.caution'), warningValue: +_get(row.old_data, 'levels.warning') }));
                },
            },
            {
                name: 'new_data',
                title: 'New data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: row.val2, isNewData: true, cautionValue: +_get(row.new_data, 'levels.caution'), warningValue: +_get(row.new_data, 'levels.warning') }));
                },
            },
            {
                name: 'date_create',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "default-alert-log-".concat(row.id), dateTime: row.date_create });
                },
            },
            {
                name: 'user_name',
                title: 'User',
                sortable: false,
            },
        ],
        sort: [
            {
                field: 'date_create',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
        },
        search: '',
        disabledSearch: true,
        hideTotalLabel: true,
        data: [],
        loader: false,
    };
};
var fetch = function (config, setConfig, setSmallLoader, selectedInstallationPointId, selectedReadingTypeIds, selectedSystemType, measure) {
    setSmallLoader(true);
    Endpoints[selectedSystemType]
        .getAlertLogBySensor({
        query: {
            installationPointId: selectedInstallationPointId,
            readingTypes: selectedReadingTypeIds,
            selected_facility_metric: METRIC_KEY[measure],
        },
    })
        .then(function (resp) {
        setSmallLoader(false);
        if (resp) {
            setConfig(__assign(__assign({}, config), { data: resp.data }));
        }
    });
};
var AlertLogTab = function (_a) {
    var _b;
    var setSmallLoader = _a.setSmallLoader, smallLoader = _a.smallLoader;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var changeAlertConditions = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
    }); }, shallow).changeAlertConditions, readingTypeId = changeAlertConditions.readingTypeId;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }, shallow), equipment = _get(installationPoint, 'equipment') || {};
    var _c = useState(installationPoint.id), selectedInstallationPointId = _c[0], setSelectedInstallationPointId = _c[1];
    var _d = useState([readingTypeId]), selectedReadingTypeIds = _d[0], setSelectedReadingTypeIds = _d[1];
    var _e = useState(initialConfig(selectedSystemType)), config = _e[0], setConfig = _e[1];
    var chartIdentifiers = getAvailableChartIdentifiersByReadingType(readingTypeId);
    var measure = selectMeasure();
    useEffect(function () {
        fetch(config, setConfig, setSmallLoader, selectedInstallationPointId, selectedReadingTypeIds, selectedSystemType, measure);
    }, [selectedReadingTypeIds, selectedInstallationPointId]);
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "g-1", children: _jsxs(Col, { md: 6, children: [_jsx(SensorsByEquipment, { selectedInstallationPointId: selectedInstallationPointId, setSelectedInstallationPointId: setSelectedInstallationPointId, isDisabled: smallLoader, equipment: equipment }), chartIdentifiers.length > 1 && (_jsx(AxisType, { isDisabled: smallLoader, selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: setSelectedReadingTypeIds, chartIdentifiers: chartIdentifiers, isSupportZAxis: isSupportZAxis((_b = installationPoint.sensor) === null || _b === void 0 ? void 0 : _b.version_type) }))] }) }), _jsx(Row, { className: "g-1 mt-2", children: _jsx(Col, { children: _jsx(DataTable, { config: config }) }) })] }));
};
export default AlertLogTab;
