import { createStore } from '../createStore';
var usePreAiModalStore = createStore(function (set) { return ({
    activeData: null,
    actions: {
        setActiveData: function (activeData) { return set({ activeData: activeData }); },
    },
}); });
export var usePreAiModalActions = function () {
    return usePreAiModalStore(function (state) { return state.actions; });
};
export var selectActiveData = function () {
    return usePreAiModalStore(function (state) { return state.activeData; });
};
