import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import DatePicker from 'react-datepicker';
import { Badge } from 'reactstrap';
import { DateInput, DateTimeLabel } from '../../../widgets/common';
import JsonData from '../../components/JsonData';
export var config = {
    columns: [
        {
            name: 'type',
            title: 'Type',
            sortable: true,
            component: function (row) {
                return _jsx(Badge, { color: "primary", children: row.type });
            },
        },
        {
            name: 'point_name',
            title: 'Point Name',
            sortable: true,
            component: function (row) {
                return row.type === 'equipment' ? '---' : row.point_name;
            },
        },
        {
            name: 'equipment_name',
            title: 'Equipment Name',
            sortable: true,
        },
        {
            name: 'old_data',
            title: 'Old Data',
            sortable: false,
            component: function (row) {
                return _jsx(JsonData, { id: row.id, data: row.old_data }, "old");
            },
        },
        {
            name: 'new_data',
            title: 'New Data',
            sortable: false,
            component: function (row) {
                return _jsx(JsonData, { id: row.id, data: row.new_data }, "new");
            },
        },
        {
            name: 'created_at',
            title: 'Time',
            sortable: true,
            component: function (row) {
                return _jsx(DateTimeLabel, { id: "idle_threshold_log_".concat(row.id), dateTime: row.created_at });
            },
        },
        {
            name: 'user_name',
            title: 'User',
            sortable: true,
        },
    ],
    sort: [
        {
            field: 'created_at',
            direction: 'desc',
        },
    ],
    filtersData: {
        dateRange: [],
    },
    filters: function (callbacks, filtersData) {
        return (_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: callbacks.onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }));
    },
    pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
    },
    search: '',
    data: [],
    loader: true,
};
