import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import DeclinedReportAi from '../../../../shared/components/dashboard/cards/DeclinedReportAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
var DeclinedReportAiCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'DeclinedReportAiAmazon';
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'DeclinedReportAi', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(DeclinedReportAi, { deps: [selectedFacility, refreshFlag], tableName: tableName, tableColumns: [], endpoint: function (hideHardwareAi, sort) {
                    return Endpoints.amazon.getDashboardDeclinedReportActionList({
                        query: {
                            selected_facility: selectedFacility,
                            hide_hardware_ai: +hideHardwareAi,
                            sort: sort,
                            onlyActive: 1,
                        },
                    });
                } })] }));
};
export default DeclinedReportAiCardWrapper;
