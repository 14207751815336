import { createStore } from '../createStore';
var useReportEditorTableStore = createStore(function (set, get) { return ({
    search: '',
    page: 1,
    pageSize: 20,
    sort: [
        {
            field: 'name',
            direction: 'asc',
        },
    ],
    actions: {
        setSearch: function (search) { return set({ search: search }); },
        setPage: function (page) { return set({ page: page }); },
        setPageSize: function (pageSize) { return set({ pageSize: pageSize }); },
        setSort: function (field) {
            var _a;
            if (field === null) {
                return set({ sort: [] });
            }
            var currentSort = get().sort;
            if (!currentSort || currentSort.length === 0) {
                return set({
                    sort: [
                        {
                            activeFieldSort: field,
                            field: field,
                            direction: 'asc',
                        },
                    ],
                });
            }
            return set({
                sort: [
                    {
                        activeFieldSort: field,
                        field: field,
                        direction: ((_a = currentSort[0]) === null || _a === void 0 ? void 0 : _a.direction) === 'asc' ? 'desc' : 'asc',
                    },
                ],
            });
        },
    },
}); });
export var useReportEditorTableActions = function () {
    return useReportEditorTableStore(function (state) { return state.actions; });
};
export var selectPage = function () {
    return useReportEditorTableStore(function (state) { return state.page; });
};
export var selectSearch = function () {
    return useReportEditorTableStore(function (state) { return state.search; });
};
export var selectPageSize = function () {
    return useReportEditorTableStore(function (state) { return state.pageSize; });
};
export var selectSort = function () {
    return useReportEditorTableStore(function (state) { return state.sort; });
};
