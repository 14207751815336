import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
export var useDeleteSeverityIncrease = function (_a) {
    var _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b, actionListId = _a.actionListId, _c = _a.confirmationText, confirmationText = _c === void 0 ? 'Are you sure you want to delete this item?' : _c, approveSeverityIncreaseMutate = _a.approveSeverityIncreaseMutate, declineSeverityIncreaseMutate = _a.declineSeverityIncreaseMutate, onSuccess = _a.onSuccess;
    if (!actionListId) {
        throw new Error('`actionListId` should be number');
    }
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var deleteSeverityIncrease = function (action) {
        var mutate = action === 'approve' ? approveSeverityIncreaseMutate : declineSeverityIncreaseMutate;
        setConfirmData({
            isVisible: false,
        });
        mutate(isPreAi ? { pre_action_list_id: actionListId } : { action_list_id: actionListId }, {
            onSuccess: function () {
                toast.success('Successfully remove', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                onSuccess();
            },
        });
    };
    var confirmDeleteSeverityIncrease = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: confirmationText,
            confirmText: 'Accept',
            additionalBtns: [
                {
                    text: 'Reject',
                    outline: false,
                    color: 'danger',
                    onAction: function () {
                        deleteSeverityIncrease('decline');
                    },
                },
            ],
            onConfirm: function () {
                deleteSeverityIncrease('approve');
            },
        });
    };
    return { confirmDeleteSeverityIncrease: confirmDeleteSeverityIncrease };
};
