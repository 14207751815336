import Highcharts from 'highcharts';
var symbolWrap = function (proceed, symbol, x, y, w, h) {
    if (symbol.indexOf('text:') === 0) {
        var text = symbol.split(':')[1], 
        // @ts-ignore
        svgElem = this.text(text, x, y + h).css({
            fontFamily: 'FontAwesome',
            fontSize: '16px',
            strokeWidth: '1px',
        });
        if (svgElem.renderer.isVML) {
            svgElem.fillSetter = function (value, key, element) {
                // @ts-ignore
                element.style.color = Highcharts.Color(value).get('rgb');
            };
        }
        return svgElem;
    }
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return proceed.apply(this, [].slice.call(arguments, 1));
};
export var initSymbols = function () {
    // @ts-ignore
    Highcharts.wrap(Highcharts.SVGRenderer.prototype, 'symbol', symbolWrap);
    // @ts-ignore
    if (Highcharts.VMLRenderer) {
        // @ts-ignore
        Highcharts.wrap(Highcharts.VMLRenderer.prototype, 'symbol', symbolWrap);
    }
};
