import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useState } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import { SYSTEM_TYPE_AMAZON } from '../../../constants';
import { SensorId } from '../../../widgets/sensor';
import NameplatesTab from '../../components/Nameplates/Nameplates';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import './MachineInfo.scss';
import ActionItemsTab from './tabs/ActionItems/ActionItems';
import AnalystNotesTab from './tabs/AnalystNotes';
import AvgReadingsTab from './tabs/AvgReadings/AvgReadings';
import FirmwareTab from './tabs/Firmware';
import HistoryTab from './tabs/History/History';
import ImagesTab from './tabs/Images';
import IntervalsTab from './tabs/Intervals/Intervals';
import MapTab from './tabs/Map/Map';
import RunningHoursTab from './tabs/RunningHours/RunningHours';
import WwEquipmentInfoTab from './tabs/WwEquipmentInfo/WwEquipmentInfo';
var MachineInfoModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel, title = _a.title, options = _a.options, additionalNoteParam = _a.additionalNoteParam;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }), equipment = _get(installationPoint, 'equipment') || {}, sensor = _get(installationPoint, 'sensor') || {}, customerId = _get(installationPoint, 'customer_id') || {};
    var tabContentMapping = [
        {
            component: _jsx(ActionItemsTab, {}),
            title: 'Action Items',
        },
        {
            component: _jsx(HistoryTab, {}),
            title: 'History',
        },
        {
            component: _jsx(AnalystNotesTab, { options: options, additionalNoteParam: additionalNoteParam }),
            title: 'Analyst Notes',
        },
        {
            component: _jsx(ImagesTab, {}),
            title: 'Images',
        },
        {
            component: _jsx(NameplatesTab, { installationPoint: installationPoint }),
            title: 'RPM/Machine info',
        },
        {
            component: _jsx(AvgReadingsTab, {}),
            title: 'AVG Readings',
        },
        {
            component: (_jsx(IntervalsTab, { equipment_id: equipment.id, equipmentName: equipment.name, sensor: sensor, customerId: customerId })),
            title: 'Intervals',
        },
        {
            component: _jsx(WwEquipmentInfoTab, {}),
            title: 'Waites Equipment Information',
        },
        {
            component: _jsx(FirmwareTab, {}),
            hidden: selectedSystemType === SYSTEM_TYPE_AMAZON,
            title: 'Firmware Updates',
        },
        {
            component: _jsx(RunningHoursTab, {}),
            hidden: selectedSystemType === SYSTEM_TYPE_AMAZON,
            title: 'Running Hours',
        },
        {
            component: _jsx(MapTab, {}),
            title: 'Map',
        },
    ];
    var _b = useState(tabContentMapping[0].title), activeTab = _b[0], setActiveTab = _b[1];
    if (!visible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Modal, { className: "modal-machine-info modal-response", size: "xxl", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Machine information: ", title, " - ", _jsx(SensorId, { sensor_id: +sensor.sensor_id })] }), _jsxs(ModalBody, { style: { minHeight: '500px' }, children: [_jsx(Nav, { tabs: true, children: tabContentMapping.map(function (el) {
                            return el.hidden ? (_jsx(_Fragment, {})) : (_jsx(NavItem, { children: _jsx(NavLink, { active: activeTab === el.title, onClick: function () { return setActiveTab(el.title); }, children: el.title }) }, el.title));
                        }) }), _jsx(TabContent, { activeTab: activeTab, children: tabContentMapping.map(function (el) { return (_jsx(TabPane, { tabId: el.title, children: el.title === activeTab ? el.component : _jsx(_Fragment, {}) }, el.title)); }) })] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default memo(MachineInfoModal);
