import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { FFT_ALERT_TYPES } from '../../../../../../../constants';
import '../../../../../../../widgets/alertConditionsSettingsLabel/AlertConditionsSettingsLabel.scss';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
export var FftAlertConditionsSettingsLabel = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    var fftAlerts = FftChartsStore(function (state) { return state.fftAlerts; });
    var fftAlertLevels = _get(fftAlerts, chartIdentifier, []);
    var fftAlertLevelsWithSettings = fftAlertLevels.filter(function (fftAlertLevel) { return fftAlertLevel.setting; });
    var shouldDisplay = fftAlertLevelsWithSettings.some(function (fftAlertLevelWithSettings) {
        return fftAlertLevelWithSettings.setting.is_ai || fftAlertLevelWithSettings.setting.is_pre_ai;
    });
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return state.chartAlertLevelsVisibleToggle; });
    if (chartAlertLevelsVisibleToggle || !shouldDisplay) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { id: "alert-conditions-settings-label-".concat(chartIdentifier), className: "alert-conditions-settings-label", color: "warning", children: _jsx(FaRegLightbulb, { size: 15 }) }), _jsx(UncontrolledTooltip, { className: "alert-conditions-settings-label-tooltip", placement: "bottom", target: "alert-conditions-settings-label-".concat(chartIdentifier), children: fftAlertLevelsWithSettings.map(function (fftAlertLevelsWithSettings, index) {
                    var _a, _b, _c;
                    return (_jsx(React.Fragment, { children: fftAlertLevelsWithSettings.setting.is_ai || fftAlertLevelsWithSettings.setting.is_pre_ai ? (_jsxs("div", { children: ["".concat((_a = fftAlertLevelsWithSettings.setting.user) === null || _a === void 0 ? void 0 : _a.name_first, " ").concat((_b = fftAlertLevelsWithSettings.setting.user) === null || _b === void 0 ? void 0 : _b.name_last), ":\u00A0", fftAlertLevelsWithSettings.fft_alert_type === FFT_ALERT_TYPES.BAND ? 'Band' : 'Envelope', "\u00A0", (_c = fftAlertLevelsWithSettings.alertLevel) === null || _c === void 0 ? void 0 : _c.name, "\u00A0"] })) : (_jsx(_Fragment, {})) }, "alert-conditions-settings-label-".concat(chartIdentifier, "-").concat(index)));
                }) })] }));
};
export default memo(FftAlertConditionsSettingsLabel);
