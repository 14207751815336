import { valibotResolver } from '@hookform/resolvers/valibot';
import { custom, forward, object, string } from 'valibot';
export var awaitingUpdateSchema = valibotResolver(object({
    comment: string(),
}, [
    forward(custom(function (_a) {
        var comment = _a.comment;
        return comment === '' || /^[^\u0400-\u04FF]+$/.test(comment);
    }, 'You cannot use Cyrillic characters in comment'), ['comment']),
]));
