import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { nameplatesListKey } from './useNameplatesQuery';
var nameplateSaveFn = function (data, selectedCustomerId) {
    return Api.post('all-analytics/nameplate', data, { query: { selected_customer_ids: [selectedCustomerId] } });
};
export var useNameplateSaveMutation = function (selectedCustomerId) {
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (_a) {
            var data = _a.data;
            return nameplateSaveFn(data, selectedCustomerId);
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: nameplatesListKey(selectedCustomerId) });
        },
    });
};
