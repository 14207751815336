// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-section span {
  color: #f64213;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/NotificationSection/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ","sourcesContent":[".notification-section {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  & span {\n    color: #f64213;\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
