import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var fftAlertCreateFn = function (coreLink, installationPointId, data) {
    return Api.post("".concat(coreLink, "/alert-conditions/fft/").concat(installationPointId), data);
};
export var useFftAlertLevelCreateMutation = function (installationPointId) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useMutation({
        mutationFn: function (data) {
            return fftAlertCreateFn(getCoreUrl(selectedSystemType), installationPointId, data);
        },
        onSuccess: function () { },
    });
};
