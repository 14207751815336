var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { find as _find, size as _size } from 'lodash';
export var onPageChange = function (page, state, dispatch, callback) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { pagination: __assign(__assign({}, state.config.pagination), { page: page }), loader: true }),
        },
    });
    if (state.config.pagination) {
        state.config.pagination.page = page;
    }
    resetHighlight();
    return callback();
};
export var onPageSizeChange = function (pageSize, state, dispatch, callback) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { pagination: __assign(__assign({}, state.config.pagination), { pageSize: pageSize }), loader: true }),
        },
    });
    if (state.config.pagination) {
        state.config.pagination.pageSize = pageSize;
        state.config.pagination.page = 1;
    }
    resetHighlight();
    return callback();
};
export var getSortConfig = function (column_name, state, isShift) {
    if (column_name === null) {
        return [];
    }
    if (!state.config.sort || state.config.sort.length === 0) {
        return [
            {
                activeFieldSort: column_name,
                field: column_name,
                direction: 'asc',
            },
        ];
    }
    if (isShift) {
        var newSortState_1 = [];
        state.config.sort.forEach(function (sortItem) {
            if (sortItem.field !== column_name) {
                newSortState_1.push(sortItem);
                return;
            }
            newSortState_1.push(__assign(__assign({}, sortItem), { direction: !sortItem.direction || sortItem.direction === 'desc' ? 'asc' : 'desc' }));
        });
        if (_size(_find(state.config.sort, { field: column_name })) === 0) {
            newSortState_1.push({
                activeFieldSort: column_name,
                field: column_name,
                direction: 'asc',
            });
        }
        return newSortState_1;
    }
    return [
        {
            activeFieldSort: column_name,
            field: column_name,
            direction: state.config.sort && state.config.sort[0].direction === null
                ? 'asc'
                : state.config.sort && state.config.sort[0].direction === 'asc'
                    ? 'desc'
                    : 'asc',
        },
    ];
};
export var onSort = function (column_name, state, dispatch, callback, ev) {
    var newSortState = getSortConfig(column_name, state, ev.shiftKey);
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { sort: newSortState, loader: true }),
        },
    });
    state.config.sort = newSortState;
    resetHighlight();
    return callback();
};
export var onSearch = function (search, state, dispatch, callback) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { pagination: __assign(__assign({}, state.config.pagination), { page: 1 }), search: search, loader: true }),
        },
    });
    state.config.search = search;
    if (state.config.pagination) {
        state.config.pagination.page = 1;
    }
    resetHighlight();
    return callback();
};
export var resetHighlight = function () {
    var rows = document.getElementsByTagName('tr');
    Object.values(rows).map(function (row) { return row.classList.remove('active-row'); });
};
export var highlightRow = function (ev) {
    resetHighlight();
    if (ev) {
        ev.target.closest('tr').classList.add('active-row');
    }
};
export var setLoader = function (state, dispatch, loader) {
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { loader: loader }),
        },
    });
};
