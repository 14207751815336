// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-select-dropdown .dropdown-toggle {
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 4px 10px;
  text-transform: uppercase;
}
.status-select-dropdown .dropdown-toggle::after {
  margin-left: 20px;
  vertical-align: 3.5px;
}
.status-select-dropdown .dropdown-toggle::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 60%;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  background: #fff;
}
.status-select-dropdown .dropdown-item .label-status {
  font-size: 16px;
  font-weight: 500;
  color: #0b84ff;
}
.status-select-dropdown .dropdown-item .to-status {
  margin-top: 2px;
  display: flex;
  align-items: center;
  font-size: 11px;
}
.status-select-dropdown .dropdown-item .to-status svg {
  margin: 0 7px;
}
.status-select-dropdown .dropdown-item .to-status b {
  color: #507dec;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/StatusSelect/index.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,qBAAA;EACA,iBAAA;EACA,yBAAA;AAAJ;AACI;EACE,iBAAA;EACA,qBAAA;AACN;AACI;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;AACN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AADN;AAGI;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AADN;AAEM;EACE,aAAA;AAAR;AAEM;EACE,cAAA;AAAR","sourcesContent":[".status-select-dropdown {\n  & .dropdown-toggle {\n    font-weight: 500;\n    letter-spacing: 0.2px;\n    padding: 4px 10px;\n    text-transform: uppercase;\n    &::after {\n      margin-left: 20px;\n      vertical-align: 3.5px;\n    }\n    &::before {\n      position: absolute;\n      content: '';\n      width: 1px;\n      height: 60%;\n      right: 30px;\n      top: 50%;\n      transform: translateY(-50%);\n      border-radius: 2px;\n      background: #fff;\n    }\n  }\n  & .dropdown-item {\n    & .label-status {\n      font-size: 16px;\n      font-weight: 500;\n      color: #0b84ff;\n    }\n    & .to-status {\n      margin-top: 2px;\n      display: flex;\n      align-items: center;\n      font-size: 11px;\n      & svg {\n        margin: 0 7px;\n      }\n      & b {\n        color: #507dec;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
