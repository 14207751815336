import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var bearingsKey = function (installationPointId, search, pagination, selfBearings, mfr) { return ['bearings', installationPointId, { search: search, pagination: pagination, selfBearings: selfBearings, mfr: mfr }]; };
var bearingsFn = function (coreLink, queryKey) {
    return Api.get("".concat(coreLink, "/bearing"), {
        query: {
            search: queryKey[2].search,
            self_bearings: +queryKey[2].selfBearings,
            mfr: queryKey[2].mfr,
            installation_point_id: queryKey[1],
            pagination: queryKey[2].pagination,
        },
    });
};
export var useBearingsQuery = function (_a) {
    var installationPointId = _a.installationPointId, search = _a.search, pagination = _a.pagination, selfBearings = _a.selfBearings, mfr = _a.mfr;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: bearingsKey(installationPointId, search, pagination, selfBearings, mfr),
        queryFn: function (_a) {
            var queryKey = _a.queryKey;
            return bearingsFn(getCoreUrl(selectedSystemType), queryKey);
        },
        select: function (_a) {
            var list = _a.list, selected = _a.selected, total = _a.total;
            return {
                list: list,
                selected: selected,
                total: total,
            };
        },
        placeholderData: keepPreviousData,
        enabled: !!installationPointId,
    });
};
