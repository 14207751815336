import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useState } from 'react';
import { FaCheck, FaCheckCircle, FaEdit, FaFileVideo, FaTimes, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge } from 'reactstrap';
import { ACTION_LIST, AVATAR_WW } from '../../../../../../../constants';
import { DateTimeLabelWithUTC } from '../../../../../../../widgets/common';
import User from '../../../../../../helper/user';
import { selectEditComment, useActionItemViewActions, } from '../../../../../../store/AIPreAi/useActionItemViewStore';
import { useConfirmModalActions } from '../../../../../../store/global/useConfirmModalStore';
import TranslatesToggleBtn from '../../../../../AIPreAi/TranslatesToggleBtn';
import { getTranslates } from '../../../../../AIPreAi/TranslatesToggleBtn/features';
import HighlighterWord from '../../../../../shared/HighlighterWord';
import './index.scss';
var params = new URLSearchParams(window.location.search);
var RegularComment = function (_a) {
    var comment = _a.comment, user_id = _a.user_id, userImage = _a.userImage, id = _a.id, user_name = _a.user_name, time_created = _a.time_created, text_updated_at = _a.text_updated_at, is_pre_comment = _a.is_pre_comment, is_deleted = _a.is_deleted, deleted_by = _a.deleted_by, time_delete = _a.time_delete, translates = _a.translates, attachments = _a.attachments, is_gathering_data = _a.is_gathering_data, postscript = _a.postscript, type = _a.type, isViewMode = _a.isViewMode, isTroubleshoot = _a.isTroubleshoot, commentDeleteMutate = _a.commentDeleteMutate, internalCommentDeleteMutate = _a.internalCommentDeleteMutate, deletePreCommentMutate = _a.deletePreCommentMutate, confirmPreCommentMutate = _a.confirmPreCommentMutate, confirmPreCommentIsLoading = _a.confirmPreCommentIsLoading, deletePreCommentIsLoading = _a.deletePreCommentIsLoading, updatePreCommentIsLoading = _a.updatePreCommentIsLoading, commentUpdateIsLoading = _a.commentUpdateIsLoading, internalCommentUpdateIsLoading = _a.internalCommentUpdateIsLoading, commentDeleteIsLoading = _a.commentDeleteIsLoading, internalCommentDeleteIsLoading = _a.internalCommentDeleteIsLoading;
    var actionItemId = useParams().id;
    var highlightSearch = params.get('search');
    var username = _get(User.get(), 'full_name');
    var editComment = selectEditComment();
    var _b = useState(false), isShowTranslates = _b[0], setIsShowTranslates = _b[1];
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _c = useActionItemViewActions(), setEditComment = _c.setEditComment, setIsGatheringData = _c.setIsGatheringData, setIsInternalComment = _c.setIsInternalComment;
    var onUpdate = function () {
        setEditComment({
            comment: comment,
            id: id,
            is_pre_comment: is_pre_comment,
            type: type,
        });
        setIsGatheringData(false);
        setIsInternalComment(false);
    };
    var onConfirm = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to confirm pre-comment?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                confirmPreCommentMutate({
                    id: id,
                    action_list_id: actionItemId ? +actionItemId : null,
                }, {
                    onSuccess: function () {
                        toast.success('The pre-comment has been successfully confirmed.', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                    },
                });
            },
        });
    };
    var onDelete = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to delete comment?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                if (is_pre_comment === 1) {
                    return deletePreCommentMutate({
                        id: id,
                    }, {
                        onSuccess: function () {
                            toast.success('The pre-comment has been successfully deleted.', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                }
                if (type === ACTION_LIST.COMMENT_TYPE_INTERNAL) {
                    return internalCommentDeleteMutate({
                        id: id,
                    }, {
                        onSuccess: function () {
                            toast.success('The internal comment has been successfully deleted.', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                }
                commentDeleteMutate({
                    id: id,
                }, {
                    onSuccess: function () {
                        toast.success('The comment has been successfully deleted.', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                    },
                });
            },
        });
    };
    return (_jsxs("div", { className: "activity-log-regular-comment", children: [_jsxs("div", { className: "d-flex", children: [user_id === 1 ? (_jsx("div", { className: "activity-log-regular-comment-icon", style: { background: '#ffcd04' }, children: _jsx("img", { src: AVATAR_WW, alt: "WW" }) })) : (_jsx("div", { className: "activity-log-regular-comment-icon", children: user_id === ACTION_LIST.AMD_USERS_ID && userImage ? (_jsx("img", { src: userImage, alt: "User" })) : (_jsx("img", { src: AVATAR_WW, alt: "WW" })) })), _jsxs("div", { className: "activity-log-regular-comment-user-info", children: [_jsxs("div", { className: "activity-log-regular-comment-name-btns", children: [_jsxs("div", { className: "d-flex align-items-center", children: [user_name, text_updated_at ? _jsxs("i", { children: [" ", is_deleted === 1 ? '(Deleted)' : '(Edited)'] }) : _jsx(_Fragment, {})] }), _jsx("span", { children: (user_id === 1 || user_name === 'System') &&
                                            is_deleted !== 1 &&
                                            !isViewMode &&
                                            !isTroubleshoot ? (!editComment ? (_jsxs(_Fragment, { children: [is_pre_comment === 1 ? (_jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Confirm pre-comment", onClick: function () { return (confirmPreCommentIsLoading ? {} : onConfirm()); }, children: _jsx(FaCheck, {}) })) : (_jsx(_Fragment, {})), user_name === username || user_name === 'System' || is_pre_comment === 1 ? (_jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Update comment", onClick: function () {
                                                        return commentUpdateIsLoading ||
                                                            updatePreCommentIsLoading ||
                                                            internalCommentUpdateIsLoading
                                                            ? {}
                                                            : onUpdate();
                                                    }, children: _jsx(FaEdit, {}) })) : (_jsx(_Fragment, {})), _jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Delete comment", onClick: function () {
                                                        return commentDeleteIsLoading || deletePreCommentIsLoading || internalCommentDeleteIsLoading
                                                            ? {}
                                                            : onDelete();
                                                    }, children: _jsx(FaTrash, {}) })] })) : (_jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Undo editing", onClick: function () {
                                                setConfirmData({
                                                    isVisible: true,
                                                    textConfirmationModal: 'Are you sure you want to deny the action? All changes in the comment will be lost',
                                                    onConfirm: function () {
                                                        setConfirmData({
                                                            isVisible: false,
                                                        });
                                                        setEditComment(null);
                                                    },
                                                });
                                            }, children: _jsx(FaTimes, {}) }))) : (_jsx(_Fragment, {})) })] }), _jsx("div", { className: "activity-log-regular-comment-date", children: _jsx(DateTimeLabelWithUTC, { dateTime: time_created }) }), _jsxs("div", { className: "activity-log-regular-comment-statuses", children: [is_pre_comment == 1 ? (_jsx(Badge, { pill: true, color: "danger", size: "sm", className: "me-1", children: "Pre-Comment" })) : (_jsx(_Fragment, {})), is_gathering_data === 1 ? (_jsx(Badge, { pill: true, color: "warning", size: "sm", children: "Gathering Data" })) : (_jsx(_Fragment, {})), type === ACTION_LIST.COMMENT_TYPE_INTERNAL ? (_jsx(Badge, { pill: true, color: "primary", size: "sm", children: "Analytic comment" })) : (_jsx(_Fragment, {})), is_deleted !== 1 &&
                                        (postscript === 'amd' ||
                                            postscript === 'customer' ||
                                            postscript === 'analytic' ||
                                            postscript === 'internal') ? (_jsxs(Badge, { pill: true, color: postscript === 'amd' || postscript === 'customer' ? 'primary' : 'info', size: "sm", children: [postscript === 'amd' || postscript === 'customer' ? 'EXTERNAL' : 'INTERNAL', " comment"] })) : (_jsx(_Fragment, {}))] })] })] }), _jsx("div", { className: "activity-log-regular-comment-content zoomImg-comments", children: is_deleted !== 1 ? (_jsxs(_Fragment, { children: [_jsx(HighlighterWord, { searchWords: highlightSearch || '', textToHighlight: isShowTranslates ? getTranslates(translates || {}, 'comment') : comment }), _jsx(TranslatesToggleBtn, { fieldName: 'comment', fieldNameId: 'comment_' + id, translates: translates || {}, buttonStyle: { top: '-10px', right: '-10px' }, isShowTranslates: isShowTranslates, setIsShowTranslates: setIsShowTranslates }), attachments.length ? (_jsx("span", { children: attachments.map(function (attachment) { return (_jsx("span", { children: _jsxs("a", { target: "_blank", rel: "noreferrer", href: attachment.url, children: [_jsx(FaFileVideo, {}), attachment.name] }) }, "attachment".concat(id, "_").concat(attachment.id))); }) })) : (_jsx(_Fragment, {}))] })) : (_jsxs("p", { children: ["This comment was deleted by ", _jsx("b", { children: deleted_by || '' }), _jsx("br", {}), " at", ' ', _jsx("i", { children: _jsx(DateTimeLabelWithUTC, { dateTime: time_delete }) })] })) })] }));
};
export default memo(RegularComment);
