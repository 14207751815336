import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { ACTION_LIST } from '../../../constants';
import { DateTimeLabelWithUTC } from '../../../widgets/common';
var SlaValues = function (_a) {
    var facility = _a.facility, state = _a.state, _b = _a.inline, inline = _b === void 0 ? false : _b;
    var settings = _get(facility, ['slaSettingsFormatted', state], {});
    var isChangedStandardSla = _get(settings, [ACTION_LIST.URGENCY_STANDARD, 'username'], 'Default') !== 'Default';
    var isChangedHighSla = _get(settings, [ACTION_LIST.URGENCY_HIGH, 'username'], 'Default') !== 'Default';
    var isChangedCriticalSla = _get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'username'], 'Default') !== 'Default';
    return (_jsxs(_Fragment, { children: [_jsxs("div", { id: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_STANDARD), className: (isChangedStandardSla ? 'cursor-pointer text-primary tdu' : 'cursor-pointer') +
                    (inline ? ' d-inline-block ms-2' : ''), children: ["Standard - ", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_STANDARD, 'sla'], '---') }), "/", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_STANDARD, 'priority'], '---') })] }), _jsxs(UncontrolledTooltip, { target: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_STANDARD), children: [_get(settings, [ACTION_LIST.URGENCY_STANDARD, 'username'], 'Default'), "\u00A0", isChangedStandardSla ? (_jsx(DateTimeLabelWithUTC, { withLineBreak: true, fromNow: true, dateTime: _get(settings, [ACTION_LIST.URGENCY_STANDARD, 'updated_at'], '') ||
                            _get(settings, [ACTION_LIST.URGENCY_STANDARD, 'date_create'], '') })) : (_jsx(_Fragment, {}))] }), _jsxs("div", { id: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_HIGH), className: (isChangedHighSla ? 'cursor-pointer text-primary tdu' : 'cursor-pointer') +
                    (inline ? ' d-inline-block ms-2' : ''), children: ["High - ", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_HIGH, 'sla'], '---') }), "/", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_HIGH, 'priority'], '---') })] }), _jsxs(UncontrolledTooltip, { target: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_HIGH), children: [_get(settings, [ACTION_LIST.URGENCY_HIGH, 'username'], 'Default'), "\u00A0", isChangedHighSla ? (_jsx(DateTimeLabelWithUTC, { withLineBreak: true, fromNow: true, dateTime: _get(settings, [ACTION_LIST.URGENCY_HIGH, 'updated_at'], '') ||
                            _get(settings, [ACTION_LIST.URGENCY_HIGH, 'date_create'], '') })) : (_jsx(_Fragment, {}))] }), _jsxs("div", { id: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_CRITICAL), className: (isChangedCriticalSla ? 'cursor-pointer text-primary tdu' : 'cursor-pointer') +
                    (inline ? ' d-inline-block ms-2' : ''), children: ["Critical - ", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'sla'], '---') }), "/", _jsx("b", { children: _get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'priority'], '---') })] }), _jsxs(UncontrolledTooltip, { target: "sla_value_".concat(facility.facility_id, "_").concat(state, "_").concat(ACTION_LIST.URGENCY_CRITICAL), children: [_get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'username'], 'Default'), "\u00A0", isChangedCriticalSla ? (_jsx(DateTimeLabelWithUTC, { withLineBreak: true, fromNow: true, dateTime: _get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'updated_at'], '') ||
                            _get(settings, [ACTION_LIST.URGENCY_CRITICAL, 'date_create'], '') })) : (_jsx(_Fragment, {}))] })] }));
};
export default memo(SlaValues);
