import { each as _each, size as _size } from 'lodash';
export var onFacilityChange = function (state, dispatch, facility) {
    var selectedLocation = state.selectedLocation;
    dispatch({
        type: 'setState',
        state: {
            selectedLocations: facility.children,
            selectedLocation: facility.fullServiceEquipmentsCount > 0 ? facility : selectedLocation,
        },
    });
};
export var buildLocationsTree = function (structure, locationsTree) {
    structure.map(function (facility) {
        if (facility.level_name === 'facility') {
            var isFacilityHasEquipments_1 = facility.fullServiceEquipmentsCount > 0;
            var checkThatFacilityHasEquipments_1 = function (items) {
                if (isFacilityHasEquipments_1) {
                    return true;
                }
                _each(items, function (item) {
                    if (item.fullServiceEquipmentsCount > 0) {
                        isFacilityHasEquipments_1 = true;
                        return true;
                    }
                    if (_size(item.children)) {
                        checkThatFacilityHasEquipments_1(item.children);
                    }
                });
            };
            checkThatFacilityHasEquipments_1(facility.children);
            if (!isFacilityHasEquipments_1) {
                return;
            }
        }
        if (facility.level_name && facility.level_name !== 'customer' && facility.level_name !== 'area') {
            locationsTree.push({
                name: facility.name,
                id: facility.id,
                facility_id: facility.facility_id,
                customer_id: facility.customer_id,
                fullServiceEquipmentsCount: facility.fullServiceEquipmentsCount,
                children: facility.children,
            });
        }
        if (_size(facility.children)) {
            buildLocationsTree(facility.children, locationsTree);
        }
    });
    return locationsTree;
};
