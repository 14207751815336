var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get as _get, max as _max, size as _size } from 'lodash';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { METRIC_KEY, READING_TYPE_TEMPERATURE } from '../../../../../../constants';
import { isMote } from '../../../../../../widgets/sensor';
import { getRmsAccVelExtraType } from '../../../../../features/charts/shared';
import { getTimezone } from '../../../../../features/shared';
import { getUoms, isAccelerationChart, isAccelerationImpactVue, isCfChart, isPinChart, } from '../../../../../helper/chart';
import { copyTextToClipboard } from '../../../../../helper/dom';
import { toggleRmsCursor } from '../shared/cursor';
import { calculateMaxYAxisValue, calculateMinYAxisValue } from '../shared/extremes';
import { calculateSize } from '../shared/size';
import { getAlertConditionsConfig } from './alertConditions';
import { getZones } from './zones';
export var fetch = function (chartIdentifier, installationPoint, dispatch, countDays, showSystemLevels, showMlSuggestedLevels, selectedSystemType, measure) {
    if (!installationPoint.id) {
        return;
    }
    dispatch({
        type: 'setState',
        state: {
            loader: true,
            isAutoAlertConditions: false,
            ambientDataIsVisible: false,
            normalizedReadingsIsVisible: false,
            seriesIsRendered: false,
            isWakeOnShake: false,
            hidePeaksOptions: {
                peak: null,
            },
        },
    });
    var timezone = getTimezone(installationPoint, selectedSystemType);
    Endpoints[selectedSystemType]
        .getRmsReadings(installationPoint.id, chartIdentifier, countDays, {
        query: {
            dateFrom: '',
            dateTo: '',
            show_system_levels: +showSystemLevels,
            show_ml_suggested_levels: +showMlSuggestedLevels,
            selected_facility_metric: METRIC_KEY[measure],
            timezone: timezone,
        },
    })
        .then(function (resp) {
        if (resp) {
            return dispatch({
                type: 'setState',
                state: {
                    isAutoAlertConditions: _get(resp.alertConditions, 'isAuto'),
                    readings: resp.readings,
                    ambient: resp.ambient || [],
                    chartMarks: resp.chartMarks,
                    alertConditions: resp.alertConditions,
                    isWakeOnShake: resp.isWakeOnShake,
                    mainSeriesLoaded: true,
                },
            });
        }
        dispatch({
            type: 'setState',
            state: {
                mainSeriesLoaded: true,
            },
        });
    })
        .catch(function () {
        dispatch({
            type: 'setState',
            state: {
                mainSeriesLoaded: true,
            },
        });
    });
};
export var setOptions = function (chartIdentifier, installationPoint, state, dispatch, setStatesChartStore, chartAlertLevelsVisibleToggle, setStatesChartFeaturesStore, readingTypes, chartView, measure, personalSettingMeasure, axisLabelName) {
    var options = state.options, readings = state.readings, ambient = state.ambient, alertConditions = state.alertConditions, isVisibleFullscreenChart = state.isVisibleFullscreenChart;
    var isCustomChartType = _get(readingTypes, [chartIdentifier, 'isCustom']);
    var seriesData = readings.filter(function (el) { return el[1] !== null; });
    var values = seriesData.map(function (item) { return item[1]; });
    var maxValue = _max(values);
    var customChartTypeMaxValue = maxValue < 0 ? 0 : maxValue;
    var size = calculateSize(chartView);
    if (isVisibleFullscreenChart) {
        size = {
            height: window.innerHeight * 0.85,
            width: window.innerWidth - 80,
        };
    }
    var series = _size(seriesData) === 0
        ? []
        : [
            {
                id: "rms_".concat(chartIdentifier, "_").concat(installationPoint.id),
                isMainSeries: true,
                installationPointId: installationPoint.id,
                isOverlay: false,
                data: seriesData,
                archiveData: seriesData,
                color: '#7cb5ec',
                sensorId: installationPoint.sensor_id,
                legendIndex: 0,
                name: installationPoint.name || "".concat(installationPoint.component, " - ").concat(installationPoint.point_name),
                units: getUoms(chartIdentifier, measure, personalSettingMeasure, readingTypes),
                chartType: chartIdentifier,
                precision: _get(readingTypes, [chartIdentifier, 'precision']),
                visible: true,
                showInLegend: true,
                events: {
                    legendItemClick: function (e) {
                        e.preventDefault();
                        // @ts-ignore
                        var overlayNames = this.chart.series
                            .filter(function (item) { return item.hasRendered; })
                            .map(function (item) { return item.name; })
                            .join(', ');
                        copyTextToClipboard(overlayNames, 'Name copied to clipboard');
                    },
                },
                dataGrouping: {
                    approximation: 'high',
                    forced: true,
                },
                zones: chartAlertLevelsVisibleToggle
                    ? []
                    : isCustomChartType
                        ? []
                        : getZones(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure),
                initialZones: chartAlertLevelsVisibleToggle
                    ? []
                    : isCustomChartType
                        ? []
                        : getZones(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure),
            },
        ];
    if (chartIdentifier === READING_TYPE_TEMPERATURE && ambient.length > 0) {
        var name = '';
        if (isMote(_get(installationPoint, 'sensor.version_type'), _get(installationPoint, 'sensor.node.lot_data'))) {
            name = 'Ambient temperature from Router';
        }
        else if (_get(installationPoint, 'sensor.node.serial')) {
            name = 'Ambient from Node ' + _get(installationPoint, 'sensor.node.serial');
        }
        series = __spreadArray(__spreadArray([], series, true), [
            {
                id: 'ambientData',
                // @ts-ignore
                visible: false,
                name: name,
                data: ambient,
                units: getUoms(READING_TYPE_TEMPERATURE, measure, personalSettingMeasure),
                chartType: 1,
                showInLegend: false,
                precision: readingTypes[READING_TYPE_TEMPERATURE].precision,
                dataGrouping: {
                    approximation: 'high',
                    forced: true,
                },
                color: '#7e27ff',
                //@ts-ignore
                dashStyle: 'Solid',
                zones: [],
            },
        ], false);
    }
    else {
        series = __spreadArray([
            {
                // @ts-ignore
                visible: false,
                name: '',
                data: [],
                showInLegend: false,
                //@ts-ignore
                dashStyle: 'Solid',
            }
        ], series, true);
    }
    return dispatch({
        type: 'setState',
        state: {
            isDataAvailable: _size(seriesData) !== 0,
            options: __assign(__assign({}, options), { title: {
                    text: axisLabelName,
                }, chart: __assign(__assign(__assign({}, options.chart), size), { events: __assign(__assign({}, options.chart.events), { click: function (ev) {
                            if (_get(ev.target, 'classList').contains('view-all-overlays-btn')) {
                                //Clicked view all btn
                                ev.preventDefault();
                                dispatch({
                                    type: 'setState',
                                    state: {
                                        isVisibleFullscreenChart: true,
                                    },
                                });
                                return;
                            }
                            if (['button', 'i'].includes(_get(ev.target, 'localName')) ||
                                [
                                    'fft-labels-shape ',
                                    'selected-shape',
                                    'highcharts-label-box highcharts-annotation-label-box',
                                ].includes(_get(ev.target, 'className.animVal') || _get(ev.target, 'className'))) {
                                return;
                            }
                            toggleRmsCursor(setStatesChartStore, _get(ev.xAxis[0].axis.chart.userOptions, 'isLockedRmsCursor'), ev.xAxis[0].axis.chart);
                        } }) }), yAxis: [
                    __assign(__assign({}, options.yAxis[0]), { title: {
                            text: _get(readingTypes, [chartIdentifier, 'title']) +
                                ' ' +
                                (isCfChart(chartIdentifier) || isPinChart(chartIdentifier)
                                    ? ''
                                    : "(".concat(getUoms(chartIdentifier, measure, personalSettingMeasure, readingTypes), ") ").concat(getRmsAccVelExtraType(chartIdentifier))),
                        }, plotLines: chartAlertLevelsVisibleToggle
                            ? []
                            : isCustomChartType
                                ? []
                                : getAlertConditionsConfig(alertConditions, chartIdentifier, setStatesChartFeaturesStore, readingTypes, measure, personalSettingMeasure), initialPlotLines: isCustomChartType
                            ? []
                            : getAlertConditionsConfig(alertConditions, chartIdentifier, setStatesChartFeaturesStore, readingTypes, measure, personalSettingMeasure), softMax: isCustomChartType
                            ? customChartTypeMaxValue
                            : calculateMaxYAxisValue(alertConditions, chartIdentifier, readings, readingTypes, measure, personalSettingMeasure), softMin: isCustomChartType
                            ? // @ts-ignore
                                Math.min(values)
                            : calculateMinYAxisValue(alertConditions, chartIdentifier, readingTypes, measure, personalSettingMeasure) }),
                ], series: series }),
            loader: false,
            seriesIsRendered: true,
        },
    });
};
export var updateSeries = function (state, dispatch, updatedSeriesArr) {
    var options = state.options;
    dispatch({
        type: 'setState',
        state: {
            options: __assign(__assign({}, options), { series: updatedSeriesArr }),
            overlayInstallationPointsLoader: 0,
        },
    });
};
export var onAutoAlertConditionButtonClick = function (state, dispatch, chartIdentifier, setStatesChartFeaturesStore, installationPoint, countDays, showSystemLevels, showMlSuggestedLevels, selectedSystemType, measure) {
    var ambientDataIsVisible = state.ambientDataIsVisible;
    setStatesChartFeaturesStore({
        stateSuggestedLevelFormulaModal: {
            visible: true,
            onCancel: function () {
                setStatesChartFeaturesStore({
                    stateSuggestedLevelFormulaModal: {
                        visible: false,
                    },
                });
            },
            data: {
                installationPoint: installationPoint,
                isAmbient: ambientDataIsVisible,
                readingTypes: [chartIdentifier],
                fromChart: true,
            },
            confirmFromChartCallback: function () {
                dispatch({
                    type: 'setState',
                    state: { isAutoAlertConditions: false },
                });
            },
            overwriteLevelsCallback: function () {
                fetch(chartIdentifier, installationPoint, dispatch, countDays, showSystemLevels, showMlSuggestedLevels, selectedSystemType, measure);
            },
        },
    });
};
export var toggleIdleThreshold = function (showThresholds, state, dispatch, chartIdentifier, installationPoint) {
    if (!isAccelerationChart(chartIdentifier) && !isAccelerationImpactVue(chartIdentifier)) {
        return;
    }
    dispatch({
        type: 'setState',
        state: {
            options: __assign(__assign({}, state.options), { yAxis: [
                    __assign(__assign({}, (state.options.yAxis[0] || [])), { plotLines: showThresholds
                            ? __spreadArray(__spreadArray([], (state.options.yAxis[0].initialPlotLines || []), true), [
                                {
                                    value: isAccelerationImpactVue(chartIdentifier)
                                        ? installationPoint.impact_idle_threshold
                                        : installationPoint.idle_threshold,
                                    zIndex: 4,
                                    className: 'alarm-plot-line idle-threshold',
                                    isThreshold: true,
                                    color: 'rgb(30, 144, 255)',
                                    width: 1,
                                },
                            ], false) : state.options.yAxis[0].initialPlotLines }),
                ] }),
            overlayInstallationPointsLoader: 0,
        },
    });
};
