import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useZoomingImg } from '../../../../hooks/zoomingImg/useZoomingImg';
import Comment from '../Comment';
var CommentsList = function (_a) {
    var comments = _a.comments, userImage = _a.userImage, isViewMode = _a.isViewMode, commentDeleteMutate = _a.commentDeleteMutate, commentDeleteIsLoading = _a.commentDeleteIsLoading, commentSaveIsLoading = _a.commentSaveIsLoading;
    useZoomingImg('.zoomImg-comments img', [comments]);
    return (_jsx("div", { className: "activity-log-comments-list", children: comments.map(function (comment) { return (_jsx(Comment, { comment: comment, userImage: userImage, isViewMode: isViewMode, commentDeleteMutate: commentDeleteMutate, commentDeleteIsLoading: commentDeleteIsLoading, commentSaveIsLoading: commentSaveIsLoading }, comment.id)); }) }));
};
export default memo(CommentsList);
