import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { usePreAiModalActions } from '../../../../../store/watchersStatistic/usePreAiModalStore';
import { useWatchersStatisticModalsActions } from '../../../../../store/watchersStatistic/useWatchersStatisticModalsStore';
import TableRow from './components/TableRow';
var PreAiTable = function (_a) {
    var data = _a.data;
    var setActiveData = usePreAiModalActions().setActiveData;
    var setIsVisiblePreAiModal = useWatchersStatisticModalsActions().setIsVisiblePreAiModal;
    return (_jsx(_Fragment, { children: _jsxs("table", { className: "table table-simply", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "User Name" }), _jsxs("th", { id: "amount", children: ["Amount", _jsx(UncontrolledTooltip, { target: "amount", children: "Pre-AI with Report\\Pre-AI with No Actions Report" })] }), _jsx("th", { children: "Action Items status" }), _jsx("th", {})] }) }), _jsx("tbody", { children: !data.length ? (_jsx(TableRow, {})) : (data.map(function (item, key) { return (_jsx(TableRow, { callback: function () {
                            setActiveData({
                                pre_ai: item.pre_ai,
                                no_action_required: item.no_action_required,
                                statusCount: item.statusCount,
                                username: item.username,
                                units: item.units,
                            });
                            setIsVisiblePreAiModal(true);
                        }, item: item }, key)); })) })] }) }));
};
export default memo(PreAiTable);
