import Api from '../../api';
export default {
    getMlSuggestedAlarmAdjustmentList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/ml-suggested-alarm-adjustment', options);
    },
    deleteMlSuggestedAlarmAdjustment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/ml-suggested-alarm-adjustment/delete/".concat(id), options);
    },
    confirmMlSuggestedAlarmAdjustment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/ml-suggested-alarm-adjustment/confirm/".concat(id), options);
    },
    getMlSuggestedAlarmAdjustmentData: function (installationPointId, readingTypeId) {
        return Api.get("amazon-analytic/ml-suggested-alarm-adjustment/".concat(installationPointId, "/").concat(readingTypeId));
    },
};
