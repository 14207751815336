import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';
import AlertLogContent from '../../../../../../../widgets/alertLogContent';
import { DateTimeLabel } from '../../../../../../../widgets/common';
import LinkWithPrefix from '../../../../../LinkWithPrefix';
export var config = function (data) {
    return {
        columns: [
            {
                name: data.length ? (Object.keys(data[0]).includes('customer_name') ? 'customer' : 'facility') : '',
                title: data.length ? (Object.keys(data[0]).includes('customer_name') ? 'Customer' : 'Facility') : '',
                sortable: false,
                component: function (row) {
                    return _get(row, 'customer_name') || _get(row, 'facility') || '---';
                },
            },
            {
                name: 'related_name',
                title: 'Related Name',
                sortable: false,
                component: function (row) {
                    return _get(row, 'val2') || '---';
                },
            },
            {
                name: 'sensor',
                title: 'Sensor',
                sortable: false,
                component: function (row) {
                    return _get(row, 'val4') || '---';
                },
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
                component: function (row) {
                    return _get(row, 'val3') || '---';
                },
            },
            {
                name: 'new_data',
                title: 'New data',
                sortable: false,
                component: function (row) {
                    return (_jsx(React.Fragment, { children: Object.values(_get(row, 'data', [])).map(function (item) { return (_jsx(AlertLogContent, { readingTypeId: +_get(item, 'reading_type_id'), isNewData: true, showAxis: true, cautionValue: +_get(item, 'caution', 0), warningValue: +_get(item, 'warning', 0) }, _get(item, 'reading_type_id'))); }) }));
                },
            },
            {
                name: 'time',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "batch-log-".concat(_get(row, 'id')), dateTime: _get(row, 'date_create') || _get(row, 'dateCreate') }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(_Fragment, { children: _get(row, 'installationPoint_id') ? (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(+_get(row, 'installationPoint_id')), target: "_blank", children: _jsxs(Button, { size: "sm", color: "success", children: [_jsx(FaEye, {}), " View component"] }) })) : (_jsx(_Fragment, {})) }));
                },
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: data.length,
        },
        search: '',
        disabledSearch: true,
        data: data,
        loader: false,
    };
};
