import Api from '../../api';
export default {
    getRmsReadings: function (installationPointId, readingTypeId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-rms-readings/".concat(installationPointId, "/").concat(readingTypeId, "/").concat(countDays), options);
    },
    getRmsReadingsByReadingTypes: function (installationPointId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-rms-readings-by-reading-types/".concat(installationPointId, "/").concat(countDays), options);
    },
    getTachReadings: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/charts/get-tach-data', options);
    },
    getRmsReadingsBatch: function (readingTypeId, countDays, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-rms-readings-batch/".concat(readingTypeId, "/").concat(countDays), options);
    },
    getFftReadings: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-fft-readings/".concat(installationPointId), options);
    },
    getFftReadingsTimestamps: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-fft-readings-timestamps/".concat(installationPointId), options);
    },
    getFft: function (readingId, installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-fft/".concat(readingId, "/").concat(installationPointId), options);
    },
    getAllFftByIds: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/charts/get-all-fft-by-ids/".concat(installationPointId), options);
    },
    removePoint: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/charts/remove-point', data);
    },
    outlier: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/charts/outlier', data);
    },
    getClosestFft: function (installationPointId, axisId, timestamp, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/fft-charts/get-closest-fft/".concat(installationPointId, "/").concat(axisId, "/").concat(timestamp), options);
    },
};
