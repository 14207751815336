var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has, map as _map } from 'lodash';
import React, { memo, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
var getTrendsSeries = function (seriesArr, hours, period) {
    var trendsSeries = [];
    seriesArr.map(function (series) {
        if (!series.yData.length)
            return;
        if (series.options.id.indexOf('trend') !== -1 || series.options.id.indexOf('ambient') !== -1)
            return;
        if (_has(series.options, 'visible') && !series.options.visible)
            return;
        var tick = series.xData[1] - series.xData[0];
        var countTickInInterval = 0;
        var arr = [];
        for (var j = 0; j <= hours * 6; j++)
            if (hours * 60 === ((Math.round(tick / 1000 / 100) * 100) / 60) * j) {
                countTickInInterval = j;
            }
        if (!countTickInInterval) {
            return;
        }
        var calcAverageTick = function (i) {
            if (i - countTickInInterval >= 0) {
                var tmpVal = 0;
                for (var k = countTickInInterval - 1; k >= 0; k--)
                    tmpVal = tmpVal + series.yData[i - k];
                return +(tmpVal / countTickInInterval).toFixed(4);
            }
            else {
                return 0;
            }
        };
        for (var i = series.yData.length - 1; i >= 0; i--) {
            arr.unshift([series.xData[i], calcAverageTick(i)]);
        }
        trendsSeries.push({
            id: 'trend' + '_' + series.options.id,
            // @ts-ignore
            linkedTo: series.options.id,
            name: series.name + ' ' + period + ' trend',
            visible: true,
            showInLegend: false,
            data: arr,
            color: series.color,
            dashStyle: 'DashDot',
        });
    });
    return trendsSeries;
};
var getSeriesWithoutTrends = function (series) {
    return series.filter(function (seriesConfig) { var _a, _b; return ((_b = (_a = seriesConfig.options) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.indexOf('trend')) === -1; });
};
var TrendButtonDropdown = function (_a) {
    var chart = _a.chart, updateSeries = _a.updateSeries, width = _a.width, isDisabled = _a.isDisabled;
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var onHide = function () {
        var updatedSeries = getSeriesWithoutTrends(chart.series);
        updateSeries(_map(updatedSeries, 'userOptions'));
    };
    var onTrend = function (hours, period) {
        var updatedSeries = getSeriesWithoutTrends(chart.series);
        var trendsSeries = getTrendsSeries(updatedSeries, hours, period);
        var updatedSeriesArr = __spreadArray(__spreadArray([], _map(updatedSeries, 'userOptions'), true), trendsSeries, true);
        updateSeries(updatedSeriesArr);
    };
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "trend-dropdown chart-action-btn  ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsx(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: _jsx("span", { className: "trend-dropdown-toggle-link", children: "Trend" }) }), _jsxs(DropdownMenu, { children: [_jsx(DropdownItem, { className: "btn-sm", onClick: function () { return onTrend(6, '6 hours'); }, children: "6 hours" }), _jsx(DropdownItem, { className: "btn-sm", onClick: function () { return onTrend(24, '1 day'); }, children: "1 day" }), _jsx(DropdownItem, { className: "btn-sm", onClick: function () { return onTrend(24 * 7, '1 week'); }, children: "1 week" }), _jsx(DropdownItem, { className: "btn-sm", onClick: function () { return onTrend(24 * 14, '2 weeks'); }, children: "2 weeks" }), _jsx(DropdownItem, { divider: true }), _jsx(DropdownItem, { className: "btn-sm", onClick: onHide, children: "Hide" })] })] }) }) }));
};
export default memo(TrendButtonDropdown);
