import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import '../../../styles/AIPreAi/description.scss';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
import SelectedRecommendations from '../selectedRecommendations/SelectedRecommendations';
var DEFAULT_DIRECTION = 0;
var ShortDescription = function (_a) {
    var recommendations = _a.recommendations, isViewMode = _a.isViewMode, localStorageKey = _a.localStorageKey, isEditState = _a.isEditState, setIsEditState = _a.setIsEditState;
    var _b = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _b.isContentVisible, storageStateMemory = _b.storageStateMemory, setIsContentVisible = _b.setIsContentVisible, onSaveAsDefaultSize = _b.onSaveAsDefaultSize;
    if (recommendations.length === 0 || isEditState) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "aiPreAi-description ai-on-equipment default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsxs("div", { className: "default-ai-block-header-title d-flex justify-content-between w-100", children: [_jsx("div", { children: "Short description:" }), _jsx("div", { children: !isViewMode && isContentVisible ? (_jsx(_Fragment, { children: _jsx(Button, { title: "Edit", color: "primary", size: "sm", className: "me-2", onClick: function () { return setIsEditState(function (prev) { return !prev; }); }, children: isEditState ? _jsx(FaTimes, {}) : _jsx(FaEdit, {}) }) })) : (_jsx(_Fragment, {})) })] }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), isContentVisible ? (_jsx("div", { className: "default-ai-block-content", children: _jsx("div", { children: _jsx("div", { className: "aiPreAi-description-text position-relative", children: _jsx(SelectedRecommendations, { recommendations: recommendations }) }) }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(ShortDescription);
