var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useState } from 'react';
import { FaChartPie } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault, getStatisticByAnalysts, } from '../../../features/watchersStatistic';
import { useStatisticPreAiQuery, } from '../../../services/watchersStatistic/useStatisticPreAiQuery';
import { selectDateRange } from '../../../store/watchersStatistic/useDateRangeStore';
import Loader from '../../Loader';
import ColumnChart from '../AllStatisticTab/components/ColumnChart';
import PieChart from '../AllStatisticTab/components/PieChart';
import SplineChart from '../AllStatisticTab/components/SplineChart';
import PreAiModal from './components/PreAiModal';
import PreAiTable from './components/PreAiTable';
var groupMapping = {
    UA_team: 'ukrainian analysts',
    USA_team: 'american analysts',
    // PHL_team: "philippine analysts",
    ML_bot_system: 'ML-bot',
};
var PreAiStatisticTab = function () {
    var dateRange = selectDateRange();
    var _a = useState({}), chartBoxes = _a[0], setChartBoxes = _a[1];
    var toggleCharts = function (item) {
        var _a;
        var boxes = __assign({}, chartBoxes);
        setChartBoxes(__assign(__assign({}, boxes), (_a = {}, _a[item] = !_get(boxes, item, false), _a)));
    };
    var _b = useStatisticPreAiQuery(), statisticPreAiData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    if (!statisticPreAiData) {
        return (_jsx(_Fragment, { children: isLoading || isFetching ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})) }));
    }
    if (isLoading || isFetching) {
        return (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) }));
    }
    var allStatisticChartsConfig = statisticPreAiData.allStatisticChartsConfig, stateChartsConfig = statisticPreAiData.stateChartsConfig, pieChartOptions = statisticPreAiData.pieChartOptions, data = statisticPreAiData.data;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: "mt-2", children: (Object.keys(data) || []).map(function (key) {
                    return (_jsx(React.Fragment, { children: key === 'Hardware_team' ? (_jsx(_Fragment, {})) : (_jsxs(Col, { className: "mb-3", md: 6, children: [_jsxs("h4", { children: ["Pre AI ", groupMapping[key], " from", ' ', getFormattedDateFromOrDefault(dateRange[0], 'YYYY-MM-DD'), " to", ' ', getFormattedDateToOrDefault(dateRange[1], 'YYYY-MM-DD'), _jsx(Button, { color: "primary", onClick: function () { return toggleCharts(key); }, size: 'sm', className: "ms-2", children: _jsx(FaChartPie, {}) })] }), !_get(chartBoxes, key, false) ? (_jsx(PreAiTable, { data: _get(data, key, []) })) : (_jsx(PieChart, { options: __assign(__assign({}, pieChartOptions), { title: {
                                            text: "Pre-Action Items ".concat(_get(groupMapping, key)),
                                        }, series: [
                                            {
                                                type: 'pie',
                                                colorByPoint: true,
                                                data: __spreadArray([], getStatisticByAnalysts(__spreadArray([], _get(data, key, []), true), 'pre_ai'), true),
                                            },
                                        ] }) }))] })) }, key));
                }) }), _jsxs(Row, { children: [_jsx(Col, { md: 6, className: "mt-2", children: _jsx(SplineChart, { options: allStatisticChartsConfig }) }), _jsx(Col, { md: 6, className: "mt-2", children: _jsx(ColumnChart, { options: stateChartsConfig }) })] }), _jsx(PreAiModal, {})] }));
};
export default memo(PreAiStatisticTab);
