var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/totalWatcherStatistic/dataTableConfig';
import { fetch } from '../../../features/dashboard/totalWatcherStatistic';
import { reducer } from '../../../helper/reducer';
import LinkWithPrefix from '../../LinkWithPrefix';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
var TotalWatcherStatistic = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, tableName = _a.tableName, tableColumns = _a.tableColumns, endpoint = _a.endpoint;
    var _c = useReducer(reducer, initialState(tableName, tableColumns)), state = _c[0], dispatch = _c[1];
    var config = state.config;
    useEffect(function () {
        fetch(state, dispatch, function () { return endpoint(); });
    }, __spreadArray([], deps, true));
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsx(CardHeader, { style: { padding: 10 }, children: _jsx(LinkWithPrefix, { to: "/watchers-statistic", children: _jsxs("div", { className: "float-start", children: ["Total statistic by analysts by ", moment().format('MMMM YYYY')] }) }) }), _jsx(CardBody, { style: { padding: 0 }, children: _jsx(Scrollbars, { style: { height: '100%' }, children: _jsx(DataTable, { tableName: tableName, dispatchTable: dispatch, config: config }) }) })] }));
};
export default TotalWatcherStatistic;
