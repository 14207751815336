import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import Select from 'react-select';
var SelectInstallationPointOnEquipment = function (_a) {
    var installationPoint = _a.installationPoint, pointsOnEquipment = _a.pointsOnEquipment, setSelectedInstallationPointId = _a.setSelectedInstallationPointId, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var getPointName = function (installationPoint) {
        var _a;
        return (_a = installationPoint.name) !== null && _a !== void 0 ? _a : installationPoint.component + installationPoint.point_name;
    };
    return (_jsx(Select, { isDisabled: disabled, classNamePrefix: "customSelectStyleDefault", placeholder: "Installation points", defaultValue: { label: getPointName(installationPoint), value: installationPoint.id }, options: pointsOnEquipment.map(function (point) { return ({ label: getPointName(point), value: point.id }); }), onChange: function (item) { return setSelectedInstallationPointId(+item.value); } }));
};
export default memo(SelectInstallationPointOnEquipment);
