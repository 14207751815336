var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get } from 'lodash';
import User from '../../../shared/helper/user';
export var getNewUpdatedData = function (data, visibleEquipmentId) {
    return Object.values(data).map(function (el) {
        var equipmentId = Object.values(el.installationPoints).length
            ? Object.values(el.installationPoints)[0].equipment_id
            : null;
        return __assign(__assign({}, el), { entity_id: el.id, name: el.name, equipment_name: '', id: equipmentId, installationPoints: Object.values(el.installationPoints).map(function (elem) {
                var alertCondition = [];
                (elem.alertConditions || []).map(function (item) {
                    var _a, _b;
                    if (item.caution_above) {
                        alertCondition.push({
                            alertLevel: {
                                name: 'caution',
                            },
                            axis_id: String(item.axis_id),
                            sensor_id: String(item.sensor_id),
                            alert_level_type: 'caution',
                            alert_level_id: String(item.alertCondition_id),
                            reading_type_id: String(item.reading_type_id),
                            value: String(item.caution_above),
                            equipment_type: 'sensor',
                            setting: _get(item, 'settings[0]', null),
                            comments: (_a = item.comments) !== null && _a !== void 0 ? _a : [],
                        });
                    }
                    if (item.warning_above) {
                        alertCondition.push({
                            alertLevel: {
                                name: 'warning',
                            },
                            axis_id: String(item.axis_id),
                            sensor_id: String(item.sensor_id),
                            alert_level_type: 'warning',
                            alert_level_id: String(item.alertCondition_id),
                            reading_type_id: String(item.reading_type_id),
                            value: String(item.warning_above),
                            equipment_type: 'sensor',
                            setting: _get(item, 'settings[1]', null),
                            comments: (_b = item.comments) !== null && _b !== void 0 ? _b : [],
                        });
                    }
                });
                return __assign(__assign({}, elem), { alertCondition: alertCondition, systemAlertConditions: (elem.systemAlertConditions || []).map(function (item) {
                        return __assign(__assign({}, item), { reading_type_id: String(item.reading_type_id), axis_id: +item.axis_id === 4 ? '1' : String(item.axis_id), alert_level_type: +item.alert_level_id === 1 ? 'caution' : +item.alert_level_id === 2 ? 'warning' : null, alertLevel: {
                                name: +item.alert_level_id === 1 ? 'caution' : +item.alert_level_id === 2 ? 'warning' : null,
                            }, equipment_type: 'sensor' });
                    }) });
            }), is_visible: (visibleEquipmentId && +visibleEquipmentId === +equipmentId) || false, certain: {
                temp: {
                    type: 'temp',
                    is_visible: false,
                    installPointIds: [],
                },
                acc: {
                    type: 'acc',
                    is_visible: false,
                    installPointIds: [],
                },
                vel: {
                    type: 'vel',
                    is_visible: false,
                    installPointIds: [],
                },
                pkpk: {
                    type: 'pkpk',
                    is_visible: false,
                    installPointIds: [],
                },
                range: {
                    type: 'range',
                    is_visible: false,
                    installPointIds: [],
                },
            } });
    });
};
export var getSuggestedAlertConditionHandler = function (resp, dispatchSuggestedLevelModal) {
    if (!resp) {
        return;
    }
    var userComments = {};
    resp.data.map(function (comment) {
        if (+comment.user_id === +User.getId()) {
            userComments[+comment.reading_type_id] = comment;
        }
    });
    dispatchSuggestedLevelModal({
        type: 'setState',
        state: {
            comments: resp.data,
            customersData: null,
            userComments: userComments,
        },
    });
};
