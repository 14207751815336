var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Select from 'react-select';
import { UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { CHART_VIEW_OPTIONS } from '../../../../../../constants';
import { clearAllTooltips } from '../../../../../helper/commonHelper';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
var ChartViewSelect = function () {
    var _a = ChartsStore(function (state) { return ({
        chartView: state.chartView,
        setChartView: state.setChartView,
    }); }, shallow), chartView = _a.chartView, setChartView = _a.setChartView;
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '58px', fontSize: '14px' })); },
    }); }, []);
    var calcDefaultValueForSelectChartView = function () {
        return CHART_VIEW_OPTIONS.filter(function (el) { return el.value === chartView; });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "select-custom-default", styles: customStyles, id: "chart-view", isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: CHART_VIEW_OPTIONS, onChange: function (ev) {
                    setChartView(ev.value);
                    clearAllTooltips();
                }, defaultValue: calcDefaultValueForSelectChartView() }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "chart-view", children: "\u0421hart view type" })] }));
};
export default React.memo(ChartViewSelect);
