import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
var useToastErrorAlert = function (errors) {
    useEffect(function () {
        var errs = Object.values(errors);
        if (!errs.length) {
            return;
        }
        toast.error(errs[0].message, { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
    }, [errors]);
};
export default useToastErrorAlert;
