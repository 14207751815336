// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supported-math-operators-badge {
  min-width: 30px !important;
}
`, "",{"version":3,"sources":["webpack://./../src/widgets/suggestedLevelsFormula/formula.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":[".supported-math-operators-badge {\n  min-width: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
