// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ww-report-header {
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 0;
  margin: 5px 0;
  transition: 0.1s;
  border: 1px solid #e1e1e1;
  background: rgba(0, 123, 255, 0.0823529412);
}
.ww-report-header img {
  max-width: 100%;
}
.dark-layout .ww-report-header {
  border: 1px solid #2b2f3d;
  background: rgba(0, 123, 255, 0.0117647059);
}
.ww-report-header:hover {
  background: rgba(41, 98, 255, 0.1882352941);
}
.ww-report-header.active {
  background: #007bff;
  color: #fff;
}
.ww-report-header.active .ww-report-header-title {
  color: #fff;
  font-weight: 500;
}
.ww-report-header-notify-status {
  font-weight: 400;
}
.ww-report-header-title {
  color: #777;
}
.dark-layout .ww-report-header-title {
  color: #ccc;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/reports/components/wwReport/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,yBAAA;EACA,2CAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,yBAAA;EACA,2CAAA;AAEJ;AAAE;EACE,2CAAA;AAEJ;AAAE;EACE,mBAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;EACA,gBAAA;AAGN;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;AADI;EACE,WAAA;AAGN","sourcesContent":[".ww-report-header {\n  cursor: pointer;\n  font-size: 14px;\n  border-radius: 3px;\n  padding: 5px 0;\n  margin: 5px 0;\n  transition: 0.1s;\n  border: 1px solid #e1e1e1;\n  background: #007bff15;\n  & img {\n    max-width: 100%;\n  }\n  .dark-layout & {\n    border: 1px solid #2b2f3d;\n    background: #007bff03;\n  }\n  &:hover {\n    background: #2962ff30;\n  }\n  &.active {\n    background: #007bff;\n    color: #fff;\n    & .ww-report-header-title {\n      color: #fff;\n      font-weight: 500;\n    }\n  }\n  &-notify-status {\n    font-weight: 400;\n  }\n  &-title {\n    color: #777;\n    .dark-layout & {\n      color: #ccc;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
