import mediumZoom from 'medium-zoom';
import { useEffect } from 'react';
import mediumZoom_Zooming from '../../helper/medium-zoom-zooming';
export var useZoomingImg = function (path, deps, isCondition) {
    useEffect(function () {
        if (isCondition) {
            return;
        }
        mediumZoom(path).on('open', function () {
            return setTimeout(function () {
                return document
                    .getElementsByClassName('medium-zoom-image--opened')[0]
                    .addEventListener('wheel', mediumZoom_Zooming);
            });
        });
    }, deps || []);
};
