var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setLoader } from '../../helper/table';
export var fetchData = function (state, dispatch, endpoint, query) {
    var config = state.config, sensorIds = state.sensorIds;
    var pagination = config.pagination, sort = config.sort, search = config.search, filtersData = config.filtersData;
    if (!filtersData) {
        return;
    }
    var user_name = filtersData.user_name, startDate = filtersData.startDate, endDate = filtersData.endDate;
    setLoader(state, dispatch, true);
    endpoint({
        query: __assign(__assign({}, query), { sensorIds: sensorIds, pagination: pagination, sort: sort, search: search, user_name: user_name, startDate: startDate, endDate: endDate }),
    }).then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, filtersData: __assign(__assign({}, filtersData), { userNames: resp.userNames || [] }), pagination: __assign(__assign({}, pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
export var onChangeUserFilter = function (state, dispatch, value) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { user_name: value }) }),
        },
    });
};
export var onChangeDateFilter = function (state, dispatch, startDate, endDate) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { startDate: startDate, endDate: endDate }) }),
        },
    });
};
export var onResetFilters = function (state, dispatch) {
    var config = state.config;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { filtersData: __assign(__assign({}, config.filtersData), { user_name: '', startDate: null, endDate: null }) }),
        },
    });
};
