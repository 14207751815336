import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { RiFileHistoryLine } from "react-icons/ri";
import { Button } from "reactstrap";
import { useActionItemModalsActions } from "../../../../store/AIPreAi/useActionItemModalsStore";
import DefaultTooltip from "../../../DefaultTooltip";
var AiHistory = function () {
    var setIsVisibleViewedAILogModal = useActionItemModalsActions().setIsVisibleViewedAILogModal;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: true, color: "primary", "data-tooltip-id": "history-ai-btn", className: "top-actions-fill", onClick: function () { return setIsVisibleViewedAILogModal(true); }, children: _jsx(RiFileHistoryLine, {}) }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "history-ai-btn", children: "AI History" })] }));
};
export default memo(AiHistory);
