import { jsx as _jsx } from "react/jsx-runtime";
import 'jvectormap-next/jquery-jvectormap.css';
import { get as _get } from 'lodash';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { VectorMap } from 'react-jvectormap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { SYSTEM_TYPE_AMAZON } from '../../../../../constants';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import '../../MachineInfo.scss';
import { fetch } from './features';
var MapTab = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var facility = selectedSystemType === SYSTEM_TYPE_AMAZON
        ? _get(installationPoint, 'facilityTable') || {}
        : _get(installationPoint, 'equipment.locationTable.facility') || {};
    var _a = useState({}), facilities = _a[0], setFacilities = _a[1];
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    var mapRef = useRef(null);
    var currentFacilityId = facility.facility_id;
    useEffect(function () {
        fetch(setFacilities, setLoader, function () {
            return Endpoints[selectedSystemType].getLocationMapMachineInfo(installationPoint.id);
        });
    }, []);
    var currentFacility = Object.values(facilities).find(function (facility) { return +currentFacilityId === +facility.facility_id; });
    var sortedData = Object.values(facilities).filter(function (facility) { return +currentFacilityId !== +facility.facility_id; });
    if (currentFacility) {
        sortedData.push(currentFacility);
    }
    return (_jsx("div", { className: "h-100", children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx("div", { className: "mt-3", children: _jsx(VectorMap, { map: 'continents_mill', backgroundColor: "transparent", zoomButtons: false, zoomOnScroll: true, normalizeFunction: 'polynomial', hoverOpacity: 0.7, hoverColor: false, markerStyle: {
                    initial: {
                        'stroke-width': 0,
                        width: 10,
                    },
                }, regionStyle: {
                    initial: {
                        fill: 'rgba(210, 214, 222, 1)',
                        'fill-opacity': 1,
                        stroke: 'none',
                        'stroke-width': 0,
                        'stroke-opacity': 1,
                    },
                    hover: {
                        'fill-opacity': 0.7,
                        cursor: 'pointer',
                        'z-index': '5',
                    },
                    selected: {
                        fill: 'yellow',
                    },
                    selectedHover: {},
                }, markers: sortedData.map(function (facility) {
                    return {
                        latLng: [facility.lat, facility.lng],
                        name: facility.name,
                        style: {
                            zIndex: +currentFacilityId === +facility.facility_id ? 10 : 5,
                            fill: +currentFacilityId === +facility.facility_id ? '#f70000' : '#008100',
                        },
                    };
                }), ref: mapRef, containerStyle: {
                    width: '100%',
                    height: '65vh',
                    maxWidth: '1300px',
                    position: 'relative',
                    margin: '0 auto',
                }, containerClassName: "map" }) })) }));
};
export default memo(MapTab);
