// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidepeaks-modal .editing-area .edit-peak-block {
  width: 30%;
}
.hidepeaks-modal .editing-area .edit-peak-block label {
  display: flex;
  align-items: center;
}
.hidepeaks-modal .editing-area .edit-peak-block label svg {
  cursor: pointer;
  color: #557;
}
.dark-layout .hidepeaks-modal .editing-area .edit-peak-block label svg {
  color: #88a;
}
.hidepeaks-modal .editing-area .edit-peak-block input {
  font-size: 15px;
}
.hidepeaks-modal .alarm-plot-line-hide-peak {
  stroke: rgb(165, 0, 0);
  stroke-width: 3px;
}`, "",{"version":3,"sources":["webpack://./../src/modals/hidePeaks/HidePeaks.scss"],"names":[],"mappings":"AAEI;EACE,UAAA;AADN;AAEM;EACE,aAAA;EACA,mBAAA;AAAR;AACQ;EACE,eAAA;EACA,WAAA;AACV;AAAU;EACE,WAAA;AAEZ;AAEM;EACE,eAAA;AAAR;AAIE;EACE,sBAAA;EACA,iBAAA;AAFJ","sourcesContent":[".hidepeaks-modal {\n  & .editing-area {\n    & .edit-peak-block {\n      width: 30%;\n      & label {\n        display: flex;\n        align-items: center;\n        & svg {\n          cursor: pointer;\n          color: #557;\n          .dark-layout & {\n            color: #88a;\n          }\n        }\n      }\n      & input {\n        font-size: 15px;\n      }\n    }\n  }\n  & .alarm-plot-line-hide-peak {\n    stroke: rgb(165, 0, 0);\n    stroke-width: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
