import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { selectChartMarksHotkey } from '../../../../../store/hotkeys/useChartsHotkeysStore';
import DefaultTooltip from '../../../../DefaultTooltip';
import HotkeyInfo from '../../../../HotkeyInfo';
var ChartMarksToggle = function () {
    var _a = ChartsStore(function (state) { return ({
        hideChartMarks: state.hideChartMarks,
        setStatesChartsStore: state.setStates,
    }); }, shallow), hideChartMarks = _a.hideChartMarks, setStatesChartsStore = _a.setStatesChartsStore;
    var chartMarksHotkey = selectChartMarksHotkey();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "btn-chart-tools btn-chart-tools-menubtn", outline: !hideChartMarks, color: "customChartTopBarSelect", size: "sm", "data-tooltip-id": "chartChartMarksVisible", onClick: function () { return setStatesChartsStore({ hideChartMarks: !hideChartMarks }); }, children: "Hide chart marks" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "chartChartMarksVisible", clickable: true, children: _jsx(HotkeyInfo, { hotkey: chartMarksHotkey }) })] }));
};
export default memo(ChartMarksToggle);
