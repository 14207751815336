// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-footer-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background: #fff;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s;
}
.dark-layout .toggle-footer-bar {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}
.toggle-footer-bar:hover {
  background: rgba(41, 98, 255, 0.1882352941);
}
.toggle-footer-bar.active, .toggle-footer-bar.active:hover {
  border: 1px solid #2962ff;
  color: #2962ff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/charts/components/ToggleFooterBar/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,mBAAA;EACA,yBAAA;AAEJ;AAAE;EACE,2CAAA;AAEJ;AAAE;EAEE,yBAAA;EACA,cAAA;AACJ","sourcesContent":[".toggle-footer-bar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  bottom: 15px;\n  right: 15px;\n  width: 50px;\n  height: 50px;\n  font-size: 20px;\n  background: #fff;\n  border: 1px solid #e2e2e2;\n  cursor: pointer;\n  border-radius: 50%;\n  transition: 0.3s;\n  .dark-layout & {\n    background: #1e222d;\n    border: 1px solid #3e3f4f;\n  }\n  &:hover {\n    background: #2962ff30;\n  }\n  &.active,\n  &.active:hover {\n    border: 1px solid #2962ff;\n    color: #2962ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
