import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var fftAverageDataKey = function (payload) { return ['fftAverageData', payload]; };
var fftAverageDataFn = function (coreLink, queryKey) {
    return Api.get("".concat(coreLink, "/charts/get-chart-fft-average-data"), {
        query: queryKey[1],
    });
};
export var useFftAverageDataQuery = function (payload) {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: fftAverageDataKey(payload),
        queryFn: function (_a) {
            var queryKey = _a.queryKey;
            return fftAverageDataFn(getCoreUrl(selectedSystemType), queryKey);
        },
        select: function (_a) {
            var std = _a.std, average = _a.average, max = _a.max;
            return { std: std, average: average, max: max };
        },
        enabled: !!(payload.followingType && payload.followingType !== 1),
    });
};
