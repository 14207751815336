import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
export var useFetchData = function (state, fetch) {
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var countDays = ChartsStore(function (state) { return ({
        countDays: state.countDays,
    }); }, shallow).countDays;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var mainSeriesLoaded = state.mainSeriesLoaded;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        fetch();
    }, [countDays]);
    useEffect(function () {
        if (!installationPoint.id) {
            return;
        }
        fetch();
    }, [installationPoint.id, measure, personalSettingMeasure]);
};
