import { each as _each, get as _get, size as _size } from 'lodash';
import { isCfChart } from '../../../../../helper/chart';
import { calculateCurrentLevel } from './alertConditions';
export var getZones = function (chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure) {
    if (isCfChart(chartIdentifier)) {
        return [];
    }
    if (_size(alertConditions) === 0 || !_get(alertConditions, 'conditions')) {
        return [];
    }
    var currentLevel = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure), cautionCondition = _get(alertConditions, 'conditions.caution') || '>=', warningCondition = _get(alertConditions, 'conditions.warning') || '>=';
    currentLevel.caution = +currentLevel.caution;
    currentLevel.warning = +currentLevel.warning;
    var zones = [];
    var zonesCondition = {
        getCautionAndWarningMoreZones: (cautionCondition === '>' || cautionCondition === '>=') &&
            (warningCondition === '>' || warningCondition === '>='),
        getCautionLessWarningMoreZones: (cautionCondition === '<' || cautionCondition === '<=') &&
            (warningCondition === '>' || warningCondition === '>='),
        getCautionMoreWarningLessZones: (cautionCondition === '>' || cautionCondition === '>=') &&
            (warningCondition === '<' || warningCondition === '<='),
        getCautionAndWarningLessZones: (cautionCondition === '<' || cautionCondition === '<=') &&
            (warningCondition === '<' || warningCondition === '<='),
        getCautionAndWarningEqualZones: cautionCondition === '=' && warningCondition === '=',
        getCautionEqualWarningLessZones: cautionCondition === '=' && (warningCondition === '<=' || warningCondition === '<'),
        getCautionEqualWarningMoreZones: cautionCondition === '=' && (warningCondition === '>=' || warningCondition === '>'),
        getCautionLessWarningEqualZones: (cautionCondition === '<=' || cautionCondition === '<') && warningCondition === '=',
        getCautionMoreWarningEqualZones: (cautionCondition === '>=' || cautionCondition === '>') && warningCondition === '=',
    };
    var zonesData = {
        getCautionAndWarningMoreZones: getCautionAndWarningMoreZones(currentLevel),
        getCautionLessWarningMoreZones: getCautionLessWarningMoreZones(currentLevel),
        getCautionMoreWarningLessZones: getCautionMoreWarningLessZones(currentLevel),
        getCautionAndWarningLessZones: getCautionAndWarningLessZones(currentLevel),
        getCautionAndWarningEqualZones: getCautionAndWarningEqualZones(currentLevel),
        getCautionEqualWarningLessZones: getCautionEqualWarningLessZones(currentLevel),
        getCautionEqualWarningMoreZones: getCautionEqualWarningMoreZones(currentLevel),
        getCautionLessWarningEqualZones: getCautionLessWarningEqualZones(currentLevel),
        getCautionMoreWarningEqualZones: getCautionMoreWarningEqualZones(currentLevel),
    };
    _each(zonesCondition, function (condition, name) {
        if (condition) {
            zones = zonesData[name];
        }
    });
    return zones;
};
var getCautionAndWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#eab700',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution,
            color: '#ff0000',
        },
        {
            color: '#eab700',
        },
    ];
};
var getCautionAndWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionAndWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution - (2 / 100) * currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning - (2 / 100) * currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution - (2 / 100) * currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionEqualWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution + (3 / 100) * currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.caution - (3 / 100) * currentLevel.caution,
            color: '#eab700',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionEqualWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution - (3 / 100) * currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionLessWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning - (1 / 100) * currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#eab700',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            value: currentLevel.caution,
            color: '#eab700',
        },
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionMoreWarningEqualZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#7cb5ec',
            },
            {
                value: currentLevel.warning - (2 / 100) * currentLevel.warning,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#ff0000',
            },
            {
                color: '#eab700',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning - (2 / 100) * currentLevel.warning,
            color: '#7cb5ec',
        },
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            color: '#eab700',
        },
    ];
};
var getCautionLessWarningMoreZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                value: currentLevel.caution,
                color: '#eab700',
            },
            {
                value: currentLevel.warning,
                color: '#7cb5ec',
            },
            {
                color: '#ff0000',
            },
        ];
    }
    return [
        {
            color: '#7cb5ec',
        },
    ];
};
var getCautionMoreWarningLessZones = function (currentLevel) {
    if (currentLevel.caution < currentLevel.warning) {
        return [
            {
                color: '#7cb5ec',
            },
        ];
    }
    return [
        {
            value: currentLevel.warning,
            color: '#ff0000',
        },
        {
            value: currentLevel.caution,
            color: '#7cb5ec',
        },
        {
            color: '#eab700',
        },
    ];
};
