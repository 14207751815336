var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import Select from "react-select";
import { UncontrolledTooltip } from "reactstrap";
import { shallow } from "zustand/shallow";
import { clearAllTooltips } from "../../../../../helper/commonHelper";
import { ChartsStore } from "../../../../../store/charts/chartsContent/ChartsStore";
import { selectHarmonicFftTwfCursorHotkey, selectNormalFftTwfCursorHotkey, selectSidebandFftTwfCursorHotkey, } from "../../../../../store/hotkeys/useChartsHotkeysStore";
import DefaultTooltip from "../../../../DefaultTooltip";
import HotkeyInfo from "../../../../HotkeyInfo";
var FftCursorSelect = function () {
    var _a = ChartsStore(function (state) { return ({
        setStatesChartsStore: state.setStates,
        chartCursorType: state.chartCursorType,
    }); }, shallow), setStatesChartsStore = _a.setStatesChartsStore, chartCursorType = _a.chartCursorType;
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '120px', fontSize: '14px' })); },
    }); }, []);
    var normalFftTwfCursorHotkey = selectNormalFftTwfCursorHotkey();
    var harmonicFftTwfCursorHotkey = selectHarmonicFftTwfCursorHotkey();
    var sidebandFftTwfCursorHotkey = selectSidebandFftTwfCursorHotkey();
    var FFT_CURSOR_OPTIONS = [
        {
            value: 'normal',
            label: (_jsxs(_Fragment, { children: [_jsx("div", { "data-tooltip-id": "normalFftTwfCursor", children: "Normal" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "normalFftTwfCursor", children: _jsx(HotkeyInfo, { hotkey: normalFftTwfCursorHotkey }) })] })),
        },
        {
            value: 'harmonic',
            label: (_jsxs(_Fragment, { children: [_jsx("div", { "data-tooltip-id": "harmonicFftTwfCursor", children: "Harmonic" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "harmonicFftTwfCursor", children: _jsx(HotkeyInfo, { hotkey: harmonicFftTwfCursorHotkey }) })] })),
        },
        {
            value: 'sideband',
            label: (_jsxs("div", { children: [_jsx("div", { "data-tooltip-id": "sidebandFftTwfCursor", children: "Sideband" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "sidebandFftTwfCursor", children: _jsx(HotkeyInfo, { hotkey: sidebandFftTwfCursorHotkey }) })] })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "select-custom-default", id: "fft-cursor-type", styles: customStyles, isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: FFT_CURSOR_OPTIONS, onChange: function (ev) {
                    setStatesChartsStore({ chartCursorType: ev.value });
                    clearAllTooltips();
                }, value: FFT_CURSOR_OPTIONS.filter(function (el) { return el.value === chartCursorType; }) }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "fft-cursor-type", children: "FFT/TWF cursors" })] }));
};
export default memo(FftCursorSelect);
