var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep as _cloneDeep, filter as _filter, orderBy as _orderBy } from 'lodash';
export var onSort = function (state, dispatch, column_name) {
    var config = state.config;
    var sort = config.sort;
    if (!sort) {
        return;
    }
    var direction = sort[0].field === column_name ? (sort[0].direction === 'asc' ? 'desc' : 'asc') : 'desc';
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, config), { data: _orderBy(config.data, [column_name], [direction]), sort: [
                    {
                        field: column_name,
                        direction: direction,
                    },
                ] }),
        },
    });
};
export var applyFilter = function (state) {
    var defaultData = state.defaultData, filters = state.filters;
    var isMoreThen25 = filters.isMoreThen25;
    var list = _cloneDeep(defaultData);
    return _filter(list, function (item) {
        return isMoreThen25 ? item.cost_avoided >= 25000 : true;
    });
};
export var onChangeSortField = function (state, dispatch, direction) {
    var filters = state.filters;
    dispatch({
        type: 'setState',
        state: {
            filters: __assign(__assign({}, filters), { sortField: direction }),
        },
    });
};
export var onChangeCost = function (state, dispatch) {
    var filters = state.filters;
    var isMoreThen25 = filters.isMoreThen25;
    dispatch({
        type: 'setState',
        state: {
            filters: __assign(__assign({}, filters), { isMoreThen25: !isMoreThen25 }),
        },
    });
};
