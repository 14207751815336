var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { usePrevious } from '../../../customHooks';
import { FrequencyConverter } from '../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
var SetFaultFrequencyModal = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        setFaultFrequency: state.setFaultFrequency,
    }); }, shallow), setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore, setFaultFrequency = _a.setFaultFrequency;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        currentSpeed: state.currentSpeed,
    }); }, shallow), installationPoint = _b.installationPoint, currentSpeed = _b.currentSpeed, equipment = _get(installationPoint, 'equipment') || {};
    var _c = FftChartsStore(function (state) { return ({
        faultFrequencies: state.faultFrequencies,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), faultFrequencies = _c.faultFrequencies, setStatesFftChartsStore = _c.setStatesFftChartsStore;
    var _d = useState({
        equipmentId: equipment.id,
        frequency: setFaultFrequency.faultFrequency.frequency,
        id: setFaultFrequency.faultFrequency.id || null,
        name: setFaultFrequency.faultFrequency.name || null,
        plotLinesCount: setFaultFrequency.faultFrequency.plotLinesCount || 1,
        installationPointId: setFaultFrequency.installationPointId || installationPoint.id,
        frequencyType: setFaultFrequency.faultFrequency.frequencyType || 'Hz',
        type: 'single',
        installationPointIds: equipment.installationPoints.map(function (installationPoint) { return +installationPoint.id; }),
    }), data = _d[0], setData = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var prevFrequencyType = usePrevious(data.frequencyType);
    useEffect(function () {
        if (!prevFrequencyType || !data.frequencyType) {
            return;
        }
        setData(__assign(__assign({}, data), { frequency: parseFloat(FrequencyConverter.fromType(prevFrequencyType, data.frequency, currentSpeed).toType(data.frequencyType).value).toFixed(2) }));
    }, [data.frequencyType]);
    var onCancel = function () {
        setStatesChartFeaturesStore({
            setFaultFrequency: {
                visible: false,
                faultFrequency: {},
            },
        });
    };
    var onCreate = function () {
        Endpoints[selectedSystemType].addFaultFrequency(data).then(function (resp) {
            if (resp) {
                setLoading(false);
                if (resp.success) {
                    if (setFaultFrequency.installationPointId === installationPoint.id) {
                        var updatedFaultFrequencies = _cloneDeep(faultFrequencies);
                        updatedFaultFrequencies.push(resp.result);
                        setStatesFftChartsStore({
                            faultFrequencies: updatedFaultFrequencies,
                        });
                    }
                    onCancel();
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onUpdate = function () {
        Endpoints[selectedSystemType].updateFaultFrequency(data.id, data).then(function (resp) {
            if (resp) {
                setLoading(false);
                if (resp.success) {
                    if (setFaultFrequency.installationPointId === installationPoint.id) {
                        var updatedFaultFrequencies_1 = _cloneDeep(faultFrequencies);
                        updatedFaultFrequencies_1.forEach(function (faultFrequency, index) {
                            if (faultFrequency.id === data.id) {
                                updatedFaultFrequencies_1[index] = resp.result;
                            }
                        });
                        setStatesFftChartsStore({
                            faultFrequencies: updatedFaultFrequencies_1,
                        });
                    }
                    onCancel();
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onSave = function () {
        setLoading(true);
        if (data.id) {
            return onUpdate();
        }
        return onCreate();
    };
    var onChangeInstallationPointIds = function (installationPointId) {
        var updatedInstallationPointIds = _cloneDeep(data.installationPointIds);
        var index = updatedInstallationPointIds.indexOf(installationPointId);
        if (index !== -1) {
            updatedInstallationPointIds.splice(index, 1);
        }
        else {
            updatedInstallationPointIds.push(installationPointId);
        }
        setData(__assign(__assign({}, data), { installationPointIds: updatedInstallationPointIds }));
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Set fault/forcing frequency", loading && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { className: "bearing-modal-content", children: [_jsxs(Row, { children: [_jsx(Col, { md: 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Asset Code:" }) }), _jsx("p", { children: equipment.asset_code })] }) }), _jsx(Col, { md: 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Equipment:" }) }), _jsx("p", { children: equipment.name })] }) }), _jsx(Col, { md: 4, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Sensor RPM:" }) }), _jsx("p", { children: currentSpeed })] }) })] }), _jsx("hr", {}), _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Sensor:" }) }), _jsx(Input, { id: "set_fault_frequency_sensor", name: "set_fault_frequency_sensor", disabled: true, value: installationPoint.name, onChange: function () { } })] }) }), _jsx(Col, { md: 3, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Title:" }) }), _jsx(Input, { id: "set_fault_frequency_name", name: "set_fault_frequency_name", placeholder: "Enter Frequency Name", value: data.name, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { name: ev.target.value }));
                                            } })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Frequency:" }) }), _jsx(Input, { id: "set_fault_frequency_frequency", name: "set_fault_frequency_frequency", type: "number", value: data.frequency, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { frequency: ev.target.value }));
                                            } })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Units:" }) }), _jsxs(Input, { id: "set_fault_frequency_frequency_type", name: "set_fault_frequency_frequency_type", type: "select", value: data.frequencyType, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { frequencyType: ev.target.value }));
                                            }, children: [_jsx("option", { value: "Hz", children: "Hz" }), _jsx("option", { value: "CPM", children: "CPM" }), _jsx("option", { value: "Orders", children: "Orders" })] })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Plot Lines:" }) }), _jsx(Input, { id: "set_fault_frequency_plot_lines", name: "set_fault_frequency_plot_lines", type: "number", value: data.plotLinesCount, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { plotLinesCount: parseInt(ev.target.value) }));
                                            } })] }) })] }), !data.id ? (_jsxs(_Fragment, { children: [_jsxs(Row, { className: "mt-2", children: [_jsx(Col, { md: 2, children: _jsx("span", { className: "fw-bold mt-2 d-block align-middle", children: "Set For:" }) }), _jsx(Col, { md: 10, children: _jsxs(Nav, { tabs: true, children: [_jsx(NavItem, { children: _jsx(NavLink, { active: data.type === 'single', onClick: function () { return setData(__assign(__assign({}, data), { type: 'single' })); }, children: "Installation Point Only" }) }), _jsx(NavItem, { children: _jsx(NavLink, { active: data.type === 'all', onClick: function () { return setData(__assign(__assign({}, data), { type: 'all' })); }, children: "All Installation Points On Equipment" }) })] }) })] }), data.type === 'all' ? (_jsxs(Row, { className: "mt-4", children: [_jsx(Col, { md: 4, children: _jsx("b", { children: "Will be applied to installation points:" }) }), _jsx(Col, { children: equipment.installationPoints
                                            .filter(function (installationPoint) { return installationPoint.sensor_id; })
                                            .map(function (installationPointItem) { return (_jsx(Row, { children: _jsx(Col, { children: _jsxs(Label, { check: true, className: "cursor-pointer", children: [_jsx(Input, { type: "checkbox", disabled: +installationPointItem.id === +installationPoint.id, checked: data.installationPointIds.includes(installationPointItem.id), onChange: function () { return onChangeInstallationPointIds(installationPointItem.id); } }), _jsx("span", { className: "fw-bold", children: installationPointItem.name })] }) }) }, "installation_point_speed_".concat(installationPointItem.id))); }) })] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, disabled: loading, children: "Save" })] }) })] }));
};
export default SetFaultFrequencyModal;
