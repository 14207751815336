import { first as _first, get as _get } from 'lodash';
import Endpoints from '../../../../api/endpoints/endpoints';
import areaStorage from '../../../../storage/area';
import facilityStorage from '../../../../storage/facility';
import zoneStorage from '../../../../storage/zone';
export var fetchFacilitiesList = function (setFetchFacility, setFetchZone, setFacilityList, setZones, setAreas, setShowAmazon, setFacilitiesListFacilitiesListStore) {
    Endpoints.amazon.getFacilitiesList().then(function (_a) {
        var _b = _a.list, list = _b === void 0 ? [] : _b;
        if (list.length === 1) {
            setShowAmazon('zone');
            fetchFacilityZoneTree(_get(_first(list), 'facility_id'), function (resp) {
                setFetchFacility(true);
                setFacilityList(list);
                setFacilitiesListFacilitiesListStore(list);
                setZones((resp.zones || []).map(function (el) {
                    return {
                        label: el,
                        value: el,
                    };
                }));
            }, setShowAmazon, setFetchZone, setZones, setAreas);
            return;
        }
        setFetchFacility(true);
        setFacilityList(list);
        setFacilitiesListFacilitiesListStore(list);
    });
};
export var fetchFacilityZoneTree = function (facilityId, callback, setShowAmazon, setFetchZone, setZones, setAreas) {
    Endpoints.amazon.getFacilityZones(facilityId).then(function (resp) {
        if (resp.zones.length === 1) {
            setShowAmazon('area');
            fetchFacilityAreaTree(facilityId, _first(resp.zones), function (resp) {
                setAreas((resp.areas || []).map(function (el) {
                    return {
                        label: el,
                        value: el,
                    };
                }));
            });
        }
        setZones((resp.zones || []).map(function (el) {
            return {
                label: el,
                value: el,
            };
        }));
        setFetchZone(true);
        callback(resp);
    });
};
export var fetchFacilityAreaTree = function (facilityId, zone, callback) {
    Endpoints.amazon.getFacilityArea(facilityId, zone).then(function (resp) {
        callback(resp);
    });
};
export var onChangeZone = function (layoutDispatch, item, setZone, setArea, defaultArea, trackEvent) {
    setArea(defaultArea);
    setZone(item);
    layoutDispatch({
        type: 'setZone',
        selectedZone: item.value,
    });
    layoutDispatch({
        type: 'setArea',
        selectedZone: null,
    });
    areaStorage.remove();
    if (!item.value) {
        zoneStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change zone', name: item.label, href: window.location.href });
    zoneStorage.set(item.value);
};
export var onChangeArea = function (layoutDispatch, item, setArea, trackEvent) {
    setArea(item);
    layoutDispatch({
        type: 'setArea',
        selectedArea: item.value,
    });
    if (!item.value) {
        areaStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change area', name: item.label, href: window.location.href });
    areaStorage.set(item.value);
};
export var onChangeFacility = function (layoutDispatch, item, setZone, defaultZone, setArea, defaultArea, trackEvent) {
    setZone(defaultZone);
    setArea(defaultArea);
    layoutDispatch({
        type: 'setFacility',
        selectedFacility: item.value,
    });
    layoutDispatch({
        type: 'setZone',
        selectedZone: null,
    });
    layoutDispatch({
        type: 'setArea',
        selectedZone: null,
    });
    areaStorage.remove();
    zoneStorage.remove();
    if (!item.value) {
        facilityStorage.remove();
        return;
    }
    trackEvent({ category: 'Base', action: 'Change facility', name: item.label, href: window.location.href });
    facilityStorage.set(item.value);
};
