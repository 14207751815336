import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { Button } from 'reactstrap';
import Loader from '../../../../components/Loader';
import EquipmentPhotosContent from '../../../../components/equipmentPhotos/EquipmentPhotosContent';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import '../../MachineInfo.scss';
var ImagesTab = function () {
    var equipmentPhotos = InstallationPointStore(function (state) { return state.equipmentPhotos; });
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState(0), saveTrigger = _b[0], setSaveTrigger = _b[1];
    return (_jsxs(_Fragment, { children: [loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, {})), _jsxs("div", { className: loader ? 'd-none' : 'd-block', children: [_jsx(EquipmentPhotosContent, { loader: loader, setLoader: setLoader, saveTrigger: saveTrigger }), equipmentPhotos.length ? (_jsx(Button, { className: "float-end mt-2", size: "sm", color: "primary", onClick: function () { return setSaveTrigger(saveTrigger + 1); }, children: "Save" })) : (_jsx(_Fragment, {}))] })] }));
};
export default memo(ImagesTab);
