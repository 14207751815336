import { useEffect } from 'react';
import { selectEditComment } from '../../store/AIPreAi/useActionItemViewStore';
var useSetCommentActivityLog = function (setValue) {
    var editComment = selectEditComment();
    useEffect(function () {
        var _a;
        setValue('comment', (_a = editComment === null || editComment === void 0 ? void 0 : editComment.comment) !== null && _a !== void 0 ? _a : '');
    }, [editComment]);
};
export default useSetCommentActivityLog;
