// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  right: -9px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #0d6efd;
  cursor: pointer;
}
.translate-button:hover {
  background: rgba(13, 110, 253, 0.1254901961);
}
.translate-button svg {
  width: 12px;
  height: 12px;
  fill: #0d6efd;
}
.translate-button.active {
  background: #0d6efd;
}
.translate-button.active svg {
  fill: #fff;
}
.translate-button.active:hover {
  background: #0d6efd;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/AIPreAi/TranslatesToggleBtn/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACF;AAAE;EACE,4CAAA;AAEJ;AAAE;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AADI;EACE,UAAA;AAGN;AADI;EACE,mBAAA;AAGN","sourcesContent":[".translate-button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: -9px;\n  right: -9px;\n  width: 18px;\n  height: 18px;\n  border-radius: 50%;\n  border: 1px solid #0d6efd;\n  cursor: pointer;\n  &:hover {\n    background: #0d6efd20;\n  }\n  & svg {\n    width: 12px;\n    height: 12px;\n    fill: #0d6efd;\n  }\n  &.active {\n    background: #0d6efd;\n    & svg {\n      fill: #fff;\n    }\n    &:hover {\n      background: #0d6efd;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
