import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DateTimeLabel } from '../../../../../../widgets/common';
export var initialState = {
    config: {
        columns: [
            {
                name: 'readingType_name',
                title: 'Reading Type',
                sortable: false,
            },
            {
                name: 'max_7d',
                title: 'Max Value (7d)',
                sortable: false,
            },
            {
                name: 'max_7d_time',
                title: 'Max Value Time (7d)',
                sortable: false,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { timeBreak: true, id: "avg_max_7d_time".concat(row.installation_point_id), dateTime: row.max_7d_time }));
                },
            },
            {
                name: 'avg_1d',
                title: 'AVG (1d)',
                sortable: false,
            },
            {
                name: 'avg_2d',
                title: 'AVG (2d)',
                sortable: false,
            },
            {
                name: 'avg_7d',
                title: 'AVG (7d)',
                sortable: false,
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        search: '',
        disabledSearch: true,
        data: [],
        loader: true,
    },
};
