import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { find as _find, get as _get, size as _size } from 'lodash';
import React, { memo, useContext } from 'react';
import { shallow } from 'zustand/shallow';
import { FREQUENCY_TYPES, READING_TYPE_FFT_ACCELERATION_X, RMS_TO_FFT_READINGS, SYSTEM_TYPE_REMASTERED, } from '../../../../../../constants';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { isTemperatureChart } from '../../../../../helper/chart';
import { ChartFeaturesStore } from '../../../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import { ModalsStore } from '../../../../../store/charts/chartsContent/ModalsStore';
import { selectMeasure } from '../../../../../store/global/useGlobalStore';
import { onAutoAlertConditionButtonClick, toggleIdleThreshold, updateSeries } from '../../features/rms';
import { AmbientButton } from '../shared/chartToolsButtons/Ambient/Ambient';
import { AutoAlertConditionsButton } from '../shared/chartToolsButtons/AutoAlertConditions';
import CopyLinkButton, { onCopyLink } from '../shared/chartToolsButtons/CopyLink/CopyLink';
import FullscreenButton, { onToggleFullscreen } from '../shared/chartToolsButtons/Fullscreen';
import HidePeaksButton from '../shared/chartToolsButtons/HidePeaksButton';
import IdleThresholdsButton from '../shared/chartToolsButtons/IdleThresholds/IdleThresholds';
import LockTooltipButton, { onToggleLockTooltip } from '../shared/chartToolsButtons/LockTooltip';
import LockTooltipPosition from '../shared/chartToolsButtons/LockTooltipPosition';
import ManagementButton from '../shared/chartToolsButtons/ManagementButton';
import { NormalizedButton } from '../shared/chartToolsButtons/Normalized/Normalized';
import TrendButtonDropdown from '../shared/chartToolsButtons/Trend';
import WaterfallPlotButton from '../shared/chartToolsButtons/WaterfallPlotButton';
var ChartToolsButtonsWrapper = function (_a) {
    var _b;
    var state = _a.state, dispatch = _a.dispatch, chartRef = _a.chartRef, chartIdentifier = _a.chartIdentifier;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isSelectionEnabled = ModalsStore(function (state) { return state.isSelectionEnabled; });
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        isAutoAlertConditionsExists: state.isAutoAlertConditionsExists,
    }); }, shallow), installationPoint = _c.installationPoint, isAutoAlertConditionsExists = _c.isAutoAlertConditionsExists;
    var _d = ChartsStore(function (state) { return ({
        isVisibleFftShapes: state.isVisibleFftShapes,
        hidePeaks: state.hidePeaks,
        countDays: state.countDays,
        showSystemLevels: state.showSystemLevels,
        showMlSuggestedLevels: state.showMlSuggestedLevels,
    }); }, shallow), isVisibleFftShapes = _d.isVisibleFftShapes, hidePeaks = _d.hidePeaks, countDays = _d.countDays, showSystemLevels = _d.showSystemLevels, showMlSuggestedLevels = _d.showMlSuggestedLevels;
    var _e = ChartFeaturesStore(function (state) { return ({
        waterfallPlotModal: state.waterfallPlotModal,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), waterfallPlotModal = _e.waterfallPlotModal, setStatesChartFeaturesStore = _e.setStatesChartFeaturesStore;
    var measure = selectMeasure();
    var options = state.options, ambientDataIsVisible = state.ambientDataIsVisible, isVisibleFullscreenChart = state.isVisibleFullscreenChart, isVisibleLockTooltip = state.isVisibleLockTooltip, normalizedReadingsIsVisible = state.normalizedReadingsIsVisible, isAutoAlertConditions = state.isAutoAlertConditions, lockTooltipPosition = state.lockTooltipPosition;
    var ambientReadingsExist = _size(_find(options.series, { id: 'ambientData' })) > 0;
    return (_jsxs("div", { className: "chart-actions-btn-wrapper", children: [isVisibleFftShapes &&
                waterfallPlotModal.readingsListChartIdentifier === chartIdentifier &&
                (waterfallPlotModal === null || waterfallPlotModal === void 0 ? void 0 : waterfallPlotModal.readingsList) &&
                ((_b = waterfallPlotModal === null || waterfallPlotModal === void 0 ? void 0 : waterfallPlotModal.readingsList) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (_jsx(WaterfallPlotButton, { chartIdentifier: RMS_TO_FFT_READINGS[waterfallPlotModal.readingsListChartIdentifier] || READING_TYPE_FFT_ACCELERATION_X, selectedUnits: FREQUENCY_TYPES.HZ, width: 130 })) : (_jsx(_Fragment, {})), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(IdleThresholdsButton, { width: 30, onShowThreshold: function (showThresholds) {
                    return toggleIdleThreshold(showThresholds, state, dispatch, chartIdentifier, installationPoint);
                }, chartIdentifier: chartIdentifier })) : (_jsx(_Fragment, {})), isTemperatureChart(+chartIdentifier) && (_jsxs(React.Fragment, { children: [ambientDataIsVisible && ambientReadingsExist && (_jsx(NormalizedButton, { width: 165, dispatch: dispatch, normalizedReadingsIsVisible: normalizedReadingsIsVisible })), _jsx(AmbientButton, { dispatch: dispatch, ambientDataIsVisible: ambientDataIsVisible, isDisabled: false, width: 100 })] })), isAutoAlertConditions && isAutoAlertConditionsExists ? (_jsx(AutoAlertConditionsButton, { onClickAutoAlertConditions: function () {
                    return onAutoAlertConditionButtonClick(state, dispatch, chartIdentifier, setStatesChartFeaturesStore, installationPoint, countDays, showSystemLevels, showMlSuggestedLevels, selectedSystemType, measure);
                }, width: 30, isDisabled: false })) : (_jsx(_Fragment, {})), hidePeaks && _jsx(HidePeaksButton, { state: state, dispatch: dispatch, isDisabled: false, width: 30 }), _jsx(TrendButtonDropdown, { width: 60, chart: chartRef.current ? _get(chartRef, 'current.chart') : {}, updateSeries: function (series) { return updateSeries(state, dispatch, series); } }), _jsx(CopyLinkButton, { width: 30, onClickCopyLink: function () { return onCopyLink(chartIdentifier); }, isDisabled: false }), _jsx(ManagementButton, { width: 30, isDisabled: false, chartIdentifier: chartIdentifier }), _jsx(LockTooltipPosition, { lockTooltipPosition: lockTooltipPosition, isVisibleLockTooltip: isVisibleLockTooltip, width: 36, dispatch: dispatch, chartRef: chartRef }), _jsx(LockTooltipButton, { width: 30, isVisibleLockTooltip: isVisibleLockTooltip, onClickLockTooltip: function () { return onToggleLockTooltip(dispatch, !isVisibleLockTooltip, chartRef); } }), _jsx(FullscreenButton, { isDisabled: isSelectionEnabled, width: 29, isVisibleFullscreenChart: isVisibleFullscreenChart, onClickFullscreen: function () { return onToggleFullscreen(dispatch, !isVisibleFullscreenChart); } })] }));
};
export default memo(ChartToolsButtonsWrapper);
