import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PRE_ACTION_LIST } from '../../../../../../constants';
import { DateTimeLabel } from '../../../../../../widgets/common';
import { PreUrgencyLabel } from '../../../../../../widgets/preActionItems';
import { SensorId } from '../../../../../../widgets/sensor';
import { getUsdFormat } from '../../../../../features/watchersStatistic';
import { selectActiveData } from '../../../../../store/watchersStatistic/usePreAiModalStore';
import { selectIsVisiblePreAiModal, useWatchersStatisticModalsActions, } from '../../../../../store/watchersStatistic/useWatchersStatisticModalsStore';
import LinkWithPrefix from '../../../../LinkWithPrefix';
import { WSStatusLabel } from '../../../StatusLabel';
import './index.scss';
var PreAiModal = function () {
    var _a, _b;
    var isVisiblePreAiModal = selectIsVisiblePreAiModal();
    var activeData = selectActiveData();
    var setIsVisiblePreAiModal = useWatchersStatisticModalsActions().setIsVisiblePreAiModal;
    return (_jsxs(Modal, { size: "lg", isOpen: isVisiblePreAiModal, toggle: function () { return setIsVisiblePreAiModal(false); }, className: "pre-ai-modal", children: [_jsx(ModalHeader, { toggle: function () { return setIsVisiblePreAiModal(false); }, children: "Pre Action Item Info" }), _jsx(ModalBody, { children: _jsxs("table", { className: "table table-simply", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Pre-AI created" }), _jsx("th", { children: "State" }), _jsx("th", { children: "Urgency" }), _jsx("th", { children: "Cost Avoided" }), _jsx("th", { children: "Created At" }), _jsx("th", { children: "Pre-AI accepted by" }), _jsx("th", { children: "Sensor ID" })] }) }), _jsx("tbody", { children: ((_a = activeData === null || activeData === void 0 ? void 0 : activeData.units) !== null && _a !== void 0 ? _a : []).length ? (((_b = activeData === null || activeData === void 0 ? void 0 : activeData.units) !== null && _b !== void 0 ? _b : []).map(function (unit, key) {
                                var _a;
                                return (_jsxs("tr", { children: [_jsx("td", { "data-label": "Pre-AI created", children: (_a = activeData === null || activeData === void 0 ? void 0 : activeData.username) !== null && _a !== void 0 ? _a : '---' }), _jsx("td", { "data-label": "State", children: _jsx(WSStatusLabel, { status: _get(unit, 'state') }) }), _jsx("td", { "data-label": "Urgency", children: _jsx(PreUrgencyLabel, { urgency: PRE_ACTION_LIST.URGENCY_IDS_MAP[_get(unit, 'urgency')] }) }), _jsx("td", { "data-label": "Cost Avoided", children: getUsdFormat(_get(unit, 'cost_avoided')) }), _jsx("td", { "data-label": "Created At", children: _jsx(DateTimeLabel, { dateTime: _get(unit, 'created_at') }) }), _jsx("td", { "data-label": "Pre-AI accepted by", children: _get(unit, 'accepted_by') || '---' }), _jsx("td", { "data-label": "Sensor ID", children: _jsx(LinkWithPrefix, { className: "cursor-pointer", target: "_blank", to: "/action-item/".concat(unit.action_item_id), children: _jsx("span", { className: unit.state.toLowerCase() === 'closed' ? '__color-orangered' : '', children: _jsx(SensorId, { sensor_id: +_get(unit, 'sensor_id') }) }) }) })] }, key));
                            })) : (_jsx("tr", { children: _jsx("td", { className: "text-center", colSpan: 7, children: _jsx("b", { children: "There are no data" }) }) })) })] }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: function () {
                            setIsVisiblePreAiModal(false);
                        }, children: "Cancel" }) }) })] }));
};
export default memo(PreAiModal);
