import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheckCircle, FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { DateTimeLabelWithUTC } from '../../../../../widgets/common';
import { selectEditComment, useActionItemViewActions, } from '../../../../store/AIPreAi/useActionItemViewStore';
import { useConfirmModalActions } from '../../../../store/global/useConfirmModalStore';
import HighlighterWord from '../../../shared/HighlighterWord';
var params = new URLSearchParams(window.location.search);
var Comment = function (_a) {
    var comment = _a.comment, userImage = _a.userImage, isViewMode = _a.isViewMode, commentDeleteMutate = _a.commentDeleteMutate, commentDeleteIsLoading = _a.commentDeleteIsLoading, commentSaveIsLoading = _a.commentSaveIsLoading;
    var highlightSearch = params.get('search');
    var commentData = comment.comment, user = comment.user, created_at = comment.created_at, id = comment.id, updatedUser = comment.updatedUser, is_deleted = comment.is_deleted, updated_at = comment.updated_at, deletedUser = comment.deletedUser;
    var editComment = selectEditComment();
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var setEditComment = useActionItemViewActions().setEditComment;
    var onUpdate = function () {
        setEditComment(comment);
    };
    var onDelete = function () {
        setConfirmData({
            isVisible: true,
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                commentDeleteMutate({
                    id: id,
                }, {
                    onSuccess: function () {
                        toast.success('The comment has been successfully deleted.', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                    },
                });
            },
        });
    };
    if (editComment && editComment.id !== id) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "activity-log-regular-comment", children: [_jsxs("div", { className: "d-flex", children: [userImage ? (_jsx("div", { className: "activity-log-regular-comment-icon", style: { background: '#ffcd04' }, children: _jsx("img", { src: userImage, alt: "WW" }) })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "activity-log-regular-comment-user-info", children: [_jsxs("div", { className: "activity-log-regular-comment-name-btns", children: [_jsxs("div", { className: "d-flex align-items-center", children: [(user === null || user === void 0 ? void 0 : user.full_name) || _jsx(_Fragment, {}), updatedUser ? _jsxs("i", { children: [" ", is_deleted === 1 ? '(Deleted)' : '(Edited)'] }) : _jsx(_Fragment, {})] }), _jsx("span", { children: is_deleted !== 1 && !isViewMode ? (!editComment ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Update comment", onClick: function () { return (commentSaveIsLoading ? {} : onUpdate()); }, children: _jsx(FaEdit, {}) }), _jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Delete comment", onClick: function () { return (commentDeleteIsLoading ? {} : onDelete()); }, children: _jsx(FaTrash, {}) })] })) : (_jsx("div", { className: "activity-log-regular-comment-name-btns-smallBtn", title: "Undo editing", onClick: function () {
                                                setConfirmData({
                                                    isVisible: true,
                                                    textConfirmationModal: 'Are you sure you want to deny the action? All changes in the comment will be lost',
                                                    onConfirm: function () {
                                                        setConfirmData({
                                                            isVisible: false,
                                                        });
                                                        setEditComment(null);
                                                    },
                                                });
                                            }, children: _jsx(FaTimes, {}) }))) : (_jsx(_Fragment, {})) })] }), _jsx("div", { className: "activity-log-regular-comment-date", children: _jsx(DateTimeLabelWithUTC, { dateTime: created_at }) })] })] }), _jsx("div", { className: "activity-log-regular-comment-content zoomImg-comments", children: is_deleted !== 1 ? (_jsx(_Fragment, { children: _jsx(HighlighterWord, { searchWords: highlightSearch || '', textToHighlight: commentData }) })) : (_jsxs("p", { children: ["This comment was deleted by ", _jsx("b", { children: deletedUser.full_name || '' }), _jsx("br", {}), " at", ' ', _jsx("i", { children: _jsx(DateTimeLabelWithUTC, { dateTime: updated_at }) })] })) })] }));
};
export default memo(Comment);
