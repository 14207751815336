import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { SPEED_TYPE_LABELS } from '../../../constants';
var NewOldData = function (_a) {
    var id = _a.id, key = _a.key, data = _a.data;
    var isEquipmentSpeedType = function (key) {
        return key === 'equipment_speed_type_id';
    };
    return (_jsx(_Fragment, { children: Object.values(data).map(function (item, index) { return (_jsxs("div", { children: [Object.keys(data)[index], " :", ' ', isEquipmentSpeedType(Object.keys(data)[index])
                    ? _get(SPEED_TYPE_LABELS, item) || 'not set'
                    : item || 'not set'] }, "".concat(id, "_").concat(key, "_").concat(index))); }) }));
};
export default NewOldData;
