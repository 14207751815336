import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from "react";
import DatePicker from "react-datepicker";
import { FaSearchMinus, FaSearchPlus, FaTimes } from "react-icons/fa";
import { Button, ButtonGroup, FormGroup, Input } from "reactstrap";
import { SYSTEM_TYPE_REMASTERED } from "../../../constants";
import { DateInput } from "../../../widgets/common";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import User from "../../helper/user";
import SlaSettingsInfo from "../slaSettings/SlaSettingsInfo";
var Filters = function (_a) {
    var onChangeState = _a.onChangeState, changeFiltersData = _a.changeFiltersData, onResetQuickFilter = _a.onResetQuickFilter, isActiveAisPage = _a.isActiveAisPage, onAdvancedSearch = _a.onAdvancedSearch, onChangeAiType = _a.onChangeAiType, onChangeDateRange = _a.onChangeDateRange, filtersData = _a.filtersData, statesCounts = _a.statesCounts, statusNames = _a.statusNames, showWwAiFilter = _a.showWwAiFilter, _b = _a.isHardwarePage, isHardwarePage = _b === void 0 ? false : _b, dateRange = _a.dateRange;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var total = statesCounts.total, states = statesCounts.states, wwMissCount = statesCounts.wwMissCount, declinedReportsCount = statesCounts.declinedReportsCount, withPreCommentsCount = statesCounts.withPreCommentsCount, noResponseCount = statesCounts.noResponseCount, severityIncreaseCount = statesCounts.severityIncreaseCount, troubleshootAiCount = statesCounts.troubleshootAiCount, waitesNewAiCount = statesCounts.waitesNewAiCount, troubleshootWaitingAiCount = statesCounts.troubleshootWaitingAiCount, troubleshootQuestionAiCount = statesCounts.troubleshootQuestionAiCount, autoAisCount = statesCounts.autoAisCount, customerCreatedAisCount = statesCounts.customerCreatedAisCount;
    if (!total) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(React.Fragment, { children: [_jsx("div", { className: "pb-2", children: _jsxs(ButtonGroup, { className: "response_group response-pad d-inline-block action-list-filters", children: [(states !== null && states !== void 0 ? states : []).map(function (item) { return (_jsx(React.Fragment, { children: item.count_state > 0 ? (_jsxs(Button, { className: "response-button-action", color: filtersData.selectedStatuses && filtersData.selectedStatuses[item.state]
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState(item.state); }, size: "sm", children: [statusNames[item.state], " - ", item.count_state] })) : (_jsx(_Fragment, {})) }, "filter_ai_".concat(item.state))); }), isActiveAisPage && waitesNewAiCount > 0 && selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['waites_new_ai']
                                ? 'primary'
                                : 'secondary', onClick: function () { return onChangeState('waites_new_ai'); }, className: "response-button-action", size: "sm", children: ["WAITES NEW - ", waitesNewAiCount] })) : (_jsx(_Fragment, {})), isActiveAisPage && troubleshootAiCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['troubleshoot_ai']
                                ? 'primary'
                                : 'secondary', onClick: function () { return onChangeState('troubleshoot_ai'); }, className: "response-button-action", size: "sm", children: ["Troubleshooting - ", troubleshootAiCount] })) : (_jsx(_Fragment, {})), isActiveAisPage && troubleshootWaitingAiCount && troubleshootWaitingAiCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['troubleshoot_waiting_ai']
                                ? 'primary'
                                : 'secondary', onClick: function () { return onChangeState('troubleshoot_waiting_ai'); }, className: "response-button-action", size: "sm", children: ["Troubleshooting Waiting - ", troubleshootWaitingAiCount] })) : (_jsx(_Fragment, {})), isActiveAisPage && troubleshootQuestionAiCount && troubleshootQuestionAiCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['troubleshoot_question_ai']
                                ? 'primary'
                                : 'secondary', onClick: function () { return onChangeState('troubleshoot_question_ai'); }, className: "response-button-action", size: "sm", children: ["Troubleshooting Question - ", troubleshootQuestionAiCount] })) : (_jsx(_Fragment, {}))] }) }), _jsxs("div", { className: "pb-2", children: [_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }), _jsxs(ButtonGroup, { className: "response_group response-pad d-inline-block action-list-filters", children: [wwMissCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['ww_missed']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('ww_missed'); }, className: "response-button-action", size: "sm", children: ["Miss - ", wwMissCount] })) : (_jsx(_Fragment, {})), User.can('viewDeclinedCR') && isActiveAisPage && !isHardwarePage && declinedReportsCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['with_declined_reports']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('with_declined_reports'); }, className: "response-button-action", size: "sm", children: ["Declined CR - ", declinedReportsCount] })) : (_jsx(_Fragment, {})), withPreCommentsCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['with_pre_comments']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('with_pre_comments'); }, className: "response-button-action", size: "sm", children: ["Pre-Comments - ", withPreCommentsCount] })) : (_jsx(_Fragment, {})), isActiveAisPage &&
                                severityIncreaseCount > 0 &&
                                (!filtersData.hardwareActionItemsTypes || filtersData.hardwareActionItemsTypes.length === 0) ? (_jsxs(Button, { color: filtersData.increaseSeverity ? 'primary' : 'secondary', onClick: function () {
                                    return changeFiltersData('increaseSeverity', +filtersData.increaseSeverity === 1 ? 0 : 1);
                                }, className: "response-button-action", size: "sm", children: ["Severity - ", severityIncreaseCount] })) : (_jsx(_Fragment, {})), noResponseCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['no_response']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('no_response'); }, className: "response-button-action", size: "sm", children: ["No Response - ", noResponseCount] })) : (_jsx(_Fragment, {})), autoAisCount && autoAisCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['auto_ais']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('auto_ais'); }, className: "response-button-action", size: "sm", children: ["Auto - ", autoAisCount] })) : (_jsx(_Fragment, {})), customerCreatedAisCount && customerCreatedAisCount > 0 ? (_jsxs(Button, { color: filtersData.selectedStatuses && filtersData.selectedStatuses['customer_created']
                                    ? 'primary'
                                    : 'secondary', onClick: function () { return onChangeState('customer_created'); }, className: "response-button-action", size: "sm", children: ["Customer Created - ", customerCreatedAisCount] })) : (_jsx(_Fragment, {})), showWwAiFilter && (_jsx(Button, { color: +filtersData.hide_hardware_ai === 1 ? 'primary' : 'secondary', onClick: function () {
                                    return changeFiltersData('hide_hardware_ai', +filtersData.hide_hardware_ai === 1 ? 0 : 1);
                                }, className: "response-button-action", size: "sm", children: "Hide WWAis" }))] }), filtersData.hardwareActionItemsTypes && filtersData.hardwareActionItemsTypes.length > 0 && (_jsx(FormGroup, { inline: true, className: "d-inline-block ms-2 fz-13", children: _jsxs(Input, { type: "select", size: "sm", className: "d-inline-block ms-2", style: { width: 200 }, onChange: function (ev) { return onChangeAiType(ev.target.value); }, value: filtersData.selectedAiType, children: [_jsx("option", { value: "", children: "All Types" }), filtersData.hardwareActionItemsTypes.map(function (hardwareActionItemsType) { return (_jsx("option", { value: hardwareActionItemsType, children: hardwareActionItemsType }, "ai_type_filter_".concat(hardwareActionItemsType))); }), _jsx("option", { value: "Other", children: "Other" })] }) })), _jsx(Button, { className: "ms-2 me-1 rounded-1 d-none", color: "danger", onClick: onResetQuickFilter, size: "sm", children: _jsx(FaTimes, {}) }), _jsx("span", { className: "float-end", children: _jsx(SlaSettingsInfo, {}) })] }), _jsx(Button, { title: filtersData.advancedSearch
                    ? 'Switch off Advanced Search (search by keywords in action item)'
                    : 'Switch on Advanced Search (search by keywords in action item)', onClick: onAdvancedSearch, size: "xs", className: "toggleSearchBtn ms-2 ".concat(filtersData.advancedSearch ? 'btn-success' : 'btn-primary'), children: filtersData.advancedSearch ? _jsx(FaSearchMinus, {}) : _jsx(FaSearchPlus, {}) })] }));
};
export default memo(Filters);
