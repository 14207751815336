import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { ACTION_LIST } from '../../../../../constants';
import { useDeleteSeverityIncrease } from '../../../../hooks/actionItem/useDeleteSeverityIncrease';
import { useApproveSeverityIncreaseMutation } from '../../../../services/actionItems/useApproveSeverityIncreasedMutation';
import { useDeclineSeverityIncreaseMutation } from '../../../../services/actionItems/useDeclineSeverityIncreasedMutation';
import { selectActivityLogSize, selectEditComment, selectIsGatheringData, selectIsInternalComment, useActionItemViewActions, } from '../../../../store/AIPreAi/useActionItemViewStore';
import '../../../../styles/AIPreAi/activityLog.scss';
import UrgencySelect from '../../../AIPreAi/UrgencySelect';
import ActivityLogToggleSize from '../../../AIPreAi/activityLogHeader/components/ActivityLogToggleSize';
import GatheringDataToggle from '../../../AIPreAi/activityLogHeader/components/GatheringDataToggle';
import SaveAsDefaultSize from '../../../AIPreAi/activityLogHeader/components/SaveAsDefaultSize';
import DefaultTooltip from '../../../DefaultTooltip';
var Header = function (_a) {
    var id = _a.id, isReplaceEquipment = _a.isReplaceEquipment, isViewMode = _a.isViewMode, internalCommentSaveMutate = _a.internalCommentSaveMutate, internalCommentSaveIsLoading = _a.internalCommentSaveIsLoading, internalCommentUpdateMutate = _a.internalCommentUpdateMutate, internalCommentUpdateIsLoading = _a.internalCommentUpdateIsLoading, commentSaveMutate = _a.commentSaveMutate, commentSaveIsLoading = _a.commentSaveIsLoading, commentGatheringDataMutate = _a.commentGatheringDataMutate, commentGatheringDataLoading = _a.commentGatheringDataLoading, commentUpdateMutate = _a.commentUpdateMutate, updatePreCommentMutate = _a.updatePreCommentMutate, commentUpdateIsLoading = _a.commentUpdateIsLoading, updatePreCommentIsLoading = _a.updatePreCommentIsLoading, control = _a.control, handleSubmit = _a.handleSubmit, comments = _a.comments, hasSeverityIncrease = _a.hasSeverityIncrease, storage = _a.storage, onSuccessDeleteSeverityIncrease = _a.onSuccessDeleteSeverityIncrease;
    var activityLogSize = selectActivityLogSize();
    var editComment = selectEditComment();
    var isGatheringData = selectIsGatheringData();
    var isInternalComment = selectIsInternalComment();
    var approveSeverityIncreaseMutate = useApproveSeverityIncreaseMutation({
        id: String(id),
        isPreAi: false,
        storage: storage,
    }).mutate;
    var declineSeverityIncreaseMutate = useDeclineSeverityIncreaseMutation({
        id: String(id),
        isPreAi: false,
        storage: storage,
    }).mutate;
    var confirmDeleteSeverityIncrease = useDeleteSeverityIncrease({
        actionListId: id,
        approveSeverityIncreaseMutate: approveSeverityIncreaseMutate,
        declineSeverityIncreaseMutate: declineSeverityIncreaseMutate,
        isPreAi: false,
        confirmationText: 'The current AI is set to Increase Severity, please process the current data before posting a comment',
        onSuccess: onSuccessDeleteSeverityIncrease,
    }).confirmDeleteSeverityIncrease;
    var setEditComment = useActionItemViewActions().setEditComment;
    var _b = useWatch({
        control: control,
        name: ['comment', 'urgency'],
    }), comment = _b[0], urgency = _b[1];
    var field = useController({
        control: control,
        name: 'comment',
    }).field;
    var setIsGatheringData = useActionItemViewActions().setIsGatheringData;
    var commentCount = useMemo(function () { return comments.filter(function (comment) { return comment.type === ACTION_LIST.COMMENT_TYPE_REGULAR; }).length; }, [comments]);
    var onSubmit = handleSubmit(function () {
        if (hasSeverityIncrease) {
            return confirmDeleteSeverityIncrease();
        }
        if (isGatheringData) {
            return commentGatheringDataMutate({
                action_list_id: id,
                comment: comment,
            }, {
                onSuccess: function () {
                    toast.success('The comment has been successfully created', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    field.onChange('');
                    setIsGatheringData(false);
                },
            });
        }
        if (editComment) {
            if ((editComment === null || editComment === void 0 ? void 0 : editComment.type) === ACTION_LIST.COMMENT_TYPE_INTERNAL) {
                return internalCommentUpdateMutate({
                    id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                    action_list_id: id,
                    comment: comment,
                }, {
                    onSuccess: function () {
                        toast.success('The internal comment has been successfully updated', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                        field.onChange('');
                        setEditComment(null);
                    },
                });
            }
            if ((editComment === null || editComment === void 0 ? void 0 : editComment.is_pre_comment) === 1) {
                return updatePreCommentMutate({
                    id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                    action_list_id: id,
                    comment: comment,
                }, {
                    onSuccess: function () {
                        toast.success('The pre-comment has been successfully updated', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                        field.onChange('');
                        setEditComment(null);
                    },
                });
            }
            return commentUpdateMutate({
                id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                action_list_id: id,
                comment: comment,
            }, {
                onSuccess: function () {
                    toast.success('The comment has been successfully updated', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    field.onChange('');
                    setEditComment(null);
                },
            });
        }
        if (isInternalComment) {
            return internalCommentSaveMutate({
                action_list_id: id,
                comment: comment,
            }, {
                onSuccess: function () {
                    toast.success('The internal comment has been successfully created', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    field.onChange('');
                },
            });
        }
        commentSaveMutate({
            action_list_id: id,
            comment: comment,
            urgency: urgency,
            is_replace_equipment: +isReplaceEquipment,
        }, {
            onSuccess: function () {
                toast.success('The comment has been successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                field.onChange('');
            },
        });
    });
    return (_jsxs("div", { className: "activity-log-header", children: [_jsx("div", { className: "d-flex align-items-center", children: !activityLogSize ? (_jsxs("div", { className: "d-flex align-items-center flex-wrap justify-content-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "aiDefaultActivityLogSize" })] })) : (_jsxs("div", { className: "d-flex align-items-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "aiDefaultActivityLogSize" }), _jsx("div", { className: "activity-log-header-title", children: "Activity Log" }), commentCount ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-comments-count", "data-tooltip-id": "comments_count", children: commentCount || _jsx(_Fragment, {}) }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "comments_count", children: "Comments count" })] })) : (_jsx(_Fragment, {}))] })) }), activityLogSize ? (_jsx("div", { className: "d-flex align-items-center", children: isViewMode ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [editComment || isInternalComment ? _jsx(_Fragment, {}) : _jsx(GatheringDataToggle, {}), editComment || isGatheringData || isInternalComment ? (_jsx(_Fragment, {})) : (_jsx(UrgencySelect, { control: control })), _jsx("div", { className: "activity-log-header-send", id: "send", onClick: function () {
                                return commentSaveIsLoading ||
                                    commentUpdateIsLoading ||
                                    commentGatheringDataLoading ||
                                    updatePreCommentIsLoading ||
                                    internalCommentSaveIsLoading ||
                                    internalCommentUpdateIsLoading
                                    ? {}
                                    : onSubmit();
                            }, children: _jsx(RiSendPlane2Fill, {}) }), _jsx(UncontrolledTooltip, { placement: "left", target: "send", children: editComment ? 'Save changes' : isInternalComment ? 'Save internal comment' : 'Save comment' })] })) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Header);
