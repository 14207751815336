import { get as _get } from 'lodash';
import { setLoader } from '../shared';
export var fetch = function (state, dispatch, endpoint, callbackTo401) {
    setLoader(dispatch, true);
    endpoint()
        .then(function (resp) {
        if (!Object.keys(resp.oauthClients).length) {
            callbackTo401();
        }
        dispatch({
            type: 'setState',
            state: {
                list: resp.oauthClients,
                loader: false,
            },
        });
    })
        .catch(function () {
        setLoader(dispatch, false);
    });
};
export var fetchStatistic = function (dispatch, endpoint) {
    setLoader(dispatch, true);
    endpoint().then(function (resp) {
        dispatch({
            type: 'setState',
            state: {
                statistic: _get(resp, 'data', null),
                loader: false,
            },
        });
    });
};
