import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, last as _last, trim as _trim } from 'lodash';
import React, { memo } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { ACTION_LIST } from '../../../../../../constants';
import { DateTimeLabelWithUTC } from '../../../../../../widgets/common';
import './index.scss';
var WwReportHeader = function (_a) {
    var isNoActionReport = _a.isNoActionReport, isDontCreateWo = _a.isDontCreateWo, verifiedTime = _a.verifiedTime, verifiedBy = _a.verifiedBy, isDeclinedReport = _a.isDeclinedReport, declineReason = _a.declineReason, actionListNoActionsTypes = _a.actionListNoActionsTypes, actionListNoActionsGroups = _a.actionListNoActionsGroups, noActionsGroupId = _a.noActionsGroupId, declinedReports = _a.declinedReports, noActionsType = _a.noActionsType, noActionsGroupOtherText = _a.noActionsGroupOtherText, submitedBy = _a.submitedBy, timeSubmited = _a.timeSubmited, isOpenFromComments = _a.isOpenFromComments, isVisibleReportBody = _a.isVisibleReportBody, isShowCollapseBtn = _a.isShowCollapseBtn, setIsVisibleReportBody = _a.setIsVisibleReportBody;
    return (_jsxs(Row, { className: "ww-report-header ".concat(isVisibleReportBody ? 'active' : '', " ").concat(!isShowCollapseBtn ? 'cursor-default' : ''), onClick: function () { return (isShowCollapseBtn ? setIsVisibleReportBody(!isVisibleReportBody) : {}); }, children: [_jsxs("div", { className: "d-flex", children: [_jsx("div", { className: "d-flex align-items-center", children: isShowCollapseBtn ? (_jsx("div", { className: "me-3 d-flex align-items-center", children: isVisibleReportBody ? _jsx(FaChevronDown, {}) : _jsx(FaChevronRight, {}) })) : (_jsx(_Fragment, {})) }), isNoActionReport && isDontCreateWo ? (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "ww-report-header-notify-status mb-1", children: "This Action Item was submitted to Waites by the Amazon facility, and it was closed without any action being required." }), _jsx("div", { className: "ww-report-header-notify-status", children: "This typically indicates that the component was displaying normal data patterns, so an Amazon Work Order was not created, and a Completion Report was not required." })] })) : (_jsxs(Row, { className: "w-100", children: [_jsx(Col, { md: 9, children: _jsx(DateTimeLabelWithUTC, { dateTime: verifiedTime || '' }) }), _jsx(Col, { md: 3, children: _trim(verifiedBy) ? _jsx("span", { children: verifiedBy }) : _jsx(_Fragment, {}) }), _jsx(Col, { md: 12, children: declineReason
                                    ? 'No action required'
                                    : _get(actionListNoActionsTypes, noActionsType, 'No action required') })] }))] }), isDeclinedReport ? (_jsxs("div", { className: "d-flex", children: ["Completion Report Declined by", ' ', _trim(_get(_last(declinedReports), 'created_by'))
                        ? "".concat(_trim(_get(_last(declinedReports), 'created_by')))
                        : 'Analyst', _jsx("span", { className: "mx-1", children: "on" }), _jsx(DateTimeLabelWithUTC, { dateTime: _get(_last(declinedReports), 'created_at') })] })) : (_jsx(_Fragment, {})), _get(actionListNoActionsGroups, noActionsGroupId) &&
                noActionsType === ACTION_LIST.NO_ACTION_MAINTENANCE &&
                !declineReason ? (_jsxs("div", { children: [_jsx("span", { className: "ww-report-header-title", children: "Reason:" }), ' ', _get(actionListNoActionsGroups, noActionsGroupId)] })) : (_jsx(_Fragment, {})), noActionsGroupId === ACTION_LIST.NO_ACTION_TYPE_OTHER &&
                noActionsGroupOtherText &&
                noActionsType === ACTION_LIST.NO_ACTION_MAINTENANCE ? (_jsx("div", { className: "position-relative", children: _jsx("span", { dangerouslySetInnerHTML: { __html: noActionsGroupOtherText } }) })) : (_jsx(_Fragment, {})), submitedBy && timeSubmited && !isOpenFromComments ? (_jsxs("div", { className: "d-flex", children: [_jsx("span", { className: "ww-report-header-title me-1", children: "Report Submitted:" }), _jsx(DateTimeLabelWithUTC, { dateTime: timeSubmited }), _trim(submitedBy) ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "mx-1", children: "by" }), " ", _jsx("b", { children: submitedBy })] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(WwReportHeader);
