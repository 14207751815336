// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-log-system-comment {
  padding: 20px;
  border-bottom: 1px solid #e2e5ea;
}
.dark-layout .activity-log-system-comment {
  border-bottom: 1px solid #2b2f3d;
}
.activity-log-system-comment-title {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}
.activity-log-system-comment-date {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin: 5px 0;
  color: #555;
}
.dark-layout .activity-log-system-comment-date {
  color: #ccc;
}
.activity-log-system-comment-postscript {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.activity-log-system-comment:first-child {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/activityLog/Comment/component/SystemComment/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;AACF;AAAE;EACE,gCAAA;AAEJ;AAAE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;AAGN;AAAE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".activity-log-system-comment {\n  padding: 20px;\n  border-bottom: 1px solid #e2e5ea;\n  .dark-layout & {\n    border-bottom: 1px solid #2b2f3d;\n  }\n  &-title {\n    text-align: center;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    text-transform: uppercase;\n  }\n  &-date {\n    text-align: center;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    text-transform: uppercase;\n    margin: 5px 0;\n    color: #555;\n    .dark-layout & {\n      color: #ccc;\n    }\n  }\n  &-postscript {\n    text-align: center;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n  }\n  &:first-child {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
