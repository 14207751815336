var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ZOOM_OPTIONS } from '../../../../../../constants';
import { abortInstallationPointRequests } from '../../../../../features/charts/leftEquipmentList';
import { clearAllTooltips } from '../../../../../helper/commonHelper';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { selectChartDefaultZoomDays, selectChartZoomDays, useChartSettingsActions, } from '../../../../../store/charts/useChartSettingsStore';
var ChartZoomSelect = function () {
    var _a = ChartsStore(function (state) { return ({
        countDays: state.countDays,
        isVisibleFftShapes: state.isVisibleFftShapes,
        setStates: state.setStates,
    }); }, shallow), countDays = _a.countDays, isVisibleFftShapes = _a.isVisibleFftShapes, setStates = _a.setStates;
    var chartDefaultZoomDays = selectChartDefaultZoomDays();
    var chartZoomDays = selectChartZoomDays();
    var setChartZoomDays = useChartSettingsActions().setChartZoomDays;
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '84px', fontSize: '14px' })); },
    }); }, []);
    var isInListZoomValue = useMemo(function () {
        return ZOOM_OPTIONS.filter(function (el) { return el.value === (chartZoomDays || ZOOM_OPTIONS[3].value); });
    }, [chartDefaultZoomDays, chartZoomDays]);
    var onChangeRange = function (value) {
        if (value > countDays) {
            abortInstallationPointRequests();
        }
        if (value > 62 && isVisibleFftShapes) {
            toast.error('Zoom should be 2 months or less', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        setChartZoomDays(value);
        setStates({
            isVisibleFftShapes: value > 62 ? false : isVisibleFftShapes,
            countDays: value > countDays ? value : countDays,
        });
        clearAllTooltips();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "select-custom-default", styles: customStyles, id: "chart-zoom", isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: ZOOM_OPTIONS, onChange: function (ev) { return onChangeRange(+ev.value); }, value: isInListZoomValue.length ? isInListZoomValue : { value: chartZoomDays, label: chartZoomDays + 'd' } }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "chart-zoom", children: "Zoom" })] }));
};
export default memo(ChartZoomSelect);
