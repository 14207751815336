import Api from '../../api';
export default {
    uploadManuallyPhoto: function (installationPointId, data, options) {
        if (options === void 0) { options = {}; }
        return Api.file("all-analytics/machine-info/upload-image/".concat(installationPointId), data, options);
    },
    deleteManuallyUploadedPhoto: function (installationPointId, id, data) {
        if (data === void 0) { data = {}; }
        return Api.del("all-analytics/machine-info/delete-image/".concat(installationPointId, "/").concat(id), data);
    },
    setSystemImage: function (installationPointId, id, data) {
        if (data === void 0) { data = {}; }
        return Api.post("all-analytics/machine-info/set-system-image/".concat(installationPointId, "/").concat(id), data);
    },
    getMachineInfoHistory: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-history/".concat(installationPointId), options);
    },
    getActionItemsHistory: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-action-items/".concat(installationPointId), options);
    },
    getLocationLogs: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-location-logs/".concat(installationPointId), options);
    },
    getAvgReadingMachineInfo: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-avg-readings/".concat(installationPointId), options);
    },
    getLocationMapMachineInfo: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-location-map/".concat(installationPointId), options);
    },
    getRunningHoursMachineInfo: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/machine-info/get-running-hours/".concat(installationPointId), options);
    },
};
