import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { READING_TYPES } from '../../../../../constants';
import { formatYAxis3dTrend } from '../../../../components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import { FftTooltip3dTrendFormatter } from '../../../../components/charts/chartsWrapper/components/shared/tooltip';
import { FrequencyConverter } from '../../../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { getUoms } from '../../../../helper/chart';
import { getFromTo, getSeriesY } from '../../../../helper/waterfallPlot';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../../store/global/useGlobalStore';
var Trend = function (_a) {
    var _b;
    var trendFrequency = _a.trendFrequency, setTrendFrequency = _a.setTrendFrequency, units = _a.units, from = _a.from, to = _a.to, series = _a.series, chartIdentifier = _a.chartIdentifier, currentSpeed = _a.currentSpeed;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    _b = getFromTo(from, to), from = _b[0], to = _b[1];
    return (_jsxs(React.Fragment, { children: [_jsxs(FormGroup, { children: [_jsx(Label, { children: "Trend Frequency:" }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "waterfall-trend-frequency", name: "waterfall-trend-frequency", type: "number", step: "0.1", min: FrequencyConverter.fromHz(from, currentSpeed).toType(units).value, max: FrequencyConverter.fromHz(to, currentSpeed).toType(units).value, onChange: function (ev) {
                                    return setTrendFrequency(FrequencyConverter.fromType(units, ev.target.value, currentSpeed).toHz().value);
                                }, value: FrequencyConverter.fromHz(trendFrequency, currentSpeed).toType(units).value }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { children: units }) })] })] }), _jsx(Nouislider, { range: {
                    min: FrequencyConverter.fromHz(from, currentSpeed).toType(units).value,
                    max: FrequencyConverter.fromHz(to, currentSpeed).toType(units).value,
                }, onChange: function (value) {
                    return setTrendFrequency(FrequencyConverter.fromType(units, value, currentSpeed).toHz().value);
                }, start: FrequencyConverter.fromHz(trendFrequency, currentSpeed).toType(units).value, step: 0.01 }), _jsx("div", { className: "mt-3", children: _jsx(HighchartsReact, { highcharts: Highcharts, options: getChartOptions(series, chartIdentifier, FrequencyConverter.fromHz(trendFrequency, currentSpeed).toType(units).value, measure, personalSettingMeasure) }) })] }));
};
var getChartOptions = function (data, chartIdentifier, trendFrequency, measure, personalSettingMeasure) {
    var trendPoints = [];
    var categories = [];
    data = data || [];
    if (trendFrequency >= 0) {
        data.forEach(function (series) {
            if (series.name === 'slice') {
                return;
            }
            var trendFrequencyY = getSeriesY(series, trendFrequency);
            trendPoints.push(trendFrequencyY);
            categories.push(series.meta.categoryName);
        });
    }
    return {
        chart: {
            type: 'line',
            borderColor: 'rgb(241, 238, 233)',
            backgroundColor: 'rgb(241, 238, 233)',
        },
        lang: {
            noData: 'Trend frequency not selected',
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function () { return false; },
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                formatter: function () {
                    formatYAxis3dTrend(this.value);
                },
            },
        },
        series: [
            {
                type: 'line',
                name: 'Trend',
                data: trendPoints,
            },
        ],
        xAxis: {
            categories: categories,
        },
        legend: false,
        exporting: false,
        tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            shape: 'rect',
            useHTML: true,
            shared: true,
            formatter: function () {
                return ReactDOMServer.renderToStaticMarkup((_jsx(FftTooltip3dTrendFormatter, { chart: this.points[0].series.chart, precision: READING_TYPES[chartIdentifier].precision, units: getUoms(chartIdentifier, measure, personalSettingMeasure) })));
            },
        },
    };
};
export default Trend;
