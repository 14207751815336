import { valibotResolver } from '@hookform/resolvers/valibot';
import { array, custom, forward, minLength, nullable, number, object, optional, string, } from 'valibot';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
export var actionItemFormDescriptionSchema = function (selectedSystemType, isEdit) {
    return valibotResolver(object({
        title: string('Title should be string', [minLength(1, 'Title cannot be empty')]),
        urgency: number(),
        tags: array(object({ id: number(), name: string() }), 'Tags should be an array', [
            minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 1 : 0, 'Tags cannot be empty'),
        ]),
        faultRecommendations: array(optional(nullable(object({
            componentType: optional(nullable(number())),
            fault: optional(nullable(number())),
            severity: optional(nullable(number())),
            recommendations: array(number()),
        }))), 'Recommendations should be an array', [
            minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED && !isEdit ? 1 : 0, 'Recommendations cannot be empty'),
        ]),
        description: string('Description should be string', [
            minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 0 : 1, 'Description cannot be empty'),
        ]),
    }, [
        forward(custom(function (_a) {
            var title = _a.title;
            return /^[^\u0400-\u04FF]+$/.test(title);
        }, 'You cannot use Cyrillic characters in title'), ['title']),
        forward(custom(function (_a) {
            var description = _a.description;
            return (selectedSystemType === SYSTEM_TYPE_REMASTERED ||
                (description !== '<p><br></p>' && description !== '<p></p>'));
        }, 'Description cannot be empty'), ['description']),
        forward(custom(function (_a) {
            var description = _a.description;
            return description.length === 0 || /^[^\u0400-\u04FF]+$/.test(description);
        }, 'You cannot use Cyrillic characters in description'), ['description']),
        forward(custom(function (_a) {
            var faultRecommendations = _a.faultRecommendations;
            if (selectedSystemType === SYSTEM_TYPE_AMAZON || isEdit) {
                return true;
            }
            return faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.componentType; });
        }, 'Component Type cannot be empty'), ['faultRecommendations']),
        forward(custom(function (_a) {
            var faultRecommendations = _a.faultRecommendations;
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            var everyFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            if (!isEdit) {
                return everyFaultRecommendationFilled;
            }
            var someRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                // @ts-ignore
                .some(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            var someSeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            return someRecommendationFilled || someSeverityFilled ? everyFaultRecommendationFilled : true;
        }, 'Fault Name cannot be empty'), ['faultRecommendations']),
        forward(custom(function (_a) {
            var faultRecommendations = _a.faultRecommendations;
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            var everyRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                // @ts-ignore
                .every(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            if (!isEdit) {
                return everyRecommendationFilled;
            }
            var someFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            var someSeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            return someFaultRecommendationFilled || someSeverityFilled ? everyRecommendationFilled : true;
        }, 'Recommendations cannot be empty'), ['faultRecommendations']),
        forward(custom(function (_a) {
            var faultRecommendations = _a.faultRecommendations;
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            var everySeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            if (!isEdit) {
                return everySeverityFilled;
            }
            var someRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                // @ts-ignore
                .some(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            var someFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            return someRecommendationFilled || someFaultRecommendationFilled ? everySeverityFilled : true;
        }, 'Severity cannot be empty'), ['faultRecommendations']),
    ]));
};
