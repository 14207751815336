var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { useAxisLabelQuery } from '../../../services/charts/useAxisLabelQuery';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useAxisLabel = function (state, dispatch, chartIdentifier) {
    var _a, _b, _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var options = state.options;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var data = useAxisLabelQuery({
        axisLabelExtraField: selectedSystemType === SYSTEM_TYPE_REMASTERED
            ? {
                customer_id: (_a = installationPoint.customer_id) !== null && _a !== void 0 ? _a : null,
            }
            : {
                facility: (_b = installationPoint.facility) !== null && _b !== void 0 ? _b : null,
            },
        equipmentId: (_c = installationPoint.equipment_id) !== null && _c !== void 0 ? _c : null,
    }).data;
    useEffect(function () {
        var _a, _b;
        if (!((_a = data === null || data === void 0 ? void 0 : data[chartIdentifier]) === null || _a === void 0 ? void 0 : _a[installationPoint.id])) {
            return;
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { title: {
                        text: (_b = data === null || data === void 0 ? void 0 : data[chartIdentifier]) === null || _b === void 0 ? void 0 : _b[installationPoint.id],
                    } }),
            },
        });
    }, [data]);
};
