var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPE_REMASTERED } from '../../../../../../constants';
import '../../../../../../css/contextmenu.css';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { isDistancePinSensor } from '../../../../../features/charts/shared';
import { setActiveChartOnClick } from '../../../../../helper/chart';
import User from '../../../../../helper/user';
import { ChartFeaturesStore } from '../../../../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
var RmsContextMenu = function (_a) {
    var chartIdentifier = _a.chartIdentifier, chartRef = _a.chartRef, children = _a.children, isImpactVueChart = _a.isImpactVueChart, tachDataIsVisible = _a.tachDataIsVisible;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState({}), point = _b[0], setPoint = _b[1];
    var storedTachDataReadings = ChartsStore(function (state) { return state.storedTachDataReadings; }, shallow);
    var _c = ChartFeaturesStore(function (state) { return ({
        removePoint: state.removePoint,
        closestFft: state.closestFft,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), removePoint = _c.removePoint, closestFft = _c.closestFft, setStatesChartFeaturesStore = _c.setStatesChartFeaturesStore;
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        isUniversalAdapter: state.isUniversalAdapter,
    }); }, shallow), installationPoint = _d.installationPoint, isUniversalAdapter = _d.isUniversalAdapter;
    var olderThan2Weeks = !_get(point, 'x') || Date.now() - _get(point, 'x') > 14 * 24 * 60 * 60 * 1000;
    var show = useContextMenu({
        id: "rms-context-menu-".concat(chartIdentifier),
    }).show;
    var onShow = useCallback(function (ev, chartIdentifier) {
        setActiveChartOnClick(ev, chartIdentifier);
        var point = _get(chartRef, 'current.chart.hoverPoint', {}) || {};
        setPoint({
            x: _get(point, 'x'),
            y: _get(point, 'y'),
            chartIdentifier: chartIdentifier,
        });
        show({ event: ev });
    }, [chartRef]);
    var onRemovePoint = useCallback(function () {
        setStatesChartFeaturesStore({
            removePoint: __assign(__assign({}, removePoint), { isVisibleModal: true, point: {
                    x: _get(point, 'x') || 0,
                    y: _get(point, 'y') || 0,
                }, chartIdentifier: _get(point, 'chartIdentifier') }),
        });
    }, [point]);
    var onClosestFft = useCallback(function () {
        setStatesChartFeaturesStore({
            closestFft: __assign(__assign({}, closestFft), { isVisibleModal: true, point: {
                    x: _get(point, 'x') || 0,
                    y: _get(point, 'y') || 0,
                } }),
        });
    }, [point]);
    var onTachData = useCallback(function () {
        var _a;
        setStatesChartFeaturesStore({
            tachDataIsVisible: __assign(__assign({}, tachDataIsVisible), (_a = {}, _a[chartIdentifier] = !tachDataIsVisible[chartIdentifier], _a)),
        });
    }, [tachDataIsVisible]);
    var noRpmData = useMemo(function () {
        return (tachDataIsVisible[chartIdentifier] &&
            storedTachDataReadings.length === 0);
    }, [tachDataIsVisible, storedTachDataReadings]);
    var onSetIdleThreshold = function () {
        setStatesChartFeaturesStore({ isVisibleIdleThresholdModal: true });
    };
    if (isUniversalAdapter || isDistancePinSensor(installationPoint)) {
        return _jsx(React.Fragment, { children: children });
    }
    return (_jsxs(React.Fragment, { children: [_jsx("span", { onContextMenu: function (ev) { return onShow(ev, chartIdentifier); }, children: children }), _jsxs(Menu, { id: "rms-context-menu-".concat(chartIdentifier), children: [!isImpactVueChart && User.can('removeChartPoint') && (_jsx(Item, { disabled: olderThan2Weeks, onClick: onRemovePoint, children: "Remove this point" })), _jsx(Item, { onClick: onClosestFft, children: "Closest FFT" }), !isUniversalAdapter && selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(Item, { onClick: onTachData, disabled: noRpmData, children: noRpmData ? (_jsx(_Fragment, { children: "No RPM data readings" })) : (_jsxs(_Fragment, { children: [tachDataIsVisible[chartIdentifier] ? 'Hide' : 'Show', " RPM data"] })) })) : (_jsx(_Fragment, {})), selectedSystemType === 'remastered' ? (_jsx(Item, { onClick: onSetIdleThreshold, children: "Set Idle Threshold" })) : (_jsx(_Fragment, {}))] })] }));
};
export default RmsContextMenu;
