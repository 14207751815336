var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { saveAs } from 'file-saver';
import { get as _get, map as _map, max as _max, orderBy as _orderBy } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { FaCloud, FaEye, FaMinus, FaPlus } from 'react-icons/fa';
import { TbArrowsSort } from 'react-icons/tb';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledTooltip, } from 'reactstrap';
import { FrequencyConverter } from '../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { getHarmonicPoints, searchPeaks } from '../../components/charts/chartsWrapper/features/shared/cursor';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { ModalsStore } from '../../store/charts/chartsContent/ModalsStore';
var PeaksModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel;
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var peaksModal = ModalsStore(function (state) { return state.peaksModal; }), currentSpeed = InstallationPointStore(function (state) { return state.currentSpeed; }), readings = FftChartsStore(function (state) { return state.readings; }), setStatesChartsStore = ChartsStore(function (state) { return state.setStates; }), setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; }), _b = useState({}), peaksHarmonics = _b[0], setPeaksHarmonics = _b[1], _c = useState('default'), sortMode = _c[0], setSortMode = _c[1], data = _get(readings, peaksModal.chartIdentifier), dataMax = _max(_map(data, 0)), _d = useState(searchPeaks(data).map(function (peak) {
        var point = {
            x: peak[0],
            y: peak[1],
            index: 2,
            series: {
                visible: true,
                userOptions: { enableMouseTracking: true },
                points: data.map(function (item) { return ({ x: item[0], y: item[1] }); }),
            },
        };
        var harmonics = getHarmonicPoints(dataMax, [point], 0), harmonicsSquares = harmonics.map(function (harmonic) { return harmonic.y * harmonic.y; }), harmonicsSumSquares = harmonicsSquares.reduce(function (acum, val) { return acum + val; });
        return {
            peak: peak,
            harmonics: harmonics,
            rms: Math.sqrt(harmonicsSumSquares),
        };
    })), peaksWithHarmonics = _d[0], setPeaksWithHarmonics = _d[1];
    useEffect(function () {
        if (sortMode === 'relevance') {
            setPeaksWithHarmonics(function (prev) { return _orderBy(prev, 'rms', 'desc'); });
            return;
        }
        setPeaksWithHarmonics(function (prev) { return _orderBy(prev, 'peak.0'); });
    }, [sortMode]);
    var onViewHarmonics = function (peak) {
        setPeaksHarmonics(function (prev) {
            var _a;
            var _b;
            return (__assign(__assign({}, prev), (_a = {}, _a[peak[0]] = {
                visible: !((_b = prev[peak[0]]) === null || _b === void 0 ? void 0 : _b.visible),
            }, _a)));
        });
    };
    var onApplyHarmonic = function (peak, harmonics) {
        setStatesChartsStore({ chartCursorType: 'harmonic' });
        setStatesChartFeaturesStore({
            harmonicByPoint: {
                peak: peak,
                harmonics: harmonics,
                chartIdentifier: peaksModal.chartIdentifier,
            },
        });
        onCancel();
    };
    var onDownload = function () {
        var csv = "Frequency (Hz),Frequency (CPM),".concat(currentSpeed ? 'Frequency (Orders),' : '', "Value\n");
        peaksWithHarmonics.forEach(function (_a) {
            var _b;
            var peak = _a.peak, harmonics = _a.harmonics;
            csv += "".concat(FrequencyConverter.fromHz(peak[0], currentSpeed)
                .toHz()
                .numberFormat(), ",").concat(FrequencyConverter.fromHz(peak[0], currentSpeed).toCpm().numberFormat(), ",").concat(currentSpeed ? "".concat(FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().numberFormat(), "x,") : '').concat(peak[1], "\n");
            if ((_b = peaksHarmonics[peak[0]]) === null || _b === void 0 ? void 0 : _b.visible) {
                csv += ',,,,\n';
                csv += ",Frequency (Hz),Frequency (CPM),".concat(currentSpeed ? 'Frequency (Orders),' : '', "Value\n");
                (harmonics || []).forEach(function (harmonic) {
                    csv += ",".concat(FrequencyConverter.fromHz(harmonic.x, currentSpeed)
                        .toHz()
                        .numberFormat(), ",").concat(FrequencyConverter.fromHz(harmonic.x, currentSpeed).toCpm().numberFormat(), ",").concat(currentSpeed
                        ? "".concat(FrequencyConverter.fromHz(harmonic.x, currentSpeed).toOrders().numberFormat(), "x,")
                        : '').concat(harmonic.y, "\n");
                });
                csv += ',,,,,\n';
            }
        });
        saveAs(new Blob([csv], { type: 'text/plain;charset=utf-8' }), 'FFT Peaks.csv');
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xxl", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["FFT PEAKS", _jsxs(Button, { className: "ms-2", color: "link", size: "md", onClick: onDownload, children: [_jsx(FaCloud, {}), " ", _jsx("span", { className: "ms-2", children: "Download List" })] })] }), _jsx(ModalBody, { children: peaksWithHarmonics.length > 0 ? (_jsxs(Table, { striped: true, className: "position-relative responsive_table table-sm", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsxs("th", { children: [_jsx(Button, { outline: sortMode === 'default', onClick: function () {
                                                    return setSortMode(function (prevState) { return (prevState === 'default' ? 'relevance' : 'default'); });
                                                }, id: "peaks-relevance-sorting", className: "ms-2", color: "success", size: "sm", children: _jsx(TbArrowsSort, {}) }), _jsx(UncontrolledTooltip, { target: "peaks-relevance-sorting", children: sortMode === 'default' ? 'Relevance sorting' : 'Default sorting' })] }), _jsx("th", { children: "Frequency (Hz)" }), _jsx("th", { children: "Frequency (CPM)" }), currentSpeed ? _jsx("th", { children: "Frequency (Orders)" }) : _jsx(_Fragment, {}), _jsx("th", { children: "Value" }), _jsx("th", {})] }) }), _jsx("tbody", { children: peaksWithHarmonics.map(function (_a, index) {
                                var _b, _c, _d;
                                var peak = _a.peak, harmonics = _a.harmonics;
                                return (_jsxs(React.Fragment, { children: [_jsxs("tr", { children: [_jsx("td", { children: _jsx(Button, { className: "ms-2", size: "sm", color: ((_b = peaksHarmonics[peak[0]]) === null || _b === void 0 ? void 0 : _b.visible) ? 'warning' : 'primary', onClick: function () { return onViewHarmonics(peak); }, children: ((_c = peaksHarmonics[peak[0]]) === null || _c === void 0 ? void 0 : _c.visible) ? _jsx(FaMinus, {}) : _jsx(FaPlus, {}) }) }), _jsx("td", { children: FrequencyConverter.fromHz(peak[0], currentSpeed).toHz().numberFormat() }), _jsx("td", { children: FrequencyConverter.fromHz(peak[0], currentSpeed).toCpm().numberFormat() }), currentSpeed ? (_jsxs("td", { children: [FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().numberFormat(), "x"] })) : (_jsx(_Fragment, {})), _jsx("td", { children: peak[1] }), _jsx("td", { children: _jsx(Button, { className: "float-end me-2", size: "md", color: "link", onClick: function () { return onApplyHarmonic(peak, harmonics); }, children: _jsx(FaEye, {}) }) })] }), ((_d = peaksHarmonics[peak[0]]) === null || _d === void 0 ? void 0 : _d.visible) ? (_jsx(React.Fragment, { children: (harmonics || []).map(function (harmonic, indexHarmonic) { return (_jsxs("tr", { children: [_jsx("td", {}), _jsx("td", { children: FrequencyConverter.fromHz(harmonic.x, currentSpeed).toHz().numberFormat() }), _jsx("td", { children: FrequencyConverter.fromHz(harmonic.x, currentSpeed).toCpm().numberFormat() }), currentSpeed ? (_jsxs("td", { children: [FrequencyConverter.fromHz(harmonic.x, currentSpeed).toOrders().numberFormat(), "x"] })) : (_jsx(_Fragment, {})), _jsx("td", { children: harmonic.y }), _jsx("td", {})] }, "fft-peak-".concat(peaksModal.chartIdentifier, "-").concat(index, "-").concat(indexHarmonic))); }) })) : (_jsx(_Fragment, {}))] }, "fft-peak-".concat(peaksModal.chartIdentifier, "-").concat(index)));
                            }) })] })) : (_jsx(_Fragment, {})) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default memo(PeaksModal);
