var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { SensorId } from '../../../../../widgets/sensor';
var SuggestedLevelsSettingsInfoSection = function (_a) {
    var selectedInstallationPoints = _a.selectedInstallationPoints, search = _a.search, filters = _a.filters, onlyIntralox = _a.onlyIntralox, selectedEntity = _a.selectedEntity;
    var groupedSelectedInstallationPoints = useMemo(function () {
        return Object.values(selectedInstallationPoints.length === 0
            ? {}
            : selectedInstallationPoints.reduce(function (map, installationPoint) { return ((map[installationPoint.equipment_id] = __spreadArray(__spreadArray([], (map[installationPoint.equipment_id] || []), true), [
                installationPoint,
            ], false)),
                map); }, {}));
    }, [selectedInstallationPoints]);
    var filtersEnabled = search || onlyIntralox || filters.greater || filters.less || filters.notSet || filters.isActions;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-2", children: _jsx("b", { children: "Settings will be applied to:" }) }), groupedSelectedInstallationPoints.length > 0 ? (_jsx("div", { className: "installation-points-list", children: groupedSelectedInstallationPoints.map(function (selectedInstallationPoints) { return (_jsxs("div", { children: [_jsxs("b", { children: [selectedInstallationPoints[0].equipment_name, ": "] }), selectedInstallationPoints.map(function (selectedInstallationPoint) { return (_jsxs("div", { children: ["- ", selectedInstallationPoint.name, ' ', _jsx("b", { children: _jsx(SensorId, { sensor_id: selectedInstallationPoint.sensor_id, allowBigHex: true, hideBrackets: true, showDecId: false }) })] }, "selectedInstallationPoint-".concat(selectedInstallationPoint.id))); })] }, "selectedEquipment-".concat(selectedInstallationPoints[0].equipment_id))); }) })) : (_jsxs("div", { children: ["All installation points on ", _jsx("b", { children: selectedEntity }), ' ', filtersEnabled ? 'which were displayed according to the selected filters:' : '', search ? (_jsxs("b", { children: [_jsx("br", {}), "Search - ", search] })) : (''), onlyIntralox ? (_jsxs("b", { children: [_jsx("br", {}), "Only Intralox"] })) : (''), filters.greater ? (_jsxs("b", { children: [_jsx("br", {}), "Greater ", filters.greater, "%"] })) : (''), filters.less ? (_jsxs("b", { children: [_jsx("br", {}), "Less ", filters.greater, "%"] })) : (''), filters.notSet ? (_jsxs("b", { children: [_jsx("br", {}), "Not set values"] })) : (''), filters.isActions ? (_jsxs("b", { children: [_jsx("br", {}), "Has Actions"] })) : ('')] }))] }));
};
export default memo(SuggestedLevelsSettingsInfoSection);
