import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { READING_TYPES } from "../../../../../../../constants";
import { FftChartsStore } from "../../../../../../store/charts/chartsContent/FftChartsStore";
import "../../../../../../styles/charts/topBar/StartupMode.scss";
var FftAlertsInfo = function () {
    var fftAlerts = FftChartsStore(function (state) { return state.fftAlerts; });
    return (_jsx("div", { className: "startupMode-wrap", children: _jsx("div", { className: "fw-bold", style: { width: 'max-content', fontSize: 12 }, children: Object.keys(fftAlerts).map(function (chartIdentifier) {
                var _a, _b, _c;
                return (_jsx("div", { children: ((_a = fftAlerts[+chartIdentifier]) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_jsxs(_Fragment, { children: [(_b = READING_TYPES[+chartIdentifier]) === null || _b === void 0 ? void 0 : _b.title, ' ', (_c = READING_TYPES[+chartIdentifier]) === null || _c === void 0 ? void 0 : _c.axisShort, _jsx("br", {})] })) : (_jsx(_Fragment, {})) }, "fft-alerts-info-".concat(chartIdentifier)));
            }) }) }));
};
export default React.memo(FftAlertsInfo);
