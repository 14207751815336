import Highcharts from 'highcharts';
export var fetch = function (setData, setLoader, endpoint) {
    setLoader(true);
    endpoint()
        .then(function (resp) {
        if (resp) {
            setData(resp.data);
            setLoader(false);
        }
    })
        .catch(function () {
        setLoader(false);
    });
};
export var chartOptions = function (data, theme) {
    var themeOptions = {
        theme: theme,
        color: theme ? (theme === 'light' ? '#1e222d' : '#f1f1f1') : '#1e222d',
    };
    return {
        lang: {
            contextButtonTitle: 'Print and download options',
            noData: 'There is no data for the selected period',
        },
        title: {
            text: null,
        },
        chart: {
            animation: false,
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'left',
                    verticalAlign: 'top',
                },
                theme: {
                    style: {
                        'font-size': '10px',
                        color: themeOptions.color,
                    },
                },
            },
            panning: false,
            backgroundColor: 'rgb(241, 238, 233)',
            events: {},
            selectionMarkerFill: 'rgba(227, 187, 49, 0.22)',
            marginTop: 30,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    color: themeOptions.color,
                    style: {
                        fontSize: '12px',
                    },
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
        },
        xAxis: {
            ordinal: false,
            crosshair: true,
            type: 'datetime',
            categories: data.map(function (item) { return item['date']; }),
        },
        legend: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                type: 'column',
                name: 'Running hours',
                data: data.map(function (item) { return +Highcharts.numberFormat(item['value'] * 24, 2); }),
            },
        ],
    };
};
