import { valibotResolver } from '@hookform/resolvers/valibot';
import { custom, forward, minLength, number, object, string, } from 'valibot';
export var reportMlSchema = valibotResolver(object({
    comment: string('Comment should be string ', [minLength(1, 'Comment cannot be empty')]),
    urgency: number(),
}, [
    forward(custom(function (_a) {
        var comment = _a.comment;
        return comment !== '<p><br></p>' && comment !== '<p></p>';
    }, 'Comment cannot be empty'), ['comment']),
    forward(custom(function (_a) {
        var comment = _a.comment;
        return /^[^\u0400-\u04FF]+$/.test(comment);
    }, 'You cannot use Cyrillic characters in comment'), ['comment']),
]));
