import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { memo, useContext, useEffect, useState } from 'react';
import Endpoints from '../../../../../api/endpoints/endpoints';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import { selectTheme } from '../../../../store/global/useGlobalStore';
import '../../MachineInfo.scss';
import { chartOptions, fetch } from './features';
var RunningHoursTab = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    var theme = selectTheme();
    useEffect(function () {
        fetch(setData, setLoader, function () {
            return Endpoints[selectedSystemType].getRunningHoursMachineInfo(installationPoint.id);
        });
    }, []);
    return (_jsx("div", { className: "h-100", children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx("div", { className: "mt-3", children: _jsx(HighchartsReact, { highcharts: Highcharts, options: chartOptions(data, theme) }) })) }));
};
export default memo(RunningHoursTab);
