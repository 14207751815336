import Api from '../../api';
export default {
    getFacilityStructure: function (facility, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/facility/structure/".concat(facility), options);
    },
    getFacilityStructureEquipments: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/facility/structure/equipments', options);
    },
};
