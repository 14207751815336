import { useEffect } from 'react';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var usePlotlines = function (state, chartRef, loader) {
    var currentSpeed = InstallationPointStore(function (state) { return state.currentSpeed; });
    var options = state.options;
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        chartRef.current.chart.userOptions.currentSpeed = currentSpeed;
    }, [currentSpeed]);
};
