import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { FaArrowLeft } from "react-icons/fa";
import LinkWithPrefix from "../../LinkWithPrefix";
import "./BackToList.scss";
var BackToList = function (_a) {
    var to = _a.to;
    return (_jsx(LinkWithPrefix, { className: "back-to-list", to: to, children: _jsx("div", { className: "back-to-list-btn", children: _jsx(FaArrowLeft, {}) }) }));
};
export default memo(BackToList);
