import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { READING_TYPES } from '../../../constants';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import { Filters } from '../../components/mlSuggestedAlarmAdjustment/Filters';
import { getUoms } from '../../helper/chart';
export var config = function (onViewClick, measure, personalSettingMeasure) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point Name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { sensor_id: +row.sensor_id, hexBreak: true, notShowEmptyHex: true });
                },
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
                component: function (row) {
                    return (_jsxs(_Fragment, { children: [READING_TYPES[row.reading_type_id]['title'], ' ', READING_TYPES[row.reading_type_id]['axis']] }));
                },
            },
            {
                name: 'caution_value',
                title: 'Caution Value',
                sortable: false,
                component: function (row) {
                    return (_jsxs(_Fragment, { children: [row.caution_value, " ", getUoms(row.reading_type_id, measure, personalSettingMeasure)] }));
                },
            },
            {
                name: 'warning_value',
                title: 'Warning Value',
                sortable: false,
                component: function (row) {
                    return (_jsxs(_Fragment, { children: [row.warning_value, " ", getUoms(row.reading_type_id, measure, personalSettingMeasure)] }));
                },
            },
            {
                name: 'created_at',
                title: 'Created At',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "mlSuggestedAlarmAdjustment_".concat(row.id, "_created_at"), dateTime: row.created_at }));
                },
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "mlSuggestedAlarmAdjustment_".concat(row.id, "_updated_at"), dateTime: row.updated_at }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsx("div", { className: "d-flex", children: _jsxs(ButtonGroup, { className: "ms-2", children: [_jsx(Button, { onClick: function () {
                                        return onViewClick(row.installation_point_id, "&scroll_to=".concat(row.reading_type_id, "&show_ml_suggested_levels=1"));
                                    }, className: "btn btn-sm btn-success", children: "View" }), _jsx(Button, { color: "primary", onClick: function () { return callbacks.onConfirm(row.id); }, size: "sm", children: "Confirm" }), _jsx(Button, { color: "danger", onClick: function () { return callbacks.onDelete(row.id); }, size: "sm", children: "Delete" })] }) }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return _jsx(Filters, { onChangeFilter: callbacks.onChangeFilter, filtersData: filtersData });
        },
        filtersData: {
            has_data_readings: 1,
        },
        withScroll: true,
    };
};
