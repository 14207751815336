var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router';
import Confirmation from '../../../modals/confirm/Confirm';
import MetricsModal from '../../../modals/metrics/Metrics';
import { getIsBlockedActionOnAIPreAIPage } from '../../../shared/helper/commonHelper';
import User from '../../../shared/helper/user';
import HotkeysModal from '../../../shared/modals/Hotkeys';
import Router from '../../../shared/router/Router';
import { selectIsVisibleHotkeysModal } from '../../../shared/store/hotkeys/useHotkeysModalStore';
var MainContent = function (_a) {
    var isFullSizeSidebar = _a.isFullSizeSidebar, props = _a.props, state = _a.state, dispatch = _a.dispatch, handleScrollStart = _a.handleScrollStart, handleScrollStop = _a.handleScrollStop;
    var selectedFacility = state.selectedFacility, selectedZone = state.selectedZone, selectedArea = state.selectedArea, refreshFlag = state.refreshFlag, isVisibleMetricsModal = state.isVisibleMetricsModal;
    var pathname = useLocation().pathname;
    var user = User.get();
    var isVisibleHotkeysModal = selectIsVisibleHotkeysModal();
    var isBlockedToggleSidebarSize = getIsBlockedActionOnAIPreAIPage(pathname);
    var _b = useMatomo(), trackPageView = _b.trackPageView, pushInstruction = _b.pushInstruction;
    useEffect(function () {
        if (user === null || user === void 0 ? void 0 : user.full_name) {
            pushInstruction('setUserId', user === null || user === void 0 ? void 0 : user.full_name);
        }
        trackPageView({
            documentTitle: (document === null || document === void 0 ? void 0 : document.title) || 'Global analytic dashboard',
            href: pathname,
        });
    }, [pathname, user]);
    return (_jsx(Scrollbars, { className: "main-scrollbar ".concat(isBlockedToggleSidebarSize ? 'without-default-styles' : '', " ").concat(pathname.includes('/dashboard') ? 'ms-300' : '', " ").concat(!isFullSizeSidebar || isBlockedToggleSidebarSize ? 'sidebarMinimumView' : '', " new-design"), style: isBlockedToggleSidebarSize ? { width: 'calc(100% - 60px)' } : {}, renderView: function (props) { return _jsx("div", __assign({}, props, { id: "scroll-container", className: "view" })); }, renderTrackHorizontal: function (props) { return _jsx("div", __assign({}, props, { className: "track-horizontal" })); }, renderThumbHorizontal: function (props) { return _jsx("div", __assign({}, props, { className: "thumb-horizontal" })); }, renderTrackVertical: function (props) { return _jsx("div", __assign({}, props, { className: "track-vertical" })); }, renderThumbVertical: function (props) { return _jsx("div", __assign({}, props, { className: "thumb-vertical" })); }, onScrollStart: handleScrollStart, onScrollStop: handleScrollStop, children: _jsxs("div", { className: pathname.includes('/dashboard') ? '' : 'main-container', children: [_jsx(Router, __assign({}, props, { selectedFacility: selectedFacility ? selectedFacility : null, selectedZone: selectedZone ? selectedZone : null, selectedArea: selectedArea ? selectedArea : null, refreshFlag: refreshFlag, layoutDispatch: dispatch })), _jsx(Confirmation, {}), isVisibleMetricsModal ? _jsx(MetricsModal, { layoutDispatch: dispatch }) : _jsx(_Fragment, {}), isVisibleHotkeysModal ? _jsx(HotkeysModal, {}) : _jsx(_Fragment, {})] }) }));
};
export default memo(MainContent);
