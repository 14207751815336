var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
var initialState = {
    isVisibleMachineInfoModal: false,
    isVisibleTwfDetectionsReport: false,
    isVisibleRequestOnDemandModal: false,
    isVisibleInListModal: false,
    isVisibleReplaceBatteryModal: false,
    isVisiblePreAiReplaceBatteryModal: false,
    isSelectionEnabled: false,
    isVisibleReplaceSensorModal: false,
    isVisiblePreAiReplaceSensorModal: false,
    isVisibleImageModal: false,
    inListModalType: '',
    equipmentTypeForReplace: '',
    chartSelectionType: '',
    isHardwareReadingsModalVisible: false,
    peaksModal: {
        visible: false,
        chartIdentifier: 0,
    },
};
export var ModalsStore = create()(devtools(function (set) { return (__assign(__assign({}, initialState), { setStates: function (states) {
        set(states, false, 'ChartModalsStoreSetStates');
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'ChartModalsStoreStore' }));
