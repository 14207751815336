import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import ActiveActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/ActiveActionItemsOnEquipment';
import PreviousActionItemsOnEquipment from '../../../../shared/components/AIPreAi/OnEquipment/PreviousActionItemsOnEquipment';
import SectionPosition from '../../../../shared/components/AIPreAi/SectionPosition';
import SensorInfoBlock, { DEFAULT_DIRECTION, } from '../../../../shared/components/AIPreAi/SensorInfoBlock/SensorInfoBlock';
import Description from '../../../../shared/components/preActionItem/Description';
import { useWidthContent } from '../../../../shared/hooks/AIPreAi/useWidthContent';
import MlReportModal from '../../../../shared/modals/AIPreAi/MlReportModal';
import PreviousAiReportModal from '../../../../shared/modals/actionItem/PreviousAiReport/PreviousAiReport';
import { useApprovePreActionItemMutation } from '../../../../shared/services/preActionItems/useApprovePreActionItemMutation';
import { useCreateMutation } from '../../../../shared/services/preActionItems/useCreateMutation';
import { useDeclinePreActionItemMutation } from '../../../../shared/services/preActionItems/useDeclinePreActionItemMutation';
import { useEditMutation } from '../../../../shared/services/preActionItems/useEditMutation';
import { useReplaceMoteBatteryMutation } from '../../../../shared/services/preActionItems/useReplaceMoteBatteryMutation';
import { useReplaceNodeBatteriesMutation } from '../../../../shared/services/preActionItems/useReplaceNodeBatteriesMutation';
import { useReplaceSensorMoteMutation } from '../../../../shared/services/preActionItems/useReplaceSensorMoteMutation';
import { useReplaceSensorMutation } from '../../../../shared/services/preActionItems/useReplaceSensorMutation';
import { useActionItemModalsActions } from '../../../../shared/store/AIPreAi/useActionItemModalsStore';
import { selectMlReportModal } from '../../../../shared/store/AIPreAi/usePreActionItemModalsStore';
import { selectIsEditSections, selectSectionPosition, usePreActionItemViewActions, } from '../../../../shared/store/preActionItem/usePreActionItemViewStore';
import { sensorInfoConfig } from '../../../config/preActionItems/sensorInfo';
import { INACTIVE_STATES } from '../../../services/actionItems/useActionItemQuery';
import { usePreActionItemQuery, } from '../../../services/preActionItems/usePreActionItemQuery';
import StatusLabelRemastered from '../../AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../AIPreAi/UrgencyLabel';
var Content = function () {
    var preActionItemId = useParams().id;
    var params = new URLSearchParams(window.location.search);
    var highlightSearch = params.get('search');
    var preActionItemData = usePreActionItemQuery({ preActionItemId: preActionItemId }).data;
    var existingActionItems = preActionItemData.existingActionItems, description = preActionItemData.description, id = preActionItemData.id, title = preActionItemData.title, installationPoint = preActionItemData.installationPoint, urgency = preActionItemData.urgency, isShowEdit = preActionItemData.isShowEdit, photos = preActionItemData.photos, isReplaceEquipment = preActionItemData.isReplaceEquipment, replacementType = preActionItemData.replacementType, installationPointId = preActionItemData.installationPointId, equipmentType = preActionItemData.equipmentType, serial = preActionItemData.serial, defaultDeclineReasons = preActionItemData.defaultDeclineReasons, declineReason = preActionItemData.declineReason, activeActionList = preActionItemData.activeActionList, storage = preActionItemData.storage, isNewPreAi = preActionItemData.isNewPreAi, preActionListTags = preActionItemData.preActionListTags, tags = preActionItemData.tags;
    var _a = useEditMutation({ preActionItemId: preActionItemId, storage: storage }), editMutate = _a.mutate, editIsLoading = _a.isPending;
    var _b = useCreateMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }), createMutate = _b.mutate, createIsLoading = _b.isPending;
    var _c = useApprovePreActionItemMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }), approveMutate = _c.mutate, approveIsLoading = _c.isPending;
    var _d = useReplaceMoteBatteryMutation({ preActionItemId: preActionItemId, storage: storage }), replaceMoteBatteryMutate = _d.mutate, replaceMoteBatteryIsLoading = _d.isPending;
    var _e = useReplaceNodeBatteriesMutation({ preActionItemId: preActionItemId, storage: storage }), replaceNodeBatteriesMutate = _e.mutate, replaceNodeBatteriesIsLoading = _e.isPending;
    var _f = useReplaceSensorMoteMutation({ preActionItemId: preActionItemId, storage: storage }), replaceSensorMoteMutate = _f.mutate, replaceSensorMoteIsLoading = _f.isPending;
    var _g = useReplaceSensorMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }), replaceSensorMutate = _g.mutate, replaceSensorIsLoading = _g.isPending;
    var declinePreActionItemMutate = useDeclinePreActionItemMutation({
        preActionItemId: preActionItemId,
        storage: storage,
    }).mutate;
    var setIdPreviousAiReportForModal = useActionItemModalsActions().setIdPreviousAiReportForModal;
    var setSectionPosition = usePreActionItemViewActions().setSectionPosition;
    var isEditSections = selectIsEditSections();
    var sectionPosition = selectSectionPosition();
    var mlReportModal = selectMlReportModal();
    var localStorageKey = 'preAiSensorInfoBlockSaveAsDefault';
    var storageState = localStorage.getItem(localStorageKey)
        ? Number(localStorage.getItem(localStorageKey))
        : DEFAULT_DIRECTION;
    var _h = useState(storageState), isContentVisible = _h[0], setIsContentVisible = _h[1];
    var config = useMemo(function () {
        return sensorInfoConfig(highlightSearch || '', preActionItemData, isNewPreAi ? [] : ['sla'], isContentVisible);
    }, [preActionItemData, isContentVisible]);
    var width = useWidthContent();
    var sections = {
        'Technical info': (_jsx(SensorInfoBlock, { blocks: config, photos: photos, isContentVisible: isContentVisible, setIsContentVisible: setIsContentVisible, localStorageKey: localStorageKey })),
        'Action Items Already Exists On This Equipment': (_jsx(ActiveActionItemsOnEquipment, { localStorageKey: "preAiOnEquipmentSaveAsDefault", existingActionItems: existingActionItems, archiveStates: INACTIVE_STATES, statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelRemastered, { commentsCount: 0, status: +existingActionItem.state, isWaitesNew: existingActionItem.is_waites_new, isTroubleshootingHold: +existingActionItem.is_troubleshooting_hold, isTroubleshootingWaiting: +existingActionItem.is_troubleshooting_waiting }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency }) })] })); } })),
        'Pre-Action Item description': (_jsx(Description, { description: description, isShowEdit: isShowEdit, id: id, title: title, installationPoint: installationPoint, urgency: urgency, defaultDeclineReasons: defaultDeclineReasons, isReplaceEquipment: isReplaceEquipment, replacementType: replacementType, editIsLoading: editIsLoading, createIsLoading: createIsLoading, editMutate: editMutate, createMutate: createMutate, approveMutate: approveMutate, approveIsLoading: approveIsLoading, replaceMoteBatteryMutate: replaceMoteBatteryMutate, replaceMoteBatteryIsLoading: replaceMoteBatteryIsLoading, replaceNodeBatteriesMutate: replaceNodeBatteriesMutate, replaceNodeBatteriesIsLoading: replaceNodeBatteriesIsLoading, replaceSensorMoteMutate: replaceSensorMoteMutate, replaceSensorMoteIsLoading: replaceSensorMoteIsLoading, replaceSensorMutate: replaceSensorMutate, replaceSensorIsLoading: replaceSensorIsLoading, localStorageKey: "preAiDescriptionSaveAsDefault", installationPointId: installationPointId, equipmentType: equipmentType, serial: serial, activeActionList: activeActionList, declineReason: declineReason, preActionListTags: preActionListTags, tags: tags, declinePreActionItemMutate: declinePreActionItemMutate, existingActionItems: existingActionItems, storage: storage })),
        'Previous Action Items': (_jsx(PreviousActionItemsOnEquipment, { localStorageKey: "preAiPreviousActionItemsOnEquipmentSaveAsDefault", installationPointCurrent: installationPoint, existingActionItems: existingActionItems, archiveStates: INACTIVE_STATES, onViewCommentsClickAction: function (id) { return setIdPreviousAiReportForModal(id); }, statusRow: function (existingActionItem) { return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx("div", { className: "me-2", children: _jsx(StatusLabelRemastered, { commentsCount: 0, status: +existingActionItem.state, isWaitesNew: existingActionItem.is_waites_new, isTroubleshootingHold: +existingActionItem.is_troubleshooting_hold, isTroubleshootingWaiting: +existingActionItem.is_troubleshooting_waiting }) }), _jsx("div", { children: _jsx(UrgencyLabel, { urgency: +existingActionItem.urgency }) })] })); } })),
    };
    return (_jsxs(ReactSortable, { animation: 250, ghostClass: "active", list: sectionPosition, setList: setSectionPosition, disabled: !isEditSections, style: {
            width: width,
            marginTop: '20px',
            transition: '.2s',
        }, children: [_jsx(SectionPosition, { sectionPosition: sectionPosition, isEditSections: isEditSections, sections: sections }), _jsx(PreviousAiReportModal, { existingActionItems: existingActionItems, onCancel: function () { return setIdPreviousAiReportForModal(null); } }), mlReportModal.visible && mlReportModal.activeMlReport ? (_jsx(MlReportModal, { installationPoint: installationPoint, activeMlReport: mlReportModal.activeMlReport })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Content);
