import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "reactstrap";
import { READING_TYPE_ACCELERATION_X, READING_TYPE_PK_PK_X, READING_TYPE_TEMPERATURE, READING_TYPE_VELOCITY_X, } from "../../../../constants";
import { DateInput } from "../../../../widgets/common";
import { getUoms } from "../../../helper/chart";
import { selectMeasure, selectPersonalSettingMeasure } from "../../../store/global/useGlobalStore";
import HighValueFilterRow from "./HighValueFilterRow";
var Filters = function (_a) {
    var defaultHighValues = _a.defaultHighValues, onChangeHighValues = _a.onChangeHighValues, onChangeDateRange = _a.onChangeDateRange, dateRange = _a.dateRange;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(Row, { className: "m-2", children: [_jsxs(Col, { md: 10, children: [_jsxs(Row, { className: "mb-1", children: [_jsx(Col, { md: 4, children: _jsx("span", { className: "text-muted", children: "show alarm levels" }) }), _jsx(Col, { md: 2, children: _jsx("span", { className: "text-muted", children: "min" }) }), _jsx(Col, { md: 2, children: _jsx("span", { className: "text-muted", children: "max" }) })] }), _jsx(HighValueFilterRow, { type: "temperature", measure: getUoms(READING_TYPE_TEMPERATURE, measure, personalSettingMeasure), defaultState: defaultHighValues.temperature, onChangeHighValues: onChangeHighValues }), _jsx(HighValueFilterRow, { type: "acceleration", measure: getUoms(READING_TYPE_ACCELERATION_X, measure, personalSettingMeasure), defaultState: defaultHighValues.acceleration, onChangeHighValues: onChangeHighValues }), _jsx(HighValueFilterRow, { type: "velocity", measure: getUoms(READING_TYPE_VELOCITY_X, measure, personalSettingMeasure), defaultState: defaultHighValues.velocity, onChangeHighValues: onChangeHighValues }), _jsx(HighValueFilterRow, { type: "pk-pk", measure: getUoms(READING_TYPE_PK_PK_X, measure, personalSettingMeasure), defaultState: defaultHighValues['pk-pk'], onChangeHighValues: onChangeHighValues })] }), _jsx(Col, { md: 2, children: _jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }) })] }));
};
export default memo(Filters);
