var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var deleteMissedAiItem = function (endpoint, id, setConfirmData, callback, setLoader) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want to remove this comment?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            setLoader();
            endpoint(id).then(function (resp) {
                if (resp.success) {
                    callback();
                }
            });
        },
    });
};
export var onChangeUrgencyFilter = function (state, dispatch, filter) {
    var _a, _b;
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { urgency: ((_b = (_a = state === null || state === void 0 ? void 0 : state.config) === null || _a === void 0 ? void 0 : _a.filtersData) === null || _b === void 0 ? void 0 : _b.urgency) === filter ? '' : filter }) }),
        },
    });
};
