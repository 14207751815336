import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var componentsKey = function (facility, zone, area, equipmentIds) { return ['components', facility, zone, area, equipmentIds]; };
var componentsFn = function (_a) {
    var _b;
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/facility/".concat(queryKey[1], "/components"), {
        query: {
            selected_zone: queryKey[2],
            selected_area: queryKey[3],
            selected_equipment_ids: (_b = queryKey[4]) !== null && _b !== void 0 ? _b : [],
        },
    });
};
export var useComponentsQuery = function (_a) {
    var facility = _a.facility, zone = _a.zone, area = _a.area, equipmentIds = _a.equipmentIds;
    return useQuery({
        queryKey: componentsKey(facility, zone, area, equipmentIds),
        queryFn: componentsFn,
        select: function (_a) {
            var _b;
            var data = _a.data;
            return {
                components: (_b = data.map(function (_a) {
                    var component = _a.component;
                    return {
                        name: component,
                        component: component,
                    };
                })) !== null && _b !== void 0 ? _b : [],
            };
        },
        placeholderData: {
            data: [],
        },
        enabled: !!facility,
    });
};
