var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
var params = new URLSearchParams(decodeURIComponent(window.location.search));
export var LeftEquipmentListStore = create()(devtools(function (set, get) { return ({
    equipments: [],
    lastReceivedEquipmentsCount: 0,
    isLoading: true,
    isAvailableEquipments: true,
    offset: 0,
    limit: 15,
    isTriggeredByScroll: false,
    filter: {
        location_id: params.get('location_id') || null,
        in_action_list: 0,
        in_bad_list: 0,
        in_watchlist: 0,
        in_pre_ai_list: 0,
        in_detached_list: 0,
        not_installed: 0,
        with_tachometer: 0,
        distance: 0,
        search: params.get('equipment') || params.get('node') || '',
        installation_point: params.get('installation_point') || null,
        filter_by_installation_point: params.get('node') ? 1 : 0,
    },
    equipmentIsExpanded: null,
    fetchEquipments: function (endpoint, callback, setInList, inList) { return __awaiter(void 0, void 0, void 0, function () {
        var data, equipments, newInList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    get().setLoader(true);
                    return [4 /*yield*/, endpoint().catch(function () {
                            get().setLoader(false);
                        })];
                case 1:
                    data = _a.sent();
                    if (!window.location.pathname.includes('/dashboard')) {
                        get().setLoader(false);
                    }
                    if (!data || !window.location.pathname.includes('/dashboard')) {
                        return [2 /*return*/];
                    }
                    equipments = data ? data.equipments : [];
                    newInList = data ? data.inList : {};
                    set(function (state) {
                        if (!state.isTriggeredByScroll) {
                            callback(equipments);
                        }
                        setInList(state.isTriggeredByScroll
                            ? {
                                actionList: __assign(__assign({}, inList.actionList), newInList.actionList),
                                alertsList: __assign(__assign({}, inList.alertsList), newInList.alertsList),
                                badList: __assign(__assign({}, inList.badList), newInList.badList),
                                preActionList: __assign(__assign({}, inList.preActionList), newInList.preActionList),
                                watchList: __assign(__assign({}, inList.watchList), newInList.watchList),
                                wwActionList: __assign(__assign({}, inList.wwActionList), newInList.wwActionList),
                                wwPreActionList: __assign(__assign({}, inList.wwPreActionList), newInList.wwPreActionList),
                                detachedList: __assign(__assign({}, inList.detachedList), newInList.detachedList),
                                nodesAiList: __assign(__assign({}, inList.nodesAiList), newInList.nodesAiList),
                                nodesAllAiList: __assign(__assign({}, inList.nodesAllAiList), newInList.nodesAllAiList),
                                nodesAllPreAiList: __assign(__assign({}, inList.nodesAllPreAiList), newInList.nodesAllPreAiList),
                            }
                            : newInList);
                        return {
                            equipments: state.isTriggeredByScroll ? __spreadArray(__spreadArray([], state.equipments, true), equipments, true) : equipments,
                            isLoading: false,
                            isTriggeredByScroll: false,
                            isAvailableEquipments: state.isTriggeredByScroll || equipments.length > 0,
                            lastReceivedEquipmentsCount: equipments === null || equipments === void 0 ? void 0 : equipments.length,
                        };
                    }, false, 'LeftEquipmentListStoreSetEquipmentsOnFetch');
                    return [2 /*return*/];
            }
        });
    }); },
    onInfiniteScroll: function (offset) {
        set({ offset: offset, isTriggeredByScroll: true, isLoading: true }, false, 'LeftEquipmentListStoreOnInfiniteScroll');
    },
    setFilter: function (filterName, value) {
        set(function (state) {
            var _a;
            return ({ filter: __assign(__assign({}, state.filter), (_a = {}, _a[filterName] = value, _a)) });
        }, false, 'LeftEquipmentListStoreSetFilter');
    },
    setLoader: function (isLoading) {
        set({ isLoading: isLoading }, false, 'LeftEquipmentListStoreSetLoader');
    },
    resetFilters: function () {
        set({
            isLoading: true,
            filter: {
                location_id: params.get('location_id') || null,
                in_action_list: 0,
                in_bad_list: 0,
                in_watchlist: 0,
                in_pre_ai_list: 0,
                in_detached_list: 0,
                not_installed: 0,
                with_tachometer: 0,
                distance: 0,
                search: params.get('equipment') || params.get('node') || '',
                installation_point: params.get('installation_point') || null,
                filter_by_installation_point: params.get('node') ? 1 : 0,
            },
        }, false, 'LeftEquipmentListStoreResetFilters');
    },
    toggleExpand: function (value) {
        set({ equipmentIsExpanded: value }, false, 'LeftEquipmentListStoreToggleExpand');
    },
    setHeatsink: function (isHeatsink, equipmentId, installationPointId) {
        set(function (state) {
            return __assign(__assign({}, state), { equipments: state.equipments.map(function (equipment) {
                    var _a;
                    if (equipment.id !== equipmentId) {
                        return equipment;
                    }
                    return __assign(__assign({}, equipment), { installationPoints: ((_a = equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) !== null && _a !== void 0 ? _a : []).map(function (installationPoint) {
                            if (!installationPointId) {
                                return __assign(__assign({}, installationPoint), { is_heatsink: +isHeatsink });
                            }
                            if (installationPoint.id !== installationPointId) {
                                return installationPoint;
                            }
                            if (installationPoint.id === installationPointId) {
                                return __assign(__assign({}, installationPoint), { is_heatsink: +isHeatsink });
                            }
                        }) });
                }) });
        });
    },
}); }, { name: 'LeftEquipmentListStore' }));
