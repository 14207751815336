import { createStore } from '../../createStore';
var useChartManagementStore = createStore(function (set) { return ({
    isVisibleManagementModal: false,
    chartIdentifiers: [],
    actions: {
        setIsVisibleManagementModal: function (isVisibleManagementModal) { return set({ isVisibleManagementModal: isVisibleManagementModal }); },
        setChartIdentifier: function (chartIdentifiers) { return set({ chartIdentifiers: chartIdentifiers }); },
    },
}); });
export var useChartManagementActions = function () {
    return useChartManagementStore(function (state) { return state.actions; });
};
export var selectIsVisibleManagementModal = function () {
    return useChartManagementStore(function (state) { return state.isVisibleManagementModal; });
};
export var selectChartIdentifiers = function () {
    return useChartManagementStore(function (state) { return state.chartIdentifiers; });
};
