// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ww-report-body,
.regular-report-body {
  font-size: 14px;
}
.ww-report-body .report-row,
.regular-report-body .report-row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e5ea;
}
.dark-layout .ww-report-body .report-row,
.dark-layout .regular-report-body .report-row {
  border-bottom: 1px solid #2b2f3d;
}
.ww-report-body .report-row img,
.regular-report-body .report-row img {
  max-width: 100% !important;
  max-height: 300px;
}
.ww-report-body .report-row span,
.regular-report-body .report-row span {
  color: #777;
}
.dark-layout .ww-report-body .report-row span,
.dark-layout .regular-report-body .report-row span {
  color: #ccc;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/actionItem/reports/index.scss"],"names":[],"mappings":"AAAA;;EAEE,eAAA;AACF;AAAE;;EACE,iBAAA;EACA,oBAAA;EACA,gCAAA;AAGJ;AAFI;;EACE,gCAAA;AAKN;AAHI;;EACE,0BAAA;EACA,iBAAA;AAMN;AAJI;;EACE,WAAA;AAON;AANM;;EACE,WAAA;AASR","sourcesContent":[".ww-report-body,\n.regular-report-body {\n  font-size: 14px;\n  & .report-row {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    border-bottom: 1px solid #e2e5ea;\n    .dark-layout & {\n      border-bottom: 1px solid #2b2f3d;\n    }\n    & img {\n      max-width: 100% !important;\n      max-height: 300px;\n    }\n    & span {\n      color: #777;\n      .dark-layout & {\n        color: #ccc;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
