import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { INSTALLATION_POINT_GROUP, NODE_GROUP, } from '../../store/analystNotes/useAnalystNotesFormModalStore';
export var getConfirmationText = function (_a, selectedSystemType) {
    var installationPointId = _a.installationPointId, nodeId = _a.nodeId, customer = _a.customer, facility = _a.facility, zone = _a.zone, area = _a.area, locations = _a.locations, equipments = _a.equipments, components = _a.components, selectedGroup = _a.selectedGroup;
    if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
        if (selectedGroup === INSTALLATION_POINT_GROUP) {
            return "<div class=\"d-flex flex-column\">\n            Are you sure you want to create a note? A note will be displayed for:\n                <div class=\"d-flex flex-column align-items-start mt-2\">\n                    <span class=\"d-flex gap-1\">Installation point: <b>".concat(installationPointId, "</b></span>\n                </div>\n            </div>");
        }
        if (selectedGroup === NODE_GROUP) {
            return "<div class=\"d-flex flex-column\">\n            Are you sure you want to create a note? A note will be displayed for all points on:\n                <div class=\"d-flex flex-column align-items-start mt-2\">\n                    <span class=\"d-flex gap-1\">Node: <b>".concat(nodeId, "</b></span>\n                </div>\n            </div>");
        }
        return "<div class=\"d-flex flex-column\">\n            Are you sure you want to create a note? A note will be displayed for all points on:\n                <div class=\"d-flex flex-column align-items-start mt-2\">\n                    <span class=\"d-flex gap-1\">Customer: <b>".concat((customer === null || customer === void 0 ? void 0 : customer.name) || 'All', "</b></span>\n                    <span class=\"d-flex gap-1\">Facility: <b>").concat((facility === null || facility === void 0 ? void 0 : facility.name) || 'All', "</b></span>\n                    <span class=\"d-flex gap-1\">Locations: <b>").concat((locations === null || locations === void 0 ? void 0 : locations.length) ? locations.map(function (location) { return location.name; }).join(', ') : 'All', "</b></span>\n                    <span class=\"d-flex gap-1\">Equipments: <b>").concat((equipments === null || equipments === void 0 ? void 0 : equipments.length) ? equipments.map(function (equipment) { return equipment.name; }).join(', ') : 'All', "</b></span>\n                    <span class=\"d-flex gap-1\">Components: <b>").concat((components === null || components === void 0 ? void 0 : components.length) ? components.map(function (component) { return component.name; }).join(', ') : 'All', "</b></span>\n                </div>\n        </div>");
    }
    if (selectedGroup === INSTALLATION_POINT_GROUP) {
        return "<div class=\"d-flex flex-column\">\n            Are you sure you want to create a note? A note will be displayed for:\n                <div class=\"d-flex flex-column align-items-start mt-2\">\n                    <span class=\"d-flex gap-1\">Installation point: <b>".concat(installationPointId, "</b></span>\n                </div>\n            </div>");
    }
    if (selectedGroup === NODE_GROUP) {
        return "<div class=\"d-flex flex-column\">\n            Are you sure you want to create a note? A note will be displayed for all points on:\n                <div class=\"d-flex flex-column align-items-start mt-2\">\n                    <span class=\"d-flex gap-1\">Node: <b>".concat(nodeId, "</b></span>\n                </div>\n            </div>");
    }
    return "<div class=\"d-flex flex-column\">\n        Are you sure you want to create a note? A note will be displayed for all points on:\n            <div class=\"d-flex flex-column align-items-start mt-2\">\n                <span class=\"d-flex gap-1\">Facility: <b>".concat((facility === null || facility === void 0 ? void 0 : facility.name) || 'All', "</b></span>\n                <span class=\"d-flex gap-1\">Zone: <b>").concat((zone === null || zone === void 0 ? void 0 : zone.name) || 'All', "</b></span>\n                <span class=\"d-flex gap-1\">Area: <b>").concat((area === null || area === void 0 ? void 0 : area.name) || 'All', "</b></span>\n                <span class=\"d-flex gap-1\">Equipments: <b>").concat((equipments === null || equipments === void 0 ? void 0 : equipments.length) ? equipments.map(function (equipment) { return equipment.name; }).join(', ') : 'All', "</b></span>\n                <span class=\"d-flex gap-1\">Components: <b>").concat((components === null || components === void 0 ? void 0 : components.length) ? components.map(function (component) { return component.name; }).join(', ') : 'All', "</b></span>\n            </div>\n    </div>");
};
