import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import DatePicker from 'react-datepicker';
import { FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { DateInput, DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import LinkWithPrefix from '../../components/LinkWithPrefix';
export var sensorLogInitialState = {
    sensorIds: null,
    sensorIdsInput: null,
    config: {
        columns: [
            {
                name: 'val1',
                title: 'Sensor',
                sortable: true,
                component: function (row) {
                    var sensorId = row.event_type === 'USER_CREATE_ACTION_ITEM' || row.event_type === 'USER_CLOSE_ACTION_ITEM'
                        ? +row.val2
                        : +row.val1;
                    return _jsx(SensorId, { sensor_id: sensorId });
                },
            },
            {
                name: 'event_type',
                title: 'Event',
                sortable: true,
            },
            {
                name: 'date_create',
                title: 'Time',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "sensor-log-date-create-".concat(row.id), dateTime: row.date_create });
                },
            },
            {
                name: 'user_name',
                title: 'User',
                sortable: true,
            },
            {
                name: 'action',
                title: '',
                sortable: false,
                component: function (row) {
                    var _a, _b;
                    return (_jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(((_b = (_a = row.sensor) === null || _a === void 0 ? void 0 : _a.installationPoint) === null || _b === void 0 ? void 0 : _b.id) || row.val5 || row.val2), target: "_blank", children: _jsxs(Button, { color: "success", size: "sm", children: [_jsx(FaEye, {}), " View component"] }) }));
                },
            },
        ],
        sort: [
            {
                field: 'date_create',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filtersData: {
            dateRange: [],
        },
        filters: function (callbacks, filtersData) {
            return (_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: callbacks.onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }));
        },
    },
};
