var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { find as _find, get as _get, set as _set } from 'lodash';
import moment from 'moment';
var statesArray = [
    'NEW',
    'Waiting',
    'Snoozed',
    'Closed',
    'Verifying',
    'Awaiting Update',
    'Archived',
    'Needs Response',
];
export var getFormattedDateFromOrDefault = function (date, format) {
    var dateFrom = date !== null && date !== void 0 ? date : new Date(new Date().setDate(1));
    return moment(dateFrom).format(format);
};
export var getFormattedDateToOrDefault = function (date, format) {
    var dateTo = date !== null && date !== void 0 ? date : new Date();
    return moment(dateTo).format(format);
};
export var groupListByDays = function (data, date_key) {
    if (date_key === void 0) { date_key = 'date_created'; }
    var out = [];
    var tmpData = {};
    data.map(function (item) {
        item.units.map(function (unit) {
            var date = moment(unit[date_key]).format('L');
            var timestamp = new Date(date).getTime();
            var item = _get(tmpData, timestamp, 0);
            // @ts-ignore
            tmpData[timestamp] = item + 1;
        });
    });
    (Object.keys(tmpData) || []).map(function (time) { return out.push([parseInt(time), _get(tmpData, time, 0)]); });
    return out.sort(function (a, b) {
        return a[0] > b[0] ? 1 : -1;
    });
};
export var getGroupedData = function (data) {
    return Object.values(data.analysts).reduce(function (data, items) {
        return __spreadArray(__spreadArray([], data, true), items, true);
    }, []);
};
export var getStatisticByState = function (data) {
    var outData = [];
    (data || []).map(function (analytic) {
        statesArray.map(function (state) {
            var stateItem = _find(outData, { name: state });
            var nexValue = _get(analytic, ['statusCount', state], 0);
            var newItem = false;
            if (!stateItem) {
                stateItem = {
                    name: state,
                    y: nexValue,
                    drilldown: state,
                };
                outData.push(stateItem);
                newItem = true;
            }
            _set(stateItem, 'y', stateItem.y + (newItem ? 0 : nexValue));
        });
    });
    return outData;
};
export var getStatisticByAnalysts = function (data, key) {
    if (key === void 0) { key = 'ai'; }
    var outData = [];
    (data || []).map(function (analytic) {
        outData.push({ name: analytic.username, y: _get(analytic, key, 0) });
    });
    return outData;
};
export var getCountAiByField = function (data, field) {
    if (field === void 0) { field = 'ai'; }
    var outData = 0;
    (data || []).map(function (analytic) {
        outData += _get(analytic, field, 0) || 0;
    });
    return outData;
};
export var getPercentageDiff = function (one, two) {
    var percent = +((100 / +one) * +two).toFixed(2);
    if (isNaN(percent)) {
        return 0;
    }
    return percent;
};
export var isAllFieldFalse = function (rowData, exceptions) {
    if (exceptions === void 0) { exceptions = []; }
    var isInvalid = true;
    for (var _i = 0, _a = Object.entries(rowData); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (!exceptions.includes(key)) {
            if (value) {
                isInvalid = false;
            }
        }
    }
    return isInvalid;
};
export var getStatusLabelClass = function (status) {
    if (status === void 0) { status = ''; }
    switch (status.toLowerCase()) {
        case 'new':
            return 'success';
        case 'question':
            return 'warning';
        case 'on hold':
        case 'waiting':
        case 'verifying':
            return 'info';
        case 'awaiting update':
            return 'primary';
        default:
            return 'secondary';
    }
};
export var getUsdFormat = function (summa) {
    if (!summa)
        return '---';
    return (+summa).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export default {
    groupListByDays: groupListByDays,
    getGroupedData: getGroupedData,
    getStatisticByState: getStatisticByState,
    getStatisticByAnalysts: getStatisticByAnalysts,
    getCountAiByField: getCountAiByField,
    getPercentageDiff: getPercentageDiff,
    getStatusLabelClass: getStatusLabelClass,
    getUsdFormat: getUsdFormat,
};
