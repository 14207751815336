import { setLoader } from '../../../../../features/shared';
export var fetch = function (dispatch, endpoint) {
    endpoint()
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    loader: false,
                    activeActionItems: resp.data.active || [],
                    previousActionItems: resp.data.previous || [],
                    actionListNoActionsTypes: resp.data.action_list_no_actions_types,
                    actionListNoActionsGroups: resp.data.action_list_no_actions_groups,
                    actionListReportsSeverity: Object.values(resp.data.action_list_reports_severity),
                },
            });
        }
    })
        .catch(function () {
        setLoader(dispatch, false);
    });
};
export var getAisForPoint = function (actionItems, id) {
    return actionItems.filter(function (actionItem) { return actionItem.installation_point_id === id; });
};
export var hasAisOnComponent = function (pointsOnComponent, activeActionItems, previousActionItems) {
    return pointsOnComponent.some(function (point) {
        var pointsWithActiveAis = activeActionItems.map(function (actionItem) { return actionItem.installation_point_id; });
        var pointsWithPreviousAis = previousActionItems.map(function (actionItem) { return actionItem.installation_point_id; });
        return pointsWithActiveAis.includes(point.id) || pointsWithPreviousAis.includes(point.id);
    });
};
