import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import CommentToML from '../../AIPreAi/topButtons/CommentToMLBtn';
import RefreshBtn from '../../AIPreAi/topButtons/RefreshBtn';
import AiHistory from './components/AiHistory';
import CustomerCreatedBtn from './components/CustomerCreatedBtn';
import MachineInformationBtn from './components/MachineInformationBtn';
import MissedAiBtn from './components/MissedAiBtn';
var Actions = function (_a) {
    var isShowCommentForMlTeam = _a.isShowCommentForMlTeam, isShowMissAi = _a.isShowMissAi, isViewMode = _a.isViewMode, onInvalidateData = _a.onInvalidateData, isCustomerCreated = _a.isCustomerCreated, storage = _a.storage, id = _a.id;
    return (_jsxs("div", { className: "top-actions", children: [_jsx(RefreshBtn, { onInvalidateData: onInvalidateData }), _jsx(MachineInformationBtn, {}), _jsx(CustomerCreatedBtn, { id: id, storage: storage, isCustomerCreated: isCustomerCreated, onInvalidateData: onInvalidateData }), isShowMissAi ? _jsx(MissedAiBtn, {}) : _jsx(_Fragment, {}), isShowCommentForMlTeam ? _jsx(CommentToML, {}) : _jsx(_Fragment, {}), !isViewMode ? _jsx(AiHistory, {}) : _jsx(_Fragment, {})] }));
};
export default memo(Actions);
