import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { CgSandClock } from 'react-icons/cg';
import DefaultTooltip from '../../../../../DefaultTooltip';
var WakeOnShakeIndicator = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "default-chart-label label-warning", "data-tooltip-id": "wake-on-shake-".concat(chartIdentifier), children: [_jsx("span", { className: "me-1", children: "WoS" }), " ", _jsx(CgSandClock, {})] }), _jsx(DefaultTooltip, { place: "left", id: "wake-on-shake-".concat(chartIdentifier), style: {
                    maxWidth: '300px',
                    textAlign: 'center',
                }, children: "Wake-on-Shake is enabled for this sensor, which will determine when vibration readings are collected" })] }));
};
export default memo(WakeOnShakeIndicator);
