import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import "../../../../../styles/charts/chartsWrapper/EquipmentInfoTable.scss";
import DataTable from "../../../../shared/Table/Table";
var EquipmentInfoTable = function (_a) {
    var equipmentInfo = _a.equipmentInfo;
    return (_jsx("div", { className: "equipment-info-table-tooltip", children: _jsx(DataTable, { config: {
                columns: [
                    {
                        name: 'entity',
                        title: '',
                        sortable: false,
                    },
                    {
                        name: 'data',
                        title: '',
                        sortable: false,
                        component: function (row) {
                            return row.data === null ? '---' : row.data;
                        },
                    },
                ],
                pagination: {
                    disabled: true,
                },
                hideTotalLabel: true,
                search: '',
                disabledSearch: true,
                data: equipmentInfo,
                loader: false,
            } }) }));
};
export default React.memo(EquipmentInfoTable);
