import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "../../../../../../styles/charts/topBar/StartupMode.scss";
var FacilityNotes = function (_a) {
    var facilityNotes = _a.facilityNotes;
    if (facilityNotes.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "startupMode-wrap", children: facilityNotes.map(function (facilityNote) { return (_jsxs("div", { children: [_jsx("div", { className: "fw-bold", children: facilityNote.title }), _jsx("div", { className: "mt-2 ms-3", children: facilityNote.text })] }, "facility_note_".concat(facilityNote.id))); }) }));
};
export default React.memo(FacilityNotes);
