import { valibotResolver } from "@hookform/resolvers/valibot";
import { array, custom, forward, minLength, nullable, number, object, optional, } from "valibot";
import { BAD_DATA_COMPONENT_TYPE_ID } from "../../../../../remastered/constants";
export var faultRecommendationsSchema = valibotResolver(object({
    faultRecommendations: array(optional(nullable(object({
        componentType: optional(nullable(number())),
        subType: optional(nullable(number())),
        fault: optional(nullable(number())),
        severity: optional(nullable(number())),
        recommendations: array(number()),
    }))), 'Recommendations should be an array', [minLength(1, 'Recommendations cannot be empty')]),
}, [
    forward(custom(function (_a) {
        var faultRecommendations = _a.faultRecommendations;
        return faultRecommendations
            .filter(function (item) { return item; })
            .every(function (faultRecommendation) { var _a; return faultRecommendation && ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    }, 'Recommendations cannot be empty'), ['faultRecommendations']),
    forward(custom(function (_a) {
        var faultRecommendations = _a.faultRecommendations;
        var badDataIsset = faultRecommendations
            .filter(function (item) { return item; })
            .some(function (faultRecommendation) { return (faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.componentType) === BAD_DATA_COMPONENT_TYPE_ID; });
        return (badDataIsset ||
            faultRecommendations
                .filter(function (item) { return item; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; }));
    }, 'Severity cannot be empty'), ['faultRecommendations']),
]));
