var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { filter as _filter, get as _get, size as _size } from "lodash";
import React, { memo, useMemo, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { DateTimeLabel } from "../../../../../widgets/common";
import { getUsdFormat } from "../../../../features/watchersStatistic";
import LinkWithPrefix from "../../../LinkWithPrefix";
import { WSStatusLabel } from "../../StatusLabel";
var AllAnalyticsTable = function (_a) {
    var data = _a.data;
    var _b = useState({}), openedBoxes = _b[0], setOpenedBoxes = _b[1];
    var toggleBox = function (analyticName) {
        var _a;
        var opened = __assign({}, openedBoxes);
        setOpenedBoxes(__assign(__assign({}, opened), (_a = {}, _a[analyticName] = !_get(opened, analyticName, false), _a)));
    };
    var hasOpened = useMemo(function () { return !!_size(_filter(openedBoxes, function (item) { return item; })); }, [openedBoxes]);
    return (_jsx(Row, { children: _jsx(Col, { md: 12, children: _jsxs("table", { className: "table table-simply", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "User Name" }), _jsxs(_Fragment, { children: [_jsx("th", { id: "ai-metric", children: "AI" }), _jsx(UncontrolledTooltip, { target: "ai-metric", children: "Active AIs or AIs with metrics/AI without metrics" })] }), hasOpened ? (_jsxs(_Fragment, { children: [_jsx("th", { children: "Sensor Id" }), _jsx("th", { children: "Cost Avoided" }), _jsx("th", { children: "Date Created" })] })) : (_jsx(_Fragment, {})), _jsx("th", { children: "Status" })] }) }), _jsx("tbody", { children: (data || []).map(function (analyticRow, index) {
                            return (_jsxs(React.Fragment, { children: [_jsxs("tr", { children: [_jsxs("td", { "data-label": "User Name", children: [_jsx(Button, { className: "me-2", onClick: function () { return toggleBox(analyticRow.username); }, color: "primary", size: "sm", children: _get(openedBoxes, analyticRow.username, false) ? _jsx(FaMinus, {}) : _jsx(FaPlus, {}) }), analyticRow.username] }), _jsxs("td", { "data-label": "Ai / No Action Required", colSpan: hasOpened ? 4 : 1, children: [analyticRow.ai || 0, " / ", analyticRow.no_action_required || 0] }), _jsx("td", { "data-label": "Status", children: _jsx("div", { className: "d-flex flex-response", children: (Object.keys(analyticRow.statusCount) || []).map(function (stateKey) {
                                                        return (_jsx(WSStatusLabel, { status: stateKey, label: analyticRow.statusCount[stateKey] }, stateKey));
                                                    }) }) })] }), (analyticRow.units || []).map(function (unit, key) {
                                        return (_jsxs("tr", { className: "unit-row ".concat(!_get(openedBoxes, analyticRow.username) ? 'hidden' : ''), children: [_jsx("td", { className: "response-empty" }), _jsx("td", { className: "response-empty", style: { minWidth: '70px' } }), _jsx("td", { "data-label": "Sensor Id", children: _jsx(LinkWithPrefix, { className: "cursor-pointer", target: "_blank", to: "/action-item/".concat(unit.action_item_id), children: _jsx("span", { className: "".concat(unit.status.toLowerCase() === 'closed' ? '__color-orangered' : ''), children: unit.sensor_id }) }) }), _jsx("td", { "data-label": "Cost Avoided", children: getUsdFormat(unit.cost_avoided) }), _jsx("td", { "data-label": "Date Created", children: _jsx(DateTimeLabel, { dateTime: unit.date_created }) }), _jsx("td", { "data-label": "Status", children: _jsx(WSStatusLabel, { status: unit.status }) })] }, key));
                                    })] }, index));
                        }) })] }) }) }));
};
export default memo(AllAnalyticsTable);
