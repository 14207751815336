var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { cloneDeep as _cloneDeep, size as _size } from 'lodash';
import moment from 'moment/moment';
import React, { Fragment, memo, useContext, useMemo } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, Input, Label } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { usePrevious } from '../../../../customHooks';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { abortInstallationPointRequests, updateUrl } from '../../../features/charts/leftEquipmentList';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { InListStore } from '../../../store/charts/inList/InListStore';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import { selectMeasure, useGlobalActions } from '../../../store/global/useGlobalStore';
import DefaultTooltip from '../../DefaultTooltip';
import LinkWithPrefix from '../../LinkWithPrefix';
import HighlighterWord from '../../shared/HighlighterWord';
import { ActionItemStateAmazon, ActionItemStateRemastered, } from '../topBar/components/Labels/Labels/ActionItem';
import RowStatus from './RowStatus';
var InstallationPointRow = function (_a) {
    var activeInstallationPointId = _a.activeInstallationPointId, overlayInstallationPoints = _a.overlayInstallationPoints, installationPoint = _a.installationPoint, onOverlayCheck = _a.onOverlayCheck, equipment = _a.equipment;
    var inList = InListStore(function (state) { return ({
        inList: state.inList,
    }); }, shallow).inList;
    var setActiveInstallationPoint = InstallationPointStore(function (state) { return ({
        setActiveInstallationPoint: state.setActiveInstallationPoint,
    }); }, shallow).setActiveInstallationPoint;
    var prevInstallationPoint = usePrevious(installationPoint);
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var trackEvent = useMatomo().trackEvent;
    var filter = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
    }); }, shallow).filter;
    var isCheckedByActiveInstallationPoint = useMemo(function () {
        return +activeInstallationPointId === +installationPoint.id;
    }, [activeInstallationPointId, installationPoint.id]);
    var isCheckedByOverlay = useMemo(function () {
        return (_size(overlayInstallationPoints.filter(function (overlayInstallationPoint) { return +overlayInstallationPoint.id === +installationPoint.id; })) > 0);
    }, [overlayInstallationPoints, installationPoint]);
    var isHighlightedPoint = useMemo(function () {
        return (installationPoint.serialMatched === 1 ||
            (+filter.search && +filter.search === +installationPoint.sensor_id) ||
            (filter.search &&
                installationPoint.sensor_id &&
                filter.search.toString().toLowerCase() ===
                    (+installationPoint.sensor_id).toString(16).toLowerCase()) ||
            (filter.search &&
                filter.search.toString().toLowerCase() ===
                    (installationPoint.point_name || installationPoint.name).toLowerCase()));
    }, [installationPoint, filter.search]);
    var setMeasure = useGlobalActions().setMeasure;
    var measure = selectMeasure();
    var onInstallationPointChange = function () {
        var _a, _b, _c, _d;
        abortInstallationPointRequests();
        var measurementUnits = selectedSystemType === SYSTEM_TYPE_REMASTERED
            ? (_c = (_b = (_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.locationTable) === null || _b === void 0 ? void 0 : _b.facility) === null || _c === void 0 ? void 0 : _c.measurement_units
            : // @ts-ignore
                (_d = installationPoint.facilityTable) === null || _d === void 0 ? void 0 : _d.measurement_units;
        if (measurementUnits && measurementUnits !== measure) {
            toast.info(_jsxs("div", { children: ["Your measurement settings are different from the facility", _jsx(Button, { color: "primary", size: "sm", style: {
                            marginTop: '8px',
                            fontSize: '14px',
                            letterSpacing: '0.5px',
                        }, onClick: function () { return setMeasure(measurementUnits); }, children: "Apply facility measurement" })] }), { style: { cursor: 'default' }, autoClose: 3500, position: 'bottom-left' });
        }
        var updatedOverlays = _cloneDeep(overlayInstallationPoints || []);
        if (updatedOverlays.length > 0) {
            var isInstallationPointInOverlays = overlayInstallationPoints.find(function (overlayInstallationPoint) { return +overlayInstallationPoint.id === +installationPoint.id; });
            if (isInstallationPointInOverlays) {
                updatedOverlays = __spreadArray([], updatedOverlays.filter(function (overlayInstallationPoint) {
                    return +overlayInstallationPoint.id !== +installationPoint.id;
                }), true);
                if (prevInstallationPoint) {
                    updatedOverlays = __spreadArray(__spreadArray([], updatedOverlays, true), [prevInstallationPoint], false);
                }
            }
        }
        updateUrl(prefix, installationPoint, overlayInstallationPoints, prevInstallationPoint, "Charts Dashboard - ".concat(installationPoint.name));
        setActiveInstallationPoint(installationPoint, updatedOverlays, equipment);
        trackEvent({
            category: 'Charts',
            action: 'View',
            name: "".concat(installationPoint.id, " - ").concat(installationPoint.name),
            href: window.location.href,
        });
    };
    return (_jsxs("div", { className: "equipment-sensor-row", id: "installationPoint-".concat(installationPoint.id), children: [_jsx(RowStatus, { isInstallationPoint: true, installationPoints: [installationPoint], className: "sensor-status", inList: inList }), _jsxs(Label, { check: true, className: isHighlightedPoint ? 'w-85' : '', children: [_jsx(Input, { "data-tooltip-id": "installationPointTooltip_".concat(installationPoint.id), type: "radio", name: "main_sensor", checked: isCheckedByActiveInstallationPoint, onChange: onInstallationPointChange }), _jsxs("span", { className: "".concat(isHighlightedPoint ? 'bg-warning w-100 d-inline-block' : 'd-block', " pe-4"), children: [_jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: installationPoint.point_name || installationPoint.name }), Object.values(inList.actionList || {}).map(function (value, index) {
                                if (value.installation_point_id === installationPoint.id) {
                                    return (_jsxs(Fragment, { children: [_jsxs("span", { className: "left-equipment-date-time", "data-tooltip-id": "in_list_point_".concat(value.installation_point_id, "_").concat(value.id, "_").concat(index), children: [_jsxs("span", { className: 'ms-1', children: ["C:\u00A0", moment(value.time_created).format('MM/DD/YYYY')] }), _jsxs("span", { className: 'ms-1', children: ["U:\u00A0", moment(value.updated_at).format('MM/DD/YYYY')] })] }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, delayShow: 300, style: { zIndex: 1000 }, clickable: true, place: "bottom", id: "in_list_point_".concat(value.installation_point_id, "_").concat(value.id, "_").concat(index), children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(ActionItemStateRemastered, { data: { value: value } })) : (_jsx(ActionItemStateAmazon, { data: { value: value } })) })] }, "in_list_".concat(value.installation_point_id, "_").concat(value.id, "_").concat(index)));
                                }
                            })] })] }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, clickable: true, place: "top", id: "installationPointTooltip_".concat(installationPoint.id), children: _jsx(LinkWithPrefix, { to: "/dashboard?installation_point=".concat(installationPoint.id), rel: "noreferrer", target: "_blank", children: _jsx(MdOpenInNew, { style: { cursor: 'pointer', fontSize: '16px' } }) }) }), _jsx(Label, { check: true, className: "equipment-checkbox float-end", children: _jsx(Input, { type: "checkbox", className: "cursor-pointer", checked: isCheckedByActiveInstallationPoint || isCheckedByOverlay, disabled: isCheckedByActiveInstallationPoint, onChange: function () { return onOverlayCheck(installationPoint); } }) })] }));
};
export default memo(InstallationPointRow);
