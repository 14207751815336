var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { find as _find, size as _size } from 'lodash';
import { resetHighlight } from '../../helper/table';
export var onSearch = function (search, onSearch, onPageChange) {
    onSearch(search);
    onPageChange(1);
    resetHighlight();
};
export var onPageChange = function (page, onPageChange) {
    onPageChange(page);
    resetHighlight();
};
export var onPageSizeChange = function (pageSize, onPageSizeChange, onPageChange) {
    onPageSizeChange(pageSize);
    onPageChange(1);
    resetHighlight();
};
export var onSort = function (column_name, sort, onSort, ev) {
    var newSortState = getSortConfig(column_name, sort, ev.shiftKey);
    onSort(newSortState);
    resetHighlight();
};
var getSortConfig = function (column_name, sort, isShift) {
    if (column_name === null) {
        return [];
    }
    if (!sort || sort.length === 0) {
        return [
            {
                activeFieldSort: column_name,
                field: column_name,
                direction: 'asc',
            },
        ];
    }
    if (isShift) {
        var newSortState_1 = [];
        sort.forEach(function (sortItem) {
            if (sortItem.field !== column_name) {
                newSortState_1.push(sortItem);
                return;
            }
            newSortState_1.push(__assign(__assign({}, sortItem), { direction: !sortItem.direction || sortItem.direction === 'desc' ? 'asc' : 'desc' }));
        });
        if (_size(_find(sort, { field: column_name })) === 0) {
            newSortState_1.push({
                activeFieldSort: column_name,
                field: column_name,
                direction: 'asc',
            });
        }
        return newSortState_1;
    }
    return [
        {
            activeFieldSort: column_name,
            field: column_name,
            direction: sort && sort[0].direction === null ? 'asc' : sort && sort[0].direction === 'asc' ? 'desc' : 'asc',
        },
    ];
};
