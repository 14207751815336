import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { axisLabelKey } from './useAxisLabelQuery';
var axisLabelUpdateFn = function (coreLink, data) { return Api.post("".concat(coreLink, "/axis-label/update"), data); };
export var useAxisLabelUpdateMutation = function (_a) {
    var axisLabelExtraField = _a.axisLabelExtraField, equipmentId = _a.equipmentId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (data) { return axisLabelUpdateFn(getCoreUrl(selectedSystemType), data); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: axisLabelKey(axisLabelExtraField, equipmentId) });
        },
    });
};
