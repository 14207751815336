import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ACTION_LIST } from '../../../constants';
import Loader from '../../components/Loader';
import { getSvgSource } from '../../components/charts/chartsWrapper/components/shared/ChartSelect';
import { getChartsData } from '../../features/mlStatistic/saveCharts';
import { getHost } from '../../helper/customer';
import useToastErrorAlert from '../../hooks/useToastErrorAlert';
import { useMlStatisticSaveChartsDataMutation, } from '../../services/mlStatistic/useMlStatisticSaveChartsDataMutation';
import { useMlStatisticSaveChartsMutation, } from '../../services/mlStatistic/useMlStatisticSaveChartsMutation';
import { useUpdateMissedAiMutation, } from '../../services/mlStatistic/useUpdateMissedAiMutation';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { FftImpactVueChartsStore } from '../../store/charts/chartsContent/FftImpactVueChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { reportMlSchema } from './schemas';
import { selectIsVisibleModal, useReportMlActions } from './store/useReportMlStore';
var ReportMl = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var _j = _a.handleDisableSelectCharts, handleDisableSelectCharts = _j === void 0 ? function () { } : _j, mlMissedAi = _a.mlMissedAi, _k = _a.callback, callback = _k === void 0 ? function () { } : _k;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var host = getHost((_f = (_e = (_d = (_c = (_b = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _b === void 0 ? void 0 : _b.locationTable) === null || _c === void 0 ? void 0 : _c.facility) === null || _d === void 0 ? void 0 : _d.customer) === null || _e === void 0 ? void 0 : _e.storage) !== null && _f !== void 0 ? _f : null);
    var isVisible = selectIsVisibleModal();
    var setIsVisible = useReportMlActions().setIsVisible;
    var _l = useForm({
        defaultValues: {
            urgency: (_g = mlMissedAi === null || mlMissedAi === void 0 ? void 0 : mlMissedAi.urgency) !== null && _g !== void 0 ? _g : ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
            comment: (_h = mlMissedAi === null || mlMissedAi === void 0 ? void 0 : mlMissedAi.comment) !== null && _h !== void 0 ? _h : '',
        },
        resolver: reportMlSchema,
    }), setValue = _l.setValue, control = _l.control, errors = _l.formState.errors, handleSubmit = _l.handleSubmit, reset = _l.reset;
    useEffect(function () {
        var _a, _b;
        if (mlMissedAi) {
            reset({
                urgency: (_a = mlMissedAi.urgency) !== null && _a !== void 0 ? _a : ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
                comment: (_b = mlMissedAi.comment) !== null && _b !== void 0 ? _b : '',
            });
        }
    }, [mlMissedAi]);
    var onClose = function () {
        setIsVisible(false);
        reset();
        callback();
    };
    useToastErrorAlert(errors);
    var _m = useWatch({
        control: control,
        name: ['urgency', 'comment'],
    }), urgency = _m[0], comment = _m[1];
    var _o = useMlStatisticSaveChartsDataMutation(), mlStatisticSaveChartsDataMutate = _o.mutate, mlStatisticSaveChartsDataIsLoading = _o.isPending;
    var _p = useUpdateMissedAiMutation({ host: host }), updateMissedAiMutate = _p.mutate, updateMissedAiIsLoading = _p.isPending;
    var _q = useMlStatisticSaveChartsMutation({ host: host }), mlStatisticSaveChartsMutate = _q.mutate, mlStatisticSaveChartsIsLoading = _q.isPending;
    var _r = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _r.data, fftTimestampDates = _r.fftTimestampDates;
    var lastTimestamps = FftImpactVueChartsStore(function (state) { return ({
        lastTimestamps: state.lastTimestamps,
    }); }, shallow).lastTimestamps;
    var _s = ChartsStore(function (state) { return ({
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedRanges: state.chartsSelectedRanges,
        setStates: state.setStates,
    }); }, shallow), selectedChartsAvailable = _s.selectedChartsAvailable, chartsSelectedRanges = _s.chartsSelectedRanges;
    var onSubmitMlReportModal = handleSubmit(function () {
        if (mlMissedAi === null || mlMissedAi === void 0 ? void 0 : mlMissedAi.id) {
            updateMissedAiMutate({
                id: mlMissedAi.id,
                urgency: urgency,
                comment: comment,
                ranges: chartsSelectedRanges,
            }, {
                onSuccess: function (resp) {
                    toast.success((resp === null || resp === void 0 ? void 0 : resp.message) || 'Successfully updated', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    onClose();
                },
                onError: function (resp) {
                    toast.error((resp === null || resp === void 0 ? void 0 : resp.message) || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
                },
            });
        }
        mlStatisticSaveChartsMutate({
            svg_sources: getSvgSource(selectedChartsAvailable),
            installation_point_id: installationPoint.id,
            urgency: urgency,
            comment: comment,
            ranges: chartsSelectedRanges,
        }, {
            onSuccess: function (resp) {
                handleDisableSelectCharts();
                toast.success((resp === null || resp === void 0 ? void 0 : resp.message) || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                onClose();
            },
            onError: function (resp) {
                toast.error((resp === null || resp === void 0 ? void 0 : resp.message) || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            },
        });
        if (!getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates).length) {
            return;
        }
        mlStatisticSaveChartsDataMutate({
            sensor_id: installationPoint.sensor_id,
            installation_point_id: installationPoint.id,
            type: 'ml_report',
            charts: getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates),
        });
    });
    if (!isVisible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Modal, { size: "xs", className: "modal-response", toggle: onClose, isOpen: isVisible, children: [_jsx(ModalHeader, { toggle: onClose, children: "Report ML" }), _jsx(ModalBody, { children: updateMissedAiIsLoading || mlStatisticSaveChartsIsLoading || mlStatisticSaveChartsDataIsLoading ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(_Fragment, { children: _jsxs(Form, { children: [_jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "Urgency:" }), _jsxs(Input, { type: "select", name: "select", value: urgency, onChange: function (ev) {
                                            return setValue('urgency', +ev.target.value);
                                        }, children: [_jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_HIGH, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_HIGH] }), _jsx("option", { value: ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.ACTION_ITEM_URGENCY_CRITICAL] })] })] }), _jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "Comment for ML-system miss: " }), _jsx(Input, { className: "input-cancel", id: "textarea", bsSize: 'lg', label: "description", type: "textarea", required: true, value: comment, onChange: function (ev) {
                                            return setValue('comment', ev.currentTarget.value);
                                        } })] })] }) })) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onClose, children: "Close" }), _jsx(Button, { size: "sm", color: "primary", disabled: updateMissedAiIsLoading || mlStatisticSaveChartsIsLoading || mlStatisticSaveChartsDataIsLoading, onClick: onSubmitMlReportModal, children: "Save" })] }) })] }));
};
export default memo(ReportMl);
