var scale = 1;
export var mediumZoom_Zooming = function (event) {
    event.preventDefault();
    scale += event.deltaY * -0.0015;
    scale = Math.min(Math.max(0.3, scale), 3);
    var translateX = (-event.target.offsetLeft + (event.target.offsetParent.offsetWidth - event.target.width) / 2) / scale;
    var translateY = (-event.target.offsetTop + (event.target.offsetParent.offsetHeight - event.target.height) / 2) / scale;
    event.target.style.transform =
        'scale(' + scale + ') translate3d(' + translateX + 'px, ' + translateY + 'px, 0)';
};
export default mediumZoom_Zooming;
