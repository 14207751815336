import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { toggleRmsCursor } from '../../../components/charts/chartsWrapper/features/shared/cursor';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useRmsCursor = function (state, chartRef) {
    var mainSeriesLoaded = state.mainSeriesLoaded, isVisibleFullscreenChart = state.isVisibleFullscreenChart;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var setStatesChartStore = ChartsStore(function (state) { return ({
        setStatesChartStore: state.setStates,
    }); }, shallow).setStatesChartStore;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        toggleRmsCursor(setStatesChartStore, true, chartRef.current.chart);
    }, [isVisibleFullscreenChart, installationPoint.id]);
};
