import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { Input } from "reactstrap";
var Filters = function (_a) {
    var callbacks = _a.callbacks, filtersData = _a.filtersData;
    return (_jsx("div", { className: "response-buttons mb-2 response-pad", children: _jsxs(Input, { type: "select", name: "select", className: "select-sm users-select", onChange: function (ev) {
                return callbacks.setSelectedUser(ev.target.value ? +ev.target.value : null);
            }, children: [_jsx("option", { value: "", children: "All users" }), filtersData.users.map(function (user) { return (_jsx("option", { value: user.user_id, children: user.username }, "users-watchlist-filter-".concat(user.user_id))); })] }) }));
};
export default memo(Filters);
