var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { setLoader } from '../../../helper/table';
export var fetch = function (state, dispatch, withData, endpoint, isOnInfiniteScroll) {
    if (isOnInfiniteScroll === void 0) { isOnInfiniteScroll = false; }
    var config = state.config, limit = state.limit;
    dispatch({
        type: 'setState',
        state: {
            fetchingInProcess: true,
        },
    });
    setLoader(state, dispatch, true);
    endpoint().then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    fetchingInProcess: false,
                    scrollLoader: resp.data.length === limit && resp.data.length !== 0,
                    config: __assign(__assign({}, config), { data: isOnInfiniteScroll ? __spreadArray(__spreadArray([], config.data, true), resp.data, true) : resp.data, loader: false }),
                },
            });
        }
    });
};
export var onScroll = function (state, dispatch, values) {
    var config = state.config, offset = state.offset, limit = state.limit, scrollLoader = state.scrollLoader, fetchingInProcess = state.fetchingInProcess;
    if (!scrollLoader || fetchingInProcess) {
        return;
    }
    var scrollTop = values.scrollTop, scrollHeight = values.scrollHeight, clientHeight = values.clientHeight;
    var pad = 100; // 100px of the bottom
    var scrollIndex = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (scrollIndex > 1 && !config.loader && config.data.length >= limit) {
        dispatch({
            type: 'setState',
            state: {
                offset: offset + limit,
            },
        });
    }
};
