var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { findIndex as _findIndex, get as _get, size as _size } from 'lodash';
import React from 'react';
import { useDrag } from 'react-dnd';
import { FaEye, FaHome, FaTimesCircle } from 'react-icons/fa';
import { Badge, Button, Input, Label } from 'reactstrap';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
var SensorLocationBlock = function (_a) {
    var name = _a.name, color = _a.color, setPositions = _a.setPositions, positions = _a.positions, index = _a.index, setIndex = _a.setIndex, pointIndex = _a.pointIndex, id = _a.id, installationPointId = _a.installationPointId, imageRef = _a.imageRef, column = _a.column, checked = _a.checked, onSelectInstallationPoint = _a.onSelectInstallationPoint, imageId = _a.imageId;
    column = column || 'right';
    var _b = InstallationPointStore(function (state) { return ({
        equipmentPhotos: state.equipmentPhotos,
        installationPoint: state.installationPoint,
    }); }), equipmentPhotos = _b.equipmentPhotos, installationPoint = _b.installationPoint;
    var _c = (function () {
        var _a;
        var position = _get(positions, [index, pointIndex], {});
        if ((position === null || position === void 0 ? void 0 : position.name) && (position === null || position === void 0 ? void 0 : position.color)) {
            return [position.name, position.color, position.installation_point_id];
        }
        var installationPointId = _get(positions, [index, pointIndex, 'installation_point_id']);
        if (!installationPointId) {
            return ['---', 'rgb(255, 193, 7)'];
        }
        var installationPointModel = (((_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.installationPoints) || []).find(function (i) { return i.id === installationPointId; });
        if (!installationPointModel) {
            return ['---', 'rgb(255, 193, 7)'];
        }
        return [installationPointModel.name, installationPointModel.color, installationPointModel.id];
    })(), namePos = _c[0], colorPos = _c[1], installationPointIdPos = _c[2];
    name = name || namePos;
    color = color || colorPos;
    installationPointId = installationPointId || installationPointIdPos;
    var _d = useDrag({
        type: 'SensorLocationBlock',
        end: function (dropResult, monitor) {
            var _a, _b;
            var _c;
            if (!dropResult) {
                return;
            }
            var dropMonitor = monitor.getDropResult();
            if (dropMonitor) {
                var boundary = (_c = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect();
                var percent_left = ((dropMonitor.monitor.x - boundary.left) * 100) / boundary.width;
                var percent_top = ((dropMonitor.monitor.y - boundary.top) * 100) / boundary.height;
                if (percent_left < 0)
                    percent_left = 0;
                if (percent_left > 100)
                    percent_left = 100;
                if (percent_top < 0)
                    percent_top = 0;
                if (percent_top > 100)
                    percent_top = 100;
                var top = Math.round((boundary.height * percent_top) / 100);
                var left = Math.round((boundary.width * percent_left) / 100);
                setPositions(__assign(__assign({}, positions), (_a = {}, _a[index] = __assign(__assign({}, (positions[index] || {})), (_b = {}, _b[pointIndex] = __assign(__assign({}, (_get(positions, [index, pointIndex]) || {})), { id: id, installation_point_id: installationPointId, equipment_image_id: imageId, index: index, name: name, color: color, pointIndex: pointIndex, percent_top: percent_top, percent_left: percent_left, top: top, left: left }), _b)), _a)));
            }
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _d[0].isDragging, drag = _d[1];
    var opacity = isDragging ? 0.4 : 1;
    if (column == 'right') {
        var isSelected = _size(_get(positions, [index, pointIndex])) > 0;
        var installationPointPhotoIndex_1 = _findIndex(equipmentPhotos, function (photo) {
            return installationPointId ? +photo.installation_point_id === +installationPointId : false;
        });
        var onRemovePosition = function () {
            var positionsLocal = __assign({}, positions);
            Object.values(positionsLocal).forEach(function (positions) {
                delete positions[pointIndex];
            });
            if (Object.values(positionsLocal[index] || {}).length === 0) {
                delete positionsLocal[index];
            }
            setPositions(positionsLocal);
        };
        return (_jsx("div", { ref: isSelected ? undefined : drag, style: { opacity: opacity }, children: pointIndex === 999 ? (_jsxs("div", { className: 'p-1 d-block ' + (isSelected ? 'cursor-pointer' : 'dashed-border-on-hover'), style: { opacity: isSelected ? 0.5 : 1 }, children: [_jsx(Badge, { className: "text-light mt-4 mb-2", style: { minWidth: 0 }, color: "warning", children: _jsx(FaHome, {}) }), _jsx("span", { children: name || '' }), isSelected ? (_jsx("span", { className: "pull-right cursor-pointer mt-3", onClick: onRemovePosition, children: _jsx(FaTimesCircle, {}) })) : (_jsx(_Fragment, {}))] })) : (_jsxs(Label, { check: true, className: 'mt-4 mb-4 p-1 d-block position-relative' +
                    (isSelected ? 'cursor-pointer' : 'dashed-border-on-hover'), children: [_jsx(Input, { className: "position-relative", onChange: onSelectInstallationPoint, checked: checked, type: "checkbox" }), _jsxs("span", { style: { opacity: isSelected ? 0.5 : 1 }, children: [_jsx("span", { className: "ms-2 p-2 text-light fz-12 rounded-pill", style: { backgroundColor: color }, children: +pointIndex + 1 }), _jsx("span", { className: "ms-2", children: name }), isSelected ? (_jsx("span", { className: "pull-right cursor-pointer", onClick: onRemovePosition, children: _jsx(FaTimesCircle, {}) })) : (_jsx(_Fragment, {})), installationPointPhotoIndex_1 >= 0 ? (_jsx(Button, { className: "float-end", size: "sm", color: "primary", outline: true, onClick: function () { return setIndex(installationPointPhotoIndex_1); }, children: _jsx(FaEye, {}) })) : (_jsx(_Fragment, {}))] })] })) }));
    }
    return (_jsxs("span", { ref: drag, title: name, style: {
            position: 'absolute',
            left: _get(positions, [index, pointIndex, 'percent_left'], 0) + '%',
            top: _get(positions, [index, pointIndex, 'percent_top'], 0) + '%',
        }, className: "img-tooltip-wrapper", children: [_jsx("span", { className: "img-tooltip", style: { backgroundColor: color }, children: _jsx("span", { className: "img-text-number", children: pointIndex === 999 ? (_jsx("i", { className: "fa fa-home", style: { margin: 0, fontSize: 12 } })) : (+pointIndex + 1) }) }), _jsx("span", { className: "img-tooltip-arrow", style: { borderColor: color + ' transparent transparent transparent' } })] }));
};
export default SensorLocationBlock;
