import { getDynamicParams } from '../../config/analystNotes/dataTableConfig';
import { createStore } from '../createStore';
var useAnalystNotesDataTableStore = createStore(function (set) { return ({
    search: getDynamicParams().search,
    page: getDynamicParams().pagination.page,
    pageSize: getDynamicParams().pagination.pageSize,
    sort: getDynamicParams().sort,
    selectedInstallationPointId: getDynamicParams().selectedInstallationPointId,
    actions: {
        setSearch: function (search) { return set({ search: search }); },
        setPage: function (page) { return set({ page: page }); },
        setPageSize: function (pageSize) { return set({ pageSize: pageSize }); },
        setSort: function (sort) { return set({ sort: sort }); },
        setSelectedInstallationPointId: function (selectedInstallationPointId) { return set({ selectedInstallationPointId: selectedInstallationPointId }); },
    },
}); });
export var useAnalystNotesDataTableActions = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.actions; });
};
export var selectSearchDataTable = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.search; });
};
export var selectPageDataTable = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.page; });
};
export var selectPageSizeDataTable = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.pageSize; });
};
export var selectSortDataTable = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.sort; });
};
export var selectSelectedInstallationPointIdDataTable = function () {
    return useAnalystNotesDataTableStore(function (state) { return state.selectedInstallationPointId; });
};
