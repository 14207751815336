import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { first as _first, includes as _includes, size as _size } from 'lodash';
import React, { useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
var LineOfResolutionSelect = function (_a) {
    var values = _a.values, setLor = _a.setLor, isDisabled = _a.isDisabled, name = _a.name, lor = _a.lor, _b = _a.axisId, axisId = _b === void 0 ? undefined : _b;
    var lorValues = values;
    var value = lor;
    if (!_includes(lorValues, +lor)) {
        value = _first(lorValues);
        if (axisId) {
            setLor(axisId, name, value, true);
        }
        else {
            setLor(value);
        }
    }
    var onChange = useCallback(function (value) {
        if (axisId) {
            return setLor(axisId, name, value, true);
        }
        setLor(value);
    }, [axisId, name, setLor]);
    return (_jsx(React.Fragment, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "Lines of resolution:" }), _jsx(Input, { type: "select", name: name || 'lines_of_resolution', className: "select-xl", disabled: !_size(lorValues) || isDisabled ? 'disabled' : '', value: value, onChange: function (ev) { return onChange(+ev.target.value); }, children: Object.values(lorValues).map(function (value) { return (_jsx("option", { value: value, children: value }, "lor-".concat(value))); }) })] }) }));
};
export default React.memo(LineOfResolutionSelect);
