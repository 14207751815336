import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash';
import React, { useEffect, useReducer } from 'react';
import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../../../components/Loader';
import { setLoader } from '../../../features/shared';
import { reducer } from '../../../helper/reducer';
var initialState = {
    photos: [],
    loader: true,
};
var HomePhotosModal = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, endpoint = _a.endpoint;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var photos = state.photos, loader = state.loader;
    useEffect(function () {
        if (isOpen) {
            setLoader(dispatch, true);
            endpoint().then(function (resp) {
                return dispatch({
                    type: 'setState',
                    state: {
                        photos: get(resp, 'photos', []),
                        loader: false,
                    },
                });
            });
        }
    }, [isOpen]);
    return (_jsxs(Modal, { size: "xl", isOpen: isOpen, toggle: toggle, children: [_jsx(ModalHeader, { toggle: toggle, children: "Photos" }), _jsx(ModalBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-md" }) })) : !photos.length ? (_jsx(Alert, { color: "danger", children: "There are no photos" })) : (photos.map(function (photo, index) { return (_jsx("div", { className: "w-100 mb-3 text-center", children: _jsx("img", { width: "70%", src: photo, alt: photo }) }, index)); })) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: toggle, children: "Cancel" }) }) })] }));
};
export default HomePhotosModal;
