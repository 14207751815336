import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Badge, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { COMPONENT_TYPE_BELT_AND_CHAINS, COMPONENT_TYPE_COUPLING } from '../../../../remastered/constants';
import RichTextEditor from '../../../../widgets/TextEditor/TextEditor';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { groupRecommendations } from '../../../features/actionItems';
import useToastErrorAlert from '../../../hooks/useToastErrorAlert';
import { useReportEditorOptionsQuery, } from '../../../services/reportEditor/useReportEditorOptionsQuery';
import FormTextEditor from '../../AIPreAi/FormDescriptionTextEditor';
import Tags from '../../shared/Tags';
import ReportEditorForm from './ReportEditorForm';
import { actionItemFormDescriptionSchema } from './schemas';
var FormDescription = function (_a) {
    var _b;
    var editIsLoading = _a.editIsLoading, createIsLoading = _a.createIsLoading, _c = _a.isCreate, isCreate = _c === void 0 ? false : _c, _d = _a.isShowAmazonWorkOrder, isShowAmazonWorkOrder = _d === void 0 ? false : _d, isPreAi = _a.isPreAi, _e = _a.tags, tagsList = _e === void 0 ? [] : _e, installationPoint = _a.installationPoint, onCancel = _a.onCancel, createMutate = _a.createMutate, editMutate = _a.editMutate, activeListTags = _a.activeListTags, isReplaceEquipment = _a.isReplaceEquipment, defaultTitle = _a.title, defaultDescription = _a.description, defaultUrgency = _a.urgency, id = _a.id, customerId = _a.customerId, woNumber = _a.woNumber, _f = _a.storage, storage = _f === void 0 ? null : _f, recommendations = _a.recommendations;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _g = useReportEditorOptionsQuery(), reportEditorData = _g.data, isPending = _g.isPending;
    var defaultTags = !activeListTags
        ? []
        : activeListTags.map(function (item) {
            return {
                id: _get(item, isPreAi ? 'preActionListTag.id' : 'actionListTag.id'),
                name: _get(item, isPreAi ? 'preActionListTag.name' : 'actionListTag.name'),
            };
        });
    var descriptionRef = useRef(defaultDescription);
    var _h = useState(recommendations), recommendationsState = _h[0], setRecommendationsState = _h[1];
    var selectedComponentType = recommendationsState.find(function (recommendation) {
        return ![COMPONENT_TYPE_COUPLING, COMPONENT_TYPE_BELT_AND_CHAINS].includes(recommendation.component_type_id);
    }) ||
        recommendationsState.find(function (recommendation) {
            return [COMPONENT_TYPE_COUPLING, COMPONENT_TYPE_BELT_AND_CHAINS].includes(recommendation.component_type_id);
        });
    var _j = useForm({
        defaultValues: {
            description: defaultDescription,
            title: defaultTitle,
            urgency: defaultUrgency,
            tags: defaultTags,
            faultRecommendations: [
                {
                    componentType: isCreate ? null : (selectedComponentType === null || selectedComponentType === void 0 ? void 0 : selectedComponentType.component_type_id) || null,
                    subType: null,
                    fault: null,
                    severity: null,
                    recommendations: [],
                },
            ],
        },
        resolver: actionItemFormDescriptionSchema(selectedSystemType, !isCreate),
    }), setValue = _j.setValue, unregister = _j.unregister, control = _j.control, errors = _j.formState.errors, handleSubmit = _j.handleSubmit;
    useToastErrorAlert(errors);
    var _k = useWatch({
        control: control,
        name: ['title', 'urgency', 'tags'],
    }), title = _k[0], urgency = _k[1], tags = _k[2];
    var faultRecommendations = useWatch({
        control: control,
        name: ['faultRecommendations'],
    })[0];
    var _l = useState(((_b = recommendationsState[0]) === null || _b === void 0 ? void 0 : _b.component_type_id) || undefined), firstSelectedComponentTypeId = _l[0], setFirstSelectedComponentTypeId = _l[1];
    var _m = useState(groupRecommendations(recommendations)), recommendationsGroupedByComponentType = _m[0], setRecommendationsGroupedByComponentType = _m[1];
    var _o = useState({}), updatedRecommendationsSeverity = _o[0], setUpdatedRecommendationsSeverity = _o[1];
    var _p = useState(''), comment = _p[0], setComment = _p[1];
    var isReportEditorUpdated = !isCreate &&
        (Object.keys(updatedRecommendationsSeverity).length > 0 ||
            faultRecommendations.some(function (recommendation) { return recommendation.recommendations && recommendation.severity; }));
    var onSubmit = handleSubmit(function () {
        if (!editMutate) {
            return;
        }
        if (isReportEditorUpdated && (comment.length === 0 || comment === '<p><br></p>')) {
            return toast.error("Reason for updating 'Short Fault Description' is required", {
                icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
            });
        }
        editMutate({
            action_item_id: id,
            title: title,
            urgency: urgency,
            description: descriptionRef.current || '<p></p>',
            is_replace_equipment: +isReplaceEquipment,
            tag_ids: tags.map(function (selectedTag) { return selectedTag.id; }),
            faultRecommendations: faultRecommendations.filter(function (faultRecommendation) {
                return faultRecommendation.severity &&
                    faultRecommendation.recommendations &&
                    faultRecommendation.recommendations.length > 0;
            }),
            updatedRecommendationsSeverity: updatedRecommendationsSeverity,
            comment: comment,
        }, {
            onSuccess: function () {
                toast.success('Successfully changed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                onCancel();
            },
        });
    });
    var onCreate = handleSubmit(function () {
        if (!createMutate || !installationPoint) {
            return;
        }
        createMutate({
            customer_id: customerId,
            installation_point_id: installationPoint.id,
            sensor_id: installationPoint.sensor_id,
            title: title,
            urgency: urgency,
            description: descriptionRef.current || '<p></p>',
            is_replace_equipment: +isReplaceEquipment,
            tag_ids: tags.map(function (selectedTag) { return selectedTag.id; }),
            faultRecommendations: faultRecommendations.filter(function (faultRecommendation) {
                return faultRecommendation.severity &&
                    faultRecommendation.recommendations &&
                    faultRecommendation.recommendations.length > 0;
            }),
        }, {
            onSuccess: function () {
                toast.success('Successfully created', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                onCancel();
            },
        });
    });
    var onTagsChange = useCallback(function (tags) {
        setValue('tags', tags);
    }, []);
    useEffect(function () {
        var _a, _b, _c, _d;
        if (((_a = faultRecommendations[0]) === null || _a === void 0 ? void 0 : _a.componentType) !== firstSelectedComponentTypeId && isCreate) {
            setFirstSelectedComponentTypeId(((_b = faultRecommendations[0]) === null || _b === void 0 ? void 0 : _b.componentType) || undefined);
        }
        if (!isCreate) {
            return;
        }
        var highestPriorityRecommendations = null;
        faultRecommendations === null || faultRecommendations === void 0 ? void 0 : faultRecommendations.forEach(function (faultRecommendation) {
            if (!highestPriorityRecommendations ||
                //@ts-ignore
                highestPriorityRecommendations.severity < faultRecommendation.severity) {
                highestPriorityRecommendations = faultRecommendation;
            }
        });
        if (((_c = highestPriorityRecommendations === null || highestPriorityRecommendations === void 0 ? void 0 : highestPriorityRecommendations.recommendations) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            var newTitle = (_d = reportEditorData.recommendations.find(function (recommendation) { return recommendation.id === highestPriorityRecommendations.recommendations[0]; })) === null || _d === void 0 ? void 0 : _d.name;
            if (newTitle && newTitle !== title) {
                setValue('title', newTitle);
            }
        }
    }, [faultRecommendations]);
    var displayReportEditor = isCreate || recommendationsState.length > 0;
    var onRecommendationsChange = function (componentTypeId, faultId, recommendationIds, value) {
        var updatedRecommendationsSeverityUpd = updatedRecommendationsSeverity;
        var recommendationsUpd = _cloneDeep(recommendationsState).map(function (recommendation) {
            if (recommendation.component_type_id === componentTypeId &&
                recommendation.fault_id === faultId &&
                recommendationIds.includes(recommendation.recommendation_id)) {
                updatedRecommendationsSeverityUpd[recommendation.id] = value;
                recommendation.severity = value;
            }
            return recommendation;
        });
        setUpdatedRecommendationsSeverity(updatedRecommendationsSeverityUpd);
        setRecommendationsState(recommendationsUpd);
        setRecommendationsGroupedByComponentType(groupRecommendations(recommendationsUpd, false));
    };
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx(Row, { children: isPreAi ? (_jsx(Col, { className: "mb-2", children: _jsx("div", { children: _jsx(Badge, { color: "danger", children: "Pre-Action item exist" }) }) })) : (_jsx(_Fragment, {})) }), _jsxs(Form, { children: [_jsxs(Row, { children: [_jsx(Col, { md: isShowAmazonWorkOrder ? 8 : 12, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Title:" }), _jsx(Input, { type: "text", name: "title", id: "title", onChange: function (ev) { return setValue('title', ev.target.value); }, value: title })] }) }), isShowAmazonWorkOrder ? (_jsxs(Col, { md: 2, className: "mt-2", children: [_jsx(Label, { className: "d-block", children: "Create amazon work order?" }), _jsx(FormGroup, { check: true, className: "d-inline-block", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", disabled: !isCreate && (woNumber === null || woNumber > 0), name: "wo_number", checked: woNumber !== null && woNumber > 0 }), "Yes"] }) }), _jsx(FormGroup, { check: true, className: "d-inline-block ms-2", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "wo_number", disabled: !isCreate && (woNumber === null || woNumber > 0), checked: isCreate || woNumber === null }), "No"] }) })] })) : (_jsx(_Fragment, {}))] }), isCreate ? (_jsxs(FormGroup, { children: [_jsx(Label, { children: "Urgency:" }), _jsxs(Input, { type: "select", name: "urgency", id: "urgency", onChange: function (ev) {
                                        return setValue('urgency', +ev.target.value);
                                    }, value: urgency, children: [_jsx("option", { value: ACTION_LIST.URGENCY_STANDARD, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.URGENCY_STANDARD] }), _jsx("option", { value: ACTION_LIST.URGENCY_HIGH, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.URGENCY_HIGH] }), _jsx("option", { value: ACTION_LIST.URGENCY_CRITICAL, children: ACTION_LIST.URGENCY_NAMES[ACTION_LIST.URGENCY_CRITICAL] })] })] })) : (_jsx(_Fragment, {})), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Tags (mandatory):" }), _jsx(Tags, { tags: tagsList, selectedTags: tags, onTagsChange: onTagsChange })] }), selectedSystemType === SYSTEM_TYPE_REMASTERED && displayReportEditor ? (_jsxs(_Fragment, { children: [_jsx(Label, { children: "Short Fault Description (mandatory):" }), isCreate ? (_jsx(_Fragment, {})) : (_jsx(_Fragment, { children: Object.values(recommendationsGroupedByComponentType).map(function (recommendationsGrouped, parentIndex) { return (_jsx(React.Fragment, { children: recommendationsGrouped ? (Object.values(recommendationsGrouped).map(function (recommendations, index) {
                                            var _a, _b, _c, _d, _e;
                                            return (_jsx(ReportEditorForm, { isEditForm: true, setValue: function (path, value) {
                                                    var _a, _b;
                                                    return onRecommendationsChange((_a = recommendations[0]) === null || _a === void 0 ? void 0 : _a.component_type_id, (_b = recommendations[0]) === null || _b === void 0 ? void 0 : _b.fault_id, recommendations.map(function (actionItemRecommendation) { return actionItemRecommendation.recommendation_id; }), value);
                                                }, unregister: unregister, index: index, countFaultRecommendations: 0, firstSelectedComponentTypeId: ((_a = recommendations[0]) === null || _a === void 0 ? void 0 : _a.component_type_id) || undefined, selectedReportEditorData: {
                                                    componentType: (_b = recommendations[0]) === null || _b === void 0 ? void 0 : _b.component_type_id,
                                                    subType: (_c = recommendations[0]) === null || _c === void 0 ? void 0 : _c.sub_type_id,
                                                    fault: (_d = recommendations[0]) === null || _d === void 0 ? void 0 : _d.fault_id,
                                                    recommendations: recommendations.map(function (actionItemRecommendation) { return actionItemRecommendation.recommendation_id; }),
                                                    severity: (_e = recommendations[0]) === null || _e === void 0 ? void 0 : _e.severity,
                                                }, isPending: true, reportEditorData: reportEditorData }, "report-editor-existing-items-".concat(parentIndex, "-").concat(index)));
                                        })) : (_jsx(_Fragment, {})) }, "report-editor-existing-items-".concat(parentIndex))); }) })), _jsx(FormGroup, { children: faultRecommendations.map(function (_a, index) {
                                        var fault = _a.fault, recommendations = _a.recommendations, severity = _a.severity, componentType = _a.componentType, subType = _a.subType;
                                        return (_jsx(ReportEditorForm, { setValue: setValue, unregister: unregister, index: index, countFaultRecommendations: faultRecommendations.length, firstSelectedComponentTypeId: firstSelectedComponentTypeId, selectedReportEditorData: { componentType: componentType, subType: subType, fault: fault, recommendations: recommendations, severity: severity }, isPending: isPending, isEdit: !isCreate, reportEditorData: reportEditorData }, "report-editor-items-".concat(index)));
                                    }) })] })) : (_jsx(_Fragment, {})), isReportEditorUpdated ? (_jsxs(FormGroup, { children: [_jsxs(Label, { for: "comment", children: ["Reason for updating ", "'Short Fault Description'", " (required):"] }), _jsx(RichTextEditor, { customerId: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer_id) || undefined, content: comment !== null && comment !== void 0 ? comment : undefined, storage: storage, onChange: function (content) { return setComment(content); } })] })) : (_jsx(_Fragment, {})), _jsx(FormTextEditor, { label: selectedSystemType === SYSTEM_TYPE_REMASTERED
                                ? 'Expanded Description (optional):'
                                : 'Description', control: control, descriptionRef: descriptionRef, storage: storage, installationPoint: installationPoint }), _jsx("div", { className: "d-flex justify-content-end", children: isCreate ? (_jsx(Button, { disabled: editIsLoading || createIsLoading, color: "success", size: "sm", onClick: function () {
                                    setValue('description', descriptionRef.current);
                                    onCreate();
                                }, children: "Create Action Item" })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: "secondary", size: "sm", onClick: onCancel, className: "me-2", children: "Cancel" }), _jsx(Button, { disabled: editIsLoading || createIsLoading, color: "success", size: "sm", onClick: function () {
                                            setValue('description', descriptionRef.current);
                                            onSubmit();
                                        }, children: "Save" })] })) })] })] }) }));
};
export default memo(FormDescription);
