var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { get as _get } from 'lodash';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getAnalystsChartOptions } from '../../features/watchersStatistic/costAvoided';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticCostAvoidedKey = function (dateRange) {
    var _a, _b;
    return [
        'statisticCostAvoided',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
    ];
};
var statisticCostAvoidedFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/cost-avoided"), {
        query: {
            'separate-by-team': 1,
            date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'),
            date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'),
        },
    });
};
export var useStatisticCostAvoidedQuery = function () {
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticCostAvoidedKey(dateRange),
        queryFn: function (data) { return statisticCostAvoidedFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var _b;
            var data = _a.data;
            return {
                analysts: (data === null || data === void 0 ? void 0 : data.analysts) || {},
                total: (data === null || data === void 0 ? void 0 : data.total) || {},
                analystsChartOptions: {
                    title: {
                        text: 'Cost Avoided By Analyst',
                    },
                    series: [
                        {
                            type: 'pie',
                            colorByPoint: true,
                            data: getAnalystsChartOptions(__assign({}, ((_b = data === null || data === void 0 ? void 0 : data.analysts) !== null && _b !== void 0 ? _b : {}))),
                        },
                    ],
                },
                teamsChartOptions: {
                    title: {
                        text: 'Cost Avoided By Teams',
                    },
                    series: [
                        {
                            type: 'pie',
                            colorByPoint: true,
                            data: [
                                {
                                    name: 'UA Team',
                                    y: _get(data === null || data === void 0 ? void 0 : data.total, 'UA_team', 0),
                                },
                                {
                                    name: 'USA Team',
                                    y: _get(data === null || data === void 0 ? void 0 : data.total, 'USA_team', 0),
                                },
                                // {
                                //     name: "PHL Team",
                                //     y: _get(data?.total, "PHL_team", 0)
                                // },
                                {
                                    name: 'ML-bot Team',
                                    y: _get(data === null || data === void 0 ? void 0 : data.total, 'ML_bot_system', 0),
                                },
                            ],
                        },
                    ],
                },
            };
        },
    });
};
