// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-viewed-ai-log .modal-body {
  min-height: 320px;
}
.modal-viewed-ai-log .loader-block {
  margin-top: 0 !important;
}
.modal-viewed-ai-log-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  font-size: 14px;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
}
.dark-layout .modal-viewed-ai-log-tab {
  border: 1px solid #3e3f4f;
}
.modal-viewed-ai-log-tab.active {
  background: #2962ff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/modals/actionItem/ViewedAILog/ViewedAILog.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,wBAAA;AAAJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;AACI;EACE,yBAAA;AACN;AACI;EACE,mBAAA;EACA,WAAA;AACN","sourcesContent":[".modal-viewed-ai-log {\n  & .modal-body {\n    min-height: 320px;\n  }\n  & .loader-block {\n    margin-top: 0 !important;\n  }\n  &-tab {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 160px;\n    font-size: 14px;\n    border-radius: 5px;\n    height: 30px;\n    cursor: pointer;\n    margin-right: 10px;\n    border: 1px solid #d9d9d9;\n    .dark-layout & {\n      border: 1px solid #3e3f4f;\n    }\n    &.active {\n      background: #2962ff;\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
