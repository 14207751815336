var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from "react";
import Dropzone from "react-dropzone";
import { FaBookmark, FaCheckCircle, FaEye, FaPlus, FaRedo, FaTimes, FaTrash, FaUndo } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, ButtonGroup } from "reactstrap";
import { shallow } from "zustand/shallow";
import { SYSTEM_TYPE_AMAZON } from "../../../constants";
import { SystemTypeContext } from "../../context/SystemTypeContext";
import { updatePositionOnImageFlip } from "../../features/equipmentPhotos";
import { useEquipmentPhotoAttachMutation } from "../../services/equipmentPhotos/useEquipmentPhotoAttachMutation";
import { useEquipmentPhotoDeleteMutation } from "../../services/equipmentPhotos/useEquipmentPhotoDeleteMutation";
import { InstallationPointStore } from "../../store/charts/chartsContent/InstallationPointStore";
import { useConfirmModalActions } from "../../store/global/useConfirmModalStore";
import ImageDropLayout from "./ImageDropLayout";
var EquipmentPhotosViewer = function (_a) {
    var imageRef = _a.imageRef, flipTurns = _a.flipTurns, setFlipTurns = _a.setFlipTurns, positions = _a.positions, setPositions = _a.setPositions, getImageIdByIndex = _a.getImageIdByIndex, mainImageId = _a.mainImageId, setMainImageId = _a.setMainImageId, index = _a.index, setIndex = _a.setIndex, loader = _a.loader, setLoader = _a.setLoader;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        equipmentPhotos: state.equipmentPhotos,
        setStates: state.setStates,
    }); }, shallow), installationPoint = _b.installationPoint, equipmentPhotos = _b.equipmentPhotos, setStates = _b.setStates;
    var equipmentPhotoDeleteMutation = useEquipmentPhotoDeleteMutation(installationPoint.equipment_id, installationPoint.id).mutate;
    var equipmentPhotoAttahMutation = useEquipmentPhotoAttachMutation(installationPoint.equipment_id, installationPoint.id).mutate;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onUpload = function (selectedFile) {
        if (!selectedFile) {
            return toast.error('File not found', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        setLoader(true);
        var data = new FormData();
        data.append('file', selectedFile);
        equipmentPhotoAttahMutation(data, {
            onSuccess: function (resp) {
                if (resp.success) {
                    toast.success('Successfully uploaded', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    setStates({ equipmentPhotos: resp.equipment_photos });
                }
            },
            onSettled: function () {
                setLoader(false);
            },
        });
    };
    var onRotate = function (angle) {
        var _a;
        var id = getImageIdByIndex(index);
        var updatedPositions = updatePositionOnImageFlip(positions, index, angle);
        setFlipTurns(__assign(__assign({}, flipTurns), (_a = {}, _a[id] = (flipTurns[id] || 0) + angle, _a)));
        setPositions(updatedPositions);
    };
    var onDelete = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to delete this photo?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                setLoader(true);
                equipmentPhotoDeleteMutation(id, {
                    onSuccess: function (resp) {
                        if (resp.success) {
                            toast.success('Successfully removed', { icon: _jsx(FaTrash, { style: { fill: '#198754' } }) });
                            setStates({ equipmentPhotos: resp.equipment_photos });
                            setIndex(0);
                        }
                    },
                    onSettled: function () {
                        setLoader(false);
                    },
                });
            },
        });
        var id = getImageIdByIndex(index);
    };
    var onView = function () {
        var _a, _b;
        var currentPhotoId = getImageIdByIndex(index);
        var currentPhotoUrl = (_b = (_a = equipmentPhotos.find(function (_a) {
            var id = _a.id;
            return id === currentPhotoId;
        })) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : null;
        if (!currentPhotoUrl) {
            return;
        }
        window.open(currentPhotoUrl, '_blank');
    };
    return (_jsxs(_Fragment, { children: [equipmentPhotos.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(ImageDropLayout, { setIndex: setIndex, imageRef: imageRef, positions: positions, index: index, id: getImageIdByIndex(index), flipTurns: flipTurns, setPositions: setPositions }), _jsxs(ButtonGroup, { className: "border-bottom pb-2 d-block w-100 text-center", children: [selectedSystemType === SYSTEM_TYPE_AMAZON ? (_jsx(_Fragment, {})) : (_jsxs(Button, { disabled: loader, onClick: function () { return setMainImageId(getImageIdByIndex(index)); }, color: "success", size: "sm", children: [_jsx(FaBookmark, {}), " Set as Main Image"] })), _jsxs(Button, { disabled: loader, onClick: onView, color: "primary", size: "sm", children: [_jsx(FaEye, {}), " View"] }), _jsxs(Button, { disabled: loader, onClick: function () { return onRotate(-0.25); }, color: "primary", size: "sm", children: [_jsx(FaUndo, {}), " Rotate"] }), _jsxs(Button, { disabled: loader, onClick: function () { return onRotate(0.25); }, color: "primary", size: "sm", children: [_jsx(FaRedo, {}), " Rotate"] }), _jsxs(Button, { disabled: loader, onClick: onDelete, color: "danger", size: "sm", children: [_jsx(FaTrash, {}), " Delete Image"] })] })] })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "d-flex flex-wrap mt-1", children: [_jsx(Dropzone, { multiple: false, onDrop: function (files) { return onUpload(files[0]); }, accept: "image/*", children: function (_a) {
                            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
                            return (_jsxs("div", __assign({}, getRootProps(), { className: "add-image-block", title: 'Add Equipment Photo', children: [_jsx("input", __assign({}, getInputProps())), _jsx(Button, { className: "btn-circle btn-icon btn-add-image", color: "primary", children: _jsx(FaPlus, {}) })] })));
                        } }), equipmentPhotos.map(function (photo, indexThumbnail) { return (_jsxs("div", { className: "position-relative", children: [getImageIdByIndex(indexThumbnail) === mainImageId ? (_jsx("span", { style: { zIndex: 1, top: 8, right: 8, borderRadius: 5, padding: 2 }, className: "text-light position-absolute bg-success", children: _jsx(FaBookmark, {}) })) : (_jsx(_Fragment, {})), _jsx("img", { onClick: function () { return setIndex(indexThumbnail); }, style: {
                                    maxHeight: '64px',
                                    minHeight: '64px',
                                    maxWidth: '64px',
                                    minWidth: '64px',
                                    margin: 5,
                                    border: index === indexThumbnail ? '2px solid #007bff' : 'unset',
                                    transform: "rotate(".concat(flipTurns[getImageIdByIndex(indexThumbnail)] || 0, "turn)"),
                                }, src: photo.url, alt: photo.url })] }, photo.url + '_' + indexThumbnail)); })] })] }));
};
export default memo(EquipmentPhotosViewer);
