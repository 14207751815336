import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { find as _find } from 'lodash';
import React, { memo } from 'react';
import { amplitudesArray, frequenciesArray } from '../features/wosFeatures';
var WakeOnShakeView = function (_a) {
    var _b, _c, _d, _e;
    var data = _a.data, installationPointId = _a.installationPointId;
    if (!data || !((_b = data[installationPointId]) === null || _b === void 0 ? void 0 : _b.wos)) {
        return (_jsxs("div", { children: [_jsx("b", { children: "WOS TRIGGER" }), _jsx("br", {}), _jsx("span", { children: "Not Set" })] }));
    }
    var wos = data[installationPointId].wos;
    var selectedFrequency = _find(frequenciesArray, [
        'key',
        (_c = wos.data_rate) !== null && _c !== void 0 ? _c : 0,
    ]);
    var selectedAmplitude = _find(amplitudesArray, ['key', (_d = wos.threshold) !== null && _d !== void 0 ? _d : 0]);
    selectedAmplitude = selectedAmplitude ? selectedAmplitude : amplitudesArray[0];
    var wosDelayStr = "".concat((_e = wos.delay) !== null && _e !== void 0 ? _e : 0, "&nbsp;ms");
    var frequencyValueStr = "".concat(selectedFrequency.value, "&nbsp;HZ");
    var amplitudeValueStr = selectedAmplitude.value < 1
        ? (selectedAmplitude.value * 1000).toFixed(2) + '&nbsp;mG'
        : "".concat(selectedAmplitude.value, "&nbsp;G");
    return (_jsxs("div", { children: [_jsx("b", { children: "WOS TRIGGER" }), _jsx("br", {}), _jsx("b", { children: "Trigger Delay:" }), " ", _jsx("span", { dangerouslySetInnerHTML: { __html: wosDelayStr } }), _jsx("br", {}), _jsx("b", { children: "Threshold Amplitude (pk-pk):" }), " ", _jsx("span", { dangerouslySetInnerHTML: { __html: amplitudeValueStr } }), _jsx("br", {}), _jsx("b", { children: "Scan Frequency:" }), " ", _jsx("span", { dangerouslySetInnerHTML: { __html: frequencyValueStr } })] }));
};
export default memo(WakeOnShakeView);
