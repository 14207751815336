import { forEach as _forEach } from 'lodash';
export var buildTree = function (structure) {
    var zoneLayout = [];
    var areasLayout = [];
    _forEach(structure, function (value, key) {
        areasLayout = [];
        _forEach(value, function (v) {
            areasLayout.push(v);
        });
        zoneLayout.push({
            zone: key,
            areas: areasLayout,
        });
    });
    return zoneLayout;
};
export var onZoneChange = function (dispatch, zone) {
    dispatch({
        type: 'setState',
        state: {
            selectedAreas: zone.areas,
            zone: zone.zone,
        },
    });
};
