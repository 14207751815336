var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { each as _each, first as _first, get as _get, includes as _includes, size as _size, sortBy as _sortBy, } from 'lodash';
import React, { memo, useContext, useMemo, useState } from 'react';
import { FaCheckCircle, FaInfoCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Alert, Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { AXIS_X, AXIS_Y, AXIS_Z, SENSOR } from '../../../constants';
import InputRange from '../../../shared/components/InputRange';
import FMaxSelect from '../../../shared/components/requestOnDemand/FmaxSelect';
import LineOfResolutionSelect from '../../../shared/components/requestOnDemand/LineOfResolutionSelect';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import '../../../shared/modals/machineInfo/MachineInfo.scss';
import { readingTypeIntervalLabels } from '../../../shared/modals/machineInfo/tabs/Intervals/Intervals';
import { InstallationPointStore } from '../../../shared/store/charts/chartsContent/InstallationPointStore';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import WakeOnShakeSettings from './components/WakeOnShakeSettings';
import { checkWosSupport } from './features/wosFeatures';
var IntervalsForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var intervalsFormData = _a.intervalsFormData, callback = _a.callback;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var visible = intervalsFormData.visible, onCancel = intervalsFormData.onCancel, serials = intervalsFormData.serials, equipment = intervalsFormData.equipment;
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var _s = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
    }); }, shallow), installationActivePoint = _s.installationPoint, setActiveInstallationPoint = _s.setActiveInstallationPoint;
    var installationPoint = equipment && !intervalsFormData.installationPoint
        ? _first(equipment.installationPoints)
        : intervalsFormData.installationPoint;
    var fftSettings = _get(installationPoint, 'settings.fft_settings') || [];
    var moteYZAxis = _get(installationPoint, 'settings.moteFMax') || [];
    var wosTriggerData = (_c = (_b = intervalsFormData.triggers) === null || _b === void 0 ? void 0 : _b.data[installationPoint.id]) === null || _c === void 0 ? void 0 : _c.wos;
    var versionType = _get(installationPoint, 'sensor.version_type', false);
    var version = _get(installationPoint, 'sensor.version', '0.0.0');
    var wosSupport = checkWosSupport((_d = intervalsFormData.triggers) === null || _d === void 0 ? void 0 : _d.restriction, versionType, version);
    var _t = useState({
        installation_point_id: installationPoint.id,
        customer_id: installationPoint.customer_id,
        minInterval: _get(installationPoint, 'settings.minInterval', 900),
        temperature_interval: _get(installationPoint, 'settings.temperature_interval', ''),
        impactvue_rms_interval: _get(installationPoint, 'settings.impactvue_rms_interval', ''),
        impactvue_fft_interval: _get(installationPoint, 'settings.impactvue_fft_interval', ''),
        temperature_interval_type: _get(installationPoint, 'settings.temperature_interval_type', ''),
        impactvue_rms_interval_type: _get(installationPoint, 'settings.impactvue_rms_interval_type', ''),
        impactvue_fft_interval_type: _get(installationPoint, 'settings.impactvue_fft_interval_type', ''),
        rms_interval: _get(installationPoint, 'settings.rms_interval', ''),
        high_pass: _get(installationPoint, 'settings.high_pass', ''),
        rms_interval_type: _get(installationPoint, 'settings.rms_interval_type', ''),
        fft_interval: _get(installationPoint, 'settings.fft_interval', ''),
        fft_interval_type: _get(installationPoint, 'settings.fft_interval_type', ''),
        fft_settings: fftSettings,
        triggers: wosSupport
            ? {
                id: (_e = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.installation_point_id) !== null && _e !== void 0 ? _e : installationPoint.id,
                wos: wosTriggerData
                    ? {
                        client_type: (_f = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.client_type) !== null && _f !== void 0 ? _f : 0,
                        data_rate: (_g = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.data_rate) !== null && _g !== void 0 ? _g : null,
                        delay: (_h = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.delay) !== null && _h !== void 0 ? _h : null,
                        installation_point_id: (_j = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.installation_point_id) !== null && _j !== void 0 ? _j : installationPoint.id,
                        samples: (_k = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.samples) !== null && _k !== void 0 ? _k : 2,
                        threshold: (_l = wosTriggerData === null || wosTriggerData === void 0 ? void 0 : wosTriggerData.threshold) !== null && _l !== void 0 ? _l : null,
                    }
                    : void 0,
            }
            : null,
    }), data = _t[0], setData = _t[1];
    var isSupportAxes = !!_get(installationPoint, 'fftAxes');
    var onConfirm = function () {
        var endpoint = equipment
            ? function () { return Endpoints[selectedSystemType].saveIntervalsEquipment(equipment.id, data); }
            : function () { return Endpoints[selectedSystemType].saveIntervals(installationPoint.id, data); };
        endpoint().then(function (resp) {
            if (resp.success) {
                onCancel();
                callback();
                if (installationPoint.id === installationActivePoint.id) {
                    setActiveInstallationPoint(__assign(__assign({}, installationActivePoint), { settings: __assign(__assign({}, installationActivePoint.settings), { 
                            // @ts-ignore
                            high_pass: data.high_pass }) }));
                }
                return toast.success(resp.message || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
            }
            return toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    var onFftSettingChange = function (axisId, name, value) {
        var _a, _b;
        setData(__assign(__assign({}, data), { fft_settings: __assign(__assign({}, data.fft_settings), (_a = {}, _a[axisId] = __assign(__assign({}, data.fft_settings[axisId]), (_b = {}, _b[name] = value, _b)), _a)) }));
    };
    var onAllAxesFftSettingChange = function (name, value) {
        var _a, _b, _c, _d, _e;
        var countAxis = _size(data.fft_settings);
        var isXAxisDepends = _includes([2, 12, 5, 15], +versionType);
        var values = _get(moteYZAxis, value);
        var newData = __assign(__assign({}, data), { fft_settings: __assign(__assign({}, data.fft_settings), (_a = {}, _a[AXIS_X] = __assign(__assign({}, data.fft_settings[AXIS_X]), (_b = {}, _b[name] = value, _b)), _a[AXIS_Y] = __assign(__assign({}, data.fft_settings[AXIS_Y]), (_c = {}, _c[name] = isXAxisDepends ? _get(values, 'y_fmax') : value, _c)), _a)) });
        if (+countAxis === 3) {
            newData = __assign(__assign({}, newData), { fft_settings: __assign(__assign({}, newData.fft_settings), (_d = {}, _d[AXIS_Z] = __assign(__assign({}, newData.fft_settings[AXIS_Z]), (_e = {}, _e[name] = isXAxisDepends ? _get(values, 'z_fmax') : value, _e)), _d)) });
        }
        setData(newData);
    };
    var isImpactvueEnabled = ((_m = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensorParameters) === null || _m === void 0 ? void 0 : _m.supportHfdvue) &&
        ((_o = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensor) === null || _o === void 0 ? void 0 : _o.is_hfdvue) &&
        +((_p = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.sensor) === null || _p === void 0 ? void 0 : _p.is_hfdvue) === 1;
    var valueKey = isImpactvueEnabled ? 'impactvue_' : '';
    var sensorParameters = _get(installationPoint, 'sensorParameters') || [];
    var fMaxValues = _get(installationPoint, "sensorParameters.".concat(valueKey, "fmax")) || [];
    var lorValues = _get(installationPoint, 'sensorParameters.lines_of_resolution') || [];
    var lorValuesImpactVue = _get(installationPoint, 'sensorParameters.lines_of_resolution') || [];
    var hfdVueSettings = _get(data.fft_settings, AXIS_X, {});
    var lowerThanMinInterval = function (interval, type, minInterval) {
        if (+interval === 0 && +type === 0) {
            return false;
        }
        return interval * getIntervalTypeMultiplier(type) < minInterval;
    };
    var getDurationReadable = function (seconds) {
        var duration = seconds;
        var durationReadable = '';
        if (duration >= 604800) {
            var days = Math.floor(duration / 604800);
            duration -= days * 604800;
            durationReadable += days + ' week' + (days > 1 ? 's' : '') + ' ';
        }
        if (duration >= 86400) {
            var days = Math.floor(duration / 86400);
            duration -= days * 86400;
            durationReadable += days + ' day' + (days > 1 ? 's' : '') + ' ';
        }
        if (duration >= 3600) {
            var hours = Math.floor(duration / 3600);
            duration -= hours * 3600;
            durationReadable += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
        }
        if (duration >= 60) {
            var minutes = Math.floor(duration / 60);
            duration -= minutes * 60;
            durationReadable += minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ';
        }
        if (duration > 0) {
            durationReadable += duration + ' second' + (duration > 1 ? 's' : '');
        }
        return durationReadable;
    };
    var isMultipleOfMinInterval = function (intervalInSeconds, valueInSeconds) {
        return valueInSeconds % intervalInSeconds === 0;
    };
    var getNearestSuitableValues = function (intervalInSeconds, valueInSeconds) {
        var loopIterator = Math.floor(valueInSeconds / intervalInSeconds);
        var suitableValues = getDurationReadable((loopIterator || 1) * intervalInSeconds);
        if (loopIterator > 0) {
            suitableValues += ' or ' + getDurationReadable(++loopIterator * intervalInSeconds);
        }
        return suitableValues;
    };
    var getIntervalTypeMultiplier = function (type) {
        switch (type) {
            case 2:
                return 60;
            case 3:
                return 3600;
            case 4:
                return 86400;
            case 5:
                return 604800;
            default:
                return 1;
        }
    };
    if (isImpactvueEnabled) {
        var fMaxValues_1 = _get(sensorParameters, 'impactvue_fmax', []) || _get(sensorParameters, 'fmax', []) || [];
        var maxAcquisitionTime_1 = _get(sensorParameters, 'maxAcquisitionTime', 60);
        var newLorValues_1 = [];
        _each(lorValuesImpactVue, function (lorValue) {
            newLorValues_1.push(lorValue);
        });
        _each(fMaxValues_1, function (fMaxValue) {
            var exist = false;
            _each(newLorValues_1, function (lorValue) {
                if (+lorValue > fMaxValue * maxAcquisitionTime_1) {
                    exist = true;
                }
            });
            if (exist) {
                newLorValues_1.push(fMaxValue * maxAcquisitionTime_1);
            }
        });
        _each(data.fft_settings, function (fftSetting) {
            if (fftSetting.hfdvue_lines_of_resolution / fftSetting.fmax > maxAcquisitionTime_1) {
                fftSetting.hfdvue_lines_of_resolution = fftSetting.fmax * maxAcquisitionTime_1;
            }
        });
        lorValuesImpactVue = _sortBy(newLorValues_1);
    }
    var maxHighPass = +_get(fftSettings, '1.fmax', 1200);
    var isNotRmsMultipleOfTemperatureReading = useMemo(function () {
        return !isMultipleOfMinInterval(data.minInterval, (data.rms_interval || 0) * getIntervalTypeMultiplier(data.rms_interval_type || 0));
    }, [data.rms_interval_type, data.rms_interval, data.minInterval]);
    var impactVueIsNotRmsMultipleOfTemperatureReading = useMemo(function () {
        return !isMultipleOfMinInterval(data.minInterval, (data.impactvue_rms_interval || 0) * getIntervalTypeMultiplier(data.impactvue_rms_interval_type || 0));
    }, [data.impactvue_rms_interval_type, data.impactvue_rms_interval, data.minInterval]);
    var isNotFftMultipleOfTemperatureReading = useMemo(function () {
        return !isMultipleOfMinInterval(data.minInterval, (data.fft_interval || 0) * getIntervalTypeMultiplier(data.fft_interval_type || 0));
    }, [data.fft_interval_type, data.fft_interval, data.minInterval]);
    var impactVueIsNotFftMultipleOfTemperatureReading = useMemo(function () {
        return !isMultipleOfMinInterval(data.minInterval, (data.impactvue_fft_interval || 0) * getIntervalTypeMultiplier(data.impactvue_fft_interval_type || 0));
    }, [data.impactvue_fft_interval_type, data.impactvue_fft_interval, data.minInterval]);
    var rmsNearestSuitableValues = useMemo(function () {
        return getNearestSuitableValues(data.minInterval, (data.rms_interval || 0) * getIntervalTypeMultiplier(data.rms_interval_type || 0));
    }, [data.rms_interval_type, data.rms_interval, data.minInterval]);
    var impactVueRmsNearestSuitableValues = useMemo(function () {
        return getNearestSuitableValues(data.minInterval, (data.impactvue_rms_interval || 0) * getIntervalTypeMultiplier(data.impactvue_rms_interval_type || 0));
    }, [data.impactvue_rms_interval_type, data.impactvue_rms_interval, data.minInterval]);
    var fftNearestSuitableValues = useMemo(function () {
        return getNearestSuitableValues(data.minInterval, (data.fft_interval || 0) * getIntervalTypeMultiplier(data.fft_interval_type || 0));
    }, [data.fft_interval_type, data.fft_interval, data.minInterval]);
    var impactVueFftNearestSuitableValues = useMemo(function () {
        return getNearestSuitableValues(data.minInterval, (data.impactvue_fft_interval || 0) * getIntervalTypeMultiplier(data.impactvue_fft_interval_type || 0));
    }, [data.impactvue_fft_interval_type, data.impactvue_fft_interval, data.minInterval]);
    var lowerThanRmsMinInterval = useMemo(function () {
        return lowerThanMinInterval(data.rms_interval || 0, data.rms_interval_type || 0, data.minInterval);
    }, [data.rms_interval_type, data.rms_interval, data.minInterval]);
    var impactVueLowerThanRmsMinInterval = useMemo(function () {
        return lowerThanMinInterval(data.impactvue_rms_interval || 0, data.impactvue_rms_interval_type || 0, data.minInterval);
    }, [data.impactvue_rms_interval_type, data.impactvue_rms_interval, data.minInterval]);
    var lowerThanFftMinInterval = useMemo(function () {
        return lowerThanMinInterval(data.fft_interval || 0, data.fft_interval_type || 0, data.minInterval);
    }, [data.fft_interval_type, data.fft_interval, data.minInterval]);
    var impactVueLowerThanFftMinInterval = useMemo(function () {
        return lowerThanMinInterval(data.impactvue_fft_interval || 0, data.impactvue_fft_interval_type || 0, data.minInterval);
    }, [data.impactvue_fft_interval_type, data.impactvue_fft_interval, data.minInterval]);
    var readableTemperatureInterval = useMemo(function () {
        return getDurationReadable((data.temperature_interval || 0) * getIntervalTypeMultiplier(data.temperature_interval_type || 0));
    }, [data.temperature_interval, data.temperature_interval_type]);
    return (_jsxs(Modal, { className: "modal-response", size: "lg", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Set Interval for", ' ', _jsx(_Fragment, { children: equipment ? (equipment.name) : (_jsxs(_Fragment, { children: [installationPoint.name, ' ', _jsx(SensorId, { sensor_id: installationPoint.sensor_id ? installationPoint.sensor_id : void 0, showDecId: false })] })) })] }), _jsxs(ModalBody, { className: "fz-13", children: [_jsxs(_Fragment, { children: [lowerThanRmsMinInterval ? (_jsx("div", { children: 'The minimum RMS interval is ' + getDurationReadable(data.minInterval).trimEnd() + '.' })) : (_jsx(_Fragment, {})), impactVueLowerThanRmsMinInterval ? (_jsx("div", { children: 'The minimum ImpactVue RMS interval is ' +
                                    getDurationReadable(data.minInterval).trimEnd() +
                                    '.' })) : (_jsx(_Fragment, {})), lowerThanFftMinInterval ? (_jsx("div", { children: 'The minimum FFT interval is ' + getDurationReadable(data.minInterval).trimEnd() + '.' })) : (_jsx(_Fragment, {})), impactVueLowerThanFftMinInterval ? (_jsx("div", { children: 'The minimum ImpactVue FFT interval is ' +
                                    getDurationReadable(data.minInterval).trimEnd() +
                                    '.' })) : (_jsx(_Fragment, {})), isNotRmsMultipleOfTemperatureReading ? (_jsx("div", { children: "The RMS interval must be a multiple of the temperature interval (".concat(readableTemperatureInterval.trimEnd(), "). ").concat(readableTemperatureInterval !== rmsNearestSuitableValues
                                    ? 'Suitable values: ' + rmsNearestSuitableValues + '.'
                                    : '') })) : (_jsx(_Fragment, {})), impactVueIsNotRmsMultipleOfTemperatureReading ? (_jsx("div", { children: "The ImpactVue RMS interval must be a multiple of the temperature interval (".concat(readableTemperatureInterval.trimEnd(), "). ").concat(readableTemperatureInterval !== impactVueRmsNearestSuitableValues
                                    ? 'Suitable values: ' + impactVueRmsNearestSuitableValues + '.'
                                    : '') })) : (_jsx(_Fragment, {})), isNotFftMultipleOfTemperatureReading ? (_jsx("div", { children: "The FFT interval must be a multiple of the temperature interval (".concat(readableTemperatureInterval.trimEnd(), "). ").concat(readableTemperatureInterval !== fftNearestSuitableValues
                                    ? 'Suitable values: ' + fftNearestSuitableValues + '.'
                                    : '') })) : (_jsx(_Fragment, {})), impactVueIsNotFftMultipleOfTemperatureReading ? (_jsx("div", { children: "The ImpactVue FFT interval must be a multiple of the temperature interval (".concat(readableTemperatureInterval.trimEnd(), "). ").concat(readableTemperatureInterval !== impactVueFftNearestSuitableValues
                                    ? 'Suitable values: ' + impactVueFftNearestSuitableValues + '.'
                                    : '') })) : (_jsx(_Fragment, {}))] }), _jsx(Row, { className: "mb-2 mt-2", children: _jsxs(Col, { children: ["RMS and FFT intervals will be applied for all tethered sensors on the following node(s):", ' ', _jsx("b", { children: serials.join(', ') })] }) }), _jsx(Row, { children: _jsxs(Col, { md: 12, className: "border-bottom pb-2", children: [_jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsx(Label, { for: "temperature_interval", children: _jsx("b", { children: "Temperature:" }) }) }), _jsx(Col, { md: 4, children: _jsx(Input, { id: "temperature_interval", label: "temperature_interval", bsSize: "sm", disabled: true, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { temperature_interval: +ev.target.value }));
                                                    }, value: data.temperature_interval }) }), _jsx(Col, { md: 4, children: _jsx(Input, { type: "select", name: "temperature_interval_type", bsSize: "sm", disabled: true, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { temperature_interval_type: +ev.target.value }));
                                                    }, value: data.temperature_interval_type, children: Object.values(readingTypeIntervalLabels).map(function (readingTypeIntervalLabel, index) { return (_jsx("option", { value: Object.keys(readingTypeIntervalLabels)[index], children: readingTypeIntervalLabel }, "temperature_interval_type_".concat(index))); }) }) })] }) }), _jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsx(Label, { for: "rms_interval", children: _jsx("b", { children: "RMS:" }) }) }), _jsx(Col, { md: 4, children: _jsx(Input, { id: "rms_interval", label: "rms_interval", bsSize: "sm", onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { rms_interval: +ev.target.value }));
                                                    }, value: data.rms_interval }) }), _jsx(Col, { md: 4, children: _jsx(Input, { type: "select", name: "rms_interval_type", bsSize: "sm", onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { rms_interval_type: +ev.target.value }));
                                                    }, value: data.rms_interval_type, children: Object.values(readingTypeIntervalLabels).map(function (readingTypeIntervalLabel, index) { return (_jsx("option", { value: Object.keys(readingTypeIntervalLabels)[index], children: readingTypeIntervalLabel }, "rms_interval_type_".concat(index))); }) }) })] }) }), _jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsx(Label, { for: "temperature_interval", children: _jsx("b", { children: "FFT:" }) }) }), _jsx(Col, { md: 4, children: _jsx(Input, { id: "fft_interval", label: "fft_interval", bsSize: "sm", onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { fft_interval: +ev.target.value }));
                                                    }, value: data.fft_interval }) }), _jsx(Col, { md: 4, children: _jsx(Input, { type: "select", name: "fft_interval_type", bsSize: "sm", onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { fft_interval_type: +ev.target.value }));
                                                    }, value: data.fft_interval_type, children: Object.values(readingTypeIntervalLabels).map(function (readingTypeIntervalLabel, index) { return (_jsx("option", { value: Object.keys(readingTypeIntervalLabels)[index], children: readingTypeIntervalLabel }, "fft_interval_type_".concat(index))); }) }) })] }) }), isImpactvueEnabled ? (_jsxs(_Fragment, { children: [_jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsx(Label, { for: "rms_interval", children: _jsx("b", { children: "RMS ImpactVUE:" }) }) }), _jsx(Col, { md: 4, children: _jsx(Input, { id: "impactvue_rms_interval", label: "impactvue_rms_interval", bsSize: "sm", onChange: function (ev) {
                                                                return setData(__assign(__assign({}, data), { impactvue_rms_interval: +ev.target.value }));
                                                            }, value: data.impactvue_rms_interval }) }), _jsx(Col, { md: 4, children: _jsx(Input, { type: "select", name: "impactvue_rms_interval_type", bsSize: "sm", onChange: function (ev) {
                                                                return setData(__assign(__assign({}, data), { impactvue_rms_interval_type: +ev.target.value }));
                                                            }, value: data.impactvue_rms_interval_type, children: Object.values(readingTypeIntervalLabels).map(function (readingTypeIntervalLabel, index) { return (_jsx("option", { value: Object.keys(readingTypeIntervalLabels)[index], children: readingTypeIntervalLabel }, "impactvue_rms_interval_type_".concat(index))); }) }) })] }) }), _jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsx(Label, { for: "impactvue_fft_interval", children: _jsx("b", { children: "FFT ImpactVUE:" }) }) }), _jsx(Col, { md: 4, children: _jsx(Input, { id: "impactvue_fft_interval", label: "impactvue_fft_interval", bsSize: "sm", onChange: function (ev) {
                                                                return setData(__assign(__assign({}, data), { impactvue_fft_interval: +ev.target.value }));
                                                            }, value: data.impactvue_fft_interval }) }), _jsx(Col, { md: 4, children: _jsx(Input, { type: "select", name: "impactvue_fft_interval_type", bsSize: "sm", onChange: function (ev) {
                                                                return setData(__assign(__assign({}, data), { impactvue_fft_interval_type: +ev.target.value }));
                                                            }, value: data.impactvue_fft_interval_type, children: Object.values(readingTypeIntervalLabels).map(function (readingTypeIntervalLabel, index) { return (_jsx("option", { value: Object.keys(readingTypeIntervalLabels)[index], children: readingTypeIntervalLabel }, "impactvue_fft_interval_type_".concat(index))); }) }) })] }) })] })) : (_jsx(_Fragment, {})), _jsx(FormGroup, { children: _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs(Label, { for: "high_pass", children: [_jsx("span", { id: "high_pass_notification", className: "me-2 cursor-pointer", children: _jsx(FaInfoCircle, {}) }), _jsx(UncontrolledTooltip, { target: "high_pass_notification", children: "When setting a high-pass filter for tethered sensor, it will apply to all sensors associated with this node" }), _jsx("b", { children: "RMS High-Pass Filter:" })] }) }), _jsxs(Col, { md: 4, children: [_jsx(Input, { bsSize: "sm", type: "number", name: 'high_pass', id: "title", value: data.high_pass, step: 1, min: 1, max: maxHighPass, onChange: function (ev) {
                                                            var value = +ev.target.value;
                                                            if (value <= maxHighPass && value >= 1) {
                                                                setData(__assign(__assign({}, data), { high_pass: value }));
                                                            }
                                                        } }), _jsx("div", { className: "mt-1", children: _jsx(InputRange, { maxValue: maxHighPass, minValue: 1, value: +data.high_pass, onChange: function (value) {
                                                                if (+value <= maxHighPass && +value >= 1) {
                                                                    setData(__assign(__assign({}, data), { high_pass: +value }));
                                                                }
                                                            } }) })] })] }) })] }) }), _jsx("h5", { className: "fz-15 mt-2 mb-2", children: _jsx("b", { children: "FFT Settings" }) }), _jsxs(Row, { className: "border-bottom", children: [isSupportAxes && (_jsx(React.Fragment, { children: Object.values(data.fft_settings).map(function (fftSetting, index) { return (_jsxs(Col, { md: isImpactvueEnabled && +_size(data.fft_settings) === 3 ? 3 : 4, 
                                    // @ts-ignore
                                    className: index !== data.fft_settings.length - 1 ? 'border-end' : '', children: [_get(fftSetting, 'axis_name', '') && (_jsx("div", { className: "text-center", children: _jsxs("b", { children: [(_get(fftSetting, 'axis_name', '') || '').toUpperCase(), "-axis"] }) })), _jsx(FMaxSelect, { name: 'fmax', isHFDvue: false, isDisabled: false, value: fftSetting.fmax, axisId: fftSetting.axis_id, fmaxValues: fMaxValues, loader: false, setFmax: onAllAxesFftSettingChange, sensorParameters: sensorParameters, disabled: fftSetting.axis_id !== +AXIS_X }), index === 0 ? (_jsx(LineOfResolutionSelect, { name: 'lines_of_resolution', values: lorValues, lor: fftSetting.lines_of_resolution, isDisabled: false, axisId: fftSetting.axis_id, setLor: onFftSettingChange })) : (_jsx(_Fragment, {})), +_get(installationPoint, 'sensor.version_type', 1) === SENSOR.VERSION_TYPE_V2 &&
                                            +fftSetting.lines_of_resolution >= 3750 && (_jsx("div", { className: "text-primary", children: "Note: Higher Lines of Resolution impact the battery life." }))] }, "interval_row_".concat(installationPoint.id, "_fft_").concat(fftSetting.axis_name))); }) })), !isSupportAxes && (_jsx(React.Fragment, { children: Object.values(data.fft_settings).map(function (fftSetting, index) { return (_jsxs(Col, { md: isImpactvueEnabled && +_size(data.fft_settings) === 3 ? 3 : 4, 
                                    // @ts-ignore
                                    className: index !== data.fft_settings.length - 1 ? 'border-end' : '', children: [_get(fftSetting, 'axis_name', '' || '') && (_jsx("div", { className: "text-center", children: _jsxs("b", { children: [(_get(fftSetting, 'axis_name', '') || '').toUpperCase(), "-axis"] }) })), _jsx(FMaxSelect, { name: 'fmax', isHFDvue: false, isDisabled: false, value: fftSetting.fmax, fmaxValues: fMaxValues, loader: false, setFmax: onAllAxesFftSettingChange, sensorParameters: sensorParameters, disabled: false }), _jsx(LineOfResolutionSelect, { name: 'lines_of_resolution', values: lorValues, lor: fftSetting.lines_of_resolution, isDisabled: false, setLor: onFftSettingChange }), +_get(installationPoint, 'sensor.version_type', 1) === SENSOR.VERSION_TYPE_V2 &&
                                            +fftSetting.lines_of_resolution >= 3750 && (_jsx("div", { className: "text-primary", children: "Note: Higher Lines of Resolution impact the battery life." }))] }, "interval_row_".concat(installationPoint.id, "_fft_").concat(fftSetting.axis_name))); }) })), isImpactvueEnabled && (_jsxs(Col, { md: +_size(data.fft_settings) === 3 ? 3 : 4, className: "border-start", children: [_jsx("div", { className: "text-center", children: _jsx("b", { children: "ImpactVUE" }) }), _jsx(FMaxSelect, { name: 'hfdvue_fmax', isHFDvue: true, isDisabled: false, value: hfdVueSettings.hfdvue_fmax, axisId: hfdVueSettings.axis_id, fmaxValues: fMaxValues, loader: false, setFmax: onAllAxesFftSettingChange, sensorParameters: sensorParameters }), _jsx(LineOfResolutionSelect, { name: 'hfdvue_lines_of_resolution', values: lorValuesImpactVue, lor: hfdVueSettings.hfdvue_lines_of_resolution, isDisabled: false, axisId: hfdVueSettings.axis_id, setLor: onFftSettingChange }), +_get(installationPoint, 'sensor.version_type', 1) === SENSOR.VERSION_TYPE_V2 &&
                                        +hfdVueSettings.hfdvue_lines_of_resolution >= 3750 && (_jsx("div", { className: "text-primary", children: "Note: Higher Lines of Resolution impact the battery life." }))] }))] }), wosSupport ? (_jsx(WakeOnShakeSettings, { installationPoint: installationPoint, wosTriggerData: wosTriggerData, isEnabledByDefault: !!wosTriggerData, data: data, setData: setData, isImpactvueEnabled: isImpactvueEnabled })) : (_jsx(_Fragment, {})), ((_r = (_q = intervalsFormData === null || intervalsFormData === void 0 ? void 0 : intervalsFormData.installationPoint) === null || _q === void 0 ? void 0 : _q.lastSettingsHistory) !== null && _r !== void 0 ? _r : []).length ? (_jsx(Row, { children: _jsx(Col, { md: 12, className: "my-2", children: intervalsFormData.installationPoint.lastSettingsHistory.map(function (el, index) { return (_jsxs(Alert, { color: "primary", children: [el.comment, _jsx("br", {}), "Last update: ", _jsx(DateTimeLabel, { id: "time_".concat(index), dateTime: el.time_create })] }, el.time_create)); }) }) })) : (_jsx(_Fragment, {}))] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", disabled: lowerThanRmsMinInterval ||
                                lowerThanFftMinInterval ||
                                isNotRmsMultipleOfTemperatureReading ||
                                isNotFftMultipleOfTemperatureReading ||
                                impactVueLowerThanRmsMinInterval ||
                                impactVueLowerThanFftMinInterval ||
                                impactVueIsNotRmsMultipleOfTemperatureReading ||
                                impactVueIsNotFftMultipleOfTemperatureReading, onClick: onConfirm, children: "Save" })] }) })] }));
};
export default memo(IntervalsForm);
