import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, last as _last, trim as _trim } from 'lodash';
import React, { memo } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Badge, Col, Row } from 'reactstrap';
import { DateTimeLabelWithUTC } from '../../../../../../widgets/common';
import DefaultTooltip from '../../../../DefaultTooltip';
var RegularReportHeader = function (_a) {
    var activeReport = _a.activeReport, isDeclinedReport = _a.isDeclinedReport, isShowCollapseBtn = _a.isShowCollapseBtn, declinedReports = _a.declinedReports, isVisibleReportBody = _a.isVisibleReportBody, setIsVisibleReportBody = _a.setIsVisibleReportBody;
    return (_jsxs(Row, { className: "regular-report-header ".concat(isVisibleReportBody ? 'active' : '', " ").concat(!isShowCollapseBtn ? 'cursor-default' : ''), onClick: function () { return (isShowCollapseBtn ? setIsVisibleReportBody(!isVisibleReportBody) : {}); }, children: [_jsx(Col, { md: 6, children: _jsxs("div", { className: "d-flex align-items-center", children: [isShowCollapseBtn ? (_jsx("div", { className: "me-3 d-flex align-items-center", children: isVisibleReportBody ? _jsx(FaChevronDown, {}) : _jsx(FaChevronRight, {}) })) : (_jsx(_Fragment, {})), _jsx(DateTimeLabelWithUTC, { dateTime: activeReport.created_at })] }) }), _jsxs(Col, { md: 3, children: [activeReport.time_submited || activeReport.submited_by ? (_jsxs(_Fragment, { children: [_jsx(Badge, { "data-tooltip-id": "ai-report-closed", color: "secondary", className: "me-2", children: "Closed" }), _jsxs(DefaultTooltip, { openEvents: { mouseenter: true }, id: "ai-report-closed", children: ["Completion Report Accepted ", activeReport.submited_by ? ' by ' + activeReport.submited_by : '', activeReport.time_submited ? (_jsxs(_Fragment, { children: [' ', "on ", _jsx(DateTimeLabelWithUTC, { dateTime: activeReport.time_submited })] })) : ('')] })] })) : (_jsx(_Fragment, {})), isDeclinedReport ? (_jsxs(_Fragment, { children: [_jsx(Badge, { "data-tooltip-id": "ai-report-on-hold", color: "secondary", children: "On hold" }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, id: "ai-report-on-hold", children: _trim(_get(_last(declinedReports), 'created_by'))
                                    ? 'Completion Report Declined  by ' + "".concat(_trim(_get(_last(declinedReports), 'created_by')))
                                    : 'Declined by Analyst' })] })) : (_jsx(_Fragment, {}))] }), _jsx(Col, { md: 3, children: activeReport.verified_by })] }));
};
export default memo(RegularReportHeader);
