import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
export var faultFrequenciesConfig = function (data) {
    return {
        columns: [
            {
                name: 'name',
                title: 'Name',
                sortable: false,
            },
            {
                name: 'frequency',
                title: 'Frequency',
                sortable: false,
                component: function (row) {
                    return row.frequency + (row.frequency_type === 'Orders' ? 'x' : '');
                },
            },
            {
                name: 'plot_lines_count',
                title: 'Plot Lines',
                sortable: false,
            },
            {
                name: 'frequency_type',
                title: 'Units',
                sortable: false,
            },
            {
                name: 'action',
                title: '',
                sortable: false,
                component: function (row, callbacks, filtersData) {
                    if (filtersData.readonly) {
                        return _jsx(_Fragment, {});
                    }
                    return (_jsxs("div", { className: "d-flex justify-content-center", children: [_jsxs(ButtonGroup, { id: "customer_created_fault_frequency_".concat(row.id), children: [_jsxs(Button, { color: "success", size: "sm", onClick: function () { return callbacks.onEdit(row); }, children: [_jsx(FaEdit, {}), " Edit"] }), _jsxs(Button, { color: "danger", size: "sm", onClick: function () { return callbacks.onRemove(row.id); }, children: [_jsx(FaTimes, {}), " Remove"] })] }), row.source !== 'analytic' ? (_jsx(UncontrolledTooltip, { target: "customer_created_fault_frequency_".concat(row.id), children: "Customer created fault/forcing frequency" })) : (_jsx(_Fragment, {}))] }));
                },
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        search: '',
        // @ts-ignore
        data: data,
        disabledSearch: true,
        filtersData: {
            selectedCount: 0,
        },
        loader: false,
    };
};
