import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FiRotateCw } from 'react-icons/fi';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../store/charts/chartsContent/ChartsStore';
import { selectChartZoomDays, useChartSettingsActions, } from '../../../../../store/charts/useChartSettingsStore';
import { selectResetRmsZoomHotkey, useChartsHotkeysActions, } from '../../../../../store/hotkeys/useChartsHotkeysStore';
import '../../../../../styles/charts/topBar/ResetRmsZoom.scss';
import DefaultTooltip from '../../../../DefaultTooltip';
import HotkeyInfo from '../../../../HotkeyInfo';
var ResetRmsZoom = function () {
    var setStates = ChartsStore(function (state) { return ({
        setStates: state.setStates,
    }); }, shallow).setStates;
    var chartZoomDays = selectChartZoomDays();
    var setChartZoomDays = useChartSettingsActions().setChartZoomDays;
    var onChange = function (chartZoomDays) {
        setChartZoomDays(+chartZoomDays);
        setStates({
            isCustomZoom: false,
            isChartResetRmsZoom: true,
        });
    };
    var setResetRmsZoomHotkey = useChartsHotkeysActions().setResetRmsZoomHotkey;
    var resetRmsZoomHotkey = selectResetRmsZoomHotkey();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { outline: true, color: "customChartTopBarSelect", className: "btn-chart-tools btn-chart-tools-resetZoom", "data-tooltip-id": "chart-reset-zoom", size: "sm", onClick: function () { return onChange(chartZoomDays); }, children: _jsx(FiRotateCw, {}) }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "top", id: "chart-reset-zoom", clickable: true, children: _jsxs("div", { className: "d-flex", children: [_jsx("span", { className: "me-2", children: "Reset zoom to current" }), _jsx(HotkeyInfo, { onChangeHotkey: setResetRmsZoomHotkey, hotkey: resetRmsZoomHotkey })] }) })] }));
};
export default memo(ResetRmsZoom);
