import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import WatchList from '../../../../shared/components/dashboard/cards/WatchList';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
var WatchListCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'WatchListAmazon';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'facility_name',
            visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
            title: 'Facility',
            sortable: false,
        },
    ];
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'WatchList', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(WatchList, { deps: [selectedFacility, refreshFlag], tableName: tableName, tableColumns: tableColumns, endpoint: function () {
                    return Endpoints.amazon.getDashboardWatchList({
                        query: {
                            selected_facility: selectedFacility,
                        },
                    });
                } })] }));
};
export default WatchListCardWrapper;
