import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SYSTEM_TYPE_REMASTERED } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectPage, selectPageSize, selectSearch, selectSort, } from '../../store/reportEditor/useReportEditorTableStore';
export var reportEditorListKey = function (query) { return ['reportEditorList', query]; };
var reportEditorListFn = function (coreLink, queryKey) {
    return Api.get("".concat(coreLink, "/report-editor/list"), { query: queryKey[1] });
};
export var useReportEditorListQuery = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var search = selectSearch();
    var page = selectPage();
    var pageSize = selectPageSize();
    var sort = selectSort();
    var query = {
        pagination: {
            page: page,
            pageSize: pageSize,
        },
        sort: sort,
        search: search,
    };
    return useQuery({
        queryKey: reportEditorListKey(query),
        queryFn: function (_a) {
            var queryKey = _a.queryKey;
            return reportEditorListFn(getCoreUrl(selectedSystemType), queryKey);
        },
        select: function (resp) {
            return resp;
        },
        enabled: selectedSystemType === SYSTEM_TYPE_REMASTERED,
    });
};
