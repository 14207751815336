// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}
.tags-wrapper-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 14px;
  width: 145px;
  border: 1px solid #d9d9d9;
  color: #555;
}
.dark-layout .tags-wrapper-tag {
  color: #ccc;
  border: 1px solid #3e3f4f;
}
.tags-wrapper-tag.selected {
  color: #fff;
  background: #2962ff;
}
.tags-wrapper-tag:hover {
  background: rgba(41, 98, 255, 0.1882352941);
}
.tags-wrapper-tag:hover.selected {
  color: #fff;
  background: #2962ff;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/shared/Tags/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,WAAA;EACA,QAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,WAAA;AAEJ;AADI;EACE,WAAA;EACA,yBAAA;AAGN;AADI;EACE,WAAA;EACA,mBAAA;AAGN;AADI;EACE,2CAAA;AAGN;AAFM;EACE,WAAA;EACA,mBAAA;AAIR","sourcesContent":[".tags-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 5px;\n  &-tag {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 3px 5px;\n    border-radius: 5px;\n    font-size: 14px;\n    width: 145px;\n    border: 1px solid #d9d9d9;\n    color: #555;\n    .dark-layout & {\n      color: #ccc;\n      border: 1px solid #3e3f4f;\n    }\n    &.selected {\n      color: #fff;\n      background: #2962ff;\n    }\n    &:hover {\n      background: #2962ff30;\n      &.selected {\n        color: #fff;\n        background: #2962ff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
