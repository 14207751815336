import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React, { memo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CompletionReport from '../../../../../components/actionItem/reports/CompletionReport';
var CompletionReportModal = function (_a) {
    var visible = _a.visible, closeModal = _a.closeModal, report = _a.report, actionListNoActionsGroups = _a.actionListNoActionsGroups, actionListNoActionsTypes = _a.actionListNoActionsTypes, actionListReportsSeverity = _a.actionListReportsSeverity;
    return (_jsxs(Modal, { className: "modal-response", toggle: function () { return closeModal(); }, isOpen: visible, size: "xl", children: [_jsx(ModalHeader, { toggle: function () { return closeModal(); }, children: "Completion Report" }), _jsx(ModalBody, { children: _jsx(CompletionReport, { isDeclinedReport: false, isOpenFromComments: false, isShowCollapseBtn: true, isShowDeclineButton: false, noActionsDescription: report === null || report === void 0 ? void 0 : report.no_actions_description, verifiedTime: report === null || report === void 0 ? void 0 : report.verified_time, verifiedBy: report === null || report === void 0 ? void 0 : report.verified_by, noActionsGroupId: report === null || report === void 0 ? void 0 : report.no_actions_group_id, noActionsType: report === null || report === void 0 ? void 0 : report.no_actions_type, noActionsGroupOtherText: report === null || report === void 0 ? void 0 : report.no_actions_group_other_text, timeSubmited: report === null || report === void 0 ? void 0 : report.time_submited, submitedBy: report === null || report === void 0 ? void 0 : report.submited_by, type: (report === null || report === void 0 ? void 0 : report.no_actions_type) ? 'regular' : null, noActionReports: (report === null || report === void 0 ? void 0 : report.no_actions_type) ? [report] : [], declineReason: report === null || report === void 0 ? void 0 : report.decline_reason, isNoActionReport: !!(report === null || report === void 0 ? void 0 : report.is_no_actions), isReportExist: !!_size(report), activeReport: report, actionListNoActionsTypes: actionListNoActionsTypes, actionListNoActionsGroups: actionListNoActionsGroups, actionListReportsSeverity: actionListReportsSeverity, storage: null }) })] }));
};
export default memo(CompletionReportModal);
