import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import Loader from '../../../shared/components/Loader';
import HomePageCard from '../../../shared/components/clients/Card';
import { fetch } from '../../../shared/features/home';
import { reducer } from '../../../shared/helper/reducer';
import NodesModal from '../../../shared/modals/clients/Nodes/Nodes';
import SensorsModal from '../../../shared/modals/clients/Sensors/Sensors';
import SensorsWithoutAlarmLevelsModal from '../../../shared/modals/clients/SensorsWithoutAlarmLevels/SensorsWithoutAlarmLevels';
var initialState = {
    list: [],
    loader: true,
};
var HomePage = function () {
    useTitle('Customers');
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var list = state.list, loader = state.loader;
    var navigate = useNavigate();
    var _b = useState(false), isOpenSensorsModal = _b[0], setOpenSensorsModal = _b[1];
    var _c = useState(false), isOpenNodesModal = _c[0], setOpenNodesModal = _c[1];
    var _d = useState(false), isOpenSensorsWithoutAlarmLevelsModal = _d[0], setOpenSensorsWithoutAlarmLevelsModal = _d[1];
    var _e = useState({
        customer_id: null,
        name: null,
    }), selectedClient = _e[0], setSelectedClient = _e[1];
    var callbackTo401 = function () {
        navigate('/401');
    };
    useEffect(function () {
        fetch(state, dispatch, function () { return Endpoints.remastered.getIOauthClients(); }, function () { return callbackTo401(); });
    }, []);
    return (_jsx(React.Fragment, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, { children: (list || []).length ? (_jsxs(Row, { className: "my-2", children: [list.map(function (client) { return (_jsx(HomePageCard, { client: client, setOpenNodesModal: setOpenNodesModal, setOpenSensorsModal: setOpenSensorsModal, setSelectedClient: setSelectedClient, setOpenSensorsWithoutAlarmLevelsModal: setOpenSensorsWithoutAlarmLevelsModal }, client.customer_id)); }), _jsx(SensorsModal, { client: selectedClient, isOpen: isOpenSensorsModal, toggle: function () { return setOpenSensorsModal(!isOpenSensorsModal); } }), _jsx(SensorsWithoutAlarmLevelsModal, { client: selectedClient, isOpen: isOpenSensorsWithoutAlarmLevelsModal, toggle: function () { return setOpenSensorsWithoutAlarmLevelsModal(!isOpenSensorsWithoutAlarmLevelsModal); } }), _jsx(NodesModal, { client: selectedClient, isOpen: isOpenNodesModal, toggle: function () { return setOpenNodesModal(!isOpenNodesModal); } })] })) : (_jsx("span", { children: "Not Found" })) })) }));
};
export default HomePage;
