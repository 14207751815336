import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
export var useZoomEffect = function (state) {
    var mainSeriesLoaded = state.mainSeriesLoaded, isVisibleFullscreenChart = state.isVisibleFullscreenChart;
    var setStates = ChartsStore(function (state) { return ({
        setStates: state.setStates,
    }); }, shallow).setStates;
    useEffect(function () {
        if (!mainSeriesLoaded) {
            return;
        }
        if (isVisibleFullscreenChart) {
            setStates({
                isCustomZoom: false,
            });
        }
    }, [isVisibleFullscreenChart]);
};
