import { createStore } from '../../shared/store/createStore';
var useSelectedLeadAnalystStore = createStore(function (set) { return ({
    selectedLeadAnalyst: [],
    actions: {
        setSelectedLeadAnalyst: function (selectedLeadAnalyst) { return set({ selectedLeadAnalyst: selectedLeadAnalyst }); },
    },
}); }, 'SelectedLeadAnalyst', true);
export var useSelectedLeadAnalystActions = function () {
    return useSelectedLeadAnalystStore(function (state) { return state.actions; });
};
export var selectSelectedLeadAnalyst = function () {
    return useSelectedLeadAnalystStore(function (state) { return state.selectedLeadAnalyst; });
};
