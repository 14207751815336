import { useQuery } from '@tanstack/react-query';
import Api from '../../../../api/api';
import { DEFAULT_CHART_LIST } from '../../../../constants';
import { isValidChartSorting } from '../../../helper/chart';
export var initialState = function (processedChartSorting) {
    return {
        id: null,
        user_id: null,
        updated_at: null,
        created_at: null,
        chartSorting: processedChartSorting,
        chartDefaultZoomDays: 14,
        chartZoomDays: 14,
    };
};
var chartSettingsFn = function () { return Api.get('all-analytics/charts/get-settings'); };
export var useChartSettingsQuery = function () {
    var processedChartSorting = DEFAULT_CHART_LIST.map(function (chartIdentifier) {
        return {
            chartIdentifier: +chartIdentifier,
            isVisible: true,
        };
    });
    return useQuery({
        queryKey: ['chartSettings'],
        queryFn: chartSettingsFn,
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h;
            var settings = _a.settings;
            return {
                id: (_b = settings === null || settings === void 0 ? void 0 : settings.id) !== null && _b !== void 0 ? _b : null,
                user_id: (_c = settings === null || settings === void 0 ? void 0 : settings.user_id) !== null && _c !== void 0 ? _c : null,
                updated_at: (_d = settings === null || settings === void 0 ? void 0 : settings.updated_at) !== null && _d !== void 0 ? _d : null,
                created_at: (_e = settings === null || settings === void 0 ? void 0 : settings.created_at) !== null && _e !== void 0 ? _e : null,
                chartSorting: isValidChartSorting((_f = settings === null || settings === void 0 ? void 0 : settings.chart_sorting) !== null && _f !== void 0 ? _f : '')
                    ? JSON.parse(settings === null || settings === void 0 ? void 0 : settings.chart_sorting)
                    : processedChartSorting,
                chartDefaultZoomDays: (_g = settings === null || settings === void 0 ? void 0 : settings.chart_default_zoom_days) !== null && _g !== void 0 ? _g : initialState(processedChartSorting).chartDefaultZoomDays,
                chartZoomDays: (_h = settings === null || settings === void 0 ? void 0 : settings.chart_zoom_days) !== null && _h !== void 0 ? _h : initialState(processedChartSorting).chartZoomDays,
            };
        },
        placeholderData: {
            settings: initialState(processedChartSorting),
        },
    });
};
