import Api from '../../api';
export default {
    suggestedLevelsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/suggested-alert-levels', options);
    },
    suggestedLevelsListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/suggested-alert-levels/get-csv', options);
    },
    updateLevelBySuggestedValue: function (data, options) {
        if (data === void 0) { data = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/suggested-alert-levels/update', data, options);
    },
    deleteLevelBySuggestedValue: function (data, options) {
        if (data === void 0) { data = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/suggested-alert-levels/delete', data, options);
    },
    getSystemAlertConditionSettingHistory: function (entityId, readingTypeId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/system-alert-condition-setting/".concat(entityId, "/").concat(readingTypeId), options);
    },
    getSystemAlertConditionData: function (equipmentId, entityId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("all-analytics/suggested-alert-levels/".concat(equipmentId, "/").concat(entityId), options);
    },
    systemAlertParseWarningLevelFormula: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/system-alert-condition-setting/parse-warning-level-formula', options);
    },
    systemAlertCheckCautionValue: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/system-alert-condition-setting/check-value', options);
    },
    saveSystemAlertConditionSetting: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/system-alert-condition-setting/save', body, options);
    },
    saveSystemAlertConditionCalculationSetting: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/system-alert-condition-setting/save-calculation-settings', body, options);
    },
    getSuggestedAlertConditionData: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-conditions/get-suggested-data', options);
    },
    recalculateSuggestedLevels: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/suggested-alert-levels/recalculate', body, options);
    },
    recalculatePointSuggestedLevels: function (installationPointId, options) {
        if (installationPointId === void 0) { installationPointId = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/suggested-alert-levels/recalculate-point', { installationPointId: installationPointId }, options);
    },
    getSuggestedAlertConditionGetLastQueue: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/suggested-alert-levels/get-last-queue', options);
    },
    getSystemAlertConditionSettingLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/system-alert-condition-setting/history', options);
    },
    getSystemAlertConditionSettingItem: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/system-alert-condition-setting/get-item', options);
    },
    getAlertConditionsRecalculationLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/suggested-alert-levels/recalculation-logs', options);
    },
};
