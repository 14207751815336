import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'reactstrap';
import { InList } from '../../../widgets/InList';
import { DateTimeLabel } from '../../../widgets/common';
import { SensorId } from '../../../widgets/sensor';
import Filters from '../../components/avgReadingValues/Filter';
import { highlightRow } from '../../helper/table';
export var config = function (avgFilterFields) {
    return {
        columns: [
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'code_version',
                title: 'Node code version',
                sortable: true,
            },
            {
                name: 'max_7d',
                title: 'Max value (7d)',
                sortable: true,
            },
            {
                name: 'max_1d',
                title: 'Max value (1d)',
                sortable: true,
            },
            {
                name: 'max_7d_time',
                title: 'Max value time (7d)',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { timeBreak: true, id: "avg_max_7d_time".concat(row.installation_point_id), dateTime: row.max_7d_time }));
                },
            },
            {
                name: 'avg_1d',
                title: 'AVG  (1d)',
                sortable: true,
            },
            {
                name: 'avg_2d',
                title: 'AVG  (2d)',
                sortable: true,
            },
            {
                name: 'avg_7d',
                title: 'AVG  (7d)',
                sortable: true,
            },
            {
                name: 'State',
                title: 'State',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsx(InList, { entity: row, onClick: function () { return callbacks.aiOnBedClick(row.equipment_id, row.installation_point_id); } }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row, callbacks) {
                    return (_jsx(Button, { onClick: function (ev) {
                            callbacks.onViewClick(row.installation_point_id);
                            highlightRow(ev);
                        }, color: "primary", size: "sm", children: "View" }));
                },
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData) {
            return _jsx(Filters, { callbacks: callbacks, filtersData: filtersData, avgFilterFields: avgFilterFields });
        },
        filtersData: {
            activeReadingType: null,
            readingTypes: null,
            activeQuickFilters: {},
            timeRange: null,
        },
    };
};
