var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { set } from '../../../storage/dashboardCardSorting';
export var onToggleHandle = function (type, key, setActualLayout) {
    setActualLayout(function (prev) {
        if (type === 'hide') {
            return {
                hidden: __spreadArray(__spreadArray([], prev.hidden, true), prev.visible.filter(function (el) { return el.i === key; }), true),
                visible: prev.visible.filter(function (el) { return el.i !== key; }),
            };
        }
        if (type === 'visible') {
            return {
                hidden: prev.hidden.filter(function (el) { return el.i !== key; }),
                visible: __spreadArray(__spreadArray([], prev.visible, true), prev.hidden.filter(function (el) { return el.i === key; }), true),
            };
        }
    });
};
export var saveSortingHandle = function (storageKey, actualLayout, setIsSelected) {
    set(storageKey, actualLayout);
    setIsSelected(false);
    toast.success('Sorting saved successfully', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
};
export var resetToDefaultSortingHandle = function (key, setActualLayout, setIsSelected, setConfirmData, defaultDashboardCardData) {
    setActualLayout({
        visible: defaultDashboardCardData,
        hidden: [],
    });
    set(key, {
        visible: defaultDashboardCardData,
        hidden: [],
    });
    setIsSelected(false);
    toast.success('Sorting reset to default and saved successfully', {
        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
    });
    setConfirmData({ isVisible: false });
};
