var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { setLoader } from '../../../helper/table';
export var fetch = function (state, dispatch, endpoint) {
    var config = state.config;
    setLoader(state, dispatch, true);
    endpoint()
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false }),
                },
            });
        }
        else {
            setLoader(state, dispatch, false);
        }
    })
        .catch(function () {
        setLoader(state, dispatch, false);
    });
};
export var onRemove = function (setConfirmData, endpoint, callback) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want to delete this item?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            endpoint().then(function (resp) {
                if (resp.success) {
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    return callback();
                }
                toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            });
        },
    });
};
