var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, } from 'reactstrap';
import { READING_TYPES } from '../../../../../constants';
import { getUoms } from '../../../../helper/chart';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../../store/global/useGlobalStore';
import ModeSwitcher from './ModeSwitcher';
var SpecificValuesForm = function (_a) {
    var selectedReadingTypes = _a.selectedReadingTypes, overwrite = _a.overwrite, setOverwrite = _a.setOverwrite, data = _a.data, setData = _a.setData, onClick = _a.onClick, loader = _a.loader;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { className: "g-0", children: [selectedReadingTypes.map(function (readingType) { return (_jsxs(Col, { md: 3, children: [selectedReadingTypes.length > 1 ? (_jsx(Label, { className: "fz-18 fw-bold ms-4 text-center border-bottom w-100", children: _get(READING_TYPES, [readingType, 'axis']) || 'X-axis' })) : (_jsx(_Fragment, {})), _jsxs(FormGroup, { inline: true, className: "d-flex justify-content-around align-items-center", children: [_jsx(Label, { className: "d-inline-block", for: "caution-".concat(readingType), children: "Caution" }), _jsxs(InputGroup, { className: "w-50", children: [_jsx(Input, { type: "number", id: "caution-".concat(readingType), name: "caution-".concat(readingType), value: _get(data, [readingType, 'caution']), onChange: function (ev) {
                                                    var _a;
                                                    return setData(__assign(__assign({}, data), (_a = {}, _a[readingType] = __assign(__assign({}, (data[readingType] || {})), { caution: +ev.target.value }), _a)));
                                                } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { className: "fz-12", children: selectedReadingTypes.length > 0
                                                        ? getUoms(readingType, measure, personalSettingMeasure)
                                                        : '' }) })] })] }), _jsxs(FormGroup, { inline: true, className: "d-flex justify-content-around align-items-center", children: [_jsx(Label, { className: "d-inline-block", for: "warning-".concat(readingType), children: "Warning" }), _jsxs(InputGroup, { className: "w-50", children: [_jsx(Input, { type: "number", id: "warning-".concat(readingType), name: "warning-".concat(readingType), value: _get(data, [readingType, 'warning']), onChange: function (ev) {
                                                    var _a;
                                                    return setData(__assign(__assign({}, data), (_a = {}, _a[readingType] = __assign(__assign({}, (data[readingType] || {})), { warning: +ev.target.value }), _a)));
                                                } }), _jsx("div", { className: "input-group-append", children: _jsx(InputGroupText, { className: "fz-12", children: selectedReadingTypes.length > 0
                                                        ? getUoms(readingType, measure, personalSettingMeasure)
                                                        : '' }) })] })] })] }, "limits-settings-".concat(readingType))); }), selectedReadingTypes.length === 1 ? (_jsxs(_Fragment, { children: [_jsx(Col, { md: 3 }), _jsx(Col, { md: 3 })] })) : (_jsx(_Fragment, {})), _jsx(Col, { md: 3, className: "d-flex align-items-center mt-5", children: _jsx(ModeSwitcher, { overwrite: overwrite, setOverwrite: setOverwrite }) })] }), _jsx(ButtonGroup, { className: "float-end mt-2", children: _jsx(Button, { size: "sm", color: "primary", onClick: onClick, disabled: loader, children: "Run new alarm settings" }) })] }));
};
export default memo(SpecificValuesForm);
