import Api from '../../../api/api';
import { getHost } from '../../../shared/helper/customer';
export var uploadUrl = function (uploadImageUrl, customerId, storage) {
    return (uploadImageUrl ||
        Api.getUrl({
            url: 'all-analytics/file-manage/upload',
            query: {
                customer_id: customerId || null,
            },
            host: getHost(storage),
        }));
};
