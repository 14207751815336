import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EquipmentPhotosContent from '../../components/equipmentPhotos/EquipmentPhotosContent';
var EquipmentPhotosModal = function (_a) {
    var visible = _a.visible, onCancel = _a.onCancel;
    var _b = useState(0), saveTrigger = _b[0], setSaveTrigger = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var onClose = function () {
        setSaveTrigger(0);
        onCancel();
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xxl", toggle: onClose, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["EQUIPMENT PHOTOS", loader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsx(ModalBody, { children: _jsx(EquipmentPhotosContent, { loader: loader, setLoader: setLoader, saveTrigger: saveTrigger, onClose: onClose }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { disabled: loader, size: "sm", outline: true, color: "secondary", onClick: onClose, children: "Cancel" }), _jsx(Button, { disabled: loader, onClick: function () { return setSaveTrigger(saveTrigger + 1); }, size: "sm", outline: true, color: "primary", children: "Save" })] }) })] }));
};
export default EquipmentPhotosModal;
