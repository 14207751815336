var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment/moment';
import Endpoints from '../../../../api/endpoints/endpoints';
import { stripHtml } from '../../../helper/dom';
export var setActiveArticle = function (dispatch, id) {
    dispatch({
        type: 'setState',
        state: {
            activeArticleId: id,
        },
    });
};
export var setViewedArticle = function (state, dispatch, systemType) {
    var releases = state.releases, activeRelease = state.activeRelease, activeArticleId = state.activeArticleId, activeArticles = state.activeArticles;
    if (!activeRelease || !activeArticles.length || !activeArticleId || !releases.length) {
        return;
    }
    var newCountNotViewed = +releases.filter(function (el) { return el.date === activeRelease.date; })[0].countNotViewed;
    var newArticles = activeArticles.map(function (el) {
        if (+el.id === +activeArticleId && !+el.is_viewed) {
            newCountNotViewed--;
            return __assign(__assign({}, el), { is_viewed: 1 });
        }
        return el;
    });
    if (!newArticles.length) {
        return;
    }
    if (JSON.stringify(newArticles) === JSON.stringify(activeArticles)) {
        return;
    }
    Endpoints.remastered
        .setViewedNews(activeArticleId, {
        query: {
            systemType: systemType,
        },
    })
        .then(function (response) {
        if (response && response.success) {
            dispatch({
                type: 'setState',
                state: {
                    releases: releases.map(function (el) {
                        if (el.date === activeRelease.date) {
                            return __assign(__assign({}, el), { countNotViewed: newCountNotViewed });
                        }
                        return el;
                    }),
                    activeArticles: newArticles.map(function (el) {
                        return __assign(__assign({}, el), { title: stripHtml(el.title) });
                    }),
                },
            });
        }
    });
};
export var setRelease = function (state, dispatch, release) {
    dispatch({
        type: 'setState',
        state: {
            activeRelease: release,
        },
    });
};
export var setLoader = function (state, dispatch, type, val) {
    var _a;
    dispatch({
        type: 'setState',
        state: (_a = {},
            _a[type] = val,
            _a),
    });
};
export var fetchReleaseList = function (state, dispatch, systemType, releaseDate) {
    setLoader(state, dispatch, 'loaderRelease', true);
    Endpoints.remastered
        .getWhatsNewReleaseList({
        query: {
            systemType: systemType,
        },
    })
        .then(function (response) {
        if (response.data && response.data.length) {
            dispatch({
                type: 'setState',
                state: {
                    releases: response.data.map(function (el) {
                        return {
                            id: +el.id,
                            date: moment(el.release_date).format('MM/DD/YYYY'),
                            countNotViewed: +el.count_not_viewed,
                        };
                    }),
                    activeRelease: {
                        id: +response.data[0].id,
                        date: moment(response.data[0].release_date).format('MM/DD/YYYY'),
                        countNotViewed: +response.data[0].count_not_viewed,
                    },
                    loaderRelease: false,
                },
            });
            if (releaseDate) {
                var item = response.data.find(function (el) { return moment(el.release_date).format('MM/DD/YYYY') === releaseDate; });
                if (item) {
                    dispatch({
                        type: 'setState',
                        state: {
                            activeRelease: {
                                id: +item.id,
                                date: releaseDate || null,
                                countNotViewed: +item.count_not_viewed,
                            },
                        },
                    });
                }
            }
        }
        else {
            dispatch({
                type: 'setState',
                state: {
                    loaderRelease: false,
                },
            });
        }
    });
};
export var fetchArticles = function (state, dispatch, systemType, activeRelease) {
    if (!activeRelease.id) {
        return;
    }
    setLoader(state, dispatch, 'loaderArticle', true);
    Endpoints.remastered
        .getWhatsNewArticles({
        query: {
            systemType: systemType,
            id: activeRelease.id,
        },
    })
        .then(function (response) {
        if (response.data && response.data.length) {
            dispatch({
                type: 'setState',
                state: {
                    activeArticles: response.data.map(function (el) {
                        return __assign(__assign({}, el), { title: stripHtml(el.title) });
                    }) || [],
                    activeArticleId: response.data[0].id || null,
                    loaderArticle: false,
                },
            });
        }
        else {
            dispatch({
                type: 'setState',
                state: {
                    loaderArticle: false,
                },
            });
        }
    });
};
export var onChangeIsOpenSearchFrame = function (val, dispatch) {
    if (!val) {
        onChangeSearch('', dispatch);
    }
    dispatch({
        type: 'setState',
        state: {
            isOpenSearchFrame: val,
        },
    });
};
export var onChangeSearch = function (val, dispatch) {
    dispatch({
        type: 'setState',
        state: {
            searchInput: val,
        },
    });
};
/**
 * Highlights the search string in the text by wrapping it in a <u> tag.
 *
 * @param {string} text - The source text to search within.
 * @param {string} searchString - The string to find in the text.
 *
 * @returns {string} - The string with the highlighted search term, if found.
 */
export var highlightSearchString = function (text, searchString) {
    var regex = new RegExp("(".concat(searchString, ")"), 'gi');
    return text.replace(regex, '<u>$1</u>');
};
/**
 * Searches the text for the specified string and returns fragments containing the
 * highlighted search term along with a specified number of words to the left and right.
 *
 * @param {number} [leftWords=Infinity] - The number of words to show to the left of the found string.
 * @param {number} [rightWords=Infinity] - The number of words to show to the right of the found string.
 *
 * @returns {string} - The resulting fragments with the highlighted search term and neighboring words.
 */
export var getSearchWordsFromTextAndCloses = function (text, searchString, leftWords, rightWords) {
    if (leftWords === void 0) { leftWords = Infinity; }
    if (rightWords === void 0) { rightWords = Infinity; }
    var words = text.split(' ');
    var results = [];
    for (var i = 0; i < words.length; i++) {
        if (words[i].toLowerCase().includes(searchString.toLowerCase())) {
            var start = Math.max(i - leftWords, 0);
            var end = Math.min(i + rightWords + 1, words.length);
            var context = words.slice(start, end).join(' ');
            var highlighted = highlightSearchString(context, searchString);
            var result = "".concat(start > 0 ? '... ' : '').concat(highlighted).concat(end < words.length ? ' ...' : '');
            results.push(result);
        }
    }
    return results.join('<br/>');
};
