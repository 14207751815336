var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer } from "react";
import Endpoints from "../../../api/endpoints/endpoints";
import { useTitle } from "../../../customHooks";
import AlertLogContent from "../../../shared/components/alertLog/Content/Content";
import AlertTabsMenu from "../../../shared/components/alertLog/Tabs/Tabs";
import { columns } from "../../../shared/config/alertLog/batchAlertLevel/dataTableColumns";
import { config as baseConfig } from "../../../shared/config/alertLog/dataTableConfig";
import { fetchData } from "../../../shared/features/alertLog";
import { reducer } from "../../../shared/helper/reducer";
var initialState = {
    sensorIds: null,
    sensorIdsInput: null,
    config: __assign(__assign({}, baseConfig), { columns: __spreadArray([
            {
                name: 'customer_name',
                title: 'Customer',
                sortable: true,
            }
        ], columns, true) }),
};
var BatchAlertsPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Batch Alerts Log');
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var config = state.config, sensorIds = state.sensorIds;
    if (!config.filtersData) {
        return _jsx(_Fragment, {});
    }
    var _d = config.filtersData, user_name = _d.user_name, startDate = _d.startDate, endDate = _d.endDate;
    var callbackFetch = function () {
        return fetchData(state, dispatch, function (options) { return Endpoints.remastered.getBatchAlert(options); });
    };
    useEffect(function () {
        if (!sensorIds) {
            return;
        }
        callbackFetch();
    }, [refreshFlag, user_name, startDate, endDate, sensorIds]);
    return (_jsxs(React.Fragment, { children: [_jsx(AlertTabsMenu, { type: "batch" }), _jsx(AlertLogContent, { state: state, dispatch: dispatch, callbackFetch: callbackFetch })] }));
};
export default BatchAlertsPage;
