export var isNoActionReport = function (comment, REPORT_TYPE_NO_ACTION, REPORT_TYPE_WW_NO_ACTION) {
    return (REPORT_TYPE_NO_ACTION.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name) ||
        REPORT_TYPE_WW_NO_ACTION.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name));
};
export var getCompletionReport = function (comment, REPORT_TYPE_NO_ACTION, REPORT_TYPE_WW_NO_ACTION, REPORT_TYPE_WW_REGULAR) {
    var report;
    var isNoActionReport = REPORT_TYPE_NO_ACTION.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name) ||
        REPORT_TYPE_WW_NO_ACTION.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name), isNoActionWWReport = REPORT_TYPE_WW_NO_ACTION.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name), isRegularWWReport = REPORT_TYPE_WW_REGULAR.includes(comment === null || comment === void 0 ? void 0 : comment.related_entity_name);
    if (isNoActionReport) {
        report = isNoActionWWReport ? comment === null || comment === void 0 ? void 0 : comment.wwActionListNoActionReport : comment === null || comment === void 0 ? void 0 : comment.actionListNoActionReport;
        report.isWwReport = isNoActionWWReport;
        report.is_no_actions = 1;
        return report;
    }
    report = isRegularWWReport ? comment === null || comment === void 0 ? void 0 : comment.wwActionListReport : comment === null || comment === void 0 ? void 0 : comment.actionListReport;
    if (!report) {
        return {};
    }
    report.isWwReport = isRegularWWReport;
    return report;
};
