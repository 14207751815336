// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.equipment-info-table-tooltip-container .tooltip-inner {
  min-width: 500px;
}
.equipment-info-table-tooltip-container .equipment-info-table-tooltip thead {
  display: none;
}
.equipment-info-table-tooltip-container .equipment-info-table-tooltip tbody tr td {
  color: #fff !important;
}
.dark-layout .equipment-info-table-tooltip-container .equipment-info-table-tooltip tbody tr td {
  color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/EquipmentInfoTable.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGI;EACE,aAAA;AADN;AAII;EACE,sBAAA;AAFN;AAIM;EACE,sBAAA;AAFR","sourcesContent":[".equipment-info-table-tooltip-container {\n  .tooltip-inner {\n    min-width: 500px;\n  }\n  .equipment-info-table-tooltip {\n    thead {\n      display: none;\n    }\n\n    tbody tr td {\n      color: #fff !important;\n\n      .dark-layout & {\n        color: #000 !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
