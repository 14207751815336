var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get } from 'lodash';
import { isFftTwfChart, isFftTwfChartImpactVue } from '../../../helper/chart';
export var getChartsData = function (chartsSelectedRanges, lastTimestamps, readingIds, fftTimestampDates) {
    return Object.keys(chartsSelectedRanges).map(function (chartIdentifier) {
        var fftData = isFftTwfChart(+chartIdentifier)
            ? {
                reading_fft_id: isFftTwfChartImpactVue(chartIdentifier)
                    ? lastTimestamps.readingFFT_id
                    : _get(readingIds, +chartIdentifier, null),
                reading_fft_timestamp: isFftTwfChartImpactVue(chartIdentifier)
                    ? lastTimestamps.timestamp
                    : _get(fftTimestampDates, +chartIdentifier, null),
            }
            : {};
        return __assign(__assign({}, fftData), { reading_type_id: +chartIdentifier, chart_type: isFftTwfChart(+chartIdentifier) ? 'fft' : 'rms', ranges: chartsSelectedRanges[+chartIdentifier] });
    });
};
