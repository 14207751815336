var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext, useEffect, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { useTitle } from '../../../../customHooks';
import Tabs from '../../../../shared/components/mlStatistic/rejected/Tabs/Tabs';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/mlStatistic/rejected/aIDataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { onChangeCreatedBy, onChangeDeclinedBy, onClearCreated, onClearDeclined, } from '../../../../shared/features/mlStatistic/rejected';
import { exportToCsv, onChangeDateRangeFilter, onViewActionItem } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
var initialState = function (onViewActionItemId) {
    var config = baseConfig(onViewActionItemId);
    return {
        created_by: null,
        declined_by: null,
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'facility',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'zone',
                    title: 'Zone',
                    sortable: true,
                },
                {
                    name: 'area',
                    title: 'Area',
                    sortable: true,
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedFacility) {
    var _a, _b;
    var config = state.config, created_by = state.created_by, declined_by = state.declined_by;
    setLoader(state, dispatch, true);
    Endpoints.amazon
        .getRejectedActionList({
        query: {
            selected_facility: selectedFacility,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            filters: JSON.stringify({
                created_by: created_by,
                declined_by: declined_by,
                date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                    ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                    : '',
                date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
                    ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                    : '',
            }),
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }), filtersData: __assign(__assign({}, config.filtersData), { users: resp.users, selectedFacility: selectedFacility }) }),
                },
            });
        }
    });
};
var RejectedAiPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    useTitle('Rejected AI');
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(function (actionItemId) { return onViewActionItem(actionItemId, selectedSystemType); })), state = _d[0], dispatch = _d[1];
    var config = state.config, declined_by = state.declined_by, created_by = state.created_by;
    useResetDataTableEffect([selectedFacility, config.filtersData.dateRange], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedFacility);
    }, [refreshFlag, selectedFacility, declined_by, created_by, config.filtersData.dateRange]);
    return (_jsx(React.Fragment, { children: _jsx(Row, { className: 'my-3', children: _jsxs(Col, { children: [_jsx(Tabs, { activeTab: "ai" }), _jsx("div", { className: "table_wrap rejected-ai align-pagination-section move-pagination-top align-user-filter-section", children: _jsx(DataTable, { selectedFilters: { created_by: created_by, declined_by: declined_by }, config: config, onPageChange: function (page) {
                                return onPageChange(page, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                            }, onPageSizeChange: function (pageSize) {
                                return onPageSizeChange(pageSize, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                            }, onSort: function (column_name, ev) {
                                return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); }, ev);
                            }, callbacks: {
                                onChangeCreatedBy: function (created_by) { return onChangeCreatedBy(dispatch, created_by); },
                                onChangeDeclinedBy: function (declined_by) { return onChangeDeclinedBy(dispatch, declined_by); },
                                exportToCsv: function () {
                                    var _a, _b;
                                    return exportToCsv(Endpoints.amazon.getRejectedActionListCsv, 'rejected-ai', {
                                        query: {
                                            selected_facility: selectedFacility,
                                            sort: config.sort,
                                            search: config.search,
                                            filters: JSON.stringify({
                                                created_by: created_by,
                                                declined_by: declined_by,
                                                date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                                                    ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                                date_to: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[1])
                                                    ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                    : '',
                                            }),
                                        },
                                        isBlob: true,
                                    });
                                },
                                onClearDeclined: function () { return onClearDeclined(dispatch); },
                                onClearCreated: function () { return onClearCreated(dispatch); },
                                onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                            }, onSearch: function (search) {
                                return onSearch(search, state, dispatch, function () { return fetch(state, dispatch, selectedFacility); });
                            } }) })] }) }) }));
};
export default RejectedAiPage;
