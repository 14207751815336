var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { initialState } from '../../config/watchersStatistic/allStatistic';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getGroupedData, getStatisticByAnalysts, getStatisticByDays, getStatisticByState, } from '../../features/watchersStatistic/allStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var allAiStatisticKey = function (dateRange) {
    var _a, _b;
    return [
        'allAiStatistic',
        (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null,
        (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null,
    ];
};
var allAiStatisticFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/all-ai"), {
        query: {
            'separate-by-team': 1,
            date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'),
            date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'),
        },
    });
};
var statisticByAnalysts = initialState.statisticByAnalysts, statisticByState = initialState.statisticByState, statisticByDays = initialState.statisticByDays;
export var useAllAiStatisticQuery = function () {
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: allAiStatisticKey(dateRange),
        queryFn: function (data) { return allAiStatisticFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var data = _a.data;
            var groupedData = getGroupedData(__assign({}, data));
            return {
                data: groupedData,
                statisticByAnalysts: __assign(__assign({}, statisticByAnalysts), { series: [
                        __assign(__assign({}, statisticByAnalysts.series[0]), { data: getStatisticByAnalysts(groupedData) }),
                    ] }),
                statisticByState: __assign(__assign({}, statisticByState), { series: [
                        __assign(__assign({}, statisticByState.series[0]), { data: getStatisticByState(groupedData) }),
                    ] }),
                statisticByDays: __assign(__assign({}, statisticByDays), { series: __spreadArray([], getStatisticByDays(data), true) }),
            };
        },
    });
};
