import { createStore } from '../../shared/store/createStore';
var useSelectedLocationsStore = createStore(function (set) { return ({
    selectedLocations: [],
    actions: {
        setSelectedLocations: function (selectedLocations) { return set({ selectedLocations: selectedLocations }); },
    },
}); }, 'SelectedLocations', true);
export var useSelectedLocationsActions = function () {
    return useSelectedLocationsStore(function (state) { return state.actions; });
};
export var selectSelectedLocations = function () {
    return useSelectedLocationsStore(function (state) { return state.selectedLocations; });
};
