import { get as _get, has as _has } from 'lodash';
import { DATEFORMAT, METRIC_KEY_NAME, TYPE_CUSTOM, TYPE_METRIC, TYPE_US } from '../../constants';
import Permissions from '../../storage/permissions';
import Cookie from './cookie';
export var set = function (user, options) {
    if (options === void 0) { options = {}; }
    return Cookie.set('user', user, options);
};
export var get = function () { return Cookie.get('user' || {}); };
export var getId = function () { return Cookie.get('user' || {}).id || 0; };
export var goToProfile = function () { return window.open("".concat(APP_SSO_URL, "/profile"), '_blank'); };
export var can = function (permissionName) {
    if (permissionName === void 0) { permissionName = ''; }
    if (!permissionName) {
        return false;
    }
    var user = Cookie.get('user') || {};
    if (user.is_super_admin) {
        return true;
    }
    var permissions = Permissions.get();
    // @ts-ignore
    return permissions && permissions[permissionName] && permissions[permissionName].id;
};
export var isSuperAdmin = function () {
    var user = Cookie.get('user') || {};
    return user.is_super_admin;
};
export var getMeasure = function (readingType, measure, personalSettingMeasure) {
    if (measure === METRIC_KEY_NAME[TYPE_CUSTOM]) {
        // personal settings sample: {1: "us", 3: "metric", 4: "us", 6: "metric", 7: "us", 16: "us", 18: "us", 20: "us", 21: "us", 22: "metric", 23: "us", 27: "us", 29: "us"}
        var mapping = {
            // TWF ACC
            101: 3,
            102: 3,
            103: 3,
            // TWF VEL
            104: 4,
            105: 4,
            106: 4,
            // FFT ACC
            107: 3,
            108: 3,
            109: 3,
            // FFT VEL
            110: 4,
            111: 4,
            112: 4,
        };
        if (_has(mapping, readingType)) {
            readingType = mapping[readingType];
        }
        return personalSettingMeasure.units[readingType] === METRIC_KEY_NAME[TYPE_US] ? TYPE_US : TYPE_METRIC;
    }
    return measure === METRIC_KEY_NAME[TYPE_US] ? TYPE_US : TYPE_METRIC;
};
export var utcToUserTime = function (timestamp, timezone) {
    return timestamp + timezone * 3600 * 1000;
};
export var getUserDateFormat = function (personalSettingMeasure, explodeData) {
    var _a;
    if (explodeData === void 0) { explodeData = false; }
    var format = DATEFORMAT[((_a = personalSettingMeasure.datetime_format) !== null && _a !== void 0 ? _a : Object.keys(DATEFORMAT)[0])];
    if (explodeData) {
        var splitData = format.split(' ');
        return {
            date: _get(splitData, '0'),
            time: _get(splitData, '1'),
        };
    }
    return format;
};
export default { set: set, get: get, getId: getId, goToProfile: goToProfile, can: can, isSuperAdmin: isSuperAdmin };
