import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import React from 'react';
import { Alert } from 'reactstrap';
var ResponseBlock = function (_a) {
    var response = _a.response;
    return (_jsx(React.Fragment, { children: !_size(response.errors) ? (_jsx(SuccessBlock, { message: _get(response, 'estimateTime')
                ? _get(response, 'estimateTime.message')
                : 'Data should return later' })) : (_jsx(ErrorBlock, { message: _get(response, 'errors.message')
                ? _get(response, 'errors.message')
                : 'Unable to process your request, please contact the manager!' })) }));
};
var SuccessBlock = function (_a) {
    var message = _a.message;
    return (_jsx(Alert, { color: "success", className: "text-center", children: message }));
};
var ErrorBlock = function (_a) {
    var message = _a.message;
    return (_jsx(Alert, { color: "danger", className: "text-center", children: message }));
};
export default React.memo(ResponseBlock);
