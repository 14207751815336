var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var onChangeHighValuesFilter = function (state, dispatch, type, isEnabled, fromValue, toValue) {
    var _a;
    if (!state.config.filtersData) {
        return;
    }
    dispatch({
        type: 'setState',
        state: {
            config: __assign(__assign({}, state.config), { filtersData: __assign(__assign({}, state.config.filtersData), { highValues: __assign(__assign({}, state.config.filtersData.highValues), (_a = {}, _a[type] = {
                        isEnabled: isEnabled,
                        fromValue: fromValue,
                        toValue: toValue,
                    }, _a)) }) }),
        },
    });
};
