import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import React, { memo, useContext, useMemo, useState } from 'react';
import { BsExclamationCircleFill, BsGearFill } from 'react-icons/bs';
import { FaArrowRight, FaBatteryEmpty, FaBug, FaChartBar, FaCheck, FaComment, FaExchangeAlt, FaExclamation, FaEye, FaTimes, } from 'react-icons/fa';
import { RiSensorLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../api/endpoints/endpoints';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { isMote } from '../../../../widgets/sensor';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { handleDisableSelectCharts, handleEnableSelectCharts } from '../../../features/charts/rightBar';
import { isDistancePinSensor } from '../../../features/charts/shared';
import { getChartsData } from '../../../features/mlStatistic/saveCharts';
import { getHost } from '../../../helper/customer';
import { makeValidLink } from '../../../helper/routeHelper';
import User from '../../../helper/user';
import { useReportMlActions } from '../../../modals/ReportMl/store/useReportMlStore';
import { useMlStatisticSaveChartsDataMutation, } from '../../../services/mlStatistic/useMlStatisticSaveChartsDataMutation';
import { useAnalystNotesModalsActions } from '../../../store/analystNotes/useAnalystNotesFormModalStore';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { FftImpactVueChartsStore } from '../../../store/charts/chartsContent/FftImpactVueChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { ModalsStore } from '../../../store/charts/chartsContent/ModalsStore';
import { InListStore } from '../../../store/charts/inList/InListStore';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import '../../../styles/charts/rightBar/index.scss';
import Loader from '../../Loader';
import { getSvgSource } from '../chartsWrapper/components/shared/ChartSelect';
import RecentEvents from './RecentEvents';
var RightBar = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var inList = InListStore(function (state) { return state.inList; });
    var setIsVisible = useReportMlActions().setIsVisible;
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        startupMode: state.startupMode,
        isOnDemand: state.isOnDemand,
        sensorHistory: state.sensorHistory,
        isUniversalAdapter: state.isUniversalAdapter,
    }); }, shallow), installationPoint = _a.installationPoint, startupMode = _a.startupMode, isOnDemand = _a.isOnDemand, sensorHistory = _a.sensorHistory, isUniversalAdapter = _a.isUniversalAdapter;
    var _b = LeftEquipmentListStore(function (state) { return ({
        isLoading: state.isLoading,
        isTriggeredByScroll: state.isTriggeredByScroll,
        isAvailableEquipments: state.isAvailableEquipments,
    }); }, shallow), isLoading = _b.isLoading, isTriggeredByScroll = _b.isTriggeredByScroll, isAvailableEquipments = _b.isAvailableEquipments;
    var _c = ChartsStore(function (state) { return ({
        chartsSelectedRanges: state.chartsSelectedRanges,
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedPainting: state.chartsSelectedPainting,
        twfMlDataItemId: state.twfMlDataItemId,
        setStatesChartsStore: state.setStates,
    }); }, shallow), chartsSelectedRanges = _c.chartsSelectedRanges, selectedChartsAvailable = _c.selectedChartsAvailable, chartsSelectedPainting = _c.chartsSelectedPainting, twfMlDataItemId = _c.twfMlDataItemId, setStatesChartsStore = _c.setStatesChartsStore;
    var _d = ModalsStore(function (state) { return ({
        setModalsStoreStates: state.setStates,
        isSelectionEnabled: state.isSelectionEnabled,
        chartSelectionType: state.chartSelectionType,
    }); }, shallow), setModalsStoreStates = _d.setModalsStoreStates, isSelectionEnabled = _d.isSelectionEnabled, chartSelectionType = _d.chartSelectionType;
    var _e = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _e.data, fftTimestampDates = _e.fftTimestampDates;
    var lastTimestamps = FftImpactVueChartsStore(function (state) { return ({
        lastTimestamps: state.lastTimestamps,
    }); }, shallow).lastTimestamps;
    var sensor = _get(installationPoint, 'sensor') || {}, node = _get(installationPoint, 'sensor.node') || {}, customer = _get(installationPoint, 'equipment.locationTable.facility.customer') || {}, isSensorExists = _size(sensor) > 0;
    var disabledAiViaStartupModeIsActive = useMemo(function () { return startupMode && startupMode.active && +_get(startupMode, 'settings.create_ai') === 0; }, [startupMode]);
    var activeInstallationPointId = useMemo(function () { return installationPoint.id; }, [installationPoint]);
    var _f = useState(false), loader = _f[0], setLoader = _f[1];
    var mlStatisticSaveChartsDataMutate = useMlStatisticSaveChartsDataMutation().mutate;
    var showInListModal = function (type) {
        setModalsStoreStates({ isVisibleInListModal: true, inListModalType: type });
    };
    var showReplaceBatteryModal = function (equipmentType) {
        setModalsStoreStates({ isVisibleReplaceBatteryModal: true, equipmentTypeForReplace: equipmentType });
    };
    var showReplacePreAiBatteryModal = function (equipmentType) {
        setModalsStoreStates({ isVisiblePreAiReplaceBatteryModal: true, equipmentTypeForReplace: equipmentType });
    };
    var handleActionItem = function (actionItem) {
        if (_size(actionItem)) {
            handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setModalsStoreStates);
            window.open(makeValidLink('/' + prefix + '/action-item/' + _get(actionItem, 'id')), '_blank');
            return;
        }
        var svgSources = getSvgSource(selectedChartsAvailable);
        if (_size(svgSources)) {
            setLoader(true);
            Endpoints[selectedSystemType]
                .saveChart({
                svg_sources: svgSources,
                installation_point_id: activeInstallationPointId,
                sensor_id: _get(installationPoint, 'sensor_id'),
                ranges: chartsSelectedRanges,
            }, {
                host: getHost(_get(customer, 'storage')),
            })
                .then(function () {
                window.open(makeValidLink('/' + prefix + '/action-item/create/' + activeInstallationPointId), '_blank');
            })
                .finally(function () {
                handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setModalsStoreStates);
                setLoader(false);
            });
        }
        else {
            window.open(makeValidLink('/' + prefix + '/action-item/create/' + activeInstallationPointId), '_blank');
        }
        if (!getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates).length) {
            return;
        }
        mlStatisticSaveChartsDataMutate({
            sensor_id: installationPoint.sensor_id,
            installation_point_id: installationPoint.id,
            type: 'action_item',
            charts: getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates),
        });
    };
    var handleRequestOnDemand = function () {
        return setModalsStoreStates({ isVisibleRequestOnDemandModal: true });
    };
    var handleMlReport = function () {
        if (!_size(selectedChartsAvailable)) {
            return toast.error('Choose at least one chart', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        setIsVisible(true);
    };
    var handlePreActionItem = function (preActionItem) {
        if (_size(preActionItem)) {
            window.open(makeValidLink('/' + prefix + '/pre-action-list/' + _get(preActionItem, 'id')), '_blank');
            return;
        }
        window.open(makeValidLink('/' + prefix + '/pre-action-list/create/' + activeInstallationPointId), '_blank');
    };
    var handleViewAllActionItems = function () {
        window.open(makeValidLink('/' + prefix + "/action-list/by-installation-point/".concat(activeInstallationPointId)), '_blank');
    };
    var actionsCallback = function () {
        var callback = _get({
            ai: function () { return handleActionItem(_get(inList, 'actionList.' + activeInstallationPointId)); },
            ml: handleMlReport,
            'twf-detections': function () { return setModalsStoreStates({ isVisibleTwfDetectionsReport: true }); },
            badList: function () { return showInListModal('badList'); },
        }, chartSelectionType, function () { });
        callback();
    };
    var inBadData = _size(_get(inList, 'badList.' + activeInstallationPointId)) > 0;
    var inHardwareActionList = _size(_get(inList, 'wwActionList.' + activeInstallationPointId)) > 0;
    var inHardwarePreActionList = _size(_get(inList, 'wwPreActionList.' + activeInstallationPointId)) > 0;
    var inHardwareNodePreActionList = _size(_get(inList, 'nodesAllPreAiList.' + node.serial)) > 0;
    var replaceSensorAiShouldBeVisible = User.can('replaceAi') && inBadData && !inHardwareActionList;
    var replaceNodeBatteryAiShouldBeVisible = User.can('replaceAi') && !inHardwareActionList;
    var replaceSensorPreAiShouldBeVisible = !User.can('replaceAi') && User.can('replacePreAi') && inBadData && !inHardwarePreActionList;
    var replaceNodeBatteryPreAiShouldBeVisible = !User.can('replaceAi') &&
        User.can('replacePreAi') &&
        !inHardwarePreActionList &&
        !inHardwareNodePreActionList;
    var _g = useAnalystNotesModalsActions(), setIsVisibleModal = _g.setIsVisibleModal, setNote = _g.setNote;
    return (_jsx("div", { className: "".concat((isLoading && !isTriggeredByScroll) || !isAvailableEquipments ? 'right-bar is-disabled' : 'right-bar'), id: "right-bar", children: !isSelectionEnabled ? (_jsxs(React.Fragment, { children: [_jsxs(Button, { size: "sm", onClick: function () { return setModalsStoreStates({ isHardwareReadingsModalVisible: true }); }, children: [_jsx(RiSensorLine, {}), " ", _jsx("span", { children: "View Network Data" })] }), !isUniversalAdapter && !isDistancePinSensor(installationPoint) ? (_jsxs(Button, { size: "sm", color: "success", disabled: !isSensorExists, onClick: function () { return handleRequestOnDemand(); }, children: [isOnDemand ? _jsx(BsExclamationCircleFill, {}) : _jsx(BsGearFill, {}), " ", _jsx("span", { children: "Request On-Demand" })] })) : (_jsx(_Fragment, {})), _jsxs(Button, { size: "sm", color: "secondary", disabled: !isSensorExists, onClick: function () {
                        return _size(_get(inList, 'badList.' + activeInstallationPointId)) > 0 || isUniversalAdapter
                            ? showInListModal('badList')
                            : handleEnableSelectCharts('badList', setStatesChartsStore, setModalsStoreStates);
                    }, children: [_jsx(FaBug, {}), _jsx("span", { children: _size(_get(inList, 'badList.' + activeInstallationPointId))
                                ? 'Remove from Bad Sensor'
                                : 'Bad Sensor' })] }), _jsxs(Button, { size: "sm", color: _size(_get(inList, 'watchList.' + activeInstallationPointId)) ? 'secondary' : 'warning', onClick: function () { return showInListModal('watchList'); }, children: [_jsx(FaEye, {}), ' ', _jsxs("span", { children: [_size(_get(inList, 'watchList.' + activeInstallationPointId))
                                    ? 'Remove From Watchlist'
                                    : _size(_get(inList, 'actionList.' + activeInstallationPointId))
                                        ? 'Add to Follow Up'
                                        : 'Add to Watchlist', ' '] })] }), _size(_get(inList, 'actionList.' + activeInstallationPointId)) ? (_jsxs(Button, { size: "sm", color: "danger", onClick: function () { return handleActionItem(_get(inList, 'actionList.' + activeInstallationPointId)); }, children: [_jsx(FaCheck, {}), " ", _jsx("span", { children: " Action Item In Progress" })] })) : (_jsx(React.Fragment, { children: !disabledAiViaStartupModeIsActive && User.can('manageAi') && (_jsxs(Button, { size: "sm", color: "danger", onClick: function () {
                            return isUniversalAdapter
                                ? handleActionItem(_get(inList, 'actionList.' + activeInstallationPointId))
                                : handleEnableSelectCharts('ai', setStatesChartsStore, setModalsStoreStates);
                        }, children: [_jsx(FaCheck, {}), " ", _jsx("span", { children: " Action Items" })] })) })), _size(_get(inList, 'actionList.' + activeInstallationPointId)) === 0 && (_jsx(React.Fragment, { children: _size(_get(inList, 'preActionList.' + activeInstallationPointId)) ? (_jsxs(Button, { size: "sm", color: "danger", onClick: function () {
                            return handlePreActionItem(_get(inList, 'preActionList.' + activeInstallationPointId));
                        }, children: [_jsx(FaCheck, {}), " ", _jsx("span", { children: " Pre-Action Item In Progress" })] })) : (_jsx(React.Fragment, { children: !disabledAiViaStartupModeIsActive && User.can('createPreAi') && (_jsxs(Button, { size: "sm", color: "danger", onClick: function () {
                                return handlePreActionItem(_get(inList, 'preActionList.' + activeInstallationPointId));
                            }, children: [_jsx(FaCheck, {}), " ", _jsx("span", { children: " Pre-Action Items" })] })) })) })), !disabledAiViaStartupModeIsActive && (_jsxs(Button, { size: "sm", color: "info", onClick: handleViewAllActionItems, children: [_jsx(FaEye, {}), " ", _jsx("span", { children: "View All Action Items" })] })), replaceSensorAiShouldBeVisible && !isUniversalAdapter ? (_jsxs(Button, { size: "sm", color: "danger", disabled: !_size(sensor), onClick: function () { return setModalsStoreStates({ isVisibleReplaceSensorModal: true }); }, children: [_jsx(FaExchangeAlt, {}), _jsx("span", { children: "Replace sensor" })] })) : (_jsx(React.Fragment, {})), replaceSensorPreAiShouldBeVisible && !isUniversalAdapter ? (_jsxs(Button, { size: "sm", color: "danger", disabled: !_size(sensor), onClick: function () { return setModalsStoreStates({ isVisiblePreAiReplaceSensorModal: true }); }, children: [_jsx(FaExchangeAlt, {}), _jsx("span", { children: "Replace sensor" })] })) : (_jsx(React.Fragment, {})), replaceNodeBatteryAiShouldBeVisible && !isUniversalAdapter ? (_jsx(ReplaceNode, { installationPoint: installationPoint, inList: inList, handleCreate: showReplaceBatteryModal })) : (_jsx(React.Fragment, {})), replaceNodeBatteryPreAiShouldBeVisible && !isUniversalAdapter ? (_jsx(ReplaceNode, { installationPoint: installationPoint, inList: inList, handleCreate: showReplacePreAiBatteryModal })) : (_jsx(React.Fragment, {})), _jsxs(Button, { size: "sm", color: "success", onClick: function () {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                        setIsVisibleModal(true);
                        setNote({
                            id: null,
                            text: '',
                            title: '',
                            tags: '',
                            attachments: [],
                            customer: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer_id) && ((_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer) === null || _a === void 0 ? void 0 : _a.name)
                                ? {
                                    id: installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer_id,
                                    name: (_b = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.customer) === null || _b === void 0 ? void 0 : _b.name,
                                }
                                : null,
                            facility: selectedSystemType === SYSTEM_TYPE_REMASTERED
                                ? ((_d = (_c = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _c === void 0 ? void 0 : _c.locationTable) === null || _d === void 0 ? void 0 : _d.facility_id)
                                    ? {
                                        id: (_f = (_e = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _e === void 0 ? void 0 : _e.locationTable) === null || _f === void 0 ? void 0 : _f.facility_id,
                                        name: ((_j = (_h = (_g = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _g === void 0 ? void 0 : _g.locationTable) === null || _h === void 0 ? void 0 : _h.facility) === null || _j === void 0 ? void 0 : _j.name) || '',
                                    }
                                    : null
                                : (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.facility) && ((_k = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.facilityTable) === null || _k === void 0 ? void 0 : _k.facility_id)
                                    ? {
                                        id: installationPoint.facilityTable.facility_id,
                                        name: installationPoint.facility,
                                    }
                                    : null,
                            locations: ((_m = (_l = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _l === void 0 ? void 0 : _l.locationTable) === null || _m === void 0 ? void 0 : _m.id)
                                ? [
                                    {
                                        id: (_p = (_o = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _o === void 0 ? void 0 : _o.locationTable) === null || _p === void 0 ? void 0 : _p.id,
                                        name: (_r = (_q = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _q === void 0 ? void 0 : _q.locationTable) === null || _r === void 0 ? void 0 : _r.name,
                                    },
                                ]
                                : null,
                            zone: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.zone)
                                ? {
                                    name: installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.zone,
                                }
                                : null,
                            area: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.area)
                                ? {
                                    name: installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.area,
                                }
                                : null,
                            equipments: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment)
                                ? [
                                    {
                                        id: (_s = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _s === void 0 ? void 0 : _s.id,
                                        name: (_t = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _t === void 0 ? void 0 : _t.name,
                                    },
                                ]
                                : null,
                            components: (installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.component)
                                ? [
                                    {
                                        name: installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.component,
                                    },
                                ]
                                : null,
                            installationPointId: installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.id,
                            node_id: null,
                        });
                    }, children: [_jsx(FaComment, {}), " ", _jsx("span", { children: "Create analyst note" })] }), !isUniversalAdapter && (_jsxs(Button, { size: "sm", color: "primary", onClick: function () { return handleEnableSelectCharts('ml', setStatesChartsStore, setModalsStoreStates); }, children: [_jsx(FaBug, {}), " ", _jsx("span", { children: "Report ML" })] })), twfMlDataItemId && (_jsxs(Button, { size: "sm", color: "primary", onClick: function () {
                        return handleEnableSelectCharts('twf-detections', setStatesChartsStore, setModalsStoreStates);
                    }, children: [_jsx(FaChartBar, {}), " ", _jsx("span", { children: "TWF Detections Report" })] })), sensorHistory.length > 0 && _jsx(RecentEvents, { sensorHistory: sensorHistory })] })) : (_jsx(React.Fragment, { children: _jsx(ActionButtons, { loader: loader, hideAction: function () {
                    return handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setModalsStoreStates);
                }, callback: actionsCallback }) })) }));
};
export var ReplaceNode = function (_a) {
    var handleCreate = _a.handleCreate, inList = _a.inList, installationPoint = _a.installationPoint;
    var sensor = _get(installationPoint, 'sensor') || {}, node = _get(installationPoint, 'sensor.node') || {};
    var inNodeAIList = _size(_get(inList, 'nodesAllAiList.' + _get(node, 'serial'))), moteSensor = !_size(sensor) ? false : isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data')), isMoteAiExist = moteSensor ? _size(_get(inList, 'actionList.' + installationPoint.id)) : false;
    if (inNodeAIList || !_get(node, 'serial') || isMoteAiExist) {
        return _jsx(React.Fragment, {});
    }
    return (_jsxs(Button, { size: "sm", color: "danger", onClick: function () { return handleCreate(moteSensor ? 'mote' : 'node'); }, disabled: !_size(sensor), children: [_jsx(FaBatteryEmpty, {}), _jsxs("span", { children: ["Replace ", moteSensor ? 'Mote' : 'Node', " Battery"] })] }));
};
export var ActionButtons = function (_a) {
    var loader = _a.loader, hideAction = _a.hideAction, callback = _a.callback;
    return (_jsxs(React.Fragment, { children: [_jsx(Button, { size: "sm", color: "primary", onClick: callback, children: loader ? (_jsx(Loader, { variant: 'loader-exs' })) : (_jsxs(_Fragment, { children: [_jsx(FaArrowRight, {}), _jsx("span", { children: "Submit" })] })) }), _jsxs(Button, { size: "sm", color: "danger", onClick: hideAction, children: [_jsx(FaExclamation, {}), " ", _jsx("span", { children: "Cancel" })] })] }));
};
export default memo(RightBar);
