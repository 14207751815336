import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DateTimeLabel } from '../../../../../widgets/common';
import LinkWithPrefix from '../../../../components/LinkWithPrefix';
export var config = function (setOpenPhotosModal) {
    return {
        columns: [
            {
                name: 'serial',
                title: 'Serial',
                sortable: true,
            },
            {
                name: 'radio_address',
                title: 'Radio Address',
                sortable: true,
            },
            {
                name: 'code_version',
                title: 'Version',
                sortable: true,
            },
            {
                name: 'powered_up_at',
                title: 'Powered Up At',
                sortable: true,
            },
            {
                name: 'last_seen',
                title: 'Last Seen',
                sortable: true,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "last_seen_".concat(row.id), dateTime: row.last_seen, timeBreak: true });
                },
            },
            {
                name: 'gateway_serial',
                title: 'Gateway Serial',
                sortable: true,
            },
            {
                name: 'count_sensors',
                title: 'Count SensorsByEquipment',
                sortable: true,
            },
            {
                name: 'actions',
                title: '',
                sortable: false,
                component: function (row) {
                    return (_jsxs("div", { className: "d-flex", children: [_jsx(LinkWithPrefix, { className: "me-1", to: "/dashboard?node=".concat(row.serial), target: "_blank", children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "success", children: "View" }) }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", onClick: function () { return setOpenPhotosModal(row.node_id); }, color: "info", children: "Images" }) })] }));
                },
            },
        ],
        search: '',
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        data: [],
        loader: true,
    };
};
