import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, sortBy as _sortBy } from 'lodash';
import React, { memo } from 'react';
import { SensorId } from '../../../../widgets/sensor';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
import './index.scss';
var DEFAULT_DIRECTION = 0;
var NodeSensors = function (_a) {
    var _b = _a.sensors, sensors = _b === void 0 ? [] : _b, _c = _a.extraColumns, extraColumns = _c === void 0 ? [] : _c, localStorageKey = _a.localStorageKey;
    var _d = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _d.isContentVisible, storageStateMemory = _d.storageStateMemory, setIsContentVisible = _d.setIsContentVisible, onSaveAsDefaultSize = _d.onSaveAsDefaultSize;
    if (!sensors.length) {
        return _jsx(_Fragment, {});
    }
    sensors = _sortBy(sensors, 'position');
    return (_jsxs("div", { className: "node-sensor-block default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsx("div", { className: "default-ai-block-header-title", children: "Node Sensors" }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), isContentVisible ? (_jsx("div", { className: "default-ai-block-content", children: sensors.map(function (sensor) { return (_jsxs("div", { className: "node-sensor-block-item", children: [_jsxs("div", { children: [_jsxs("div", { children: ["Hex code:", ' ', _jsx("b", { children: _jsx(SensorId, { sensor_id: +(sensor.hardware_id || sensor.sensor_id), showDecId: false }) })] }), extraColumns.map(function (column) { return (_jsxs("div", { children: [column.name, ": ", _jsx("b", { children: _get(sensor, column.path, '---') })] }, "node_sensors_".concat(sensor.sensor_id, "_").concat(column.path))); })] }), _jsx("span", { className: "node-sensor-block-item-position", children: sensor.position })] }, "node_sensors_".concat(sensor.sensor_id))); }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(NodeSensors);
