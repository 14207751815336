// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sensorLog {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/sensorLog/sensorLog.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".page-sensorLog {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
