var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { A_KEY_CODE, C_KEY_CODE, D_KEY_CODE, H_KEY_CODE, N_KEY_CODE, R_KEY_CODE, SHIFT_KEY_CODE, SHIFT_KEY_NAME, S_KEY_CODE, } from '../../constants/hotkeys';
import { createStore } from '../createStore';
export var CHARTS_HOTKEYS_DESCRIPTION = {
    alertLevelsHotkey: 'Hide Alert levels',
    tooltipsHotkey: 'Hide Data',
    chartMarksHotkey: 'Hide Chart marks',
    resetRmsZoomHotkey: 'Reset zoom to current',
    normalFftTwfCursorHotkey: 'Normal FFT/TWF Cursor',
    harmonicFftTwfCursorHotkey: 'Harmonic FFT/TWF Cursor',
    sidebandFftTwfCursorHotkey: 'Sideband FFT/TWF Cursor',
};
var defaultHotkeys = {
    alertLevelsHotkey: {
        codes: [SHIFT_KEY_CODE, A_KEY_CODE],
        names: [SHIFT_KEY_NAME, 'a'],
    },
    tooltipsHotkey: {
        codes: [SHIFT_KEY_CODE, D_KEY_CODE],
        names: [SHIFT_KEY_NAME, 'd'],
    },
    chartMarksHotkey: {
        codes: [SHIFT_KEY_CODE, C_KEY_CODE],
        names: [SHIFT_KEY_NAME, 'c'],
    },
    resetRmsZoomHotkey: {
        codes: [R_KEY_CODE],
        names: ['r'],
    },
    normalFftTwfCursorHotkey: {
        codes: [SHIFT_KEY_CODE, N_KEY_CODE],
        names: [SHIFT_KEY_NAME, 'n'],
    },
    harmonicFftTwfCursorHotkey: {
        codes: [SHIFT_KEY_CODE, H_KEY_CODE],
        names: [SHIFT_KEY_NAME, 'h'],
    },
    sidebandFftTwfCursorHotkey: {
        codes: [SHIFT_KEY_CODE, S_KEY_CODE],
        names: [SHIFT_KEY_NAME, 's'],
    },
};
var useChartsHotkeysStore = createStore(function (set) { return (__assign(__assign({}, defaultHotkeys), { actions: {
        setAlertLevelsHotkey: function (alertLevelsHotkey) { return set({ alertLevelsHotkey: alertLevelsHotkey }); },
        setTooltipsHotkey: function (tooltipsHotkey) { return set({ tooltipsHotkey: tooltipsHotkey }); },
        setChartMarksHotkey: function (chartMarksHotkey) { return set({ chartMarksHotkey: chartMarksHotkey }); },
        setResetRmsZoomHotkey: function (resetRmsZoomHotkey) { return set({ resetRmsZoomHotkey: resetRmsZoomHotkey }); },
        setNormalFftTwfCursorHotkey: function (normalFftTwfCursorHotkey) { return set({ normalFftTwfCursorHotkey: normalFftTwfCursorHotkey }); },
        setHarmonicFftTwfCursorHotkey: function (harmonicFftTwfCursorHotkey) { return set({ harmonicFftTwfCursorHotkey: harmonicFftTwfCursorHotkey }); },
        setSidebandFftTwfCursorHotkey: function (sidebandFftTwfCursorHotkey) { return set({ sidebandFftTwfCursorHotkey: sidebandFftTwfCursorHotkey }); },
        resetHotkeysToDefault: function () { return set(defaultHotkeys); },
    } })); }, 'ChartsHotkeysStore', true, 0.2);
export var useChartsHotkeysActions = function () {
    return useChartsHotkeysStore(function (state) { return state.actions; });
};
export var selectAlertLevelsHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.alertLevelsHotkey; });
};
export var selectTooltipsHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.tooltipsHotkey; });
};
export var selectChartMarksHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.chartMarksHotkey; });
};
export var selectResetRmsZoomHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.resetRmsZoomHotkey; });
};
export var selectNormalFftTwfCursorHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.normalFftTwfCursorHotkey; });
};
export var selectHarmonicFftTwfCursorHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.harmonicFftTwfCursorHotkey; });
};
export var selectSidebandFftTwfCursorHotkey = function () {
    return useChartsHotkeysStore(function (state) { return state.sidebandFftTwfCursorHotkey; });
};
