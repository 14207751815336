var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/oldPointsVerification/dataTableConfig';
import { fetch, onScroll } from '../../../features/dashboard/oldPointsVerification';
import { reducer } from '../../../helper/reducer';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState);
    return {
        offset: 0,
        limit: 10,
        scrollLoader: true,
        fetchingInProcess: false,
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
var OldPointsVerification = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, tableName = _a.tableName, _c = _a.tableColumns, tableColumns = _c === void 0 ? [] : _c, endpoint = _a.endpoint;
    var _d = useReducer(reducer, initialState(tableName, tableColumns)), state = _d[0], dispatch = _d[1];
    var config = state.config, limit = state.limit, offset = state.offset, scrollLoader = state.scrollLoader;
    var _e = useState(true), withData = _e[0], setWithData = _e[1];
    useEffect(function () {
        fetch(state, dispatch, withData, function () { return endpoint(withData, limit, offset); });
    }, __spreadArray([withData], deps, true));
    useEffect(function () {
        if (config.loader) {
            return;
        }
        fetch(state, dispatch, withData, function () { return endpoint(withData, limit, offset); }, true);
    }, [offset]);
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", style: { padding: 10 }, children: [_jsx("div", { className: "float-start", children: "Old Points Verification" }), _jsx("div", { children: _jsxs(Label, { check: true, className: "float-end", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setWithData(!withData); } }), _jsx("span", { children: "Hide no data" })] }) })] }), _jsx(CardBody, { style: { padding: 0 }, children: _jsxs(Scrollbars, { onUpdate: function (values) {
                        return onScroll(state, dispatch, values);
                    }, style: { height: '100%' }, children: [_jsx(DataTable, { config: config, tableName: tableName, dispatchTable: dispatch }), !config.loader && scrollLoader ? (_jsx("div", { className: "d-flex justify-content-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(_Fragment, {}))] }) })] }));
};
export default OldPointsVerification;
