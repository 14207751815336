// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-exs > img {
  width: 20px;
}
.loader-xs > img {
  width: 40px;
}
.loader-sm > img {
  width: 100px;
}
.loader-md > img {
  width: 140px;
}
.loader-lg > img {
  width: 180px;
}
.loader-xl > img {
  width: 220px;
}
.loader-2xl > img {
  width: 260px;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/Loader/style.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;AADN;AAKI;EACE,WAAA;AAHN;AAOI;EACE,YAAA;AALN;AASI;EACE,YAAA;AAPN;AAWI;EACE,YAAA;AATN;AAaI;EACE,YAAA;AAXN;AAeI;EACE,YAAA;AAbN","sourcesContent":[".loader {\n  &-exs {\n    & > img {\n      width: 20px;\n    }\n  }\n  &-xs {\n    & > img {\n      width: 40px;\n    }\n  }\n  &-sm {\n    & > img {\n      width: 100px;\n    }\n  }\n  &-md {\n    & > img {\n      width: 140px;\n    }\n  }\n  &-lg {\n    & > img {\n      width: 180px;\n    }\n  }\n  &-xl {\n    & > img {\n      width: 220px;\n    }\n  }\n  &-2xl {\n    & > img {\n      width: 260px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
