import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import UserActivity from '../../../../shared/components/dashboard/cards/UserActivity';
import { onToggleHandle } from '../../../../shared/features/dashboard';
var UserActivityCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    return (_jsxs(_Fragment, { children: [isSelected ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'UserActivity', setActualLayout); } })] })) : (_jsx(_Fragment, {})), _jsx(UserActivity, { deps: [selectedFacility, refreshFlag], endpoint: Endpoints.amazon.getUserActivity })] }));
};
export default UserActivityCardWrapper;
