var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { head as _head } from 'lodash';
import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { useLocationsAssetsTreeQuery, } from '../../../services/useLocationsAssetsTreeQuery';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations, useSelectedLocationsActions } from '../../../store/SelectedLocationsStore';
var LocationsSelect = function () {
    var _a;
    var trackEvent = useMatomo().trackEvent;
    var _b = useLocationsAssetsTreeQuery(), locationsAssetsTreeData = _b.data, locationsAssetsTreeIsLoading = _b.isLoading, locationsAssetsTreeIsFetching = _b.isFetching;
    var list = locationsAssetsTreeData.list;
    var setSelectedLocations = useSelectedLocationsActions().setSelectedLocations;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var onChangeHandler = function (item) {
        setSelectedLocations([item.value]);
        trackEvent({ category: 'Base', action: 'Change location', name: item.label, href: window.location.href });
    };
    var options = useMemo(function () { return __spreadArray([
        { id: 0, label: 'All Assets', value: 0 }
    ], list.map(function (asset) { return ({ label: asset.label, id: asset.value, value: asset.value }); }), true); }, [list]);
    return (_jsx("div", { className: "".concat(selectedCustomers.length && location.pathname !== '/customers' ? '' : 'hidden'), children: _jsx(Select, { isLoading: locationsAssetsTreeIsLoading || locationsAssetsTreeIsFetching, name: "locationSelector", classNamePrefix: "customSelectStyleDefault", placeholder: 'Asset tree', onChange: onChangeHandler, value: (_a = options.find(function (_a) {
                var value = _a.value;
                return value === _head(selectedLocations);
            })) !== null && _a !== void 0 ? _a : null, options: options }) }));
};
export default memo(LocationsSelect);
