import Api from '../../api';
export default {
    getDashboardCountAiStats: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/count-ai', options);
    },
    getStatisticCountAi: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/count-ai', options);
    },
    getStatisticCostAvoided: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/cost-avoided', options);
    },
    getStatisticPreAi: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/pre-ai', options);
    },
    getStatisticCostAvoidedReport: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/cost-avoided-report', options);
    },
    getAllAiStatistic: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/all-ai', options);
    },
    getStatisticCountAlarmLevel: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/count-alarm-level', options);
    },
    getStatisticCountAlarmLevelForUser: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/statistic/count-alarm-level-for-user', options);
    },
};
