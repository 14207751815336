var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getFormattedDateFromOrDefault, getFormattedDateToOrDefault } from '../../features/watchersStatistic';
import { getCoreUrl } from '../../helper/commonHelper';
import { selectDateRange } from '../../store/watchersStatistic/useDateRangeStore';
export var statisticKPIKey = function (dateRange, username, userId, queryAdditionProperties) { var _a, _b; return ['statisticKPI', (_a = dateRange[0]) !== null && _a !== void 0 ? _a : null, (_b = dateRange[1]) !== null && _b !== void 0 ? _b : null, username, userId, queryAdditionProperties]; };
var statisticKPIFn = function (_a, coreLink) {
    var queryKey = _a.queryKey;
    return Api.get("".concat(coreLink, "/statistic/analysts-kpi"), {
        query: __assign({ date_from: getFormattedDateFromOrDefault(queryKey[1], 'YYYY-MM-DD 00:00:00'), date_to: getFormattedDateToOrDefault(queryKey[2], 'YYYY-MM-DD 00:00:00'), selected_user: {
                user_name: queryKey[3],
                id: queryKey[4],
            } }, queryKey[5]),
    });
};
export var useStatisticKPIQuery = function (_a) {
    var username = _a.username, userId = _a.userId, queryAdditionProperties = _a.queryAdditionProperties;
    var dateRange = selectDateRange();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: statisticKPIKey(dateRange, username, userId, queryAdditionProperties),
        queryFn: function (data) { return statisticKPIFn(data, getCoreUrl(selectedSystemType)); },
        select: function (_a) {
            var data = _a.data;
            return __assign(__assign({}, data), { sumCompletionReportsAccepted: Object.values(data.completion_reports_accepted).reduce(function (acc, val) { return acc + val; }, 0), sumPreAisDeclined: __spreadArray(__spreadArray([], Object.values(data.pre_ais_declined.alarm_levels_adjusted_reasons), true), Object.values(data.pre_ais_declined.default_reasons), true).reduce(function (acc, val) { return acc + val; }, 0), sumAlarmLevelsAdjustedReasons: Object.values(data.pre_ais_declined.alarm_levels_adjusted_reasons).reduce(function (acc, val) { return acc + val; }, 0) });
        },
    });
};
