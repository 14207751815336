import Api from '../../api';
export default {
    getSensorLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/sensor-log', options);
    },
    exportSensorLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/sensor-log/export', options);
    },
    getAmbientAlertLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-log/ambient-alerts', options);
    },
    getAlertLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-log/alerts', options);
    },
    getAlertLogBySensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-log/sensor-alerts', options);
    },
    getBatchAlert: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/alert-log/get-table-batch', options);
    },
};
