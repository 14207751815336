import { jsx as _jsx } from "react/jsx-runtime";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "./css/app.css";
import "./css/darkmode.scss";
import MainLayout from "./layout/main";
import "./scss/app.scss";
var uri = window.location.pathname + window.location.search + window.location.hash;
var currentDomain = window.location.href;
if (APP_OLD_HOST && currentDomain.includes(APP_OLD_HOST) && APP_NEW_HOST) {
    window.location.assign(APP_NEW_HOST + uri);
}
if (window.location.host === 'analytics.waites.net') {
    window.location.assign(APP_NEW_HOST + '/amazon' + uri);
}
var container = document.getElementById('app');
if (container) {
    var root = ReactDOM.createRoot(container);
    root.render((_jsx(MainLayout, {})));
}
