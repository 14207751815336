import { useQuery } from '@tanstack/react-query';
import Api from '../../api/api';
export var facilitiesKey = function () { return ['facilities']; };
var facilitiesFn = function () {
    return Api.get('amazon-analytic/facility/list');
};
export var useFacilitiesQuery = function () {
    return useQuery({
        queryKey: facilitiesKey(),
        queryFn: facilitiesFn,
        select: function (_a) {
            var list = _a.list;
            return {
                facilities: list !== null && list !== void 0 ? list : [],
            };
        },
        placeholderData: {
            list: [],
        },
    });
};
