// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-background {
  background-color: #ffff00;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/shared/HighlighterWord/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":[".highlight-background {\n  background-color: #ffff00;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
