import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Button } from 'reactstrap';
var FooterToolsSimpleBtn = function (_a) {
    var className = _a.className, onClick = _a.onClick, isDisabled = _a.isDisabled, children = _a.children;
    return (_jsx(Button, { className: "footer-tools-simpleBtn ".concat(className), color: "primary", outline: true, disabled: isDisabled, onClick: function () {
            onClick();
        }, children: children }));
};
export default memo(FooterToolsSimpleBtn);
