import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaRegNoteSticky } from 'react-icons/fa6';
import { Button } from 'reactstrap';
import { useActionItemModalsActions } from '../../../../store/AIPreAi/useActionItemModalsStore';
import DefaultTooltip from '../../../DefaultTooltip';
var MachineInformationBtn = function () {
    var setIsVisibleMachineInformationModal = useActionItemModalsActions().setIsVisibleMachineInformationModal;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { size: "sm", outline: true, color: "primary", "data-tooltip-id": "analyst-notes-btn", className: "top-actions-fill", onClick: function () { return setIsVisibleMachineInformationModal(true); }, children: _jsx(FaRegNoteSticky, {}) }), _jsx(DefaultTooltip, { place: "bottom", openEvents: { mouseenter: true }, id: "analyst-notes-btn", children: "Machine Information" })] }));
};
export default memo(MachineInformationBtn);
