// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badData-filter-select {
  padding-block-start: 10px;
  min-width: 230px;
  max-width: 25%;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/badData/Filter/Filter.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,gBAAA;EACA,cAAA;AAAJ","sourcesContent":[".badData-filter {\n  &-select {\n    padding-block-start: 10px;\n    min-width: 230px;\n    max-width: 25%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
