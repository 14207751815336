export var calculateSize = function (chartView) {
    var chartHeight = chartView === 'list' ? 425 : 275;
    return {
        height: chartHeight,
        width: null,
    };
};
export var calculateWidth = function (chartView) {
    var _a;
    var chartsWrapperWidth = (_a = document === null || document === void 0 ? void 0 : document.getElementById('charts-wrapper')) === null || _a === void 0 ? void 0 : _a.offsetWidth;
    return chartView === 'list' ? chartsWrapperWidth : chartsWrapperWidth / 2 - 10;
};
