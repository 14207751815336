import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { ACTION_LIST } from '../../../constants';
import SlaSettingsTable from '../../../shared/components/slaSettings/SlaSettingsTable';
import User from '../../../shared/helper/user';
var SlaSettings = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    if (!User.isSuperAdmin() && !User.can('manageWatchList')) {
        return _jsxs("p", { className: "mt-2 text-center fz-18", children: ["You ", "haven't", " access to this page"] });
    }
    return _jsx(SlaSettingsTable, { actionListStatusNames: ACTION_LIST.STATUS_NAMES, refreshFlag: refreshFlag });
};
export default memo(SlaSettings);
