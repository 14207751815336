import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import FilterSensorSearch from "../FilterSensorSearch";
export var Filters = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, exportToCsv = _a.exportToCsv;
    var sensorIds = state.sensorIds;
    return (_jsx(Row, { className: "mb-2", children: _jsxs(Col, { children: [_jsx(FilterSensorSearch, { state: state, dispatch: dispatch }), sensorIds ? (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () { return exportToCsv(); }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }) }));
};
export default Filters;
