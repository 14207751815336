import { useQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
import { transformDefaultReasonsReplacement } from '../../../shared/features/preActionItem';
export var getDefaultDeclineReasonsKey = function (preActionItemId) { return [
    'getDefaultDeclineReasons',
    +preActionItemId,
]; };
var getDefaultDeclineReasonsFn = function () {
    return Api.get('amazon-analytic/pre-action-list/get-default-decline-reasons', {
        query: {},
    });
};
export var useGetDefaultDeclineReasonsQuery = function (_a) {
    var preActionItemId = _a.preActionItemId, componentName = _a.componentName;
    return useQuery({
        queryKey: getDefaultDeclineReasonsKey(preActionItemId),
        queryFn: getDefaultDeclineReasonsFn,
        select: function (_a) {
            var list = _a.list;
            return {
                defaultDeclineReasons: transformDefaultReasonsReplacement(list, componentName),
            };
        },
        enabled: !!componentName,
    });
};
