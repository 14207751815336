import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { first as _first, isArray as _isArray, size as _size } from 'lodash';
import React, { memo, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ACTION_LIST } from '../../../../constants';
import TranslatesToggleBtn from '../../../components/AIPreAi/TranslatesToggleBtn';
import { getTranslates } from '../../../components/AIPreAi/TranslatesToggleBtn/features';
import { selectIdPreviousAiReportForModal, useActionItemModalsActions, } from '../../../store/AIPreAi/useActionItemModalsStore';
import './index.scss';
var PreviousAiReportModal = function (_a) {
    var onCancel = _a.onCancel, existingActionItems = _a.existingActionItems;
    var _b = useState(false), isShowTranslatesCause = _b[0], setIsShowTranslatesCause = _b[1];
    var _c = useState(false), isShowTranslatesNoActionsDescription = _c[0], setIsShowTranslatesNoActionsDescription = _c[1];
    var _d = useState(false), isShowTranslatesText = _d[0], setIsShowTranslatesText = _d[1];
    var setIdPreviousAiReportForModal = useActionItemModalsActions().setIdPreviousAiReportForModal;
    var idPreviousAiReportForModal = selectIdPreviousAiReportForModal();
    var actionItem = existingActionItems.find(function (el) { return el.id === idPreviousAiReportForModal; });
    if (!existingActionItems.length || !actionItem) {
        return _jsx(_Fragment, {});
    }
    var report = _first(actionItem.reports);
    if (actionItem.type === ACTION_LIST.AI_TYPE_WW) {
        report = _first(actionItem.wwReports);
        if (_size(report)) {
            report.isWwReport = true;
        }
    }
    if (_size(report) && report.file && !_isArray(report.file)) {
        report.file = JSON.parse(report.file);
    }
    if (actionItem.is_no_actions !== 1 && !_size(report)) {
        toast.error('There is no report', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        setIdPreviousAiReportForModal(null);
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Modal, { size: "lg", className: "modal-report modal-response", toggle: onCancel, isOpen: !!idPreviousAiReportForModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: 'Report' }), _jsx(ModalBody, { children: actionItem.is_no_actions === 1 ? (_jsxs("div", { className: "position-relative", children: [_jsx("h6", { children: _jsx("b", { children: "Description/ Reason Provided:" }) }), _jsx("div", { dangerouslySetInnerHTML: {
                                __html: isShowTranslatesCause
                                    ? getTranslates(actionItem.translates || {}, 'no_actions_description')
                                    : actionItem.no_actions_description,
                            } }), _jsx(TranslatesToggleBtn, { fieldNameId: 'modal_report_no_actions_description' + actionItem.id, fieldName: 'no_actions_description', translates: actionItem.translates || {}, buttonStyle: { top: '0', right: '0' }, isShowTranslates: isShowTranslatesNoActionsDescription, setIsShowTranslates: setIsShowTranslatesNoActionsDescription })] })) : (_jsxs(_Fragment, { children: [!report.isWwReport ? (_jsxs(Row, { className: "position-relative", children: [_jsx(Col, { md: 6, children: "Completion Report cause:" }), _jsxs(Col, { md: 6, children: [_jsx("div", { dangerouslySetInnerHTML: {
                                                __html: isShowTranslatesCause
                                                    ? getTranslates(actionItem.translates || {}, 'cause')
                                                    : report.cause,
                                            } }), _jsx(TranslatesToggleBtn, { fieldNameId: 'modal_report_cause' + actionItem.id, fieldName: 'cause', translates: actionItem.translates || {}, buttonStyle: { top: '0', right: '0' }, isShowTranslates: isShowTranslatesCause, setIsShowTranslates: setIsShowTranslatesCause })] })] })) : (_jsx(_Fragment, {})), _jsxs(Row, { className: "position-relative", children: [_jsx(Col, { md: 6, children: "Completion Report details:" }), _jsxs(Col, { md: 6, children: [_jsx("div", { dangerouslySetInnerHTML: {
                                                __html: isShowTranslatesText
                                                    ? getTranslates(actionItem.translates || {}, 'text')
                                                    : report.text,
                                            } }), _jsx(TranslatesToggleBtn, { fieldNameId: 'modal_report_text' + actionItem.id, fieldName: 'text', translates: actionItem.translates || {}, buttonStyle: { top: '0', right: '0' }, isShowTranslates: isShowTranslatesText, setIsShowTranslates: setIsShowTranslatesText })] })] })] })) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Close" }) }) })] }));
};
export default memo(PreviousAiReportModal);
