import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment/moment';
import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Endpoints from '../../../api/endpoints/endpoints';
import { useTitle } from '../../../customHooks';
import Filters from '../../../shared/components/sensorLog/Filter';
import DataTable from '../../../shared/components/shared/Table/Table';
import { sensorLogInitialState } from '../../../shared/config/sensorLog/initialStateConfig';
import { fetch } from '../../../shared/features/sensorLog';
import { exportToCsv, onChangeDateRangeFilter } from '../../../shared/features/shared';
import { reducer } from '../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../../shared/helper/table';
import User from '../../../shared/helper/user';
import '../../../shared/styles/sensorLog/sensorLog.scss';
var SensorLogPage = function (_a) {
    var _b;
    var _c = _a.refreshFlag, refreshFlag = _c === void 0 ? null : _c;
    useTitle('Sensor Log');
    if (!User.can('viewSensorLog')) {
        var navigate = useNavigate();
        navigate('/401');
    }
    var _d = useReducer(reducer, sensorLogInitialState), state = _d[0], dispatch = _d[1];
    var config = state.config, sensorIds = state.sensorIds;
    var callbackFetch = function () { return fetch(state, dispatch, function (data) { return Endpoints.amazon.getSensorLog(data); }); };
    useEffect(function () {
        if (!sensorIds) {
            return;
        }
        callbackFetch();
    }, [refreshFlag, sensorIds, (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange]);
    return (_jsxs("div", { className: "page-sensorLog", children: [_jsx("h3", { className: "page-title", children: "Sensor Log" }), _jsx(Filters, { state: state, dispatch: dispatch, exportToCsv: function () {
                    var _a, _b, _c, _d;
                    return exportToCsv(Endpoints.amazon.exportSensorLog, 'sensor-log', {
                        query: {
                            sensorIds: sensorIds,
                            sort: config.sort,
                            search: config.search,
                            date_from: ((_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.dateRange[0])
                                ? moment((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                : '',
                            date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                                ? moment((_d = config === null || config === void 0 ? void 0 : config.filtersData) === null || _d === void 0 ? void 0 : _d.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                : '',
                        },
                        isBlob: true,
                    });
                } }), sensorIds ? (_jsx(React.Fragment, { children: _jsx(DataTable, { config: config, onPageChange: function (page) { return onPageChange(page, state, dispatch, function () { return callbackFetch(); }); }, callbacks: {
                        onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                    }, onPageSizeChange: function (pageSize) {
                        return onPageSizeChange(pageSize, state, dispatch, function () { return callbackFetch(); });
                    }, onSort: function (column_name, ev) { return onSort(column_name, state, dispatch, function () { return callbackFetch(); }, ev); }, onSearch: function (search) { return onSearch(search, state, dispatch, function () { return callbackFetch(); }); } }) })) : (_jsx(_Fragment, {}))] }));
};
export default SensorLogPage;
