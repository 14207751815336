import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from '../../components/shared/Table/Table';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { useExistAiPreAiBedQuery } from '../../services/useExistAiPreAiOnBedQuery';
import { initialState } from './config/defaultTableConfig';
import { selectIsVisibleModal, useExistingAiPreAiActions } from './store/useExistingAiPreAiOnBedStore';
var ExistingAiPreAiOnBedModal = function (_a) {
    var _b;
    var _c = _a.isPreAi, isPreAi = _c === void 0 ? false : _c;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setIsVisible = useExistingAiPreAiActions().setIsVisible;
    var isVisible = selectIsVisibleModal();
    var existAiPreAiBedData = useExistAiPreAiBedQuery({ isPreAi: isPreAi }).data;
    if (!isVisible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Modal, { size: "xl", toggle: function () { return setIsVisible(false); }, isOpen: isVisible, children: [_jsxs(ModalHeader, { toggle: function () { return setIsVisible(false); }, children: [isPreAi ? 'Pre-Action items' : 'Action items', " on Bed"] }), _jsx(ModalBody, { children: _jsx(DataTable, { config: (_b = existAiPreAiBedData === null || existAiPreAiBedData === void 0 ? void 0 : existAiPreAiBedData.config) !== null && _b !== void 0 ? _b : initialState(selectedSystemType, isPreAi).config }) }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: function () { return setIsVisible(false); }, children: "Close" }) }) })] }));
};
export default memo(ExistingAiPreAiOnBedModal);
