var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment';
import React, { memo, useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { useTitle } from '../../../../customHooks';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/mlStatistic/missed/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { deleteMissedAiItem, onChangeUrgencyFilter } from '../../../../shared/features/mlStatistic/missed';
import { exportToCsv, onChangeDateRangeFilter, onViewImages, onViewInstallationPoint, } from '../../../../shared/features/shared';
import { reducer } from '../../../../shared/helper/reducer';
import { onPageChange, onPageSizeChange, onSearch, onSort, setLoader } from '../../../../shared/helper/table';
import { useResetDataTableEffect } from '../../../../shared/hooks/useResetDataTableEffect';
import ReportMl from '../../../../shared/modals/ReportMl';
import { useReportMlActions } from '../../../../shared/modals/ReportMl/store/useReportMlStore';
import ImagesModal from '../../../../shared/modals/imagesModal/ImagesModal';
import { useConfirmModalActions } from '../../../../shared/store/global/useConfirmModalStore';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
var initialState = function (onViewInstallationPoint) {
    var config = baseConfig(onViewInstallationPoint);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'customer_name',
                    title: 'Customer',
                    sortable: true,
                },
                {
                    name: 'facility',
                    title: 'Facility',
                    sortable: true,
                },
                {
                    name: 'location_name',
                    title: 'Location',
                    sortable: true,
                    component: function (row) {
                        return _get(row, 'location_info') || _get(row, 'location_name') || '---';
                    },
                },
                {
                    name: 'equipment_name',
                    title: 'Equipment',
                    sortable: true,
                    component: function (row) {
                        return row.equipment_info || row.equipment_name || '---';
                    },
                }
            ], config.columns, true) }),
    };
};
var fetch = function (state, dispatch, selectedCustomers) {
    var _a, _b, _c;
    var config = state.config;
    setLoader(state, dispatch, true);
    Endpoints.remastered
        .getMissedActionList({
        query: {
            selected_customer_ids: selectedCustomers,
            pagination: config.pagination,
            sort: config.sort,
            search: config.search,
            urgency: (_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.urgency,
            date_from: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0])
                ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                : '',
            date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                : '',
        },
    })
        .then(function (resp) {
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    config: __assign(__assign({}, config), { data: resp.data, loader: false, pagination: __assign(__assign({}, config.pagination), { total: resp.total }) }),
                },
            });
        }
    });
};
var MissedAiPage = function (_a) {
    var _b, _c;
    var _d = _a.refreshFlag, refreshFlag = _d === void 0 ? null : _d;
    useTitle('Missed AI');
    var selectedCustomers = selectSelectedCustomers();
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _e = useReducer(reducer, initialState(function (installationPointId) { return onViewInstallationPoint(installationPointId, selectedSystemType); })), state = _e[0], dispatch = _e[1];
    var _f = useState({
        visible: false,
        images: [],
    }), imagesModalData = _f[0], setImagesModalData = _f[1];
    var _g = useState(void 0), mlMissedAi = _g[0], setMlMissedAi = _g[1];
    var config = state.config;
    useResetDataTableEffect([selectedCustomers, config.filtersData.dateRange, (_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.urgency], state, dispatch);
    useEffect(function () {
        fetch(state, dispatch, selectedCustomers);
    }, [refreshFlag, selectedCustomers, config.filtersData.dateRange, (_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.urgency]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var setIsVisible = useReportMlActions().setIsVisible;
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx(Row, { className: "mb-2", children: _jsxs(Col, { className: "d-flex justify-content-between align-items-center", children: [_jsx("h3", { className: "page-title", children: "Missed AI" }), config.data.length > 0 ? (_jsx(Button, { color: "success", style: { height: '28px' }, size: "sm", onClick: function () {
                                            var _a, _b, _c;
                                            return exportToCsv(Endpoints.remastered.getMissedActionListCsv, 'missed-ai', {
                                                query: {
                                                    selected_customer_ids: selectedCustomers,
                                                    sort: config.sort,
                                                    search: config.search,
                                                    urgency: (_a = config === null || config === void 0 ? void 0 : config.filtersData) === null || _a === void 0 ? void 0 : _a.urgency,
                                                    date_from: ((_b = config === null || config === void 0 ? void 0 : config.filtersData) === null || _b === void 0 ? void 0 : _b.dateRange[0])
                                                        ? moment(config.filtersData.dateRange[0]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                    date_to: ((_c = config === null || config === void 0 ? void 0 : config.filtersData) === null || _c === void 0 ? void 0 : _c.dateRange[1])
                                                        ? moment(config.filtersData.dateRange[1]).format('YYYY-MM-DD 00:00:00')
                                                        : '',
                                                },
                                                isBlob: true,
                                            });
                                        }, children: "Export to CSV" })) : (_jsx(_Fragment, {}))] }) }), _jsx("div", { className: "table_wrap rejected-ai", children: _jsx(DataTable, { selectedFilters: { created_by: state.created_by, declined_by: state.declined_by }, config: config, onPageChange: function (page) {
                                    return onPageChange(page, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers); });
                                }, onPageSizeChange: function (pageSize) {
                                    return onPageSizeChange(pageSize, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers); });
                                }, onSort: function (column_name, ev) {
                                    return onSort(column_name, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers); }, ev);
                                }, callbacks: {
                                    onViewImages: function (images) { return onViewImages(setImagesModalData, images); },
                                    deleteMissedAiItem: function (id) {
                                        return deleteMissedAiItem(Endpoints.remastered.removeMissedActionList, id, setConfirmData, function () { return fetch(state, dispatch, selectedCustomers); }, function () { return setLoader(state, dispatch, true); });
                                    },
                                    setMlMissedAi: function (row) {
                                        setIsVisible(true);
                                        setMlMissedAi({
                                            id: row.id,
                                            comment: row.comment,
                                            urgency: row.urgency,
                                        });
                                    },
                                    onChangeDateRange: function (range) { return onChangeDateRangeFilter(state, dispatch, range); },
                                    onChangeUrgencyFilter: function (urgency) { return onChangeUrgencyFilter(state, dispatch, urgency); },
                                }, onSearch: function (search) {
                                    return onSearch(search, state, dispatch, function () { return fetch(state, dispatch, selectedCustomers); });
                                } }) })] }) }), _jsx(ImagesModal, { images: imagesModalData.images.filter(function (image) { return image; }), onCancel: function () { return setImagesModalData({ visible: false, images: [] }); }, visible: imagesModalData.visible }), _jsx(ReportMl, { mlMissedAi: mlMissedAi, callback: function () {
                    fetch(state, dispatch, selectedCustomers);
                    setMlMissedAi(void 0);
                } })] }));
};
export default memo(MissedAiPage);
