import { createStore } from '../createStore';
var useFooterBarStore = createStore(function (set) { return ({
    isVisibleFooterBar: false,
    actions: {
        setIsVisibleFooterBar: function (isVisibleFooterBar) { return set({ isVisibleFooterBar: isVisibleFooterBar }); },
    },
}); });
export var useFooterBarActions = function () {
    return useFooterBarStore(function (state) { return state.actions; });
};
export var selectIsVisibleFooterBar = function () {
    return useFooterBarStore(function (state) { return state.isVisibleFooterBar; });
};
