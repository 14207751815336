import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo } from 'react';
import { Col } from 'reactstrap';
import { selectDateRange } from '../../../../../store/watchersStatistic/useDateRangeStore';
import Table from './components/Table';
var groupMapping = {
    UA_team: 'ukrainian analysts',
    USA_team: 'american analysts',
    // PHL_team: "philippine analysts",
    ML_bot_system: 'ML-bot',
    Hardware_team: 'Network',
};
var AnalystsTable = function (_a) {
    var tablesData = _a.tablesData;
    var dateRange = selectDateRange();
    var analysts = tablesData.analysts, totalByTeam = tablesData.totalByTeam;
    return (_jsx(_Fragment, { children: (Object.keys(analysts) || []).map(function (key) {
            var _a, _b;
            return (_jsxs(Col, { className: "count-ai-table-wrap mb-3", md: 6, children: [_jsxs("h4", { children: ["Statistic ", groupMapping[key], " from", ' ', moment((_a = dateRange[0]) !== null && _a !== void 0 ? _a : new Date()).format('YYYY-MM-DD'), " to", ' ', moment((_b = dateRange[1]) !== null && _b !== void 0 ? _b : new Date()).format('YYYY-MM-DD')] }), _jsx(Table, { data: analysts[key], totalByTeam: totalByTeam })] }, key));
        }) }));
};
export default memo(AnalystsTable);
