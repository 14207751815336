import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import { getIsBlockedActionOnAIPreAIPage } from '../../../../shared/helper/commonHelper';
var SimpleLink = function (_a) {
    var item = _a.item, index = _a.index, prefix = _a.prefix, isFullSizeSidebar = _a.isFullSizeSidebar;
    var pathname = useLocation().pathname;
    var isBlockedToggleSidebarSize = getIsBlockedActionOnAIPreAIPage(pathname);
    return (_jsxs(NavLink, { "data-tooltip-id": "sidebar_".concat(index), onClick: _get(item, 'onClick', null), className: function (navData) { return (navData.isActive ? 'is-active' : ''); }, to: prefix + item.link, end: true, children: [_jsx("div", { className: "menu__icon", children: item.icon() }), isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsx(_Fragment, {})) : (_jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "right", id: "sidebar_".concat(index), children: item.title })), isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsx("div", { className: "menu__title", children: item.getTitle ? item.getTitle() : item.title })) : (_jsx(_Fragment, {}))] }));
};
export default memo(SimpleLink);
